import React, { useState, useEffect, useCallback } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/mn';
import 'react-datepicker/dist/react-datepicker.css';
import mn from 'date-fns/locale/mn';

registerLocale('mn', mn);

const CustomDatePicker = ({
  ref = null,
  value = null,
  onChange = () => {},
  disable = false,
  minDate = null,
  placeholder = 'Огноо оруулна уу...',
  customClass = '',
}) => {
  const [date, setDate] = useState(null);
  useEffect(() => {
    if (value) {
      setDate(new Date(value));
    }
  }, [value]);

  const _format = useCallback((val) => {
    if (!val) return '';

    const len = val.length;

    if (len === 1 && !['1', '2'].includes(val[0])) {
      return '';
    }
    if (len === 2 && !['0', '9'].includes(val[1])) {
      return val.substr(0, 1);
    }
    if (len === 5) {
      if (val[4] === '/') {
        return val.substr(0, 4);
      }
      if (['0', '1'].includes(val[4])) {
        return `${val.substr(0, 4)}/${val.substr(4)}`;
      }
      return val.substr(0, 4);
    }

    if (
      // eslint-disable-next-line operator-linebreak
      len === 7 &&
      ![
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
      ].includes(val.substr(5, 6))
    ) {
      return val.substr(0, 6);
    }
    if (len === 8) {
      if (val[7] === '/') {
        return val.substr(0, 7);
      }
      if (['0', '1', '2'].includes(val[7])) {
        return `${val.substr(0, 7)}/${val.substr(7)}`;
      }
      if (!(val[5] === '0' && val[6] === '2') && ['3'].includes(val[7])) {
        return `${val.substr(0, 7)}/${val.substr(7)}`;
      }
      return val.substr(0, 7);
    }
    if (len === 10) {
      if (val[8] === '3' && !['0', '1'].includes(val[9])) {
        return val.substr(0, 9);
      }
    }
    if (len > 10) {
      return val.substr(0, 10);
    }
    return val;
  });

  const onChangeRaw = useCallback(
    (e) => {
      e.target.value = _format(e.target.value);
    },
    [_format]
  );

  const changeDate = (_date) => {
    if (_date) {
      setDate(_date);
      onChange(moment(_date).format('YYYY/MM/DD'));
    } else {
      setDate(null);
      onChange(null);
    }
  };

  return (
    <DatePicker
      className={`ant-picker ${customClass}`}
      locale="mn"
      selected={date}
      onChange={changeDate}
      onChangeRaw={onChangeRaw}
      dateFormat="yyyy/MM/dd"
      readOnly={disable}
      ref={ref}
      placeholderText={placeholder}
      minDate={new Date(minDate)}
    />
  );
};

export default CustomDatePicker;
