import React, { useState, useEffect } from 'react';
import { Form, Select, Row, Col, Button, Modal } from 'antd';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import EditableTable from 'src/components/common/editableTable';
import newAlert from 'src/components/newAlert';
import { HurunguColumns } from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';

const { Option } = Select;
const NewUpdateHurungu = ({ listDef = null, onCancel = {}, visible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const socket = useSelector((state) => state.socket);

  const [tableData, setTableData] = useState([]);
  const [SelectedHurunguRows, setSelectedHurunguRows] = useState([]);
  const [SelectedHurunguIds, setSelectedHurunguIds] = useState([]);

  function select_data() {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content?.result_list);
        setSelectedHurunguIds([]);
        setSelectedHurunguRows([]);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: { action_type: 'undsen_medeelel_hurungu' },
    });
    dispatch(start_loading({}));
  }

  useEffect(() => {
    if (visible) {
      select_data();
    }
  }, [visible]);

  const closeModal = () => {
    form.resetFields();
    onCancel('visible2');
  };

  const handleSave = (values) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: data.message });
      } else {
        newAlert({ type: 'error', msg: data.message });
      }
      closeModal();
      dispatch(end_loading({}));
    });
    const temp_data = {
      ...values,
      data_table: SelectedHurunguRows,
    };
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: {
        action_type: 'undsen_medeelel_hurungu',
        json_array: { ...temp_data },
      },
    });
    dispatch(start_loading({}));
  };

  const onFinish = (values) => {
    handleSave(values);
  };

  const geTvalue = (value) => {
    form.setFieldsValue({
      children: [...value],
    });
  };

  const rowSelection = {
    selectedRowKeys: SelectedHurunguIds,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedHurunguRows([...selectedRows]);
      setSelectedHurunguIds([...selectedRowKeys]);
    },
  };

  const layout = {
    labelCol: {
      xs: { span: 5 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 19 },
      sm: { span: 19 },
    },
  };

  return (
    <Modal
      className="custom-modal"
      title="Үндсэн хөрөнгийн ангилал /хөрөнгийн кодоор/"
      open={visible}
      width="80%"
      footer={null}
      onCancel={() => {
        onCancel('visible2');
      }}
    >
      <div className="dans-medeelel-container">
        <Form
          form={form}
          onFinish={onFinish}
          className="register-form"
          {...layout}
          size="small"
        >
          <Form.Item
            name="hurungu_turul"
            label="Хөрөнгийн төрөл"
            labelAlign="left"
            rules={[{ required: true, message: '' }]}
          >
            <Select
              size="small"
              placeholder="Сонгох"
              style={{ width: '60%', fontSize: 13 }}
            >
              {get(listDef, 'turul', []).map((i) => (
                <Option value={i?.val}>{i?.text}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="hurungu_buleg_dugaar"
            label="Хөрөнгийн бүлэг"
            labelAlign="left"
            rules={[{ required: true, message: '' }]}
          >
            <Select placeholder="Сонгох" style={{ width: '60%', fontSize: 13 }}>
              {get(listDef, 'buleg', []).map((i) => (
                <Option value={i?.val}>{i?.text}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {visible && (
              <EditableTable
                dataSource={tableData}
                rowSelection={rowSelection}
                columns={HurunguColumns()}
                geTvalue={geTvalue}
                scroll={{ y: '500' }}
              />
            )}
          </Form.Item>
          <Row style={{ marginTop: 10 }}>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => {
                  closeModal();
                }}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Буцах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default NewUpdateHurungu;
