import React, { useEffect, useState } from 'react';
import { useQuery } from 'src/core/utils/formater';
import { useSelector, useDispatch } from 'react-redux';
import CanView from 'src/components/permission/canview';
import { useHistory } from 'react-router-dom';
import { PlusCircleOutlined, CalculatorOutlined } from '@ant-design/icons';
import { Button as AntButton, Row } from 'antd';
import { Table } from 'src/components';
import newAlert from 'src/components/newAlert';
import { customConfirm } from 'src/components/common/confirmModal';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { UrsgalZasvarAll } from './UrsgalZasvarAll';
import { TatvarZalruulgaAll } from './TatvarZalruulgaAll';
import {
  ColumnsMain,
  customSummary,
  ColumnsDansMain,
  customDansSummary,
} from './dataStructure/data';
import { searchList } from './dataStructure/search';
import BurtgeList from './BurtgeList';

export default function Tab1() {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);

  const [sumType, seTsumType] = useState();
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });
  const [visibleUrsgalZasvarAll, setVisibleUrsgalZasvarAll] = useState(false);
  // eslint-disable-next-line operator-linebreak
  const [visibleTatvarZalruulgaAll, setVisibleTatvarZalruulgaAll] =
    useState(false);
  const [selectedHurungu, seTselectedHurungu] = useState({});
  const [burtgel, seTburtgel] = useState(false);

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const search = {
      type: sumType || 'hurungu',
      dans_dugaar: query.get('dans_dugaar'),
      hurungu: query.get('hurungu'),
    };
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'ehnii_tatvar_suuri_page',
        month: '13',
        page,
        size,
        search,
      },
    });
    dispatch(start_loading({}));
  };

  function cal_suuri() {
    socket.on('request_out', (data) => {
      if (data.status === '0') {
        socket.removeAllListeners('request_out');
        newAlert({ type: 'success', msg: 'Товчоо амжилттай тооцооллоо' });
        dispatch(end_loading({}));
        select_data();
      } else if (data.status === '1') {
        socket.removeAllListeners('request_out');
        newAlert({ type: 'error', msg: data.message });
        dispatch(end_loading({}));
        select_data();
      } else if (data.status === '3') {
        newAlert({ type: 'warning', msg: data.message });
      } else {
        socket.removeAllListeners('request_out');
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
        select_data();
      }
    });
    socket.emit('request_in', {
      channel: 'ts003_calculate',
      content: { action_type: 'ehnii_uldegdel_tatvar_suuri' },
    });
    dispatch(start_loading({}));
  }

  const clearPage = () => {
    const proplist = ['page', 'size'];
    let not_change = true;
    proplist.forEach((name) => {
      if (query.get(name)) {
        query.delete(name);
        not_change = false;
      }
    });
    if (not_change) {
      select_data();
    } else {
      history.push({ search: query.toString() });
    }
  };

  useEffect(() => {
    if (sumType) {
      clearPage();
    }
  }, [sumType]);

  useEffect(() => {
    select_data();
  }, [query]);

  const onCancel = () => {
    setVisibleUrsgalZasvarAll(false);
  };

  const onCancelZalruulga = () => {
    setVisibleTatvarZalruulgaAll(false);
  };

  const onSelectHurungu = (row) => {
    seTselectedHurungu(row);
    seTburtgel(true);
  };

  const title = () => (
    <Row>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={version?.is_first_year ? <PlusCircleOutlined /> : null}
        onClick={() => {
          setVisibleTatvarZalruulgaAll(true);
        }}
      >
        Татварын суурь - Залруулга
      </AntButton>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={version?.is_first_year ? <PlusCircleOutlined /> : null}
        onClick={() => {
          setVisibleUrsgalZasvarAll(true);
        }}
      >
        Татварын суурь - Өртгийн өөрчлөлт
      </AntButton>
      {version?.is_first_year && (
        <CanView allowedRole="006_1">
          <AntButton
            className="add-btn"
            type="primary"
            size="small"
            icon={<CalculatorOutlined />}
            onClick={() => {
              customConfirm({
                title: 'Татварын суурь тооцоолохыг зөвшөөрөх үү?',
                onOk: () => cal_suuri(),
              });
            }}
          >
            Татварын суурь тооцоолох
          </AntButton>
        </CanView>
      )}
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        onClick={() => {
          seTsumType(sumType === 'dans' ? 'hurungu' : 'dans');
        }}
      >
        {sumType === 'dans' ? 'Дансаар' : 'Хөрөнгөөр'}
      </AntButton>
    </Row>
  );

  const mainTable = () => (
    <div className="table-antd-div no-scroll">
      <Table
        isSearch
        searchList={searchList}
        title={title}
        columns={ColumnsMain({ onSelectHurungu })}
        total={tableData.total_size}
        dataSource={tableData.result_list}
        scroll={{
          y: 'calc(100vh - 360px)',
        }}
        summaryList={{
          sumColumn: customSummary,
          sumDataSource: tableData.total_list,
        }}
      />
    </div>
  );

  const mainDansTable = () => (
    <div className="table-antd-div no-scroll">
      <Table
        isSearch
        searchList={searchList}
        title={title}
        columns={ColumnsDansMain()}
        total={tableData.total_size}
        dataSource={tableData.result_list}
        scroll={{
          y: 'calc(100vh - 360px)',
        }}
        summaryList={{
          sumColumn: customDansSummary,
          sumDataSource: tableData.total_list,
        }}
      />
    </div>
  );

  const onCancelBurtgel = () => {
    seTburtgel(false);
  };

  return (
    <>
      <UrsgalZasvarAll
        visibleUrsgalZasvarAll={visibleUrsgalZasvarAll}
        onCancel={onCancel}
      />
      <BurtgeList
        hurunguData={selectedHurungu}
        closeDrawer={onCancelBurtgel}
        open={burtgel}
      />
      <TatvarZalruulgaAll
        visibleTatvarZalruulgaAll={visibleTatvarZalruulgaAll}
        onCancel={onCancelZalruulga}
      />
      {sumType === 'dans' ? mainDansTable() : mainTable()}
    </>
  );
}
