import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, List, Button, Divider, Spin, Modal } from 'antd';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { LoadingOutlined } from '@ant-design/icons';
import Zoom from 'react-reveal/Zoom';
import newAlert from 'src/components/newAlert';
import { columns, late_columns } from './data';
import TailanIlgeeh from './tailanIlgeeh';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Detail = ({ element = null, onClose = () => {} }) => (
  <div className="custom-detail">
    <div className="custom-header-section">
      <p className="month-detail">{element?.taxReportCode} cap</p>

      <div className="month-detail-body">
        <p>
          Тайлангийн маягтын нэр: <span> {element?.taxReportName}</span>
        </p>
      </div>
    </div>
    {element?.returnPeriods.map((data, index) => (
      <div className="custom-detail-body">
        <div className="subTitle">{data.periodLabel}</div>

        <Table
          className="file-table"
          columns={late_columns}
          dataSource={data?.reports}
          pagination={false}
          scroll={{
            x: 700,
          }}
          rowClassName={() => 'e-tax-row'}
        />
      </div>
    ))}

    <div className="btn-section">
      <Button className="e-tax-btn" onClick={onClose}>
        Буцах{' '}
      </Button>
    </div>
  </div>
);

const ReportSend = () => {
  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));
  const dispatch = useDispatch();
  const [visible, seTvisible] = useState(false);
  const [mayagtType, seTmayagtType] = useState('mayagt');
  const [medeeVisible, seTmedeeVisible] = useState(false);
  const [dataHistory, seTdataHistory] = useState([]);
  const [dataDetail, seTdataDetail] = useState({});

  const close = () => {
    seTvisible(false);
    seTmedeeVisible(false);
  };

  const open = (data) => {
    seTdataDetail(data);
    seTmayagtType(1);
    seTvisible(true);
  };

  const open_medee = (data) => {
    seTdataDetail(data);
    seTmayagtType(2);
    seTmedeeVisible(true);
  };
  useEffect(() => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        seTdataHistory(content?.report_data);
      } else {
        newAlert({ type: 'error', msg: content.Message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss012_manage',
      content: {
        action_type: 'get_saved_tailan',
        access_token: JSON.parse(localStorage.getItem('etaxtoken'))
          ?.access_token,
        ttd: localStorage.getItem('ttd'),
      },
    });
    dispatch(start_loading({}));
  }, []);

  return (
    <>
      <Modal
        className="custom-modal"
        title="Etax системд маягт хадгалах"
        visible={visible}
        closable={false}
        width={900}
        footer={null}
      >
        <TailanIlgeeh
          mayagtType={mayagtType}
          dataDetail={dataDetail}
          closeModal={close}
          socket={socket}
          dispatch={dispatch}
          data_date={data_date}
        />
      </Modal>

      <Modal
        className="custom-modal"
        title="Etax системд мэдээ хадгалах"
        visible={medeeVisible}
        closable={false}
        width={900}
        footer={null}
      >
        <TailanIlgeeh
          mayagtType={mayagtType}
          dataDetail={dataDetail}
          closeModal={close}
          socket={socket}
          dispatch={dispatch}
          data_date={data_date}
        />
      </Modal>
      <div className="report-send">
        <Table
          className="e-tax-table"
          columns={columns({
            open,
            open_medee,
          })}
          dataSource={dataHistory}
          pagination={false}
          scroll={{
            x: 700,
          }}
          rowClassName={() => 'e-tax-row'}
        />
      </div>
    </>
  );
};

const get_history_list = (socket, dispatch, seTdataHistory, seTloading) => {
  socket.on('request_out', ({ status, content }) => {
    socket.removeAllListeners('request_out');
    if (status === '0') {
      seTdataHistory(content?.tailan_data);
    } else {
      newAlert({ type: 'error', msg: content.Message });
    }
    dispatch(end_loading({}));
    seTloading(false);
  });
  socket.emit('request_in', {
    channel: 'ss012_manage',
    content: {
      action_type: 'get_history',
      access_token: JSON.parse(localStorage.getItem('etaxtoken'))?.access_token,
      ttd: localStorage.getItem('ttd'),
    },
  });
  dispatch(start_loading({}));
  seTloading(true);
};

const ReportHistory = () => {
  const ZoomWrap = ({
    item = null,
    fade = false,
    onClose = () => {},
    children,
  }) =>
    (fade ? (
      <Zoom>
        <Detail onClose={onClose} element={item} />
      </Zoom>
    ) : (
      children
    ));
  const socket = useSelector((state) => state.socket);
  const dispatch = useDispatch();
  const [active, seTactive] = useState(null);
  const [loading, seTloading] = useState(false);
  const [dataHistory, seTdataHistory] = useState([]);

  useEffect(() => {
    get_history_list(socket, dispatch, seTdataHistory, seTloading);
  }, []);

  const onActive = (id) => {
    seTloading(true);
    setTimeout(() => {
      seTactive(id);
      seTloading(false);
    }, 100);
  };

  const onClose = () => {
    seTloading(true);
    setTimeout(() => {
      seTactive(null);
      seTloading(false);
    }, 100);
  };

  if (loading) return '';

  const _grid = {
    gutter: 25,
    xs: 1,
    sm: 1,
    md: 1,
    lg: active ? 1 : 3,
    xl: active ? 1 : 3,
    xxl: active ? 1 : 3,
  };

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin indicator={antIcon} />;
      </div>
    );
  }

  return (
    <div className="report-history">
      <List
        grid={_grid}
        className="year-list"
        dataSource={dataHistory}
        renderItem={(item, idx) => (
          <ZoomWrap item={item} onClose={onClose} fade={active === item?.id}>
            <List.Item
              onClick={() => onActive(item?.id)}
              className={`month-card ${
                active ? (active === item?.id ? 'active' : 'deactive') : ''
              }`}
            >
              <p className="month-card-title">{item?.taxReportCode}</p>
              <div className="inside-body">
                <Divider className="ww-100 fs-13 fw-600 f-st-i">
                  {item?.taxReportName}
                </Divider>
              </div>
            </List.Item>
          </ZoomWrap>
        )}
      />
    </div>
  );
};

const ReportLate = () => {
  const { socket } = useSelector((state) => ({
    socket: state.socket,
  }));
  const dispatch = useDispatch();
  const [lateHistory, seTlateHistory] = useState([]);

  useEffect(() => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        seTlateHistory(content?.report_data);
      } else {
        newAlert({ type: 'error', msg: content.Message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss012_manage',
      content: {
        action_type: 'get_late_list',
        access_token: JSON.parse(localStorage.getItem('etaxtoken'))
          ?.access_token,
        ttd: localStorage.getItem('ttd'),
      },
    });
    dispatch(start_loading({}));
  }, []);

  return (
    <div className="report-late">
      <Table
        className="e-tax-table"
        columns={late_columns}
        dataSource={lateHistory}
        pagination={false}
        scroll={{
          x: 700,
        }}
        rowClassName={() => 'e-tax-row'}
      />
    </div>
  );
};

export default {
  send: ReportSend,
  history: ReportHistory,
  late: ReportLate,
};
