import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Form, Row, Col, Button } from 'antd';
import { Table } from 'src/components';
import { useQuery } from 'src/core/utils/formater';
import { useHistory } from 'react-router-dom';
import newAlert from 'src/components/newAlert';
import {
  customColumns,
  customSummary,
  modalColumns,
  modalSummary,
} from './dataStructure/data';

import { start_loading, end_loading } from '../../../core/actions/loader';

const MainPage = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState({});
  const [modalData, seTmodalData] = useState({});
  const [dansData, seTdansData] = useState({});

  const [form] = Form.useForm();

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setTableData(data.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });

    socket.emit('request_in', {
      channel: 'ts001_04_mh_controller',
      content: {
        action_type: 'get_dun_list_buren_sanhuu',
        month,
      },
    });

    dispatch(start_loading({}));
  };

  const getTovchoo = (dans_info) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        seTmodalData(data.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const search = {};

    socket.emit('request_in', {
      channel: 'ts001_04_mh_controller',
      content: {
        action_type: 'get_tovchoo_sanhuu',
        dans_id: dans_info?.dans_id,
        month: data_date?.month,
        paging: { page, size, search },
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date?.month);
    }
  }, [data_date]);

  useEffect(() => {
    if (visible) {
      getTovchoo(dansData);
    }
  }, [query]);

  const handleOpenModal = (dans_info) => {
    // Delete page search from query
    query.delete('page');
    history.push({ search: query.toString() });
    seTdansData(dans_info);
    getTovchoo(dans_info);
    setVisible(true);
  };

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          columns={customColumns({
            handleOpenModal,
          })}
          dataSource={tableData?.json_array}
          scroll={{
            y: 'calc(100vh - 260px)',
          }}
          customize={() => customSummary({ sum_data: tableData?.sum_data })}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Мөнгөн хөрөнгө - Дансны үнэ дэлгэрэнгүй"
        open={visible}
        closable={false}
        maskClosable={false}
        width={1200}
        footer={null}
      >
        <CustomModal
          form={form}
          onClose={() => {
            setVisible(false);
          }}
          modalData={modalData}
          dansData={dansData}
        />
      </Modal>
    </>
  );
};

const CustomModal = ({
  onClose = () => {},
  form = null,
  modalData = {},
  dansData = {},
}) => {
  const onCancel = () => {
    onClose();
  };

  return (
    <>
      Дансны дугаар: {dansData?.dans_dugaar} / {dansData?.valiut} /
      <Table
        columns={modalColumns}
        dataSource={modalData?.json_array}
        scroll={{
          y: 175,
        }}
        customize={() => modalSummary({ sum_data: modalData?.sum_data })}
        total={modalData?.total}
      />
      <Form form={form} className="register-form" size="small">
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Хаах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default MainPage;
