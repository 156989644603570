import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { roundFloat } from 'src/core/utils/formater';
import { customConfirm } from 'src/components/common/confirmModal';

const setRound = (_) => roundFloat(_);

const list = {
  0: '',
  1: 'Хөдөлмөрийн гэрээ',
  2: 'Контрактын гэрээ',
  3: 'Ажил гүйцэтгэх гэрээ',
  4: 'Хөлсөөр ажиллах гэрээ',
};

export const columns = ({ delete_row = () => {} }) => [
  {
    title: 'Огноо',
    align: 'center',
    dataIndex: 'ognoo',
    titleAlign: 'center',
    width: 100,
  },
  {
    title: 'Орлогын төрөл',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'orlogiin_torol',
    width: 100,
    render: (_) => (_ === 1 ? 'Цалин хөлс' : 'Шууд бус орлого'),
  },
  {
    title: 'Гэрээний төрөл',
    align: 'left',
    width: 150,
    titleAlign: 'left',
    dataIndex: 'gereenii_torol',
    render: (_) => list[_],
  },
  {
    title: 'Цалингийн зардлын дүн',
    align: 'right',
    width: 100,
    titleAlign: 'center',
    dataIndex: 'dun',
    render: setRound,
  },

  {
    title: 'Тайлбар',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'tailbar',
  },
  // {
  //   title: 'Засах',
  //   align: 'center',
  //   titleAlign: 'center',
  //   width: 50,
  //   render: (_, row) => (
  //     <Tooltip title="Засах">
  //       <EditOutlined
  //         style={{ color: '#08c' }}
  //         onClick={() => send_yoslol_data(row)}
  //       />
  //     </Tooltip>
  //   ),
  // },
  {
    title: 'Устгах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    render: (_, row) => (
      <Tooltip title="Устгах">
        <DeleteOutlined
          onClick={() =>
            customConfirm({
              onOk: () => delete_row(row?.id),
            })
          }
        />
      </Tooltip>
    ),
  },
];

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: null,
  },
  {
    align: 'right',
    value: 'dun',
  },
  {
    align: 'right',
    value: null,
  },
  {
    align: 'right',
    value: null,
  },
  {
    align: 'right',
    value: null,
  },
];
