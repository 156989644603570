import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from 'src/components';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  Typography,
  Select,
  Divider,
  Checkbox,
  Input,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { roundFloat, onlyNumber, useQuery } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import { customColumns, customSummary } from './dataStructure/data';
import { searchList } from './dataStructure/search';
import { start_loading, end_loading } from '../../../core/actions/loader';

const { Text } = Typography;
const { Option } = Select;
const month_list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const layout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 17 },
  },
};

const layoutEditModal = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 15 },
  },
};

const Tohiruulga = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  // const [tableData, setTableData] = useState({
  //   filter_list: [],
  //   json_array: [],
  //   sum_data: {},
  //   total: 0,
  // });
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });
  const [currentDansInfo, seTcurrentDansInfo] = useState(null);
  const [visible, seTvisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [batchDelVisible, setBatchDelVisible] = useState(false);
  const [journalType, seTjournalType] = useState('balance');

  const [form] = Form.useForm();
  const [deleteForm] = Form.useForm();
  const [batchDeleteForm] = Form.useForm();

  const generateTailan = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setTableData(data?.content);
      } else {
        setTableData({});
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });

    const page = query.get('page');
    const size = query.get('size');
    const { month } = data_date;
    const search = {
      dans_dugaar: query.get('dans_dugaar') || '',
      turul: journalType || '',
    };
    socket.emit('request_in', {
      channel: 'ts001_05_view',
      content: {
        action_type: 'sanhuu_tohiruulga_page',
        month,
        page,
        size,
        search,
      },
    });

    dispatch(start_loading({}));
  };

  const deleteValue = (deleteData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай устгагдлаа' });
        generateTailan(data_date.month);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tatvar_journal_burtgel',
      content: {
        action_type: 'delete',
        id: deleteData.delete_id,
        turul: deleteData.journal_type,
      },
    });

    dispatch(start_loading({}));
  };

  const saveValues = (saveData) => {
    const tempData = saveData;
    delete tempData.undsen_dans;
    delete tempData.harits_dans;

    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan(data_date.month);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_05_view',
      content: { action_type: 'sanhuu_tohiruulga_page', data: tempData },
    });

    dispatch(start_loading({}));
  };

  const deleteBatch = (reqData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай устгагдлаа' });
        generateTailan(data_date.month);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tatvar_journal_burtgel',
      content: { action_type: 'delete_orlogo_tohirgoo_batch', month: reqData?.month },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan();
    }
  }, [data_date, journalType, query]);

  const deleteAction = (row) => {
    deleteForm.setFieldsValue({
      delete_id: row?.id,
      journal_type: row?.turul,
    });

    setDeleteVisible(true);
  };

  const editAction = (row) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        seTcurrentDansInfo(data.content);
        form.setFieldsValue({
          id: row?.id,
          undsen_dans: `${row?.dans_dugaar} ${row?.dans_ner}`,
          harits_dans: `${row?.harits_dans_dugaar} ${row?.harits_dans_ner}`,

          deb: roundFloat(row?.deb),
          cred: roundFloat(row?.cred),

          guilgee_utga: row?.guilgee_utga,
          aanoat_nohtsol: row?.modifier?.ext_aanoat_nohtsol,
          gadaad_orlogo: row?.modifier?.ext_gadaad_olson,
          aanoat_zardal: row?.modifier?.ext_aanoat_zardal,
        });
        seTvisible(true);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tatvar_journal_burtgel',
      content: {
        action_type: 'get_dans_info',
        tohiruulga_type: 'Орлого',
        month: String(data_date.month),
        dans_id: row?.dans_id,
      },
    });
  };

  const joutnalTypeUpdate = (type) => {
    seTjournalType(type);
  };

  const title = () => (
    <div className="table-title-text">
      {'Тохируулгын төрөл: '}
      <Select
        // defaultValue="balance"
        value={journalType}
        style={{ width: 180 }}
        onChange={joutnalTypeUpdate}
        size="small"
      >
        <Option value="balance">ХА тохируулга</Option>
        <Option value="orlogo">ОЗНД тохируулга</Option>
        <Option value="journal">ОАТ тохируулга</Option>
      </Select>
      {/* <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<DeleteOutlined />}
        onClick={() => {
          setBatchDelVisible(true);
        }}
      >
        Орлогын тохиргоо багцаар устгах
      </Button> */}
    </div>
  );

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          columns={customColumns({
            customDelete: deleteAction,
            dansInfo: tableData?.filter_list,
            customEdit: editAction,
          })}
          dataSource={tableData?.result_list}
          scroll={{
            y: 'calc(100vh - 330px)',
          }}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableData?.total_list,
          }}
          title={title}
          total={tableData?.total_size}
          isSearch
          searchList={searchList}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Татварын тохируулга устгах"
        open={deleteVisible}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <DeleteModal
          onClose={() => {
            setDeleteVisible(false);
          }}
          form={deleteForm}
          deleteValue={deleteValue}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Татварын тохиргоо засах"
        open={visible}
        closable={false}
        maskClosable={false}
        width={810}
        footer={null}
      >
        <CustomModal
          onClose={() => {
            seTvisible(false);
          }}
          form={form}
          listData={currentDansInfo}
          saveValues={saveValues}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Орлогын тохиргоо багцаар устгах"
        open={batchDelVisible}
        closable={false}
        maskClosable={false}
        width={400}
        footer={null}
      >
        <BatchDeleteModal
          onClose={() => {
            setBatchDelVisible(false);
          }}
          form={batchDeleteForm}
          onRequest={deleteBatch}
        />
      </Modal>
    </>
  );
};

const DeleteModal = ({
  onClose = () => {},
  form = null,
  deleteValue = () => {},
}) => {
  const onFinish = (values) => {
    deleteValue(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="delete_id" hidden />
        <Form.Item name="journal_type" hidden />
        <Text>Та энэ үйлдлийг хийхдээ итгэлтэй байна уу?</Text>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Устгах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const CustomModal = ({
  onClose = () => {},
  form = null,
  listData = {},
  saveValues = () => {},
}) => {
  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const onFinish = (values) => {
    saveValues(values);
    onCancel();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layoutEditModal}
        size="small"
      >
        <Form.Item name="id" hidden />
        <Form.Item name="undsen_dans" label="Үндсэн данс" labelAlign="left">
          <Text ellipsis>{form.getFieldValue('undsen_dans')}</Text>
        </Form.Item>
        <Form.Item name="harits_dans" label="Харьцах данс" labelAlign="left">
          <Text ellipsis>{form.getFieldValue('harits_dans')}</Text>
        </Form.Item>
        <Divider plain>Дүн</Divider>
        <Form.Item
          name="deb"
          label="Дебет"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" />
        </Form.Item>
        <Form.Item
          name="cred"
          label="Кредит"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" />
        </Form.Item>

        <Divider plain>Нэмэлт тохиргоо</Divider>
        <Form.Item
          name="guilgee_utga"
          label="Гүйлгээний утга"
          labelAlign="left"
        >
          <Input className="w-100" />
        </Form.Item>
        <Form.Item
          name="aanoat_nohtsol"
          label="ААНОАТ-ын орлого ХЗ нөхцөл"
          labelAlign="left"
          hidden={listData?.aanoat_nohtsol_enabled}
        >
          <Select defaultValue={null}>
            <Option value="">---</Option>
            {listData?.aanoat_nohtsol.map((i) => (
              <Option value={i?.id}>
                {i?.dugaar} {i?.ner}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="gadaad_orlogo"
          label="Гадаад улсад олсон орлого эсэх"
          labelAlign="left"
          hidden={listData?.gadaad_orlogo_enabled}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="aanoat_zardal"
          label="ААНОАТ 18.4-т заасан зардлал эсэх"
          labelAlign="left"
          hidden={listData?.aanoat_zardal_enabled}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const BatchDeleteModal = ({
  onClose = () => {},
  form = null,
  onRequest = () => {},
}) => {
  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const onFinish = (values) => {
    onRequest(values);
    onCancel();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layoutEditModal}
        size="small"
      >
        <Form.Item
          name="month"
          label="Сар"
          labelAlign="left"
          initialValue={1}
        >
          <Select>
            {month_list.map((i) => (
              <Option value={i} key={`month_${i}_del`}>
                {i}-р сар
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Устгах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Tohiruulga;
