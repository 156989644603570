import React, { useState, useEffect } from 'react';
import ProCard from '@ant-design/pro-card';
import { Typography, Button, Select } from 'antd';
import { Table } from 'src/components';
import { SearchOutlined } from '@ant-design/icons';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'src/core/utils/formater';
import { useSelector, useDispatch } from 'react-redux';
import { columns } from './data/data';

const { Title } = Typography;
const { Option } = Select;

const AuditLog = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const history = useHistory();

  const socket = useSelector((state) => state.socket);

  const [years, setYears] = useState([]);
  const [selectedValeus, seTselectedValeus] = useState({
    year: null, month: null,
  });
  const version = useSelector((state) => state.version);
  const [tableData, setTableData] = useState({
    total: 0,
    table_list: [],
    search_infos: {},
  });
  // const [searchData, setSearchData] = useState(
  //   {
  //     dans_dugaar: [],
  //     hurungu_code: [],
  //     burtgel_dugaar: [],
  //     version_number: [],
  //     tailan_jil: [],
  //   }
  // );
  const getYear = () => new Date().getFullYear();
  function select_data() {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');
      console.log(data_temp);

      if (data_temp.status === '0') {
        console.log(data_temp.content.json_array);

        const { json_array } = data_temp.content;

        setTableData({ ...json_array });

        dispatch(end_loading({}));
      } else {
        dispatch(end_loading({}));
      }
    });
    const page = query.get('page');
    const size = query.get('size');

    const search = {
      year: query.get('year'),
      month: query.get('month'),
      dans_dugaar: query.get('Dans'),
      hurungu_code: query.get('hurungu'),
      burtgel_dugaar: query.get('Burtgel'),
      version_number: query.get('Version'),
      tailan_jil: query.get('tailan_jil'),
    };
    socket.emit('request_in', {
      channel: 'ss004_user_management',
      content: { action_type: 'get_username', receive_channel: 'ss003_view', page, size, search },
    });
    dispatch(start_loading({}));
  }

  useEffect(() => {
    // select_data();
    console.log(version);
    const currentYear = getYear();
    seTselectedValeus({
      year: query.get('year') ? query.get('year') : currentYear,
      month: query.get('month') ? query.get('month') : 1,
    });
    setYears([
      currentYear,
      (currentYear - 1),
    ]);
  }, [query]);
  const oneChangeYear = (value) => {
    seTselectedValeus({ ...selectedValeus,
      year: value,
    });
  };
  const oneChangeMonth = (value) => {
    seTselectedValeus({ ...selectedValeus,
      month: value,
    });
  };
  const search_1 = () => {
    const propName = 'year';
    const propName2 = 'month';
    const proplist = ['Dans', 'hurungu', 'Burtgel', 'Version', 'tailan_jil'];

    if (query.get('year') === selectedValeus.year && query.get('month') === selectedValeus.month) {
      select_data();
    } else {
      if (query.get(propName)) {
        query.set(propName, selectedValeus.year);
      } else {
        query.append(propName, selectedValeus.year);
      }
      if (query.get(propName2)) {
        query.set(propName2, selectedValeus.month);
      } else {
        query.append(propName2, selectedValeus.month);
      }
      proplist.forEach((name) => {
        if (query.get(name)) {
          query.delete(name);
        }
      });

      history.push({ search: query.toString() });
    }
  };

  const title = () => (
    <div className="audit-title">
      <span className="fs-13 fw-600">Жил: </span>
      <Select className="select" value={selectedValeus.year} onChange={oneChangeYear}>
        {years.map((index) => (
          <Option value={index}>{index}</Option>
        ))}
      </Select>
      <span className="fs-13 fw-600"> Сар:</span>
      <Select className="select" value={selectedValeus.month} onChange={oneChangeMonth}>
        <Option value="1">1 сар</Option>
        <Option value="2">2 сар</Option>
        <Option value="3">3 сар</Option>
        <Option value="4">4 сар</Option>
        <Option value="5">5 сар</Option>
        <Option value="6">6 сар</Option>
        <Option value="7">7 сар</Option>
        <Option value="8">8 сар</Option>
        <Option value="9">9 сар</Option>
        <Option value="10">10 сар</Option>
        <Option value="11">11 сар</Option>
        <Option value="12">12 сар</Option>
      </Select>
      <Button className="confirm-btn" type="primary" icon={<SearchOutlined />} onClick={search_1}>
        Хайх
      </Button>
      {/* <Button className="confirm-btn" type="primary" icon={<SearchOutlined />}>
        Хайх
      </Button> */}
    </div>
  );
  return (
    <div className="audit-container">
      <ProCard
        className="role-header-card"
        split="vertical"
        title={<Title level={4}>Үйлдлийн түүх</Title>}
      >
        <div className="inside-container">
          <Table
            title={title}
            className="audit-table"
            columns={columns}
            dataSource={tableData.table_list}
            rowClassName="audit-row"
            pagination={false}
            bordered={false}
            total={tableData.total}
          />
        </div>
      </ProCard>
    </div>
  );
};

export default AuditLog;
