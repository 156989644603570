import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { roundFloat } from 'src/core/utils/formater';

export const dans_columns = (handleSelectDans = () => {}) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_code',
    align: 'left',
    width: 250,
    fixed: 'left',
    render: (_, row) => (
      <Button
        type="link"
        size="small"
        className="underline"
        onClick={() => handleSelectDans(row?.dans_code)}
      >
        {row?.dans_code}
      </Button>
    ),
  },
  {
    title: 'Дансны үнэ',
    dataIndex: 'etssiin_dun',
    width: 150,
    align: 'right',
    render: (_, row) => ({
      children: roundFloat(row?.etssiin_dun || 0),
    }),
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'etssiin_dun_tatvar',
    width: 150,
    align: 'right',
    render: (_, row) => ({
      children: roundFloat(row?.etssiin_dun_tatvar || 0),
    }),
  },
  {
    title: 'Зөрүү',
    dataIndex: 'zuruu',
    width: 150,
    align: 'right',
    render: (_, row) => ({
      children: roundFloat(row?.zuruu || 0),
    }),
  },
  {
    title: 'Түр зөрүүгээр хүлээн зөвшөөрөхгүй зөрүү',
    align: 'center',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'hz_hasagdah_tur_zuruu',
        width: 150,
        align: 'right',
        render: (_, row) => ({
          children: roundFloat(row?.hz_hasagdah_tur_zuruu || 0),
        }),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'hz_tatvar_tur_zuruu',
        align: 'right',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.hz_tatvar_tur_zuruu || 0),
        }),
      },
    ],
  },
  {
    title: 'Түр зөрүү',
    align: 'center',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'hasagdah_tur_zuruu',
        width: 150,
        align: 'right',
        render: (_, row) => ({
          children: roundFloat(row?.hasagdah_tur_zuruu || 0),
        }),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'tatvar_tur_zuruu',
        align: 'right',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.tatvar_tur_zuruu || 0),
        }),
      },
    ],
  },
];

export const hariltsagch_columns = (pushDialogValuesData = () => {}) => [
  {
    title: 'Харилцагч',
    children: [
      {
        title: 'Код',
        dataIndex: 'hariltsagch_code',
        width: 150,
        align: 'left',
      },
      {
        title: 'Нэр',
        dataIndex: 'hariltsagch_ner',
        width: 200,
        align: 'left',
        ellipsis: true,
      },
    ],
  },
  {
    title: 'Дансны үнэ',
    dataIndex: 'etssiin_dun',
    width: 150,
    align: 'right',
    render: (_, row) => ({
      children: roundFloat(row?.etssiin_dun || 0),
    }),
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'etssiin_dun_tatvar',
    width: 150,
    align: 'right',
    render: (_, row) => ({
      children: roundFloat(row?.etssiin_dun_tatvar || 0),
    }),
  },
  {
    title: 'Зөрүү',
    dataIndex: 'zuruu',
    width: 150,
    align: 'right',
    render: (_, row) => ({
      children: roundFloat(row?.zuruu || 0),
    }),
  },
  {
    title: 'Түр зөрүүгээр хүлээн зөвшөөрөхгүй зөрүү',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'hz_hasagdah_tur_zuruu',
        width: 150,
        align: 'right',
        render: (_, row) => ({
          children: roundFloat(row?.hz_hasagdah_tur_zuruu || 0),
        }),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'hz_tatvar_tur_zuruu',
        width: 150,
        align: 'right',
        render: (_, row) => ({
          children: roundFloat(row?.hz_tatvar_tur_zuruu || 0),
        }),
      },
    ],
  },
  {
    title: '',
    align: 'center',
    width: 30,
    render: (_, row) => {
      if (row?.zuruu !== 0) {
        return (
          <div>
            <EditOutlined
              onClick={() => pushDialogValuesData(row, 'hasagdah_tur_zuruu')}
            />
          </div>
        );
      }
      return '';
    },
  },
  {
    title: 'Түр зөрүү',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'hasagdah_tur_zuruu',
        width: 150,
        align: 'right',
        render: (_, row) => ({
          children: roundFloat(row?.hasagdah_tur_zuruu || 0),
        }),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'tatvar_tur_zuruu',
        width: 150,
        align: 'right',
        render: (_, row) => ({
          children: roundFloat(row?.tatvar_tur_zuruu || 0),
        }),
      },
    ],
  },
];

export const hariltsagch_sum_columns = [
  {
    word: '',
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'etssiin_dun',
  },
  {
    align: 'right',
    value: 'etssiin_dun_tatvar',
  },
  {
    align: 'right',
    value: 'zuruu',
  },
  {
    align: 'right',
    value: 'hz_hasagdah_tur_zuruu',
  },
  {
    align: 'right',
    value: 'hz_tatvar_tur_zuruu',
  },
  {
    word: '',
  },
  {
    align: 'right',
    value: 'hasagdah_tur_zuruu',
  },
  {
    align: 'right',
    value: 'tatvar_tur_zuruu',
  },
];
