import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { roundFloat } from 'src/core/utils/formater';
import { Typography } from 'antd';

const { Text } = Typography;

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'deb',
  },
  {
    align: 'right',
    value: 'cred',
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
];

export const customColumns = ({
  customDelete = () => {},
  // dansInfo = [],
  customEdit = () => {},
}) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    titleAlign: 'center',
    fixed: 'left',
    width: 200,
    render: (_, row) => (
      <div>
        <div>{row?.dans_dugaar}</div>
        {row?.harits_dans_dugaar && <div className="bg-highlight">{row?.harits_dans_dugaar}</div>}
      </div>
    ),
    // filters: dansInfo,
    // onFilter: (value, record) => {
    //   if (
    //     // eslint-disable-next-line operator-linebreak
    //     record.dans_dugaar.indexOf(value) === 0 ||
    //     record.harits_dans_dugaar.indexOf(value) === 0
    //   ) {
    //     return true;
    //   }
    //   return false;
    // },
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    align: 'left',
    titleAlign: 'center',
    width: 290,
    render: (_, row) => (
      <div>
        <div>
          <Text ellipsis={{ tooltip: true }}>{row?.dans_ner}</Text>
        </div>
        {row?.harits_dans_dugaar &&<div className="bg-highlight">
          <Text ellipsis={{ tooltip: true }}>{row?.harits_dans_ner}</Text>
        </div>}
      </div>
    ),
  },
  {
    title: 'Огноо',
    dataIndex: 'ognoo',
    align: 'center',
    titleAlign: 'center',
    width: 80,
  },
  {
    title: 'Дүн',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Дебет',
        dataIndex: 'deb',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => (
          <div>
            <p>{roundFloat(row?.deb)}</p>
            {row?.harits_dans_dugaar && <p className="bg-highlight">{roundFloat(row?.harits_deb)}</p>}
          </div>
        ),
      },
      {
        title: 'Кредит',
        dataIndex: 'cred',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => (
          <div>
            <div>{roundFloat(row?.cred)}</div>
            {row?.harits_dans_dugaar && <div className="bg-highlight">{roundFloat(row?.harits_cred)}</div>}
          </div>
        ),
      },
    ],
  },
  {
    title: 'Төрөл',
    dataIndex: 'turul',
    align: 'left',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: (
        <Text ellipsis={{ tooltip: true }} className="cell-padding-5">
          {row?.turul}
        </Text>
      ),
    }),
  },
  // {
  //   title: 'Устгах',
  //   align: 'center',
  //   titleAlign: 'center',
  //   fixed: 'right',
  //   width: 50,
  //   render: (_, row) => (
  //     <div>
  //       <DeleteOutlined onClick={() => customDelete(row)} />
  //     </div>
  //   ),
  // },
];
