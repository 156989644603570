/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import { Row, Button as AntButton, Select, Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { Table } from 'src/components';
import { searchList } from './dataStructure/search';
import {
  ColumnsMain,
  customSummary,
  ColumnsMainShort,
  customSummaryShort,
} from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { AnglalModal } from './anglalModal';

const optionList = {
  mayagt_44: '44. Суудлын автомашин, түүний эд анги сэлбэгт төлсөн НӨАТ',
  mayagt_45: '45. Хувьдаа болон ажиллагсдын хэрэгцээнд зориулж худалдан авсан бараа, ажил, үйлчилгээнд төлсөн НӨАТ',
  mayagt_46: '46. Хуулийн 13 дугаар зүйлд заасан чөлөөлөгдөх үйлдвэрлэл, үйлчилгээнд зориулж импортолсон болон худалдаж авсан бараа, ажил, үйлчилгээнд төлсөн НӨАТ',
  mayagt_47: '47. Ашиглалтын өмнөх үйл ажиллагаанд зориулж импортоор оруулсан болон худалдан авсан бараа, ажил, үйлчилгээнд төлсөн НӨАТ',
  mayagt_48: '48. Тайлант хугацааны албан татвар ногдох бараа, ажил, үйлчилгээтэй хамааралгүй импортоор оруулсан болон худалдан авсан бараа, ажил, үйлчилгээнд төлсөн НӨАТ',
};

const Hudaldanavalt = () => {
  const dispatch = useDispatch();

  const query = useQuery();

  const { socket, data_date } = useSelector((state) => state);
  const [visible, setVisible] = useState({
    visible: false,
    type: '',
  });
  const [visibleEdit, seTvisibleEdit] = useState({
    visible: false,
    rowData: null,
  });
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });
  const [selectedRowKeys, seTselectedRowKeys] = useState([]);
  const [selectedType, seTselectedType] = useState('mayagt_44');

  const checkAll = tableData.result_list.length === selectedRowKeys.length;
  const indeterminate = tableData.result_list.length > 0
  && selectedRowKeys.length < tableData.result_list.length && selectedRowKeys.length !== 0;

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        seTselectedRowKeys([]);
        setTableData(data?.content);
        console.log(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const month = data_date?.month;
    const year = data_date?.year;
    const search = {
      hariltsagch: query.get('hariltsagch'),
      tohiruulga: query.get('tohiruulga'),
      zuruu: query.get('zuruu'),
      noat: query.get('noat'),
    };
    socket.emit('request_in', {
      channel: 'ss013_03_view',
      content: {
        action_type: 'hudaldan_avalt_page',
        month,
        page,
        size,
        search,
        year,
      },
    });
    dispatch(start_loading({}));
  };
  const saveSelected = (type) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        select_data();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss013_03_modify',
      content: {
        action_type: 'hudaldan_avalt_angilal',
        main_type: type,
        selected_list: selectedRowKeys,
        selected_type: selectedType,
        year: data_date.year,
      },
    });
    dispatch(start_loading({}));
  };
  const selectData = (row) => {
    // console.log(row);
    if (selectedRowKeys.indexOf(row.key) > -1) {
      // Key exists in the row, remove it from selectedRowKeys
      seTselectedRowKeys(selectedRowKeys.filter((selectedKey) => selectedKey !== row.key));
    } else {
      // Key doesn't exist in the row, add it to selectedRowKeys
      seTselectedRowKeys([...selectedRowKeys, row.key]);
    }
    // console.log(checkAll, indeterminate, tableData.result_list.length, selectedRowKeys.length);
  };
  const onCheckAllChange = () => {
    if (checkAll) { seTselectedRowKeys([]); } else {
      seTselectedRowKeys(tableData.result_list.map((item) => item.key));
    }
  };
  const onChangeSelect = (value) => {
    seTselectedType(value);
  };
  useEffect(() => {
    if (!visibleEdit.visible) {
      select_data();
    }
  }, [visibleEdit]);

  useEffect(() => {
    select_data();
  }, [data_date, query]);

  const title = () => (
    <Row>
      {indeterminate || checkAll ? (
        <>
          <AntButton
            className="add-btn"
            type="primary"
            size="small"
            onClick={() => {
              setVisible({ visible: true,
                type: 'hereg' });
            }}
          >
            Хэрэгжээгүй
          </AntButton>
          <AntButton
            className="add-btn"
            type="primary"
            size="small"
            onClick={() => {
              setVisible({ visible: true,
                type: 'hasag' });
            }}
          > Хасагдахгүй
          </AntButton>
          <AntButton
            className="add-btn"
            type="primary"
            size="small"
            onClick={() => {
              setVisible({ visible: true,
                type: 'hoish' });
            }}
          > Хойшлуулах
          </AntButton>
          <AntButton
            className="add-btn"
            type="primary"
            size="small"
            onClick={() => {
              setVisible({ visible: true,
                type: 'clear' });
            }}
          > Буцаах
          </AntButton>
        </>
      )
        : (
          <AntButton
            className="add-btn"
            type="primary"
            size="small"
          />
        )}
    </Row>
  );
  const onClickEdit = (row) => {
    seTvisibleEdit({
      visible: true,
      rowData: row,
    });
    console.log('row', row);
  };
  const mainTable = () => (
    <div className="table-antd-div no-scroll">
      {data_date?.month && (String(data_date?.month).split(',')).length === 1 ? (
        <Table
          isSearch
          searchList={searchList}
          title={title}
          columns={ColumnsMain({ selectData,
            checkAll,
            indeterminate,
            onClickEdit,
            onCheckAllChange,
            selectedRowKeys,
            optionList })}
          total={tableData.total_size}
          dataSource={tableData.result_list}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableData.total_list,
          }}
          scroll={{
            y: 'calc(100vh - 370px)',
          }}
        />
      ) : (
        <Table
          isSearch
          searchList={searchList}
          title={title}
          columns={ColumnsMainShort()}
          total={tableData.total_size}
          dataSource={tableData.result_list}
          summaryList={{
            sumColumn: customSummaryShort,
            sumDataSource: tableData.total_list,
          }}
          scroll={{
            y: 'calc(100vh - 370px)',
          }}
        />
      )}
    </div>
  );
  const handleCancel = () => {
    setVisible(false);
  };

  const handleCancelEdit = () => {
    seTvisibleEdit({
      visible: false,
      rowData: null,
    });
  };
  return (
    <>
      <AnglalModal
        closable={false}
        visibleEdit={visibleEdit}
        seTvisibleEdit={seTvisibleEdit}
        onCancel={handleCancelEdit}
        select={select_data}
        optionList
      />
      <Modal
        closable={false}
        visible={visible.visible}
        icon={<WarningOutlined />}
        okText="Тийм"
        cancelText="Үгүй"
        onOk={() => {
          saveSelected(visible.type);
          setVisible(false);
        }}
        className="noat-modal"
        onCancel={handleCancel}
      >
        <>
          {visible.type === 'hasag' ? (
            <div>
              <h1>Хасагдахгүй НӨАТ-ын ангилалд тохируулах үү?</h1>
              <Select
                value={selectedType}
                style={{ width: 300, marginTop: 20 }}
                placeholder="Төрөл сонго уу?"
                onChange={onChangeSelect}
              >
                {Object.keys(optionList).map((key) => (
                  <Select.Option key={key} value={key}>
                    {optionList[key]}
                  </Select.Option>
                ))}
              </Select>
            </div>
          ) : visible.type === 'hoish' ? (
            <div>
              <h1>Хойшлуулах НӨАТ-ын ангилалд тохируулах үү?</h1>
            </div>
          ) : visible.type === 'hereg' ? (
            <div>
              <h1>Хэрэгжээгүй НӨАТ-ын ангилалд тохируулах үү?</h1>
            </div>
          ) : (
            <div>
              <h1>НӨАТ-ын ангиллыг буцаах үү??</h1>
            </div>
          )}
        </>
      </Modal>
      { mainTable()}
    </>
  );
};

export default Hudaldanavalt;
