import React from 'react';
import { Row, Col } from 'antd';
import { roundFloat } from 'src/core/utils/formater';

export const guilgeeColumns = [
  {
    dataIndex: 'turul_name',
    key: 'text',
    align: 'center',
  },
];

export const expandColumns = [
  { title: '№', dataIndex: 'id', key: 'id' },
  { title: 'Тайлбар', dataIndex: 'desc', key: 'desc', width: '40vw' },
  { title: 'Мөр', dataIndex: 'mur', key: 'mur', align: 'center', width: '5vw' },
  {
    title: 'Тооцоолол',
    dataIndex: 'name',
    textAlign: 'left',
    key: 'name',
    width: '20vw',
    render: (_, { tootsoolol_list = [] }) => ({
      children: (
        <div
          style={{
            width: 500,
          }}
        >
          {tootsoolol_list.map((i, idx) => (
            <Row className="fs-12">
              <Col
                className={`border-row right ${
                  idx === tootsoolol_list.length - 1 ? 'last' : ''
                }`}
                span={19}
              >
                {i.name}
              </Col>
              <Col
                span={5}
                className={`border-row center ${
                  idx === tootsoolol_list.length - 1 ? 'last' : ''
                }`}
              >
                {i.dun}
              </Col>
            </Row>
          ))}
        </div>
      ),
      props: {
        colSpan: 2,
      },
    }),
  },
  {
    title: 'Дүн',
    dataIndex: 'dun',
    key: 'dun',
    width: '12vw',
    render: (_) => roundFloat(_),
  },
];
