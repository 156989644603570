import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table as AntTable } from 'antd';

import { roundFloat, useQuery } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import Table from 'src/components/common/table';
import newAlert from 'src/components/newAlert';
import { dans_columns } from '../dataStructure/data';

export default function TabDans() {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [totalData, setTotalData] = useState(5);

  const handleSelectDans = (dans_code) => {
    const propName = 'dans_code';
    const propName2 = 'tab';
    const proplist = ['Hariltsagch', 'page2', 'size2', 'page3', 'size3'];
    if (query.get(propName)) {
      query.set(propName, dans_code);
    } else {
      query.append(propName, dans_code);
    }
    if (query.get(propName2)) {
      query.set(propName2, '2');
    } else {
      query.append(propName2, '2');
    }
    proplist.forEach((name) => {
      if (query.get(name)) {
        query.delete(name);
      }
    });
    history.push({ search: query.toString() });
  };

  useEffect(() => {
    const fetchData = async (page, size) => {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');

        if (data.content.status === '0') {
          setTableData(data.content.json_array);
          setTotalData(data.content.total);
          setTableDataSum(data.content.sum_dict);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }

        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts002_00_deferred_tax',
        content: {
          action_type: 'deferred_tax_dansaar',
          type: 'hurungu',
          month: data_date.month,
          page: page || 1,
          size: size || 20,
        },
      });
      dispatch(start_loading({}));
    };
    const page = query.get('page');
    const size = query.get('size');

    if (query.get('tab')) {
      if (query.get('tab') === '1') {
        fetchData(page, size);
      }
    } else fetchData(page, size);
  }, [query, data_date]);

  return (
    <div className="table-wrapper">
      <div className="inside-table no-scroll">
        <Table
          columns={dans_columns(handleSelectDans)}
          total={totalData}
          dataSource={tableData}
          scroll={{
            y: 'calc(100vh - 330px)',
          }}
          customize={() => (
            <AntTable.Summary fixed>
              <AntTable.Summary.Row>
                <AntTable.Summary.Cell align="left">
                  Хойшлогдсон татварын хөрөнгийн дүн:
                </AntTable.Summary.Cell>
                <AntTable.Summary.Cell align="right">
                  {roundFloat(tableDataSum.eh_uld_deferred_tax)}
                </AntTable.Summary.Cell>
                <AntTable.Summary.Cell align="right">
                  {roundFloat(tableDataSum.zalruulga)}
                </AntTable.Summary.Cell>
                <AntTable.Summary.Cell align="right">
                  {roundFloat(tableDataSum.deb_deferred_tax)}
                </AntTable.Summary.Cell>
                <AntTable.Summary.Cell align="right">
                  {roundFloat(tableDataSum.cred_deferred_tax)}
                </AntTable.Summary.Cell>
                <AntTable.Summary.Cell align="right">
                  {roundFloat(tableDataSum.ets_uld_deferred_tax)}
                </AntTable.Summary.Cell>
              </AntTable.Summary.Row>
            </AntTable.Summary>
          )}
        />
      </div>
    </div>
  );
}
