import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { DeleteOutlined } from '@ant-design/icons';
import { Button as AntButton } from 'antd';
import CanView from 'src/components/permission/canview';
import { customConfirm } from 'src/components/common/confirmModal';

export const customSummary = [
  {},
  {},
  {},
  {
    align: 'right',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'too_shirheg',
  },
  {
    align: 'right',
    value: 'urtug',
  },
  {
    align: 'right',
    value: 'huramt_elegdel',
  },
];

export const customDansSummary = [
  {
    align: 'right',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'too_shirheg',
  },
  {
    align: 'right',
    value: 'urtug',
  },
  {
    align: 'right',
    value: 'huramt_elegdel',
  },
];

export const ColumnsMain = ({ onSelectHurungu = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    align: 'left',
    width: 120,
    fixed: 'left',
    render: (_, row) => (
      <AntButton
        align="center"
        type="link"
        size="small"
        className="underline"
        onClick={() => onSelectHurungu(row)}
      >
        {row?.code}
      </AntButton>
    ),
  },
  {
    title: 'Хөрөнгийн нэр',
    dataIndex: 'ner',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Хэмжих нэгж',
    dataIndex: 'hemjih_negj',
    align: 'center',
    width: 100,
  },
  {
    title: 'Эхний үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'too_shirheg',
        align: 'right',
        width: 80,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Өртөг',
        dataIndex: 'urtug',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Хуримтлагдсан элэгдэл',
        dataIndex: 'huramt_elegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];

export const ColumnsDansMain = () => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Эхний үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'too_shirheg',
        align: 'right',
        width: 70,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Өртөг',
        dataIndex: 'urtug',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Хуримтлагдсан элэгдэл',
        dataIndex: 'huramt_elegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];

export const ColumnsBurtgel = () => [
  {
    title: 'Бүртгэлийн дугаар',
    dataIndex: 'burtgel_dugaar',
    width: 150,
    align: 'left',
    fixed: 'left',
  },
  {
    title: 'Эхний үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'too_shirheg',
        align: 'right',
        width: 80,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Өртөг',
        dataIndex: 'urtug',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Хуримтлагдсан элэгдэл',
        dataIndex: 'huramt_elegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];

export const UsgalZasvarAllColumns = ({ deleteUrgalZasvar = () => {} }) => [
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    align: 'left',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Хөрөнгийн нэр',
    dataIndex: 'ner',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Бүртгэлийн дугаар',
    dataIndex: 'burtgel_dugaar',
    align: 'left',
    width: 150,
  },
  {
    title: 'Татварын зорилгоор',
    children: [
      {
        title: 'Ашиглаж эхэлсэн огноо',
        dataIndex: 'ashiglaj_ehelsen',
        align: 'left',
        width: 150,
      },
      {
        title: 'Анхны өртөг',
        dataIndex: 'urtug_anh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Өөрчлөлтийн',
    children: [
      {
        title: 'Хөрөнгийн төрөл',
        dataIndex: 'hurungu_turul',
        align: 'left',
        width: 150,
        ellipsis: true,
      },
      {
        title: 'Огноо',
        dataIndex: 'ognoo',
        align: 'center',
        width: 75,
      },
      {
        title: 'Их засвар / Урсгал засвар',
        dataIndex: 'type_ner',
        align: 'center',
        width: 150,
        // ellipsis: true,
      },
      {
        title: 'Дүн',
        dataIndex: 'dun',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Урсгал засварын хэтрэлт',
        dataIndex: 'cap_dun',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            <div>{row?.cap_dun ? roundFloat(row?.cap_dun) : ''}</div>
          </>
        ),
      },
    ],
  },
  {
    title: 'Устгах',
    align: 'center',
    width: 50,
    render: (_, row) => (
      <>
        <CanView allowedRole="006_1">
          {![0, ''].includes(row?.id) && (
            <DeleteOutlined
              size="small"
              onClick={
                () => customConfirm({ onOk: () => deleteUrgalZasvar(row?.id) })
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          )}
        </CanView>
      </>
    ),
  },
];

export const ZalruulgaAllColumns = ({ delete_id = () => {} }) => [
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    align: 'left',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Хөрөнгийн нэр',
    dataIndex: 'ner',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Бүртгэлийн дугаар',
    dataIndex: 'burtgel_dugaar',
    align: 'left',
    width: 150,
  },
  {
    title: 'Залруулагдсан',
    children: [
      {
        title: 'Огноо',
        dataIndex: 'ognoo',
        align: 'left',
        width: 75,
      },
      {
        title: 'Өртгийн дүн',
        dataIndex: 'dun_nemeh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Хуримтлагдсан элэгдлийн дүн',
        dataIndex: 'dun_nemeh_elegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Устгах',
    align: 'center',
    width: 50,
    render: (_, row) => (
      <>
        <CanView allowedRole="006_1">
          {[0, ''].includes(row?.dahin_id) && (
            <DeleteOutlined
              size="small"
              onClick={
                () => customConfirm({ onOk: () => delete_id(row?.dahin_id) })
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          )}
        </CanView>
      </>
    ),
  },
];
