import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { Button } from 'antd';

export const dans_columns = ({ handleSelectDans = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_code',
    align: 'left',
    width: 250,
    fixed: 'left',
    render: (_, row) => (
      <Button
        type="link"
        size="small"
        className="underline"
        onClick={() => handleSelectDans(row?.dans_code, row?.tur_zuruu_turul)}
      >
        {row?.dans_code}
      </Button>
    ),
  },
  {
    title: 'Түр зөрүүгийн төрөл',
    dataIndex: 'tur_zuruu_turul',
    align: 'center',
    width: 200,
    ellipsis: true,
    render: (_, row) => (
      <>{row?.dans_code !== '' ? <div>{row?.tur_zuruu_turul} </div> : null}</>
    ),
  },
  {
    title: 'ГВХЗ Бодит',
    align: 'center',
    children: [
      {
        title: 'Ашиг',
        dataIndex: 'ashig',
        align: 'right',
        width: 150,
        render: (_, row) => <>{roundFloat(row?.ashig)}</>,
      },
      {
        title: 'Алдагдал',
        dataIndex: 'aldagdal',
        align: 'right',
        width: 150,
        render: (_, row) => <>{roundFloat(row?.aldagdal)}</>,
      },
    ],
  },
  {
    title: 'Эхний үлдэгдэл',
    dataIndex: 'ehnii_uldegdel',
    align: 'right',
    width: 150,
    render: (_, row) => (
      <>
        {row?.is_foreign ? (
          <div>
            <p>{roundFloat(row?.ehnii_uldegdel)}</p>
            <p className="bg-highlight">
              {roundFloat(row?.ehnii_uldegdel_valiut)}
            </p>
          </div>
        ) : (
          roundFloat(row?.ehnii_uldegdel)
        )}
      </>
    ),
  },
  {
    title: 'Гүйлгээ',
    align: 'center',
    children: [
      {
        title: 'Дебет',
        dataIndex: 'deb',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            {row?.is_foreign ? (
              <div>
                <p>{roundFloat(row?.deb)}</p>
                <p className="bg-highlight">{roundFloat(row?.deb_valiut)}</p>
              </div>
            ) : (
              roundFloat(row?.deb)
            )}
          </>
        ),
      },
      {
        title: 'Кредит',
        dataIndex: 'cred',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            {row?.is_foreign ? (
              <div>
                <p>{roundFloat(row?.cred)}</p>
                <p className="bg-highlight">{roundFloat(row?.cred_valiut)}</p>
              </div>
            ) : (
              roundFloat(row?.cred)
            )}
          </>
        ),
      },
    ],
  },
  {
    title: 'Эцсийн үлдэгдэл',
    dataIndex: 'etssiin_uldegdel',
    align: 'right',
    width: 150,
    render: (_, row) => (
      <>
        {row?.is_foreign ? (
          <div>
            <p>{roundFloat(row?.etssiin_uldegdel)}</p>
            <p className="bg-highlight">
              {roundFloat(row?.etssiin_uldegdel_valiut)}
            </p>
          </div>
        ) : (
          roundFloat(row?.etssiin_uldegdel)
        )}
      </>
    ),
  },
];

export const hariltsagch_columns = ({ handleOpenModal = () => {} }) => [
  {
    title: 'Харилцагч',
    align: 'center',
    children: [
      {
        title: 'Код',
        dataIndex: 'hariltsagch_code',
        align: 'left',
        width: 150,
        fixed: 'left',
        render: (_, row) => (
          <Button
            size="small"
            type="link"
            className="underline"
            onClick={() => {
              handleOpenModal(row?.hariltsagch_code);
            }}
          >
            {row?.hariltsagch_code}
          </Button>
        ),
      },
      {
        title: 'Нэр',
        dataIndex: 'hariltsagch_ner',
        align: 'left',
        width: 250,
        ellipsis: true,
        fixed: 'left',
      },
    ],
  },
  {
    title: 'ГВХЗ Бодит',
    align: 'center',
    children: [
      {
        title: 'Ашиг',
        dataIndex: 'ashig',
        align: 'right',
        width: 150,
        render: (_, row) => <>{roundFloat(row?.ashig)}</>,
      },
      {
        title: 'Алдагдал',
        dataIndex: 'aldagdal',
        width: 150,
        align: 'right',
        render: (_, row) => <>{roundFloat(row?.aldagdal)}</>,
      },
    ],
  },
  {
    title: 'Эхний үлдэгдэл',
    dataIndex: 'ehnii_uldegdel',
    align: 'right',
    width: 150,
    render: (_, row) => (
      <>
        {row?.is_foreign ? (
          <div>
            <p>{roundFloat(row?.ehnii_uldegdel)}</p>
            <p className="bg-highlight">
              {roundFloat(row?.ehnii_uldegdel_valiut)}
            </p>
          </div>
        ) : (
          roundFloat(row?.ehnii_uldegdel)
        )}
      </>
    ),
  },
  {
    title: 'Гүйлгээ',
    align: 'center',
    children: [
      {
        title: 'Дебет',
        dataIndex: 'deb',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            {row?.is_foreign ? (
              <div>
                <p>{roundFloat(row?.deb)}</p>
                <p className="bg-highlight">{roundFloat(row?.deb_valiut)}</p>
              </div>
            ) : (
              roundFloat(row?.deb)
            )}
          </>
        ),
      },
      {
        title: 'Кредит',
        dataIndex: 'cred',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            {row?.is_foreign ? (
              <div>
                <p>{roundFloat(row?.cred)}</p>
                <p className="bg-highlight">{roundFloat(row?.cred_valiut)}</p>
              </div>
            ) : (
              roundFloat(row?.cred)
            )}
          </>
        ),
      },
    ],
  },
  {
    title: 'Эцсийн үлдэгдэл',
    dataIndex: 'etssiin_uldegdel',
    align: 'right',
    width: 150,
    render: (_, row) => (
      <>
        {row?.is_foreign ? (
          <div>
            <p>{roundFloat(row?.etssiin_uldegdel)}</p>
            <p className="bg-highlight">
              {roundFloat(row?.etssiin_uldegdel_valiut)}
            </p>
          </div>
        ) : (
          roundFloat(row?.etssiin_uldegdel)
        )}
      </>
    ),
  },
];
