import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Form, Row, Col, Input, Button, Divider } from 'antd';
import { Table } from 'src/components';
import { useQuery, roundFloat, onlyNumber } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import { customColumns, customSummary } from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';

const MainPage = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);

  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState({
    json_array: [],
    sum_data: {},
    total: 0,
  });

  const generateTailan = useCallback((query_data) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setTableData(data.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    const page = query_data.get('page');
    const size = query_data.get('size');
    const search = {};

    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: {
        action_type: 'get_dans_dun_list',
        month: 'ets',
        first_year: true,
        paging: { page, size, search },
      },
    });

    dispatch(start_loading({}));
  }, []);

  const saveValues = (saveData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan(query);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: {
        action_type: 'set_dans_dun',
        month: 'ets',
        data: saveData,
        first_year: true,
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    generateTailan(query);
  }, [query, generateTailan]);

  const [form] = Form.useForm();

  const edit = (row) => {
    form.setFieldsValue({
      dans_id: row?.dans_id,
      valiut_torol: row?.valiut_torol,
      dans_dun_deb: roundFloat(row?.dans_dun_deb),
      dans_dun_cred: roundFloat(row?.dans_dun_cred),
      dans_dun_valiut_deb: roundFloat(row?.dans_dun_valiut_deb),
      dans_dun_valiut_cred: roundFloat(row?.dans_dun_valiut_cred),
    });

    setVisible(true);
  };

  const title = () => (
    <>
      <div className="table-title-text-center">Дансны үнэ - Эхний үлдэгдэл</div>
    </>
  );

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          columns={customColumns({
            customEdit: edit,
            version,
          })}
          dataSource={tableData?.json_array}
          scroll={{
            y: 'calc(100vh - 300px)',
          }}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableData?.sum_data,
          }}
          total={tableData?.total}
          title={title}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Эхний үлдэгдлийн дүн засах"
        open={visible}
        closable={false}
        maskClosable={false}
        width={400}
        footer={null}
      >
        <CustomModal
          onClose={() => {
            setVisible(false);
          }}
          form={form}
          saveValues={saveValues}
        />
      </Modal>
    </>
  );
};

const layout = {
  labelCol: {
    xs: { span: 9 },
    sm: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 15 },
    sm: { span: 15 },
  },
};

const CustomModal = ({ onClose = () => {}, form = null, saveValues }) => {
  const onFinish = (values) => {
    saveValues(values);
    onClose();
  };

  const onCancel = () => {
    onClose();
  };
  const isHide = form.getFieldValue('valiut_torol') === 'MNT';
  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="dans_id" hidden />
        <Form.Item name="valiut_torol" hidden />
        {/* <Divider>Төгрөгийн дүн</Divider> */}
        <Form.Item
          name="dans_dun_deb"
          label="Дебет /төгрөг/"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" />
        </Form.Item>
        <Form.Item
          name="dans_dun_cred"
          label="Кредит /төгрөг/"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" />
        </Form.Item>
        <Divider>Валютын дүн</Divider>
        <Form.Item
          name="dans_dun_valiut_deb"
          label="Дебет /валют/"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" disabled={isHide} />
        </Form.Item>
        <Form.Item
          name="dans_dun_valiut_cred"
          label="Кредит /валют/"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" disabled={isHide} />
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MainPage;
