import React from 'react';
import { Form, Input, Row, Col, Button, Select } from 'antd';
import DatePicker from 'src/components/common/datePicker';
import { onlyNumber, replaceFloat } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';

const { TextArea } = Input;
const { Option } = Select;
const NewValueAdd = ({
  form = null,
  onClose = () => {},
  socket = null,
  refetch = () => {},
  dispatch = null,
  yoslolTypes = [],
}) => {
  const onFinish = (values) => {
    console.log('values ', values);
    const { dun } = values;
    const new_temp_data = values;
    new_temp_data.dun = replaceFloat(dun);

    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: [refetch(), onClose(), form.resetFields()] },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_service_yoslol_hundetgel',
      content: {
        array_data: new_temp_data,
        action_type: 'add_yoslol_data',
      },
    });
    dispatch(start_loading({}));
  };

  return (
    <>
      <Form
        form={form}
        name="add"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 25 }}
        size="small"
        onFinish={onFinish}
      >
        <Form.Item
          label="Огноо"
          name="ognoo"
          rules={[{ required: true, message: 'Заавал бөглөх' }]}
        >
          <DatePicker customClass="h-25" />
        </Form.Item>

        <Form.Item
          label="Орлогын төрөл"
          name="orlogiin_torol"
          rules={[{ required: true, message: 'Заавал бөглөх' }]}
        >
          <Select
            placeholder="Сонгох"
            style={{ width: '50%', fontSize: 13 }}
            onChange={(val) => {
              if (val === 1) {
                form.setFieldsValue({
                  gereenii_torol: yoslolTypes[0]?.yoslol_torol_id,
                });
              }
            }}
          >
            {[
              {
                value: 1,
                text: 'Цалин хөлс',
              },
              {
                value: 2,
                text: 'Шууд бус орлого',
              },
            ].map((i) => (
              <Option value={i?.value}>{i.text}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const orlogiin_torol = getFieldValue('orlogiin_torol');
            if (orlogiin_torol === 1) {
              return (
                <Form.Item name="gereenii_torol" label="Гэрээний төрөл">
                  <Select
                    placeholder="Сонгох"
                    style={{ width: '50%', fontSize: 13 }}
                  >
                    {yoslolTypes.length > 0 &&
                      yoslolTypes.map((i) => (
                        <Option value={i?.id}>{i?.value}</Option>
                      ))}
                  </Select>
                </Form.Item>
              );
            }
          }}
        </Form.Item>
        <Form.Item
          label="Цалингийн зардлын дүн"
          name="dun"
          rules={[{ required: true, message: 'Заавал бөглөх' }]}
          normalize={onlyNumber}
        >
          <Input className="ww-90" />
        </Form.Item>
        <Form.Item label="Тайлбар:" name="tailbar" labelAlign="right">
          <TextArea bordered rows={4} maxLength={300} showCount />
        </Form.Item>
      </Form>
      <Row>
        <Col span={24} className="text-align-r">
          <Form.Item noStyle>
            <Button
              className="cancel-btn"
              type="primary"
              shape="round"
              size="small"
              onClick={onClose}
            >
              Буцах
            </Button>
            <Button
              form="add"
              className="confirm-btn ml-5"
              type="primary"
              shape="round"
              htmlType="submit"
              size="small"
            >
              Хадгалах
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default NewValueAdd;
