/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Form, Button } from 'antd';
import VerificationInput from 'react-verification-input';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { useDispatch, useSelector } from 'react-redux';

const VertificationDeviceModal = ({ submitCode = () => {}, jwtToken }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { socket } = useSelector((state) => ({
    socket: state?.socket,
    jwt_token: state?.jwt_token,
  }));

  const submit = (value) => {
    submitCode(value?.verification_code);
  };

  const reSend = () => {
    socket.on('signin_rsp', (data) => {
      socket.removeAllListeners('signin_rsp');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: data?.content?.Message });
      } else {
        newAlert({ type: 'error', msg: data?.content?.Message });
      }

      dispatch(end_loading({}));
    });
    socket.emit('device_verify', {
      content: {
        action_type: 'regenerate_device_otp',
      },
      tag: { jwt_1: jwtToken, socket_id: socket.id },
    });
    dispatch(start_loading({}));
  };

  return (
    <div className="device-inside">
      <p className="ml-15">Та энэ төхөөрөмжөөс анх удаа хандаж байна.</p>
      <p className="ml-15">
        Таны имэйл хаяг руу нэг удаагийн баталгаажуулах код илгээлээ
      </p>
      <Form form={form} onFinish={submit} className="new-login">
        <Form.Item name="verification_code">
          <VerificationInput
            autoFocus
            validChars="0-9"
            removeDefaultStyles
            classNames={{
              container: 'custom-input-container',
              character: 'custom-input-character',
              characterInactive: 'custom-input-character--inactive',
              characterSelected: 'custom-input-character--selected',
            }}
          />
        </Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          className="custom-verify-button"
        >
          Нэвтрэх
        </Button>
        <p className="re-send" onClick={reSend}>
          Имэйл дахин илгээх
        </p>
      </Form>
    </div>
  );
};

export default VertificationDeviceModal;
