const oDefaultState = {
	bOpenDialog: false,
	oActionData: {}
};

const reducer = (state = oDefaultState, action) => {
	switch (action.type) {
		case "tax_shalgah_balance_dialog_open":
			const oNewState1 = {
				...state,
				bOpenDialog: true,
				oActionData: action.payload,
			};
			return oNewState1;
		case "tax_shalgah_balance_dialog_close":
			const oNewState2 = {
				oActionData:{},
				bOpenDialog: false,
			};
			return oNewState2;
		case "tax_shalgah_balance_reset":
			return oDefaultState;
		case "tax_shalgah_balance_dialog_set_id":
			const oNewState3 = {
				...state,
				oActionData: action.payload,
			};
			return oNewState3;
		default:
			return state;
	}
};

export default reducer;
