import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { DeleteOutlined } from '@ant-design/icons';

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'tatvar_dans_dun',
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'tatvar_nogdoh_dun',
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'tatvar_suutgah_dun',
  },
  {
    align: 'right',
    value: 'tatvar_suutgaagui_dun',
  },
  {
    value: null,
  },
];

export const customColumns = ({ customDelete = () => {} }) => [
  {
    title: 'Д/Д',
    dataIndex: 'counter',
    align: 'center',
    titleAlign: 'center',
    width: 50,
  },
  {
    title: 'Суутгагч аж ахуйн нэгжийн',
    titleAlign: 'center',
    children: [
      {
        title: 'Нэр',
        dataIndex: 'ner',
        align: 'left',
        titleAlign: 'center',
        width: 150,
      },
      {
        title: 'Регистрийн дугаар',
        dataIndex: 'ttd',
        align: 'left',
        titleAlign: 'center',
        width: 100,
      },
    ],
  },
  {
    title: 'Орлогын төрөл',
    dataIndex: 'tatvar_dans',
    align: 'left',
    titleAlign: 'center',
    width: 385,
  },
  {
    title: 'Татвар ногдуулах орлого',
    dataIndex: 'tatvar_dans_dun',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.tatvar_dans_dun),
    }),
  },
  {
    title: 'Ногдуулах албан татвар',
    titleAlign: 'center',
    children: [
      {
        title: 'Хувь',
        dataIndex: 'tatvar_nogdoh_huvi',
        align: 'right',
        titleAlign: 'center',
        width: 80,
        render: (_, row) => ({
          children: roundFloat(row?.tatvar_nogdoh_huvi),
        }),
      },
      {
        title: 'Дүн',
        dataIndex: 'tatvar_nogdoh_dun',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.tatvar_nogdoh_dun),
        }),
      },
    ],
  },
  {
    title: 'Татвар суутгагдсан эсэх',
    dataIndex: 'tatvar_suutgah',
    align: 'center',
    titleAlign: 'center',
    width: 100,
  },
  {
    title: 'Суутгуулсан татвар',
    dataIndex: 'tatvar_suutgah_dun',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.tatvar_suutgah_dun),
    }),
  },
  {
    title: 'Суутгуулаагүй татвар',
    dataIndex: 'tatvar_suutgaagui_dun',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.tatvar_suutgaagui_dun),
    }),
  },
  {
    title: 'Устгах',
    align: 'center',
    titleAlign: 'center',
    width: 60,
    render: (_, row) => {
      if (row?.can_delete === true) {
        return {
          children: (
            <div>
              <DeleteOutlined onClick={() => customDelete(row)} />
            </div>
          ),
        };
      }
      return '';
    },
  },
];
