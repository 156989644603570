import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Form, Row, Col, Input, Button } from 'antd';
import { PicLeftOutlined } from '@ant-design/icons';
import { Table } from 'src/components';
import { useQuery, roundFloat, onlyNumber } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import { customColumns, customSummary } from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';

const GuilgeeBalanceTailan = () => {
  const dispatch = useDispatch();
  const channel_list = {
    uh: {
      send_channel: 'ts003_api',
      receive_channel: 'ts001_02_tur_zuruu_control',
      action_type: 'set_tatvar_suuri_uh',
    },
    avug: {
      send_channel: 'send_tatvar_suuri_by_account',
      receive_channel: 'ts001_02_tur_zuruu_control',
      action_type: 'set_tatvar_suuri_avug',
    },
    mh: {
      send_channel: 'ts001_02_tur_zuruu_control',
      receive_channel: 'request_out',
      action_type: 'set_tatvar_suuri_mh',
    },
  };

  const query = useQuery();
  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);
  const data_date = { month: '13' };

  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState({
    json_array: [],
    sum_data: {},
    total: 0,
  });

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setTableData(data.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const search = {};

    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: {
        action_type: 'get_gb_tatvar',
        month_start: '1',
        month_end: String(month),
        first_year: true,
        paging: { page, size, search },
      },
    });

    dispatch(start_loading({}));
  };

  const saveValues = (saveData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan(data_date.month);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: {
        action_type: 'set_dans_ts_dun',
        month: String(data_date.month),
        data: saveData,
        first_year: true,
      },
    });

    dispatch(start_loading({}));
  };

  const getValues = (action) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хуулагдлаа' });
        generateTailan(data_date.month);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: action?.send_channel,
      content: {
        return_channel: action?.receive_channel,
        month: String(data_date.month),
        action_type: action?.action_type,
        first_year: true,
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
  }, [query]);

  const [form] = Form.useForm();

  const editAction = (row) => {
    form.setFieldsValue({
      dans_id: row?.dans_id,
      deb: roundFloat(row?.dun_ets_deb),
      cred: roundFloat(row?.dun_ets_cred),
    });
    setVisible(true);
  };

  const title = () => (
    <>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<PicLeftOutlined />}
        disabled={!version?.is_first_year}
        onClick={() => {
          getValues(channel_list?.avug);
        }}
      >
        Дүн хуулах /Харилцагч/
      </Button>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<PicLeftOutlined />}
        disabled={!version?.is_first_year}
        onClick={() => {
          getValues(channel_list?.uh);
        }}
      >
        Дүн хуулах /Үндсэн хөрөнгө/
      </Button>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<PicLeftOutlined />}
        disabled={!version?.is_first_year}
        onClick={() => {
          getValues(channel_list?.mh);
        }}
      >
        Дүн хуулах /Мөнгөн хөрөнгө/
      </Button>
    </>
  );

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          columns={customColumns({
            customEdit: editAction,
            version,
          })}
          dataSource={tableData?.json_array}
          scroll={{
            y: 'calc(100vh - 300px)',
          }}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableData?.sum_data,
          }}
          title={title}
          total={tableData?.total}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Татварын суурь дүн засах"
        visible={visible}
        closable={false}
        maskClosable={false}
        width={400}
        footer={null}
      >
        <CustomModal
          onClose={() => {
            setVisible(false);
          }}
          form={form}
          saveValues={saveValues}
        />
      </Modal>
    </>
  );
};

const layout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
  },
};

const CustomModal = ({
  onClose = () => {},
  form = null,
  saveValues = () => {},
}) => {
  const onFinish = (values) => {
    saveValues(values);
    onClose();
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="dans_id" hidden />
        <Form.Item
          name="deb"
          label="Дебет"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" />
        </Form.Item>
        <Form.Item
          name="cred"
          label="Кредит"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" />
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default GuilgeeBalanceTailan;
