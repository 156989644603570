import React, { useState } from 'react';
import { Form, Input, Row, Col, Button, Upload, message } from 'antd';
import DatePicker from 'src/components/common/datePicker';
import { onlyNumber, replaceFloat } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { CloudUploadOutlined } from '@ant-design/icons';
import axios from 'src/core/utils/axios';
import { typesList } from 'src/core/utils/filetypes';
import CanView from 'src/components/permission/canview';
import newAlert from 'src/components/newAlert';

const { TextArea } = Input;

const NewValueAdd = ({
  form = null,
  onClose = () => {},
  socket = null,
  refetch = () => {},
  dispatch = null,
}) => {
  const [error, seTerror] = useState(false);
  const gamshig_data = form.getFieldValue();

  const submit = (confirmData) => {
    const new_temp_data = confirmData;

    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: {
          onSuccess: [form.resetFields(), onClose(), refetch()],
        },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_service_gamshig',
      content: {
        json_array: new_temp_data,
        action_type: 'add_gamshig_data',
      },
    });
  };
  const onFinish = (values) => {
    console.log('=========onfinish==========');
    values.tailbar = values.tailbar !== undefined ? values.tailbar : '';
    const { file, ..._values } = values;
    _values.hohirol_dun = replaceFloat(_values.hohirol_dun);
    if (file !== undefined) {
      const { size: file_size } = file[0];
      socket.on('request_out', async ({ status, content: return_data }) => {
        socket.removeAllListeners('request_out');
        if (status === '0') {
          const formData = new FormData();
          formData.append('file', values.file[0].originFileObj);
          const { status: file_status, content } = await axios.post(
            'attach',
            '/file_upload',
            formData,
            {
              file_size,
            }
          );
          if (file_status === '0') {
            const { json_array } = content;
            const confirmData = {
              file_name: json_array?.received_file_name,
              file_id: json_array?.file_id,
              file_size: json_array?.file_size_mb,
              ..._values,
            };
            submit(confirmData);
          } else {
            dispatch(end_loading({}));
            newAlert({
              type: file_status === '0' ? 'success' : 'error',
              msg: content.Message,
            });
          }
        } else {
          dispatch(end_loading({}));
          newAlert({
            type: status === '0' ? 'success' : 'error',
            msg: return_data.Message,
          });
        }
      });
      socket.emit('request_in', {
        channel: 'ss005_company_manage',
        content: {
          action_type: 'check_company_storage',
          file_size,
        },
      });
      dispatch(start_loading({}));
    } else {
      const confirmData = {
        file_name: '',
        file_id: '',
        ..._values,
      };
      submit(confirmData);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <>
      <Form
        form={form}
        name="add"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 25 }}
        size="small"
        onFinish={onFinish}
      >
        <Form.Item
          label="Огноо"
          name="ognoo"
          rules={[{ required: true, message: '' }]}
        >
          <DatePicker customClass="h-25" />
        </Form.Item>
        <Form.Item
          label="Дүгнэлт гаргасан байгууллагын нэр"
          name="dugnelt_gargasan_company_ner"
          rules={[{ required: true, message: '' }]}
        >
          <Input className="ww-90" />
        </Form.Item>
        <Form.Item
          label="Хохирлын дүн"
          name="hohirol_dun"
          normalize={onlyNumber}
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input className="ww-90" />
        </Form.Item>
        <Form.Item label="Тайлбар:" name="tailbar" labelAlign="right">
          <TextArea bordered rows={4} maxLength={300} showCount />
        </Form.Item>
        {gamshig_data &&
        Object.keys(gamshig_data).length === 0 &&
        Object.getPrototypeOf(gamshig_data) === Object.prototype ? (
          <CanView allowedRole="001_1">
            <Form.Item
              label="Дүгнэлт хавсаргах"
              name="file"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                accept={typesList.join()}
                showUploadList
                maxCount={1}
                onRemove={() => {
                  seTerror(false);
                }}
                beforeUpload={(File) => {
                  if (File?.size / 1000000 < 100) {
                    const isXls = typesList.find((i) => i === File.type);
                    if (!isXls) {
                      message.error(
                        'Зөвхөн xls, word, pdf, jpeg, png  өргөтгөлтэй файл оруулна уу!'
                      );
                      seTerror(true);
                    } else {
                      seTerror(false);
                    }
                  } else {
                    message.error('Файлын хэмжээ 100MB аас хэтрэхгүй байх !');
                    seTerror(true);
                  }
                  return false;
                }}
              >
                <CloudUploadOutlined className="fs-30" />
              </Upload>
            </Form.Item>
          </CanView>
        ) : (
          <></>
        )}
      </Form>
      <Row>
        <Col span={24} className="text-align-r">
          <Form.Item noStyle>
            <Button
              className="cancel-btn"
              type="primary"
              shape="round"
              onClick={onClose}
            >
              Буцах
            </Button>
            <Button
              form="add"
              disabled={error}
              className="confirm-btn ml-5"
              type="primary"
              shape="round"
              htmlType="submit"
            >
              Хадгалах
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default NewValueAdd;
