import React from 'react';
import { Form, Input, Row, Col } from 'antd';
import { phoneNumber } from 'src/core/utils/formater';
import { LockOutlined } from '@ant-design/icons';

const iconBody = {
  style: {
    color: '#d9d9d9',
  },
  className: 'mr-10',
};

const lockIcon = <LockOutlined {...iconBody} />;
export const EditProfile = () => (
  <>
    <Row className="ww-100">
      <Col xs={{ span: 24 }} sm={{ span: 12 }} className="gutter">
        <Form.Item name="lastname" label="Овог">
          <Input placeholder="Thomas D." />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} className="gutter left">
        <Form.Item name="firstname" label="Нэр">
          <Input placeholder="Hardison" />
        </Form.Item>
      </Col>
      <Col />
    </Row>
    <Form.Item name="email" label="И-мэйл">
      <Input disabled placeholder="taxacc@admin.com" />
    </Form.Item>
    <Form.Item name="phone_number" normalize={phoneNumber} label="Утас">
      <Input placeholder="9511-3730" />
    </Form.Item>
  </>
);

export const ChangePassword = ({ form }) => (
  <div style={{ marginTop: 18 }}>
    <Form.Item
      rules={[
        {
          required: true,
          message: 'Хуучин нууц үгээ оруулна уу',
        },
      ]}
      name="prev_pass"
      label="Нууц үг"
    >
      <Input.Password
        placeholder="Нууц үг"
        className="ww-50"
        prefix={lockIcon}
      />
    </Form.Item>
    <Form.Item
      rules={[
        {
          required: true,
          message: 'Шинэ нууц үгээ оруулна уу',
        },
      ]}
      name="new_pass"
      label="Шинэ нууц үг"
    >
      <Input.Password
        placeholder="Шинэ нууц үг"
        className="ww-50"
        prefix={lockIcon}
      />
    </Form.Item>
    <Form.Item
      rules={[
        {
          required: true,
          message: 'Шинэ нууц үгээ давтана уу',
        },
        {
          validator: async (_, confirm_pass) => {
            const new_pass = form.getFieldValue('new_pass');
            if (new_pass !== confirm_pass) {
              return Promise.reject(
                new Error('Шинэ нууц үгтэй таарахгүй байна')
              );
            }
          },
        },
      ]}
      name="confirm_pass"
      label="Дахин оруулна уу"
    >
      <Input.Password
        placeholder="Дахин оруулна уу"
        className="ww-50"
        prefix={lockIcon}
      />
    </Form.Item>
  </div>
);
