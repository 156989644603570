import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { onlyNumber, roundFloat } from 'src/core/utils/formater';
import {
  Typography,
  Button,
  Form,
  Row,
  Col,
  Modal,
  Input,
  Divider,
} from 'antd';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from '../../../core/actions/loader';

const { Text } = Typography;

export const UpdateBurtgel = ({ openDialog, onCancel, data, hurungu_info }) => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const [form] = Form.useForm();

  const handleDialogClose = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      urtug: roundFloat(data?.urtug),
      huramt_elegdel: roundFloat(data?.huramt_elegdel),
    });
  }, [data]);

  const handleSave = (values) => {
    const new_data = {
      dans_dugaar: hurungu_info.dans_dugaar,
      burtgel_id: values.burtgel_id,
      burtgel_dugaar: values.burtgel_dugaar,
      urtug: values.urtug.replaceAll(',', ''),
      huramt_elegdel: values.huramt_elegdel.replaceAll(',', ''),
    };

    socket.on('request_out', (req_data) => {
      socket.removeAllListeners('request_out');
      if (req_data.status === '0') {
        form.resetFields();
        onCancel();
        newAlert({ type: 'success', msg: req_data.message });
      } else {
        newAlert({ type: 'error', msg: req_data.message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: {
        action_type: 'burtgel_ehnii_uldegdel',
        json_array: { ...new_data },
      },
    });
    dispatch(start_loading({}));
  };

  const onFinish = (values) => {
    handleSave(values);
  };

  const layout = {
    labelCol: {
      xs: { span: 9 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 15 },
      sm: { span: 15 },
    },
  };

  return (
    <Modal
      className="custom-modal"
      title="Бүртгэл холбох"
      open={openDialog}
      closable={false}
      maskClosable={false}
      width={600}
      footer={null}
      onCancel={handleDialogClose}
    >
      <div className="dans-medeelel-container">
        <Form
          form={form}
          onFinish={onFinish}
          className="register-form"
          {...layout}
          size="small"
        >
          <Form.Item name="burtgel_id" hidden />
          <Form.Item name="dans_dugaar" label="Дансны дугаар" labelAlign="left">
            <Text>{hurungu_info?.dans_dugaar}</Text>
          </Form.Item>
          <Form.Item label="Үндсэн хөрөнгийн нэр" labelAlign="left">
            <Text ellipsis>{hurungu_info?.ner}</Text>
          </Form.Item>
          <Form.Item label="Үндсэн хөрөнгийн код" labelAlign="left">
            <Text>{hurungu_info?.code}</Text>
          </Form.Item>
          <Form.Item
            name="burtgel_dugaar"
            label="Бүртгэлийн дугаар"
            labelAlign="left"
          >
            <Text>{data?.burtgel_dugaar}</Text>
          </Form.Item>
          <Divider />
          <Form.Item label="Бүртгэлийн анхны өртөг" labelAlign="left">
            <Text>{roundFloat(data?.urtug_anh)}</Text>
          </Form.Item>
          <Form.Item label="Хөрөнгийн нийт өртөг" labelAlign="left">
            <Text>{roundFloat(hurungu_info?.urtug)}</Text>
          </Form.Item>
          <Form.Item
            name="urtug"
            label="Бүртгэлд xолбосон өртөг"
            labelAlign="left"
            normalize={onlyNumber}
          >
            <Input placeholder="Дүн оруулна уу..." className="w-100" />
          </Form.Item>
          <Form.Item label="Хөрөнгийн нийт элэгдэл" labelAlign="left">
            <Text>{roundFloat(hurungu_info?.huramt_elegdel)}</Text>
          </Form.Item>
          <Form.Item
            name="huramt_elegdel"
            label="Бүртгэлд xолбосон элэгдэл"
            labelAlign="left"
            normalize={onlyNumber}
          >
            <Input placeholder="Дүн оруулна уу..." className="w-100" />
          </Form.Item>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <Button
                  onClick={() => {
                    form.resetFields();
                    handleDialogClose();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </Button>
                <Button
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
