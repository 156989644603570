import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Typography, Table as AntTable } from 'antd';

import { useQuery, roundFloat } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import Table from 'src/components/common/table';
import newAlert from 'src/components/newAlert';
import { hariltsagch_columns } from '../data/dataStructure';

import GuilgeeJagsaaltModal from '../modals/modalGuilgeeJagsaalt';

const { Text } = Typography;

const TabDans = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const { socket, version, data_date } = useSelector((state) => ({
    socket: state?.socket,
    version: state?.version,
    data_date: state?.data_date,
  }));

  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [detailParam, setDetailParam] = useState([]);
  const [footerParam, setFooterParam] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [hariltsagch, setHariltsagch] = useState('');
  const [dansCode, setDansCode] = useState('');

  const [totalData, setTotalData] = useState(5);

  const handleOpenModal = (hariltsagch_code) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0') {
        if (data.content.data_array) {
          setHariltsagch(data.content?.data_array[0]?.hariltsagch_name);
          setDetailParam(data.content.data_array);
          setFooterParam(data.content.sum_list);
          setShowModal(true);
        }
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts002_00_dansnii_une',
      content: {
        action_type: 'dansnii_une_detail',
        hariltsagch_code,
        dans_code: dansCode,
        year: version.year,
        month: data_date.month,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    const fetchData = async (page, size) => {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');

        if (data.content.status === '0') {
          setTableData(data.content.json_array);
          setTotalData(data.content.total);
          setTableDataSum(data.content.sum_list);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }

        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts002_00_dansnii_une',
        content: {
          dans_code: query.get('dans_code'),
          month: data_date.month,
          action_type: 'dansnii_une_hariltsagchaar',
          page: page || 1,
          size: size || 20,
        },
      });
      dispatch(start_loading({}));
    };
    const page = query.get('page2');
    const size = query.get('size2');
    setDansCode(query.get('dans_code'));
    if (query.get('tab') === '2' && query.get('dans_code')) {
      fetchData(page, size);
    }
  }, [query, data_date]);

  const showSum = () => (
    <AntTable.Summary fixed>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell align="right" colSpan={2}>
          Нийт дүн:
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(tableDataSum?.eh_dun)}</p>
          <p className="bg-highlight">
            {roundFloat(tableDataSum?.eh_dun_valiut)}
          </p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(tableDataSum?.hanshiin_zuruu)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(tableDataSum?.deb)}</p>
          <p className="bg-highlight">{roundFloat(tableDataSum?.deb_valiut)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(tableDataSum?.cred)}</p>
          <p className="bg-highlight">
            {roundFloat(tableDataSum?.cred_valiut)}
          </p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(tableDataSum?.ets_dun)}</p>
          <p className="bg-highlight">
            {roundFloat(tableDataSum?.ets_dun_valiut)}
          </p>
        </AntTable.Summary.Cell>
      </AntTable.Summary.Row>
    </AntTable.Summary>
  );

  return (
    <>
      {query.get('dans_code') ? (
        <div className="table-wrapper">
          <p>
            <span className="ml-3"> Дансны дугаар: </span>
            {query.get('dans_code')}
          </p>
          <div className="inside-table no-scroll">
            <Table
              columns={hariltsagch_columns({ handleOpenModal })}
              total={totalData}
              dataSource={tableData}
              tab="2"
              scroll={{
                y: 'calc(100vh - 370px)',
              }}
              customize={showSum}
            />
          </div>
        </div>
      ) : (
        <Text className="ant-form-text">Дансаа сонгоно уу!</Text>
      )}

      <Modal
        className="custom-modal"
        title="Авлага өглөгийн дэлгэрэнгүй"
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        width={1500}
        footer={null}
        centered
      >
        <GuilgeeJagsaaltModal
          detailParam={detailParam}
          footerParam={footerParam}
          hariltsagch={hariltsagch}
          dansCode={dansCode}
          onClose={() => {
            setShowModal(false);
          }}
        />
      </Modal>
    </>
  );
};

export default TabDans;
