import React, { useState, useEffect } from 'react';
import { Form, Select, Row, Col, Button, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import EditableTable from 'src/components/common/editableTable';
import newAlert from 'src/components/newAlert';
import { PlusCircleOutlined } from '@ant-design/icons';
import { AddNewBurtgel } from 'src/components/undsenHurungu/addNewBurtgel';
import { ColumnsBurtgelEdit, EditSum } from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';

const { Option } = Select;
const EditBurtgel = ({ hurunguData = {}, onCancel = {}, visible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const data_date = useSelector((state) => state.data_date);
  const socket = useSelector((state) => state.socket);
  const [tableDataTotal, setTableDataTotal] = useState({});

  const [tableDataList, setTableDataList] = useState([]);
  const [tableDataInfo, setTableDataInfo] = useState({});

  const [burtgelOpenDialog, setBurtgelOpenDialog] = useState(false);
  const [burtgelUpdate, setBurtgelUpdate] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        console.log(data);
        setTableDataTotal(data?.content?.total_list);
        setTableDataList(data?.content?.result_list);
        setTableDataInfo(data?.content?.result_json);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const { month } = data_date;
    const search = {
      dans: hurunguData.dans_dugaar,
      hurungu: hurunguData.hurungu_id,
    };
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'sanhuu_tovchoo_burtgel_edit',
        month,
        search,
      },
    });
    dispatch(start_loading({}));
  };
  useEffect(() => {
    if (visible) {
      select_data();
    }
  }, [visible]);
  const clear = (inputString) => {
    const stringWithoutCommas = String(inputString).replace(/,/g, '');
    const numberValue = parseFloat(stringWithoutCommas);
    return numberValue;
  };

  const sumBurt = () => {
    const temp_sum = {
      ...tableDataTotal,
      burt_ets_too_shirheg: 0,
      burt_ets_urtug: 0,
      burt_ets_huramt_elegdel: 0,
      burt_eh_too_shirheg: 0,
      burt_eh_urtug: 0,
      burt_eh_huramt_elegdel: 0,
      burt_deb_too_shirheg: 0,
      burt_deb_urtug: 0,
      burt_deb_huramt_elegdel: 0,
      burt_cred_too_shirheg: 0,
      burt_cred_urtug: 0,
      burt_cred_huramt_elegdel: 0,
    };
    tableDataList.forEach((item) => {
      

      temp_sum.burt_eh_too_shirheg += clear(item.eh_too_shirheg);
      temp_sum.burt_eh_urtug += clear(item.eh_urtug);
      temp_sum.burt_eh_huramt_elegdel += clear(item.eh_huramt_elegdel);

      temp_sum.burt_deb_too_shirheg += clear(item.deb_too_shirheg);
      temp_sum.burt_deb_urtug += clear(item.deb_urtug);
      temp_sum.burt_deb_huramt_elegdel += clear(item.deb_huramt_elegdel);

      temp_sum.burt_cred_too_shirheg += clear(item.cred_too_shirheg);
      temp_sum.burt_cred_urtug += clear(item.cred_urtug);
      temp_sum.burt_cred_huramt_elegdel += clear(item.cred_huramt_elegdel);

      
    });
    temp_sum.burt_ets_too_shirheg = temp_sum.burt_eh_too_shirheg +  temp_sum.burt_deb_too_shirheg - temp_sum.burt_cred_too_shirheg
    temp_sum.burt_ets_urtug = temp_sum.burt_eh_urtug +  temp_sum.burt_deb_urtug - temp_sum.burt_cred_urtug
    temp_sum.burt_ets_huramt_elegdel = temp_sum.burt_eh_huramt_elegdel +  temp_sum.burt_cred_huramt_elegdel - temp_sum.burt_deb_huramt_elegdel
    setTableDataTotal({ ...temp_sum });
  };
  const closeModal = () => {
    form.resetFields();
    setTableDataList([]);
    setTableDataInfo({});
    setTableDataTotal({});
    setSelectedValues([]);
    onCancel();
  };

  const handleSave = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: data.message });
        // closeModal();
      } else {
        newAlert({ type: 'error', msg: data.message });
      }
      
      dispatch(end_loading({}));
    });
    const temp_data = {
      hurunguData,
      tableDataList,
    };
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: {
        action_type: 'edit_burtgel_save',
        json_array: { ...temp_data },
      },
    });
    dispatch(start_loading({}));
  };

  const onFinish = () => {
    handleSave();
  };

  const geTvalue = (value) => {
    setTableDataList([...value]);
  };
  useEffect(() => {
    if (visible && tableDataList) sumBurt();
  }, [tableDataList]);

  const layout = {
    labelCol: {
      xs: { span: 5 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 19 },
      sm: { span: 19 },
    },
  };
  useEffect(() => {
    if (burtgelUpdate && !burtgelOpenDialog) {
      select_data();
      setBurtgelUpdate(false);
    }
  }, [burtgelOpenDialog]);

  const handleAddButtonClick = () => {
    const temp_row = {
      burtgel_dugaar: null,
      burtgel_id: null,
      dans_dugaar: hurunguData.dans_dugaar,
      hurungu_id: hurunguData.hurungu_id,
      eh_huramt_elegdel: 0.00,
      eh_too_shirheg: 0.00,
      eh_urtug: 0.00,
      cred_huramt_elegdel: 0.00,
      cred_too_shirheg: 0.00,
      cred_urtug: 0.00,
      deb_huramt_elegdel: 0.00,
      deb_too_shirheg: 0.00,
      deb_urtug: 0.00,
      ets_huramt_elegdel: 0.00,
      ets_too_shirheg: 0.00,
      ets_urtug: 0.00,
    };

    const new_rows = selectedValues.map((selectedId) => {
      const burtgelInfo = tableDataInfo?.burtgel_infos.find(
        (info) => info?.burtgel_id === selectedId
      );
      console.log(selectedValues.length === 1, tableDataList.length === 0);
      if (selectedValues.length === 1 && tableDataList.length === 0) {
        temp_row.cred_too_shirheg = tableDataTotal.cred_too_shirheg;
        temp_row.cred_urtug = tableDataTotal.cred_urtug;
        temp_row.cred_huramt_elegdel = tableDataTotal.cred_huramt_elegdel;
        temp_row.deb_too_shirheg = tableDataTotal.deb_too_shirheg;
        temp_row.deb_urtug = tableDataTotal.deb_urtug;
        temp_row.deb_huramt_elegdel = tableDataTotal.deb_huramt_elegdel;
      }
      if (burtgelInfo) {
        return {
          ...temp_row,
          key: `${burtgelInfo.burtgel_id}_${tableDataList.length + 1}`,
          burtgel_id: burtgelInfo.burtgel_id,
          burtgel_dugaar: burtgelInfo.burtgel_dugaar,
          eh_huramt_elegdel: burtgelInfo.huramt_elegdel,
          eh_too_shirheg: burtgelInfo.too_shirheg,
          eh_urtug: burtgelInfo.urtug,
          ets_huramt_elegdel: burtgelInfo.huramt_elegdel,
          ets_too_shirheg: burtgelInfo.too_shirheg,
          ets_urtug: burtgelInfo.urtug,
        };
      }
      return null; // or handle if not found
    }).filter((row) => row !== null); // Remove null entries if any
    setTableDataList(
      [...tableDataList, ...new_rows]
    );
    setSelectedValues([]);
  };
  const deleteRow = (row) => {
    const updatedResultList = tableDataList.filter(
      (item) => item.burtgel_id !== row.burtgel_id
    );

    setTableDataList([
      ...updatedResultList,
    ]);
  };

  return (
    <Modal
      className="custom-modal"
      title="Үндсэн хөрөнгийн ангилал /хөрөнгийн кодоор/"
      open={visible}
      width="100%"
      footer={null}
      onCancel={() => {
        onCancel('visible2');
      }}
    >
      <div className="dans-medeelel-container">
        <AddNewBurtgel
          data={hurunguData}
          openDialog={burtgelOpenDialog}
          setOpenDialog={setBurtgelOpenDialog}
        />
        <Form
          form={form}
          onFinish={onFinish}
          className="register-form"
          {...layout}
          size="small"
        >
          <Form.Item
            name="hurungu_turul"
            label="Бүртгэл хүснэгтэд нэмэх"
            labelAlign="left"
          >
            <Select
              size="small"
              placeholder="Сонгох"
              allowClear
              mode="multiple"
              value={selectedValues}
              onChange={setSelectedValues}
              style={{ width: '60%', fontSize: 13 }}
            >
              {tableDataInfo?.burtgel_infos
                ?.filter((burtgelInfo) => !tableDataList.some((item) =>
                  item.burtgel_id === burtgelInfo?.burtgel_id))
                .map((burtgelInfo) => (
                  <Option key={burtgelInfo?.burtgel_id} value={burtgelInfo?.burtgel_id}>
                    {burtgelInfo?.burtgel_dugaar}
                  </Option>
                ))}
            </Select>
            <Button
              className="add-btn"
              type="primary"
              size="small"
              icon={<PlusCircleOutlined />}
              onClick={handleAddButtonClick}
            >
              Нэмэх
            </Button>
          </Form.Item>
          <Button
            className="add-btn"
            type="primary"
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              setBurtgelOpenDialog(true);
              setBurtgelUpdate(true);
            }}
          >
            Бүртгэлийн дугаар үүсгэх
          </Button>
          <Form.Item noStyle shouldUpdate>
            {visible && tableDataList && (
              <EditableTable
                dataSource={tableDataList}
                columns={ColumnsBurtgelEdit({ deleteRow })}
                geTvalue={geTvalue}
                customize={() => <EditSum data={tableDataTotal} />}
                scroll={{
                  y: 'calc(100vh - 430px)',
                }}
              />
            )}
          </Form.Item>
          <Row style={{ marginTop: 10 }}>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => {
                  closeModal();
                }}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Буцах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default EditBurtgel;
