import React from 'react';
import { roundFloat } from 'src/core/utils/formater';

export const UsgalZasvarAllColumns = () => [
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    align: 'left',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Үндсэн хөрөнгийн',
    children: [
      {
        title: 'Нэр',
        dataIndex: 'ner',
        align: 'left',
        width: 200,
        ellipsis: true,
      },
      {
        title: 'Бүртгэлийн дугаар',
        dataIndex: 'burtgel_dugaar',
        align: 'left',
        width: 150,
      },
    ],
  },
  {
    title: 'Татварын зорилгоор',
    children: [
      {
        title: 'Ашиглаж эхэлсэн огноо',
        dataIndex: 'ashiglaj_ehelsen',
        align: 'center',
        width: 100,
      },
      {
        title: 'Анхны өртөг',
        dataIndex: 'urtug_anh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Өөрчлөлтийн',
    children: [
      {
        title: 'Хөрөнгийн төрөл',
        dataIndex: 'hurungu_turul',
        align: 'center',
        width: 150,
      },
      {
        title: 'Огноо',
        dataIndex: 'ognoo',
        align: 'center',
        width: 100,
      },
      {
        title: 'Их засвар / Урсгал засвар',
        dataIndex: 'type_ner',
        align: 'left',
        width: 150,
      },
      {
        title: 'Дүн',
        dataIndex: 'dun',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Урсгал засварын хэтрэлт',
        dataIndex: 'cap_dun',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <div>{row?.cap_dun ? roundFloat(row?.cap_dun) : ''}</div>
        ),
      },
    ],
  },
];
