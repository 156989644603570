import React from 'react';
import CanView from 'src/components/permission/canview';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const initialValues = {
  hariltsagch_code: '',
  ttd: '',
  ner: '',
  oroltsoo: '',
  helber: '',
  turul: '',
  uil_ajillagaanii_chiglel: '',
  oroltsoo_huvi: '',
  baingiin_hayag: '',
  sanaliin_erhiin_hemjee: '',
  angilal: '',
};

const columns = ({
  handleOpenRegisterModal = () => {},
  onDelete = () => {},
}) => [
  {
    title: '№',
    dataIndex: 'index',
    align: 'left',
    width: 50,
  },
  {
    title: 'Харилцан хамааралтай этгээд',
    children: [
      {
        title: 'Регистрийн дугаар',
        dataIndex: 'ttd',
        align: 'left',
        width: 180,
      },
      {
        title: 'Нэр',
        dataIndex: 'ner',
        align: 'left',
        width: 150,
      },
      {
        title: 'Төрөл',
        dataIndex: 'turul_ner',
        align: 'left',
        width: 250,
      },
      {
        title: 'Оролцоо',
        dataIndex: 'oroltsoo_ner',
        align: 'left',
        width: 100,
      },
    ],
  },
  {
    title: 'Эзэмшиж буй',
    children: [
      {
        title: 'Хувьцаа, хувь оролцооны хувь хэмжээ',
        dataIndex: 'oroltsoo_huvi',
        align: 'left',
        width: 80,
        render: (_) => `${_}%`,
      },
      {
        title: 'Саналын эрхийн хувь хэмжээ',
        dataIndex: 'sanaliin_erhiin_hemjee',
        align: 'left',
        width: 80,
        render: (_) => `${_}%`,
      },
    ],
  },
  {
    title: 'Татвар төлөгчийн ангилал',
    dataIndex: 'is_foreign',
    align: 'left',
    width: 100,
    render: (_) => (_ === '1' ? 'Гадаад' : 'Дотоод'),
  },
  {
    title: 'Байнгын татвар төлөгчөөр бүртгэгдсэн улсын код',
    dataIndex: 'country_code',
    align: 'left',
    width: 150,
  },
  {
    title: 'Үйл ажиллагааны чиглэл',
    dataIndex: 'uil_ajillagaanii_chiglel',
    align: 'left',
    width: 130,
  },
  {
    title: 'Байнгын хаяг',
    dataIndex: 'baingiin_hayg',
    align: 'left',
    width: 130,
  },
  {
    title: 'Засах',
    width: 50,
    align: 'center',
    fixed: 'right',
    render: (_, row) => (
      <CanView allowedRole="007_1">
        <EditOutlined onClick={() => handleOpenRegisterModal(row)} />
      </CanView>
    ),
  },
  {
    title: 'Устгах',
    width: 50,
    align: 'center',
    fixed: 'right',
    render: (_, { hariltsagch_code = '' }) => (
      <CanView allowedRole="007_1">
        <DeleteOutlined onClick={() => onDelete(hariltsagch_code)} />
      </CanView>
    ),
  },
];

export default {
  columns,
  initialValues,
};
