import React from 'react';
import { roundFloat } from 'src/core/utils/formater';

const setFloat = (_) => roundFloat(_);
export const columns = [
  {
    title: '№',
    align: 'left',
    dataIndex: 'key',
    titleAlign: 'center',
    width: 20,
  },
  {
    title: 'Огноо',
    align: 'center',
    dataIndex: 'ognoo',
    titleAlign: 'center',
    width: 50,
  },
  {
    title: 'Хүүгийн орлого олгосон ХХЭтгээдийн нэр',
    align: 'left',
    titleAlign: 'center',
    width: 150,
    dataIndex: 'hariltsagch_name',
  },
  {
    title: 'Хүүгийн орлого олохтой холбогдон гарсан эсэх',
    align: 'left',
    width: 100,
    titleAlign: 'center',
    dataIndex: 'type',
    render: (_) => {
      if (_ === 1) {
        return 'Тийм';
      }
      return 'Үгүй';
    },
  },
  {
    title: 'Татвар Төлөгчийн Дугаар',
    align: 'center',
    width: 100,
    titleAlign: 'center',
    dataIndex: 'ttd',
  },
  {
    title: 'Хүүгийн зардлын дүн',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'dun',
    render: setFloat,
  },
  {
    title: 'Борлуулалтын орлогын дүн',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'borluulalt_dun',
    render: setFloat,
  },
  {
    title: 'Элэгдэл, Хорогдол, Хүүнээс бусад зардлын дүн',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'busad_dun',
    render: setFloat,
  },
  {
    title: 'Хүлээн зөвшөөрөх зээлийн дээд хэмжээ /Хязгаар/',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'huleen_zowshooroh_zardal',
    render: setFloat,
  },
  {
    title: 'Хязгаарлалтаас хэтэрсэн зээлийн хүүгийн зардал',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'hetersen_dun',
    render: setFloat,
  },
];
export const columns_2 = [
  {
    title: '№',
    align: 'left',
    dataIndex: 'key',
    titleAlign: 'center',
    width: 20,
  },
  {
    title: 'Огноо',
    align: 'center',
    dataIndex: 'ognoo',
    titleAlign: 'center',
    width: 100,
  },
  {
    title: 'Хүүгийн орлого олгосон Хөрөнгө оруулагчийн нэр',
    align: 'left',
    dataIndex: 'hariltsagch_name',
    titleAlign: 'center',
    width: 150,
  },
  {
    title: 'Төрөл',
    align: 'left',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'turul',
  },
  {
    title: 'Татвар Төлөгчийн Дугаар',
    align: 'left',
    titleAlign: 'center',
    width: 80,
    dataIndex: 'ttd',
  },
  {
    title: 'Хүүгийн зардлын дүн',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'dun',
    render: setFloat,
  },
  {
    title: 'Өмнө нь оруулсан хөрөнгө оруулалтын дүн',
    align: 'right',
    titleAlign: 'center',
    width: 120,
    dataIndex: 'horongo_dun',
    render: setFloat,
  },
  {
    title: 'Хүлээн зөвшөөрөх зээлийн дээд хэмжээ /Хязгаар/',
    align: 'right',
    titleAlign: 'center',
    width: 120,
    dataIndex: 'huleen_zowshooroh_zardal',
    render: setFloat,
  },
  {
    title: 'Хязгаарлалтаас хэтэрсэн зээлийн хүүгийн зардал',
    align: 'right',
    titleAlign: 'center',
    width: 120,
    dataIndex: 'hetersen_dun',
    render: setFloat,
  },
];

export const columns_3 = [
  {
    title: 'Дансны',
    titleAlign: 'center',
    children: [
      {
        title: '№',
        align: 'center',
        dataIndex: 'key',
        titleAlign: 'center',
        width: '1vw',
      },
      {
        title: 'Дугаар',
        align: 'left',
        dataIndex: 'dans_dugaar',
        titleAlign: 'center',
        width: '3vw',
      },
      {
        title: 'Нэр',
        align: 'left',
        titleAlign: 'center',
        width: '7vw',
        dataIndex: 'dans_ner',
      },
      {
        title: 'Дүн',
        align: 'right',
        titleAlign: 'center',
        width: '3vw',
        dataIndex: 'dans_dun',
        render: setFloat,
      },
    ],
  },
];

export const customSummary = [
  {
    value: null,
  },

  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'dun',
    render: setFloat,
  },
  {
    align: 'right',
    value: 'borluulalt_dun',
    render: setFloat,
  },
  {
    align: 'right',
    value: 'busad_dun',
    render: setFloat,
  },
  {
    align: 'right',
    value: 'huleen_zowshooroh_zardal',
    render: setFloat,
  },
  {
    align: 'right',
    value: 'hetersen_dun',
    render: setFloat,
  },
];

export const customSummary_2 = [
  {
    value: null,
  },

  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'dun',
    render: setFloat,
  },
  {
    align: 'right',
    value: 'horongo_dun',
    render: setFloat,
  },
  {
    align: 'right',
    value: 'huleen_zowshooroh_zardal',
    render: setFloat,
  },
  {
    align: 'right',
    value: 'hetersen_dun',
    render: setFloat,
  },
];

export const customSummary_3 = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'dans_dun',
    render: setFloat,
  },
];
