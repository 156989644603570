import React, { useState, useEffect } from 'react';
import { TreeSelect as AntTreeSelect } from 'antd';

const { SHOW_PARENT } = AntTreeSelect;

const TreeSelect = ({
  data = [],
  value = undefined,
  onChange = () => {},
  placeHolder = '',
  defaultExpandAll = true,
  defaultValue = null,
  disabled = false,
}) => {
  const [treeData, seTtreeData] = useState([]);
  useEffect(() => {
    let _children = [];
    if (data.length > 0) {
      const _data = data.map((item) => {
        const { ner, children = [] } = item;
        if (children.length > 0) {
          _children = children.map((el) => {
            const { angilal_id, angilal_ner } = el;
            return { title: angilal_ner, value: angilal_id };
          });
        }
        return {
          title: ner,
          children: _children,
          selectable: false,
          value: ner,
        };
      });
      seTtreeData(_data);
    }
  }, [data]);

  const filter = (input, option) => {
    const { title } = option;
    return title && title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <div>
      <AntTreeSelect
        style={{ width: '100%' }}
        value={value}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={treeData}
        allowClear
        treeDefaultExpandAll={defaultExpandAll}
        placeholder={placeHolder}
        showCheckedStrategy={SHOW_PARENT}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={disabled}
        showSearch
        filterTreeNode={filter}
      />
    </div>
  );
};

export default TreeSelect;
