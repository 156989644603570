import React from 'react';
import {
  Button as AntButton,
  Form,
  Input,
  Select as AntSelect,
  Row,
  Col,
  Divider,
  InputNumber,
} from 'antd';
import { onFilter } from 'src/core/utils/selectFilter';
import { onlyNumber } from 'src/core/utils/formater';

const { Option } = AntSelect;

export default function RegisterModal({
  dictionaryValues,
  modalValues,
  actionType,
  handleSave,
  onClose = () => {},
}) {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    handleSave(values, actionType);
    form.resetFields();
  };
  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const layout = {
    labelCol: {
      xs: { span: 13 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 10 },
      sm: { span: 15 },
    },
  };

  if (modalValues) {
    form.setFieldsValue({
      hariltsagch_code: modalValues?.hariltsagch_code || null,
      ...modalValues,
    });
  }
  const seTcountryTtd = (code) => {
    const hariltsgach = dictionaryValues.hariltsagch_code.find(
      (x) => x.code === code
    );
    form.setFieldsValue({
      country_code: hariltsgach?.country_code,
      ttd: hariltsgach?.ttd,
    });
  };

  return (
    <div className="hariltsagch-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        size="small"
        initialValues={{
          hariltsagch_code: null,
          turul_id: null,
          oroltsoo_id: null,
          helber_id: null,
        }}
        {...layout}
      >
        <Form.Item
          name="hariltsagch_code"
          label="Харилцагч"
          rules={[
            {
              required: true,
              message: 'Харилцагч сонгоно уу!',
            },
          ]}
        >
          <AntSelect
            onChange={(e) => {
              seTcountryTtd(e);
            }}
            showSearch
            onFilter={onFilter}
            disabled={actionType === 'edit'}
            placeholder="Бүртгэлтэй харилцагчаас сонгоно уу!"
          >
            {dictionaryValues.hariltsagch_code.map((x) => (
              <Option value={x.code}>
                {x.code} - {x.name}
              </Option>
            ))}
          </AntSelect>
        </Form.Item>
        <Form.Item name="ttd" label="Регистрийн дугаар">
          <Input className="w-30" disabled />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Үйл ажиллагааны чиглэл оруулна уу...!',
            },
          ]}
          name="uil_ajillagaanii_chiglel"
          label="Үйл ажиллагааны чиглэл"
        >
          <Input
            className="w-100"
            placeholder="Үйл ажиллагааны чиглэл оруулна уу..."
            maxLength={100}
            showCount
          />
        </Form.Item>
        <Form.Item
          name="country_code"
          label="Байнгын татвар төлөгчөөр бүртгэгдсэн улсын код"
        >
          <Input disabled className="w-30" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Байнгын хаягаа оруулна уу...',
            },
          ]}
          name="baingiin_hayg"
          label="Байнгын хаяг"
        >
          <Input
            className="w-100"
            showCount
            maxLength={200}
            placeholder="Байнгын хаягаа оруулна уу..."
          />
        </Form.Item>
        <Divider />

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Төрөл сонгоно уу!',
            },
          ]}
          name="turul_id"
          label="Төрөл"
        >
          <AntSelect
            placeholder="Сонгох"
            style={{
              whiteSpace: 'nowrap;',
              overflow: 'auto',
            }}
          >
            {dictionaryValues.turul.map((x) => (
              <Option value={x.id}>{x.ner}</Option>
            ))}
          </AntSelect>
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Хэлбэр сонгоно уу!',
            },
          ]}
          name="helber_id"
          label="Хэлбэр"
        >
          <AntSelect placeholder="Сонгох">
            {dictionaryValues.helber.map((x) => (
              <Option value={x.id}>{x.ner}</Option>
            ))}
          </AntSelect>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Оролцоо сонгоно уу!',
            },
          ]}
          name="oroltsoo_id"
          label="Оролцоо"
        >
          <AntSelect placeholder="Сонгох">
            {dictionaryValues.oroltsoo.map((x) => (
              <Option value={x.id}>{x.ner}</Option>
            ))}
          </AntSelect>
        </Form.Item>
        <Divider />
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Хувьцаа, хувь оролцооны хувь хэмжээ оруулна уу!',
            },
          ]}
          name="oroltsoo_huvi"
          label="Хувьцаа, хувь оролцооны хувь хэмжээ (%)"
        >
          <InputNumber
            className="w-30"
            max={100}
            normalize={onlyNumber}
            defaultValue={0}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Саналын эрхийн хувь хэмжээ оруулна уу!',
            },
          ]}
          name="sanaliin_erhiin_hemjee"
          label="Саналын эрхийн хувь хэмжээ (%)"
        >
          <InputNumber
            className="w-30"
            max={100}
            normalize={onlyNumber}
            defaultValue={0}
          />
        </Form.Item>

        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item noStyle>
              <AntButton
                onClick={onCancel}
                className="cancel-btn mr-5"
                type="primary"
                shape="round"
              >
                Буцах
              </AntButton>
              <AntButton
                className="confirm-btn"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </AntButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
