import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { roundFloat } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Divider,
  Modal,
} from 'antd';
import { start_loading, end_loading } from '../../../core/actions/loader';

const optionList = {
  mayagt_44: '44. Суудлын автомашин, түүний эд анги сэлбэгт төлсөн НӨАТ',
  mayagt_45: '45. Хувьдаа болон ажиллагсдын хэрэгцээнд зориулж худалдан авсан бараа, ажил, үйлчилгээнд төлсөн НӨАТ',
  mayagt_46: '46. Хуулийн 13 дугаар зүйлд заасан чөлөөлөгдөх үйлдвэрлэл, үйлчилгээнд зориулж импортолсон болон худалдаж авсан бараа, ажил, үйлчилгээнд төлсөн НӨАТ',
  mayagt_47: '47. Ашиглалтын өмнөх үйл ажиллагаанд зориулж импортоор оруулсан болон худалдан авсан бараа, ажил, үйлчилгээнд төлсөн НӨАТ',
  mayagt_48: '48. Тайлант хугацааны албан татвар ногдох бараа, ажил, үйлчилгээтэй хамааралгүй импортоор оруулсан болон худалдан авсан бараа, ажил, үйлчилгээнд төлсөн НӨАТ',
};

export const AnglalModal = ({
  onCancel = () => {},
  visibleEdit = {
    visible: false,
    rowData: {},
  },
  seTvisibleEdit = () => {},
}) => {
  const dispatch = useDispatch();
  const { socket, data_date } = useSelector((state) => state);

  const [note, seTnote] = useState(null);

  const [form] = Form.useForm();
  const [selectedType, seTselectedType] = useState('mayagt_44');

  const anglal = visibleEdit.rowData?.zuruu_iluu_niit_base > 0.1
  || visibleEdit.rowData?.zuruu_iluu_noat_base > 0.1;
  useEffect(() => {
    if (visibleEdit.visible) {
      if (note === null) {
        seTnote(visibleEdit.rowData?.note);
      }
    }
  }, [visibleEdit]);
  const onFinish = () => {
    socket.on('request_out', (req_data) => {
      socket.removeAllListeners('request_out');
      if (req_data.status === '0') {
        newAlert({ type: 'success', msg: req_data.message });
        seTvisibleEdit({
          visible: false,
          rowData: {},
        });
        seTnote(null);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const row_data = {
      ...visibleEdit.rowData,
      note,
      selected_type: selectedType,
    };
    socket.emit('request_in', {
      channel: 'ss013_03_modify',
      content: {
        action_type: 'save_hudaldan_avalt',
        json_data: row_data,
        year: data_date?.year,

      },
    });
    dispatch(start_loading({}));
  };
  const onChangeHasagNiit = (e) => {
    const { value } = e.target;
    if (value) {
      const rowData = {
        ...visibleEdit.rowData,
        zuruu_iluu_niit: visibleEdit.rowData?.zuruu_iluu_niit_base
        - visibleEdit.rowData?.toh_hoish_niit - value,
        toh_hasag_niit: value,
      };
      seTvisibleEdit({ ...visibleEdit, rowData,
      });
    } else {
      const rowData = {
        ...visibleEdit.rowData,
        zuruu_iluu_niit: visibleEdit.rowData?.zuruu_iluu_niit_base
        - visibleEdit.rowData?.toh_hoish_niit,
        toh_hasag_niit: null,
      };
      seTvisibleEdit({ ...visibleEdit, rowData,
      });
    }
  };

  const onChangeHasagNoat = (e) => {
    const { value } = e.target;
    if (value) {
      const rowData = {
        ...visibleEdit.rowData,
        zuruu_iluu_noat: visibleEdit.rowData?.zuruu_iluu_noat_base
        - visibleEdit.rowData?.toh_hoish_noat - value,
        toh_hasag_noat: value,
      };
      seTvisibleEdit({ ...visibleEdit, rowData,
      });
    } else {
      const rowData = {
        ...visibleEdit.rowData,
        zuruu_iluu_noat: visibleEdit.rowData?.zuruu_iluu_noat_base
        - visibleEdit.rowData?.toh_hoish_noat,
        toh_hasag_noat: null,
      };
      seTvisibleEdit({ ...visibleEdit, rowData,
      });
    }
  };

  const onChangeHoishNiit = (e) => {
    const { value } = e.target;
    if (value) {
      const rowData = {
        ...visibleEdit.rowData,
        zuruu_iluu_niit: visibleEdit.rowData?.zuruu_iluu_niit_base
        - visibleEdit.rowData?.toh_hasag_niit - value,
        toh_hoish_niit: value,
      };
      seTvisibleEdit({ ...visibleEdit, rowData,
      });
    } else {
      const rowData = {
        ...visibleEdit.rowData,
        zuruu_iluu_niit: visibleEdit.rowData?.zuruu_iluu_niit_base
        - visibleEdit.rowData?.toh_hasag_niit,
        toh_hoish_niit: null,
      };
      seTvisibleEdit({ ...visibleEdit, rowData,
      });
    }
  };

  const onChangeHoishNoat = (e) => {
    const { value } = e.target;
    if (value) {
      const rowData = {
        ...visibleEdit.rowData,
        zuruu_iluu_noat: visibleEdit.rowData?.zuruu_iluu_noat_base
        - visibleEdit.rowData?.toh_hasag_noat - value,
        toh_hoish_noat: value,
      };
      seTvisibleEdit({ ...visibleEdit, rowData,
      });
    } else {
      const rowData = {
        ...visibleEdit.rowData,
        zuruu_iluu_noat: visibleEdit.rowData?.zuruu_iluu_noat_base
        - visibleEdit.rowData?.toh_hasag_noat,
        toh_hoish_noat: null,
      };
      seTvisibleEdit({ ...visibleEdit, rowData,
      });
    }
  };

  // aaaa
  const onChangeHeregNiit = (e) => {
    const { value } = e.target;
    if (value) {
      const rowData = {
        ...visibleEdit.rowData,
        zuruu_dutuu_niit: visibleEdit.rowData?.zuruu_dutuu_niit_base - value,
        toh_hereg_niit: value,
      };
      seTvisibleEdit({ ...visibleEdit, rowData,
      });
    } else {
      const rowData = {
        ...visibleEdit.rowData,
        zuruu_dutuu_niit: visibleEdit.rowData?.zuruu_dutuu_niit_base,
        toh_hereg_niit: null,
      };
      seTvisibleEdit({ ...visibleEdit, rowData,
      });
    }
  };
  const onChangeHeregNoat = (e) => {
    const { value } = e.target;
    if (value) {
      const rowData = {
        ...visibleEdit.rowData,
        zuruu_dutuu_noat: visibleEdit.rowData?.zuruu_dutuu_noat_base - value,
        toh_hereg_noat: value,

      };
      seTvisibleEdit({ ...visibleEdit, rowData,
      });
    } else {
      const rowData = {
        ...visibleEdit.rowData,
        zuruu_dutuu_noat: visibleEdit.rowData?.zuruu_dutuu_noat_base,
        toh_hereg_noat: null,
      };
      seTvisibleEdit({ ...visibleEdit, rowData,
      });
    }
  };
  const onChangeSelect = (value) => {
    seTselectedType(value);
  };
  return (
    <Modal
      open={visibleEdit.visible}
      width="650px"
      footer={null}
      closable={false}
      maskClosable={false}
    >
      <div>
        <Row justify="start">
          <Col span={24}>
            <h2 style={{ fontWeight: 'bold' }}>НӨАТ тохируулах</h2>
          </Col>
        </Row>
        <Divider style={{ margin: '10px' }} />
        <Row>
          <Col span={24}>
            <Form
              form={form}
              className="register-form"
              size="small"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              initialValues={{ remember: true }}
            >
              <Form.Item
                label="Тэмдэглэл"
                labelAlign="left"
                className="ant-form-item-small"
                style={{ margin: '10px' }}
              >
                <Input style={{ width: 300 }} className="ant-input" size="small" value={note} onChange={(e) => { seTnote(e.target.value); }} />
              </Form.Item>
              {anglal && (
                <Form.Item
                  label="Хасагдахгүй"
                  labelAlign="left"
                  className="ant-form-item-small"
                  style={{ margin: '10px' }}
                >
                  <Select
                    value={selectedType}
                    style={{ width: 300 }}
                    placeholder="Төрөл сонго уу?"
                    onChange={onChangeSelect}
                    disabled={!(visibleEdit.rowData?.toh_hasag_noat > 0.00
                      || visibleEdit.rowData?.toh_hasag_niit > 0.00)}
                  >
                    {Object.keys(optionList).map((key) => (
                      <Select.Option key={key} value={key}>
                        {optionList[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) }

              <Divider style={{ margin: '10px' }} />
              {anglal
                ? (
                  <table className="ant-table custom-antd-table-one" style={{ tableLayout: 'fixed', margin: '10px' }}>
                    <tbody className="ant-table-tbody">
                      <tr className="ant-table-row ant-table-row-level-0 table-row-class">
                        <td className="ant-table-cell" style={{ width: '100px', margin: '10px' }} />
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px', fontWeight: 'bold' }}>Хасагдахгүй</td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px', fontWeight: 'bold' }}>Хойшлуулах</td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px', fontWeight: 'bold' }}>Зөрүү илүү</td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px', fontWeight: 'bold' }}>Нийт </td>
                      </tr>
                      <tr className="ant-table-row ant-table-row-level-0 table-row-class">
                        <td className="ant-table-cell" style={{ width: '100px', margin: '10px' }}>Нийт дүн</td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px' }}>
                          <Input type="number" className="ant-input" size="small" style={{ width: '130px' }} value={visibleEdit.rowData?.toh_hasag_niit} onChange={onChangeHasagNiit} />
                        </td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px' }}>
                          <Input type="number" className="ant-input" size="small" style={{ width: '130px' }} value={visibleEdit.rowData?.toh_hoish_niit} onChange={onChangeHoishNiit} />
                        </td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px' }}>{roundFloat(visibleEdit.rowData?.zuruu_iluu_niit, 3)}</td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px' }}>{roundFloat(visibleEdit.rowData?.zuruu_iluu_niit_base, 3)}</td>
                      </tr>
                      <tr className="ant-table-row ant-table-row-level-0 table-row-class">
                        <td className="ant-table-cell" style={{ width: '100px', margin: '10px' }}>НӨАТ</td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px' }}>
                          <Input type="number" className="ant-input" size="small" style={{ width: '130px' }} value={visibleEdit.rowData?.toh_hasag_noat} onChange={onChangeHasagNoat} />
                        </td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px' }}>
                          <Input type="number" className="ant-input" size="small" style={{ width: '130px' }} value={visibleEdit.rowData?.toh_hoish_noat} onChange={onChangeHoishNoat} />
                        </td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px' }}>{roundFloat(visibleEdit.rowData?.zuruu_iluu_noat, 3)}</td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px' }}>{roundFloat(visibleEdit.rowData?.zuruu_iluu_noat_base, 3)}</td>
                      </tr>
                    </tbody>
                  </table>
                )
                : (
                  <table className="ant-table custom-antd-table-one" style={{ tableLayout: 'fixed', margin: '10px' }}>
                    <tbody className="ant-table-tbody">
                      <tr className="ant-table-row ant-table-row-level-0 table-row-class">
                        <td className="ant-table-cell" style={{ width: '100px', margin: '10px' }} />
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px', fontWeight: 'bold' }}>Хэрэгжээгүй</td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px', fontWeight: 'bold' }}>Зөрүү дутуу</td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px', fontWeight: 'bold' }}>Нийт </td>
                      </tr>
                      <tr className="ant-table-row ant-table-row-level-0 table-row-class">
                        <td className="ant-table-cell" style={{ width: '100px', margin: '10px' }}>Нийт дүн</td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px' }}>
                          <Input type="number" className="ant-input" size="small" style={{ width: '130px' }} value={visibleEdit.rowData?.toh_hereg_niit} onChange={onChangeHeregNiit} />
                        </td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px' }}>{roundFloat(visibleEdit.rowData?.zuruu_dutuu_niit, 3)}</td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px' }}>{roundFloat(visibleEdit.rowData?.zuruu_dutuu_niit_base, 3)}</td>
                      </tr>
                      <tr className="ant-table-row ant-table-row-level-0 table-row-class">
                        <td className="ant-table-cell" style={{ width: '100px', margin: '10px' }}>НӨАТ</td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px' }}>
                          <Input type="number" className="ant-input" size="small" style={{ width: '130px' }} value={visibleEdit.rowData?.toh_hereg_noat} onChange={onChangeHeregNoat} />
                        </td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px' }}>{roundFloat(visibleEdit.rowData?.zuruu_dutuu_noat, 3)}</td>
                        <td className="ant-table-cell ant-table-cell-ellipsis" style={{ width: '150px', margin: '10px' }}>{roundFloat(visibleEdit.rowData?.zuruu_dutuu_noat_base, 3)}</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              <Row style={{ marginTop: 10 }}>
                <Col span={24} className="text-align-r">
                  <Form.Item noStyle>
                    <Button
                      onClick={() => {
                        seTvisibleEdit({
                          visible: false,
                          rowData: {},
                        });
                        seTnote(null);
                        onCancel();
                      }}
                      className="cancel-btn"
                      type="primary"
                      shape="round"
                    >
                      Буцах
                    </Button>
                    <Button
                      className="confirm-btn ml-5"
                      type="primary"
                      shape="round"
                      onClick={onFinish}
                    >
                      Хадгалах
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
