import { roundFloat } from 'src/core/utils/formater';

const setRound = (_) => roundFloat(_);
export const exampleColumn = [
  {
    title: 'Үзүүлэлт',
    dataIndex: 'uzuulelt',
    align: 'left',
    key: 'uzuulelt',
    width: 150,
    render: (_, row, index) => _,
  },
  {
    width: 150,
  },
  {
    title: 'Эхний үлдэгдэл',
    align: 'center',
    children: [
      {
        title: '1.1. Түүхий эд',
        dataIndex: 'tuuhii_ed',
        align: 'center',
        key: 'tuuhii_ed', // Key updated to match JSON structure
        width: 150,
        render: setRound,
      },
      {
        title: '1.2. Дуусаагүй үйлдвэрлэл',
        dataIndex: 'duusaagui_uild', // Updated dataIndex to match JSON structure
        align: 'center',
        key: 'duusaagui_uild', // Key updated to match JSON structure
        width: 150,
        render: setRound,
      },
      {
        title: '1.3. Бэлэн бүтээгдэхүүн',
        dataIndex: 'belen_but', // Updated dataIndex to match JSON structure
        align: 'center',
        key: 'belen_but', // Key updated to match JSON structure
        width: 150,
        render: setRound,
      },
      {
        title: '1.4. Бараа',
        dataIndex: 'baraa', // Updated dataIndex to match JSON structure
        align: 'center',
        key: 'baraa', // Key updated to match JSON structure
        width: 150,
        render: setRound,
      },
      {
        title: '1.5. Хангамжийн материал',
        dataIndex: 'hangamj_mat', // Updated dataIndex to match JSON structure
        titleAlign: 'center',
        align: 'center',
        key: 'hangamj_mat', // Key updated to match JSON structure
        width: 150,
        render: setRound,
      },
      {
        title: '1.6.Бусад',
        dataIndex: 'busad', // Updated dataIndex to match JSON structure
        titleAlign: 'center',
        align: 'center',
        key: 'busad', // Key updated to match JSON structure
        width: 150,
        render: setRound,
      },
      {
        title: '2. Нийт дүн',
        dataIndex: 'niit', // Updated dataIndex to match JSON structure
        titleAlign: 'center',
        align: 'center',
        key: 'niit', // Key updated to match JSON structure
        width: 150,
        render: setRound,
      },
    ],
  },
];

export const expandColumns = ({ title = 'no Title' }) => [
  {
    key: 'title',
    align: 'center',
    width: 150,
    render: (_, row, index) => {
      const obj = {
        children: title,
        props: {},
      };
      if (!index) {
        if (parseInt(row.mur) > 10 && parseInt(row.mur) < 25) {
          obj.props.rowSpan = 14;
        } else if (parseInt(row.mur) < 10 && parseInt(row.mur) > 4) {
          obj.props.rowSpan = 5;
        } else if (parseInt(row.mur) < 37 && parseInt(row.mur) > 33) {
          obj.props.rowSpan = 3;
        } else if (parseInt(row.mur) < 49 && parseInt(row.mur) > 43) {
          obj.props.rowSpan = 5;
        } else if (parseInt(row.mur) < 66 && parseInt(row.mur) > 61) {
          obj.props.rowSpan = 5;
        }
      }
      if (index) {
        obj.props.rowSpan = 0;
      }
      return obj;
    },
  },
  {
    // title: '2. Нийт дүн',
    dataIndex: 'lvl1',
    titleAlign: 'center',
    align: 'center',
    key: 'lvl1',
    width: 150,
  },
  {
    // title: '1.1. Түүхий эд',
    dataIndex: 'tuuhii_ed',
    align: 'center',
    key: 'tuuhii_ed',
    width: 150,
    render: setRound,
  },
  {
    // title: '1.2. Дуусаагүй үйлдвэрлэл',
    dataIndex: 'duusaagui_uild',
    align: 'center',
    key: 'duusaagui_uild',
    width: 150,
    render: setRound,
  },
  {
    // title: '1.3. Бэлэн бүтээгдэхүүн',
    dataIndex: 'belen_but',
    align: 'center',
    key: 'belen_but',
    width: 150,
    render: setRound,
  },
  {
    // title: '1.4. Бараа',
    dataIndex: 'baraa',
    align: 'center',
    key: 'baraa',
    width: 150,
    render: setRound,
  },
  {
    // title: '1.5. Хангамжийн материал',
    dataIndex: 'hangamj_mat',
    titleAlign: 'center',
    align: 'center',
    key: 'hangamj_mat',
    width: 150,
    render: setRound,
  },
  {
    // title: '1.6.Бусад',
    dataIndex: 'busad',
    titleAlign: 'center',
    align: 'center',
    key: 'busad',
    width: 150,
    render: setRound,
  },
  {
    // title: '2. Нийт дүн',
    dataIndex: 'niit',
    titleAlign: 'center',
    align: 'center',
    key: 'niit',
    width: 150,
    render: setRound,
  },
];

export const temp_data = [
  {
    uzuulelt: ' 1. Эхний үлдэгдэл (өртгөөр)',
    dun: 480000.0,
  },
  {
    uzuulelt: ' 2. Нэмэгдсэн дүн',
    dun: 480000.0,
  },
  {
    uzuulelt: '3. Хасагдсан дүн',
    dun: 480000.0,
  },
  {
    uzuulelt: '4. Эцсийн үлдэгдэл (өртгөөр)',
    dun: 480000.0,
  },
  {
    uzuulelt: '5. Үнийн бууралтын гарз(-)',
    dun: 480000.0,
  },
  {
    uzuulelt: '6. Үнийн бууралтын буцаалт',
    dun: 480000.0,
  },
  {
    uzuulelt: '7. Дансны цэвэр дүн:',
    dun: 480000.0,
    subRow: {
      title: '7',
      child: [
        {
          lvl1: '7.1 Эхний үлдэгдэл',
          mur: '5',
          dun: 10000.0,
        },
        {
          lvl1: '7.2 Эцсийн үлдэгдэл',
          mur: '6',
          dun: 20000.0,
        },
      ],
    },
  },
];
