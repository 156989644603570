import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import axios from 'src/core/utils/axios';

import { Table } from 'src/components';
import { Button, Modal } from 'antd';
import {
  PrinterOutlined,
  ExportOutlined,
  AuditOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { customColumns } from './dataStructure/data';
import NoatModal from './modals/noatModal';

const FileDownload = require('js-file-download');

const tText = 'T6_1_TailanTatvar';
const tTitle = `${tText}.tableText.title`;
const tailan_type_map = {
  tt02: {
    file_name: 'ААНОАТ-ын тайлан.xlsx',
    action_type_secondary: 'export_sanhuu_tt02',
  },
  negtgel: {
    file_name: 'ААНОАТ-ын тайлан (ХМ).xlsx',
    action_type_secondary: 'export_sanhuu_tailan',
  },
};

const MainPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const componentRef = useRef(null);
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [noatModuleOpen, setNoatModuleOpen] = useState(false);
  const [noatData, setNoatData] = useState({});

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Маягт TT-02',
  });

  const titleTextRender = (item, key) => {
    const { title, ..._item } = item;
    return {
      title: t(`${tTitle}.${key}`, item?.title),
      ..._item,
    };
  };

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        if (t) {
          const arr = data.content.json_array.map((i, key) => {
            if (i?.title) {
              return titleTextRender(i, key);
            }
            return i;
          });
          setTableData(arr);
        }
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss005_company_manage',
      content: {
        receive_channel: 'ts001_02_tailan_control',
        action_type: 'get_uil_ajillagaa_for_tailan',
        action_type_secondary: 'get_sanhuu_tt02',
        month_start: '1',
        month_end: String(month),
      },
    });

    dispatch(start_loading({}));
  };

  const generateNoatTulgalt = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data?.status === '0') {
        setNoatData(data?.content?.result);
        setNoatModuleOpen(true);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss005_company_manage',
      content: {
        receive_channel: 'ss013_03_api',
        action_type: 'get_uil_ajillagaa_for_tailan',
        action_type_secondary: 'api_niit_dun',
        month_start: '1',
        month_end: String(data_date.month),
      },
    });
    dispatch(start_loading({}));
  };

  // const prepareDashboard = () => {
  //   socket.on('request_out', (data) => {
  //     socket.removeAllListeners('request_out');

  //     if (data.status === '0') {
  //       newAlert({ type: 'success', msg: 'Амжилттай' });
  //     } else {
  //       newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
  //     }
  //     dispatch(end_loading({}));
  //   });
  //   socket.emit('request_in', {
  //     channel: 'ss005_company_manage',
  //     content: {
  //       receive_channel: 'ts001_02_dashboard',
  //       action_type: 'get_uil_ajillagaa_for_tailan',
  //       action_type_secondary: 'prepare_data',
  //       month: data_date.month,
  //     },
  //   });

  //   dispatch(start_loading({}));
  // };

  /**
   *
   * @param {*} tailan_type tailan type for download request
   */
  const exportTailan = (tailan_type) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        const file_export_address_link = `/get_file?file_id=${data.content.file_id}`;
        axios.get('export', file_export_address_link, {}, {}, (event) => {
          FileDownload(event, tailan_type_map[tailan_type].file_name);
        });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss005_company_manage',
      content: {
        receive_channel: 'ts001_02_tailan_control',
        action_type: 'get_uil_ajillagaa_for_tailan',
        action_type_secondary:
          tailan_type_map[tailan_type].action_type_secondary,
        month_start: '1',
        month_end: String(data_date.month),
      },
    });

    dispatch(start_loading({}));
  };

  const closeNoatModal = () => {
    setNoatModuleOpen(false);
    setNoatData({});
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
  }, [data_date, t]);

  const title = () => (
    <>
      <div className="table-title-text">
        {t(
          `${tText}.tableTitle`,
          'Аж ахуйн нэгжийн орлогын албан татварын тайлан'
          // 'АЖ АХУЙН НЭГЖИЙН ОРЛОГЫН АЛБАН ТАТВАРЫН ТАЙЛАН'
        )}
      </div>
      {/* <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<ExportOutlined />}
        onClick={() => {
          prepareDashboard();
        }}
      >
        {t(`${tText}.finalizeBtn`, 'Дашборд')}
      </Button> */}
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<AuditOutlined />}
        onClick={() => {
          generateNoatTulgalt();
        }}
      >
        НӨАТ-ын тайлан тулгалт
      </Button>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<PrinterOutlined />}
        onClick={() => {
          handlePrint();
        }}
      >
        {t(`${tText}.printBtn`, 'Хэвлэх')}
      </Button>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<ExportOutlined />}
        onClick={() => {
          exportTailan('tt02');
        }}
      >
        {t(`${tText}.exportBtn`, 'Экспорт')}
      </Button>
      {/* <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<ExportOutlined />}
        onClick={() => {
          exportTailan('negtgel');
        }}
      >
        {t(`${tText}.exportAllBtn`, 'Экспорт (ХМ)')}
      </Button> */}
    </>
  );

  return (
    <>
      <div className="table-antd-div tailan-container" ref={componentRef}>
        <Table
          columns={customColumns({ t })}
          dataSource={tableData}
          isTitle
          title={title}
        />
      </div>
      <Modal
        className="custom-modal"
        title="НӨАТ-ын тайлан тулгалт"
        open={noatModuleOpen}
        onCancel={() => {
          closeNoatModal(false);
        }}
        width={900}
        footer={null}
        centered
      >
        <NoatModal
          data={noatData}
          onClose={() => {
            closeNoatModal(false);
          }}
        />
      </Modal>
    </>
  );
};

export default MainPage;
