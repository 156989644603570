export const start_loading = (json_data) => ({
  type: 'start_loading',
  payload: json_data,
});
export const end_loading = (json_data) => ({
  type: 'end_loading',
  payload: json_data,
});

export const is_loading = (isLoading) => ({
  type: 'is_loading',
  payload: isLoading,
});
