import React, { useState, useEffect } from 'react';
import { Table, InputNumber, Row, Col, Spin, Button, Collapse } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { roundFloat, replaceFloat } from 'src/core/utils/formater';
import debounce from 'lodash.debounce';

const { Panel } = Collapse;

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editingValue, setEditingValue] = useState(null);

  // Debounce handler for the value change to avoid immediate rounding
  const debouncedSave = debounce((value) => {
    const roundedValue = roundFloat(value); // Round after debounce
    handleSave(record.key, dataIndex, roundedValue);
  }, 300); // Adjust debounce delay (300ms here)

  const handleChange = (value) => {
    setEditingValue(value); // Temporarily hold the raw input before rounding
    debouncedSave(value);
  };

  useEffect(
    () =>
      // Clean up debounce on unmount
      () => {
        debouncedSave.cancel();
      },
    []
  );

  let childNode = children || '';

  if (editable && record) {
    childNode = (
      <InputNumber
        min={0}
        value={editingValue !== null ? editingValue : record[dataIndex]}
        onChange={handleChange}
        className="wide-input-number" // Apply the custom class
        step={0.01} // Allow smooth input for decimal numbers
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const App = () => {
  const initialData = [
    {
      key: '1',
      title: '1. ҮНДСЭН ХӨРӨНГӨ /ӨРТӨГ/',
    },
    {
      key: '1.1',
      category: '1.1 Эхний үлдэгдэл',
      value1: 100.456,
      value2: 200.789,
      value3: 300,
      value4: 400.123,
      value5: 500.456,
      value6: 600.789,
      value7: 700,
      value8: 800.123,
      editable: false,
      lvl2: true,
    },
    {
      key: '1.2',
      category: '1.2 Нэмэгдсэн дүн',
      value1: 100.456,
      value2: 200.789,
      value3: 300,
      value4: 400.123,
      value5: 500.456,
      value6: 600.789,
      value7: 700,
      value8: 800.123,
      lvl2: true,
      editable: true,
    },
    {
      key: '1.2.1',
      category: '1.2.1 Хэрэгжүүлэх арга хэмжээ',
      value1: 100.456,
      value2: 200.789,
      value3: 300,
      value4: 400.123,
      value5: 500.456,
      value6: 600.789,
      value7: 700,
      value8: 800.123,
      editable: true,
      sub: true,
    },
    // Add more data here based on your image
  ];

  const [dataSource, setDataSource] = useState(initialData);
  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleSend = async () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: get_data(data_date.month) },
      });
      dispatch(end_loading({}));
    });
    const content = {
      month: data_date?.month,
      action_type: 'save_sa10',
      table_data: dataSource,
      text,
    };

    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content,
    });
    dispatch(start_loading({}));
  };
  const get_data = (month) => {
    socket.on('request_out', ({ status, content }) => {
      console.log('🚀 ~ socket.on ~ content:', content);
      socket.removeAllListeners('request_out');
      if (status === '0') {
        setDataSource(content?.table_data);
        setText(content?.text);
      } else {
        newAlert({ torol: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'get_sa10',
        month,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    get_data(data_date.month);
  }, []);

  const handleSave = (key, dataIndex, value) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];

    // Round the value before saving it
    const roundedValue = replaceFloat(value);
    console.log('Original value:', value);
    console.log('Rounded value:', roundedValue);
    // Update the data source with the rounded value
    newData.splice(index, 1, { ...item, [dataIndex]: roundedValue });

    setDataSource(newData);
  };

  const columns = [
    {
      title: 'Үзүүлэлт',
      dataIndex: 'category',
      key: 'category',
      width: 400,
      align: 'left',
      render: (text, record) => {
        if (record.title) {
          return {
            children: <strong>{record.title}</strong>,
            props: {
              colSpan: 9,
            },
          };
        }
        return (
          <span
            className={record.sub ? 'sub-row' : record.lvl2 ? 'lvl2-row' : ''}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: 'Зохиогчийн эрх',
      dataIndex: 'value1',
      key: 'value1',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Компьютерийн программ хангамж',
      dataIndex: 'value2',
      key: 'value2',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Мэдээллийн сан',
      dataIndex: 'value3',
      key: 'value3',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Патент',
      dataIndex: 'value4',
      key: 'value4',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Барааны тэмдэг',
      dataIndex: 'value5',
      key: 'value5',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Тусгай зөвшөөрөл',
      dataIndex: 'value6',
      key: 'value6',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Газар эзэмших эрх',
      dataIndex: 'value7',
      key: 'value7',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Хөрөнгөөр хүлээн зөвшөөрсөн хөгжүүлэлтийн хөрөнгө',
      dataIndex: 'value8',
      key: 'value8',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Бусад биет бус хөрөнгө',
      dataIndex: 'value9',
      key: 'value9',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Нийт дүн',
      dataIndex: 'sum',
      key: 'sum',
      editable: false,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable:
          record.editable && col.dataIndex !== 'sum' ? col.editable : false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div className="tax-account-table-container">
      <Collapse
        defaultActiveKey={['4']}
        expandIconPosition="right"
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ? <UpOutlined /> : <DownOutlined />
        }
      >
        <Panel header="10. Биет бус хөрөнгө" key="1">
          <Table
            dataSource={dataSource}
            columns={mergedColumns}
            rowClassName={() => 'editable-row'}
            bordered
            pagination={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            footer={() => (
              <Button
                type="primary"
                className="tax-account-button-unique-mini"
                onClick={() => handleSend(1)}
              >
                Хадгалах
              </Button>
            )}
          />
        </Panel>

        <Panel
          header="10.1 Тэмдэглэл (Биет бус хөрөнгийн анги бүрийн хувьд ашигласан хэмжилтийн суурь, хорогдол тооцох арга, ашиглалтын хугацаа, дахин үнэлсэн бол дахин үнэлгээ хүчинтэй болсон хугацаа, хараат бус үнэлгээчин үнэлсэн эсэх, бусад биет бус хөрөнгийн бүрэлдэхүүн болон бусад тайлбар тэмдэглэлийг хийнэ.)"
          key="4"
        >
          <div className="tax-account-notes-container">
            <table className="tax-account-table-unique">
              <thead>
                <tr>
                  <th>Тайлбар</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <textarea
                      value={text}
                      onChange={handleChange}
                      rows={10}
                      className="tax-account-textarea-unique"
                      placeholder="Бөглөнө үү"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <Button
              type="primary"
              className="tax-account-button-unique-mini"
              onClick={() => handleSend(2)}
            >
              Хадгалах
            </Button>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default App;
