import React from 'react';
import { Button, Form, Row, Col, Modal } from 'antd';
import { Table } from 'src/components';
import { ErrorListColumns } from './dataStructure/data';

export const ErrorList = ({
  onCancel = () => {},
  errorList = {
    have_error: false,
    errors: [],
    burtgel_errors: {},
  },
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      className="custom-modal"
      title="Эхний үлдэгдэл оруулахад гарсан алдаа"
      open={errorList.have_error}
      closable={false}
      maskClosable={false}
      width="80%"
      footer={null}
      onCancel={onCancel}
    >
      <div className="dans-medeelel-container">
        <Form
          form={form}
          className="register-form"
          size="small"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          {errorList?.errors?.length > 0 ? (
            <Table
              columns={ErrorListColumns()}
              dataSource={errorList?.errors}
              scroll={{
                y: 'calc(100vh - 330px)',
              }}
            />
          ) : (
            Object.keys(errorList?.burtgel_errors).map((i) => (
              <p>
                <span className="ml-3">
                  {i} бүртгэлийн дугаар давхцсан байна
                </span>
              </p>
            ))
          )}
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <Button
                  onClick={() => {
                    onCancel();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
