// import React from 'react';

export const ColumnsMain = () => [
  {
    title: 'Борлуулсан ААН-ийн нэр',
    dataIndex: 'har_ner',
    align: 'left',
    width: 200,
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Харилцагчийн РД',
    dataIndex: 'har_reg',
    align: 'left',
    fixed: 'left',
    width: 100,
  },
  {
    title: 'Бичигдсэн сар',
    dataIndex: 'month',
    align: 'left',
    width: 75,
  },
  {
    title: 'Барааны нэр',
    dataIndex: 'baraa_ner',
    align: 'left',
    width: 150,
    ellipsis: true,
  },
  {
    title: 'Дугаар',
    dataIndex: 'noat_dugaar',
    align: 'left',
    width: 150,
  },
  {
    title: 'Огноо',
    dataIndex: 'noat_ognoo',
    align: 'left',
    width: 100,
  },
  {
    title: 'НӨАТ-гүй үнэ',
    dataIndex: 'noat_oroogui',
    align: 'left',
    width: 150,
  },
  {
    title: 'НӨАТ',
    dataIndex: 'noat_dun',
    align: 'left',
    width: 150,
  },
  {
    title: 'Нийт үнэ',
    dataIndex: 'noat_niit',
    align: 'left',
    width: 150,
  },
  {
    title: 'Тайлбар',
    dataIndex: 'note',
    align: 'left',
    width: 150,
    ellipsis: true,
  },
];

export const customSummary = [
  {
    word: ' ',
  },
  {
    align: 'right',
    word: 'Нийт дүн:',
  },
  {
    word: ' ',
  },
  {
    word: ' ',
  },
  {
    word: ' ',
  },
  {
    word: ' ',
  },
  {
    word: ' ',
  },
  {
    align: 'right',
    value: 'noat_oroogui',
  },
  {
    align: 'right',
    value: 'noat_dun',
  },
  {
    align: 'right',
    value: 'noat_niit',
  },
  {
    word: ' ',
  },
];
