import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { Button as AntButton } from 'antd';

export const customDansSummary = [
  {
    align: 'right',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'dans_une',
  },
  {
    align: 'right',
    value: 'tatvar_suuri',
  },
  {
    align: 'right',
    value: 'zuruu',
  },
  {
    align: 'right',
    value: 'du_nemegdel',
  },
  // {
  //   align: 'right',
  //   value: 'zuruu_uurchlult',
  // },
  // {
  //   align: 'right',
  //   value: 'du_nemegdel_uurchlult',
  // },
];

export const customSummary = [
  {
    value: null,
  },
  {
    value: null,
  },
  {
    align: 'right',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'dans_une',
  },
  {
    align: 'right',
    value: 'tatvar_suuri',
  },
  {
    align: 'right',
    value: 'zuruu',
  },
  {
    align: 'right',
    value: 'du_nemegdel',
  },
  // {
  //   align: 'right',
  //   value: 'zuruu_uurchlult',
  // },
  // {
  //   align: 'right',
  //   value: 'du_nemegdel_uurchlult',
  // },
];

export const ColumnsDansMain = () => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Дансны үнэ',
    dataIndex: 'dans_une',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'tatvar_suuri',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'ДҮ-ТС зөрүү',
    children: [
      {
        title: 'Нийт',
        dataIndex: 'zuruu',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Үүнээс ДҮНэмэгдэлд ногдох',
        dataIndex: 'du_nemegdel',
        align: 'right',
        width: 210,
        render: (_) => roundFloat(_),
      },
    ],
  },
  // {
  //   title: 'Тайлант үеийн өөрчлөлт',
  //   children: [
  //     {
  //       title: 'Нийт',
  //       dataIndex: 'zuruu_uurchlult',
  //       align: 'right',
  //       width: 150,
  //       render: (_) => <>{_ ? roundFloat(_) : <></>}</>,
  //     },
  //     {
  //       title: 'Үүнээс ДҮНэмэгдэлд ногдох',
  //       dataIndex: 'du_nemegdel_uurchlult',
  //       align: 'right',
  //       width: 210,
  //       render: (_) => <>{_ ? roundFloat(_) : <></>}</>,
  //     },
  //   ],
  // },
];

export const ColumnsMain = ({ onSelectHurungu = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    align: 'left',
    width: 120,
    fixed: 'left',
    render: (_, row) => (
      <AntButton
        align="center"
        type="link"
        size="small"
        className="underline"
        onClick={() => onSelectHurungu(row)}
      >
        {row?.code}
      </AntButton>
    ),
  },
  {
    title: 'Хөрөнгийн нэр',
    dataIndex: 'ner',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Дансны үнэ',
    dataIndex: 'dans_une',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'tatvar_suuri',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'ДҮ-ТС зөрүү',
    children: [
      {
        title: 'Нийт',
        dataIndex: 'zuruu',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Үүнээс ДҮНэмэгдэлд ногдох',
        dataIndex: 'du_nemegdel',
        align: 'right',
        width: 210,
        render: (_) => roundFloat(_),
      },
    ],
  },
  // {
  //   title: 'Тайлант үеийн өөрчлөлт',
  //   children: [
  //     {
  //       title: 'Нийт',
  //       dataIndex: 'zuruu_uurchlult',
  //       align: 'right',
  //       width: 150,
  //       render: (_) => <>{_ ? roundFloat(_) : <></>}</>,
  //     },
  //     {
  //       title: 'Үүнээс ДҮНэмэгдэлд ногдох',
  //       dataIndex: 'du_nemegdel_uurchlult',
  //       align: 'right',
  //       width: 210,
  //       render: (_) => <>{_ ? roundFloat(_) : <></>}</>,
  //     },
  //   ],
  // },
];

export const ColumnsBurtgel = ({ setBurtgelDataRow = () => {} }) => [
  {
    title: 'Бүртгэлийн дугаар',
    dataIndex: 'burtgel_dugaar',
    className: 'children-100',
    width: 150,
    fixed: 'left',
    // eslint-disable-next-line no-confusing-arrow
    render: (_, row) =>
      row?.not_tax ? (
        <>{row?.burtgel_dugaar}</>
      ) : (
        <AntButton
          align="center"
          type="link"
          size="small"
          className="underline"
          onClick={() => setBurtgelDataRow(row)}
        >
          {row?.burtgel_dugaar}
        </AntButton>
      ),
  },
  {
    title: 'Дансны үнэ',
    dataIndex: 'dans_une',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'tatvar_suuri',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'ДҮ-ТС зөрүү',
    children: [
      {
        title: 'Нийт',
        dataIndex: 'zuruu',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Үүнээс ДҮНэмэгдэлд ногдох',
        dataIndex: 'du_nemegdel',
        align: 'right',
        width: 210,
        render: (_) => roundFloat(_),
      },
    ],
  },
  // {
  //   title: 'Тайлант үеийн өөрчлөлт',
  //   children: [
  //     {
  //       title: 'Нийт',
  //       dataIndex: 'zuruu_uurchlult',
  //       align: 'right',
  //       width: 150,
  //       render: (_) => <>{_ ? roundFloat(_) : <></>}</>,
  //     },
  //     {
  //       title: 'Үүнээс ДҮНэмэгдэлд ногдох',
  //       dataIndex: 'du_nemegdel_uurchlult',
  //       align: 'right',
  //       width: 210,
  //       render: (_) => <>{_ ? roundFloat(_) : <></>}</>,
  //     },
  //   ],
  // },
];
