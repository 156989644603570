import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { orderBy, uniqBy } from 'lodash';

export const roundFloat = (floatNumber, precision = 2) => {
  if (floatNumber === undefined) return '';
  const _floatNumber = Number(floatNumber);
  const number = (Math.round(Math.abs(_floatNumber) * 100) / 100).toFixed(
    precision
  );
  const _number = Number(number).toLocaleString('en-US', {
    minimumFractionDigits: 2,
  });
  return _floatNumber >= 0 ? _number : `(${_number})`;
};

export const onlyNumber = (value = '') => {
  if (typeof value === 'undefined') return;
  // eslint-disable-next-line no-param-reassign
  if (typeof value !== 'string') value = value.toString();
  const newValue = value
    .replace(/[^.\d.-]/g, '')
    .replace(/^(-?\d*\.?)|(\d*)\.?/g, '$1$2');
  const index = newValue.indexOf('.');
  if (index !== -1) {
    const front = newValue.substring(0, index);
    const newFront = front.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,');
    const back = newValue.substring(index, newValue.length);
    // eslint-disable-next-line consistent-return
    return `${newFront}${back}`;
  }
  // eslint-disable-next-line consistent-return
  return newValue.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,');
};

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const replaceFloat = (item = '') => {
  if (typeof item !== 'string') {
    return parseFloat(item);
  }
  const formated = item.length > 0 && item.replaceAll(',', '');
  return parseFloat(formated || item);
};

export const orderByUniq = (arr = [], param = '') =>
  orderBy(
    uniqBy(arr, param).map((i) => ({
      text: `${i[param]}`,
      value: i[param],
    })),
    ['value'],
    ['asc']
  );

const check = (e = '') => {
  if (!e) return;
  const item = e.replace('-', '');
  const customRegex = /^[0-9-+()]*$/;
  return item.match(customRegex);
};

export const phoneNumber = (value = '') => {
  console.log(value);
  if (!value) return;
  const len = value.length;

  if (!check(value[len - 1])) {
    return value.substring(0, len - 1);
  }

  if (len === 5) {
    if (value[4] === '-') {
      return value.substr(0, 4);
    }
    return `${value.substr(0, 4)}-${value.substr(4, 5)}`;
  }
  if (len > 5 && len < 9) {
    if (value[4] === '-') {
      return value;
    }
    return `${value.substr(0, 4)}-${value.substr(4, 9)}`;
  }

  if (len >= 9) {
    return value.substr(0, 9);
  }

  return value;
};
