import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// antd changed
import { CheckOutlined, WarningOutlined } from '@ant-design/icons';
import { Card, List, Checkbox as AntCheckBox, Popover } from 'antd';
import { set } from 'lodash';
import { roundFloat } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import Table from 'src/components/common/table';
import { start_loading, end_loading } from 'src/core/actions/loader';
import CanView from 'src/components/permission/canview';

const Client = ({
  radioValue = 'first',
  tableData = [],
  seTradioValue = () => {},
  seTdisable,
  setActiveItem = () => {},
  checkDisable = () => {},
  setTableData = () => {},
  // temp
}) => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const [error, seTerror] = useState(false);
  // zovshoorogdson condition state
  const [approved, seTapproved] = useState({
    first: '0',
    second: '0',
  });

  // const text = 'ААНОАТ-ын тухай хуулийн дагуу хөнгөлөлт эдлэх боломжгүй байна';
  const [hongololtDun, setHongololtDun] = useState({
    niitleg_orlogo: '0',
    hasagdah_zardal: '0',
    tatvar_noogduulah: '0',
  });

  const data = [
    {
      title: 'Нийтлэг хувь хэмжээтэй орлогын дүн:',
      value: roundFloat(hongololtDun.niitleg_orlogo),
    },
    {
      title: 'Хасагдах зардлын дүн:',
      value: roundFloat(hongololtDun.hasagdah_zardal),
    },
    {
      title: 'Татвар ногдуулах орлогын дүн:',
      value: roundFloat(hongololtDun.tatvar_noogduulah),
    },
  ];

  const content = {
    lawyer: (
      <div className="white">
        <WarningOutlined className="white" /> ААНОАТ-ын тухай хуулийн дагуу
        хөнгөлөлт <br /> эдлэх боломжгүй байна
      </div>
    ),
    permission: (
      <div className="white">
        <WarningOutlined className="red" /> Энэ үйлдлийг хийх эрх байхгүй байна
      </div>
    ),
  };

  // hyazgaalasan hongololt_id list
  const restrictId = [1, 5, 10];

  const generateError = (arr) => {
    if (arr.every((i) => i.state === '0')) {
      return arr;
    }

    let finded = null;
    arr.forEach((i) => {
      if (i?.state === '1') {
        console.log(i);
        const err = restrictId.find((k) => k === i.id);
        if (err) {
          finded = err;
        }
      }
    });

    if (finded) {
      return arr.map((i) => (finded === i.id ? i : { error: true, ...i }));
    }
    return arr.map((i) => {
      if (restrictId.includes(i?.id)) {
        return {
          error: true,
          ...i,
        };
      }
      return i;
    });
  };

  const updateMyData = (rowIndex, columnId, value) => {
    let allClear = false;
    setTableData((old) => {
      if (value === '0') {
        allClear = checker(old, rowIndex);
      }
      return old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        if (allClear) {
          set(row, 'error', false);
          return row;
        }
        const _checker = restrictId.find((i) => i === row?.id);

        if (restrictId.find((i) => i === rowIndex)) {
          set(row, 'error', !row?.error);
        } else if (_checker) {
          set(row, 'error', true);
        }

        return row;
      });
    });
  };

  const newColumns = [
    {
      title: '№',
      dataIndex: 'row',
      key: 'row',
      width: 30,
      align: 'center',
      render: (_, row) => <span>{row?.id + 1}</span>,
    },
    {
      title: 'Хөнгөлөлтийн төрөл',
      dataIndex: 'torol',
      titleAlign: 'left',
      key: 'torol',
    },
    {
      title: () => <CheckOutlined />,
      dataIndex: 'state',
      key: 'state',
      align: 'center',
      width: 50,
      render: (_, row, rowIndex) => {
        const { state, disabled } = row;
        return (
          <CanView allowedRole="002_1">
            <AntCheckBox
              disabled={disabled || row?.error}
              checked={state === '1'}
              onChange={({ target }) => {
                const { checked } = target;
                updateMyData(rowIndex, 'state', checked ? '1' : '0');
              }}
            />
          </CanView>
        );
      },
    },
  ];

  const mainData = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log(content);
      if (status === '0') {
        const temp_data = content.hongololt_dun;
        const {
          zowshoorolgui_state,
          zowshooroltei_state,
          prev_type,
          disabled_state,
        } = content;
        if (zowshoorolgui_state === '1' && zowshooroltei_state === '1') {
          seTdisable(true);
        }

        seTapproved({
          first: zowshoorolgui_state,
          second: zowshooroltei_state,
        });

        setHongololtDun({
          niitleg_orlogo: temp_data?.niitleg_orlogo,
          hasagdah_zardal: temp_data?.hasagdah_zardal,
          tatvar_noogduulah: temp_data?.tatvar_noogduulah,
        });
        seTradioValue(prev_type);

        const new_temp_data = [];
        for (const hongololt_list in content.hongololt_22_5_types) {
          const tempdata = content.hongololt_22_5_types[hongololt_list];
          const new_row_data = {
            hongololt_id: String(tempdata.hongololt_id),
            torol: String(tempdata.torol_ner),
            state: String(tempdata.state),
            id: new_temp_data.length,
            disabled: tempdata?.disabled,
          };
          new_temp_data.push(new_row_data);
        }

        if (new_temp_data) {
          const arr = generateError(new_temp_data);
          setTableData(arr);
        }
        newAlert({ type: 'success', msg: content.Message });
        dispatch(end_loading({}));
      } else {
        seTapproved({
          first: content.zowshoorolgui_state,
          second: content.zowshooroltei_state,
        });

        if (
          content.zowshoorolgui_state === '1' &&
          content.zowshooroltei_state === '1'
        ) {
          seTdisable(true);
        }
        newAlert({ type: 'error', msg: content.Message });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts007_00_get_hongololt_data',
      content: {
        month_end: data_date.month,
      },
    });

    dispatch(start_loading({}));
  };
  useEffect(() => {
    mainData();
  }, [data_date.month]);

  useEffect(() => {
    checkDisable(error);
  }, [error]);

  useEffect(() => {
    if (tableData.length > 0) {
      tableData.forEach((item) => {
        const { hongololt_id, state } = item;
        if (state === '1') {
          const res = restrictId.find((i) => i === hongololt_id);
          if (res) {
            seTerror(true);
          }
        }
      });
      const _error = tableData.every((i) => i.state === '0');
      if (_error) {
        seTerror(false);
      }
    }
  }, [tableData]);

  const checker = (arr, id) => {
    const result = arr.filter((i) => {
      if (i.id === id) {
        return true;
      }
      return i.state !== '0';
    });
    return result.length === 1;
  };
  // --------------------------------

  const onChangeRadio = ({ target }) => {
    const { value } = target;
    seTradioValue(value);
  };

  const customLabel = (state) => {
    const title = state === '1' ? '22.1' : '22.5';
    const _class = state === '1' ? 'front-end ' : 'back-end';
    const _value = state === '1' ? 'first' : 'second';
    const _disabled = approved[_value] === '1';

    return (
      <label>
        <input
          type="radio"
          checked={radioValue === _value}
          value={_value}
          disabled={approved[_value] === '1'}
          onChange={onChangeRadio}
        />
        {_disabled ? (
          <Popover
            overlayClassName="Warning"
            content={
              content.lawyer === '1'
                ? content.permission === '1'
                : content.permission
            }
          >
            <div
              className={`${_class} box ${
                approved[_value] === '1' ? 'disabled' : ''
              }`}
            >
              <span> ААНОАТ-ын {title}</span>
            </div>
          </Popover>
        ) : (
          <div
            className={`${_class} box ${
              approved[_value] === '1' ? 'disabled' : ''
            }`}
          >
            <span> ААНОАТ-ын {title}</span>
          </div>
        )}
      </label>
    );
  };
  return (
    <div className="hungulult-step1-container">
      <div className="section-one">
        <Card title="Үзүүлэлт" className="custom-card">
          <List
            size="small"
            dataSource={data}
            className="custom-list"
            renderItem={(item) => (
              <List.Item>
                <span>{item.title}</span>
                <span>{item.value}</span>
              </List.Item>
            )}
          />
        </Card>
        <Card title="Хөнгөлөлтийн төрөл сонгох" className="custom-main-card">
          <div className="middle">
            {customLabel('1', radioValue, approved)}
            {customLabel('2', radioValue, approved)}
          </div>
        </Card>
      </div>
      {radioValue === 'second' && (
        <div className="section-two">
          <Card title="" className="custom-body-card">
            <div className="table-container">
              <Table columns={newColumns} dataSource={tableData} />
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};
export default Client;
