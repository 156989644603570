import React, { useEffect, useState } from 'react';
import { Table as AntTable, Row, Col, Typography } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { isEmpty, isArray, get, omit, set } from 'lodash';
import { roundFloat } from 'src/core/utils/formater';
import Pagination from 'src/components/common/pagination';

const { Text } = Typography;

let _key = 0;

const AllColumnsLength = (columns) => {
  const sum = columns.reduce(
    // (prev, current) => prev + get(current, 'width', 1),
    // 0
    (prev, current) => prev + get(current, 'children.length', 1),
    0
  );
  return sum;
};

const ExpandedTable = ({
  columns = [],
  dataSource = [],
  scroll = {},
  rowSelection = {},
  hide = false,
  customize = null,
  summaryList = {},
  isTitle = false,
  isSticky = true,
  title = null,
  expandAll = false,
  total = null,
}) => {
  const [_dataSource, seTdataSource] = useState([]);
  const [_column, seTcolumn] = useState([]);
  const [loading, seTloading] = useState(true);

  const renderFirst = (_, row) => {
    if (!row?.title) {
      return {
        props: {
          colSpan: 0,
        },
      };
    }
    return {
      children: <span>{row?.title}</span>,
      props: {
        colSpan: AllColumnsLength(columns),
      },
    };
  };

  const renderContent = (value, row) => {
    const obj = {
      children: value,
      props: {},
    };
    if (row?.title) obj.props.colSpan = 0;
    return obj;
  };

  const findArrayKey = (arr) => {
    let findedKey = '';
    if (!arr || arr.lenght === 0) return;
    arr.forEach((i) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(i)) {
        if (isArray(value)) {
          findedKey = key;
          return;
        }
      }
    });
    // eslint-disable-next-line consistent-return
    return findedKey;
  };

  const generateArray = (arr, findedKey) => {
    if (!arr || arr.length === 0) return;
    // eslint-disable-next-line consistent-return
    return arr.map((i) => {
      if (get(i, `${findedKey}`, []).length > 0) {
        if (!i.render) {
          set(i, 'render', renderContent);
        }
        const _subChild = generateArray(get(i, `${findedKey}`, []), findedKey);
        const cleanData = omit(i, [`${findedKey}`]);
        return {
          children: [..._subChild],
          // eslint-disable-next-line no-plusplus
          key: ++_key,
          ...cleanData,
        };
      }
      return {
        ...i,
        // eslint-disable-next-line no-plusplus
        key: ++_key,
      };
    });
  };

  useEffect(() => {
    if (dataSource.length > 0) {
      const findedKey = findArrayKey(dataSource);
      let data = null;
      if (findedKey) {
        data = generateArray(dataSource, findedKey);
      } else {
        data = dataSource;
      }

      seTdataSource(data);
      seTloading(false);
    } else {
      seTdataSource([]);
    }
    seTloading(false);
  }, [dataSource]);

  let _children = [];

  useEffect(() => {
    if (columns.length > 0) {
      if (isTitle && dataSource.length !== 0) {
        const _columns = columns.map((i) => {
          const { children = [] } = i;
          if (children.length > 0) {
            _children = children.map((j) => {
              if (!j?.render) {
                set(j, 'render', renderContent);
              }
              return j;
            });
            set(i, 'children', _children);
          } else if (!i.render) {
            set(i, 'render', renderContent);
          }

          return i;
        });
        _columns.push({
          colSpan: 0,
          render: renderFirst,
        });
        seTcolumn(_columns);
      } else {
        seTcolumn([...columns]);
      }
    }
  }, []);

  if (loading) {
    // return (
    //   <div
    //     style={{
    //       textAlign: 'center',
    //     }}
    //   >
    //     loading....
    //   </div>
    // );
    return '';
  }

  const _footer = () => <Pagination total={total} />;

  return (
    <div>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <AntTable
            title={title}
            className="custom-antd-table"
            columns={_column}
            // bordered
            sticky={isSticky}
            dataSource={_dataSource}
            rowSelection={!isEmpty(rowSelection) ? rowSelection : undefined}
            scroll={scroll}
            pagination={false}
            size="small"
            locale={{
              emptyText: <span>Дата байхгүй</span>,
              filterConfirm: <span>Шүүлт хийх</span>,
              filterEmptyText: <span>Дата байхгүй</span>,
              filterReset: <span>Шүүлтийг цэвэрлэх</span>,
            }}
            onHeaderRow={
              (column) =>
                column.map((i) => {
                  set(i, 'align', i?.titleAlign || 'center');
                  return i;
                })
              // eslint-disable-next-line react/jsx-curly-newline
            }
            rowClassName={
              (record, res, expanded) =>
                `table-row-class ${expanded ? 'expanded' : ''} ${
                  record?.title ? 'title' : ''
                } ${!record?.children && !expanded ? 'non-expanded' : ''} ${
                  record?.mor_type === 'sum' ? 'unique-row' : ''
                }`
              // eslint-disable-next-line react/jsx-curly-newline
            }
            expandable={{
              defaultExpandAllRows: expandAll,
              // eslint-disable-next-line no-confusing-arrow
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded && record?.children ? (
                  <DownOutlined
                    className="icon-style"
                    onClick={(e) => onExpand(record, e)}
                  />
                ) : (
                  record?.children && (
                    <RightOutlined
                      className="icon-style"
                      onClick={(e) => onExpand(record, e)}
                    />
                  )
                ),
            }}
            summary={
              // eslint-disable-next-line operator-linebreak
              customize ||
              (() =>
                // eslint-disable-next-line operator-linebreak
                !isEmpty(summaryList) &&
                summaryList?.sumDataSource && (
                  <AntTable.Summary fixed>
                    <AntTable.Summary.Row>
                      {summaryList?.sumColumn.map((item, idx) => {
                        if (item?.word) {
                          return (
                            <AntTable.Summary.Cell
                              align={item?.align}
                              index={idx}
                            >
                              <Text ellipsis={{ tooltip: true }}>
                                {item?.word}
                              </Text>
                            </AntTable.Summary.Cell>
                          );
                        }
                        if (!item?.value) {
                          return <AntTable.Summary.Cell />;
                        }
                        return (
                          <AntTable.Summary.Cell align={item.align} index={idx}>
                            {roundFloat(summaryList?.sumDataSource[item.value])}
                          </AntTable.Summary.Cell>
                        );
                      })}
                    </AntTable.Summary.Row>
                  </AntTable.Summary>
                ))
            }
            hideSelectAll={hide}
            footer={!total ? null : _footer}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ExpandedTable;
