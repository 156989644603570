import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';
import { WarningFilled } from '@ant-design/icons';

const Content = ({
  text = '',
  onConfirm = () => {},
  confirmText = '',
  icon = <></>,
}) => {
  const [disabled, seTdisable] = useState(true);
  const onChange = ({ target: { value = '' } }) => {
    if (value && value.length === confirmText.length) {
      seTdisable(value.toLowerCase() !== confirmText);
    } else {
      seTdisable(true);
    }
  };
  const close = () => {
    Modal.destroyAll();
  };
  const confirm = () => {
    onConfirm();
    Modal.destroyAll();
  };
  return (
    <div className="delete-container">
      <div className="icon-side">{icon}</div>
      <div className="main-side">
        <p> {text} </p>
        <p>Та &quot;{confirmText}&quot; гэж бичиж баталгаажуулна уу</p>
        <Input
          className="input"
          size="small"
          maxLength={6}
          onChange={onChange}
          placeholder="..."
        />
        <div className="buttons">
          <Button className="left" size="small" onClick={close}>
            Үгүй
          </Button>
          <Button
            className={`${!disabled ? 'submit' : ''}`}
            size="small"
            disabled={disabled}
            onClick={confirm}
          >
            Тийм
          </Button>
        </div>
      </div>
    </div>
  );
};

const DeleteConfirm = ({
  text = 'Та энэ үйлдлийг хийхдээ итгэлтэй байна уу?',
  onConfirm = () => {},
  width = 500,
  confirmText = 'delete',
  icon = <WarningFilled />,
}) => {
  Modal.error({
    className: 'delete-modal',
    icon: null,
    okButtonProps: {
      style: {
        display: 'none',
      },
    },
    footer: null,
    closable: false,
    content: (
      <Content
        text={text}
        onConfirm={onConfirm}
        confirmText={confirmText}
        icon={icon}
      />
    ),
    okText: 'Тийм',
    mask: false,
    width,
  });
};

export default DeleteConfirm;
