import React from 'react';
import { toast } from 'react-toastify';
import {
  CheckCircleFilled,
  WarningOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

export default function NewAlert({
  type = 'success',
  msg = '',
  functions = null,
  form = null,
}) {
  if (form) {
    form.resetFields();
  }
  switch (type) {
    case 'success':
      if (functions?.onSuccess) {
        if (typeof functions.onSuccess === 'object') {
          functions.onSuccess.forEach((element) => element);
        } else {
          [functions.onSuccess].forEach((element) => element);
        }
      }

      return toast.success(msg, {
        theme: 'colored',
        icon: (
          <div>
            <CheckCircleFilled style={{ fontSize: '24px' }} />
          </div>
        ),
      });
    case 'error':
      if (functions?.onFail) {
        if (typeof functions.onFail === 'object') {
          functions.onFail.forEach((element) => element);
        } else {
          [functions.onFail].forEach((element) => element);
        }
      }

      return toast.error(msg, {
        theme: 'colored',
        icon: (
          <div>
            <CloseCircleOutlined style={{ fontSize: '24px' }} />
          </div>
        ),
      });
    case 'warning':
      if (functions?.onWarning) {
        if (typeof functions.onWarning === 'object') {
          functions.onWarning.forEach((element) => element);
        } else {
          [functions.onWarning].forEach((element) => element);
        }
      }

      return toast.warning(msg, {
        theme: 'colored',
        icon: (
          <div>
            <WarningOutlined style={{ fontSize: '24px' }} />
          </div>
        ),
      });
    default:
      return toast.warning('Алдаа гарлаа Фронтоо шалга', {
        theme: 'colored',
        icon: (
          <div>
            <WarningOutlined style={{ fontSize: '24px' }} />
          </div>
        ),
      });
  }
}
