import React, { useState, useEffect } from 'react';
import { InputNumber, Button, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { useQuery, roundFloat } from 'src/core/utils/formater';
import { set_alert } from 'src/core/actions/alert';
// Editable Cell Component with Border Style for Clarity
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editingValue, setEditingValue] = useState(null);

  const handleChange = (value) => {
    setEditingValue(roundFloat(value)); // Round value to float for consistent display
    handleSave(record.key, dataIndex, value); // Call save function with updated value
  };

  let childNode = children || '';

  if (editable && record) {
    childNode = (
      <InputNumber
        className="editable-input"
        min={0}
        value={
          editingValue !== null ? editingValue : roundFloat(record[dataIndex])
        }
        onChange={handleChange}
        style={{
          width: '100%',
          border: '1px solid #d9d9d9', // Add border to indicate editability
          padding: '4px',
        }}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const App = () => {
  const initialData = [
    { key: '1', title: '1. ХӨРӨНГӨ' },
    {
      key: '1.1',
      category: '1.1 Эргэлтийн хөрөнгө',
      eh_uld: 0.0,
      ets_uld: 0.0,
      editable: false,
      lvl2: true,
    },
    {
      key: '1.1.1',
      category: '1.1.1 Мөнгө,түүнтэй адилтгах хөрөнгө',
      eh_uld: 0.0,
      ets_uld: 0.0,
      editable: true,
      sub: true,
    },
    {
      key: '1.1.2',
      category: '1.1.2 Дансны авлага',
      eh_uld: 0.0,
      ets_uld: 0.0,
      editable: true,
      sub: true,
    },
    // Add additional rows here...
  ];
  const query = useQuery();
  const [dataSource, setDataSource] = useState(initialData);
  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));
  const dispatch = useDispatch();

  const handleSave = (key, dataIndex, value) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, [dataIndex]: value });
    setDataSource(newData); // Update table data
  };

  const get_data = (month) => {
    socket.on('request_out', ({ status, content }) => {
      console.log('🚀 ~ socket.on ~ content:', content);
      socket.removeAllListeners('request_out');
      if (status === '0') {
        setDataSource(content?.result_list?.tailan_data);
        if (content?.result_list?.zoruu) {
          dispatch(
            set_alert({
              type: 'warning',
              msg: (
                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <b>Тайланд зөрүү гарсан байна</b>
                </span>
              ),
              open: true,
            })
          );
        }
      } else {
        newAlert({ torol: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const search = {
      dans_dugaar: query.get('dans_dugaar') || '',
      ded_agilal: query.get('ded_agilal') || '',
    };
    socket.emit('request_in', {
      channel: 'ts001_05_view',
      content: {
        action_type: 'sanhuu_baidal_tailan',
        month,
        page,
        size,
        search,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    get_data(data_date.month);
  }, [data_date.month]);

  // Number Formatter
  const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Define table columns
  const columns = [
    {
      title: 'Үзүүлэлт',
      dataIndex: 'category',
      key: 'category',
      width: 600,
      align: 'left',
      render: (text, record) => {
        if (record.title) {
          return {
            children: <strong>{record.title}</strong>,
            props: {
              colSpan: 9,
            },
          };
        }
        return (
          <span
            className={
              record.lvl1
                ? 'mlvl1'
                : record.lvl2
                ? 'mlvl2'
                : record.lvl3
                ? 'mlvl3'
                : ''
            }
          >
            {text}
          </span>
        );
      },
    },
    {
      title: 'Эхний үлдэгдэл',
      dataIndex: 'eh_uld',
      key: 'eh_uld',
      editable: true,
      width: 200,
      render: (text, record) => {
        if (record.title) return { children: null, props: { colSpan: 0 } };
        return typeof text === 'number' ? numberFormatter.format(text) : text;
      },
    },
    {
      title: 'Эцсийн үлдэгдэл /төгрөгөөр/',
      dataIndex: 'ets_uld',
      key: 'ets_uld',
      editable: true,
      width: 200,
      render: (text, record) => {
        if (record.title) return { children: null, props: { colSpan: 0 } };
        return typeof text === 'number' ? numberFormatter.format(text) : text;
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) return col;
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div className="tax-account-table-antd-div tailan-container">
      <Table
        dataSource={dataSource}
        columns={mergedColumns}
        rowClassName="editable-row"
        bordered
        pagination={false}
        components={{ body: { cell: EditableCell } }}
      />
    </div>
  );
};

export default App;
