import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, Pagination, Comment, Form, Input, Button, List, Avatar } from 'antd';
import { UserOutlined, DeleteOutlined } from '@ant-design/icons';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from '../../actions/loader';

const { TextArea } = Input;

const CommentDrawer = ({
  open = false,
  closeDrawer = () => {},
  pageType = null,
}) => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const [paging, setPaging] = useState({ page: 1, size: 10 });
  const [commentData, setCommentData] = useState([]);
  // const [commentInputLoading, setCommentInputLoading] = useState(false);

  const [form] = Form.useForm();

  const fetchData = (turul) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setCommentData(data.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const page = paging?.page;
    const size = paging?.size;
    const search = {};
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: {
        action_type: 'get_comment_list',
        paging: { page, size, search },
        turul,
      },
    });
    dispatch(start_loading({}));
  };

  const addComment = (data) => {
    socket.on('request_out', (rsp) => {
      socket.removeAllListeners('request_out');
      if (rsp.status === '0') {
        form.resetFields();
        fetchData(pageType);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: {
        action_type: 'add_comment',
        turul: pageType,
        month: data_date.month,
        comment: data.comment,
      },
    });
    dispatch(start_loading({}));
  };

  const Editor = ({ onSubmit, submitting }) => (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item name="comment">
        <TextArea rows={4} maxLength={1000} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" loading={submitting} type="primary" className="confirm-btn" shape="round">
          Хадгалах
        </Button>
      </Form.Item>
    </Form>
  );

  const deleteComment = (id) => {
    socket.on('request_out', (rsp) => {
      socket.removeAllListeners('request_out');
      if (rsp.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай устгагдлаа' });
        fetchData(pageType);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: { action_type: 'delete_comment', id },
    });
    dispatch(start_loading({}));
  };

  const CustomComment = ({ author, content, datetime, can_delete = false, id = null }) => (
    <Comment
      actions={[<DeleteOutlined style={{ color: 'red' }} hidden={!can_delete} onClick={() => { deleteComment(id); }} />]}
      author={<p>{author}</p>}
      avatar={<Avatar><UserOutlined /></Avatar>}
      content={<p>{content}</p>}
      datetime={<p>{datetime}</p>}
    >
      {/* {children} */}
    </Comment>
  );

  const CommentList = () => (
    <List
      dataSource={commentData?.json_array}
      itemLayout="horizontal"
      renderItem={(props) => <CustomComment {...props} />}
    />
  );

  const onChange = (e) => {
    setPaging({ ...paging, page: e });
  };

  // const onShowSizeChange = (_, pageSize) => {
  //   setPaging({ ...paging, size: pageSize });
  // };

  useEffect(() => {
    if (open && pageType !== null) {
      fetchData(pageType);
    }
  }, [open, paging]);

  return (
    <Drawer
      title="ТМЗ зөвлөмжүүд"
      open={open}
      onClose={closeDrawer}
      width="45%"
      className="custom-drawer-comment"
      footer={(
        <>
          {commentData?.total > 0 && (
            <Pagination
              size="small"
              current={paging?.page}
              onChange={onChange}
              total={commentData?.total}
              // showSizeChanger
              locale={{ items_per_page: 'хуудас' }}
              pageSize={paging?.size}
              // onShowSizeChange={onShowSizeChange}
              style={{ textAlign: 'center' }}
            />
          )}
          {commentData?.can_comment && (
            <Comment
              content={(
                <Editor
                  onSubmit={addComment}
                  // submitting={commentInputLoading}
                />
              )}
            />
          )}
        </>
      )}
    >
      {commentData?.json_array?.length > 0 && <CommentList />}
    </Drawer>
  );
};

export default CommentDrawer;
