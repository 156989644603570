import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CanView from 'src/components/permission/canview';

import { Modal, Button, message } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import newAlert from 'src/components/newAlert';
import { useQuery } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import Table from 'src/components/common/table';
import RegisterModal from './registerModal';
import utils from './dataStructure';

const HuvitsaaEzemshigch = () => {
  const query = useQuery();

  const [tableData, setTableData] = useState([]);
  const [modalValues, setModalValues] = useState(utils?.initialValues);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [dictionaryValues, setDictionaryValues] = useState({});
  const [actionType, setActionType] = useState('');
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();

  const { socket, version } = useSelector((state) => ({
    socket: state?.socket,
    version: state?.version,
  }));

  const handleOpenRegisterModal = (rowData = {}) => {
    socket.on('request_out', (data) => {
      if (data.content.status === '0') {
        setActionType(rowData ? 'edit' : 'register');
        setDictionaryValues({
          hariltsagch_list: data.content.dictionary_data_list.hariltsagch_list,
          turul:
            data.content.dictionary_data_list.huvitsaa_ezemshigch_turul_list,
        });
        setModalValues(rowData);
        setIsVisibleModal(true);
      }
      dispatch(end_loading({}));
      socket.removeAllListeners('request_out');
    });
    socket.emit('request_in', {
      channel: 'ts002_00_huvitsaa_ezemshigch_medeelel',
      content: {
        action_type: 'huvitsaa_ezemshigch_dictionary_list',
        version: version.version_number,
        year: version.year,
      },
    });

    dispatch(start_loading({}));

    setActionType(rowData ? 'edit' : 'register');
    setModalValues(rowData);
  };

  // eslint-disable-next-line no-shadow
  const handleSave = (values, actionType) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      dispatch(end_loading({}));
      if (data.content.status === '0') {
        setTableData(data.content.huvitsaa_ezemshigch_list);
        setTotal(data.content.total);
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
      } else if (data?.content?.status === '2') {
        newAlert({ type: 'error', msg: data?.content?.msg });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
    });
    socket.emit('request_in', {
      channel: 'ts002_00_huvitsaa_ezemshigch_medeelel',
      content: {
        action_type:
          actionType === 'register'
            ? 'huvitsaa_ezemshigch_register'
            : 'huvitsaa_ezemshigch_edit',
        version: version.version_number,
        year: version.year,
        json_data: values,
        page: query.get('page') || 1,
        size: query.get('size') || 20,
      },
    });

    dispatch(start_loading({}));
  };

  const fetch = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.content.status === '0') {
        setTableData(data.content.huvitsaa_ezemshigch_list);
        setTotal(data.content.total);
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts002_00_huvitsaa_ezemshigch_medeelel',
      content: {
        action_type: 'huvitsaa_ezemshigch_list',
        version: version.version_number,
        year: version.year,
        page: query.get('page') || 1,
        size: query.get('size') || 20,
      },
    });

    dispatch(start_loading({}));
  };
  useEffect(() => {
    fetch();
  }, [query]);

  const onDelete = (hariltsagch_code = '') => {
    // huvitsaa_ezemshigch_delete
    if (hariltsagch_code) {
      socket.on('request_out', ({ content }) => {
        socket.removeAllListeners('request_out');
        if (content.status === '0') {
          message.success('done');
          setTimeout(() => {
            fetch();
          }, 1000);
        }
        if (content.status === '1') {
          message.error(content?.msg);
        }
        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts002_00_huvitsaa_ezemshigch_medeelel',
        content: {
          action_type: 'huvitsaa_ezemshigch_delete',
          hariltsagch_code,
        },
      });

      dispatch(start_loading({}));
    }
  };

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          dataSource={tableData}
          columns={utils?.columns({ handleOpenRegisterModal, onDelete })}
          scroll={{
            y: 'calc(100vh - 280px)',
          }}
          title={() => (
            <CanView allowedRole="007_1">
              <Button
                className="add-btn"
                type="primary"
                size="small"
                icon={<PlusCircleOutlined />}
                onClick={() => handleOpenRegisterModal(null)}
              >
                Бүртгэл нэмэх
              </Button>
            </CanView>
          )}
          total={total}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Хөрөнгө оруулагч бүртгэх"
        visible={isVisibleModal}
        closable={false}
        maskClosable={false}
        width={1000}
        footer={null}
      >
        <RegisterModal
          dictionaryValues={dictionaryValues}
          modalValues={modalValues}
          actionType={actionType}
          handleSave={handleSave}
          onClose={() => {
            setIsVisibleModal(false);
          }}
        />
      </Modal>
    </>
  );
};
export default HuvitsaaEzemshigch;
