import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Row,
  Slider,
  DatePicker,
  Input,
  Select,
  InputNumber,
  Button,
} from 'antd';
import { onlyNumber, useQuery } from 'src/core/utils/formater';
import locale from 'antd/es/date-picker/locale/mn_MN';
import { ArrowsAltOutlined } from '@ant-design/icons';

import { useHistory } from 'react-router-dom';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

const CheckList = ({ list = [], valueName = 'name', valueKey = 'value' }) => {
  const query = useQuery();
  const history = useHistory();
  const onChange = ({ target }) => {
    const { checked, value } = target;
    const _list = query.getAll('checkList');
    if (checked) {
      query.append('checkList', value);
    } else {
      const arr = _list.filter((i) => i !== value);
      query.delete('checkList');
      arr.forEach((j) => query.append('checkList', j));
    }
    query.delete('page');
    history.push({ search: query.toString() });
  };

  const values = query.getAll('checkList');
  return (
    <div className="check-container">
      <Checkbox.Group
        value={values}
        className="custom-check"
        style={{ width: '100%' }}
      >
        {list.map((k) => (
          <Row className="row">
            <Checkbox onChange={onChange} value={k[valueKey]}>
              {k[valueName]}
            </Checkbox>{' '}
            <span className="mr-10">{k?.total}</span>
          </Row>
        ))}
      </Checkbox.Group>
    </div>
  );
};

const Ranger = ({ max = 20000000 }) => {
  const query = useQuery();
  const history = useHistory();

  const [_values, seTvalues] = useState([
    query.get('min') || 0,
    query.get('max') || max,
  ]);
  const makeQuery = (type, value) => {
    if (!query.get(type)) {
      query.append(type, value);
    } else {
      query.set(type, value);
    }
    query.delete('page');
    history.push({ search: query.toString() });
  };

  const afterChange = (value) => {
    makeQuery('min', value[0]);
    makeQuery('max', value[1]);
  };

  const onChange = (value) => {
    seTvalues([...value]);
  };

  useEffect(() => {
    if (!query.get('min')) {
      seTvalues([0, max]);
    }
  }, [query]);

  return (
    <div className="slider-container">
      <Slider
        onChange={onChange}
        onAfterChange={afterChange}
        max={max}
        value={_values}
        range={{ draggableTrack: true }}
        step={100}
      />
      <div className="price">
        <span>{onlyNumber(query.get('min') || 0)}₮</span>
        <span>{onlyNumber(query.get('max') || max)}₮</span>
      </div>
    </div>
  );
};

const Date = () => {
  const query = useQuery();
  const history = useHistory();

  const onChange = (_, dateString) => {
    if (!query.get('date')) {
      query.append('date', dateString);
    } else {
      query.set('date', dateString);
    }
    query.delete('page');
    history.push({ search: query.toString() });
  };
  return (
    <div className="date-container">
      <DatePicker
        value={query.get('date') ? moment(query.get('date')) : null}
        format="YYYY/MM/DD"
        onChange={onChange}
        locale={locale}
      />
    </div>
  );
};

const DateRange = () => {
  const query = useQuery();
  const history = useHistory();
  let dates = [];
  const makeQuery = (type, value) => {
    if (!query.get(type)) {
      query.append(type, value);
    } else {
      query.set(type, value);
    }
    query.delete('page');
    history.push({ search: query.toString() });
  };

  const onChange = (_, dateStrings) => {
    makeQuery('startDate', dateStrings[0]);
    makeQuery('endDate', dateStrings[1]);
  };
  if (!query.get('startDate') || !query.get('endDate')) {
    dates = null;
  } else {
    dates = [moment(query.get('startDate')), moment(query.get('endDate'))];
  }

  return (
    <div className="date-container">
      <RangePicker
        value={dates}
        format="YYYY/MM/DD"
        onChange={onChange}
        locale={locale}
      />
    </div>
  );
};

const SearchInput = ({ placeholder = 'Хайх...', queryKey = 'search' }) => {
  const query = useQuery();
  const history = useHistory();

  const makeQuery = (type, value) => {
    if (value) {
      if (!query.get(type)) {
        query.append(type, value);
      } else {
        query.set(type, value);
      }
    } else if (query.get(type)) {
      query.delete(type);
    }
    query.delete('page');
    history.push({ search: query.toString() });
  };
  const onSearch = (value = '') => {
    makeQuery(queryKey, value);
  };
  return (
    <div className="input-container">
      <Search
        defaultValue={query.get(queryKey) || null}
        size="small"
        onSearch={onSearch}
        placeholder={placeholder}
        enterButton
      />
    </div>
  );
};

const SelectSearch = ({
  list = [],
  valueName = 'name',
  valueKey = 'value',
  queryKey = 'select',
}) => {
  const query = useQuery();
  const history = useHistory();

  const makeQuery = (type, value) => {
    if (!query.get(type)) {
      query.append(type, value);
    } else {
      query.set(type, value);
    }
    query.delete('page');
    history.push({ search: query.toString() });
  };
  const onChange = (value = '') => {
    makeQuery(queryKey, value);
  };
  const value = query.get(queryKey) || null;
  return (
    <div className="select-container">
      <Select
        onChange={onChange}
        value={value}
        className="ww-100"
        size="small"
        placeholder="Сонгох.."
      >
        {list.map((k) => (
          <Option value={k[valueKey]}>{k[valueName]}</Option>
        ))}
      </Select>
    </div>
  );
};

const InputRange = () => {
  const query = useQuery();
  const history = useHistory();
  const [value, seTvalue] = useState({
    start: null,
    end: null,
  });
  const makeQuery = (type, value) => {
    if (!query.get(type)) {
      query.append(type, value);
    } else {
      query.set(type, value);
    }

    query.delete('page');
    history.push({ search: query.toString() });
  };

  const onController = (type = 'start', _value = '') => {
    seTvalue((prev) => ({
      ...prev,
      [type]: _value,
    }));
  };

  const submit = () => {
    if (value?.start && value?.end) {
      makeQuery('startInput', value?.start);
      makeQuery('endInput', value?.end);
    }
  };
  useEffect(() => {
    const start = query.get('startInput') || null;
    const end = query.get('endInput') || null;

    seTvalue({ start, end });
  }, [query]);

  return (
    <div className="input-ranger-container">
      <InputNumber
        value={value?.start}
        onChange={(e) => onController('start', e)}
        size="small"
        placeholder="start"
        className="ww-100"
      />
      <ArrowsAltOutlined />
      <InputNumber
        value={value?.end}
        onChange={(e) => onController('end', e)}
        placeholder="end"
        size="small"
        className="ww-100"
      />
      <div className="btn">
        <Button
          disabled={!value?.start || !value?.end}
          onClick={submit}
          size="small"
        >
          Хайх
        </Button>
      </div>
    </div>
  );
};

export default {
  checkList: CheckList,
  ranger: Ranger,
  date: Date,
  dateRange: DateRange,
  input: SearchInput,
  select: SelectSearch,
  inputRange: InputRange,
};
