import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'src/core/utils/axios';
import { onlyNumber } from 'src/core/utils/formater';
import {
  DatePicker,
  Button as AntButton,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Upload,
  message,
  Switch,
  Space,
} from 'antd';
import CanView from 'src/components/permission/canview';
import { PlusCircleOutlined, CloudUploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import CustomDatePicker from 'src/components/common/datePicker';
import newAlert from 'src/components/newAlert';
import Table from 'src/components/common/table';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { RegZalruulga } from './zalruulgaModal';
import { columns } from './dataStructure/data';

export default function Client() {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const [tableData, setTableData] = useState([]);
  const [visible, seTvisible] = useState({ zalruulga: false, aldagdal: false });
  const [form] = Form.useForm();
  const [form_3] = Form.useForm();

  const typesList = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg',
    'image/png',
    'application/pdf',
  ];

  const [update, SetUpdate] = useState(false);
  const { TextArea } = Input;

  const { year_end, is_first_year } = useSelector((state) => state.version);

  const FileDownload = require('js-file-download');
  const onCancel = () => {
    seTvisible({ zalruulga: false, aldagdal: false });
  };

  const UpdateCancel = () => {
    SetUpdate(false);
  };

  const onChange = (date, dateString) => dateString;

  const mainData = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        const new_temp_data = content?.aldagdal_array.map(
          (inner_data, key) => ({
            id: key + 1,
            ...inner_data,
          })
        );
        if (new_temp_data) {
          setTableData(new_temp_data);
        }
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_02_manage_aldagdal_data',
      content: { action_type: 'get_aldagdal_data' },
    });

    dispatch(start_loading({}));
  };

  const handleSaveValue = (aldagdalData) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        newAlert({ type: 'success', msg: content.Message });
        form.resetFields();
        onCancel();
        mainData();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_02_manage_aldagdal_data',
      content: {
        action_type: 'add_aldagdal',
        array_data: aldagdalData,
      },
    });
    dispatch(start_loading({}));
  };

  const handleSaveZalruulga = (curData, formData) => {
    console.log('🚀 ~ handleSaveZalruulga ~ curData:', curData);
    const type = curData?.state === false ? 'add_prev_year' : 'add_zalruulga';
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        newAlert({ type: 'success', msg: content.Message });

        formData.resetFields();
        onCancel();
        mainData();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_02_manage_aldagdal_data',
      content: {
        action_type: type,
        array_data: curData,
      },
    });
    dispatch(start_loading({}));
  };

  const NewFileAdd = () => {
    const onFinish = async (data) => {
      const { file, ..._values } = data;
      if (file !== undefined) {
        const { size: file_size } = file[0];
        socket.on('request_out', async ({ status, content: return_data }) => {
          socket.removeAllListeners('request_out');
          if (status === '0') {
            const formData = new FormData();
            formData.append('file', file[0].originFileObj);
            const { status: file_status, content } = await axios.post(
              'attach',
              '/file_upload',
              formData,
              {
                file_size,
              }
            );
            if (file_status === '0') {
              const { json_array } = content;
              const confirmData = {
                ..._values,
                file_ner: json_array?.received_file_name,
                file_id: json_array?.file_id,
                ognoo: moment(_values.ognoo).format('yyyy'),
                akt_ognoo: moment(_values.akt_ognoo).format('yyyy/MM/DD'),
              };

              handleSaveValue(confirmData);
              form.resetFields();
            } else {
              dispatch(end_loading({}));
              newAlert({ type: 'error', msg: return_data.Message });
            }
          } else {
            dispatch(end_loading({}));
            newAlert({ type: 'error', msg: return_data.Message });
          }
        });
        socket.emit('request_in', {
          channel: 'ss005_company_manage',
          content: {
            action_type: 'check_company_storage',
            file_size,
          },
        });
        dispatch(start_loading({}));
      } else {
        const confirmData = {
          ..._values,
          ognoo: moment(_values.ognoo).format('yyyy'),
          akt_ognoo: moment(_values.akt_ognoo).format('yyyy/MM/DD'),
        };
        handleSaveValue(confirmData);
      }
    };

    const normFile = (e) => {
      console.log('Upload event:', e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    return (
      <>
        <Form
          form={form}
          name="basic"
          style={{
            fontSize: 13,
          }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Татварын алба:"
            name="tatvar_alba"
            rules={[{ required: true, message: 'Заавал бөглөх' }]}
          >
            <Input bordered />
          </Form.Item>
          <Form.Item
            label="Гэрээний огноо"
            name="ognoo"
            labelAlign="right"
            rules={[
              {
                required: true,
                message: 'огноо оруулна уу',
              },
              {
                validator: async (_, ognoo) => {
                  const selected_date = moment(ognoo).format('yyyy');
                  if (is_first_year && selected_date) {
                    if (parseInt(selected_date) < parseInt(year_end) - 4) {
                      return Promise.reject(
                        new Error(
                          'Зөвхөн тухайн тайлангийн жилээс өмнөх 4 жил доторх алдагдлыг бүртгэх боломжтой'
                        )
                      );
                    }
                    if (parseInt(selected_date) > parseInt(year_end)) {
                      return Promise.reject(
                        new Error(
                          'Эхний үлдэгдлийн жил дээр тухайн жилээс дээш үеийн алдагдал бүртгэх боломжгүй'
                        )
                      );
                    }
                  }
                  if (!is_first_year && year_end !== selected_date) {
                    return Promise.reject(
                      new Error(
                        'Эхний үлдэгдлийн жил дээр зөвхөн өөр тайлангийн жилийн алдагдал бүртгэх боломжтой'
                      )
                    );
                  }
                },
              },
            ]}
          >
            <DatePicker onChange={onChange} picker="year" />
          </Form.Item>
          <Form.Item
            label="Актын огноо:"
            name="akt_ognoo"
            labelAlign="right"
            rules={[
              {
                required: true,
                message: 'огноо оруулна уу',
              },
            ]}
          >
            <CustomDatePicker customClass="ww-60" />
          </Form.Item>
          <Form.Item
            label="Алдагдлын дүн:"
            name="aldagdal_dun"
            labelAlign="right"
            rules={[
              {
                required: true,
                message: 'Алдагдлын дүн оруулна уу',
              },
            ]}
            normalize={onlyNumber}
          >
            <Input bordered />
          </Form.Item>

          <Form.Item
            label="Актын №:"
            name="akt_number"
            rules={[{ required: true, message: 'Заавал бөглөх' }]}
          >
            <Input bordered />
          </Form.Item>
          <CanView allowedRole="001_1">
            <Form.Item
              label="Файл хавсаргах:"
              name="file"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              {/* <FileUpload name="file" /> */}
              <Upload
                accept={typesList.join()}
                maxCount={1}
                beforeUpload={(File) => {
                  const isXls = typesList.find((i) => i === File.type);
                  if (!isXls) {
                    message.error(
                      'Тухайн өргөтгөлтэй файлыг хүлээн авах боломжгүй байна!'
                    );
                    return Upload.LIST_IGNORE;
                  }
                  return false;
                }}
              >
                <CloudUploadOutlined className="fs-30" />
              </Upload>
            </Form.Item>
          </CanView>

          <Form.Item label="Тайлбар:" name="tailbar" labelAlign="right">
            <TextArea bordered rows={4} maxLength={300} showCount />
          </Form.Item>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <AntButton
                  onClick={() => {
                    form.resetFields();
                    onCancel();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </AntButton>
                <AntButton
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </AntButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const handleSaveUpdate = (aldagdalData) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        newAlert({ type: 'success', msg: content.Message });

        UpdateCancel();
        mainData();
        form_3.resetFields();
      } else {
        newAlert({ type: 'error', msg: content.Message });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_02_manage_aldagdal_data',
      content: {
        action_type: 'update_aldagdal',
        array_data: aldagdalData,
      },
    });

    dispatch(start_loading({}));
  };

  const UpdateData = () => {
    const currentData = form_3.getFieldValue();
    const onFinish = async (data) => {
      const { ..._values } = data;

      const confirmData = {
        ..._values,
        file_ner: currentData?.file_ner,
        file_id: currentData?.file_id,
        ognoo: moment(_values.ognoo).format('yyyy'),
        aldagdal_id: currentData.aldagdal_id,
      };

      handleSaveUpdate(confirmData);
    };
    return (
      <>
        <Form
          form={form_3}
          name="basic"
          style={{
            fontSize: 13,
          }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Татварын алба:"
            name="tatvar_alba"
            rules={[{ required: true, message: 'Заавал бөглөх' }]}
          >
            <Input bordered />
          </Form.Item>
          <Form.Item
            label="Гэрээний огноо"
            name="ognoo"
            labelAlign="right"
            rules={[
              {
                required: true,
                message: 'огноо оруулна уу',
              },
            ]}
          >
            <DatePicker onChange={onChange} picker="year" />
          </Form.Item>
          <Form.Item
            label="Актын огноо:"
            name="akt_ognoo"
            labelAlign="right"
            rules={[
              {
                required: true,
                message: 'огноо оруулна уу',
              },
            ]}
          >
            <CustomDatePicker customClass="ww-60" />
          </Form.Item>
          <Form.Item
            label="Алдагдлын дүн:"
            name="aldagdal_dun"
            labelAlign="right"
            rules={[
              {
                required: true,
                message: 'Алдагдлын дүн оруулна уу',
              },
            ]}
            normalize={onlyNumber}
          >
            <Input bordered />
          </Form.Item>
          <Form.Item
            label="Актын №:"
            name="akt_number"
            rules={[{ required: true, message: 'Заавал бөглөх' }]}
          >
            <Input bordered />
          </Form.Item>

          <Form.Item label="Тайлбар:" name="tailbar" labelAlign="right">
            <TextArea bordered rows={4} maxLength={300} showCount />
          </Form.Item>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <AntButton
                  onClick={() => {
                    form.resetFields();
                    onCancel();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </AntButton>
                <AntButton
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </AntButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  function send_confirm_row_id(file_id, dugnelt_file_name) {
    axios.get(
      'attach',
      '/get_file',
      {},
      {
        file_size: 999,
        file_id,
      },
      (event) => {
        FileDownload(event, dugnelt_file_name);
      }
    );
  }

  const delete_row = (id) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        newAlert({ type: 'success', msg: content?.Message });
        mainData();
      } else {
        newAlert({ type: 'error', msg: content?.Message });
        mainData();
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_02_manage_aldagdal_data',
      content: {
        aldagdal_id: id,
        action_type: 'delete_aldagdal',
      },
    });

    dispatch(start_loading({}));
  };

  const title = () => (
    <>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          seTvisible({ aldagdal: true });
        }}
      >
        Алдагдлын дүн нэмэх
      </AntButton>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          seTvisible({ zalruulga: true });
        }}
      >
        Залруулга бүртгэх
      </AntButton>
    </>
  );

  const send_aldagdal_data = (
    aldagdal_id,
    ognoo,
    aldagdal_dun,
    ehnii_uld_dun,
    tatvar_alba,
    akt_ognoo,
    akt_number,
    file_ner,
    file_id,
    database_name,
    tailbar
  ) => {
    const form_data = {
      aldagdal_id,
      ognoo: moment(ognoo),
      aldagdal_dun: onlyNumber(aldagdal_dun),
      ehnii_uld_dun,
      tatvar_alba,
      akt_ognoo,
      akt_number,
      file_ner,
      file_id,
      tailbar,
      database_name,
    };

    form_3.setFieldsValue(form_data);
    SetUpdate(true);
  };

  useEffect(() => {
    console.log('>> Getting old list...');
    mainData();
  }, []);

  return (
    <>
      <Modal
        title="Алдагдал бүртгэх"
        visible={visible.aldagdal}
        width={550}
        footer={null}
        onCancel={onCancel}
        wrapClassName="custom-modal"
      >
        <NewFileAdd onCancel={onCancel} />
      </Modal>
      <Modal
        title="Залруулга бүртгэх"
        visible={visible.zalruulga}
        width={550}
        footer={null}
        onCancel={onCancel}
        wrapClassName="custom-modal"
      >
        <RegZalruulga
          socket={socket}
          dispatch={dispatch}
          handleSaveZalruulga={handleSaveZalruulga}
          onCancel={onCancel}
        />
      </Modal>
      <Modal
        title="Алдагдал засах"
        visible={update}
        width={550}
        footer={null}
        onCancel={UpdateCancel}
        wrapClassName="custom-modal"
      >
        <UpdateData onCancel={UpdateCancel} />
      </Modal>
      <div className="table-antd-div aldagdal-container">
        <Table
          columns={columns({
            send_confirm_row_id,
            send_aldagdal_data,
            delete_row,
          })}
          dataSource={tableData}
          title={title}
        />
      </div>
    </>
  );
}
