import React from 'react';
import { roundFloat } from 'src/core/utils/formater';

const tText = 'T6_1_TailanTatvar.tableText';
const hText = 'T6_1_TailanTatvar.headerText';

export const customColumns = ({ t = null }) => [
  {
    dataIndex: 'text',
    align: 'left',
    width: 50,
    render: (_, row, index) => {
      if (row?.mor_type === 'sum') {
        return {
          children: (
            <span className="fw-600">{t(`${tText}.${index}`, row?.text)}</span>
          ),
          props: {
            colSpan: row?.title ? 0 : 3,
          },
        };
      }
      if (row?.mor_type === 'lvl1') {
        return {
          children: <span>{t(`${tText}.${index}`, row?.text)}</span>,
          props: {
            colSpan: row?.title ? 0 : 3,
          },
        };
      }
      return {
        props: {
          colSpan: row?.title ? 0 : 1,
        },
      };
    },
  },
  {
    dataIndex: 'text',
    align: 'left',
    width: 50,
    render: (_, row, index) => {
      if (row?.mor_type === 'lvl2') {
        return {
          children: <span>{t(`${tText}.${index}`, row?.text)}</span>,
          props: {
            colSpan: row?.title ? 0 : 2,
          },
        };
      }
      if (row?.mor_type === 'lvl1' || row?.mor_type === 'sum') {
        return {
          props: {
            colSpan: 0,
          },
        };
      }
      return {
        props: {
          colSpan: row?.title ? 0 : 1,
        },
      };
    },
  },
  {
    title: t(`${hText}.${'uzuulelt'}`, 'ҮЗҮҮЛЭЛТҮҮД'),
    dataIndex: 'text',
    align: 'left',
    titleAlign: 'center',
    width: 484,
    render: (_, row, index) => {
      if (row?.mor_type === 'lvl3') {
        return {
          children: <span>{t(`${tText}.${index}`, row?.text)}</span>,
          props: {
            colSpan: row?.title ? 0 : 1,
          },
        };
      }
      return {
        props: {
          colSpan: 0,
        },
      };
    },
  },
  {
    title: t(`${hText}.${'mur'}`, 'МӨР'),
    dataIndex: 'mur',
    align: 'center',
    titleAlign: 'center',
    width: 50,
  },
  {
    title: t(`${hText}.${'dun'}`, 'ДҮН'),
    dataIndex: 'dun',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => {
      if (row?.mor_type === 'sum') {
        return {
          children: <span className="fw-600">{roundFloat(row?.dun)}</span>,
          props: {
            colSpan: row?.title && 0,
          },
        };
      }
      return {
        children: roundFloat(row?.dun),
        props: {
          colSpan: row?.title && 0,
        },
      };
    },
  },
];
