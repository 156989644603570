/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { Tooltip } from 'antd';

export const mainColumns = ({ makeInfo = () => {} }) => [
  {
    title: '#',
    dataIndex: '',
    align: 'center',
    titleAlign: 'center',
    width: 30,
    render: (_, __, idx) => idx + 1,
  },
  {
    title: 'Нэр',
    dataIndex: 'ner',
    align: 'left',
    titleAlign: 'left',
    render: (_, row) => (
      <Tooltip title={_} placement="right">
        <div className="parent-clicker" onClick={() => makeInfo(row)}>
          <span>{_}</span>
        </div>
      </Tooltip>
    ),
  },
];

const render = (item) => (typeof item === 'string' ? item : roundFloat(item));

export const infoColumns = () => [
  {
    title: '#',
    dataIndex: 'counter',
    align: 'left',
    titleAlign: 'center',
    width: 30,
  },
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    titleAlign: 'center',
    width: 250,
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    align: 'left',
    titleAlign: 'center',
    // width: 200,
    ellipsis: true,
  },
  {
    title: 'Татварын ангилал',
    dataIndex: 'dans_dugaar_harits',
    align: 'left',
    titleAlign: 'center',
    // width: 200,
    ellipsis: true,
  },
  {
    title: 'Дт (%)',
    dataIndex: 'perc_deb',
    align: 'right',
    titleAlign: 'center',
    width: 60,
    render: (_, row) => ({
      children: roundFloat(row?.perc_deb),
    }),
  },
  {
    title: 'Кт (%)',
    dataIndex: 'perc_cred',
    align: 'right',
    titleAlign: 'center',
    width: 60,
    render: (_, row) => ({
      children: roundFloat(row?.perc_cred),
    }),
  },
  {
    title: 'Тооцоолсон дүн',
    dataIndex: 'dun',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render,
    editable: true,
    type: 'number',
  },
];
