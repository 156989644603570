import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Button as AntButton } from 'antd';
import Table from 'src/components/common/table';
import newAlert from 'src/components/newAlert';
import { PlusCircleOutlined } from '@ant-design/icons';

import { start_loading, end_loading } from 'src/core/actions/loader';
import { onlyNumber, useQuery } from 'src/core/utils/formater';
import { customSummary, columns } from './dataStructure/data';

import Add from './add';

const Client = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const [visible, seTvisible] = useState(false);
  const [yoslolTypes, setYoslolTypes] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [form] = Form.useForm();
  const [total, seTtotal] = useState([]);
  const query = useQuery();
  const page = query.get('page');
  const size = query.get('size');

  const open = () => {
    seTvisible(true);
  };

  const close = () => {
    form.resetFields();
    seTvisible(false);
  };
  function send_yoslol_data(data) {
    const { tsalin_zardal_dun, orlogiin_torol, ..._data } = data;
    const values = {
      tsalin_zardal_dun: onlyNumber(tsalin_zardal_dun),
      torol: parseInt(orlogiin_torol),
      ..._data,
    };
    form.setFieldsValue(values);
    open();
  }

  const title = () => (
    <AntButton
      id="first"
      className="add-btn"
      type="primary"
      size="small"
      icon={<PlusCircleOutlined />}
      onClick={() => open()}
    >
      Нэмэх
    </AntButton>
  );

  const mainData = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');

      if (status === '0') {
        seTtotal(content?.total);
        setYoslolTypes(content?.yoslol_hundetgel_types);
        const new_temp_data = content?.json_array.map((inner_data, key) => ({
          id: key + 1,
          ...inner_data,
        }));
        if (new_temp_data) {
          setTableData(new_temp_data);
          setTableDataSum(content?.sum_dun);
        }
      } else {
        newAlert({ type: 'error', msg: content.Message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_service_yoslol_hundetgel',
      content: {
        action_type: 'get_yoslol_hundetgel_input',
        page,
        size,
        search: {},
      },
    });

    dispatch(start_loading({}));
  };

  function delete_row(id) {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: mainData() },
      });
    });
    socket.emit('request_in', {
      channel: 'ts004_00_service_yoslol_hundetgel',
      content: {
        id,
        action_type: 'delete_yoslol_data',
      },
    });
  }

  useEffect(() => {
    console.log('>> Getting old list...');
    mainData();
  }, [query]);

  return (
    <>
      <div className="table-antd-div zardal-container-yoslol">
        <Table
          // customAdd={open}
          columns={columns({
            delete_row,
            send_yoslol_data,
          })}
          dataSource={tableData}
          scroll={{
            x: 'auto',
          }}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableDataSum,
          }}
          title={title}
          total={total}
        />
      </div>
      <Modal
        title="Цалингийн зардлын дүн бүртгэх"
        visible={visible}
        width={700}
        footer={null}
        closable={false}
        maskClosable={false}
        onCancel={close}
        wrapClassName="custom-modal"
      >
        <Add
          onClose={close}
          form={form}
          socket={socket}
          dispatch={dispatch}
          refetch={mainData}
          yoslolTypes={yoslolTypes}
        />
      </Modal>
    </>
  );
};
export default Client;
