import React, { useState } from 'react';
import { Form, Select, Upload, Button, Row, Col, message, Divider } from 'antd';
import axios from 'src/core/utils/axios';
import { CloudUploadOutlined } from '@ant-design/icons';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { FileError } from 'src/components/common/file_error';
import { useDispatch } from 'react-redux';

const typesList = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const { Option } = Select;

export const NewFileTovchoo = ({
  after_handleUpload = () => {},
  onCancel = () => {},
}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [fileType, seTfileType] = useState('1');

  const onFinish = async (data) => {
    const { file, file_2 } = data;
    const state_data = {
      status2: false,
      status: false,
      content2: null,
      content: null,
    };
    // First file
    if (file) {
      dispatch(start_loading({}));
      const { size: file_size } = file[0];
      const { file_source } = data;
      const formData = new FormData();
      formData.append('file', file[0].originFileObj);
      const { status, content } = await axios.post(
        'first',
        fileType !== '2'
          ? '/undsen_horongo_tovchoo'
          : '/undsen_horongo_tovchoo_burtgel',
        formData,
        { file_size, file_source }
      );
      state_data.status = status;
      state_data.content = content;
    }
    // Second file
    if (file_2 && fileType === '2') {
      dispatch(start_loading({}));
      const { size: file_size_2 } = file_2[0];
      const { file_source_2 } = data;
      const formData2 = new FormData();
      formData2.append('file', file_2[0].originFileObj);
      const { status, content } = await axios.post(
        'first',
        '/burtgel_dugaar_delgerengui',
        formData2,
        { file_size: file_size_2, file_source: file_source_2 }
      );
      state_data.status2 = status;
      state_data.content2 = content;
    } else {
      state_data.status2 = '0';
      state_data.content2 = { json_array: '' };
    }

    if (state_data.status === '0' && state_data.status2 === '0') {
      const temp_content = {
        fileType,
        tovchoo: state_data.content.json_array,
        burtgel: state_data.content2.json_array,
      };
      after_handleUpload(temp_content);
    } else if (
      // eslint-disable-next-line operator-linebreak
      state_data.content?.type === 'content' ||
      state_data.content2?.type === 'content'
    ) {
      FileError({
        text: `${state_data.content?.message}, ${state_data.content2?.message}`,
      });
      dispatch(end_loading({}));
      // form.resetFields();
      // onCancel();
    } else {
      newAlert({
        type: 'error',
        msg: `${state_data.content?.message}, ${state_data.content2?.message}`,
      });
      dispatch(end_loading({}));
      // form.resetFields();
      // onCancel();
    }
    form.resetFields();
    onCancel();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onSelectType = (value) => {
    seTfileType(value);
  };

  const layout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 17 },
    },
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item label="Файл төрөл" labelAlign="left" name="file_type">
          <Select
            placeholder="Сонгох"
            style={{ width: '100%', fontSize: 13 }}
            defaultValue="1"
            onChange={onSelectType}
          >
            <Option value="1">Хөрөнгөөр</Option>
            <Option value="2">Бүртгэлээр</Option>
          </Select>
        </Form.Item>
        <Divider plain>Үндсэн хөрөнгийн товчоо</Divider>
        <Form.Item
          name="file_source"
          label="Эх сурвалж"
          labelAlign="left"
          rules={[{ required: true, message: '' }]}
        >
          <Select placeholder="Сонгох" style={{ width: '100%', fontSize: 13 }}>
            <Option value="1">Диамонд</Option>
            <Option value="2">Такс</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="file"
          label="Файл"
          labelAlign="left"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: 'Файл сонгоно уу' }]}
        >
          <Upload
            accept={typesList.join()}
            showUploadList
            maxCount={1}
            beforeUpload={(File) => {
              const isXls = typesList.find((i) => i === File.type);
              if (!isXls) {
                message.error('Зөвхөн xls, xlsx өргөтгөлтэй файл оруулна уу');
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
          >
            <CloudUploadOutlined className="fs-30" />
          </Upload>
        </Form.Item>
        <div hidden={fileType !== '2'}>
          <Divider plain>Үндсэн хөрөнгийн бүртгэлээрх дэлгэрэнгүй</Divider>
          <Form.Item
            name="file_source_2"
            label="Эх сурвалж"
            labelAlign="left"
            rules={[{ required: fileType === '2', message: '' }]}
          >
            <Select
              placeholder="Сонгох"
              style={{ width: '100%', fontSize: 13 }}
            >
              <Option value="1">Диамонд</Option>
              <Option value="2">Такс</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="file_2"
            label="Файл"
            labelAlign="left"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: fileType === '2', message: 'Файл сонгоно уу' }]}
          >
            <Upload
              accept={typesList.join()}
              showUploadList
              maxCount={1}
              beforeUpload={(File) => {
                const isXls = typesList.find((i) => i === File.type);
                if (!isXls) {
                  message.error('Зөвхөн xls, xlsx өргөтгөлтэй файл оруулна уу');
                  return Upload.LIST_IGNORE;
                }
                return false;
              }}
            >
              <CloudUploadOutlined className="fs-30" />
            </Upload>
          </Form.Item>
        </div>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={() => {
                  seTfileType('1');
                  form.resetFields();
                  onCancel();
                }}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Буцах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
