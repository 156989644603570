import moment from 'moment';

const checkType = (date) => {
  if (!date) return false;
  return typeof date === 'string' || typeof date === 'object';
};

export const date = (oDate, oFromTimestamp = false) => {
  if (!checkType(oDate)) return 'Wrong date value';
  if (oFromTimestamp) {
    const formattedValue = new Date(oDate * 1000);
    const value = moment(formattedValue).format('YYYY/MM/DD');
    return value !== 'Invalid date' ? value : moment().format('YYYY/MM/DD');
  }
  const value = moment(oDate).format('YYYY/MM/DD');
  return value !== 'Invalid date' ? value : moment().format('YYYY/MM/DD');
};

export const dateTime = (oDate) => moment(oDate).format('YYYY/MM/DD HH:mm');
