import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { roundFloat } from 'src/core/utils/formater';

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'dans_une_deb',
  },
  {
    align: 'right',
    value: 'dans_une_cred',
  },
  {
    align: 'right',
    value: 'tat_tohir_deb',
  },
  {
    align: 'right',
    value: 'tat_tohir_cred',
  },
  {
    align: 'right',
    value: 'tat_suuri_deb',
  },
  {
    align: 'right',
    value: 'tat_suuri_cred',
  },
];

export const customColumns = ({ customEdit = () => {}, version }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    titleAlign: 'center',
    width: 250,
    render: (_, row) => ({
      children: row?.dans_dugaar,
      props: {
        colSpan: row?.title && 0,
      },
    }),
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    align: 'left',
    titleAlign: 'center',
    width: 285,
    ellipsis: true,
    render: (_, row) => ({
      children: row?.dans_ner,
      props: {
        colSpan: row?.title && 0,
      },
    }),
  },
  {
    title: 'Татварын тохиргоо',
    align: 'center',
    titleAlign: 'center',
    width: 70,
    render: (_, row) => {
      if (row?.tohiruulga_enabled === true && version?.is_first_year) {
        return {
          children: (
            <div>
              <EditOutlined onClick={() => customEdit(row)} />
            </div>
          ),
          props: {
            colSpan: row?.title && 0,
          },
        };
      }
      return {
        props: {
          colSpan: row?.title && 0,
        },
      };
    },
  },
  {
    title: 'Дансны үнэ',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Дебет',
        dataIndex: 'dans_une_deb',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.dans_une_deb),
          props: {
            colSpan: row?.title && 0,
          },
        }),
      },
      {
        title: 'Кредит',
        dataIndex: 'dans_une_cred',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.dans_une_cred),
          props: {
            colSpan: row?.title && 0,
          },
        }),
      },
    ],
  },
  {
    title: 'Татварын тохируулга',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Дебет',
        dataIndex: 'tat_tohir_deb',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.tat_tohir_deb),
          props: {
            colSpan: row?.title && 0,
          },
        }),
      },
      {
        title: 'Кредит',
        dataIndex: 'tat_tohir_cred',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.tat_tohir_cred),
          props: {
            colSpan: row?.title && 0,
          },
        }),
      },
    ],
  },
  {
    title: 'Татварын суурь',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Дебет',
        dataIndex: 'tat_suuri_deb',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.tat_suuri_deb),
          props: {
            colSpan: row?.title && 0,
          },
        }),
      },
      {
        title: 'Кредит',
        dataIndex: 'tat_suuri_cred',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.tat_suuri_cred),
          props: {
            colSpan: row?.title && 0,
          },
        }),
      },
    ],
  },
];
