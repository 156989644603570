import { concat, split } from 'lodash';
import { routeList } from '../Routes';
import { routeList as noatList } from './routesNoat';

const settingPath = [
  'tohirgoo',
  'ehniiUldegdel',
  'undsenMedeelel',
  'tatvarTulugch',
];
let allChild = [];

export const settingsSlicer = (array = [], first_year = false) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return array;
  }
  return array.filter((i) => {
    if (i === '/main') {
      return true;
    }
    if (i === '/noat') {
      return true;
    }

    if (first_year) {
      const middle = split(i, '/', 3)?.[2];
      return settingPath.includes(middle);
    }
    return true;
  });
};

export const routes = (permission = []) => {
  const routesList = Object.values({ ...routeList }).map((i) => {
    const { children = [] } = i;
    if (i?.permission) {
      const parent = permission.includes(i.path);
      if (parent) {
        if (children.length > 0) {
          allChild = concat(allChild, children);
        }
      }
    } else if (children.length > 0) {
      allChild = concat(allChild, children);
    }
    return i;
  });
  const _noatList = Object.values({ ...noatList }).map((i) => i);
  return [...allChild, ...routesList, ..._noatList];
};

export const routesN = () => {
  const _noatList = Object.values({ ...noatList }).map((i) => i);
  return [..._noatList];
};
