import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'tatvar_dans_dun',
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'tatvar_nogdoh_dun',
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'gadaad_nogdoh_dun',
  },
  {
    align: 'right',
    value: 'tatvar_hasah_dun',
  },
  {
    value: null,
  },
  {
    value: null,
  },
];

export const customColumns = ({
  customDelete = () => {},
  customEdit = () => {},
}) => [
  {
    title: 'Д/Д',
    dataIndex: 'counter',
    align: 'center',
    titleAlign: 'center',
    width: 50,
  },
  {
    title: 'Гадаад улсын нэр',
    dataIndex: 'gadaad_uls_ner',
    align: 'left',
    titleAlign: 'center',
    width: 180,
  },
  {
    title: 'Татварын жилд гадаад улсад олсон орлого',
    dataIndex: 'tatvar_dans',
    align: 'left',
    titleAlign: 'center',
    width: 310,
  },
  {
    title: 'Татвар ногдуулах орлого',
    dataIndex: 'tatvar_dans_dun',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.tatvar_dans_dun),
    }),
  },
  {
    title: 'Ногдуулах албан татвар',
    titleAlign: 'center',
    children: [
      {
        title: 'Хувь',
        dataIndex: 'tatvar_nogdoh_huvi',
        align: 'right',
        titleAlign: 'center',
        width: 80,
        render: (_, row) => ({
          children: roundFloat(row?.tatvar_nogdoh_huvi),
        }),
      },
      {
        title: 'Дүн',
        dataIndex: 'tatvar_nogdoh_dun',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.tatvar_nogdoh_dun),
        }),
      },
    ],
  },
  {
    title:
      'Тайлант жилд гадаад улсад олсон орлогоос тухайн орны хуульд заасан хувь хэмжээгээр төлсөн татвар',
    titleAlign: 'center',
    children: [
      {
        title: 'Хувь',
        dataIndex: 'gadaad_nogdoh_huvi',
        align: 'right',
        titleAlign: 'center',
        width: 80,
        render: (_, row) => ({
          children: roundFloat(row?.gadaad_nogdoh_huvi),
        }),
      },
      {
        title: 'Дүн',
        dataIndex: 'gadaad_nogdoh_dun',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.gadaad_nogdoh_dun),
        }),
      },
    ],
  },
  {
    title: 'Монгол улсад хасах албан татвар',
    dataIndex: 'tatvar_hasah_dun',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.tatvar_hasah_dun),
    }),
  },
  {
    title: 'Бүртгэх',
    align: 'center',
    titleAlign: 'center',
    width: 60,
    render: (_, row) => (
      <div>
        <EditOutlined onClick={() => customEdit(row)} />
      </div>
    ),
  },
  {
    title: 'Устгах',
    align: 'center',
    titleAlign: 'center',
    width: 60,
    render: (_, row) => {
      if (row?.can_delete === true) {
        return {
          children: (
            <div>
              <DeleteOutlined onClick={() => customDelete(row)} />
            </div>
          ),
        };
      }
      return '';
    },
  },
];
