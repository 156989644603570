import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { Table as AntTable } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const clear = (inputString) => {
  const stringWithoutCommas = String(inputString).replace(/,/g, '');
  const numberValue = parseFloat(stringWithoutCommas);
  return numberValue;
};

export const ColumnsBurtgelEdit = ({ deleteRow = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    key: 'dans_dugaar',
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    key: 'dans_ner',
  },
  {
    title: 'Дэд ангилал',
    dataIndex: 'ded_agilal',
    key: 'ded_agilal',
  },
  {
    title: 'Дебет',
    dataIndex: 'deb',
    key: 'deb',
    align: 'right',
    editable: true,
  },
  {
    title: 'Кредит',
    dataIndex: 'cred',
    key: 'cred',
    align: 'right',
    editable: true,
  },
  {
    title: 'Устгах',
    align: 'center',
    width: 50,
    fixed: 'right',
    render: (_, row) => (
      <DeleteOutlined id="second" onClick={() => deleteRow(row)} />
    ),
  },
];

export const totalSum = ({ data = null }) => (
  <AntTable.Summary fixed>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right">
        <></>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <></>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>Нийт</p>
        </>
      </AntTable.Summary.Cell>

      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.deb)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.cred)}</p>
        </>
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
  </AntTable.Summary>
);

export const EditSum = ({ data = null }) => (
  <AntTable.Summary fixed>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right">
        <></>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>Нийт</p>
        </>
      </AntTable.Summary.Cell>

      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.deb)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.cred)}</p>
        </>
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
  </AntTable.Summary>
);

const setFloat = (_) => roundFloat(_);
export const columns = () => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    key: 'dans_dugaar',
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    key: 'dans_ner',
  },
  {
    title: 'Дэд ангилал',
    dataIndex: 'ded_agilal',
    key: 'ded_agilal',
  },
  {
    title: 'Дебет',
    dataIndex: 'deb',
    key: 'deb',
    align: 'right',
    render: (_) => roundFloat(_),
  },
  {
    title: 'Кредит',
    dataIndex: 'cred',
    key: 'cred',
    align: 'right',
    render: (_) => roundFloat(_),
  },
];

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'tomilolt_hyazgaar',
    render: setFloat,
  },
  {
    align: 'right',
    value: 'guitsetgel_tomilolt_zardal',
    render: setFloat,
  },
  {
    align: 'right',
    value: 'unaa_nisleg',
    render: setFloat,
  },
  {
    align: 'right',
    value: 'hetersen_dun',
    render: setFloat,
  },
];
