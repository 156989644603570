import React from 'react';

import { Form, Button, Row, Col, Input } from 'antd';
import backImg from 'src/assets/images/flag.jpg';
import { useHistory } from 'react-router-dom';
import headerImg from 'src/assets/images/logo70.jpg';
import { useSelector } from 'react-redux';
import NodeRSA from 'node-rsa';
import newAlert from 'src/components/newAlert';
import utils from '../login/data';

const ForgotPassword = () => {
  const history = useHistory();

  const [form] = Form.useForm();
  const socket = useSelector((state) => state.socket);
  const rsa = useSelector((state) => state.rsa);

  const onFinish = (values) => {
    socket.on('forgot_pass_rsp', ({ status, content }) => {
      socket.removeAllListeners('forgot_pass_rsp');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content?.Message,
      });
      history.push('/login');
    });
    const key = new NodeRSA({ b: 512 });
    key.importKey(rsa.serverPublicKey, 'pkcs1-public-pem');
    const encrypted_email = key.encrypt(values.email, 'base64');
    socket.emit('forgot_password', {
      email: encrypted_email,
    });
  };

  return (
    <Row type="flex" className="login-container">
      <Col xs={0} sm={0} md={9} lg={12} className="white-color">
        <div className="back-img">
          <img src={backImg} alt="flag" />
        </div>
      </Col>
      <Col xs={24} sm={24} md={15} lg={12} className="white-color">
        <div className="form-container-user">
          <div className="containers">
            <div>
              <div className="field-container big">
                <div className="header">
                  <div className="header-image">
                    <img src={headerImg} alt="tax-acc-logo" />
                  </div>
                </div>
                <Form form={form} onFinish={onFinish}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Имэйл хаяг оруулна уу',
                      },
                      { type: 'email', message: 'Зөв имэйл оруулна уу' },
                    ]}
                  >
                    <Input
                      id="email"
                      type="email"
                      size="large"
                      placeholder="Имэйл хаяг"
                      style={{
                        width: '100%',
                      }}
                      prefix={utils.userIcon}
                    />
                  </Form.Item>
                  <Row>
                    <Col span={8} style={{ textAlign: 'left' }}>
                      <Form.Item noStyle>
                        <Button
                          className="confirm-btn"
                          type="primary"
                          shape="round"
                          onClick={() => {
                            history.push('/login');
                          }}
                        >
                          Буцах
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col span={16} style={{ textAlign: 'right' }}>
                      <Form.Item noStyle>
                        <Button
                          className="confirm-btn"
                          type="primary"
                          shape="round"
                          htmlType="submit"
                        >
                          Нэг удаагийн нууц үг явуулах
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
