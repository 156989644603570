import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { SelectOutlined } from '@ant-design/icons';

export const ZuruuColumns = () => [
  {
    title: 'Огноо ',
    dataIndex: 'ognoo',
    align: 'left',
    width: 70,
    fixed: 'left',
    render: (_, row) => (
      <>
        <p>{row?.ognoo}</p>
        {row?.sanhuu_type !== 'none' || row?.tatvar_type !== 'none' ? (
          <>
            <p>
              {row?.sanhuu_text !== 'none'
                ? row?.sanhuu_text
                : row?.tatvar_text}
            </p>
          </>
        ) : (
          <></>
        )}
      </>
    ),
  },
  {
    title: 'Санхүү-Өртөг',
    children: [
      {
        title: 'Анхны өртөг',
        dataIndex: 'urtug_anh',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Их засвар',
        dataIndex: 'urtug_capital_zasvar',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Дахин үнэлгээ',
        dataIndex: 'urtug_du_nemegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Нийт',
        dataIndex: 'urtug_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Санхүү-Элэгдлийн зардал',
    children: [
      {
        title: 'Анхны өртөг',
        dataIndex: 'zardal_anh',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Их засвар',
        dataIndex: 'zardal_capital_zasvar',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Дахин үнэлгээ',
        dataIndex: 'zardal_du_nemegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Нийт',
        dataIndex: 'zardal_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Санхүү-Хуримтлагдсан элэгдэл',
    children: [
      {
        title: 'Анхны өртөг',
        dataIndex: 'hurumt_elegdel_anh',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Их засвар',
        dataIndex: 'hurumt_elegdel_capital_zasvar',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Дахин үнэлгээ',
        dataIndex: 'hurumt_elegdel_du_nemegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Нийт',
        dataIndex: 'hurumt_elegdel_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Санхүү-Дансны үнэ',
    children: [
      {
        title: 'Анхны өртөг',
        dataIndex: 'une_anh',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Их засвар',
        dataIndex: 'une_capital_zasvar',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Дахин үнэлгээ',
        dataIndex: 'une_du_nemegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Нийт',
        dataIndex: 'une_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Татвар-Өртөг',
    children: [
      {
        title: 'Анхны өртөг',
        dataIndex: 'urtug_anh_tat',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Засвар',
        dataIndex: 'urtug_capital_zasvar_tat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Нийт',
        dataIndex: 'urtug_niit_tat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Татвар-Элэгдлийн зардал',
    children: [
      {
        title: 'Анхны өртөг',
        dataIndex: 'zardal_anh_tat',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Засвар',
        dataIndex: 'zardal_capital_zasvar_tat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Нийт',
        dataIndex: 'zardal_niit_tat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Татвар-Хуримтлагдсан элэгдэл',
    children: [
      {
        title: 'Анхны өртөг',
        dataIndex: 'hurumt_elegdel_anh_tat',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Засвар',
        dataIndex: 'hurumt_elegdel_capital_zasvar_tat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Нийт',
        dataIndex: 'hurumt_elegdel_niit_tat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Татвар-Татварын суурь',
    children: [
      {
        title: 'Анхны өртөг',
        dataIndex: 'une_anh_tat',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Засвар',
        dataIndex: 'une_capital_zasvar_tat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Нийт',
        dataIndex: 'une_niit_tat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'ДҮ-ТС зөрүү',
    children: [
      {
        title: 'Нийт',
        dataIndex: 'zuruu',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Үүнээс ДҮНэмэгдэлд ногдох',
        dataIndex: 'zuruu_du',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Тайлант үеийн өөрчлөлт',
    children: [
      {
        title: 'Нийт',
        dataIndex: 'tur_zuruu_uurchlult',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Үүнээс ДҮНэмэгдэлд ногдох',
        dataIndex: 'tur_zuruu_uurchlult_du',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Зөрүүгийн төрөл',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'hasagdah_tur_zuruu',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'tatvar_nogdoh_tur_zuruu',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];


export const AddBurtgelColumns = ({rowSelect=()=>{}}) => [
  {
    title: 'Огноо ',
    dataIndex: 'ognoo',
    align: 'left',
    width: 70,
    fixed: 'left',
  },
  {
    title: 'Дебет',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'deb_too',
        subtype: 'base',
        align: 'right',
        type: 'number',
        width: 150,
        editable: true,
      },
      {
        title: 'Өртөг',
        dataIndex: 'deb_dun',
        subtype: 'base',
        align: 'right',
        type: 'number',
        width: 150,
        editable: true,
      },
      {
        title: 'Сонгох',
        align: 'center',
        width: 50,
        fixed: 'right',
        render: (_, row) => (
          <SelectOutlined id="second" onClick={() => rowSelect(row)} />
        ),
      },
    ],
  },
];
