import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  InputNumber,
  Row,
  Col,
  Spin,
  Button,
  Collapse,
  Form,
  Input,
  DatePicker,
  Tooltip,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  LoadingOutlined,
  DownOutlined,
  UpOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import moment from 'moment';
import { enable_state, disable_state } from 'src/core/actions/route_state';
import { customConfirm } from 'src/components/common/confirmModal';
import debounce from 'lodash.debounce';
import { roundFloat, replaceFloat } from 'src/core/utils/formater';

const { Panel } = Collapse;

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editingValue, setEditingValue] = useState(null);

  // Debounce handler for the value change to avoid immediate rounding
  const debouncedSave = debounce((value) => {
    const roundedValue = roundFloat(value); // Round after debounce
    handleSave(record.key, dataIndex, roundedValue);
  }, 300); // Adjust debounce delay (300ms here)

  const handleChange = (value) => {
    setEditingValue(value); // Temporarily hold the raw input before rounding
    debouncedSave(value);
  };

  useEffect(
    () =>
      // Clean up debounce on unmount
      () => {
        debouncedSave.cancel();
      },
    []
  );

  let childNode = children || '';

  if (editable && record) {
    childNode = (
      <InputNumber
        min={0}
        value={editingValue !== null ? editingValue : record[dataIndex]}
        onChange={handleChange}
        className="wide-input-number" // Apply the custom class
        step={0.01} // Allow smooth input for decimal numbers
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableCell2 = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave2,
  ...restProps
}) => {
  const [editingValue, setEditingValue] = useState(null);

  // Debounce handler for the value change to avoid immediate rounding
  const debouncedSave = debounce((value) => {
    const roundedValue = roundFloat(value); // Round after debounce
    handleSave2(record.key, dataIndex, roundedValue);
  }, 300); // Adjust debounce delay (300ms here)

  const handleChange = (value) => {
    setEditingValue(value); // Temporarily hold the raw input before rounding
    debouncedSave(value);
  };

  useEffect(
    () =>
      // Clean up debounce on unmount
      () => {
        debouncedSave.cancel();
      },
    []
  );

  let childNode = children || '';

  if (editable && record) {
    childNode = (
      <InputNumber
        min={0}
        value={editingValue !== null ? editingValue : record[dataIndex]}
        onChange={handleChange}
        className="wide-input-number" // Apply the custom class
        step={0.01} // Allow smooth input for decimal numbers
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
const EditableCell3 = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave3,
  ...restProps
}) => {
  const [editingValue, setEditingValue] = useState(null);

  // Debounce handler for the value change to avoid immediate rounding
  const debouncedSave = debounce((value) => {
    const roundedValue = roundFloat(value); // Round after debounce
    handleSave3(record.key, dataIndex, roundedValue);
  }, 300); // Adjust debounce delay (300ms here)

  const handleChange = (value) => {
    setEditingValue(value); // Temporarily hold the raw input before rounding
    debouncedSave(value);
  };

  useEffect(
    () =>
      // Clean up debounce on unmount
      () => {
        debouncedSave.cancel();
      },
    []
  );

  let childNode = children || '';

  if (editable && record) {
    childNode = (
      <InputNumber
        min={0}
        value={editingValue !== null ? editingValue : record[dataIndex]}
        onChange={handleChange}
        className="wide-input-number" // Apply the custom class
        step={0.01} // Allow smooth input for decimal numbers
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

// const EditableCell = ({
//   title,
//   editable,
//   children,
//   dataIndex,
//   record,
//   handleSave,
//   ...restProps
// }) => {
//   const [editingValue, setEditingValue] = useState(null);

//   const handleChange = (value) => {
//     setEditingValue(roundFloat(value));
//     handleSave(record.key, dataIndex, value);
//   };

//   let childNode = children || '';

//   if (editable && record) {
//     childNode = (
//       <InputNumber
//         min={0}
//         value={
//           editingValue !== null ? editingValue : roundFloat(record[dataIndex])
//         }
//         onChange={handleChange}
//       />
//     );
//   }

//   return <td {...restProps}>{childNode}</td>;
// };

const App = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
  });

  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));
  const dispatch = useDispatch();

  const [dataSource, setDataSource] = useState([]);
  const [dataSource2, setDataSource2] = useState([]);
  const [dataSource3, setDataSource3] = useState([]);
  const [text, setText] = useState('');

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const get_data = (month) => {
    socket.on('request_out', ({ status, content }) => {
      console.log('🚀 ~ socket.on ~ content:', content);
      socket.removeAllListeners('request_out');
      if (status === '0') {
        setDataSource(content?.table1);
        setDataSource2(content?.table2);
        setDataSource3(content?.table3);
        setText(content.text);
      } else {
        newAlert({ msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'get_sa17',
        month,
      },
    });
    dispatch(start_loading({}));
  };

  const handleSend = async () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        onSuccess: [get_data(data_date.month), dispatch(disable_state({}))],
      });
      dispatch(end_loading({}));
    });
    const content = {
      month: data_date?.month,
      action_type: 'save_sa17',
      table_1: dataSource,
      table_2: dataSource2,
      table_3: dataSource3,
      text,
    };

    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content,
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    get_data(data_date.month);
  }, [data_date.month]);

  const handleSave = (key, dataIndex, value) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];

    // Round the value before saving it
    const roundedValue = replaceFloat(value);
    console.log('Original value:', value);
    console.log('Rounded value:', roundedValue);
    // Update the data source with the rounded value
    newData.splice(index, 1, { ...item, [dataIndex]: roundedValue });

    setDataSource(newData);
  };
  const handleSave2 = (key, dataIndex, value) => {
    const newData = [...dataSource2];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];

    // Round the value before saving it
    const roundedValue = replaceFloat(value);
    console.log('Original value:', value);
    console.log('Rounded value:', roundedValue);
    // Update the data source with the rounded value
    newData.splice(index, 1, { ...item, [dataIndex]: roundedValue });

    setDataSource2(newData);
  };
  const handleSave3 = (key, dataIndex, value) => {
    const newData = [...dataSource3];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];

    // Round the value before saving it
    const roundedValue = replaceFloat(value);
    console.log('Original value:', value);
    console.log('Rounded value:', roundedValue);
    // Update the data source with the rounded value
    newData.splice(index, 1, { ...item, [dataIndex]: roundedValue });

    setDataSource3(newData);
  };

  const columns = [
    {
      title: 'Үзүүлэлт',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      align: 'left',
      render: (text, record) => text,
    },
    {
      title: 'Эхний үлдэгдэл',
      children: [
        {
          title: 'Тоо ширхэг',
          dataIndex: 'eh_too',
          key: 'eh_too',
          editable: true,
          width: 200,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'Дүн(төгрөгөөр)',
          dataIndex: 'eh_mnt',
          key: 'eh_mnt',
          editable: true,
          width: 200,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
      ],
    },
    {
      title: 'Эцсийн үлдэгдэл',
      children: [
        {
          title: 'Тоо ширхэг',
          dataIndex: 'ets_too',
          key: 'ets_too',
          editable: true,
          width: 200,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'Дүн(төгрөгөөр)',
          dataIndex: 'ets_mnt',
          key: 'ets_mnt',
          editable: true,
          width: 200,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
      ],
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }
    if (col.children) {
      // Handle child columns
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editableFields
              ? record.editableFields[childCol.dataIndex]
              : false,
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave,
          }),
        })),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editableFields
          ? record.editableFields[col.dataIndex]
          : false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const columns2 = [
    {
      title: 'Татварын өрийн төрөл',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      align: 'left',
      render: (text, record) => text,
    },
    {
      title: 'Үндсэн хөрөнгийн дахин үнэлгээний нэмэгдэл',
      dataIndex: 'uh_unelgee',
      key: 'uh_unelgee',
      width: 200,
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Биет бус хөрөнгийн дахин үнэлгээний нэмэгдэл',
      dataIndex: 'biet_bus',
      key: 'biet_bus',
      editable: true,
      width: 200,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Нийт дүн',
      dataIndex: 'niit_dun',
      key: 'niit_dun',
      editable: true,
      width: 200,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
  ];

  const mergedColumns2 = columns2.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }
    if (col.children) {
      // Handle child columns
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editableFields
              ? record.editableFields[childCol.dataIndex]
              : false,
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave2,
          }),
        })),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editableFields
          ? record.editableFields[col.dataIndex]
          : false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave2,
      }),
    };
  });

  const columns3 = [
    {
      title: 'Татварын өрийн төрөл',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      align: 'left',
      render: (text, record) => text,
    },
    {
      title: 'Эхний үлдэгдэл',
      dataIndex: 'eh_uld',
      key: 'eh_uld',
      editable: true,
      width: 200,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Нэмэгдсэн',
      dataIndex: 'nemegdsen',
      key: 'nemegdsen',
      editable: true,
      width: 200,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Хасагдсан',
      dataIndex: 'hasagdsan',
      key: 'hasagdsan',
      editable: true,
      width: 200,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Эцсийн үлдэгдэл',
      dataIndex: 'ets_uld',
      key: 'ets_uld',
      editable: true,
      width: 200,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
  ];
  const mergedColumns3 = columns3.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }
    if (col.children) {
      // Handle child columns
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editableFields
              ? record.editableFields[childCol.dataIndex]
              : false,
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave3,
          }),
        })),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editableFields
          ? record.editableFields[col.dataIndex]
          : false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave3,
      }),
    };
  });

  return (
    <div className="tax-account-table-container">
      <Collapse
        defaultActiveKey={['1']}
        expandIconPosition="right"
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ? <UpOutlined /> : <DownOutlined />
        }
      >
        <Panel header="17.1 Өмч" key="1">
          <Table
            className="tax-account-table-panel mw-1500"
            dataSource={dataSource}
            columns={mergedColumns}
            rowClassName={() => 'editable-row'}
            bordered
            pagination={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            footer={() => (
              <Button
                type="primary"
                className="tax-account-button-unique-mini"
                onClick={() => handleSend(1)}
              >
                Хадгалах
              </Button>
            )}
          />
        </Panel>
        <Panel header="17.2 Хөрөнгийн дахин үнэлгээний нэмэгдэл" key="2">
          <Table
            className="tax-account-table-panel mw-1500"
            dataSource={dataSource2}
            columns={mergedColumns2}
            rowClassName={() => 'editable-row'}
            bordered
            pagination={false}
            components={{
              body: {
                cell: EditableCell2,
              },
            }}
            footer={() => (
              <Button
                type="primary"
                className="tax-account-button-unique-mini"
                onClick={() => handleSend(1)}
              >
                Хадгалах
              </Button>
            )}
          />
        </Panel>
        <Panel header="17.3 Гадаад валютын хөрвүүлэлтийн нөөц" key="3">
          <Table
            className="tax-account-table-panel mw-1500"
            dataSource={dataSource3}
            columns={mergedColumns3}
            rowClassName={() => 'editable-row'}
            bordered
            pagination={false}
            components={{
              body: {
                cell: EditableCell3,
              },
            }}
            footer={() => (
              <Button
                type="primary"
                className="tax-account-button-unique-mini"
                onClick={() => handleSend(1)}
              >
                Хадгалах
              </Button>
            )}
          />
        </Panel>

        <Panel
          header="17.4 Тэмдэглэл (Эздийн өмчийн бусад хэсгийн бүрэлдэхүүн тус бүрээр тодруулж тайлбар, тэмдэглэл хийнэ. )"
          key="4"
        >
          <div className="tax-account-notes-container">
            <table className="tax-account-table-unique">
              <thead>
                <tr>
                  <th>Тайлбар</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <textarea
                      value={text}
                      onChange={handleChange}
                      rows={10}
                      className="tax-account-textarea-unique"
                      placeholder="Бөглөнө үү"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <Button
              type="primary"
              className="tax-account-button-unique-mini"
              onClick={() => handleSend(2)}
            >
              Хадгалах
            </Button>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default App;
