import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { customConfirm } from 'src/components/common/confirmModal';
import { Tooltip } from 'antd';

export const columns = ({ delete_row = () => {} }) => [
  {
    title: '№',
    align: 'left',
    width: 20,
    render: (_, row) => row?.id + 1,
  },
  {
    title: 'Тусгай зөвшөөрөлтэй эсэх',
    align: 'center',
    titleAlign: 'center',
    width: '15vh',
    render: (_, row) => (row?.tusgai_zowshoorol === '2' ? 'Тийм' : 'Үгүй'),
  },
  {
    title: 'Тусгай зөвшөөрөл ангилал',
    dataIndex: 'angilal_ner',
  },
  {
    title: 'Тусгай зөвшөөрөл төрөл',
    dataIndex: 'uil_ajillagaa_torol_ner',
  },
  {
    title: 'Огноо',
    dataIndex: 'created_date',
  },
  {
    title: 'Устгах',
    width: 50,
    render: (_, row) => (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <Tooltip title="Устгах">
          <DeleteOutlined
            onClick={() =>
              customConfirm({
                onOk: () => delete_row(row?.uil_ajillagaa_id),
              })
            }
          />
        </Tooltip>
      </div>
    ),
  },
];
