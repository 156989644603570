import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Button as AntButton,
  Form,
  Row,
  Col,
  Select,
  Input,
  Modal,
} from 'antd';

import DatePicker from 'src/components/common/datePicker';
import { onFilter } from 'src/core/utils/selectFilter';
import { onlyNumber } from 'src/core/utils/formater';

import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from '../../../core/actions/loader';

const { Option } = Select;

export const AddUrsgalZasvar = ({ onCancel = () => {}, zasvarVisible }) => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);

  const [HurunguList, setHurunguList] = useState([]);
  const [BurtgelList, setBurtgelList] = useState([]);
  const [SelectedHurunguId, setSelectedHurunguId] = useState(null);
  const [SelectedBurtgelId, setSelectedBurtgelId] = useState(null);

  function select_hurungu_list() {
    socket.on('request_out', (def_data) => {
      const data_temp = def_data;
      socket.removeAllListeners('request_out');

      if (data_temp.status === '0') {
        const { content } = data_temp;
        setHurunguList(content?.result_list);
      } else {
        // setIsSuccess(false);
        // setOpenAlert(true);
        // setAlertMsg("Системд алдаа гарлаа");
      }
      dispatch(end_loading({}));
    });

    socket.emit('request_in', {
      channel: 'ts003_view',
      content: { action_type: 'undsen_hurungu_list' },
    });
    dispatch(start_loading({}));
  }
  function select_burtgel_list() {
    socket.on('request_out', (def_data) => {
      const data_temp = def_data;
      socket.removeAllListeners('request_out');

      if (data_temp.status === '0') {
        const { content } = data_temp;
        setBurtgelList(content?.result_list);
      } else {
        // setIsSuccess(false);
        // setOpenAlert(true);
        // setAlertMsg("Системд алдаа гарлаа");
      }
      dispatch(end_loading({}));
    });

    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'burtgel_list',
        hurungu_id: SelectedHurunguId,
      },
    });
    dispatch(start_loading({}));
  }

  useEffect(() => {
    if (zasvarVisible) {
      select_hurungu_list();
      // console.log('data_date', data_date);
    }
  }, [zasvarVisible]);

  useEffect(() => {
    if (SelectedHurunguId != null) {
      select_burtgel_list();
    }
  }, [SelectedHurunguId]);

  const [form] = Form.useForm();

  const handleSave = (values) => {
    if (version?.year) {
      const this_year = version.year.split('/')[0];
      if (moment(values.ognoo).isSame(`${this_year}/01/01`, 'year')) {
        const new_data = {
          burtgel_id: SelectedBurtgelId,
          ognoo: moment(values.ognoo).format('YYYY/MM/DD'),
          dun: values.dun.replaceAll(',', ''),
        };

        socket.on('request_out', (req_data) => {
          const data_temp = req_data;
          socket.removeAllListeners('request_out');

          if (data_temp.status === '0') {
            newAlert({ type: 'success', msg: data_temp.message });

            onCancel();
          } else {
            newAlert({ type: 'error', msg: data_temp.message });
          }
          dispatch(end_loading({}));
        });
        socket.emit('request_in', {
          channel: 'ts003_modify',
          content: {
            action_type: 'insert_ursgal_zasvar',
            json_array: { ...new_data },
          },
        });

        dispatch(start_loading({}));
      } else {
        newAlert({ type: 'error', msg: 'Огноо таарахгүй байна' });
      }
    } else {
      newAlert({ type: 'error', msg: 'System error' });
    }
  };

  const onChangeHurungu = (value) => {
    setSelectedHurunguId(value);
    setSelectedBurtgelId(null);
    setBurtgelList([]);
    console.log('value', value);
  };
  const onChangeBurgel = (value) => {
    setSelectedBurtgelId(value);
    console.log('value', value);
  };
  const onFinish = (values) => {
    console.log(values);
    handleSave(values);
  };
  return (
    <Modal
      title=" Урсгал засвар нэмэх"
      visible={zasvarVisible}
      width={800}
      footer={null}
      closable={false}
      maskClosable={false}
      onCancel={onCancel}
      wrapClassName="custom-modal"
    >
      <Form
        form={form}
        name="basic"
        style={{
          fontSize: 13,
        }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item label="Үндсэн хөрөнгө ">
          <Select
            placeholder="Үндсэн хөрөнгөө сонгоно уу!"
            showSearch
            allowClear
            filterOption={onFilter}
            onChange={onChangeHurungu}
            value={SelectedHurunguId}
          >
            {HurunguList ? (
              HurunguList.map((value) => (
                <Option value={value.hurungu_id}>
                  {value.code} {value.ner}{' '}
                </Option>
              ))
            ) : (
              <div />
            )}
          </Select>
        </Form.Item>

        <Form.Item label="Бүртгэлийн дугаар" name="burtgel_id">
          <Select
            placeholder="Бүртгэлээ сонгоно уу!"
            allowClear
            disabled={SelectedHurunguId == null}
            onChange={onChangeBurgel}
          >
            {BurtgelList ? (
              BurtgelList.map((value) => (
                <Option value={value.burtgel_id}>
                  {value.burtgel_dugaar}{' '}
                </Option>
              ))
            ) : (
              <div />
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name="ognoo"
          label="Огноо"
          labelAlign="right"
          // normalize={onlyNumber}
          rules={[
            {
              required: true,
              // message: 'Дүн оруулна уу...',
              message: '',
              // type: 'number',
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="dun"
          label="Дүн"
          normalize={onlyNumber}
          rules={[
            {
              required: true,
              // message: 'Дүн оруулна уу...',
              message: 'Урсгал засварын дүнгээ оруулна уу!',
              // type: 'number',
            },
          ]}
        >
          <Input
            defaultValue={0.0}
            // bordered={false}
            className="w-100"
          />
        </Form.Item>

        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item noStyle>
              <AntButton
                onClick={() => {
                  form.resetFields();
                  onCancel();
                }}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Буцах
              </AntButton>
              <AntButton
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
                disabled={SelectedBurtgelId == null}
              >
                Хадгалах
              </AntButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
