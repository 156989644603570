import UserCreate from 'src/pages/register/createUser';
import Login from 'src/pages/login/newLogin';
import ForgotPassword from 'src/pages/forgotPassword';

// landing section
const landingList = {
  Login: {
    key: 'landing_login',
    path: '/login',
    component: Login,
  },
  UserCreate: {
    key: 'landing_register',
    path: '/registerNew',
    component: UserCreate,
  },
  ForgotPassword: {
    key: 'landing_forgot',
    path: '/forgotpassword',
    component: ForgotPassword,
  },
};

const _landingList = [...Object.values(landingList)].map((j) => j.path);
const routes = [...Object.values(landingList)];

export default {
  checkList: _landingList,
  routes,
};
