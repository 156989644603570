import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Table, Row, Col, Spin, Button, Collapse } from 'antd';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';

import { expandColumns, exampleColumn, temp_data } from './dataStructure/data';

const { Panel } = Collapse;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const columns = exampleColumn;

const Tailan = ({ description }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const [tableData, setTableData] = useState([]);
  const [text, setText] = useState(description);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleSend = async (table_type) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
      });
      dispatch(end_loading({}));
    });
    const content = {
      month: data_date?.month,
      action_type: 'save_sa06',
      table_type,
      table_data: tableData,
      text,
    };

    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content,
    });
    dispatch(start_loading({}));
  };

  const generateHmTailan = (month) => {
    setLoading(true);
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        console.log('===============================');
        console.log(content);
        setTableData(content?.table_data);
        setText(content?.text);
        console.log('===============================');
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'get_sa06',
        month,
      },
    });
    setLoading(false);
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date?.month) generateHmTailan(data_date?.month);
  }, [data_date]);

  const mainSummary = (list = []) => (
    <Table.Summary>
      {list.map((j) => (
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={2}>
            <Row
              style={{
                width: '100% !important',
              }}
            >
              <Col
                style={{
                  textAlign: 'right',
                  paddingRight: 20,
                }}
                span={13}
              >
                {j.ner}
              </Col>
              <Col
                style={{
                  textAlign: 'center',
                }}
                span={1}
              >
                {j.mur}
              </Col>
              <Col
                style={{
                  textAlign: 'right',
                }}
                span={10}
              >
                {j.dun}
              </Col>
            </Row>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      ))}
    </Table.Summary>
  );

  const expandedRowRender = (data = null) => {
    if (data?.title) {
      return (
        <Table
          columns={expandColumns({ title: data?.title })}
          dataSource={data?.child}
          pagination={false}
        />
      );
    }

    return null;
  };
  if (loading) {
    return (
      <div className="loading-spin">
        <Spin indicator={antIcon} />
      </div>
    );
  }

  const title = () => <></>;
  return (
    <div className="tax-account-table-container">
      <Collapse
        defaultActiveKey={['4']}
        expandIconPosition="right"
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ? <UpOutlined /> : <DownOutlined />
        }
      >
        <Panel header="6. Бараа материал" key="1">
          <div
            style={{
              minWidth: 900,
              overflow: 'scroll',
            }}
          >
            <Table
              className="table-antd-div no-scroll"
              columns={columns}
              dataSource={tableData}
              expandable={{
                defaultExpandAllRows: true,
                expandedRowRender: (row) =>
                  expandedRowRender(row?.subRow || row?.subChildren),
                expandIcon: () => '',
                rowExpandable: (row) => {
                  if (!row?.subRow && !row?.subChildren) {
                    return null;
                  }
                  return row;
                },
              }}
              rowClassName="role-row"
              scroll={{
                y: true,
                x: 'auto',
              }}
              pagination={false}
              summary={() => mainSummary([])}
              title={title}
              footer={() => (
                <Button
                  type="primary"
                  className="tax-account-button-unique-mini"
                  onClick={() => handleSend(1)}
                >
                  Хадгалах
                </Button>
              )}
            />
          </div>
        </Panel>
        <Panel
          header="6.1 Тэмдэглэл: (Бараа материалын өртгийг тодорхойлоход ашигласан арга, бараа материалын бүртгэлийн систем, өртөг болон цэвэр боломжит үнийн аль багыг сонгох аргын талаар тайлбар, тэмдэглэл хийнэ.)"
          key="4"
        >
          <div className="tax-account-notes-container">
            <table className="tax-account-table-unique">
              <thead>
                <tr>
                  <th>Тайлбар</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <textarea
                      value={text}
                      onChange={handleChange}
                      rows={10}
                      className="tax-account-textarea-unique"
                      placeholder="Бөглөнө үү"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <Button
              type="primary"
              className="tax-account-button-unique-mini"
              onClick={() => handleSend(2)}
              loading={loading}
            >
              Хадгалах
            </Button>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default Tailan;
