import React from 'react';
import { Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

export const customConfirm = ({
  onOk = () => {},
  title = 'Устгах төлвийг зөвшөөрөх үү ?',
  content = null,
  icon = <WarningOutlined />,
  width = '400px',
}) => {
  Modal.confirm({
    width,
    title,
    icon,
    okText: 'Тийм',
    cancelText: 'Үгүй',
    content,
    onOk() {
      onOk();
    },
  });
};
