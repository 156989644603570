import React from 'react';
import {
  Form,
  Input,
  Select as AntSelect,
  Row,
  Col,
  Button,
  Upload,
  message,
} from 'antd';
import { get, set } from 'lodash';
import { CloudUploadOutlined } from '@ant-design/icons';
import DatePicker from 'src/components/common/datePicker';
import { onlyNumber } from 'src/core/utils/formater';

import { end_loading } from 'src/core/actions/loader';
import CustomSelect from 'src/components/common/customSelect';
import newAlert from 'src/components/newAlert';

const { Option } = AntSelect;
const typesList = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const NewValueAdd = ({
  form = null,
  data = null,
  close = () => {},
  socket = null,
  refetch = () => {},
  dispatch = null,
}) => {
  const getObject = (gazar_torol = '') => {
    const arr = [
      ...data?.dotood_tomilolt_array,
      ...data?.gadaad_tomilolt_array,
    ];
    return arr.find((i) => i.gazar_torol === gazar_torol);
  };
  const customText = (item) => `${item?.gazar_torol}`;

  const onFinish = (values) => {
    const id = form.getFieldValue('id');
    const action_type = !id ? 'add_tomilolt' : 'update_tomilolt_data';

    if (id) set(values, 'id', id);
    const newGazar_torol = getObject(values?.gazar_torol);
    set(values, 'gazar_torol', newGazar_torol);
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: {
          onSuccess: [form.resetFields(), refetch(), close()],
          onFail: [refetch(), close()],
        },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_tomilolt_service',
      content: {
        array_data: values,
        action_type,
      },
    });
  };

  return (
    <>
      <Form
        form={form}
        name="add"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        className="small-form"
        initialValues={{
          unaa_nisleg: 0,
          guitsetgel_tomilolt_zardal: 0,
          tomilolt_hun_honog: 0,
          tomilolt_torol: 1,
          gazar_torol: null,
        }}
        size="small"
        onFinish={onFinish}
      >
        <Form.Item
          label="Огноо"
          name="ognoo"
          rules={[{ required: true, message: '' }]}
        >
          <DatePicker customClass="h-25" />
        </Form.Item>

        <Form.Item
          label="Томилолтын төрөл"
          name="tomilolt_torol"
          rules={[{ required: true, message: '' }]}
        >
          <AntSelect
            className="ww-50"
            onChange={() => {
              form.setFieldsValue({ gazar_torol: null });
            }}
            placeholder="Сонгох"
          >
            {[
              {
                value: 1,
                text: 'Гадаад',
              },
              {
                value: 2,
                text: 'Дотоод',
              },
            ].map((i) => (
              <Option value={i?.value}>{i?.text}</Option>
            ))}
          </AntSelect>
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const name =
              getFieldValue('tomilolt_torol') === 1
                ? 'gadaad_tomilolt_array'
                : 'dotood_tomilolt_array';
            if (name) {
              return (
                <Form.Item
                  name="gazar_torol"
                  label="Томилолтоор явсан газар"
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                >
                  <CustomSelect
                    size="small"
                    className="ww-60"
                    list={get(data, name, [])}
                    valueProp="gazar_torol"
                    customText={customText}
                  />
                </Form.Item>
              );
            }
          }}
        </Form.Item>

        <Form.Item
          label="Томилолтын хүн/хоног"
          name="tomilolt_hun_honog"
          normalize={onlyNumber}
          rules={[{ required: true, message: '' }]}
        >
          <Input className="ww-90" />
        </Form.Item>

        <Form.Item
          label="Гүйцэтгэлээрх томилолтын зардал"
          name="guitsetgel_tomilolt_zardal"
          normalize={onlyNumber}
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input className="ww-90" />
        </Form.Item>

        <Form.Item
          label="Үүнээс унаа, нислэгийн зардал"
          name="unaa_nisleg"
          normalize={onlyNumber}
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input className="ww-90" />
        </Form.Item>
      </Form>
      <Row
        style={{
          marginTop: 8,
        }}
      >
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            className="cancel-btn"
            type="primary"
            shape="round"
            size="small"
            onClick={close}
          >
            Буцах
          </Button>
          <Button
            form="add"
            className="confirm-btn ml-5"
            type="primary"
            shape="round"
            htmlType="submit"
            size="small"
          >
            Хадгалах
          </Button>
        </Col>
      </Row>
    </>
  );
};

export const NewFileAddModal = ({
  onClose = () => {},
  form = null,
  handleUpload = () => {},
}) => {
  const onFinish = (values) => {
    handleUpload(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        size="small"
      >
        <Form.Item
          name="file"
          label="Файл"
          labelAlign="left"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: 'Файл сонгоно уу' }]}
        >
          <Upload
            accept={typesList.join()}
            showUploadList
            maxCount={1}
            beforeUpload={(File) => {
              const isXls = typesList.find((i) => i === File.type);
              if (!isXls) {
                message.error('Зөвхөн xls, xlsx өргөтгөлтэй файл оруулна уу');
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
          >
            <CloudUploadOutlined className="fs-30" />
          </Upload>
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
