import { isArray } from 'lodash';

export const onFilter = (input, option) => {
  const { children = '' } = option;
  if (isArray(children)) {
    const allIn = children.reduce((a, b) => a + b, 0);
    return allIn.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  return children && children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
