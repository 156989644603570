import React, { useEffect, useState } from 'react';
import { onFilter } from 'src/core/utils/selectFilter';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Button, Form, Row, Col, Select, Input, Modal } from 'antd';
import { onlyNumber } from 'src/core/utils/formater';
import DatePicker from 'src/components/common/datePicker';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from '../../../core/actions/loader';

const { Option } = Select;

export const UrsgalZasvar = ({ onCancel = () => {}, zasvarVisible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const socket = useSelector((state) => state.socket);

  const [HurunguList, setHurunguList] = useState([]);
  const [BurtgelList, setBurtgelList] = useState([]);
  const [SelectedHurunguId, setSelectedHurunguId] = useState(null);
  const [SelectedBurtgelId, setSelectedBurtgelId] = useState(null);

  const select_hurungu_list = () => {
    socket.on('request_out', (def_data) => {
      socket.removeAllListeners('request_out');
      if (def_data.status === '0') {
        setHurunguList(def_data?.content?.result_list);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: { action_type: 'undsen_hurungu_list' },
    });
    dispatch(start_loading({}));
  };

  function select_burtgel_list() {
    socket.on('request_out', (def_data) => {
      socket.removeAllListeners('request_out');
      if (def_data.status === '0') {
        setBurtgelList(def_data?.content?.result_list);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'burtgel_list',
        hurungu_id: SelectedHurunguId,
      },
    });
    dispatch(start_loading({}));
  }

  useEffect(() => {
    if (zasvarVisible) {
      select_hurungu_list();
    }
  }, [zasvarVisible]);

  useEffect(() => {
    if (SelectedHurunguId != null) {
      select_burtgel_list();
    }
  }, [SelectedHurunguId]);

  const handleSave = (values) => {
    const new_data = {
      ognoo: moment(values.ognoo).format('YYYY/MM/DD'),
      burtgel_id: SelectedBurtgelId,

      dun: values.dun.replaceAll(',', ''),
    };
    socket.on('request_out', (req_data) => {
      socket.removeAllListeners('request_out');
      if (req_data.status === '0') {
        form.resetFields();
        onCancel();
        newAlert({ type: 'success', msg: req_data.message });
      } else {
        newAlert({ type: 'error', msg: req_data.message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: {
        action_type: 'insert_ursgal_zasvar',
        json_array: { ...new_data },
      },
    });
    dispatch(start_loading({}));
  };

  const onChangeHurungu = (value) => {
    setSelectedHurunguId(value);
    setSelectedBurtgelId(null);
    setBurtgelList([]);
  };

  const onChangeBurgel = (value) => {
    setSelectedBurtgelId(value);
  };

  const onFinish = (values) => {
    handleSave(values);
  };

  const layout = {
    labelCol: {
      xs: { span: 10 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 14 },
      sm: { span: 14 },
    },
  };

  return (
    <Modal
      className="custom-modal"
      title=" Урсгал засвар нэмэх"
      open={zasvarVisible}
      closable={false}
      maskClosable={false}
      width={800}
      footer={null}
      onCancel={onCancel}
    >
      <div className="dans-medeelel-container">
        <Form
          form={form}
          onFinish={onFinish}
          className="register-form"
          {...layout}
          size="small"
        >
          <Form.Item label="Үндсэн хөрөнгө" labelAlign="left">
            <Select
              placeholder="Үндсэн хөрөнгөө сонгоно уу"
              allowClear
              onChange={onChangeHurungu}
              value={SelectedHurunguId}
              showSearch
              filterOption={onFilter}
            >
              {
                // eslint-disable-next-line operator-linebreak
                HurunguList &&
                  HurunguList.map((value) => (
                    <Option value={value.hurungu_id}>
                      {value.code} {value.ner}
                    </Option>
                  ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            label="Бүртгэлийн дугаар"
            labelAlign="left"
            name="burtgel_id"
          >
            <Select
              placeholder="Бүртгэлээ сонгоно уу"
              allowClear
              disabled={SelectedHurunguId == null}
              onChange={onChangeBurgel}
              // value={SelectedBurtgelId}
              showSearch
              filterOption={onFilter}
            >
              {
                // eslint-disable-next-line operator-linebreak
                BurtgelList &&
                  BurtgelList.map((value) => (
                    <Option value={value.burtgel_id}>
                      {value.burtgel_dugaar}{' '}
                    </Option>
                  ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            name="ognoo"
            label="Огноо"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <DatePicker customClass="ww-100 h-25" />
          </Form.Item>
          <Form.Item
            name="dun"
            label="Дүн"
            labelAlign="left"
            normalize={onlyNumber}
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input className="w-100" placeholder="Дүн оруулна уу..." />
          </Form.Item>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <Button
                  onClick={() => {
                    form.resetFields();
                    onCancel();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </Button>
                <Button
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  disabled={SelectedBurtgelId == null}
                >
                  Хадгалах
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
