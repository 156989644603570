// import React from 'react';
import { roundFloat } from 'src/core/utils/formater';

export const guilgeeColumns = [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dugaar',
    align: 'left',
    titleAlign: 'center',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'ner',
    align: 'left',
    titleAlign: 'center',
    width: 300,
    ellipsis: true,
  },
  {
    title: 'Журналаар ГБ',
    children: [
      {
        title: 'Бодит бус алдагдал',
        dataIndex: 'journal_bodit_bus_aldagdal',
        align: 'right',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.journal_bodit_bus_aldagdal),
        }),
      },
      {
        title: 'Бодит бус ашиг',
        dataIndex: 'journal_bodit_bus_ashig',
        align: 'right',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.journal_bodit_bus_ashig),
        }),
      },
    ],
  },
  {
    title: 'Ханш тохируулга',
    children: [
      {
        title: 'Бодит бус алдагдал',
        dataIndex: 'tohir_bodit_bus_aldagdal',
        align: 'right',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.tohir_bodit_bus_aldagdal),
        }),
      },
      {
        title: 'Бодит бус ашиг',
        dataIndex: 'tohir_bodit_bus_ashig',
        align: 'right',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.tohir_bodit_bus_ashig),
        }),
      },
    ],
  },
  {
    title: 'СТ-30а ТҮӨөрчлөлт',
    children: [
      {
        title: 'Бодит бус алдагдал',
        dataIndex: 'uurchlult_bodit_bus_aldagdal',
        align: 'right',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.uurchlult_bodit_bus_aldagdal),
        }),
      },
      {
        title: 'Бодит бус ашиг',
        dataIndex: 'uurchlult_bodit_bus_ashig',
        align: 'right',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.uurchlult_bodit_bus_ashig),
        }),
      },
    ],
  },
];

export const boditSummary = [
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'center',
    word: '',
  },
  {
    align: 'right',
    value: 'journal_bodit_aldagdal',
  },
  {
    align: 'right',
    value: 'journal_bodit_ashig',
  },
  {
    align: 'right',
    value: 'tohir_bodit_bus_aldagdal',
  },
  {
    align: 'right',
    value: 'tohir_bodit_bus_ashig',
  },
  {
    align: 'right',
    value: 'uurchlult_bodit_aldagdal',
  },
  {
    align: 'right',
    value: 'uurchlult_bodit_ashig',
  },
];

export const boditColumns = [
  {
    title: '',
    width: 250,
    fixed: 'left',
  },
  {
    title: '',
    width: 300,
    ellipsis: true,
  },
  {
    title: 'Журналаар ГБ',
    children: [
      {
        title: 'Бодит алдагдал',
        width: 150,
      },
      {
        title: 'Бодит ашиг',
        width: 150,
      },
    ],
  },
  {
    title: 'Ханш тохируулга',
    children: [
      {
        title: 'Бодит бус алдагдал',
        width: 150,
      },
      {
        title: 'Бодит бус ашиг',
        width: 150,
      },
    ],
  },
  {
    title: 'Үлдэгдэл Нийт',
    children: [
      {
        title: 'Бодит алдагдал',
        width: 150,
      },
      {
        title: 'Бодит ашиг',
        width: 150,
      },
    ],
  },
];
