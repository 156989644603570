import React from 'react';
import { Result, Button } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Error = () => {
  const history = useHistory();
  const jwt_token = useSelector((state) => state.jwt_token);
  const isLogged = jwt_token?.user_state === '1';
  const toHome = () => {
    const url = isLogged ? '/main' : '/login';
    history.push(url);
  };
  const extraButton = (
    <Button type="primary" onClick={toHome}>
      {isLogged ? ' Үндсэн цэс рүү буцах' : 'Нэвтрэх цэс рүү буцаx'}
    </Button>
  );
  return (
    <div className="error-container">
      <Result
        status="404"
        title="404"
        subTitle="Хуудас олдсонгүй"
        extra={extraButton}
      />
    </div>
  );
};
export default Error;
