import React from 'react';
import { Button as AntButton, Table as AntTable, Row, Col } from 'antd';

import { roundFloat } from 'src/core/utils/formater';
import Table from '../../../../components/common/table';

const GuilgeeJagsaaltModal = ({
  detailParam,
  footerParam,
  dansCode,
  onClose = () => {},
}) => (
  <>
    <div style={{ marginBottom: '5px' }}>
      <Row>
        <Col xs={24}>
          <p>
            <span className="ml-3">Данс: {dansCode}</span>
          </p>
        </Col>
        <Col xs={24}>
          <p>
            <span className="ml-3">
              Харилцагч: {detailParam[0]?.hariltsagch_name}
            </span>
          </p>
        </Col>
      </Row>
    </div>
    <Table
      columns={[
        {
          title: 'Огноо',
          dataIndex: 'ognoo',
          align: 'left',
          width: 70,
          fixed: 'left',
        },
        {
          title: 'Гүйлгээний утга',
          dataIndex: 'guilgeenii_utga',
          align: 'left',
          ellipsis: true,
          width: 250,
        },
        {
          title: 'Журналын ханш',
          dataIndex: 'hansh',
          width: 100,
          align: 'right',
          render: (_, row) => <>{roundFloat(row?.hansh)}</>,
        },
        {
          title: 'Монгол банкны ханш',
          dataIndex: 'mb_hansh',
          width: 100,
          align: 'right',
          render: (_, row) => (
            <>{row?.mb_hansh ? roundFloat(row?.mb_hansh) : roundFloat(0.0)}</>
          ),
        },
        {
          title: 'Ханшийн зөрүү',
          dataIndex: 'hanshiin_zuruu',
          align: 'right',
          width: 150,
          render: (_, row) => <>{roundFloat(row?.hanshiin_zuruu)}</>,
        },
        {
          title: 'Гүйлгээ',
          align: 'center',
          children: [
            {
              title: 'Дебет',
              dataIndex: 'deb',
              align: 'right',
              width: 150,
              render: (_, row) => (
                <>
                  {row?.valiut === 'MNT' || !row?.valiut ? (
                    roundFloat(row?.deb)
                  ) : (
                    <div>
                      <p>{roundFloat(row?.deb)}</p>
                      <p className="bg-highlight">
                        {roundFloat(row?.deb_valiut)}
                      </p>
                    </div>
                  )}
                </>
              ),
            },
            {
              title: 'Кредит',
              dataIndex: 'cred',
              align: 'right',
              width: 150,
              render: (_, row) => (
                <>
                  {row?.valiut === 'MNT' || !row?.valiut ? (
                    roundFloat(row?.cred)
                  ) : (
                    <div>
                      <p>{roundFloat(row?.cred)}</p>
                      <p className="bg-highlight">
                        {roundFloat(row?.cred_valiut)}
                      </p>
                    </div>
                  )}
                </>
              ),
            },
          ],
        },
        {
          title: 'Эцсийн үлдэгдэл /Дансны үнэ/',
          dataIndex: 'ets_uld',
          align: 'right',
          width: 150,
          render: (_, row) => (
            <>
              {row?.valiut === 'MNT' || !row?.valiut ? (
                roundFloat(row?.ets_uld)
              ) : (
                <div>
                  <p>{roundFloat(row?.ets_uld)}</p>
                  <p className="bg-highlight">
                    {roundFloat(row?.ets_uld_valiut)}
                  </p>
                </div>
              )}
            </>
          ),
        },
      ]}
      dataSource={detailParam}
      scroll={{
        y: 175,
      }}
      customize={() => (
        <AntTable.Summary fixed>
          <AntTable.Summary.Row>
            <AntTable.Summary.Cell align="right" colSpan={2}>
              Нийт дүн:
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell colSpan={2} />
            <AntTable.Summary.Cell align="right">
              <p>{roundFloat(footerParam?.hanshiin_zuruu)}</p>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell align="right">
              <p>{roundFloat(footerParam?.deb)}</p>
              <p className="bg-highlight">
                {roundFloat(footerParam?.deb_valiut)}
              </p>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell align="right">
              <p>{roundFloat(footerParam?.cred)}</p>
              <p className="bg-highlight">
                {roundFloat(footerParam?.cred_valiut)}
              </p>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell />
          </AntTable.Summary.Row>
        </AntTable.Summary>
      )}
    />
    <div style={{ textAlign: 'right', marginTop: 10 }}>
      <AntButton
        onClick={onClose}
        className="cancel-btn"
        type="primary"
        shape="round"
      >
        Буцах
      </AntButton>
    </div>
  </>
);

export default GuilgeeJagsaaltModal;
