import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { roundFloat, onlyNumber } from 'src/core/utils/formater';

import {
  EditOutlined,
  PlusCircleOutlined,
  CloudUploadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import CustomDatePicker from 'src/components/common/datePicker';
import {
  Typography,
  Table,
  Button as AntButton,
  Modal,
  Form,
  Card,
  List,
  Input,
  Row,
  Col,
  Upload,
  message,
  Spin,
  Empty,
  Tooltip,
} from 'antd';

import axios from 'src/core/utils/axios';
import { isEmpty, set, isArray } from 'lodash';
import TableCommon from 'src/components/common/table';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import moment from 'moment';
import CanView from 'src/components/permission/canview';
import { column_22_5_11 } from './dataStructure/data';

const { Text } = Typography;
const { TextArea } = Input;
const typesList = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg',
  'image/png',
  'application/pdf',
];

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = (
    <Input
      size="small"
      style={{
        width: '90%',
        margin: 0,
      }}
    />
  );
  return (
    <td {...restProps}>
      {editing === 'true' ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          normalize={onlyNumber}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Client = () => {
  const [form] = Form.useForm();
  const [form_3] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const currentData = form_3.getFieldValue();
  const socket = useSelector((state) => state.socket);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [visible, seTvisible] = useState(false);
  const [update, SetUpdate] = useState(false);
  const [horongoOruulalt, setHorongoOruulalt] = useState({
    oruulsan_horongo_oruulalt: 0,
    zartsuulsan_zardal: 0,
    uldegdel: 0,
  });

  const [actionType, setActionType] = useState('');
  const FileDownload = require('js-file-download');
  const [columnData, setColumnData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [_tableData1, _setTableData1] = useState({
    list: [],
    sum: [],
  });

  const [_tableData2, _setTableData2] = useState({
    list: [],
    sum: [],
  });

  const [loading, seTloading] = useState(false);
  const [ajiltanData, setAjiltanData] = useState({
    niitAjiltanToo: 0,
    tootsohAjiltanToo: 0,
  });
  const [hodolmorChadvarAldagdaltHuvi, setHodolmorChadvarAldagdaltHuvi] =
    useState(0);
  const hogjiliinBerhsheelData = [
    {
      title: 'Хамрагдах хугацаа:',
      value: `1 - ${selectedMonth} сар`,
      type: '',
    },
    {
      title: 'Нийт ажилтны тоо:',
      value: ajiltanData?.niitAjiltanToo,
      type: 'editable',
      action_type: 'niitAjiltanToo',
    },
    {
      title: 'Хөгжлийн бэрхшээлтэй ажилтны тоо:',
      value: ajiltanData?.tootsohAjiltanToo,
      type: 'editable',
      action_type: 'tootsohAjiltanToo',
    },
  ];

  const hodolmoriinChadvarAldalttai = [
    {
      title: 'Хамрагдах хугацаа:',
      value: `1 - ${selectedMonth} сар`,
      type: '',
    },
    {
      title: 'Эзлэх хувь:',
      value: `${roundFloat(hodolmorChadvarAldagdaltHuvi)}%`,
      type: '',
    },
    {
      title: 'Нийт ажилтны тоо:',
      value: ajiltanData?.niitAjiltanToo,
      type: 'editable',
      action_type: 'niitAjiltanToo',
    },
    {
      title: 'Хөдөлмөрийн чадвар алдалт 50%-с дээш ажилтны тоо:',
      value: ajiltanData?.tootsohAjiltanToo,
      type: 'editable',
      action_type: 'tootsohAjiltanToo',
    },
  ];

  const SetValue = (type, value) => {
    const _currentValue = { ...horongoOruulalt };
    _currentValue[type] = value;
    _currentValue.uldegdel =
      _currentValue.oruulsan_horongo_oruulalt -
      _currentValue.zartsuulsan_zardal;
    setHorongoOruulalt(_currentValue);
  };

  const onCancel = () => {
    seTvisible(false);
    form_3.resetFields();
  };

  const UpdateCancel = () => {
    SetUpdate(false);
    form_3.resetFields();
  };

  const convertSumArray = (sum) => {
    const arr = new Array(2);
    if (isEmpty(sum)) return [];
    Object.entries(sum).forEach((i) => {
      if (i[0] === 'dans_ner') {
        arr[0] = i[1];
      }
      if (i[0] === 'dans_dun') {
        arr[1] = roundFloat(i[1]);
      }
      if (i[0].startsWith('type')) {
        arr.push(roundFloat(i[1]));
      }
    });

    arr.push(roundFloat(sum?.niit_dun));
    return arr;
  };

  const mainData = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        if (content?.response_type === 'type_22_5_2') {
          setAjiltanData({
            niitAjiltanToo: content?.niit_ajiltnii_too,
            tootsohAjiltanToo: content?.hogjiliin_berhsheeltei_ajiltan_too,
          });
          setActionType(content?.response_type);
          setSelectedMonth(content?.selected_month);
          dispatch(end_loading({}));
        } else if (content?.response_type === 'type_22_5_6') {
          setAjiltanData({
            niitAjiltanToo: content?.niit_ajiltnii_too,
            tootsohAjiltanToo: content?.aldalttai_ajiltan_too,
          });
          setHodolmorChadvarAldagdaltHuvi(content.ezleh_huvi);
          setActionType(content?.response_type);
          setSelectedMonth(content.selected_month);
          dispatch(end_loading({}));
        } else if (content?.response_type === 'type_22_1') {
          setActionType(content?.response_type);
          dispatch(end_loading({}));
        } else if (content?.response_type === 'type_22_5_11') {
          console.log('=============type_22_5_11==================');
          console.log(content);
          console.log('=============type_22_5_11==================');
          setActionType(content.response_type);
          setSelectedMonth(content.selected_month);
          const new_temp_data = content?.table_data.map((inner_data, key) => ({
            id: key,
            ...inner_data,
          }));
          if (new_temp_data.length > 0) {
            setTableData(new_temp_data);
          }
          dispatch(end_loading({}));
        } else {
          if (isEmpty(content)) return;
          setActionType(content?.response_type);
          setSelectedMonth(content.selected_month);

          const temp_horongo_oruulalt = horongoOruulalt;
          temp_horongo_oruulalt.oruulsan_horongo_oruulalt =
            content?.horongo_oruulalt?.oruulsan_horongo_oruulalt;
          temp_horongo_oruulalt.zartsuulsan_zardal =
            content?.horongo_oruulalt?.zartsuulsan_zardal;
          temp_horongo_oruulalt.uldegdel = content?.horongo_oruulalt?.uldegdel;

          setHorongoOruulalt(temp_horongo_oruulalt);

          setColumnData(
            content?.column_data &&
              content?.column_data.map((i) => ({
                titleAlign: 'center',
                align: 'right',
                render: (_) => (
                  <div>
                    <p>{roundFloat(_)}</p>
                  </div>
                ),
                ...i,
              }))
          );

          const niitleg_tatvar = content?.niitleg_tatvar_data.map(
            (inner_data, key) => ({
              id: key,
              ...inner_data,
            })
          );

          const hasagdah_zardal = content?.hasagdah_zardal_data.map(
            (inner_data, key) => ({
              id: key,
              ...inner_data,
            })
          );

          const sum = niitleg_tatvar[niitleg_tatvar.length - 1];
          const sum1 = hasagdah_zardal[hasagdah_zardal.length - 1];
          niitleg_tatvar.pop();
          hasagdah_zardal.pop();

          const totalSum = convertSumArray(sum);
          const totalSum1 = convertSumArray(sum1);

          _setTableData1({
            list: [...niitleg_tatvar],
            sum: totalSum,
          });
          _setTableData2({
            list: [...hasagdah_zardal],
            sum: totalSum1,
          });

          dispatch(end_loading({}));
        }
      } else {
        newAlert({ type: 'error', msg: content.Message });
        dispatch(end_loading({}));
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_00_get_hongololt_table_data_by_selected_types',
      content: {},
    });
    dispatch(start_loading({}));
  };

  const handleSaveValue = (hongololt_data) => {
    console.log(
      '🚀 ~ file: index.js:320 ~ handleSaveValue ~ hongololt_data:',
      hongololt_data
    );
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        const new_temp_data = content?.table_data.map((inner_data, key) => ({
          id: key,
          ...inner_data,
        }));

        if (new_temp_data.length > 0) {
          newAlert({
            type: 'success',
            msg: 'Дүн амжилттай хадгалагдлаа',
            functions: {
              onSuccess: [
                setTableData(new_temp_data),
                onCancel(),
                form_3.resetFields(),
              ],
            },
          });
        } else {
          onCancel();
          form_3.resetFields();
        }
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts007_00_update_table_by_zaalt_type',
      content: {
        action_type: 'type_22_5_11',
        inner_action_type: 'add_dun',
        array_data: hongololt_data,
      },
    });

    dispatch(start_loading({}));
  };

  const NewFileAdd = ({ onCancel = () => {} }) => {
    const [error, seTerror] = useState(false);
    const onFinish = async (data) => {
      const { file, ..._values } = data;
      if (file !== undefined) {
        const { size: file_size } = file[0];
        socket.on('request_out', async ({ status, content: return_data }) => {
          socket.removeAllListeners('request_out');
          if (status === '0') {
            const formData = new FormData();
            formData.append('file', data.file[0].originFileObj);
            const { content } = await axios.post(
              'attach',
              '/file_upload',
              formData,
              {
                file_size,
              }
            );
            const { json_array } = content;
            const confirmData = {
              ..._values,
              file_name: json_array?.received_file_name,
              file_id: json_array?.file_id,
              file_size,
              ognoo: moment(_values.ognoo).format('yyyy/MM/DD'),
            };
            handleSaveValue(confirmData);
          } else {
            newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
          }
          dispatch(end_loading({}));
        });

        socket.emit('request_in', {
          channel: 'ss005_company_manage',
          content: {
            action_type: 'check_company_storage',
            file_size,
          },
        });

        dispatch(start_loading({}));
      } else {
        const confirmData = {
          ..._values,
          ognoo: moment(_values.ognoo).format('yyyy/MM/DD'),
        };
        handleSaveValue(confirmData);
      }
    };

    const normFile = (e) => {
      console.log('Upload event:', e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    return (
      <>
        <Form
          form={form_3}
          name="basic"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 18 }}
          onFinish={onFinish}
          initialValues={{
            ognoo: new Date(),
            geree_ner: '',
            hongololt_dun: '',
            tailbar: '',
            file_id: '',
            file_name: '',
          }}
        >
          <Form.Item
            label="Гэрээний нэр:"
            name="geree_ner"
            rules={[{ required: true, message: 'Заавал бөглөх' }]}
          >
            <Input bordered />
          </Form.Item>
          <Form.Item
            label="Гэрээний огноо"
            name="ognoo"
            labelAlign="right"
            rules={[
              {
                required: true,
                message: 'огноо оруулна уу',
              },
            ]}
          >
            <CustomDatePicker customClass="ww-60" />
          </Form.Item>
          <Form.Item
            label="Хөнгөлөлтийн дүн:"
            name="hongololt_dun"
            labelAlign="right"
            rules={[
              {
                required: true,
                message: 'Хөнгөлөлтийн дүн оруулна уу',
              },
            ]}
            normalize={onlyNumber}
          >
            <Input bordered />
          </Form.Item>
          <Form.Item label="Тайлбар:" name="tailbar" labelAlign="right">
            <TextArea bordered rows={4} maxLength={300} showCount />
          </Form.Item>

          <CanView allowedRole="001_1">
            <Form.Item
              label="Файл хавсаргах:"
              name="file"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                accept={typesList.join()}
                maxCount={1}
                onRemove={() => {
                  seTerror(false);
                }}
                beforeUpload={(File) => {
                  if (File?.size / 1000000 < 100) {
                    const isXls = typesList.find((i) => i === File.type);
                    if (!isXls) {
                      message.error(
                        'Зөвхөн xls, word, pdf, jpeg, png  өргөтгөлтэй файл оруулна уу!'
                      );
                      seTerror(true);
                    } else {
                      seTerror(false);
                    }
                  } else {
                    message.error('Файлын хэмжээ 100MB аас хэтрэхгүй байх !');
                    seTerror(true);
                  }
                  return false;
                }}
              >
                <CloudUploadOutlined className="fs-30" />
              </Upload>
            </Form.Item>
          </CanView>

          <Row>
            <Col span={24} className="text-align-r">
              <Form.Item noStyle>
                <AntButton
                  onClick={() => {
                    form_3.resetFields();
                    onCancel();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </AntButton>
                <AntButton
                  disabled={error}
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </AntButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const handleSaveUpdate = (hongololt_data) => {
    for (const data in hongololt_data) {
      if (hongololt_data[data] === undefined) {
        hongololt_data[data] = '';
      }
    }

    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: status === '0' ? 'Дүн амжилттай шинэчлэгдлээ' : content.Message,
        functions: {
          onSuccess: [form_3.resetFields(), UpdateCancel(), mainData()],
        },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_00_update_table_by_zaalt_type',
      content: {
        action_type: 'type_22_5_11',
        inner_action_type: 'update_dun',
        array_data: hongololt_data,
      },
    });

    dispatch(start_loading({}));
  };

  const UpdateData = () => {
    const onFinish_1 = async (data) => {
      const { file, ..._values } = data;
      if (file !== undefined) {
        const { size: file_size } = file[0];
        const formData = new FormData();
        formData.append('file', data.file[0].originFileObj);
        const { content } = await axios.post(
          'attach',
          '/file_upload',
          formData,
          {
            file_size,
          }
        );

        const { json_array } = content;
        const confirmData = {
          ..._values,
          file_name: json_array?.received_file_name,
          file_id: json_array?.file_id,
          ognoo: moment(_values.ognoo).format('yyyy/MM/DD'),
          hongololt_id: currentData.hongololt_id,
        };
        handleSaveUpdate(confirmData);
      } else {
        const confirmData = {
          ..._values,
          file_name: currentData.file_name,
          file_id: currentData.file_id,
          ognoo: moment(_values.ognoo).format('yyyy/MM/DD'),
          hongololt_id: currentData.hongololt_id,
        };
        handleSaveUpdate(confirmData);
      }
    };

    return (
      <>
        <Form
          form={form_3}
          name="basic"
          style={{
            fontSize: 13,
          }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={onFinish_1}
        >
          <Form.Item
            label="Гэрээний нэр:"
            name="geree_ner"
            rules={[{ required: true, message: 'Заавал бөглөх' }]}
          >
            <Input
              bordered
              // defaultValue={currentValue.geree_ner}
            />
          </Form.Item>
          <Form.Item
            label="Гэрээний огноо"
            name="ognoo"
            labelAlign="right"
            rules={[
              {
                required: true,
                message: 'огноо оруулна уу',
              },
            ]}
          >
            <CustomDatePicker customClass="ww-60" />
          </Form.Item>
          <Form.Item
            label="Хөнгөлтийн дүн:"
            name="hongololt_dun"
            labelAlign="right"
            rules={[
              {
                required: true,
                message: 'Хөнгөлөлтийн дүн оруулна уу',
              },
            ]}
            normalize={onlyNumber}
          >
            <Input bordered />
          </Form.Item>
          <Form.Item label="Тайлбар:" name="tailbar" labelAlign="right">
            <TextArea bordered rows={4} maxLength={300} showCount />
          </Form.Item>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <AntButton
                  onClick={() => {
                    form_3.resetFields();
                    UpdateCancel();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </AntButton>
                <AntButton
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </AntButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const HorongoData = [
    {
      title: 'Хамрагдах хугацаа:',
      value: `${selectedMonth}-р сар хүртэлх`,
    },
    {
      title: 'Оруулсан хөрөнгө оруулалт:',
      value: onlyNumber(horongoOruulalt?.oruulsan_horongo_oruulalt),
      type: 'editable',
      action_type: 'oruulsan_horongo_oruulalt',
    },
    {
      title: 'Зарцуулсан зардал:',
      value: onlyNumber(horongoOruulalt?.zartsuulsan_zardal),
      type: 'editable',
      action_type: 'zartsuulsan_zardal',
    },
    {
      title: 'Үлдэгдэл:',
      value: onlyNumber(horongoOruulalt?.uldegdel),
    },
  ];

  const activeBackground = (e) => {
    e.target.style.border = '1px solid lightblue';
  };

  const deactiveBackground = (e) => {
    e.target.style.border = 'none';
  };

  function send_confirm_row_id(file_id, dugnelt_file_name) {
    axios.get(
      'attach',
      '/get_file',
      {},
      {
        file_size: 999,
        file_id,
      },
      (event) => {
        FileDownload(event, dugnelt_file_name);
      }
    );
  }

  useEffect(() => {
    mainData();
  }, []);

  function send_update_data(
    ognoo,
    hongololt_id,
    file_id,
    file_ner,
    geree_ner,
    tailbar,
    hongololt_dun
  ) {
    SetUpdate(true);
    const form_data = {
      ognoo,
      hongololt_id,
      file_id,
      file_ner,
      geree_ner,
      tailbar,
      hongololt_dun: roundFloat(hongololt_dun),
    };
    form_3.setFieldsValue(form_data);
  }

  const delete_row = (id) => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');
      // console.log(data_temp.content)
      newAlert({
        type: data_temp.status === '0' ? 'success' : 'error',
        msg:
          data_temp.status === '0'
            ? 'Дүн амжилттай устлаа'
            : 'Системд алдаа гарлаа',
        functions: { onSuccess: mainData(), onFail: mainData() },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_00_update_table_by_zaalt_type',
      content: {
        action_type: 'type_22_5_11',
        inner_action_type: 'delete_data',
        hongololt_id: id,
      },
    });

    dispatch(start_loading({}));
  };

  const SetAjiltan = (type, value) => {
    const _currentValue = { ...ajiltanData };
    _currentValue[type] = value;
    setAjiltanData(_currentValue);
  };

  function handleSaveHorongoOruulalt() {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
      });
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_00_update_table_by_zaalt_type',
      content: {
        current_month: selectedMonth,
        action_type: 'horongo_oruulalt',
        array_data: horongoOruulalt,
      },
    });

    dispatch(start_loading({}));
  }

  function handleSaveValue_22_5_2() {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: mainData() },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_00_update_table_by_zaalt_type',
      content: {
        current_month: selectedMonth,
        action_type: 'type_22_5_2',
        niit_ajiltan_too: ajiltanData.niitAjiltanToo,
        hogjil_berhsheeltei_ajiltan_too: ajiltanData.tootsohAjiltanToo,
      },
    });

    dispatch(start_loading({}));
  }

  function handleSaveValue_22_5_6() {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: mainData(), onFail: mainData() },
      });
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_00_update_table_by_zaalt_type',
      content: {
        current_month: selectedMonth,
        action_type: 'type_22_5_6',
        niit_ajiltan_too: ajiltanData.niitAjiltanToo,
        aldalttai_ajiltan_too: ajiltanData.tootsohAjiltanToo,
      },
    });
    dispatch(start_loading({}));
  }

  const isEditing = (record) => {
    if (record?.dans_key === editingKey) {
      return 'true';
    }
    return 'false';
  };

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record?.dans_key);
  };

  useEffect(() => {
    if (!loading) {
      mainData();
    }
  }, [loading]);

  const submit = async (record) => {
    const row = await form.validateFields();
    const array_data = {
      dans_key: record.dans_key,
      row,
    };
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
      });

      setTimeout(() => {
        seTloading(false);
      }, 1000);
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_00_update_table_by_zaalt_type',
      content: {
        action_type: 'others_list',
        inner_action_type: record.type,
        array_data,
      },
    });
    dispatch(start_loading({}));
  };

  const cancel = () => {
    setEditingKey('');
  };

  let columnCountClass = '';

  useEffect(() => {
    const new_array = [
      {
        title: '№',
        dataIndex: 'row',
        editable: 'false',
        width: 30,
        align: 'center',
        titleAlign: 'center',
        fixed: true,
        render: (text, row) => (
          <p style={{ textAlign: 'center' }}> {row.id + 1} </p>
        ),
      },
      {
        title: 'Дансны нэр',
        dataIndex: 'dans_ner',
        width: '20vw',
        editable: 'false',
        ellipsis: true,
        fixed: true,
        render: (_, row) => <Text ellipsis>{row.dans_ner}</Text>,
      },
      {
        title: 'Дансны дүн',
        dataIndex: 'dans_dun',
        titleAlign: 'center',
        align: 'right',
        width: '7vw',
        editable: 'false',
        render: (_, row) => (
          <div>
            <p>{roundFloat(row?.dans_dun)}</p>
          </div>
        ),
      },
      {
        title: 'Нийт дүн',
        dataIndex: 'niit_dun',
        align: 'right',
        width: '7vw',
        editable: 'false',
        titleAlign: 'center',
        render: (_, row) => (
          <div>
            <p>{roundFloat(row?.niit_dun)}</p>
          </div>
        ),
      },
      {
        title: 'Засах',
        dataIndex: 'edit',
        // editable: 'false',
        width: 50,
        align: 'center',
        titleAlign: 'center',
        render: (_, record) => {
          if (record.dans_key === editingKey) {
            return (
              <>
                <CanView allowedRole="002_1">
                  <AntButton
                    size="small"
                    type="text"
                    onClick={async () => {
                      seTloading(true);
                      setEditingKey('');
                      await submit(record);
                    }}
                  >
                    Хадгалах
                  </AntButton>
                  <AntButton size="small" type="text" danger onClick={cancel}>
                    Буцах
                  </AntButton>
                </CanView>
              </>
            );
          }
          return (
            <CanView allowedRole="002_1">
              <Tooltip title="Засах">
                <EditOutlined
                  size="small"
                  type="text"
                  disabled={editingKey !== ''}
                  danger
                  onClick={() => edit(record)}
                >
                  Засах
                </EditOutlined>
              </Tooltip>
            </CanView>
          );
        },
      },
    ];
    new_array.splice.apply(new_array, [3, 0].concat(columnData));
    setColumns(new_array);
  }, [columnData, editingKey]);

  const generateArr = (arr) =>
    arr.map((col) => {
      if (col.editable === 'false' || !col?.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });

  const generateArr_1 = (arr) =>
    arr.map((col) => {
      if (col.editable === 'false' || !col?.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin indicator={antIcon} />;
      </div>
    );
  }

  const title = () => (
    <>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          seTvisible(true);
        }}
      >
        Нэмэх
      </AntButton>
    </>
  );
  const checkArr = ['7', '8', '9'];
  const cardOpener = () => {
    if (!actionType) return false;
    if (isArray(actionType)) {
      return actionType.filter((i) => checkArr.includes(i)).length > 0;
    }
    return checkArr.includes(actionType);
  };

  const locale = {
    emptyText: (
      <div style={{ textAlign: 'center' }}>
        <Empty description="Дата байхгүй" />
      </div>
    ),
  };

  return (
    <>
      <Modal
        title="Тогтвортой байдлын гэрээний дагуу хөнгөлөлт"
        visible={visible}
        width={550}
        footer={null}
        onCancel={onCancel}
        wrapClassName="custom-modal"
      >
        <NewFileAdd onCancel={onCancel} />
      </Modal>
      <Modal
        title="Тогтвортой байдлын гэрээний дагуу хөнгөлөлт"
        visible={update}
        width={550}
        footer={null}
        onCancel={UpdateCancel}
        wrapClassName="custom-modal"
      >
        <UpdateData onCancel={onCancel} />
      </Modal>
      {(() => {
        if (actionType === 'type_22_5_2') {
          return (
            <>
              <Card
                title="22.5.2 Хөгжлийн бэрхшээлтэй ажилтан 2/3:"
                className="custom-card"
              >
                <List
                  size="small"
                  dataSource={hogjiliinBerhsheelData}
                  className="custom-list"
                  renderItem={(item) => (
                    <List.Item>
                      <div>
                        <span>{item.title}</span>
                      </div>
                      <div>
                        {item?.type === 'editable' ? (
                          <CanView allowedRole="002_1">
                            <Input
                              style={{
                                textAlign: 'end',
                                height: 30,
                              }}
                              onMouseEnter={(e) => activeBackground(e)}
                              onMouseLeave={(e) => deactiveBackground(e)}
                              bordered={false}
                              defaultValue={item.value}
                              onChange={({ target }) => {
                                const { value } = target;
                                SetAjiltan(item.action_type, value);
                              }}
                            />
                          </CanView>
                        ) : (
                          <span>{item.value}</span>
                        )}
                      </div>
                    </List.Item>
                  )}
                />

                <CanView allowedRole="002_1">
                  <AntButton
                    variant="contained"
                    color="primary"
                    className="add-btn"
                    shape="round"
                    size="small"
                    onClick={handleSaveValue_22_5_2}
                  >
                    Хадгалах
                  </AntButton>
                </CanView>
              </Card>
            </>
          );
        }
        if (actionType === 'type_22_5_6') {
          return (
            <>
              <Card
                title=" 22.5.6 Хөдөлмөрийн чадвар алдалт 50% дээш ажилтаны эзлэх
                хувь:"
                className="custom-card"
              >
                <List
                  size="small"
                  dataSource={hodolmoriinChadvarAldalttai}
                  className="custom-list"
                  renderItem={(item) => (
                    <List.Item style={{ marginTop: '5px' }}>
                      <div>
                        <span
                          style={{
                            textAlign: 'left',
                            display: 'inline-block',
                          }}
                        >
                          {item.title}
                        </span>
                      </div>
                      <div>
                        {item?.type === 'editable' ? (
                          <CanView allowedRole="002_1">
                            <Input
                              style={{ textAlign: 'right' }}
                              onMouseEnter={(e) => activeBackground(e)}
                              onMouseLeave={(e) => deactiveBackground(e)}
                              bordered={false}
                              defaultValue={item.value}
                              onChange={({ target }) => {
                                const { value } = target;
                                SetAjiltan(item.action_type, value);
                              }}
                            />
                          </CanView>
                        ) : (
                          <span>{item.value}</span>
                        )}
                      </div>
                    </List.Item>
                  )}
                />

                <CanView allowedRole="002_1">
                  <AntButton
                    className="add-btn"
                    variant="contained"
                    color="primary"
                    size="small"
                    shape="round"
                    onClick={handleSaveValue_22_5_6}
                  >
                    Хадгалах
                  </AntButton>
                </CanView>
              </Card>
            </>
          );
        }
        if (actionType === 'type_22_5_11') {
          return (
            <>
              <div className="table-antd-div">
                <TableCommon
                  columns={column_22_5_11({
                    send_confirm_row_id,
                    delete_row,
                    send_update_data,
                  })}
                  dataSource={tableData}
                  scroll={{
                    x: 'auto',
                  }}
                  title={title}
                />
              </div>
            </>
          );
        }
        if (actionType === 'type_22_1') {
          return (
            <div
              style={{
                display: 'flex',
                height: '100%',
              }}
            >
              <center
                style={{
                  margin: 'auto',
                  width: '20vw',
                  alignItems: 'center',
                }}
              >
                Тухайн цонхонд тохирох хөнгөлөлт байхгүй байна !
              </center>
            </div>
          );
        }

        const customSum = (data) => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell />
              {data &&
                data?.length > 0 &&
                data.map((item) => (
                  <Table.Summary.Cell
                    align={`${item === 'Нийт' ? 'left' : 'right'}`}
                  >
                    {item}
                  </Table.Summary.Cell>
                ))}
            </Table.Summary.Row>
          </Table.Summary>
        );

        return (
          <>
            {(() => {
              if (cardOpener()) {
                return (
                  <Card title="Хөрөнгө оруулалт" className="custom-card">
                    {!isEmpty(HorongoData) && (
                      <List
                        size="small"
                        dataSource={HorongoData}
                        className="custom-list"
                        renderItem={(item) => (
                          <List.Item>
                            <div>
                              <span>{item.title}</span>
                            </div>
                            <div>
                              {item?.type === 'editable' ? (
                                <Input
                                  style={{
                                    textAlign: 'end',
                                    height: '5vh',
                                  }}
                                  bordered={false}
                                  value={item?.value}
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    SetValue(item.action_type, value);
                                  }}
                                  onMouseEnter={(e) => activeBackground(e)}
                                  onMouseLeave={(e) => deactiveBackground(e)}
                                />
                              ) : (
                                <span>{item.value}</span>
                              )}
                            </div>
                          </List.Item>
                        )}
                      />
                    )}
                    <CanView allowedRole="002_1">
                      <AntButton
                        className="confirm-btn ml-5"
                        type="primary"
                        shape="round"
                        onClick={handleSaveHorongoOruulalt}
                      >
                        Хадгалах
                      </AntButton>
                    </CanView>
                  </Card>
                );
              }
            })()}

            {(() => {
              if (columnData.length === 5) {
                columnCountClass = 'max-wid-80';
              } else if (columnData.length === 4) {
                columnCountClass = 'max-wid-70';
              } else if (columnData.length === 3) {
                columnCountClass = 'max-wid-60';
              } else {
                columnCountClass = '';
              }
            })()}
            <div className="hungulult-dun-holboh">
              <div className="container_1">
                <Form form={form}>
                  <Table
                    className="custom-antd-table-one-no-title"
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    title={() => 'Нийтлэг хувь хэмжээтэй орлого'}
                    scroll={{
                      y: 450,
                      x: 'max-width',
                    }}
                    onHeaderRow={(column) =>
                      column.map((i) => {
                        set(i, 'align', i?.titleAlign || 'center');
                        return i;
                      })
                    }
                    bordered={false}
                    locale={locale}
                    dataSource={_tableData1?.list}
                    columns={generateArr(columns)}
                    summary={() => customSum(_tableData1.sum)}
                    rowClassName="editable-row"
                    pagination={false}
                    size="small"
                  />
                </Form>
              </div>
              <div className="container">
                <Form form={form}>
                  <Table
                    className="custom-antd-table-one-no-title"
                    locale={locale}
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    title={() => 'Хасагдах зардал'}
                    scroll={{
                      y: 450,
                      x: 'max-width',
                    }}
                    onHeaderRow={(column) =>
                      column.map((i) => {
                        set(i, 'align', i?.titleAlign || 'center');
                        return i;
                      })
                    }
                    bordered={false}
                    dataSource={_tableData2?.list}
                    columns={generateArr_1(columns)}
                    summary={() => customSum(_tableData2.sum)}
                    rowClassName="editable-row"
                    pagination={false}
                    size="small"
                  />
                </Form>
              </div>
            </div>
          </>
        );
      })()}
    </>
  );
};

export default Client;
