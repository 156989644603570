import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { useSelector, useDispatch } from 'react-redux';
import newAlert from 'src/components/newAlert';

const Sm_14 = ({ description }) => {
  const [text, setText] = useState(description);

  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const get_data = (month) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        console.log('===============================');
        console.log(content.text);
        console.log('===============================');
        setText(content?.text);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'get_sa14',
        month,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date?.month) get_data(data_date?.month);
  }, [data_date]);

  const handleSend = async () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
      });
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'save_sa14',
        month: data_date?.month,
        text,
      },
    });
    dispatch(start_loading({}));
  };

  return (
    <div className="taxAccountTableContainer">
      <div className="taxAccountTitle">
        14. Хөрөнгө оруулалтын зориулалттай үл хөдлөх хөрөнгө
      </div>
      <table className="taxAccountTable">
        <thead>
          <tr>
            {/* <th /> */}
            <th>Тайлбар</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* <td>Гараар бөглөх</td> */}
            <td>
              <textarea
                value={text}
                onChange={handleChange}
                rows={10}
                style={{ width: '100%', borderRadius: '4px' }}
                placeholder="Бөглөнө үү"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Button type="primary" onClick={handleSend} className="taxAccountButton">
        Хадгалах
      </Button>
    </div>
  );
};

export default Sm_14;
