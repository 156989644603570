import React, { useState, useEffect } from 'react';
import { Pagination as AntPagination } from 'antd';

const Pagination = ({ total = 500, getParam = () => {} }) => {
  const [size, seTsize] = useState(20);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getParam({
      size,
      page,
    });
  }, [size, page]);

  const onChange = (e) => {
    setPage(e);
  };

  const onShowSizeChange = (_, pageSize) => {
    seTsize(pageSize);
  };

  return (
    <AntPagination
      size="small"
      current={page}
      onChange={onChange}
      total={total}
      showSizeChanger
      locale={{ items_per_page: 'хуудас' }}
      pageSize={size}
      onShowSizeChange={onShowSizeChange}
    />
  );
};
export default Pagination;
