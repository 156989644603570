import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { Table as AntTable, Button as AntButton } from 'antd';

export const summary = [
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Хойшлогдсон татварын өр төлбөрийн нийт дүн',
  },

  {
    align: 'right',
    value: 'tur_zuruu_eh',
  },
  {
    align: 'right',
    value: 'tur_zuruu_deb',
  },
  {
    align: 'right',
    value: 'tur_zuruu_cred',
  },
  {
    align: 'right',
    value: 'tur_zuruu_ets',
  },
];

export const customDansSummary = ({ data = {} }) => (
  <AntTable.Summary fixed>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right">
        <p>Хүлээн зөвшөөрсөн татвар ногдох</p>
        <p>түр зөрүүгийн дүн:</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.eh_turuu_zur_tatvar_nogdoh)}
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.nogdoh_deb)}
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.nogdoh_gred)}
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.ets_turuu_zur_tatvar_nogdoh)}
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right">
        Татварын дундаж хувь хэмжээ:
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.dundaj_huvi)}%
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.dundaj_huvi)}%
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.dundaj_huvi)}%
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.dundaj_huvi)}%
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right">
        Хойшлогдсон татварын өр төлбөрийн дүн:
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(
          (data?.eh_turuu_zur_tatvar_nogdoh * data?.dundaj_huvi) / 100
        )}
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat((data?.nogdoh_deb * data?.dundaj_huvi) / 100)}
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat((data?.nogdoh_gred * data?.dundaj_huvi) / 100)}
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(
          (data?.ets_turuu_zur_tatvar_nogdoh * data?.dundaj_huvi) / 100
        )}
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
  </AntTable.Summary>
);

export const customSummary = ({ data = {} }) => (
  <AntTable.Summary fixed>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right" colSpan={3}>
        Хүлээн зөвшөөрсөн татвар ногдох түр зөрүүгийн дүн:
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.eh_turuu_zur_tatvar_nogdoh)}
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.nogdoh_deb)}
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.nogdoh_gred)}
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.ets_turuu_zur_tatvar_nogdoh)}
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right" colSpan={3}>
        Татварын дундаж хувь хэмжээ:
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.dundaj_huvi)}%
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.dundaj_huvi)}%
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.dundaj_huvi)}%
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(data?.dundaj_huvi)}%
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right" colSpan={3}>
        Хойшлогдсон татварын өр төлбөрийн дүн:
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(
          (data?.eh_turuu_zur_tatvar_nogdoh * data?.dundaj_huvi) / 100
        )}
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat((data?.nogdoh_deb * data?.dundaj_huvi) / 100)}
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat((data?.nogdoh_gred * data?.dundaj_huvi) / 100)}
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        {roundFloat(
          (data?.ets_turuu_zur_tatvar_nogdoh * data?.dundaj_huvi) / 100
        )}
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
  </AntTable.Summary>
);

export const ColumnsDansMain = () => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Татвар ногдох түр зөрүү эхний үлдэгдэл',
    dataIndex: 'eh_turuu_zur_tatvar_nogdoh',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Дебет',
    dataIndex: 'tur_zuruu_deb',
    align: 'right',
    width: 150,
    render: (_, row) => roundFloat(row.nogdoh_deb),
  },
  {
    title: 'Кредит',
    dataIndex: 'tur_zuruu_cred',
    align: 'right',
    width: 150,
    render: (_, row) => roundFloat(row.nogdoh_gred),
  },
  {
    title: 'Татвар ногдох түр зөрүү эцсийн үлдэгдэл',
    dataIndex: 'ets_turuu_zur_tatvar_nogdoh',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
];

export const ColumnsMain = ({ onSelectHurungu = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    align: 'left',
    width: 120,
    fixed: 'left',
    render: (_, row) => (
      <AntButton
        align="center"
        type="link"
        size="small"
        className="underline"
        onClick={() => onSelectHurungu(row)}
      >
        {row?.code}
      </AntButton>
    ),
  },
  {
    title: 'Хөрөнгийн нэр',
    dataIndex: 'ner',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Татвар ногдох түр зөрүү эхний үлдэгдэл',
    dataIndex: 'eh_turuu_zur_tatvar_nogdoh',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Дебет',
    dataIndex: 'tur_zuruu_deb',
    align: 'right',
    width: 150,
    render: (_, row) => roundFloat(row.nogdoh_deb),
  },
  {
    title: 'Кредит',
    dataIndex: 'tur_zuruu_cred',
    align: 'right',
    width: 150,
    render: (_, row) => roundFloat(row.nogdoh_gred),
  },
  {
    title: 'Татвар ногдох түр зөрүү эцсийн үлдэгдэл',
    dataIndex: 'ets_turuu_zur_tatvar_nogdoh',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
];

export const ColumnsBurtgel = () => [
  {
    title: 'Бүртгэлийн дугаар',
    dataIndex: 'burtgel_dugaar',
    width: 150,
    fixed: 'left',
  },
  {
    title: 'Татвар ногдох түр зөрүү эхний үлдэгдэл',
    dataIndex: 'eh_turuu_zur_tatvar_nogdoh',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Дебет',
    dataIndex: 'tur_zuruu_deb',
    align: 'right',
    width: 150,
    // eslint-disable-next-line no-confusing-arrow
    render: (_, row) =>
      row.uurchlult_turuu_zur_tatvar_nogdoh < 0
        ? roundFloat(row.uurchlult_turuu_zur_tatvar_nogdoh)
        : '0.00',
  },
  {
    title: 'Кредит',
    dataIndex: 'tur_zuruu_cred',
    align: 'right',
    width: 150,
    // eslint-disable-next-line no-confusing-arrow
    render: (_, row) =>
      row.uurchlult_turuu_zur_tatvar_nogdoh > 0
        ? roundFloat(row.uurchlult_turuu_zur_tatvar_nogdoh)
        : '0.00',
  },
  {
    title: 'Татвар ногдох түр зөрүү эцсийн үлдэгдэл',
    dataIndex: 'ets_turuu_zur_tatvar_nogdoh',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
];
