import React, { useState } from 'react';
import { Upload, message, Button } from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadOutlined } from '@ant-design/icons';

const ImgUpload = ({
  value = null,
  onChange = () => {},
  noBorder = false,
  disabled = false,
  customClass = false,
}) => {
  const [imageUrl, seTimageUrl] = useState(value);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(
        'Зөвхөн JPG/JPEG эсвэл PNG өргөтгөлтэй файл оруулах боломжтой'
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Зураг дээд тал нь 2MB ээс ихгүй байх шаардлагатай!');
    }
    return isJpgOrPng && isLt2M;
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = (info) => {
    getBase64(info.file.originFileObj, (img) => {
      seTimageUrl(img);
      onChange(img);
    });
  };

  return (
    <div className="img-upload">
      <ImgCrop
        rotate
        modalTitle="Зураг янзлах"
        modalOk="Тийм"
        modalCancel="Болих"
      >
        <Upload
          listType="text"
          onChange={handleChange}
          onPreview={onPreview}
          maxCount={1}
          showUploadList={false}
          beforeUpload={beforeUpload}
        >
          <Button
            className="upload-btn"
            shape="round"
            disabled={disabled}
            icon={<UploadOutlined />}
          >
            {imageUrl && imageUrl.length > 0 ? 'Солих' : 'Оруулах'}
          </Button>
        </Upload>
      </ImgCrop>
      {imageUrl && imageUrl.length > 0 && (
        <img width={100} height={100} src={imageUrl} />
      )}
    </div>
  );
};

export default ImgUpload;
