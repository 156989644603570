import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Steps, Button } from 'antd';

import { start_loading, end_loading } from 'src/core/actions/loader';
import HongololtCalculate from './hongololtCalculate';
import HongololtDunHolboh from './hongololtDunHolboh';
import HongololtTorolSongoh from './hongololtTorolSongoh';

const { Step } = Steps;

const ValidationInput = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const role_list = useSelector((state) => state.role);
  const permission = role_list.filter((data) => data.code === '002_1');

  // steps
  const [step, seTstep] = useState(0);
  const [disable, seTdisable] = useState(false);
  const [_disable, _seTdisable] = useState(false);
  const [radioValue, seTradioValue] = useState('first');
  const [tableData, setTableData] = useState([]);
  const [endMonth, setEndMonth] = useState('');
  const next = () => {
    seTstep(step + 1);
  };

  const prev = () => {
    seTstep(step - 1);
  };

  useEffect(() => {
    setEndMonth(data_date.month);
  }, [data_date.month]);

  const handleSaveValue = (month, step) => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');
      if (data_temp.status === '0') {
        dispatch(end_loading({}));
        seTstep(step);
      } else {
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts007_00_update_hongololt_info_table',
      content: {
        table_data: tableData,
        selected_month: month,
      },
    });

    dispatch(start_loading({}));
  };

  const send_data_22_1_type = (month, step) => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      // console.log(data_temp.content)
      if (data_temp.status === '0') {
        dispatch(end_loading({}));
        seTstep(step);
      } else {
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts007_00_update_hongololt_info_table',
      content: {
        table_data: '22_1',
        selected_month: month,
      },
    });

    dispatch(start_loading({}));
  };
  const onConfirmStepOne = () => {
    const _step = radioValue === 'first' ? 2 : 1;
    if (radioValue === 'second') {
      // save action
      if (permission.length !== 0) {
        handleSaveValue(data_date.month, _step);
      } else {
        seTstep(_step);
      }
    } else if (permission.length !== 0) {
      send_data_22_1_type(data_date.month, _step);
    } else {
      seTstep(_step);
    }
  };

  useEffect(() => {
    if (radioValue === 'second') {
      const _listenCheck = tableData.every((i) => i.state === '0');
      seTdisable(_listenCheck);
    } else {
      seTdisable(false);
    }
  }, [tableData, radioValue]);

  const steps = [
    {
      title: 'Хөнгөлөлтийн төрөл сонгох',
      content: (
        <HongololtTorolSongoh
          radioValue={radioValue}
          onConfirmStepOne={onConfirmStepOne}
          seTradioValue={(value) => {
            seTradioValue(value);
          }}
          tableData={tableData}
          setTableData={(value) => {
            setTableData(value);
          }}
          seTdisable={_seTdisable}
          endMonth={endMonth}
          setEndMonth={(val) => {
            setEndMonth(val);
          }}
        />
      ),
    },
    {
      title: 'Хөнгөлөлтөнд хамаарах дүн холбох',
      content: <HongololtDunHolboh />,
    },
    {
      title: 'Хөнгөлөлтийн тооцоолол',
      content: <HongololtCalculate />,
    },
  ];

  return (
    <div className="hungulult-container">
      {/* Steps starting  */}
      <Steps current={step}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[step].content}</div>
      <div className="steps-action">
        {step > 0 && (
          <Button className="previous-btn" shape="round" onClick={prev}>
            Өмнөх
          </Button>
        )}
        {
          step === steps.length - 1 && ''
          // <Button
          //   type="primary"
          //   className="next-btn"
          //   shape="round"
          //   onClick={confirm}
          // >
          //   Last Action
          // </Button>
        }
        {step > 0 && step < steps.length - 1 && (
          <Button
            className="next-btn"
            type="primary"
            shape="round"
            onClick={next}
          >
            Дараах
          </Button>
        )}
        {step === 0 && (
          <Button
            className="next-btn"
            type="primary"
            shape="round"
            disabled={disable || _disable}
            onClick={onConfirmStepOne}
          >
            Дараах
          </Button>
        )}
      </div>
      {/* {getActiveComponent(activeItem)} */}
    </div>
  );
};
export default ValidationInput;
