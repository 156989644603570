import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'src/core/utils/formater';

import { Button as AntButton } from 'antd';

import { Table } from 'src/components';

import { start_loading, end_loading } from 'src/core/actions/loader';
import {
  PlusCircleOutlined,
  // UploadOutlined,
} from '@ant-design/icons';
import newAlert from 'src/components/newAlert';
import { expColumns } from './dataStructure/data';
import { AddUrsgalZasvar } from './AddUrsgalZasvar';
import { AddUrsgalZasvarFile } from './AddUrsgalZasvarFile';

const UndsenHurunguTovchooTS = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const socket = useSelector((state) => state?.socket);

  const data_date = useSelector((state) => state.data_date);
  const [visibleFilersgalZasvar, seTvisibleFilersgalZasvar] = useState(false);

  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });
  const [visible, setVisible] = useState(false);

  function generateTailan() {
    dispatch(start_loading({}));
    socket.on('request_out', (data_temp) => {
      const { content } = data_temp;

      socket.removeAllListeners('request_out');

      console.log('generateTailan', data_temp);
      if (data_temp.status === '0') {
        setTableData({ ...content });
      } else {
        //
      }

      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const search = {
      hurungu: query.get('hurungu'),
      burtgel: query.get('burtgel'),
      type: 'tailan_jill',
    };
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: { action_type: 'ursgal_zasvar_page', page, size, search },
    });
  }

  useEffect(() => {
    generateTailan();
  }, [data_date, query]);
  const after_handleUpload = (content) => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');

      if (data_temp.status === '0') {
        console.log(data_temp);
        generateTailan();
        newAlert({ type: 'success', msg: data_temp.message });
      } else {
        newAlert({ type: 'error', msg: data_temp.message });
      }
      dispatch(end_loading({}));
    });

    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: {
        action_type: 'ursgal_zasvar_file',
        file_name: content?.json_array,
        type: 'tailan_jil',
      },
    });
  };
  const deleteUrsgalZasvar = (id) => {
    socket.on('request_out', (req_data) => {
      const data_temp = req_data;
      socket.removeAllListeners('request_out');

      if (data_temp.status === '0') {
        generateTailan();
        dispatch(end_loading({}));
      } else {
        dispatch(end_loading({}));
      }
    });

    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: { action_type: 'ursgal_zasvar_delete', id },
    });
    dispatch(start_loading({}));
  };
  const customTitle = () => (
    <>
      <AntButton
        id="first"
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          setVisible(true);
        }}
      >
        Урсгал засварын зардал нэмэх
      </AntButton>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          seTvisibleFilersgalZasvar(true);
        }}
      >
        Файл оруулах
      </AntButton>
    </>
  );

  useEffect(() => {
    if (!visible) {
      generateTailan();
    }
  }, [visible]);
  const onCancel = () => {
    setVisible(false);
  };
  return (
    <>
      <AddUrsgalZasvarFile
        visible={visibleFilersgalZasvar}
        onClose={() => {
          seTvisibleFilersgalZasvar(false);
        }}
        after_handleUpload={after_handleUpload}
      />
      <div className="table-antd-div">
        <Table
          columns={expColumns({
            deleteRow: deleteUrsgalZasvar,
          })}
          title={customTitle}
          total={tableData.total_size}
          dataSource={tableData.result_list}
          scroll={{
            x: 'auto',
          }}
        />
      </div>

      <AddUrsgalZasvar zasvarVisible={visible} onCancel={onCancel} />
    </>
  );
};
export default UndsenHurunguTovchooTS;
