import React from 'react';
import {
  Button as AntButton,
  Form,
  Input,
  Select as AntSelect,
  Row,
  Col,
} from 'antd';
import { onFilter } from 'src/core/utils/selectFilter';

const { Option } = AntSelect;

const RegisterClientModal = ({
  countryList,
  initialValues,
  handleSave = () => {},
  actionType,
  onClose = () => {},
}) => {
  const onFinish = (values) => {
    handleSave(values);
  };
  const [form] = Form.useForm();

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const nationalList = [
    { id: '0', name: 'Дотоод' },
    { id: '1', name: 'Гадаад' },
  ];

  const layout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 17 },
    },
  };
  if (initialValues) form.setFieldsValue(initialValues);

  return (
    <div className="hariltsagch-medeelel">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item
          name="code"
          label="Код"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: 'Харилцагчийн код оруулна уу!',
            },
          ]}
        >
          <Input
            className="w-100"
            placeholder="Харилцагчийн код оруулна уу!"
            disabled={actionType !== 'register'}
          />
        </Form.Item>

        <Form.Item
          name="name"
          label="Нэр"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: 'Харилцагчийн нэр оруулна уу!',
            },
          ]}
        >
          <Input className="w-100" placeholder="Харилцагчийн нэр оруулна уу!" />
        </Form.Item>

        <Form.Item name="ttd" label="Регистрийн дугаар" labelAlign="left">
          <Input
            className="w-100"
            showCount
            maxLength={10}
            placeholder="Регистрийн дугаар оруулна уу!"
          />
        </Form.Item>
        <Form.Item
          name="is_foreign"
          label="Улсын харьяалал"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: 'Улсын харьяалал сонгоно уу!',
            },
          ]}
        >
          <AntSelect
            className="w-100"
            onChange={() => form.setFieldsValue({ country_code: 976 })}
          >
            {nationalList.map((x) => (
              <Option value={x.id}>{x.name}</Option>
            ))}
          </AntSelect>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev, current) =>
            prev.is_foreign !== current.is_foreign
          }
        >
          {({ getFieldValue }) => {
            const _key = getFieldValue('is_foreign');

            return (
              <Form.Item
                name="country_code"
                label="Улсын код"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: 'Улсын код сонгоно уу!',
                  },
                ]}
              >
                <AntSelect
                  className="w-100"
                  disabled={_key === '0'}
                  showSearch
                  onFilter={onFilter}
                >
                  {countryList.map((x) => (
                    <Option value={x.code}>
                      ({x.code}) {x.name}
                    </Option>
                  ))}
                </AntSelect>
              </Form.Item>
            );
          }}
        </Form.Item>

        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item noStyle>
              <AntButton
                onClick={onCancel}
                className="cancel-btn mr-5"
                type="primary"
                shape="round"
              >
                Цуцлах
              </AntButton>
              <AntButton
                className="confirm-btn"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </AntButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default RegisterClientModal;
