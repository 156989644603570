export const tourList = [
  {
    id: '1',
    path: '/main/ugugdulOruulah/jagsaalt',
    youtube: [
      {
        title: 'Жагсаалт оруулах',
        url: 'https://www.youtube.com/embed/WizWMA48LPI',
      },
      {
        title: 'Уншуулах файл жагсаалт Diamond',
        url: 'https://www.youtube.com/embed/KIf_bfrPt68',
      },
      {
        title: 'Уншуулах файл жагсаалт Impact',
        url: 'https://www.youtube.com/embed/eq0D_Zyr1kI',
      },
      {
        title: 'Уншуулах файл жагсаалт Smart',
        url: 'https://www.youtube.com/embed/76lioYUNjyY',
      },
      {
        title: 'Уншуулах файл жагсаалт Tax',
        url: 'https://www.youtube.com/embed/j_MI-mnzRtU',
      },
    ],
  },
  {
    id: '2',
    path: '/main/ugugdulOruulah/jagsaalt/ogogdolBatalgaajuulah',
    youtube: 'https://www.youtube.com/embed/j_MI-mnzRtU',
  },
  {
    id: '3',
    path: '/main/ugugdulOruulah/hyazgaar',
    youtube: 'https://www.youtube.com/embed/E-KDk7VL29Q',
  },
  {
    id: '4',
    path: '/main/ugugdulOruulah/hyazgaar/tomilolt',
    youtube: 'https://www.youtube.com/embed/u_J4jYsBBzs',
  },
  {
    id: '5',
    path: '/main/ugugdulOruulah/hyazgaar/gamshig',
    youtube: 'https://www.youtube.com/embed/S8MVNsel18U',
  },
  {
    id: '6',
    path: '/main/ugugdulOruulah/hyazgaar/tsalin',
    youtube: 'https://www.youtube.com/embed/h0PR9Rs9awY',
  },
  {
    id: '7',
    path: '/main/ugugdulOruulah/hyazgaar/huu',
    youtube: 'https://www.youtube.com/embed/smN27b27uko',
  },
  {
    id: '8',
    path: '/main/ugugdulOruulah/hyazgaar/ursgalZasvar',
    youtube: 'https://www.youtube.com/embed/IPr1X8sxrms',
  },
  {
    id: '9',
    path: '/main/dansniiUne/guilgeeBalance',
    youtube: 'https://www.youtube.com/embed/FBNufvDnq3k',
  },
  {
    id: '10',
    path: '/main/dansniiUne/avlagaUglugTovchoo',
    youtube: 'https://www.youtube.com/embed/i_ycLPlzc9k',
  },
  {
    id: '11',
    path: '/main/dansniiUne/undsenHurunguTovchoo',
    youtube: 'https://www.youtube.com/embed/Ydz-wxvv2PY',
  },
  {
    id: '12',
    path: '/main/tatvarSuuri/guilgeeBalance',
    youtube: 'https://www.youtube.com/embed/lP2Nc1IJ15I',
  },
  {
    id: '13',
    path: '/main/tatvarSuuri/guilgeeBalance/mungunHurungu',
    youtube: 'https://www.youtube.com/embed/fPOUbTBLR3s',
  },
  {
    id: '14',
    path: '/main/tatvarSuuri/avlagaUglugTovchoo',
    youtube: 'https://www.youtube.com/embed/fPOUbTBLR3s',
  },
  {
    id: '15',
    path: '/main/tatvarSuuri/undsenHurungu',
    youtube: 'https://www.youtube.com/embed/fPOUbTBLR3s',
  },
  {
    id: '16',
    path: '/main/tatvarTohirgoo/dansniiUneZuruu',
    youtube: 'https://www.youtube.com/embed/1TiUVsqOTig',
  },
  {
    id: '17',
    path: '/main/tatvarTohirgoo/dansniiUneZuruu/avlagaUglug',
    youtube: 'https://www.youtube.com/embed/ki-KmBi6Gvs',
  },
  {
    id: '18',
    path: '/main/tatvarTohirgoo/dansniiUneZuruu/undsenHurungu',
    youtube: 'https://www.youtube.com/embed/3vzWYqYjlKA',
  },
  {
    id: '19',
    path: '/main/tatvarTohirgoo/shalgahBalance',
    youtube: 'https://www.youtube.com/embed/dDsqVWACw5Q',
  },
  {
    id: '20',
    path: '/main/tatvarTohirgoo/shalgahBalance/tohirgoo',
    youtube: 'https://www.youtube.com/embed/rRq6ygiI6No',
  },
  {
    id: '21',
    path: '/main/tatvarTohirgoo/shalgahBalance/hanshTohirgoo',
    youtube: 'https://www.youtube.com/embed/dAgiVADwEBo',
  },
  {
    id: '22',
    path: '/main/tatvarTohirgoo/hyazgaar',
    youtube: 'https://www.youtube.com/embed/ov7k4-5yCk4',
  },
  {
    id: '23',
    path: '/main/tatvarTohirgoo/hyazgaar/tomilolt',
    youtube: 'https://www.youtube.com/embed/sRJyAxaAdRs',
  },
  {
    id: '24',
    path: '/main/tatvarTohirgoo/hyazgaar/huu',
    youtube: 'https://www.youtube.com/embed/tNa8Qs8hW6U',
  },
  {
    id: '25',
    path: '/main/tatvarTohirgoo/hyazgaar/zasvar',
    youtube: 'https://www.youtube.com/embed/y0grGZYHGNY',
  },
  {
    id: '26',
    path: '/main/tatvarTohirgoo/hungulult',
    youtube: 'https://www.youtube.com/embed/_EBNKN2bJJo',
  },
  {
    id: '27',
    path: '/main/hoishlogdsonTatvar/turZuruu',
    youtube: 'https://www.youtube.com/embed/Jjs987RSRI8',
  },
  {
    id: '28',
    path: '/main/hoishlogdsonTatvar/turZuruu/avlagaUglug',
    youtube: 'https://www.youtube.com/embed/CzZXyDbaR6o',
  },
  {
    id: '29',
    path: '/main/hoishlogdsonTatvar/turZuruu/undsenHurungu',
    youtube: 'https://www.youtube.com/embed/z4U7LshsciI',
  },
  {
    id: '30',
    path: '/main/hoishlogdsonTatvar/taxAvgPer',
    youtube: 'https://www.youtube.com/embed/AmYhaFeWDIo',
  },
  {
    id: '31',
    path: '/main/hoishlogdsonTatvar/aldagdal',
    youtube: 'https://www.youtube.com/embed/PJ-A-eUjJWQ',
  },
  {
    id: '32',
    path: '/main/hoishlogdsonTatvar/hurungu',
    youtube: 'https://www.youtube.com/embed/Ggs3dw-sLQk',
  },
  {
    id: '33',
    path: '/main/hoishlogdsonTatvar/hurungu/avlagaUglug',
    youtube: 'https://www.youtube.com/embed/EA66KExJ7kk',
  },
  {
    id: '34',
    path: '/main/hoishlogdsonTatvar/hurungu/undsenHurungu',
    youtube: 'https://www.youtube.com/embed/63NT9rO3iSc',
  },
  {
    id: '35',
    path: '/main/hoishlogdsonTatvar/urTulbur',
    youtube: 'https://www.youtube.com/embed/Ggs3dw-sLQk',
  },
  {
    id: '36',
    path: '/main/hoishlogdsonTatvar/urTulbur/avlagaUglug',
    youtube: 'https://www.youtube.com/embed/qHQwo5z6aPE',
  },
  {
    id: '37',
    path: '/main/hoishlogdsonTatvar/urTulbur/undsenHurungu',
    youtube: 'https://www.youtube.com/embed/hL6f1HWcrNg',
  },
  {
    id: '38',
    path: '/main/zuruuTailan/ajliinHusnegt',
    youtube: 'https://www.youtube.com/embed/Hk5IN3Aq_ys',
  },
  {
    id: '39',
    path: '/main/zuruuTailan/zohitsuulahTailan',
    youtube: 'https://www.youtube.com/embed/r0NRCApNOHg',
  },
  {
    id: '40',
    path: '/main/tailan/tatvarTailan',
    youtube: [
      { url: 'https://www.youtube.com/embed/pZmNSbn_XN8', title: 'ААНОАТ' },
      { url: 'https://www.youtube.com/embed/jSUNGsCDhjw', title: 'ААНОАТ ХМ6' },
      {
        url: 'https://www.youtube.com/embed/AaTfN_z1HkE',
        title: 'Дараагийн жил үүсгэх',
      },
    ],
  },
  {
    id: '41',
    path: '/main/tailan/tatvarTailan/hm02_7',
    youtube: 'https://www.youtube.com/embed/mTAEX86JotE',
  },
];

export const newTourList = [
  {
    path: '/main',
    youtube: 'https://www.youtube.com/embed/e61iNY4XiO8',
  },
  {
    path: '/main/tohirgoo/tatvarJil',
    youtube: [
      {
        title: 'Эхний үлдэгдлийн жил үүсгэх',
        url: 'https://www.youtube.com/embed/ZZYRCizfQY8',
      },
      {
        title: 'Дараагийн жил үүсгэх',
        url: 'https://www.youtube.com/embed/AaTfN_z1HkE',
      },
    ],
  },
  {
    path: '/main/tohirgoo/roleAdd',
    youtube: 'https://www.youtube.com/embed/CIeGg8HvgiM',
  },
  {
    path: '/main/tohirgoo/userAdd',
    youtube: 'https://www.youtube.com/embed/CIeGg8HvgiM',
  },
  {
    path: '/main/tatvarTulugch/medeelel',
    youtube: 'https://www.youtube.com/embed/aqPnt280QJU',
  },
  {
    path: '/main/undsenMedeelel/dans',
    youtube: [
      {
        title: 'Гүйлгээ баланс оруулах',
        url: 'https://www.youtube.com/embed/YLu1MqZCk0U',
      },
      {
        title: 'Түр зөрүү данс ангилал',
        url: 'https://www.youtube.com/embed/uDUVrm0y77I',
      },
      {
        title: 'Уншуулах файл С1 - Tax',
        url: 'https://www.youtube.com/embed/Kn6_TNGa-QU',
      },
      {
        title: 'Уншуулах файл С1 - Diamond',
        url: 'https://www.youtube.com/embed/mACz7UtgIIk',
      },
      {
        title: 'Дансны мэдээлэл шинэчлэх',
        url: 'https://www.youtube.com/embed/uhI6XriPT-Y',
      },
      {
        title: 'Дансны мэдээлэл шинэчлэх - бусад',
        url: 'https://www.youtube.com/embed/dOwmr5dXj5U',
      },
    ],
  },
  {
    path: '/main/undsenMedeelel/dans/TempDiffInfo',
    youtube: 'https://www.youtube.com/embed/uDUVrm0y77I',
  },
  {
    path: '/main/undsenMedeelel/dans/AccountLinkDep',
    youtube: 'https://www.youtube.com/embed/-oA2MXiS36g',
  },
  {
    path: '/main/undsenMedeelel/dans/Zagvar',
    youtube: 'https://www.youtube.com/embed/VN9fp_N9Q6c',
  },
  {
    path: '/main/undsenMedeelel/dans/DedAngilal',
    youtube: 'https://www.youtube.com/embed/8is-WSTXq-g',
  },
  {
    path: '/main/undsenMedeelel/hariltsagch',
    youtube: 'https://www.youtube.com/embed/XqP2w5TwOWo',
  },
  {
    path: '/main/undsenMedeelel/hurungu',
    youtube: [
      {
        title: 'Үндсэн хөрөнгө товчоо уншуулах',
        url: 'https://www.youtube.com/embed/k5WFIyh5ycw',
      },
      {
        title: 'Үндсэн хөрөнгийн мэдээлэл',
        url: 'https://www.youtube.com/embed/lLBkrfWqiOY',
      },
    ],
  },
  {
    path: '/main/ehniiUldegdel/dansUne',
    youtube: 'https://www.youtube.com/embed/k5WFIyh5ycw',
  },
  {
    path: '/main/ehniiUldegdel/dansUne/asset',
    youtube: [
      {
        title: 'C1 ҮХ өртгийн өөрчлөлт оруулах 1',
        url: 'https://www.youtube.com/embed/0KRehoOGkrE',
      },
      {
        title: 'C1 ҮХ өртгийн өөрчлөлт оруулах 2',
        url: 'https://www.youtube.com/embed/caquy94LtG0',
      },
      {
        title: 'C1 ҮХ өртгийн өөрчлөлт оруулах 3',
        url: 'https://www.youtube.com/embed/zppc4hMMGiY',
      },
    ],
  },
  {
    path: '/main/ehniiUldegdel/tatvarSuuri',
    youtube: 'https://www.youtube.com/embed/tYNsLUo664k',
  },
  {
    path: '/main/ehniiUldegdel/tatvarSuuri/avlagaUglug',
    youtube: 'https://www.youtube.com/embed/lzERl5T-gB0',
  },
  {
    path: '/main/ehniiUldegdel/tatvarSuuri/undsenHurungu',
    youtube: 'https://www.youtube.com/embed/_KgzkwiVC88',
  },
  {
    path: '/main/ehniiUldegdel/tatvarSuuri/mungunHurungu',
    youtube: 'https://www.youtube.com/embed/sp-APR-SvJY',
  },
  {
    path: '/main/ehniiUldegdel/tatvarTohirgoo',
    youtube: [
      {
        title: 'С1 ДҮ ТС зөрүү тохиргоо хийх',
        url: 'https://www.youtube.com/embed/ZpHTgB5WHS8',
      },
      {
        title: 'С1 ДҮ ТС зөрүү бусад',
        url: 'https://www.youtube.com/embed/G5DXnytvL4A',
      },
    ],
  },
  {
    path: '/main/ehniiUldegdel/turZuruu',
    youtube: 'https://www.youtube.com/embed/tc5ew5FPpNM',
  },
  {
    path: '/main/ehniiUldegdel/dundajHuvi',
    youtube: 'https://www.youtube.com/embed/DB5v-McZ6E4',
  },
  {
    path: '/main/ehniiUldegdel/hoishTatvarHurungu',
    youtube: 'https://www.youtube.com/embed/DYxuQqy6OVc',
  },
  {
    path: '/main/ehniiUldegdel/hoishTatvarUrTulbur',
    youtube: 'https://www.youtube.com/embed/DYxuQqy6OVc',
  },
  {
    path: '/main/tailan/e_tax',
    youtube: 'https://www.youtube.com/embed/kTes7xCJ0SY',
  },
];
