import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from 'src/components';
// import { PicLeftOutlined, WarningTwoTone } from '@ant-design/icons';
import { PicLeftOutlined } from '@ant-design/icons';
// import { Button, Typography, Table as AntTable } from 'antd';
import { Button, Table as AntTable } from 'antd';
import newAlert from 'src/components/newAlert';
import { roundFloat } from 'src/core/utils/formater';
import {
  guilgeeColumns,
  boditColumns,
  boditSummary,
} from './dataStructure/data';

import { start_loading, end_loading } from '../../../core/actions/loader';

// const { Text } = Typography;

const GuilgeeBalanceTailan = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const [tableData, setTableData] = useState({
    json_array: [],
    json_array_sum: {},
    json_data_bodit: {},
    has_warning: false,
  });

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tatvar_journal_burtgel',
      content: {
        action_type: 'get_hansh_tohirgoo_dun',
        month,
      },
    });
    dispatch(start_loading({}));
  };

  const saveTohirgoo = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({
          type: 'success',
          msg: 'Ханшийн тохиргоо амжилттай хийгдлээ',
        });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tatvar_journal_burtgel',
      content: {
        data: tableData,
        action_type: 'set_hansh_tohirgoo',
        month: data_date.month,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_date]);

  const title = () => (
    <>
      {/* {tableData?.has_warning ? (
        <div className="table-title-text">
          <WarningTwoTone
            style={{ fontSize: '20px', marginRight: '10px' }}
            twoToneColor="red"
          />
          <Text>Журналын нийлбэр дүн таараагүй</Text>
        </div>
      ) : (
        <></>
      )} */}
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<PicLeftOutlined />}
        onClick={() => {
          saveTohirgoo();
        }}
        // disabled={tableData?.has_warning}
      >
        Ханшийн тохируулга хийх
      </Button>
    </>
  );

  const titleBodit = () => (
    <>
      <div className="table-title-text-center">
        ГВХЗөрүүгийн бодит ашиг, алдагдал /Ханшийн тохируулга хийсний дараах/
      </div>
    </>
  );

  const showSum = () => (
    <AntTable.Summary fixed>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell colSpan={2}>Нийт дүн:</AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(tableData?.json_array_sum?.journal_bodit_bus_aldagdal)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(tableData?.json_array_sum?.journal_bodit_bus_ashig)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(tableData?.json_array_sum?.tohir_bodit_bus_aldagdal)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(tableData?.json_array_sum?.tohir_bodit_bus_ashig)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(tableData?.json_array_sum?.uurchlult_bodit_bus_aldagdal)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(tableData?.json_array_sum?.uurchlult_bodit_bus_ashig)}
        </AntTable.Summary.Cell>
      </AntTable.Summary.Row>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell colSpan={2}>
          Бодит бус ашиг алдагдлын журналын нийт дүн:
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(tableData?.json_array_sum?.orlogo_bodit_bus_aldagdal)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(tableData?.json_array_sum?.orlogo_bodit_bus_ashig)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell colSpan={4} />
      </AntTable.Summary.Row>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell colSpan={2}>Зөрүү:</AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(tableData?.json_array_sum?.zoruu_aldagdal)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(tableData?.json_array_sum?.zoruu_ashig)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell colSpan={4} />
      </AntTable.Summary.Row>
    </AntTable.Summary>
  );

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          columns={guilgeeColumns}
          dataSource={tableData?.json_array}
          scroll={{
            y: 'calc(100vh - 480px)',
          }}
          customize={showSum}
          title={title}
        />
        <Table
          columns={boditColumns}
          scroll={{
            y: 'calc(100vh - 250px)',
          }}
          summaryList={{
            sumColumn: boditSummary,
            sumDataSource: tableData?.json_data_bodit,
          }}
          withEmptyIcon={false}
          title={titleBodit}
        />
      </div>
    </>
  );
};

export default GuilgeeBalanceTailan;
