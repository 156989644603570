import React, {
  useEffect,
  createContext,
  useState,
  useRef,
  useContext,
} from 'react';
import {
  Table as AntTable,
  Row,
  Col,
  Input,
  Form,
  DatePicker,
  InputNumber,
  Checkbox,
} from 'antd';
import { set, isEmpty } from 'lodash';
import { onlyNumber, roundFloat } from 'src/core/utils/formater';
import moment from 'moment';
import 'moment/locale/mn';
import locale from 'antd/es/date-picker/locale/mn_MN';

const EditableContext = createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form1] = Form.useForm();
  return (
    <Form id="EditableRow" form={form1} component={false}>
      <EditableContext.Provider value={form1}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type = 'input',
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    try {
      if (editing) {
        inputRef.current.focus();
      }
    } catch (error) {
      console.warn('err', error);
    }
  }, [editing]);

  const formatFloat = (item) =>
    parseFloat(typeof item === 'string' ? item.replaceAll(',', '') : item);

  const typeRender = () => {
    if (type === 'date') return moment(record[dataIndex]);
    if (type === 'number') {
      return formatFloat(record[dataIndex]);
    }
    return record[dataIndex];
  };

  const toggleEdit = () => {
    setEditing(!editing);

    form.setFieldsValue({
      [dataIndex]: typeRender(),
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if (type === 'date') {
        set(values, dataIndex, moment(values[dataIndex]).format('YYYY/MM/DD'));
      }
      if (type === 'number') {
        set(values, dataIndex, onlyNumber(values[dataIndex]));
      }
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.warn('Save failed:', errInfo);
    }
  };

  const renderItem = (_type) => {
    switch (_type) {
      case 'input':
        return (
          <Input
            size="small"
            ref={inputRef}
            onChange={(e) => {
              form.setFieldsValue({
                [dataIndex]: e?.target?.value,
              });
            }}
            onPressEnter={save}
            onBlur={save}
          />
        );
      case 'date':
        return <DatePicker locale={locale} ref={inputRef} onBlur={save} />;
      case 'number':
        return (
          <InputNumber
            ref={inputRef}
            size="small"
            className="custom-input-number"
            locale={locale}
            formatter={onlyNumber}
            onBlur={save}
            onPressEnter={save}
          />
        );
      case 'check':
        return <Checkbox locale={locale} ref={inputRef} onBlur={save} />;
      default:
        return null;
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
        valuePropName={type === 'check' ? 'checked' : 'value'}
      >
        {renderItem(type)}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 12,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const EditableTable = ({
  title = null,
  columns = [],
  dataSource = [],
  scroll = {},
  rowSelection = null,
  geTvalue = () => {},
  summaryList = [],
  customize = null,
  pagination = false,
}) => {
  const [_dataSource, seTdataSource] = useState(dataSource);
  const handleSave = (row) => {
    const newData = [..._dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    seTdataSource([...newData]);
    geTvalue(newData);
  };

  useEffect(() => {
    seTdataSource([...dataSource]);
  }, [dataSource]);

  const renderColumns = (arr = []) => {
    const _columns = arr.map((col) => {
      let childList = [];
      if (col?.children && col?.children.length > 0) {
        childList = col?.children.map((j) => {
          if (!j.editable) {
            return j;
          }
          return {
            ...j,
            onCell: (record) => ({
              record,
              editable: j.editable,
              dataIndex: j.dataIndex,
              title: j.title,
              type: j?.type,
              handleSave,
            }),
          };
        });
        set(col, 'children', childList);
      }

      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          type: col?.type,
          handleSave,
        }),
      };
    });
    return _columns;
  };

  return (
    <div>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <AntTable
            title={title}
            components={components}
            className="custom-antd-table-one"
            rowSelection={rowSelection}
            columns={renderColumns(columns)}
            dataSource={_dataSource}
            scroll={scroll}
            pagination={pagination}
            rowClassName={() => 'table-row-class'}
            onHeaderRow={(column) =>
              column.map((i) => {
                set(i, 'align', i?.titleAlign || 'center');
                return i;
              })
            }
            size="small"
            hideSelectAll={false}
            summary={
              customize ||
              (() =>
                !isEmpty(summaryList) && summaryList?.sumDataSource ? (
                  <AntTable.Summary fixed>
                    <AntTable.Summary.Row>
                      {summaryList?.sumColumn.map((item, idx) => {
                        if (item?.word) {
                          return (
                            <AntTable.Summary.Cell
                              align={item?.align}
                              index={idx}
                            >
                              {item?.word}
                            </AntTable.Summary.Cell>
                          );
                        }
                        if (!item?.value) {
                          return <AntTable.Summary.Cell />;
                        }
                        if (idx === summaryList?.sumColumn.length - 1) {
                          return (
                            <AntTable.Summary.Cell
                              align={item.align}
                              index={idx}
                              className="last-item"
                            >
                              {roundFloat(
                                summaryList?.sumDataSource[item.value]
                              )}
                            </AntTable.Summary.Cell>
                          );
                        }
                        return (
                          <AntTable.Summary.Cell align={item.align} index={idx}>
                            {roundFloat(summaryList?.sumDataSource[item.value])}
                          </AntTable.Summary.Cell>
                        );
                      })}
                    </AntTable.Summary.Row>
                  </AntTable.Summary>
                ) : null)
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default EditableTable;
