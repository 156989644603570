import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { EditOutlined } from '@ant-design/icons';
import CanView from 'src/components/permission/canview';

export const ColumnsMain = ({ typeList, stateList }) => [
  {
    title: 'Огноо',
    dataIndex: 'ognoo',
    align: 'left',
    width: 110,
  },
  {
    title: 'Жил',
    dataIndex: 'year',
    align: 'left',
    width: 50,
  },
  {
    title: 'Сар',
    dataIndex: 'month',
    align: 'left',
    width: 50,
    render: (_) => (<>{_} сар</>),
  },
  {
    title: 'Төлөв',
    dataIndex: 'state',
    align: 'left',
    width: 75,
    render: (_, row) => (
      <div>{row?.state ? stateList[row.state] : ''}</div>
    ),
  },
  {
    title: 'Борлуулалт',
    children: [
      {
        title: 'Эх сурвалж',
        dataIndex: 'bo_file_type',
        align: 'left',
        width: 100,
        render: (_, row) => (
          <div>{row?.bo_file_type ? typeList[row.bo_file_type] : ''}</div>
        ),
      },
      {
        title: 'Файлын нэр ',
        dataIndex: 'bo_file_name',
        align: 'left',
        width: 150,
        ellipsis: true,
      },
    ],
  },
  {
    title: 'Худалдан авалт',
    children: [
      {
        title: 'Эх сурвалж',
        dataIndex: 'ha_file_type',
        align: 'left',
        width: 100,
        render: (_, row) => (
          <div>{row?.ha_file_type ? typeList[row.ha_file_type] : ''}</div>
        ),
      },
      {
        title: 'Файлын нэр',
        dataIndex: 'ha_file_name',
        align: 'left',
        width: 150,
        ellipsis: true,
      },
    ],
  },

];

export const ColumnsBurtgel = ({ openModal = () => {}, version }) => [
  {
    title: 'Бүртгэлийн дугаар',
    dataIndex: 'burtgel_dugaar',
    width: 150,
    fixed: 'left',
  },
  {
    title: 'Дансны үнэ',
    dataIndex: 'dans_une',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'tatvar_suuri',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Зөрүү',
    dataIndex: 'zuruu',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Түр зөрүүгээр хүлээн зөвшөөрөхгүй зөрүү',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'zuvshuurugduhgui_turuu_zur_hasagdah',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'zuvshuurugduhgui_turuu_zur_tatvar_nogdoh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: '',
        align: 'center',
        width: 40,
        render: (_, row) => (
          <>
            <CanView allowedRole="006_1">
              {
                // eslint-disable-next-line operator-linebreak
                row.burtgel_dugaar !== '' &&
                  // eslint-disable-next-line operator-linebreak
                  version?.is_first_year &&
                  Math.round(Number(row.zuruu)) !== 0 && (
                    <EditOutlined onClick={() => openModal(row)} />
                    // eslint-disable-next-line indent
                  )
              }
            </CanView>
          </>
        ),
      },
    ],
  },
  {
    title: 'Түр зөрүү',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'turuu_zur_hasagdah',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'turuu_zur_tatvar_nogdoh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];
