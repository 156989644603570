import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { concat } from 'lodash';

import Noat from '../../../pages/noat/2_1_0_root';
import Hist from '../../../pages/noat/2_1_1_main';
import Borluulalt from '../../../pages/noat/2_2_1_borluulalt';
import BorluulaltButsaalt from '../../../pages/noat/2_2_2_borluulalt_butsaalt';
import Hudaldanavalt from '../../../pages/noat/2_3_1_hudaldanavalt';
import HudaldanavaltButsaalt from '../../../pages/noat/2_3_2_hudaldanavalt_butsaalt';
import Negtgel from '../../../pages/noat/2_4_2_negtgel';
import Tailan from '../../../pages/noat/2_4_1_tailan';

import S4_2_HereglegchMedeelel from '../../../pages/userProfile/newUserProfile';
import S1_3_UserAdd from '../../../pages/s1_3_Hereglegch';
import S4_1_TatvarTulugchMedeelel from '../../../pages/s4_1_TatvarTulugch';

export const routeList = {
  Noat: {
    path: '/noat',
    component: Noat,
    permission: false,
  },
  Hist: {
    path: '/noat/history',
    component: Hist,
    permission: false,
  },
  Borluulalt: {
    path: '/noat/borluulalt',
    component: Borluulalt,
    permission: false,
  },
  BorluulaltButsaalt: {
    path: '/noat/borluulalt_butsaalt',
    component: BorluulaltButsaalt,
    permission: false,
  },
  Hudaldanavalt: {
    path: '/noat/hudaldanavalt',
    component: Hudaldanavalt,
    permission: false,
  },
  HudaldanavaltButsaalt: {
    path: '/noat/hudaldanavalt_butsaalt',
    component: HudaldanavaltButsaalt,
    permission: false,
  },
  Tailan: {
    path: '/noat/tailan',
    component: Tailan,
    permission: false,
  },
  Negtgel: {
    path: '/noat/negtgel',
    component: Negtgel,
    permission: false,
  },
  HereglegchMedeelel: {
    path: '/noat/hereglegchMedeelel',
    component: S4_2_HereglegchMedeelel,
    permission: false,
  },
  UserAdd: {
    path: '/noat/tohirgoo/userAdd',
    component: S1_3_UserAdd,
    permission: false,
  },
  TatvarTulugchMedeelel: {
    path: '/noat/tatvarTulugch/medeelel',
    component: S4_1_TatvarTulugchMedeelel,
    permission: false,
  },
};

const Entity = () => {
  let childList = [];
  Object.values(routeList).forEach((i) => {
    const { children = [] } = i;
    if (children.length > 0) {
      childList = concat(childList, children);
    }
  });
  const mainList = [...Object.values(routeList), ...childList];
  return (
    <Switch>
      {mainList.map(({ path, component }, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <Route exact path={path} component={component} key={key} />
      ))}
    </Switch>
  );
};

export default Entity;
