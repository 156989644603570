import React from 'react';
import CanView from 'src/components/permission/canview';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { roundFloat } from 'src/core/utils/formater';

const render = (value = '') => roundFloat(value);

const initialValues = {
  hh_etgeed_id: '',
  ttd: '',
  ner: '',
  turul_id: '',
  huvitsaa_amount: '',
  huvitsaa_price: '',
  hayg: '',
  hurungu_amount: '',
  ezemshliin_huvi: '',
};

const columns = ({
  handleOpenRegisterModal = () => {},
  onDelete = () => {},
}) => [
  {
    title: '№',
    dataIndex: 'index',
    align: 'left',
    width: 50,
  },
  {
    title: 'Хөрөнгө оруулагчийн төрөл',
    dataIndex: 'turul_ner',
    align: 'left',
    width: 150,
  },
  {
    title: 'Регистрийн дугаар',
    dataIndex: 'ttd',
    align: 'left',
    width: 180,
  },
  {
    title: 'Нэр',
    dataIndex: 'ner',
    align: 'left',
    width: 150,
  },
  {
    title: 'Хаяг',
    dataIndex: 'hayg',
    align: 'left',
    width: 150,
  },
  {
    title: 'Эзэмшиж буй хувьцааны тоо',
    dataIndex: 'huvitsaa_amount',
    align: 'right',
    render,
    width: 120,
  },
  {
    title: 'Нэгж хувьцааны үнэ',
    dataIndex: 'huvitsaa_price',
    align: 'right',
    render,
    width: 150,
  },
  {
    title: 'Хувь нийлүүлсэн хөрөнгийн хэмжээ',
    dataIndex: 'hurungu_amount',
    align: 'right',
    render,
    width: 150,
  },
  {
    title: 'Эзэмшлийн хувь хэмжээ',
    dataIndex: 'ezemshliin_huvi',
    align: 'right',
    render: (_) => `${_} %`,
    width: 80,
  },
  {
    title: 'Засах',
    width: 50,
    align: 'center',
    fixed: 'right',
    render: (_, row) => (
      <CanView allowedRole="007_1">
        <EditOutlined onClick={() => handleOpenRegisterModal(row)} />
      </CanView>
    ),
  },
  {
    title: 'Устгах',
    width: 50,
    align: 'center',
    fixed: 'right',
    render: (_, { hariltsagch_code = '' }) => (
      <CanView allowedRole="007_1">
        <DeleteOutlined onClick={() => onDelete(hariltsagch_code)} />
      </CanView>
    ),
  },
];

export default {
  initialValues,
  columns,
};
