import React, { useState, useEffect } from 'react';
import { Tabs as AntTabs } from 'antd';
// import { useSelector } from 'react-redux';

import { Route, Switch, useLocation, useHistory } from 'react-router-dom';

const { TabPane } = AntTabs;

const Tabs = ({ defaultKey = '1', listTabs = [] }) => {
  const { pathname = '' } = useLocation();
  const [activeItem, setActiveItem] = useState(defaultKey);
  const history = useHistory();
  // const permissionList = useSelector((state) => state.role);
  // const _permissionList = permissionList.map((i) => i.route);
  const onChange = (key) => {
    setActiveItem(key);
    // change(key);
    const findedPath = listTabs.find((i) => i.key === key)?.path;
    if (findedPath) {
      history.push(findedPath);
    }
  };
  useEffect(() => {
    if (pathname) {
      const findedId = listTabs.find((i) => i.path === pathname)?.key;
      if (findedId) {
        setActiveItem(findedId.toString());
      } else {
        history.push(listTabs[0]?.path);
      }
    }
  }, []);
  // if (_permissionList.length > 0) {
  //   listTabs = listTabs.filter((i) => _permissionList.includes(i.path));
  // }
  return (
    <>
      <div className="custom-tabs-container">
        <Switch>
          <AntTabs activeKey={activeItem} onChange={onChange}>
            {listTabs.map((item) => (
              <TabPane tab={item?.title} key={item.key}>
                <div className="inside-container">
                  <Route path={item.path}>{item.content}</Route>
                </div>
              </TabPane>
            ))}
          </AntTabs>
        </Switch>
      </div>
    </>
  );
};

export default Tabs;
