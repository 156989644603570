import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tooltip, Popconfirm } from 'antd';
import { roundFloat } from 'src/core/utils/formater';
import CanView from 'src/components/permission/canview';
import { customConfirm } from 'src/components/common/confirmModal';

export const column_22_5_11 = ({
  send_confirm_row_id = () => {},
  delete_row = () => {},
  send_update_data = () => {},
}) => [
  {
    title: 'Гэрээний нэр',
    dataIndex: 'geree_ner',
    align: 'left',
    titleAlign: 'center',
    width: '6vw',
  },
  {
    title: 'Гэрээний огноо',
    dataIndex: 'ognoo',
    align: 'left',
    titleAlign: 'center',
    width: '6vw',
  },
  {
    title: 'Хөнгөлөлтийн дүн',
    dataIndex: 'hongololt_dun',
    align: 'left',
    titleAlign: 'center',
    width: '6vw',
    render: (_, row) => (
      <div>
        <p>{roundFloat(row?.hongololt_dun)}</p>
      </div>
    ),
  },
  {
    title: 'Тайлбар',
    dataIndex: 'tailbar',
    align: 'left',
    titleAlign: 'center',
    width: '10vw',
  },
  {
    title: 'Файл хавсаргах',
    dataIndex: 'file_ner',
    align: 'left',
    titleAlign: 'center',
    width: '6vw',
    render: (_, row) => (
      <CanView allowedRole="002_1">
        <Tooltip title={row?.file_ner}>
          <Popconfirm
            title="Файл татах уу?"
            okText="Тийм"
            cancelText="Үгүй"
            onConfirm={() => send_confirm_row_id(row.file_id, row.file_ner)}
          >
            <div className="link-wrap"> {row.file_ner}</div>
          </Popconfirm>
        </Tooltip>
      </CanView>
    ),
  },
  {
    title: 'Устгах',
    dataIndex: 'delete',
    align: 'center',
    titleAlign: 'center',
    width: '3vw',
    render: (_, row) => (
      <CanView allowedRole="002_1">
        <Tooltip title="Устгах">
          <DeleteOutlined
            onClick={() =>
              customConfirm({
                onOk: () => delete_row(row?.hongololt_id),
              })
            }
          />
        </Tooltip>
      </CanView>
    ),
  },
  {
    title: 'Засах',
    dataIndex: 'edit',
    align: 'center',
    titleAlign: 'center',
    width: '3vw',
    render: (_, row) => (
      <CanView allowedRole="002_1">
        <Tooltip title="Засах">
          {/* <span>Tooltip will show on mouse enter.</span> */}
          <EditOutlined
            onClick={() =>
              send_update_data(
                row.ognoo,
                row.hongololt_id,
                row.file_id,
                row.file_ner,
                row.gereenii_ner,
                row.tailbar,
                row.hongololt_dun
              )
            }
          />
        </Tooltip>
      </CanView>
    ),
  },
];
