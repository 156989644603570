/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { roundFloat } from 'src/core/utils/formater';
import { Tooltip } from 'antd';

export const mainColumns = ({
  customEdit = () => {},
  customDelete = () => {},
  makeInfo = () => {},
}) => [
  {
    title: '#',
    dataIndex: '',
    align: 'center',
    titleAlign: 'center',
    width: 30,
    render: (_, __, idx) => idx + 1,
  },
  {
    title: 'Нэр',
    dataIndex: 'ner',
    align: 'left',
    titleAlign: 'left',
    render: (_, row) => (
      <Tooltip title={_} placement="right">
        <div className="parent-clicker" onClick={() => makeInfo(row)}>
          <span>{_}</span>
        </div>
      </Tooltip>
    ),
  },
  {
    title: '',
    align: 'center',
    titleAlign: 'center',
    width: 30,
    render: (_, row) => {
      if (row?.can_modify === true) {
        return <EditOutlined onClick={() => customEdit(row)} />;
      }
      return '';
    },
  },
  {
    title: '',
    align: 'center',
    titleAlign: 'center',
    width: 30,
    render: (_, row) => {
      if (row?.can_modify === true) {
        return <DeleteOutlined onClick={() => customDelete(row)} />;
      }
      return '';
    },
  },
];

export const infoColumns = ({
  customEdit = () => {},
  customDelete = () => {},
}) => [
  {
    title: '#',
    dataIndex: 'counter',
    align: 'left',
    titleAlign: 'center',
    width: 30,
  },
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    titleAlign: 'center',
    width: 250,
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    align: 'left',
    titleAlign: 'center',
    // width: 200,
    ellipsis: true,
  },
  {
    title: 'Татварын ангилал',
    dataIndex: 'dans_dugaar_harits',
    align: 'left',
    titleAlign: 'center',
    // width: 200,
    ellipsis: true,
  },
  {
    title: 'Дт (%)',
    dataIndex: 'perc_deb',
    align: 'right',
    titleAlign: 'center',
    width: 60,
    render: (_, row) => ({
      children: roundFloat(row?.perc_deb),
    }),
  },
  {
    title: 'Кт (%)',
    dataIndex: 'perc_cred',
    align: 'right',
    titleAlign: 'center',
    width: 60,
    render: (_, row) => ({
      children: roundFloat(row?.perc_cred),
    }),
  },
  {
    title: '',
    align: 'center',
    titleAlign: 'center',
    width: 30,
    render: (_, row) => <EditOutlined onClick={() => customEdit(row)} />,
  },
  {
    title: '',
    align: 'center',
    titleAlign: 'center',
    width: 30,
    render: (_, row) => <DeleteOutlined onClick={() => customDelete(row)} />,
  },
];
