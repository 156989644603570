import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table, Row, Col, Button, Tooltip, Input, message, Spin } from 'antd';
import { CalculatorOutlined, LoadingOutlined } from '@ant-design/icons';
import { onlyNumber, roundFloat } from 'src/core/utils/formater';

import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { guilgeeColumns, expandColumns } from './dataStructure/data';

const columns = guilgeeColumns;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Tailan = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const [tableData, setTableData] = useState([]);
  const [tableSummary, setTableSummary] = useState([]);
  const [busadData, setBusadData] = useState({
    hongologdoh_tat: '',
    choloologdoh_tat: '',
  });
  const [loading, seTloading] = useState(true);

  const generateHmTailan = (month) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        for (const data in content.mayagt_data) {
          for (const inner_data in content.mayagt_data[data].child) {
            for (const tdata in content.mayagt_data[data].child[inner_data]
              .tootsoolol_list) {
              if (
                content.mayagt_data[data].child[inner_data].tootsoolol_list[
                  tdata
                ].name !== 'Хөнгөлөлтийн хувь' &&
                content.mayagt_data[data].child[inner_data].tootsoolol_list[
                  tdata
                ].dun !== '' &&
                !content.mayagt_data[data].child[inner_data].tootsoolol_list[
                  tdata
                ].dun.includes(',')
              ) {
                content.mayagt_data[data].child[inner_data].tootsoolol_list[
                  tdata
                ].dun = roundFloat(
                  content.mayagt_data[data].child[inner_data].tootsoolol_list[
                    tdata
                  ].dun
                );
              }
            }
          }
        }
        for (const data in content.mayagt_summary) {
          const temp_busad = busadData;
          if (content.mayagt_summary[data].mur === 15) {
            temp_busad.hongologdoh_tat = roundFloat(
              content.mayagt_summary[data].dun
            );
          } else if (content.mayagt_summary[data].mur === 16) {
            temp_busad.choloologdoh_tat = roundFloat(
              content.mayagt_summary[data].dun
            );
          }
          setBusadData(temp_busad);

          // content.mayagt_summary[data].dun = roundFloat(
          //   content.mayagt_summary[data].dun
          // );
        }
        setTableData(content.mayagt_data);
        setTableSummary(content.mayagt_summary);
        seTloading(false);
      } else {
        //
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_00_hungulult_manage',
      content: {
        action_type: 'get_hm02_data',
        month_start: '1',
        month_end: String(month),
      },
    });

    dispatch(start_loading({}));
  };

  const sendBusadData = (data) => {
    if (data.hongologdoh_tat !== 0 ?? data.choloologdoh_tat !== 0) {
      socket.on('request_out', ({ status, content }) => {
        socket.removeAllListeners('request_out');
        newAlert({
          type: status === '0' ? 'success' : 'error',
          msg: content.Message,
          functions: {
            onSuccess: generateHmTailan(data_date.month),
          },
        });

        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts007_00_hungulult_manage',
        content: {
          action_type: 'set_mayagt_busad_data',
          data,
          month: data_date.month,
        },
      });
      dispatch(start_loading({}));
    } else {
      message.warning('Дүн өөрчлөгдөөгүй байна');
    }
  };

  const setValue = (type, values) => {
    setBusadData((prev) => ({
      ...prev,
      [type]: values,
    }));
  };

  useEffect(() => {
    if (data_date) {
      generateHmTailan(data_date.month);
    }
  }, [data_date]);

  const title = () => (
    <div className="table-title-text-center">
      АЛБАН ТАТВАРЫН ХӨНГӨЛӨЛТИЙН ТООЦООЛОЛ
    </div>
  );

  const mainSummary = (list = []) => (
    <Table.Summary fixed>
      {list.map((j) => (
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={2}>
            <Row
              style={{
                width: '100% !important',
              }}
            >
              <Col
                style={{
                  textAlign: 'right',
                  paddingRight: 7,
                }}
                span={13}
              >
                {j.ner}
              </Col>
              <Col
                style={{
                  textAlign: 'center',
                  paddingLeft: 15,
                }}
                span={1}
              >
                {j.mur}
              </Col>
              <Col
                style={{
                  textAlign: 'center',
                }}
                span={10}
              >
                {(() => {
                  if (j?.mur === 15) {
                    return (
                      <Input
                        className="none-border-input fs-12 fw-600 cursor-pointer text-center"
                        bordered={false}
                        value={busadData?.hongologdoh_tat || ''}
                        onChange={({ target }) => {
                          setValue('hongologdoh_tat', target?.value);
                        }}
                        onPressEnter={({ target }) => {
                          const _value = onlyNumber(target?.value);
                          setValue('hongologdoh_tat', _value);
                        }}
                        onBlur={({ target }) => {
                          const _value = onlyNumber(target?.value);
                          setValue('hongologdoh_tat', _value);
                        }}
                      />
                    );
                  }

                  if (j?.mur === 16) {
                    return (
                      <Input
                        className="none-border-input fs-12 fw-600 cursor-pointer text-center"
                        bordered={false}
                        value={busadData?.choloologdoh_tat || ''}
                        onChange={({ target }) => {
                          setValue('choloologdoh_tat', target?.value);
                        }}
                        onPressEnter={({ target }) => {
                          const _value = onlyNumber(target?.value);
                          setValue('choloologdoh_tat', _value);
                        }}
                        onBlur={({ target }) => {
                          const _value = onlyNumber(target?.value);
                          setValue('choloologdoh_tat', _value);
                        }}
                      />
                    );
                  }

                  return roundFloat(j.dun);
                })()}
                {/* {j?.mur === 15 || j?.mur === 16 ? (
                  <Input
                    className="none-border-input fs-12 fw-600 cursor-pointer text-center"
                    bordered={false}
                    defaultValue={j.dun}
                    onChange={({ target }) => {
                      if (j.mur === 15) {
                        setValue('hongologdoh_tat', onlyNumber(target.value));
                      } else if (j.mur === 16) {
                        setValue('choloologdoh_tat', onlyNumber(target.value));
                      }
                    }}
                  />
                ) : (
                  roundFloat(j.dun)
                )} */}
              </Col>
            </Row>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      ))}
    </Table.Summary>
  );

  const expandedRowRender = (list = [], summary = null) =>
    list.length > 0 && (
      <Table
        className="aa-tatvar-tailan sub"
        columns={expandColumns}
        dataSource={list}
        pagination={false}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell
                style={{
                  textAlign: 'center',
                }}
                index={1}
                align="right"
                colSpan={2}
              >
                Хэсгийн дүн
              </Table.Summary.Cell>
              <Table.Summary.Cell align="center" index={2}>
                {summary?.mur}
              </Table.Summary.Cell>
              <Table.Summary.Cell />
              <Table.Summary.Cell align="center" index={1}>
                {roundFloat(summary?.dun)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    );

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin indicator={antIcon} />
      </div>
    );
  }
  return (
    <div className="hh-100">
      <Table
        title={title}
        className="aa-tatvar-tailan"
        columns={columns}
        dataSource={tableData}
        expandable={{
          defaultExpandAllRows: true,
          expandedRowRender: (row) =>
            expandedRowRender(row?.child, row?.summary),
          expandIcon: () => '',
        }}
        rowClassName="role-row"
        scroll={{ y: 'calc(100vh - 330px)' }}
        summary={() => mainSummary(tableSummary)}
        pagination={false}
      />
      <div style={{ float: 'right', paddingRight: 50 }}>
        <Tooltip title="ААНОАТ-ын хуулиар бусад дүн өөрчлөх">
          <Button
            className="add-btn"
            type="primary"
            size="small"
            icon={<CalculatorOutlined />}
            onClick={() => {
              sendBusadData(busadData);
            }}
          >
            Хадгалах
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default Tailan;
