import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  InputNumber,
  Row,
  Col,
  Spin,
  Button,
  Collapse,
  Form,
  Input,
  DatePicker,
  Tooltip,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  LoadingOutlined,
  DownOutlined,
  UpOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { roundFloat } from 'src/core/utils/formater';
import moment from 'moment';
import { enable_state, disable_state } from 'src/core/actions/route_state';
import { customConfirm } from 'src/components/common/confirmModal';

const { Panel } = Collapse;

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editingValue, setEditingValue] = useState(null);

  const handleChange = (value) => {
    setEditingValue(roundFloat(value));
    if (handleSave) {
      handleSave(record.key, dataIndex, value);
    } else {
      console.error('handleSave is not defined');
    }
  };

  let childNode = children || '';

  if (editable && record) {
    childNode = (
      <InputNumber
        className="wide-input-number" // Apply the custom class
        min={0}
        value={
          editingValue !== null ? editingValue : roundFloat(record[dataIndex])
        }
        onChange={handleChange}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableCell2 = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave2,
  ...restProps
}) => {
  const [editingValue, setEditingValue] = useState(null);

  const handleChange = (value) => {
    setEditingValue(roundFloat(value));
    if (handleSave2) {
      handleSave2(record.key, dataIndex, value);
    } else {
      console.error('handleSave is not defined');
    }
  };

  let childNode = children || '';

  if (editable && record) {
    childNode = (
      <InputNumber
        className="wide-input-number" // Apply the custom class
        min={0}
        value={
          editingValue !== null ? editingValue : roundFloat(record[dataIndex])
        }
        onChange={handleChange}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const App = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });

  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));
  const dispatch = useDispatch();

  const [dataSource, setDataSource] = useState([]);
  const [dataSource2, setDataSource2] = useState([]);

  const handleSend = async () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
      });
      dispatch(end_loading({}));
    });
    const content = {
      month: data_date?.month,
      action_type: 'save_sa18',
      table_1: dataSource,
      table_2: dataSource2,
    };

    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content,
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    const get_data = (month) => {
      socket.on('request_out', ({ status, content }) => {
        console.log('🚀 ~ socket.on ~ content:', content);
        socket.removeAllListeners('request_out');
        if (status === '0') {
          setDataSource(content?.table1);
          setDataSource2(content?.table2);
        } else {
          newAlert({ msg: 'Системд алдаа гарлаа' });
        }
        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts001_05_report',
        content: {
          action_type: 'get_sa18',
          month,
        },
      });
      dispatch(start_loading({}));
    };
    get_data(data_date.month);
  }, [data_date.month]);

  const handleSave = (key, dataIndex, value) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, [dataIndex]: value });
    setDataSource(newData);
  };
  const handleSave2 = (key, dataIndex, value) => {
    const newData = [...dataSource2];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, [dataIndex]: value });
    setDataSource2(newData);
  };

  const columns = [
    {
      title: 'Үзүүлэлт',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      align: 'left',
      render: (text, record) => text,
    },
    {
      title: 'Өмнөх оны дүн',
      dataIndex: 'omnoh_on',
      key: 'omnoh_on',
      width: 200,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Тайлант оны дүн',
      dataIndex: 'tailant_on',
      key: 'tailant_on',
      width: 200,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }
    if (col.children) {
      // Handle child columns
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editableFields
              ? record.editableFields[childCol.dataIndex]
              : false,
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave,
          }),
        })),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editableFields
          ? record.editableFields[col.dataIndex]
          : false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const columns2 = [
    {
      title: 'Үзүүлэлт',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      align: 'left',
      render: (text, record) => text,
    },
    {
      title: 'Өмнөх оны дүн',
      dataIndex: 'omnoh_on',
      key: 'omnoh_on',
      width: 200,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Тайлант оны дүн',
      dataIndex: 'tailant_on',
      key: 'tailant_on',
      width: 200,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
  ];

  const mergedColumns2 = columns2.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }
    if (col.children) {
      // Handle child columns
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editableFields
              ? record.editableFields[childCol.dataIndex]
              : false,
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave2,
          }),
        })),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editableFields
          ? record.editableFields[col.dataIndex]
          : false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave2,
      }),
    };
  });

  return (
    <div className="tax-account-table-container">
      <Collapse
        defaultActiveKey={['1', '2']}
        expandIconPosition="right"
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ? <UpOutlined /> : <DownOutlined />
        }
      >
        <Panel header="18.1 Борлуулалтын орлого" key="1">
          <Table
            className="tax-account-table-panel mw-1500"
            dataSource={dataSource}
            columns={mergedColumns}
            rowClassName={() => 'editable-row'}
            bordered
            pagination={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            // footer={() => (
            //   <Button
            //     type="primary"
            //     className="tax-account-button-unique-mini"
            //     onClick={() => handleSend(1)}
            //   >
            //     Хадгалах
            //   </Button>
            // )}
          />
        </Panel>
        <Panel header="18.2 Борлуулалтын өртөг" key="2">
          <Table
            className="tax-account-table-panel mw-1500"
            dataSource={dataSource2}
            columns={mergedColumns2}
            rowClassName={() => 'editable-row'}
            bordered
            pagination={false}
            components={{
              body: {
                cell: EditableCell2,
              },
            }}
            // footer={() => (
            //   <Button
            //     type="primary"
            //     className="tax-account-button-unique-mini"
            //     onClick={() => handleSend(1)}
            //   >
            //     Хадгалах
            //   </Button>
            // )}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default App;
