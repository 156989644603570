import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Modal, Table as AntTable } from 'antd';
import { useQuery, roundFloat } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import Table from 'src/components/common/table';
import newAlert from 'src/components/newAlert';
import { hariltsagch_columns } from '../dataStructure/data';
import TuuhenHanshModal from '../tuuhenHanshModal';

const { Text } = Typography;

const nullValue = {
  dun_id: '',
  dans_type: '',
  dans_valiut: '',
  valiut_dun: 0,
};

const TabDans = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [totalData, setTotalData] = useState(5);
  const [openModalHansh, setOpenModalHansh] = useState(false);
  const [dialogHanshValues, setDialogHanshValues] = useState({});

  const handleOpenModalHansh = (rowData) => {
    // eslint-disable-next-line operator-linebreak
    const file_type =
      // eslint-disable-next-line operator-linebreak
      query.get('dans_code').charAt(0) === '1' ||
      query.get('dans_code').charAt(0) === '2'
        ? 'avlaga'
        : 'oglog';

    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setDialogHanshValues({
          dun_id: rowData.id,
          dans_type: file_type,
          dans_valiut: data.content.info.valiut || '',
          valiut_dun: rowData.ets_uld_valiut || 0,
        });

        setOpenModalHansh(true);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: {
        action_type: 'get_dans_info',
        dans_dugaar: query.get('dans_code'),
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    const fetchData = async (page, size) => {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');

        if (data.content.status === '0') {
          setTableData(data.content.json_array);
          setTotalData(data.content.total);
          setTableDataSum(data.content.sum_dict);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }
        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts002_00_tatvar_suuri',
        content: {
          action_type: 'ehnii_uldegdel_hariltsagchaar',
          dans_code: query.get('dans_code'),
          month: data_date.month,
          page: page || 1,
          size: size || 20,
        },
      });
      dispatch(start_loading({}));
    };
    const page = query.get('page2');
    const size = query.get('size2');
    if (query.get('tab') === '2' && query.get('dans_code')) {
      fetchData(page, size);
    }
  }, [query, data_date]);

  const showSum = () => (
    <AntTable.Summary fixed>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell />
        <AntTable.Summary.Cell align="right">Нийт дүн:</AntTable.Summary.Cell>
        <AntTable.Summary.Cell />
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(tableDataSum?.ets_uld)}</p>
          <p className="bg-highlight">
            {roundFloat(tableDataSum?.ets_uld_valiut)}
          </p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell />
      </AntTable.Summary.Row>
    </AntTable.Summary>
  );

  return (
    <>
      {query.get('dans_code') ? (
        <div className="table-wrapper">
          <p>
            <span className="ml-3 "> Дансны дугаар: </span>
            {query.get('dans_code')}
          </p>
          <div className="inside-table">
            <Table
              columns={hariltsagch_columns(
                handleOpenModalHansh,
                query.get('tur_zuruu_turul')
              )}
              tab="2"
              total={totalData}
              dataSource={tableData}
              scroll={{
                y: 'calc(100vh - 360px)',
              }}
              customize={showSum}
            />
          </div>
        </div>
      ) : (
        <Text className="ant-form-text">Дансаа сонгоно уу!</Text>
      )}
      <Modal
        className="custom-modal"
        title="Түүхэн ханш"
        visible={openModalHansh}
        closable={false}
        maskClosable={false}
        width={600}
        footer={null}
      >
        <TuuhenHanshModal
          initialValues={dialogHanshValues}
          onClose={() => {
            setOpenModalHansh(false);
            setDialogHanshValues(nullValue);
          }}
        />
      </Modal>
    </>
  );
};

export default TabDans;
