import React from 'react';
import { Button, Form, Row, Col, Modal } from 'antd';
import { Table } from 'src/components';
import { ErrorListDahinColumns, ErrorListDahinRowsColumns } from './dataStructure/data';

export const ErrorListDahin = ({
  onCancel = () => {},
  errorList = {
    have_error: false,
    errors: [],
    burtgel_errors: {},
  },
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      className="custom-modal"
      title="Өртгийн өөрчлөлтийн жагсаалт оруулахад гарсан алдаа"
      open={errorList.have_error}
      closable={false}
      maskClosable={false}
      width="80%"
      footer={null}
      onCancel={onCancel}
    >
      <div className="dans-medeelel-container">
        <Form
          form={form}
          className="register-form"
          size="small"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Table
            columns={ErrorListDahinColumns()}
            dataSource={errorList?.errors.sum_error}
            scroll={{
              y: 'calc(100vh - 330px)',
            }}
          />
          <Table
            columns={ErrorListDahinRowsColumns()}
            dataSource={errorList?.errors.error_row}
            scroll={{
              y: 'calc(100vh - 330px)',
            }}
          />
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <Button
                  onClick={() => {
                    onCancel();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
