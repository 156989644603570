import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { roundFloat } from 'src/core/utils/formater';
import { customConfirm } from 'src/components/common/confirmModal';

const setFloat = (_) => roundFloat(_);

export const customSummary = [
  {
    align: 'center',
    word: '',
  },
  {
    align: 'center',
    word: '',
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'center',
    word: '',
  },
  {
    align: 'center',
    word: '',
  },
  {
    align: 'center',
    word: '',
  },

  {
    align: 'right',
    value: 'zam_honog',
  },
  {
    align: 'right',
    value: 'bair_honog',
  },
  {
    align: 'center',
    word: '',
  },
  {
    align: 'right',
    value: 'guitsetgel_tomilolt_zardal',
  },
  {
    align: 'right',
    value: 'unaa_nisleg',
  },
  {
    align: 'right',
    value: null,
  },
  {
    align: 'right',
    value: null,
  },
];
export const columns = ({
  onEdit = () => {},
  delete_row = () => {},
  hanshEdit = () => {},
  warning = () => {},
}) => [
  {
    title: 'Огноо',
    align: 'center',
    dataIndex: 'ognoo',
    titleAlign: 'center',
    width: 100,
  },
  {
    title: 'Томилолтын төрөл',
    align: 'left',
    titleAlign: 'center',
    width: 100,
    render: (_, row) => {
      if (row.tomilolt_torol === '1') {
        return (
          <div
            style={{
              width: '3vw',
              flexWrap: 'wrap',
              textAlign: 'center',
            }}
          >
            Гадаад
          </div>
        );
      }
      if (row.tomilolt_torol === '2') {
        return (
          <div
            style={{
              width: '3vw',
              flexWrap: 'wrap',
              textAlign: 'center',
            }}
          >
            Дотоод
          </div>
        );
      }
      return <div />;
    },
  },
  {
    title: 'Томилолтоор явсан газар',
    align: 'left',
    width: 200,
    // className: 'children-25',
    titleAlign: 'center',
    dataIndex: 'gazar_torol',
  },
  {
    title: 'Ханш',
    align: 'center',
    titleAlign: 'center',
    width: 100,
    render: (_, row) => {
      if (row.tomilolt_torol === '1') {
        return (
          <>
            {roundFloat(row.hansh)}
            <Tooltip title="Өөрчлөх">
              <EditOutlined
                onClick={() => {
                  const data = {
                    hansh: row?.hansh,
                    tomilolt_id: row?.tomilolt_id,
                    hansh_tailbar: row?.hansh_tailbar,
                  };
                  hanshEdit(data);
                }}
                style={{ color: '#08c' }}
              />
            </Tooltip>
          </>
        );
      }
      if (row.tomilolt_torol === '2') {
        return <div />;
      }
      return <div />;
    },
  },
  {
    title:
      'Төрийн албан хаагчийн гадаад томилолтын зам хоног, зочид буудлын зардлын хэмжээ',
    align: 'center',
    children: [
      {
        title: 'USD',
        align: 'center',
        children: [
          {
            title: 'Зам хоног',
            align: 'right',
            width: 50,
            titleAlign: 'center',
            dataIndex: 'gadaad_zam',
            render: setFloat,
          },
          {
            title: 'Зочид буудал',
            align: 'right',
            titleAlign: 'center',
            width: 50,
            dataIndex: 'gadaad_bair',
            render: setFloat,
          },
        ],
      },

      {
        title: 'MNT',
        align: 'center',
        children: [
          {
            title: 'Зам хоног',
            align: 'right',
            titleAlign: 'center',
            dataIndex: 'zam_honog',
            width: 100,
            render: setFloat,
          },
          {
            title: 'Зочид буудал',
            align: 'right',
            titleAlign: 'center',
            dataIndex: 'bair_honog',
            width: 100,
            render: setFloat,
          },
        ],
      },
    ],
  },

  {
    title: 'Хүн хоног',
    align: 'right',
    titleAlign: 'center',
    dataIndex: 'hun_honog',
    width: 50,
  },
  {
    title: 'Гүйцэтгэлээрх томилолтын зардал',
    align: 'right',
    titleAlign: 'center',
    dataIndex: 'guitsetgel_tomilolt_zardal',
    width: 100,
    render: setFloat,
  },
  {
    title: 'Унаа, нислэгийн зардал',
    align: 'right',
    titleAlign: 'center',
    dataIndex: 'unaa_nisleg',
    width: 100,
    render: setFloat,
  },
  {
    title: 'Засах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    render: (_, row) => (
      <Tooltip title="Засах">
        <EditOutlined
          onClick={() => {
            const data = {
              id: row.id,
              tomilolt_torol: parseInt(row?.tomilolt_torol),
              tomilolt_hun_honog: row?.hun_honog,
              guitsetgel_tomilolt_zardal: row?.guitsetgel_tomilolt_zardal,
              unaa_nisleg: row?.unaa_nisleg,
              ognoo: row?.ognoo,
              gazar_torol: row?.gazar_torol,
            };

            onEdit(data);
          }}
          style={{ color: '#08c' }}
        />
      </Tooltip>
    ),
  },
  {
    title: 'Устгах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    render: (_, row) => (
      <Tooltip title="Устгах">
        <DeleteOutlined
          onClick={() =>
            customConfirm({
              onOk: () => delete_row(row?.id),
            })
          }
        />
      </Tooltip>
    ),
  },
];
