import React from 'react';
import { Tooltip } from 'antd';
import { SendOutlined, SaveOutlined } from '@ant-design/icons';

export const data = [
  {
    id: 1,
    periodLabel: '1-р улирал',
    taxFrequency: 'Q',
    startDate: '2020-01-01',
    endDate: '2020-02-10',
  },
  {
    id: 2,
    periodLabel: '2-р улирал',
    taxFrequency: 'Q',
    startDate: '2020-01-01',
    endDate: '2020-02-10',
  },
  {
    id: 3,
    periodLabel: '5-р улирал',
    taxFrequency: 'Q',
    startDate: '2020-01-01',
    endDate: '2020-02-10',
  },
  {
    id: 4,
    periodLabel: '6-р улирал',
    taxFrequency: 'Q',
    startDate: '2020-01-01',
    endDate: '2020-02-10',
  },
  {
    id: 5,
    periodLabel: '7-р улирал',
    taxFrequency: 'Q',
    startDate: '2020-01-01',
    endDate: '2020-02-10',
  },
  {
    id: 6,
    periodLabel: '8-р улирал',
    taxFrequency: 'Q',
    startDate: '2020-01-01',
    endDate: '2020-02-10',
  },
];

export const columns = ({ open = () => {}, open_medee = () => {} }) => [
  {
    title: 'Маягтын код',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'taxReportCode',
    width: 50,
  },
  {
    title: 'Маягтын нэр',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'taxTypeName',
    width: 120,
  },
  {
    title: 'Татварын албаны нэр',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'branchName',
    width: 120,
  },
  {
    title: 'Тайлангийн төлөвийн нэр',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'taxReportStatusName',
    width: 120,
    render: (_) => <>{_}</>,
  },
  {
    title: 'Хуулийн хугацаа дуусах огноо',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'returnDueDate',
    width: 120,
    render: (_) => <>{_}</>,
  },
  {
    title: 'Тайлбар',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'descName',
    width: 120,
  },
  {
    title: 'Маягт хадгалах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    dataIndex: 'state',
    render: (_, row) => (
      <div>
        <Tooltip title="Маягт хадгалах">
          <SendOutlined
            onClick={() => {
              open(row);
            }}
            style={{ color: 'green', fontSize: 15 }}
          />
        </Tooltip>
      </div>
    ),
  },
  {
    title: 'Мэдээ хадгалах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    dataIndex: 'state',
    render: (_, row) => (
      <div>
        <Tooltip title="Мэдээ хадгалах">
          <SendOutlined
            onClick={() => {
              open_medee(row);
            }}
            style={{ color: 'green', fontSize: 15 }}
          />
        </Tooltip>
      </div>
    ),
  },
];

export const modal_columns = ({ sendTailan = () => {} }) => [
  {
    title: '№',
    align: 'left',
    titleAlign: 'left',
    width: 30,
    render: (_, row) => row.id,
  },
  {
    title: 'Тайлан код',
    align: 'left',
    titleAlign: 'left',
    width: 100,
    dataIndex: 'tailan_key',
  },
  {
    title: 'Тайлангийн нэр',
    align: 'left',
    titleAlign: 'left',
    width: 400,
    dataIndex: 'name',
  },
  {
    title: 'Төлөв',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    dataIndex: 'state',
    render: (_, row) => {
      console.log(_);
      if (_ === 0) {
        return (
          <div>
            <Tooltip title="Тайлан хадгалах">
              <SendOutlined
                onClick={() => {
                  // eslint-disable-next-line no-param-reassign
                  sendTailan({
                    tailan_key: row?.tailan_key,
                    form_no: row?.formNo,
                  });
                }}
                style={{ color: 'green', fontSize: 15 }}
              />
            </Tooltip>
          </div>
        );
      }

      if (_ === -1) {
        return (
          <div>
            <Tooltip title="Идэвхгүй">
              <SendOutlined disabled />
            </Tooltip>
          </div>
        );
      }

      return (
        <div>
          <Tooltip title="Дахин хадгалах">
            <SendOutlined
              onClick={() => {
                sendTailan({
                  tailan_key: row?.tailan_key,
                  form_no: row?.formNo,
                });
              }}
              style={{ color: 'blue', fontSize: 15 }}
            />
          </Tooltip>
        </div>
      );
    },
  },
];
export const late_columns = [
  {
    title: 'Маягтын код',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'taxReportCode',
    width: 50,
  },
  {
    title: 'Маягтын нэр',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'taxTypeName',
    width: 120,
  },
  {
    title: 'Татварын албаны нэр',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'branchName',
    width: 120,
  },
  {
    title: 'Тайлангийн төлөвийн нэр',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'taxReportStatusName',
    width: 120,
    render: (_) => <>{_}</>,
  },
  {
    title: 'Хуулийн хугацаа дуусах огноо',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'returnDueDate',
    width: 120,
    render: (_) => <>{_}</>,
  },
  {
    title: 'Тайлбар',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'descName',
    width: 120,
  },
];
