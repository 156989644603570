import React from 'react';
import { Modal, List, Card, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
// import etaxLogo from 'src/assets/images/etax.png';
// import Logo from 'src/assets/images/ICON2.png';

export const SelectModul = ({ onOk = () => {} }) => {
  const handleClose = () => {
    Modal.destroyAll();
  };

  Modal.confirm({
    className: 'select-modul-modal',
    title: (
      <div className="modal-header">
        <Typography.Title level={3}>Модуль сонгох</Typography.Title>
        <CloseOutlined onClick={handleClose} className="close-icon" />
      </div>
    ),
    width: 600,
    centered: true,
    icon: null,
    okButtonProps: { style: { display: 'none' } },
    cancelButtonProps: { style: { display: 'none' } },
    footer: null,
    onOk() {
      onOk();
    },
    content: (
      <>
        <List
          className="list-container"
          grid={{ gutter: 24, column: 2 }}
          dataSource={[
            {
              title: (
                <div>
                  <Typography.Title
                    level={4}
                    style={{
                      textAlign: 'center',
                      fontSize: 14,
                    }}
                  >
                    ААНОАТ
                  </Typography.Title>
                  Аж ахуй нэгжийн орлогын албан татварын тооцоолол, тайлан
                  /ААНОАТ тайлан, СБТТҮХЗөрүүг зохицуулах тайлан/
                  {/* <div className="icons-container">
                    <img src={Logo} alt="Logo" />
                  </div> */}
                </div>
              ),
              onClick: () => {
                localStorage.setItem('modul', 'main');
                onOk();
                handleClose();
              },
            },
            {
              title: (
                <div>
                  <Typography.Title
                    level={4}
                    style={{
                      textAlign: 'center',
                      fontSize: 14,
                    }}
                  >
                    НӨАТ
                  </Typography.Title>
                  Борлуулсан болон худалдан авсан бараа ажил үйлчилгээний
                  ибаримт тулгалт хяналт, НӨАТ тооцоолол, тайлан /НӨАТ суутган
                  төлөгчийн тайлан/
                  {/* <div className="icons-container">
                    <img src={etaxLogo} alt="Logo" />
                  </div> */}
                </div>
              ),
              onClick: () => {
                localStorage.setItem('modul', 'noat');
                onOk();
                handleClose();
              },
            },
          ]}
          renderItem={(item) => (
            <List.Item>
              <Card
                className="card-container"
                onClick={item.onClick}
                bordered={false}
                hoverable
              >
                {item.title}
              </Card>
            </List.Item>
          )}
        />
      </>
    ),
  });
};
