import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Statistic,
  Row,
  Col,
  Tooltip as AntTooltip,
  Typography,
} from 'antd';
import {
  PieChart,
  Pie,
  Tooltip,
  Legend,
  Cell,
  Text,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ReferenceLine,
  Bar,
  Sector,
} from 'recharts';
import newAlert from 'src/components/newAlert';
import { roundFloat } from 'src/core/utils/formater';
import { start_loading, end_loading } from '../../core/actions/loader';

const { Paragraph } = Typography;
const COLORS = ['#3d74ac', '#0094bc', '#00afa8', '#4cc37a', '#b9cd4d'];
const COLORS_2 = ['#0094bc', '#00afa8', '#4cc37a', '#b9cd4d', '#3d74ac'];

const Home = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [dashboardData, setDashboardData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndexTwo, setActiveIndexTwo] = useState(0);
  const [initDashboard, setInitDashboard] = useState(false);

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const onPieEnterTwo = useCallback(
    (_, index) => {
      setActiveIndexTwo(index);
    },
    [setActiveIndexTwo]
  );

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="dashboard-chart-tooltip">
          {payload[0].name} : {roundFloat(payload[0].value)}
        </div>
      );
    }
    return null;
  };

  const CustomTooltipMulti = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="dashboard-chart-tooltip">
          <p style={{ marginBottom: '10px' }}>{label}</p>
          {payload.map(({ name, value, color }) => (
            <p
              style={{ color: color, marginBottom: '5px' }}
            >{`${name} : ${roundFloat(value)}`}</p>
          ))}
        </div>
      );
    }
    return null;
  };

  const CustomizedTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} textAnchor="end" fill="#757575">
          {roundFloat(payload.value)}
        </text>
      </g>
    );
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 15) * cos;
    const my = cy + (outerRadius + 15) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1);
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${roundFloat(value)}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const getDashboardData = () => {
    setInitDashboard(true);
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data?.status === '0') {
        setDashboardData(data?.content?.data);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: {
        action_type: 'get_dashboard_data',
        month: data_date.month,
      },
    });
    dispatch(start_loading({}));
  };

  const calcDashboardData = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        getDashboardData();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tatvar_journal_burtgel',
      content: {
        action_type: 'get_hansh_tohirgoo_dun',
        month: data_date.month,
        is_dashboard: true,
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date && initDashboard) {
      getDashboardData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_date]);

  if (!dashboardData) {
    return (
      <div
        className="dashboard-dialog dashboard-dialog-center"
        onClick={() => getDashboardData()}
      >
        <Text>Дашборд ачааллах</Text>
      </div>
    );
  }

  return (
    <div className="temp-home-container">
      <div className="inside">
        <div className="inside-left">
          <div className="dashboard-dialog" onClick={() => calcDashboardData()}>
            <Text>Дашбордны дүн шинэчлэх</Text>
          </div>
          {dashboardData?.left_panel?.map((value, index) =>
            value?.tooltip ? (
              <Card className={`dashboard-card-${index}`}>
                <AntTooltip
                  title={
                    <div>
                      <Text>{value?.tooltip?.text}</Text>
                      {value?.tooltip?.extra ? (
                        value?.tooltip?.extra.map((extra_data) => (
                          <Paragraph>
                            {extra_data?.text} : {roundFloat(extra_data?.value)}
                          </Paragraph>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  placement="right"
                  overlayClassName="dashboard-card-tooltip"
                  color="white"
                >
                  <Statistic
                    title={value?.title}
                    value={value?.value}
                    precision={2}
                    suffix={value?.suffix}
                  />
                </AntTooltip>
              </Card>
            ) : (
              <Card className={`dashboard-card-${index}`}>
                <Statistic
                  title={value?.title}
                  value={value?.value}
                  precision={2}
                  suffix={value?.suffix}
                />
              </Card>
            )
          )}
        </div>
        <div className="inside-right">
          <div>
            <Row gutter={24}>
              {dashboardData?.right_panel?.top?.map((value) =>
                value?.tooltip ? (
                  <Col span={4}>
                    <Card>
                      <AntTooltip
                        title={
                          <div>
                            <Text>{value?.tooltip?.text}</Text>
                            {value?.tooltip?.extra ? (
                              value?.tooltip?.extra.map((extra_data) => (
                                <Paragraph>
                                  {extra_data?.text} :{' '}
                                  {roundFloat(extra_data?.value)}
                                </Paragraph>
                              ))
                            ) : (
                              <></>
                            )}
                          </div>
                        }
                        placement="bottom"
                        overlayClassName="dashboard-card-tooltip"
                        color="white"
                      >
                        <Statistic
                          title={value?.title}
                          value={value?.value}
                          precision={2}
                          suffix={value?.suffix}
                        />
                      </AntTooltip>
                    </Card>
                  </Col>
                ) : (
                  <Col span={4}>
                    <Card>
                      <Statistic
                        title={value?.title}
                        value={value?.value}
                        precision={2}
                        suffix={value?.suffix}
                      />
                    </Card>
                  </Col>
                )
              )}
            </Row>
          </div>
          <div>
            <Row gutter={24}>
              <Col span={10}>
                <div className="chart-container">
                  <Text className="chart-title">
                    ХОЙШЛОГДСОН ТАТВАРЫН ХӨРӨНГӨ
                  </Text>
                  <PieChart width={525} height={168}>
                    <Pie
                      data={dashboardData?.right_panel?.chart?.hoish_hurungu}
                      dataKey="value"
                      startAngle={180}
                      endAngle={0}
                      cx="50%"
                      cy="95%"
                      innerRadius={50}
                      outerRadius={90}
                      minAngle={3}
                      label={({
                        cx,
                        cy,
                        midAngle,
                        innerRadius,
                        outerRadius,
                        value,
                        index,
                      }) => {
                        const RADIAN = Math.PI / 180;
                        // eslint-disable-next-line
                        const radius =
                          25 + innerRadius + (outerRadius - innerRadius);
                        // eslint-disable-next-line
                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        // eslint-disable-next-line
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);
                        return (
                          <text
                            x={x}
                            y={y}
                            fill={COLORS[index % COLORS.length]}
                            textAnchor={x > cx ? 'start' : 'end'}
                            dominantBaseline="central"
                          >
                            {roundFloat(value)}
                          </text>
                        );
                      }}
                    >
                      {dashboardData?.right_panel?.chart?.hoish_hurungu?.map(
                        (_, index) => (
                          <Cell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        )
                      )}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                  </PieChart>
                </div>
              </Col>
              <Col span={10}>
                <div className="chart-container">
                  <Text className="chart-title">ХОЙШЛОГДСОН ТАТВАРЫН ӨР</Text>
                  <PieChart width={525} height={168}>
                    <Pie
                      data={dashboardData?.right_panel?.chart?.hoish_ur}
                      dataKey="value"
                      startAngle={180}
                      endAngle={0}
                      cx="50%"
                      cy="95%"
                      innerRadius={50}
                      outerRadius={90}
                      minAngle={3}
                      label={({
                        cx,
                        cy,
                        midAngle,
                        innerRadius,
                        outerRadius,
                        value,
                        index,
                      }) => {
                        const RADIAN = Math.PI / 180;
                        // eslint-disable-next-line
                        const radius =
                          25 + innerRadius + (outerRadius - innerRadius);
                        // eslint-disable-next-line
                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        // eslint-disable-next-line
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);
                        return (
                          <text
                            x={x}
                            y={y}
                            fill={COLORS_2[index % COLORS_2.length]}
                            textAnchor={x > cx ? 'start' : 'end'}
                            dominantBaseline="central"
                          >
                            {roundFloat(value)}
                          </text>
                        );
                      }}
                    >
                      {dashboardData?.right_panel?.chart?.hoish_ur?.map(
                        (_, index) => (
                          <Cell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cell-${index}`}
                            fill={COLORS_2[index % COLORS_2.length]}
                          />
                        )
                      )}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                  </PieChart>
                </div>
              </Col>
              <Col span={4}>
                {dashboardData?.right_panel?.special?.map((value) =>
                  value?.tooltip ? (
                    <Card className="dashboard-card-spec">
                      <AntTooltip
                        title={
                          <div>
                            <Text>{value?.tooltip?.text}</Text>
                            {value?.tooltip?.extra ? (
                              value?.tooltip?.extra.map((extra_data) => (
                                <Paragraph>
                                  {extra_data?.text} : {extra_data?.value}
                                </Paragraph>
                              ))
                            ) : (
                              <></>
                            )}
                          </div>
                        }
                        placement="left"
                        overlayClassName="dashboard-card-tooltip"
                        color="white"
                      >
                        <Statistic
                          title={value?.title}
                          value={value?.value}
                          precision={2}
                          suffix={value?.suffix}
                        />
                      </AntTooltip>
                    </Card>
                  ) : (
                    <Card className="dashboard-card-spec">
                      <Statistic
                        title={value?.title}
                        value={value?.value}
                        precision={2}
                        suffix={value?.suffix}
                      />
                    </Card>
                  )
                )}
              </Col>
              <Col span={9}>
                <div className="chart-container">
                  <Text className="chart-title">ОРЛОГО</Text>
                  <PieChart width={480} height={402}>
                    <Pie
                      data={dashboardData?.right_panel?.chart?.orlogo?.undsen}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      innerRadius={0}
                      outerRadius={85}
                      minAngle={5}
                    >
                      {dashboardData?.right_panel?.chart?.orlogo?.undsen?.map(
                        (data, index) => (
                          <Cell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cell-${index}`}
                            fill={data?.color}
                          />
                        )
                      )}
                    </Pie>
                    <Pie
                      activeIndex={activeIndex}
                      activeShape={renderActiveShape}
                      data={dashboardData?.right_panel?.chart?.orlogo?.zadargaa}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      innerRadius={90}
                      outerRadius={105}
                      minAngle={5}
                      onMouseEnter={onPieEnter}
                    >
                      {dashboardData?.right_panel?.chart?.orlogo?.zadargaa?.map(
                        (data, index) => (
                          <Cell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cell-${index}`}
                            fill={data?.color}
                          />
                        )
                      )}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      payload={dashboardData?.right_panel?.chart?.orlogo?.undsen?.map(
                        (item) => ({
                          id: item.name,
                          type: 'circle',
                          value: item.name,
                          color: item.color,
                        })
                      )}
                    />
                  </PieChart>
                </div>
              </Col>
              <Col span={9}>
                <div className="chart-container">
                  <Text className="chart-title">ЗАРДАЛ</Text>
                  <PieChart width={480} height={402}>
                    <Pie
                      data={dashboardData?.right_panel?.chart?.zardal?.undsen}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      innerRadius={0}
                      outerRadius={85}
                      minAngle={5}
                    >
                      {dashboardData?.right_panel?.chart?.zardal?.undsen?.map(
                        (data, index) => (
                          <Cell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cell-${index}`}
                            fill={data?.color}
                          />
                        )
                      )}
                    </Pie>
                    <Pie
                      activeIndex={activeIndexTwo}
                      activeShape={renderActiveShape}
                      data={dashboardData?.right_panel?.chart?.zardal?.zadargaa}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      innerRadius={90}
                      outerRadius={105}
                      minAngle={5}
                      onMouseEnter={onPieEnterTwo}
                    >
                      {dashboardData?.right_panel?.chart?.zardal?.zadargaa?.map(
                        (data, index) => (
                          <Cell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cell-${index}`}
                            fill={data?.color}
                          />
                        )
                      )}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      payload={dashboardData?.right_panel?.chart?.zardal?.undsen?.map(
                        (item) => ({
                          id: item.name,
                          type: 'circle',
                          value: item?.elegdel
                            ? `${
                                item.name
                              } [ТЗ байгуулсан элэгдлийн зөрүү: ${roundFloat(
                                item?.elegdel
                              )}]`
                            : item.name,
                          color: item.color,
                        })
                      )}
                    />
                  </PieChart>
                </div>
              </Col>
              <Col span={6}>
                <div className="chart-container">
                  <Text className="chart-title">ВАЛЮТЫН ХАНШИЙН ЗӨРҮҮ</Text>
                  <BarChart
                    width={285}
                    height={402}
                    data={dashboardData?.right_panel?.chart?.hansh}
                    stackOffset="sign"
                    margin={{
                      top: 15,
                      right: 5,
                      left: 55,
                      bottom: 10,
                    }}
                    barSize={30}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis orientation="left" tick={<CustomizedTick />} />
                    <Tooltip content={<CustomTooltipMulti />} />
                    <Legend />
                    <ReferenceLine y={0} stroke="#000" />
                    <Bar dataKey="Ашиг" fill="#3d74ac" stackId="stack" />
                    <Bar dataKey="Алдагдал" fill="#0094bc" stackId="stack" />
                  </BarChart>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
