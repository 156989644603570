export const searchList = [
  {
    type: 'input',
    title: 'Харилцагчийн нэр, РД',
    children: {
      queryKey: 'hariltsagch',
    },
  },
  {
    type: 'select',
    title: 'Тохируулга төрөл',
    children: {
      queryKey: 'tohiruulga',
      list: [
        {
          value: 'Бүгд',
          name: 'Бүгд',
        },
        {
          value: 'Хойшлуулах',
          name: 'Хойшлуулах',
        },
        {
          value: 'Илүү шивсэн',
          name: 'Илүү шивсэн',
        },
      ],
    },
  },
  {
    type: 'select',
    title: 'Зөрүү төрөл',
    children: {
      queryKey: 'zuruu',
      list: [
        {
          value: 'Бүгд',
          name: 'Бүгд',
        },
        {
          value: 'Илүү',
          name: 'Илүү',
        },
        {
          value: 'Дутуу',
          name: 'Дутуу',
        },
      ],
    },
  },
  {
    type: 'select',
    title: 'НӨАТ дүн',
    children: {
      queryKey: 'noat',
      list: [
        {
          value: 'Бүгд',
          name: 'Бүгд',
        },
        {
          value: 'НӨАТ-тэй',
          name: 'НӨАТ-тэй',
        },
        {
          value: 'НӨАТ-гүй',
          name: 'НӨАТ-гүй',
        },
      ],
    },
  },
];
