import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'src/core/utils/axios';

import { Table } from 'src/components';
import {
  Typography,
  Modal,
  Tooltip,
  Form,
  Row,
  Col,
  Input,
  Button,
  Divider,
} from 'antd';
import {
  PicLeftOutlined,
  CheckOutlined,
  ExportOutlined,
} from '@ant-design/icons';

// import { isEmpty } from 'lodash';
import newAlert from 'src/components/newAlert';
import { roundFloat, onlyNumber } from 'src/core/utils/formater';
import { customColumns, customSummary } from './dataStructure/data';
import { set_alert } from 'src/core/actions/alert';

import { start_loading, end_loading } from '../../../core/actions/loader';

const FileDownload = require('js-file-download');

const { Text } = Typography;

const MainPage = () => {
  const dispatch = useDispatch();
  const channel_list = {
    uh: {
      send_channel: 'ts003_api',
      receive_channel: 'ts001_02_tur_zuruu_control',
      action_type: 'set_tur_zuruu_zuvshuuruh',
    },
    avug: {
      send_channel: 'send_deferred_tax_by_account',
      receive_channel: 'ts001_02_tur_zuruu_control',
      action_type: 'set_tur_zuruu_zuvshuuruh',
    },
  };

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);
  const data_date = { month: '13' };

  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [dansInfo, setDansInfo] = useState(null);
  const [visible, setVisible] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [validEnabled, setValidEnabled] = useState(false);
  const [validtext, setValidText] = useState('');

  const [form] = Form.useForm();

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      const array = [];
      socket.removeAllListeners('request_out');

      if (data?.status === '0') {
        if ('json_array' in data?.content) {
          console.log(data?.content);
          
          data.content.json_array.hurungu.forEach((i) => {
            array.push(i);
          });
          array.push(data.content.sum_data_hurungu);
          data.content.json_array.ur_umch.forEach((i) => {
            array.push(i);
          });
          array.push(data.content.sum_data_ur_umch);
          data.content.json_array.busad_orlogo.forEach((i) => {
            array.push(i);
          });
          array.push(data.content.sum_data_busad_orlogo);

          setTableData(array);
          setTableDataSum(data.content.sum_data);
          setValidEnabled(data?.content?.validEnabled);
          setValidText(data?.content?.validTooltip);

          dispatch(set_alert({
            type: 'warning',
            msg: data?.content?.main_validtion.message,
            open: !data?.content?.main_validtion.validated,
          }));

        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_control',
      content: {
        action_type: 'get_tur_zuruu_hz_dun',
        month: String(month),
        first_year: true,
      },
    });

    dispatch(start_loading({}));
  };

  /**
   * Export tailan to xlsx format
   */
  const exportTailan = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        const file_export_address_link = `/get_file?file_id=${data.content.file_id}`;
        axios.get('export', file_export_address_link, {}, {}, (event) => {
          FileDownload(event, 'Түр зөрүү хүлээн зөвшөөрөх.xlsx');
        });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_control',
      content: {
        action_type: 'get_tur_zuruu_hz_dun',
        month: String(data_date.month),
        first_year: true,
        receive_channel: 'ss010_00_export_file',
        receive_action_type: 'export_dans_tur_zuruu',
      },
    });

    dispatch(start_loading({}));
  };

  const saveValues = (saveData) => {
    const tempData = saveData;
    delete tempData.dans_dugaar;
    delete tempData.dans_ner;
    delete tempData.dans_une;
    delete tempData.tatvar_suuri;
    delete tempData.zuruu;
    tempData.month = String(data_date.month);

    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan(data_date.month);
      } else if (data.status === '3') {
        newAlert({
          type: 'error',
          msg: 'Татварын шалгах балансын дүнг баталгаажуул уу !!',
        });
        dispatch(end_loading({}));
      } else if (data.status === '5') {
        newAlert({
          type: 'error',
          msg: 'Зөрүүгийн дүнгээс бага утга оруулна уу',
        });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_control',
      content: {
        action_type: 'set_tur_zuruu_hz_dun',
        data: tempData,
        first_year: true,
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
  }, []);

  const editAction = (row) => {
    setDansInfo(row);
    // Set form data
    form.setFieldsValue({
      dans_id: row?.dans_id,
      dans_dugaar: row?.dans_dugaar,
      dans_ner: row?.dans_ner,
      dans_turul: row?.dans_turul,
      dans_une: roundFloat(row?.dans_une),
      tatvar_suuri: roundFloat(row?.tatvar_suuri),
      zuruu: roundFloat(row?.zuruu),
      tatvar_zuruu: roundFloat(0.0),
      hasagdah_zuruu: roundFloat(0.0),
    });
    setVisible(true);
  };

  // useEffect(() => {
  //   if (!isEmpty(tableData)) {
  //     setLoading(false);
  //   } else {
  //     setLoading(true);
  //   }
  // }, [tableData]);

  // if (loading) {
  //   return 'Дата байхгүй...';
  // }

  const validateData = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай баталгаажлаа' });
        generateTailan(data_date.month);
      } else if (data.status === '3') {
        newAlert({
          type: 'error',
          msg: 'Татварын шалгах балансын дүнг баталгаажуул уу !!',
        });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_01_validate_company_data',
      content: {
        month: String(data_date.month),
        step_type: 'tur_zuruu',
      },
    });

    dispatch(start_loading({}));
  };

  const getValues = (action) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хуулагдлаа' });
        generateTailan(data_date.month);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: action?.send_channel,
      content: {
        return_channel: action?.receive_channel,
        month: String(data_date.month),
        action_type: action?.action_type,
      },
    });

    dispatch(start_loading({}));
  };

  return (
    <>
      <div className="tur-zuruu-temp-container">
        <div className="title">
          <Button
            className="add-btn"
            type="primary"
            size="small"
            icon={<ExportOutlined />}
            onClick={() => {
              exportTailan();
            }}
          >
            Экспорт
          </Button>
          <Button
            id="first"
            className="add-btn"
            type="primary"
            size="small"
            icon={<PicLeftOutlined />}
            disabled={!version?.is_first_year}
            onClick={() => {
              getValues(channel_list?.avug);
            }}
          >
            Дүн хуулах /Харилцагч/
          </Button>
          <Button
            id="second"
            className="add-btn"
            type="primary"
            size="small"
            icon={<PicLeftOutlined />}
            disabled={!version?.is_first_year}
            onClick={() => {
              getValues(channel_list?.uh);
            }}
          >
            Дүн хуулах /Үндсэн хөрөнгө/
          </Button>
          <Tooltip title={validtext}>
            <Button
              id="third"
              className="add-btn"
              type="primary"
              size="small"
              icon={<CheckOutlined />}
              onClick={() => {
                validateData();
              }}
              disabled={!validEnabled || !version?.is_first_year}
            >
              Дүн баталгаажуулах
            </Button>
          </Tooltip>
        </div>
        <div className="table-antd-div no-scroll">
          <Table
            columns={customColumns({
              customEdit: editAction,
              version,
            })}
            dataSource={tableData}
            scroll={{
              x: 1550,
              y: 'calc(100vh - 300px)',
            }}
            summaryList={{
              sumColumn: customSummary,
              sumDataSource: tableDataSum,
            }}
            // title={title}
          />
        </div>
      </div>
      <Modal
        className="custom-modal"
        title="Хүлээн зөвшөөрөгдөхгүй түр зөрүү бүртгэх"
        open={visible}
        closable={false}
        maskClosable={false}
        width={700}
        footer={null}
      >
        <CustomModal
          onClose={() => {
            setVisible(false);
          }}
          form={form}
          listData={dansInfo}
          saveValues={saveValues}
        />
      </Modal>
    </>
  );
};

const layout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 16 },
  },
};

const CustomModal = ({
  onClose = () => {},
  form = null,
  listData = {},
  saveValues = () => {},
}) => {
  const onFinish = (values) => {
    saveValues(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="dans_id" hidden />
        <Form.Item name="dans_turul" hidden />
        <Form.Item name="dans_dugaar" label="Дансны дугаар" labelAlign="left">
          <Text ellipsis>{form.getFieldValue('dans_dugaar')}</Text>
        </Form.Item>
        <Form.Item name="dans_ner" label="Дансны нэр" labelAlign="left">
          <Text ellipsis>{form.getFieldValue('dans_ner')}</Text>
        </Form.Item>
        <Divider />
        <Form.Item name="dans_une" label="Дансны үнэ" labelAlign="left">
          <Text>{form.getFieldValue('dans_une')}</Text>
        </Form.Item>
        <Form.Item name="tatvar_suuri" label="Татварын суурь" labelAlign="left">
          <Text>{form.getFieldValue('tatvar_suuri')}</Text>
        </Form.Item>
        <Form.Item name="zuruu" label="Зөрүү" labelAlign="left">
          <Text>{form.getFieldValue('zuruu')}</Text>
        </Form.Item>
        <Divider />
        <Form.Item
          name="hasagdah_zuruu"
          label="Хасагдах түр зөрүү"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input
            className="w-100 text-align-r"
            defaultValue="0.00"
            disabled={listData?.hasagdah_zuruu_disabled}
          />
        </Form.Item>
        <Form.Item
          name="tatvar_zuruu"
          label="Татвар ногдох түр зөрүү"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input
            className="w-100 text-align-r"
            defaultValue="0.00"
            disabled={listData?.tatvar_zuruu_disabled}
          />
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MainPage;
