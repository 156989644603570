import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';

const CanView = ({ allowedRole, children }) => {
  const permissionList = useSelector((state) => state.role);
  const permissionCodes = permissionList.map((p) => p.code);
  if (!allowedRole) return null;
  if (!permissionCodes.includes(allowedRole)) {
    return (
      <Tooltip
        title="Энэ үйлдлийг хийх эрхгүй байна"
        color="orange"
        className="custom-tooltip"
      >
        <div
          style={{
            cursor: 'not-allowed',
          }}
          id="target"
        >
          <div style={{ pointerEvents: 'none' }}>{children}</div>
        </div>
      </Tooltip>
    );
  }
  return <>{children}</>;
};

export default CanView;
