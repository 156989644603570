import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import DatePicker from 'src/components/common/datePicker';
import { onlyNumber } from 'src/core/utils/formater';
import {
  Typography,
  Button as AntButton,
  Form,
  Row,
  Col,
  Modal,
  Input,
  Divider,
} from 'antd';
import { Table } from 'src/components';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from '../../core/actions/loader';
import { AddBurtgelColumns } from './dataStructure/data';

const { Text } = Typography;

export const AddNewBurtgel = ({ openDialog, setOpenDialog, data }) => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);
  const [form] = Form.useForm();
  const dateFormat = 'YYYY/MM/DD';

  const [startDate, setStartDate] = useState(null);
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {
      burtgels:[],
    },
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });
  const [CurrentData, setCurrentData] = useState({
    ner: '',
    code: '',
    hurungu_id: '',
    burtgel_dugaar: '',
    ashiglaj_ehelsen: null,
    urtug_anh: 0.0,
    urtug_uldeh: 0.0,
    ashiglaj_duusah: null,
  });


  const select_data = (data) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: { 
        action_type: 'add_burtgel_barimt_jagsaalt',
        hurungu_id: data.hurungu_id },
    });
    dispatch(start_loading({}));
  };


  useEffect(() => {
    if (data) {
      if(openDialog){
        const cur_data = {
          ...CurrentData,
          hurungu_id: data.hurungu_id ? data.hurungu_id : data.id,
          code: data.code,
          ner: data.ner,
        };
        setCurrentData(cur_data);
        form.setFieldsValue({
          ...CurrentData,
        });
        select_data(cur_data);
      }
     
    }
  }, [openDialog]);

  const handleDialogClose = () => {
    form.resetFields();
    setOpenDialog(false);
  };

  const handleSave = (values) => {
    const new_data = {
      ...CurrentData,
      burtgel_dugaar: values.burtgel_dugaar,
      ashiglaj_ehelsen: moment(values.ashiglaj_ehelsen).format('YYYY/MM/DD'),
      urtug_anh: values.urtug_anh,
      urtug_uldeh: values.urtug_uldeh,
      ashiglaj_duusah: moment(values.ashiglaj_duusah).format('YYYY/MM/DD'),
    };
    socket.on('request_out', (req_data) => {
      socket.removeAllListeners('request_out');
      if (req_data.status === '0') {
        handleDialogClose();
        newAlert({ type: 'success', msg: req_data.message });
      } else {
        newAlert({ type: 'error', msg: req_data.message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: {
        action_type: 'insert_undsen_hurungu_burtgel',
        json_array: { ...new_data },
      },
    });
    dispatch(start_loading({}));
  };

  const onFinish = (values) => {
    console.log(tableData.result_json.burtgels);
    let msg_list = [];
  
    // Check if burtgel_dugaar is already registered
    if (tableData.result_json.burtgels.includes(values.burtgel_dugaar)) {
      msg_list.push('Бүртгэлийн дугаар бүртгэгдсэн байна.');
    }
    
    // Get date fields from the form
    const ashiglaj_duusah = form.getFieldValue('ashiglaj_duusah'); // Ensure correct retrieval
    const ashiglaj_ehelsen = form.getFieldValue('ashiglaj_ehelsen');

    // Check if both dates are valid and if ashiglaj_duusah is before or the same as ashiglaj_ehelsen
    if (ashiglaj_duusah && ashiglaj_ehelsen) {
      const duusahMoment = moment(ashiglaj_duusah);
      const ehelsenMoment = moment(ashiglaj_ehelsen);
  
      // Use moment.js methods for comparison
      if (duusahMoment.isSameOrBefore(ehelsenMoment)) {
        msg_list.push('Ашиглаж дуусах хугацаа ашиглаж эхлэх хугацаанаас бага байна.');
      }
    }
    console.log(msg_list  );
    // If there are any errors, show the error alert
    if (msg_list.length > 0) {
      newAlert({ type: 'error', msg: msg_list.join(' ') });
    } else {
      // No errors, proceed with save
      handleSave(values);
    }
  };

  const rowSelect = (rowData) => {
    const cur_data = {
      ...CurrentData,
    burtgel_dugaar:  form.getFieldValue('burtgel_dugaar'),
    ashiglaj_duusah:  form.getFieldValue('ashiglaj_duusah'),
    ashiglaj_ehelsen:rowData.ognoo,
    urtug_anh:rowData.deb_dun,
    };
    setCurrentData(cur_data);
    form.setFieldsValue({
      ...cur_data,
    });
  };

  const layout = {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 16 },
    },
  };

  const customTitle = () => (
   
    <div className="table-title-text-center">
      Дебет бичилтүүд
    </div>
  );

  return (
    <Modal
      className="custom-modal"
      title="Бүртгэлийн дугаар үүсгэх"
      open={openDialog}
      closable={false}
      maskClosable={false}
      width={600}
      footer={null}
      onCancel={handleDialogClose}
    >
      <div className="dans-medeelel-container">
        <Form
          form={form}
          onFinish={onFinish}
          className="register-form"
          {...layout}
          size="small"
        >
          <Form.Item label="Үндсэн хөрөнгийн нэр" labelAlign="left">
            <Text ellipsis>{CurrentData?.ner}</Text>
          </Form.Item>
          <Form.Item label="Үндсэн хөрөнгийн код" labelAlign="left">
            <Text>{CurrentData?.code}</Text>
          </Form.Item>
          <Form.Item
            name="burtgel_dugaar"
            label="Бүртгэлийн дугаар"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input
              placeholder="Бүртгэлийн дугаар оруулна уу..."
              className="w-100"
            />
          </Form.Item>
          <Form.Item
            name="urtug_anh"
            label="Анхны өртөг"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
            normalize={onlyNumber}
          >
            <Input
              placeholder="Дүн оруулна уу..."
              className="w-100"
              defaultValue="0.00"
            />
          </Form.Item>
          <Form.Item
            name="urtug_uldeh"
            label="Үлдэх өртөг"
            labelAlign="left"
            normalize={onlyNumber}
          >
            <Input
              placeholder="Дүн оруулна уу..."
              className="w-100"
              defaultValue="0.00"
            />
          </Form.Item>
          <Divider plain>Ашиглах хугацаа</Divider>
          <Form.Item
            name="ashiglaj_ehelsen"
            label="Эхлэх"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <DatePicker
              customClass="h-25"
              onChange={(date) => {
                setStartDate(new Date(date));
                form.setFieldsValue({
                  ...form.setFieldsValue(),
                  ashiglaj_duusah: date,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name="ashiglaj_duusah"
            label="Дуусах"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <DatePicker customClass="h-25" minDate={startDate} />
          </Form.Item>

          {!version.is_first_year && (<div className="table-antd-div no-scroll">
            <Table
              title={customTitle}
              columns={AddBurtgelColumns({rowSelect})}
              total={tableData.total_size}
              dataSource={tableData.result_list}
              scroll={{
                y: 'calc(100vh - 330px)',
              }}
            />
          </div>)}
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <AntButton
                  onClick={() => {
                    form.resetFields();
                    handleDialogClose();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </AntButton>
                <AntButton
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </AntButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
