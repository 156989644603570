import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Row, Col, Button, Checkbox, Modal } from 'antd';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';

const { Option } = Select;

const NewUpdateDialog = ({
  listDef = null,
  form = null,
  onCancel = {},
  open = false,
}) => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);

  const [notTax, seTnotTax] = useState({ value: false, check: true });
  const [merge, seTmerge] = useState({ value: false, check: true });

  const handleSave = (values) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: data.message });
      } else {
        newAlert({ type: 'error', msg: data.message });
      }
      onCancel('visible1');
      dispatch(end_loading({}));
    });
    const temp_data = {
      ...values,
      code: form.getFieldValue('code'),
      old_code: form.getFieldValue('old_code'),
      not_tax: !notTax.value,
      merge: !merge.value,
    };
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: {
        action_type: 'undsen_medeelel_hurungu_one',
        json_array: { ...temp_data },
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (open) {
      seTnotTax({
        value: !form.getFieldValue('not_tax'),
        check: false,
      });
      seTmerge({
        value: !form.getFieldValue('merge_burtgel'),
        check: false,
      });
    }
  }, [open]);

  const onFinish = (values) => {
    handleSave(values);
  };

  const layout = {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 16 },
    },
  };

  return (
    <Modal
      className="custom-modal"
      title={`Үндсэн хөрөнгийн код : ${form.getFieldValue('code') || '---'}`}
      open={open}
      closable={false}
      maskClosable={false}
      width={600}
      footer={null}
    >
      <div className="dans-medeelel-container">
        <Form
          form={form}
          onFinish={onFinish}
          className="register-form"
          {...layout}
          size="small"
        >
          <Form.Item
            name="code"
            label="Хөрөнгийн код"
            labelAlign="left"
            rules={[{ required: true, message: '' }]}
          >
            <Input className="w-100" />
          </Form.Item>
          <Form.Item
            name="ner"
            label="Хөрөнгийн нэр"
            labelAlign="left"
            rules={[{ required: true, message: '' }]}
          >
            <Input className="w-100" />
          </Form.Item>
          <Form.Item
            name="hemjih_negj"
            label="Хэмжих нэгж"
            labelAlign="left"
            rules={[{ required: true, message: '' }]}
          >
            <Select
              placeholder="Сонгох"
              style={{ width: '100%', fontSize: 13 }}
            >
              {get(listDef, 'hemjih_negj', []).map((i) => (
                <Option value={String(i)}>{i}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="hurungu_turul"
            label="Хөрөнгийн төрөл"
            labelAlign="left"
            rules={[{ required: true, message: '' }]}
          >
            <Select
              placeholder="Сонгох"
              style={{ width: '100%', fontSize: 13 }}
            >
              {[
                { text: 'Үл хөдлөх', val: 'ul_hudluh' },
                { text: 'Хөдлөх', val: 'hudluh' },
              ].map((i) => (
                <Option value={i?.val}>{i?.text}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="hurungu_buleg_dugaar"
            label="Хөрөнгийн бүлэг"
            labelAlign="left"
            rules={[{ required: true, message: '' }]}
          >
            <Select
              placeholder="Сонгох"
              style={{ width: '100%', fontSize: 13 }}
            >
              {get(listDef, 'buleg', []).map((i) => (
                <Option value={i?.val}>{i?.text}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Зөрүү тооцох" labelAlign="left">
            <Checkbox
              checked={notTax.value}
              disabled={notTax.check}
              onClick={() => {
                seTnotTax({ ...notTax, value: !notTax.value });
              }}
            />
          </Form.Item>
          <Form.Item label="Бүртгэл задлах" labelAlign="left">
            <Checkbox
              checked={merge.value}
              disabled={merge.check}
              onClick={() => {
                seTmerge({ ...merge, value: !merge.value });
              }}
            />
          </Form.Item>
          <Form.Item label="Анхны өртөг" labelAlign="left">
            {form.getFieldValue('urtug_anh')}
          </Form.Item>
          <Form.Item label="Үлдэх өртөг" labelAlign="left">
            {form.getFieldValue('urtug_uldeh')}
          </Form.Item>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => {
                  onCancel('visible1');
                }}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Буцах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default NewUpdateDialog;
