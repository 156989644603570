const oDefaultState = {
  count: 0,
  data: {
    test: 'bla bla',
  },
};

const reducer = (state = oDefaultState, action) => {
  switch (action.type) {
    case 'ADD':
      const newState = { ...state, count: state.count + action.payload };
      return newState;
    case 'SUB':
      const newState2 = { ...state, count: state.count - action.payload };
      return newState2;
    case 'WS_CONNECT':
      const newState3 = { ...state, count: 100 };
      return newState3;
    default:
      return state;
  }
};

export default reducer;
