import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'src/components';
import newAlert from 'src/components/newAlert';
import { customColumns, customSummary } from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';

const MainPage = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const data_date = { month: '13' };

  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      const array = [];

      if (data?.status === '0') {
        if ('json_array' in data?.content) {
          data.content.json_array.hurungu.forEach((i) => {
            array.push(i);
          });
          array.push(data.content.sum_data_hurungu);
          data.content.json_array.ur_umch.forEach((i) => {
            array.push(i);
          });
          array.push(data.content.sum_data_ur_umch);
          data.content.json_array.busad_orlogo.forEach((i) => {
            array.push(i);
          });
          array.push(data.content.sum_data_busad_orlogo);
          data.content.json_array.footer.forEach((i) => {
            array.push(i);
          });

          setTableData(array);
          setTableDataSum(data.content.sum_data);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_control',
      content: {
        action_type: 'get_ht_ur_umch',
        month: String(month),
        first_year: true,
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
  }, []);

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          columns={customColumns()}
          dataSource={tableData}
          scroll={{
            y: 'calc(100vh - 260px)',
          }}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableDataSum,
          }}
        />
      </div>
    </>
  );
};

export default MainPage;
