import React from 'react';
import { roundFloat } from 'src/core/utils/formater';

import { Button } from 'antd';

export const dans_columns = ({ handleSelectDans = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_code',
    align: 'left',
    width: 250,
    render: (_, row) => (
      <Button
        type="link"
        size="small"
        className="underline"
        onClick={() => handleSelectDans(row?.dans_code)}
      >
        {row?.dans_code}
      </Button>
    ),
  },
  {
    title: '',
  },
  {
    title: 'Эхний үлдэгдэл',
    dataIndex: 'ets_uld',
    align: 'right',
    width: 200,
    render: (_, row) => (
      <>
        <p>{roundFloat(row?.ets_uld)}</p>
        <p className="bg-highlight">{roundFloat(row?.ets_uld_valiut)}</p>
      </>
    ),
  },
];

export const hariltsagch_columns = [
  {
    title: 'Харилцагч',
    align: 'center',
    children: [
      {
        title: 'Код',
        dataIndex: 'hariltsagch_code',
        width: 100,
        align: 'left',
      },
      {
        title: 'Нэр',
        dataIndex: 'hariltsagch_ner',
        width: 350,
        align: 'left',
        ellipsis: true,
      },
    ],
  },
  {
    title: '',
  },
  {
    title: 'Эхний үлдэгдэл',
    dataIndex: 'ets_uld',
    align: 'right',
    width: 200,
    render: (_, row) => (
      <>
        <p>{roundFloat(row?.ets_uld)}</p>
        <p className="bg-highlight">{roundFloat(row?.ets_uld_valiut)}</p>
      </>
    ),
  },
];
