import React from 'react';
import { Select, Empty } from 'antd';
import { onFilter } from 'src/core/utils/selectFilter';

const { Option } = Select;

const empty = (
  <div style={{ textAlign: 'center' }}>
    <Empty description="Дата байхгүй" />
  </div>
);

const CustomSelect = ({
  onChange = () => {},
  size = 'middle',
  className = '',
  list = [],
  valueProp = '',
  textProp = '',
  customText = () => {},
  value = '',
  defaultValue = '',
}) => {
  if (value || defaultValue) {
    return (
      <Select
        size={size}
        value={value || defaultValue}
        className={className}
        showSearch
        onChange={onChange}
        filterOption={onFilter}
        notFoundContent={empty}
      >
        {list.map((i) => (
          <Option value={i[valueProp]}>
            {textProp ? i[textProp] : customText(i)}
          </Option>
        ))}
      </Select>
    );
  }

  return (
    <Select
      size={size}
      className={className}
      placeholder="Сонгох"
      showSearch
      onChange={onChange}
      filterOption={onFilter}
      notFoundContent={empty}
    >
      {list.map((i) => (
        <Option value={i[valueProp]}>
          {textProp ? i[textProp] : customText(i)}
        </Option>
      ))}
    </Select>
  );
};

export default CustomSelect;
