import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { Button } from 'antd';

export const dans_columns = (handleSelectDans = () => {}) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_code',
    width: 300,
    align: 'left',
    fixed: 'left',
    render: (_, row) => (
      <>
        {row?.mor_type === 'sub' ? (
          <Button
            onClick={() => handleSelectDans(row?.dans_code)}
            type="link"
            size="small"
            className="underline"
          >
            {row?.dans_code}
          </Button>
        ) : (
          row?.dans_code
        )}
      </>
    ),
  },
  {
    title: 'Хасагдах түр зөрүү Эхний үлдэгдэл',
    dataIndex: 'eh_uld_tur_zuruu',
    width: 150,
    align: 'right',
    render: (_, row) => {
      if (row?.mor_type === 'percent') {
        return <p>{roundFloat(row?.eh_uld_tur_zuruu)} %</p>;
      }
      if (row?.mor_type === 'sum') {
        return <p>{roundFloat(row?.eh_uld_tur_zuruu)}</p>;
      }
      return roundFloat(row?.eh_uld_tur_zuruu);
    },
  },
  {
    title: 'Эхний үлдэгдлийн залруулга',
    dataIndex: 'zalruulga',
    width: 150,
    align: 'right',
    render: (_, row) => {
      if (row?.mor_type === 'percent') {
        return '--';
      }
      if (row?.mor_type === 'sum') {
        return <p>{roundFloat(row?.zalruulga)}</p>;
      }
      return roundFloat(row?.zalruulga);
    },
  },
  {
    title: 'Дебет',
    dataIndex: 'deb_deferred_tax',
    width: 150,
    align: 'right',
    render: (_, row) => {
      if (row?.mor_type === 'percent') {
        return <p>{roundFloat(row?.deb_tur_zuruu)} %</p>;
      }
      if (row?.mor_type === 'sum') {
        return <p>{roundFloat(row?.deb_deferred_tax)}</p>;
      }
      return roundFloat(row?.deb_deferred_tax);
    },
  },
  {
    title: 'Кредит',
    dataIndex: 'cred_deferred_tax',
    width: 150,
    align: 'right',
    render: (_, row) => {
      if (row?.mor_type === 'percent') {
        return <p>{roundFloat(row?.cred_tur_zuruu)} %</p>;
      }
      if (row?.mor_type === 'sum') {
        return <p>{roundFloat(row?.cred_deferred_tax)}</p>;
      }
      return roundFloat(row?.cred_deferred_tax);
    },
  },
  {
    title: 'Хасагдах түр зөрүү Эцсийн үлдэгдэл',
    dataIndex: 'ets_uld_tur_zuruu',
    width: 150,
    align: 'right',
    render: (_, row) => {
      if (row?.mor_type === 'percent') {
        return <p>{roundFloat(row?.ets_uld_tur_zuruu)} %</p>;
      }
      if (row?.mor_type === 'sum') {
        return <p>{roundFloat(row?.ets_uld_tur_zuruu)}</p>;
      }
      return roundFloat(row?.ets_uld_tur_zuruu);
    },
  },
];

export const hariltsagch_columns = [
  {
    title: 'Харилцагч',
    children: [
      {
        title: 'Код',
        dataIndex: 'hariltsagch_code',
        align: 'left',
        width: 150,
        render: (_, row) => {
          if (row?.mor_type === 'percent' || row?.mor_type === 'sum') {
            return {
              children: (
                <p style={{ textAlign: 'left' }}>{row?.hariltsagch_code}</p>
              ),
              props: {
                colSpan: 2,
              },
            };
          }
          return row?.hariltsagch_code;
        },
      },
      {
        title: 'Нэр',
        dataIndex: 'hariltsagch_ner',
        align: 'left',
        width: 250,
        ellipsis: 'true',
      },
    ],
  },
  {
    title: 'Хасагдах түр зөрүү Эхний үлдэгдэл',
    dataIndex: 'eh_uld_tur_zuruu',
    align: 'right',
    width: 150,
    render: (_, row) => roundFloat(row?.eh_uld_tur_zuruu),
  },
  {
    title: 'Эхний үлдэгдлийн залруулга',
    dataIndex: 'zalruulga',
    align: 'right',
    width: 150,
    render: (_, row) => roundFloat(row?.zalruulga),
  },
  {
    title: 'Дебет',
    dataIndex: 'deb_deferred_tax',
    align: 'right',
    width: 150,
    render: (_, row) => roundFloat(row?.deb_deferred_tax),
  },
  {
    title: 'Кредит',
    dataIndex: 'cred_deferred_tax',
    align: 'right',
    width: 150,
    render: (_, row) => roundFloat(row?.cred_deferred_tax),
  },
  {
    title: 'Хасагдах түр зөрүү Эцсийн үлдэгдэл',
    dataIndex: 'ets_uld_tur_zuruu',
    align: 'right',
    width: 150,
    render: (_, row) => roundFloat(row?.ets_uld_tur_zuruu),
  },
];

export const hariltsagch_sum_columns = [
  {
    word: '',
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'eh_uld_tur_zuruu',
  },
  {
    align: 'right',
    value: 'zalruulga',
  },
  {
    align: 'right',
    value: 'deb_deferred_tax',
  },
  {
    align: 'right',
    value: 'cred_deferred_tax',
  },
  {
    align: 'right',
    value: 'ets_uld_tur_zuruu',
  },
];
