import { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import jwt from 'jwt-decode';

export const useHubspotChat = (portalId) => {
  const eventRef = useRef(null);
  const jwt_token = useSelector((state) => state.jwt_token);
  const [hubSpotLoaded, setHubSpotLoaded] = useState(false);

  const connectHubSpot = (user_state, company_state) => {
    window.hsConversationsSettings = { loadImmediately: false };
    window.hsConversationsOnReady = [
      () => {
        setHubSpotLoaded(true);
        if (window.HubSpotConversations) {
          if (user_state === 1 && company_state === 1) {
            window.HubSpotConversations.widget.load();
          } else if (user_state === 1 && company_state === 0) {
            window.HubSpotConversations.widget.remove();
          } else {
            window.HubSpotConversations.widget.remove({ resetWidget: true });
          }
        }
      },
    ];

    const script = document.createElement('script');
    script.id = 'hubSpot';
    script.src = `//js.hs-scripts.com/${portalId}.js`;
    script.async = true;

    if (!document.getElementById('hubSpot')) {
      document.body.appendChild(script);
    }

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
      window.hsConversationsOnReady = [];
    };
  };

  const addWidget = useCallback(() => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.load();
    }
  }, []);

  const closeWidget = useCallback(() => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove();
    }
  }, []);

  const removeWidget = useCallback(() => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove({ resetWidget: true });
    }
  }, []);

  useEffect(() => {
    const { jwt_1: jwt_1_TEMP } = jwt_token;
    let jwt_decoded = null;
    if (jwt_1_TEMP) {
      jwt_decoded = jwt(jwt_1_TEMP);
    }
    if (!jwt_decoded) {
      closeWidget();
      setHubSpotLoaded(false);
    } else {
      connectHubSpot(jwt_decoded?.user_state, jwt_decoded?.company_state);
    }
  }, [jwt_token]);

  useEffect(() => {
    eventRef.current = () => {
      // setActiveConversation(payload.conversation.conversationId);
    };
    if (hubSpotLoaded) {
      window.HubSpotConversations.on('conversationStarted', eventRef.current);
    }
    return () => {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.off(
          'conversationStarted',
          eventRef.current
        );
      }
    };
  }, [hubSpotLoaded]);

  return {
    hubSpotLoaded,
    removeWidget,
    addWidget,
    closeWidget,
    connectHubSpot,
  };
};
