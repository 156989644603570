import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { roundFloat } from 'src/core/utils/formater';
import { customConfirm } from 'src/components/common/confirmModal';
import CanView from 'src/components/permission/canview';

const types_list = {
  1: '24-Ашигт малтмалаас бусад байгалийн баялаг ашигласны төлбөр, хураамж',
  2: '25-Ашигт малтмалын нөөц ашигласны төлбөр',
  3: '26-Газрын төлбөр',
  4: '27-Агаарын бохирдлын төлбөр',
  5: '28-Автотээвэр болон өөрөө явагч хэрэгслийн албан татвар',
  6: '29-Үл хөдлөх эд хөрөнгийн албан татвар',
  7: '30-Онцгой албан татвар',
};

export const columns = ({ delete_row = () => {} }) => [
  {
    title: 'Огноо',
    align: 'center',
    dataIndex: 'ognoo',
    width: 100,
  },
  {
    title: 'Татварын төрөл',
    align: 'left',
    titleAlign: 'center',
    className: 'fixed-150',
    render: (_, row) => types_list[row.torol],
    ellipsis: true,
  },
  {
    title: 'Дүн',
    align: 'right',
    className: 'children-25',
    titleAlign: 'center',
    dataIndex: 'dun',
    render: (_, row) => roundFloat(row.dun),
  },
  {
    title: 'Тайлбар',
    align: 'left',
    titleAlign: 'center',
    className: 'fixed-150',
    dataIndex: 'tailbar',
    ellipsis: true,
  },
  {
    title: 'Устгах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    render: (_, row) => (
      <Tooltip title="Устгах">
        <DeleteOutlined
          onClick={() =>
            customConfirm({
              onOk: () => delete_row(row?.id),
            })
          }
        />
      </Tooltip>
    ),
  },
];

export const customSummary = [
  {
    align: 'center',
    word: '',
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'total',
  },
  {
    align: 'center',
    word: '',
  },
  {
    align: 'center',
  },
  {
    align: 'center',
  },
  {
    align: 'center',
  },
];
