import React from 'react';
import { roundFloat } from 'src/core/utils/formater';

export const columns = () => [
  {
    title: 'Татварын жил',
    dataIndex: 'ognoo',
    align: 'center',
    titleAlign: 'center',
    width: '8vw',
  },
  {
    title: 'Утга',
    dataIndex: 'utga',
    align: 'right',
    titleAlign: 'center',
    width: '8vw',
  },
  {
    title: 'Эхний үлдэгдэл',
    dataIndex: 'eh_uld',
    align: 'right',
    titleAlign: 'center',
    width: '8vw',
    render: (_, row) => roundFloat(row?.eh_uld),
  },
  {
    title: 'Өссөн',
    dataIndex: 'osson',
    align: 'right',
    titleAlign: 'center',
    width: '8vw',
    render: (_, row) => roundFloat(row?.osson),
  },
  {
    title: 'Буурсан',
    dataIndex: 'buursan',
    align: 'right',
    titleAlign: 'center',
    width: '8vw',
    render: (_, row) => roundFloat(row?.buursan),
  },
  {
    title: 'Эцсийн үлдэгдэл',
    dataIndex: 'ets_uld',
    align: 'right',
    titleAlign: 'center',
    width: '8vw',
    render: (_, row) => roundFloat(row?.ets_uld),
  },
  {
    title: 'Цаашид ашиглах жил',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: '4',
        dataIndex: 'year_4',
        align: 'right',
        titleAlign: 'center',
        width: 120,
        render: (_, row) => roundFloat(row?.year_4),
      },
      {
        title: '3',
        dataIndex: 'year_3',
        align: 'right',
        titleAlign: 'center',
        width: 120,
        render: (_, row) => roundFloat(row?.year_3),
      },
      {
        title: '2',
        dataIndex: 'year_2',
        align: 'right',
        titleAlign: 'center',
        width: 120,
        render: (_, row) => roundFloat(row?.year_2),
      },
      {
        title: '1',
        dataIndex: 'year_1',
        align: 'right',
        titleAlign: 'center',
        width: 120,
        render: (_, row) => roundFloat(row?.year_1),
      },
    ],
  },
  {
    title: 'Шилжигдээгүй алдагдал',
    dataIndex: 'shiljeegui_ald',
    align: 'right',
    titleAlign: 'center',
    width: '8vw',
    render: (_, row) => roundFloat(row?.shiljeegui_ald),
  },
];
