import { roundFloat } from 'src/core/utils/formater';

const setRound = (_) => roundFloat(_);
export const exampleColumn = [
  {
    title: 'Үзүүлэлт',
    dataIndex: 'uzuulelt',
    align: 'center',
    key: 'uzuulelt',
    render: (_, row, index) => _,
  },
  {
    width: 50,
  },
  {
    width: 50,
  },
  {
    title: 'Мөр',
    dataIndex: 'mur',
    align: 'left',
    key: 'mur',
    width: 50,
  },
  {
    title: 'Дүн',
    dataIndex: 'dun',
    titleAlign: 'center',
    align: 'center',
    key: 'dun',
    width: 150,
    render: setRound,
  },
];

export const expandColumns = ({ title = 'no Title' }) => [
  {
    key: 'title',
    align: 'center',
    render: (_, row, index) => {
      const obj = {
        children: title,
        props: {},
      };
      if (!index) {
        if (parseInt(row.mur) > 10 && parseInt(row.mur) < 25) {
          obj.props.rowSpan = 14;
        } else if (parseInt(row.mur) < 10 && parseInt(row.mur) > 4) {
          obj.props.rowSpan = 5;
        } else if (parseInt(row.mur) < 37 && parseInt(row.mur) > 33) {
          obj.props.rowSpan = 3;
        } else if (parseInt(row.mur) < 49 && parseInt(row.mur) > 43) {
          obj.props.rowSpan = 5;
        } else if (parseInt(row.mur) < 66 && parseInt(row.mur) > 61) {
          obj.props.rowSpan = 5;
        }
      }
      if (index) {
        obj.props.rowSpan = 0;
      }
      return obj;
    },
  },
  {
    dataIndex: 'lvl1',
    align: 'left',
    key: 'mur',
  },
  {
    dataIndex: 'lvl3',
    align: 'left',
    key: 'mur',
    render: (value, row, index) => {
      if (!row?.mur) {
        const obj = {
          children: value,
          props: {},
        };
        obj.props.rowSpan = 6;
        return obj;
      }
      return value;
    },
  },
  {
    dataIndex: 'mur',
    align: 'center',
    key: 'mur',
    width: 50,
  },
  {
    dataIndex: 'dun',
    titleAlign: 'center',
    align: 'center',
    key: 'dun',
    width: 150,
    render: (value, row, index) => {
      if (parseInt(row?.mur) === 37) {
        const obj = {
          children: value,
          props: {},
        };
        obj.props.rowSpan = 4;
        return obj;
      }
      if (parseInt(row?.mur) > 37 && parseInt(row?.mur) < 41) {
        const obj = {
          children: roundFloat(value),
          props: { rowSpan: 0 },
        };
        return obj;
      }
      return roundFloat(value);
    },
  },
];

export const expandColumn1 = [
  {
    dataIndex: 'title',
    align: 'left',
    key: 'mur',
    width: 50,
    className: 'fw-600',
    render: (value) => {
      if (value) {
        const obj = {
          children: value,
          props: {},
        };
        obj.props.colSpan = 3;
        return obj;
      }
      return value;
    },
  },
  {
    dataIndex: 'lvl2',
    align: 'left',
    key: 'mur',
    width: 50,
    render: (value, row) => {
      if (row?.title) {
        const obj = {
          children: value,
          props: {},
        };
        obj.props.colSpan = 0;
        return obj;
      }
      if (value) {
        const obj = {
          children: value,
          props: {},
        };
        obj.props.colSpan = 2;
        return obj;
      }
      return value;
    },
  },
  {
    dataIndex: 'lvl3',
    align: 'left',
    key: 'mur',
    render: (value, row, index) => {
      if (row?.title) {
        const obj = {
          children: value,
          props: {},
        };

        obj.props.colSpan = 0;
        return obj;
      }
      if (row?.lvl2) {
        const obj = {
          children: value,
          props: {},
        };

        obj.props.colSpan = 0;
        return obj;
      }
      return value;
    },
  },
  {
    dataIndex: 'mur',
    align: 'center',
    key: 'mur',
    width: 50,
  },
  {
    dataIndex: 'dun',
    titleAlign: 'center',
    align: 'center',
    key: 'dun',
    width: 150,
    render: setRound,
  },
];
export const temp_data = [
  {
    uzuulelt: 'Тухайн сарын нийт борлуулалтын орлого (2+3)',
    mur: '1',
    dun: 480000.0,
  },
  {
    uzuulelt:
      'Хуулийн 13 дугаар зүйлд заасан тухайн сарын НӨАТ-аас чөлөөлөгдөх борлуулалт /хавсралтаас өөрт хамаарах чөлөөлөгдөх борлуулалтын орлогыг сонгоно',
    mur: '2',
    dun: 480000.0,
  },
  {
    uzuulelt:
      'Нэмэгдсэн өртгийн албан татвар ногдох бараа, ажил, үйлчилгээний нийт борлуулалтын орлого (4+10+29)',
    mur: '3',
    dun: 480000.0,
  },
  {
    uzuulelt: 'НӨАТ ногдох бараа борлуулсны орлого (5+...+9)',
    mur: '4',
    dun: 480000.0,
    subRow: {
      title: 'Үүнээс',
      child: [
        {
          lvl3: 'Дотоодын зах зээлд борлуулсан барааны борлуулалтын орлого',
          mur: '5',
          dun: 10000.0,
        },
        {
          lvl3: 'Бусад барааны борлуулалтын орлого',
          mur: '6',
          dun: 20000.0,
        },
        {
          lvl3: 'Эрх борлуулсны орлого',
          mur: '7',
          dun: 30000.0,
        },
        {
          lvl3: 'Татан буугдах үед өөрт үлдээсэн барааны орлого',
          mur: '8',
          dun: 40000.0,
        },
        {
          lvl3: 'Өрийн төлбөрт шилжүүлсэн барааны орлого',
          mur: '9',
          dun: 50000.0,
        },
      ],
    },
  },
  {
    uzuulelt: 'НӨАТ ногдох ажил, үйлчилгээний орлого (11+...+24)',
    mur: '19',
    dun: 60000.0,
    subRow: {
      title: 'Үүнээс',
      child: [
        {
          lvl3: 'Өрийн төлбөрт тооцсон ажил гүйцэтгэх, үйлчилгээ үзүүлсний орлого',
          mur: '11',
          dun: 70000.0,
        },
        {
          lvl3: 'Цахилгаан, дулаан, хий, ус хангамж, ариутгах татуурга, шуудан, харилцаа холбооны болон бусад үйлчилгээ үзүүлсний орлого',
          mur: '12',
          dun: 80000.0,
        },
        {
          lvl3: 'Бараа түрээслүүлэх, бусад хэлбэрээр эзэмшүүлэх, ашиглуулах үйлчилгээний орлого',
          mur: '13',
          dun: 90000.0,
        },
        {
          lvl3: 'Зочид буудал буюу түүнтэй адилтгах байранд байр түрээслүүлэх, бусад хэлбэрээр эзэмшүүлэх, ашиглуулах үйлчилгээний орлого',
          mur: '14',
          dun: 100000.0,
        },
        {
          lvl3: 'Үл хөдлөх, хөдлөх эд хөрөнгө түрээслүүлэх буюу бусад хэлбэрээр эзэмшүүлэх ашиглуулах үйлчилгээий орлого',
          mur: '15',
          dun: 110000.0,
        },
        {
          lvl3: 'Шинэ бүтээл, бүтээгдэхүүний загвар, ашигтай загвар, зохиогчийн эрхэд хамаарах бүтээл, программ хангамж,барааны тэмдэг, ноу-хау, хөрөнгийн мэдээллийг шилжүүлсэн, түрээсэлсэн, худалдсаны орлого',
          mur: '16',
          dun: 120000.0,
        },
        {
          lvl3: 'Эд мөнгөний хонжворт сугалаатөлбөрт таавар бооцоот тоглоомын үйл ажиллагааны орлого',
          mur: '17',
          dun: 130000.0,
        },
        {
          lvl3: 'Зуучлалын үйлчилгээний орлого',
          mur: '18',
          dun: 140000.0,
        },
        {
          lvl3: 'Бусдын буруутай үйл ажиллагааны улмаас авсан хүү, торгууль, алдангийн орлого',
          mur: '19',
          dun: 150000.0,
        },
        {
          lvl3: 'Хөрөнгийн үнэлгээний үйлчилгээний орлого',
          mur: '20',
          dun: 160000.0,
        },
        {
          lvl3: 'Төрөөс олгож буй төсвийн санхүүжилт, татаас, урамшууллын орлого',
          mur: '21',
          dun: 170000.0,
        },
        {
          lvl3: 'Өмгөөлөл, хууль зүйн зөвлөлгөө өгөх үйлчилгээний орлого',
          mur: '22',
          dun: 180000.0,
        },
        {
          lvl3: 'Үсчин, гоо сайхан, засвар үйлчилгээ, угаалга, хими цэвэрлэгээний үйлчилгээний орлого',
          mur: '23',
          dun: 190000.0,
        },
        {
          lvl3: 'Хуулийн 13 дугаар зүйлд зааснаас бусад бүх төрлийн үйлчилгээний орлого',
          mur: '24',
          dun: 200000.0,
        },
      ],
    },
  },
  {
    uzuulelt:
      'Тухайн сарын НӨАТ ногдох дотоодын бараа, ажил, үйлчилгээний орлого (4+10)',
    mur: '25',
    dun: 210000.0,
  },
  {
    uzuulelt: 'Ногдуулсан татвар (25*10%)',
    mur: '26',
    dun: 220000.0,
  },
  {
    uzuulelt:
      'Нэмэгдсэн өртгийн албан татварын 0 хувь хэрэглэх бараа борлуулсны орлого',
    mur: '27',
    dun: 230000.0,
  },
  {
    uzuulelt:
      'Нэмэгдсэн өртгийн албан татварын 0 хувь хэрэглэх ажил, үйлчилгээ үзүүлсний орлого',
    mur: '28',
    dun: 240000.0,
  },
  {
    uzuulelt:
      'Тухайн сарын НӨАТ ногдох экспортын бараа, ажил, үйлчилгээний орлого (27+28)',
    mur: '29',
    dun: 250000.0,
  },
  {
    uzuulelt: 'Ногдуулсан татвар (29*0%)',
    mur: '30',
    dun: 260000.0,
  },
  {
    uzuulelt: 'Тухайн сард ногдуулсан НӨАТ-ын нийт татвар (26+30)',
    mur: '31',
    dun: 270000.0,
  },
  {
    uzuulelt:
      'Б. Тухайн сард ХУДАЛДАН АВСАН бараа, гүйцэтгэсэн ажил, үзүүлсэн үйлчилгээний НЭМЭГДСЭН ӨРТГИЙН АЛБАН ТАТВАРЫН ТООЦОО',
  },
  {
    uzuulelt: 'Тухайн сард худалдан авсан бараа, ажил үйлчилгээний нийт дүн',
    mur: '32',
    dun: 290000.0,
  },
  {
    uzuulelt:
      'Тухайн сард худалдан авсан БАҮ-нээс НӨАТ-тай худалдан авсан бараа, ажил, үйлчилгээний дүн (34+..+41)',
    mur: '33',
    dun: 300000.0,
    subRow: {
      title: 'Үүнээс',
      child: [
        {
          lvl3: 'Импортын бараа, ажил үйлчилгээний дүн ( НӨАТ-гүй дүн)',
          mur: '34',
          dun: 320000.0,
        },
        {
          lvl3: 'Дотоодын зах зээлээс худалдан авсан бараа, ажил, үйлчилгээнд төлсөн дүн ( НӨАТ-гүй дүн)',
          mur: '35',
          dun: 320000.0,
        },
        {
          lvl3: 'Суутган төлөгчөөр бүртгүүлэх үед импортоор оруулсан болон бусдаас худалдан авсан бараа, ажил, үйлчилгээний дүн /НӨАТ-гүй дүн/',
          mur: '36',
          dun: 330000.0,
        },
        {
          lvl3: 'Тухайн сард хамаарах үндсэн хөрөнгө /Үүнд хайгуулын үйл ажиллагааны зардал хамаарна/ бэлтгэхэд зориулж импортоор оруулсан буюу худалдан авсан бараа, ажил, үйлчилгээнд төлсөн болон барилга байгууламж, машин тоног төхөөрөмж бусад үндсэн хөрөнгө худалдан авах, импортлоход төлсөн дүн /НӨАТ-гүй',
        },
        {
          lvl3: 'барилга байгууламж',
          mur: '37',
          dun: 340000.0,
          sdsa: 320000.0,
        },
        {
          lvl3: 'машин,тоног төхөөрөмжо',
          mur: '38',
          dun: 350000.0,
        },
        {
          lvl3: 'бусад үндсэн хөрөнгө',
          mur: '39',
          dun: 360000.0,
        },
        {
          lvl3: 'Хайгуул үнэлгээний хөрөнгө',
          mur: '40',
          dun: 360000.0,
        },
      ],
    },
  },
  {
    uzuulelt:
      'Мал аж ахуй, газар тариалангийн үйлдвэрлэл эрхлэгчээс худалдан авсан мах, сүү, өндөг, арьс шир, хонь тэмээний ноос, ноолуур, сарлагийн хөөвөр, мах боловсруулах, үйлдвэрлэлд зориулан бэлтгэх, таван хошуу мал, төмс, хүнсний ногоо, жимс жимсгэнэ болон дотоодод үйлдвэрлэсэн гурилыг худалдан авсан дүн / НӨАТ-гүй дүн/',
    mur: '41',
    dun: 310000.0,
  },
  {
    uzuulelt:
      'Худалдан авсан бараа, ажил, үйлчилгээнд төлсөн НӨАТ-ын нийт дүн (33*10%)',
    mur: '42',
    dun: 310000.0,
  },
  {
    uzuulelt:
      'Худалдан авсан бараа, ажил, үйлчилгээнд төлсөн хасагдахгүй НӨАТ-ын дүн (44+…+48)',
    mur: '43',
    dun: 310000.0,
    subRow: {
      title: 'Үүнээс',
      child: [
        {
          lvl3: 'Суудлын автомашин, түүний эд анги сэлбэгт төлсөн НӨАТ',
          mur: '44',
          dun: 320000.0,
        },
        {
          lvl3: 'Хувьдаа болон ажиллагсдын хэрэгцээнд зориулж худалдан авсан бараа, ажил, үйлчилгээнд төлсөн НӨАТ',
          mur: '45',
          dun: 320000.0,
        },
        {
          lvl3: 'Хуулийн 13 дугаар зүйлд заасан чөлөөлөгдөх үйлдвэрлэл, үйлчилгээнд зориулж импортолсон болон худалдаж авсан бараа, ажил, үйлчилгээнд төлсөн НӨАТ',
          mur: '46',
          dun: 330000.0,
        },
        {
          lvl3: 'Ашиглалтын өмнөх үйл ажиллагаанд зориулж импортоор оруулсан болон худалдан авсан бараа, ажил, үйлчилгээнд төлсөн НӨАТ',
          mur: '47',
          dun: 340000.0,
        },
        {
          lvl3: 'Тайлант хугацааны албан татвар ногдох бараа, ажил, үйлчилгээтэй хамааралгүй импортоор оруулсан болон худалдан авсан бараа, ажил, үйлчилгээнд төлсөн НӨАТ',
          mur: '48',
          dun: 340000.0,
        },
      ],
    },
  },
  {
    uzuulelt: 'Тухайн сард хасагдах НӨАТ-ын дүн (42-43)',
    mur: '49',
    dun: '',
  },
  {
    uzuulelt: '',
    mur: '',
    dun: '',
  },
  {
    uzuulelt:
      'В. САНХҮҮГИЙН ТҮРЭЭСИЙН ЗҮЙЛИЙН БОЛОН САНХҮҮЖИЛТИЙН ХЭЛЭЛЦЭЭРИЙН ЗҮЙЛИЙН НЭМЭГДСЭН ӨРТГИЙН АЛБАН ТАТВАРЫН ТООЦОО',
    mur: '',
    dun: '',
  },

  {
    uzuulelt:
      'Санхүүгийн түрээсийн зүйлийг санхүүгийн түрээсээр дотоодын зах зээлд борлуулсны орлого',
    mur: '50',
    dun: 310000.0,
  },
  {
    uzuulelt:
      'Факторинг, форфайтинг зэрэг тэдгээртэй адилтгах хэлцлийн үйлчилгээний орлого',
    mur: '51',
    dun: 310000.0,
  },
  {
    uzuulelt: 'Ногдуулсан НӨАТ (50+51)*10%',
    mur: '52',
    dun: 310000.0,
  },
  {
    uzuulelt:
      'Санхүүгийн түрээсийн зүйлийг санхүүгийн түрээсээр дотоодын зах зээлээс худалдан авахад төлсөн түрээсийн төлбөр /төлбөр хийхээр тохирсон хуваарийг баримтлан/',
    mur: '53',
    dun: 310000.0,
  },
  {
    uzuulelt:
      'Факторинг, форфайтинг зэрэг тэдгээртэй адилтгах хэлцлийг худалдан авахад төлсөн төлбөр /төлбөр хийхээр тохирсон хуваарийг баримтлан/',
    mur: '54',
    dun: 310000.0,
  },
  {
    uzuulelt: 'Хасагдах НӨАТ (53+54)*10%',
    mur: '55',
    dun: 310000.0,
  },
  {
    uzuulelt: 'Г. ТУХАЙН САРЫН НЭМЭГДСЭН ӨРТГИЙН АЛБАН ТАТВАРЫН ТООЦООЛОЛ',
    mur: '',
    dun: '',
  },
  {
    uzuulelt: 'Тухайн сард төлбөл зохих НӨАТ (31+52)',
    mur: '56',
    dun: 310000.0,
  },
  {
    uzuulelt: 'Тухайн сард буцаан авах НӨАТ (49+55)',
    mur: '57',
    dun: 310000.0,
  },
  {
    uzuulelt: 'Д. ТАЙЛАНГИЙН ЗАЛРУУЛГА',
    mur: '',
    dun: '',
  },
  {
    uzuulelt: 'Борлуулалтын орлогын буцаалт, хөнгөлөлтийн дүн',
    mur: '58',
    dun: 310000.0,
  },
  {
    uzuulelt: 'Төлсөн НӨАТ',
    mur: '59',
    dun: 310000.0,
  },
  {
    uzuulelt: 'Худалдан авалтын буцаалт, хөнгөлөлтийн дүн',
    mur: '60',
    dun: 310000.0,
  },
  {
    uzuulelt: 'Хасагдах НӨАТ',
    mur: '61',
    dun: 310000.0,
  },
  {
    uzuulelt: 'Е. ТУХАЙН САРЫН НЭМЭГДСЭН ӨРТГИЙН АЛБАН ТАТВАРЫН ТООЦООЛОЛ',
    mur: '',
    dun: '',
    subRow: {
      title: 'ҮҮНД:',
      child: [
        {
          lvl3: 'НИЙТ ТӨЛБӨЛ ЗОХИХ НӨАТ (56+61)',
          mur: '62',
          dun: 320000.0,
        },
        {
          lvl3: 'НИЙТ БУЦААН АВАХ НӨАТ (57+59)',
          mur: '63',
          dun: 320000.0,
        },
        {
          lvl3: 'ЭЦСИЙН ТООЦООГООР ТӨЛБӨЛ ЗОХИХ НӨАТ (62-63)',
          mur: '64',
          dun: 330000.0,
        },
        {
          lvl3: 'ЭЦСИЙН ТООЦООГООР БУЦААН АВАХ НӨАТ (62-63)',
          mur: '65',
          dun: 340000.0,
        },
      ],
    },
  },
];
