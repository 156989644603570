import React from 'react';
import { Button, Row, Col, Divider } from 'antd';
import { roundFloat } from 'src/core/utils/formater';
import { Table } from 'src/components';

const GuilgeeJagsaaltModal = ({ data, onClose = () => {} }) => (
  <>
    <div style={{ marginBottom: '5px', marginRight: '10px' }}>
      <Row style={{ textAlign: 'right' }}>
        <Col xs={12}>ААНОАТ ТТ-02 Мөр 2+3+5</Col>
        <Col xs={6}>{roundFloat(data?.aanoat?.dun) || roundFloat(0.0)}</Col>
      </Row>
      <Divider />
      <Row style={{ textAlign: 'right' }}>
        <Col xs={12}>Нийт ибаримтаар шивсэн</Col>
        <Col xs={6}>{roundFloat(data?.shivsen?.dun) || roundFloat(0.0)}</Col>
        <Col xs={6}>{roundFloat(data?.shivsen?.zuruu) || roundFloat(0.0)}</Col>
      </Row>
      <Divider />
      <Row style={{ textAlign: 'right' }}>
        <Col xs={12}>НББ Борлуулалтын бүртгэл</Col>
        <Col xs={6}>{roundFloat(data?.nbb?.dun) || roundFloat(0.0)}</Col>
        <Col xs={6}>{roundFloat(data?.nbb?.zuruu) || roundFloat(0.0)}</Col>
      </Row>
    </div>
    <Table
      columns={[
        {
          dataIndex: 'month',
          align: 'center',
          width: 150,
          render: (_, row) => <>{`${row.month} сар`}</>,
        },
        {
          title: 'Нийт ибаримтаар шивсэн /буцаалт хөнгөлөлт хассан/',
          dataIndex: 'noat_shivsen',
          align: 'right',
          width: 150,
          render: (_, row) => <>{roundFloat(row?.noat_shivsen)}</>,
        },
        {
          title: 'НББ Борлуулалтын бүртгэл',
          dataIndex: 'borluulalt_burtgel',
          align: 'right',
          width: 150,
          render: (_, row) => <>{roundFloat(row?.borluulalt_burtgel)}</>,
        },
        {
          dataIndex: 'zuruu',
          align: 'right',
          width: 150,
          render: (_, row) => <>{roundFloat(row?.zuruu)}</>,
        },
      ]}
      dataSource={data?.delgerengui}
      scroll={{ y: 175 }}
      summaryList={{
        sumColumn: [
          {
            align: 'right',
            word: 'Нийт дүн:',
          },
          {
            align: 'right',
            value: 'noat_shivsen',
          },
          {
            align: 'right',
            value: 'borluulalt_burtgel',
          },
          {
            align: 'right',
            value: 'zuruu',
          },
        ],
        sumDataSource: data?.delgerengui_sum,
      }}
    />
    <div style={{ textAlign: 'right', marginTop: 10 }}>
      <Button
        onClick={onClose}
        className="cancel-btn"
        type="primary"
        shape="round"
      >
        Хаах
      </Button>
    </div>
  </>
);

export default GuilgeeJagsaaltModal;
