import React from 'react';
import { Tooltip } from 'antd';
import { HistoryOutlined, DeleteOutlined } from '@ant-design/icons';
import { roundFloat } from 'src/core/utils/formater';

export const customSummary = [
  { value: null },
  { align: 'left', word: 'Нийт дүн:' },
  { value: null },
  { align: 'right', value: 'dansnii_une' },
  { align: 'right', value: 'tatvariin_suuri' },
  { align: 'right', value: 'zuruu' },
  { value: null },
];

export const customColumns = ({ customEdit = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    titleAlign: 'center',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    align: 'left',
    titleAlign: 'center',
    width: 300,
    ellipsis: true,
  },
  {
    title: 'Валютын төрөл',
    dataIndex: 'valiut',
    align: 'center',
    titleAlign: 'center',
    width: 100,
  },
  {
    title: 'Дансны үнэ',
    dataIndex: 'dansnii_une',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => {
      if (row.dans_valiut === 'MNT') {
        return (
          <div>
            <p>{roundFloat(row?.dansnii_une)}</p>
          </div>
        );
      }
      return (
        <div>
          <p>{roundFloat(row?.dansnii_une)}</p>
          <p className="bg-highlight">{roundFloat(row?.dansnii_une_valiut)}</p>
        </div>
      );
    },
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'tatvariin_suuri',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => roundFloat(row?.tatvariin_suuri),
  },
  {
    title: 'Зөрүү',
    dataIndex: 'zuruu',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => roundFloat(row?.zuruu),
  },
  {
    title: 'Түүхэн ханш',
    align: 'center',
    titleAlign: 'center',
    width: 120,
    fixed: 'right',
    render: (_, row) => (
      <>
        {row.valiut === 'MNT' ? (
          <></>
        ) : (
          <Tooltip title="Түүхэн ханш оруулах">
            <HistoryOutlined
              style={{ fontSize: '16px' }}
              onClick={() => customEdit(row)}
            />
          </Tooltip>
        )}
      </>
    ),
  },
];

export const hanshSummary = [
  { align: 'right', word: 'Нийт валютын дүн:' },
  { align: 'right', value: 'valiut_dun' },
  { value: null },
  { value: null },
];

export const hanshColumns = ({ customDelete = () => {} }) => [
  {
    title: 'Огноо',
    dataIndex: 'guilgee_ognoo',
    align: 'left',
    titleAlign: 'center',
    width: 150,
  },
  {
    title: 'Валютын дүн',
    dataIndex: 'valiut_dun',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => roundFloat(row?.valiut_dun),
  },
  {
    title: 'Ханш',
    dataIndex: 'valiut_hansh',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => roundFloat(row?.valiut_hansh),
  },
  {
    title: '',
    dataIndex: 'action',
    align: 'center',
    width: 50,
    render: (_, row) => (
      <>
        <Tooltip title="Устгах">
          <DeleteOutlined onClick={() => customDelete(row.tuuhen_hansh_id)} />
        </Tooltip>
      </>
    ),
  },
];
