import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Table as AntTable } from 'antd';
import { roundFloat, useQuery } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import Table from 'src/components/common/table';
import newAlert from 'src/components/newAlert';
import { hariltsagch_columns } from '../dataStructure/data';

const { Text } = Typography;

export default function TabDans() {
  const dispatch = useDispatch();
  const query = useQuery();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [totalData, setTotalData] = useState(5);

  useEffect(() => {
    const fetchData = async (page, size) => {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');

        if (data.content.status === '0') {
          setTableData(data.content?.json_array);
          setTotalData(data.content?.total);
          setTableDataSum(data.content?.sum_dict);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }
        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts002_00_dansnii_une',
        content: {
          dans_code: query.get('dans_code'),
          month: data_date.month,
          action_type: 'ehnii_uldegdel_hariltsagchaar',
          page: page || 1,
          size: size || 20,
        },
      });
      dispatch(start_loading({}));
    };
    const page = query.get('page2');
    const size = query.get('size2');
    if (query.get('tab') === '2' && query.get('dans_code')) {
      fetchData(page, size);
    }
  }, [query, data_date]);

  const showSum = () => (
    <AntTable.Summary fixed>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell />
        <AntTable.Summary.Cell align="right">Нийт дүн:</AntTable.Summary.Cell>
        <AntTable.Summary.Cell />
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(tableDataSum?.ets_uld)}</p>
          <p className="bg-highlight">
            {roundFloat(tableDataSum?.ets_uld_valiut)}
          </p>
        </AntTable.Summary.Cell>
      </AntTable.Summary.Row>
    </AntTable.Summary>
  );

  return (
    <>
      {query.get('dans_code') ? (
        <div className="table-wrapper">
          <p>
            <span className="ml-3 "> Дансны дугаар: </span>
            {query.get('dans_code')}
          </p>
          <div className="inside-table">
            <Table
              columns={hariltsagch_columns}
              total={totalData}
              dataSource={tableData}
              tab="2"
              scroll={{
                y: 'calc(100vh - 370px)',
              }}
              customize={showSum}
            />
          </div>
        </div>
      ) : (
        <Text className="ant-form-text">Дансаа сонгоно уу!</Text>
      )}
    </>
  );
}
