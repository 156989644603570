import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'src/core/utils/formater';
import { Button as AntButton, Row } from 'antd';
import { Table } from 'src/components';
import newAlert from 'src/components/newAlert';
import { EditOutlined } from '@ant-design/icons';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { columns, totalSum } from './dataStructure/data';
import EditJournal from './EditJournal';

const AddRowTable = () => {
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });
  const query = useQuery();
  const [visible, seTvisible] = useState(false);
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        console.log(data?.content);
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const { month } = data_date;
    const search = {
      dans_dugaar: query.get('dans_dugaar') || '',
      ded_agilal: query.get('ded_agilal')|| '',
    };
    socket.emit('request_in', {
      channel: 'ts001_05_view',
      content: {
        action_type: 'sanhuu_journal_page',
        month,
        page,
        size,
        search,
      },
    });
    dispatch(start_loading({}));
  };
  const onCancel = () => {
    seTvisible(false);
    select_data();
  };
  const title = () => (
    <Row>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<EditOutlined />}
        onClick={() => {
          seTvisible(true);
        }}
      >
        Засварлах
      </AntButton>
    </Row>
  );
  useEffect(() => {
    select_data();
  }, [query, data_date]);
  return (
    <>
      <EditJournal visible={visible} onCancel={onCancel} />
      <div className="table-antd-div no-scroll">
        <Table
          title={title}
          dataSource={tableData.result_list}
          columns={columns()}
          pagination={false}
          customize={() => totalSum({ data: tableData.total_list })}
        />
      </div>
    </>
  );
};

export default AddRowTable;
