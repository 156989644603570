import React from 'react';
import { Button } from 'antd';
import { roundFloat } from 'src/core/utils/formater';

export const dans_columns = (handleSelectDans = () => {}) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_code',
    align: 'left',
    width: 300,
    fixed: 'left',
    render: (_, row) => (
      <Button
        type="link"
        size="small"
        className="underline"
        onClick={() => handleSelectDans(row?.dans_code, row?.tur_zuruu_turul)}
      >
        {row?.dans_code}
      </Button>
    ),
  },
  {
    title: 'Түр зөрүүгийн төрөл',
    dataIndex: 'tur_zuruu_turul',
    align: 'center',
    width: 200,
    ellipsis: true,
    render: (_, row) => (
      <>{row?.dans_code !== '' ? <div>{row?.tur_zuruu_turul} </div> : null}</>
    ),
  },
  {
    title: 'Дансны үнэ',
    dataIndex: 'etssiin_dun',
    align: 'right',
    width: 150,
    render: (_, row) => <>{roundFloat(row?.etssiin_dun)}</>,
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'etssiin_dun_tatvar',
    align: 'right',
    width: 150,
    render: (_, row) => <>{roundFloat(row?.etssiin_dun_tatvar)}</>,
  },
  {
    title: 'Зөрүү',
    dataIndex: 'zuruu',
    align: 'right',
    width: 150,
    render: (_, row) => <>{roundFloat(row?.zuruu)}</>,
  },
];

export const hariltsagch_columns = () => [
  {
    title: 'Харилцагч',
    align: 'center',
    children: [
      {
        title: 'Код',
        dataIndex: 'hariltsagch_code',
        align: 'left',
        width: 150,
        fixed: 'left',
      },
      {
        title: 'Нэр',
        dataIndex: 'hariltsagch_ner',
        align: 'left',
        width: 250,
        ellipsis: true,
        fixed: 'left',
      },
    ],
  },
  {
    title: 'Дансны үнэ',
    dataIndex: 'etssiin_dun',
    align: 'right',
    width: 150,
    render: (_, row) => <>{roundFloat(row?.etssiin_dun)}</>,
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'etssiin_dun_tatvar',
    align: 'right',
    width: 150,
    render: (_, row) => <>{roundFloat(row?.etssiin_dun_tatvar)}</>,
  },
  {
    title: 'Зөрүү',
    dataIndex: 'zuruu',
    align: 'right',
    width: 150,
    render: (_, row) => <>{roundFloat(row?.zuruu)}</>,
  },
];

export const hariltsagch_sum_columns = [
  {
    word: '',
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'etssiin_dun',
  },
  {
    align: 'right',
    value: 'etssiin_dun_tatvar',
  },
  {
    align: 'right',
    value: 'zuruu',
  },
];
