import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import { Button as AntButton, Row } from 'antd';
import { Table } from 'src/components';
import CanView from 'src/components/permission/canview';
import { FileUpload } from './fileUpload';
import { TokenView } from './tokenView';
import { ColumnsMain } from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { set_notification_type } from '../../../core/actions/notificationType';

const stateList = {
  error: 'Aмжилтгүй',
  started: 'Эxэлсэн',
  complete: 'Амжилттай',
};

const typeList = {
  1: 'Диамонд',
  2: 'Смарт ложик',
  3: 'Эрхэт',
  4: 'Такс',
};

const Noat = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { socket, data_date } = useSelector((state) => state);
  const notificationType = useSelector((state) => state.notificationType);

  const [openFile, seTopenFile] = useState(false);
  const [openToken, seTopenToken] = useState(false);
  const [onetime, seTonetime] = useState(true);
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const search = {};
    socket.emit('request_in', {
      channel: 'ss013_03_view',
      content: {
        action_type: 'noat_jobs_page',
        page,
        size,
        search,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    select_data();
  }, [data_date, query]);

  useEffect(() => {
    if (!tableData.result_list && onetime) {
      seTonetime(false);
      select_data();
    }
  }, [tableData]);

  useEffect(() => {
    if (notificationType === 'НӨАТ') {
      select_data();
      dispatch(set_notification_type(''));
    }
  }, [notificationType]);

  const onCancel = () => {
    seTopenFile(false);
    select_data();
  };

  const onCancelToken = () => {
    seTopenToken(false);
  };

  const title = () => (
    <Row>
      <CanView allowedRole="100_1">
        <AntButton
          className="add-btn"
          type="primary"
          size="small"
          onClick={() => {
            seTopenFile(true);
          }}
        >
          Өгөгдөл оруулах
        </AntButton>
      </CanView>
      <CanView allowedRole="100_1">
        <AntButton
          className="add-btn"
          type="primary"
          size="small"
          onClick={() => {
            seTopenToken(true);
          }}
        >
          Токен үүсгэх
        </AntButton>
      </CanView>
    </Row>
  );

  const MainTable = () => (
    <div className="table-antd-div no-scroll">
      <Table
        title={title}
        columns={ColumnsMain({ typeList, stateList })}
        total={tableData.total_size}
        dataSource={tableData.result_list}
        scroll={{
          y: 'calc(100vh - 220px)',
        }}
      />
    </div>
  );

  return (
    <>
      <TokenView visible={openToken} onCancel={onCancelToken} />
      <FileUpload
        visible={openFile}
        onCancel={onCancel}
        // sourceList={typeList}
      />
      <MainTable />
    </>
  );
};

export default Noat;
