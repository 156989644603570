import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from 'src/components';
import { Modal, Form, Row, Col, Button, Typography, Select } from 'antd';
import newAlert from 'src/components/newAlert';
import { useQuery } from 'src/core/utils/formater';
import { customColumns, customSummary } from './dataStructure/data';
import { searchList } from './dataStructure/search';
import { start_loading, end_loading } from '../../../core/actions/loader';

const { Text } = Typography;
const { Option } = Select;

const MainPage = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);
  const data_date = { month: '13' };

  const [tableData, setTableData] = useState({
    filter_list: [],
    json_array: [],
    sum_data: {},
    total: 0,
  });
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [journalType, seTjournalType] = useState('balance');

  const [deleteForm] = Form.useForm();

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setTableData(data?.content);
      } else {
        setTableData({});
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const search = {
      dans_dugaar: query.get('dans_dugaar'),
    };

    socket.emit('request_in', {
      channel: 'ts001_02_tatvar_journal_burtgel',
      content: {
        month_start: String(month),
        month_end: String(month),
        action_type: 'get_journal_list',
        first_year: true,
        journal_type: journalType,
        paging: { page, size, search },
      },
    });

    dispatch(start_loading({}));
  };

  const deleteValue = (deleteData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай устгагдлаа' });
        generateTailan(data_date.month);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tatvar_journal_burtgel',
      content: {
        action_type: 'delete',
        id: deleteData.delete_id,
        turul: deleteData.journal_type,
        first_year: true,
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
  }, [journalType, query]);

  const deleteAction = (row) => {
    deleteForm.setFieldsValue({
      delete_id: row?.id,
      journal_type: row?.turul,
    });

    setDeleteVisible(true);
  };

  const joutnalTypeUpdate = (type) => {
    seTjournalType(type);
  };

  const title = () => (
    <div className="table-title-text">
      {'Тохируулгын төрөл: '}
      <Select
        // defaultValue="balance"
        value={journalType}
        style={{ width: 120 }}
        onChange={joutnalTypeUpdate}
        size="small"
      >
        <Option value="balance">Баланс</Option>
        <Option value="orlogo">Орлого</Option>
        <Option value="hyazgaar">Хязгаар</Option>
      </Select>
    </div>
  );

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          columns={customColumns({
            customDelete: deleteAction,
            dansInfo: tableData?.filter_list,
            version,
          })}
          dataSource={tableData?.json_array}
          scroll={{
            y: 'calc(100vh - 330px)',
          }}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableData?.sum_data,
          }}
          title={title}
          total={tableData?.total}
          isSearch
          searchList={searchList}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Татварын тохируулга устгах"
        open={deleteVisible}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <DeleteModal
          onClose={() => {
            setDeleteVisible(false);
          }}
          form={deleteForm}
          deleteValue={deleteValue}
        />
      </Modal>
    </>
  );
};

const layout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 17 },
  },
};

const DeleteModal = ({
  onClose = () => {},
  form = null,
  deleteValue = () => {},
}) => {
  const onFinish = (values) => {
    deleteValue(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="delete_id" hidden />
        <Form.Item name="journal_type" hidden />
        <Text>Та энэ үйлдлийг хийхдээ итгэлтэй байна уу?</Text>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Устгах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MainPage;
