import React, { useState, useEffect } from 'react';
import { Form, Select, Row, Col, Button, Modal, Tooltip  } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import EditableTable from 'src/components/common/editableTable';
import newAlert from 'src/components/newAlert';
import { PlusCircleOutlined } from '@ant-design/icons';
import { AddNewBurtgel } from 'src/components/undsenHurungu/addNewBurtgel';
import { ColumnsOZND, OZNDEditSum } from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { customConfirm } from 'src/components/common/confirmModal';

const { Option } = Select;
const EditOZND = ({ onCancel = {}, visible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const data_date = useSelector((state) => state.data_date);
  const socket = useSelector((state) => state.socket);
  const [tableDataTotal, setTableDataTotal] = useState({});
  const [saved, seTsaved] = useState(true);

  const [tableDataList, setTableDataList] = useState([]);
  const [tableDataInfo, setTableDataInfo] = useState({});
  const [selectedValues, setSelectedValues] = useState([]);

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        console.log(data);
        setTableDataTotal(data?.content?.total_list);
        setTableDataList(data?.content?.result_list);
        setTableDataInfo(data?.content?.result_json);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const { month } = data_date;
    const search = {
    };
    socket.emit('request_in', {
      channel: 'ts001_05_view',
      content: {
        action_type: 'sanhuu_oznd_edit',
        month,
        search,
      },
    });
    dispatch(start_loading({}));
  };
  useEffect(() => {
    if (visible) {
      select_data();
    }
  }, [visible]);
  const clear = (inputString) => {
    const stringWithoutCommas = String(inputString).replace(/,/g, '');
    const numberValue = parseFloat(stringWithoutCommas);
    return numberValue;
  };

  const sumBurt = () => {
    const temp_sum = {
      oznd_deb: 0,
      oznd_cred: 0,
    };
    tableDataList.forEach((item) => {
      temp_sum.oznd_deb += clear(item.oznd_deb);
      temp_sum.oznd_cred += clear(item.oznd_cred);
    });
    setTableDataTotal({...tableDataTotal, ...temp_sum });
  };
  const closeModal = () => {
    form.resetFields();
    setTableDataList([]);
    setTableDataInfo({});
    setTableDataTotal({});
    setSelectedValues([]);
    onCancel();
  };

  const handleSave = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: data.message });
        seTsaved(true)
      } else {
        newAlert({ type: 'error', msg: data.message });
      }
      // closeModal();
      dispatch(end_loading({}));
    });

    const { month } = data_date;
    socket.emit('request_in', {
      channel: 'ts001_05_modify',
      content: {
        action_type: 'oznd_edit_save',  
        json_array: { ...tableDataList },
        month: month,
      },
    });
    dispatch(start_loading({}));
  };

  const onFinish = () => {
    customConfirm({
      title: 'ОАТ тохируулга хадгалах уу?',
      onOk: () => {
        if((0.00 === tableDataTotal?.oznd_deb && 0.00  === tableDataTotal?.oznd_cred) 
          || (Math.abs(tableDataTotal?.jr_ets_cred - tableDataTotal?.oznd_deb) < 0.01 && Math.abs(tableDataTotal?.jr_ets_deb - tableDataTotal?.oznd_cred) < 0.01) ){
          handleSave();
        }
        else{
          newAlert({ type: 'warning', msg: 'ОЗНД тохируулга тулаагүй байна !!' });
        }
      },
    });
  };

  const geTvalue = (value) => {
    setTableDataList([...value]);
    seTsaved(false)
  };
  useEffect(() => {
    if (visible && tableDataList) sumBurt();
  }, [tableDataList]);

  const layout = {
    labelCol: {
      xs: { span: 5 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 19 },
      sm: { span: 19 },
    },
  };

  const handleAddButtonClick = () => {
    const temp_row = {
      key: null,
      dans_id: null,
      dans_dugaar: null,
      oznd_deb: 0.00,
      oznd_cred: 0.00,
    };

    const new_rows = selectedValues.map((selectedId) => {
      const dansInfo = tableDataInfo?.dans_list.find(
        (info) => info?.dans_id === selectedId
      );
      if (dansInfo) {
        return {
          ...temp_row,
          key:  dansInfo.dans_id  + '_' + (tableDataList.length + 1) ,
          dans_id: dansInfo.dans_id,
          dans_dugaar: dansInfo.dans_dugaar,
          dans_ner: dansInfo.dans_ner
        };
      }
      return null; // or handle if not found
    }).filter((row) => row !== null); // Remove null entries if any
    setTableDataList(
      [...tableDataList, ...new_rows]
    );
    setSelectedValues([]);
    seTsaved(false)
  };
  const deleteRow = (row) => {
    const updatedResultList = tableDataList.filter(
      (item) => item.key !== row.key
    );
    setTableDataList([
      ...updatedResultList,
    ]);
    seTsaved(false)
  };

  return (
    <Modal
      className="custom-modal"
      title="ОЗНД тохируулга"
      open={visible}
      width="900px"
      footer={null}
      closable={false}
      maskClosable={false}
      
    >
      <div className="dans-medeelel-container">
        <Form
          form={form}
          onFinish={onFinish}
          className="register-form"
          {...layout}
          size="small"
        >
          <Form.Item
            name="hurungu_turul"
            // label="нэмэх"
            labelAlign="left"
          >
            <Select
              size="small"
              placeholder="Сонгох"
              allowClear
              mode="multiple"
              value={selectedValues}
              onChange={setSelectedValues}
              style={{ width: '500px', fontSize: 13 }}
              showSearch
              maxTagCount={1}
              maxTagPlaceholder={(omittedValues) => (
                <Tooltip
                  overlayStyle={{ pointerEvents: 'none' }}
                  title={omittedValues.map(({ label }) => label).join(', ')}
                >
                  <span>Сонгосон</span>
                </Tooltip>
              )}
              filterOption={(input, option) =>
                (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
              }
            >
               {tableDataInfo?.dans_list
                ?.filter((dansInfo) => !tableDataList.some((item) =>
                  item.dans_id === dansInfo?.dans_id))
                .map((dansInfo) => (
                  <Option key={dansInfo?.dans_id} value={dansInfo?.dans_id} >
                    {dansInfo?.dans_dugaar + ' ' + dansInfo?.dans_ner }
                  </Option>
                ))}

            </Select>
            <Button
              className="add-btn"
              type="primary"
              size="small"
              icon={<PlusCircleOutlined />}
              onClick={handleAddButtonClick}
            >
              Нэмэх
            </Button>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {visible && tableDataList && (
              <EditableTable
                dataSource={tableDataList}
                columns={ColumnsOZND({ deleteRow, clear })}
                geTvalue={geTvalue}
                customize={() => <OZNDEditSum data={tableDataTotal} />}
                scroll={{
                  y: 'calc(100vh - 430px)',
                }}
              />
            )}
          </Form.Item>
          <Row style={{ marginTop: 10 }}>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => {
                  if(saved){
                    closeModal();
                  }
                  else{
                    customConfirm({
                      title: 'Хадгалаагүй байна. Буцахыг зөвшөөрөх үү?',
                      onOk: () => {
                        closeModal();
                      },
                    });
                  }
                }}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Буцах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default EditOZND;
