import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import makeData from './makeData'
import { Button as AntButton, Card, List, Input } from 'antd';
import { isEmpty } from 'lodash';
import TableCommon from 'src/components/common/table';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { roundFloat } from 'src/core/utils/formater';
import CanView from 'src/components/permission/canview';
import newAlert from 'src/components/newAlert';

export default function Client() {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const [actionType, setActionType] = useState('');
  const [currentValue, setCurrentValue] = useState({
    tatvar_nogdoh_orlogo: '',
    hasagdah_zardal: '',
    noogduulah_orlogo: '',
    nogdson_tatvar: '',
    hongololt_huvi: '',
    hongologdoh_tatvar_22_1: '',
    hongologdoh_tatvar_22_5: '',
  });

  const [selectedMonth, setSelectedMonth] = useState('');
  const [tableData, setTableData] = useState([]);
  const [firstData, setFirstData] = useState([
    {
      title: '№',
      dataIndex: 'row',
      width: 30,
      align: 'center',
      titleAlign: 'center',
      render: (text, row) => (
        <p style={{ textAlign: 'center' }}> {row.id + 1} </p>
      ),
    },
    {
      title: 'Хөнгөлөлт',
      dataIndex: 'ner',
      align: 'left',
      titleAlign: 'center',
    },
    {
      title: 'Нийт дүн',
      dataIndex: 'total',
      align: 'right',
      titleAlign: 'center',
      render: (_) => roundFloat(_),
    },
  ]);
  const [columnData, setColumnData] = useState([]);
  const [hongololtTitle, setHongololtTitle] = useState([]);
  const hongololtData = [
    {
      title: 'Татвар ногдох орлого:',
      value: roundFloat(currentValue?.tatvar_nogdoh_orlogo),
      type: '',
    },
    {
      title: 'Хасагдах зардал:',
      value: roundFloat(currentValue?.hasagdah_zardal),
    },
    {
      title: 'Татвар ногдуулах орлого:',
      value: roundFloat(currentValue?.noogduulah_orlogo),
    },
    {
      title: 'Ногдсон татвар:',
      value: roundFloat(currentValue?.nogdson_tatvar),
    },
    {
      title: 'Хөнгөлөлтийн хувь:',
      value: currentValue?.hongololt_huvi,
    },
    {
      title: 'Хөнгөлөгдөх татвар:',
      value: roundFloat(currentValue?.hongologdoh_tatvar_22_1),
    },
  ];
  const typeList = ['type_22_1', 'type_22_5_2', 'type_22_5_6', 'type_22_5_11'];

  const mainData = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');

      if (status === '0') {
        if (typeList.includes(content.response_type)) {
          if (content.response_type === 'type_22_1') {
            setHongololtTitle('ААНОАТ-ын 22.1-р хөнгөлөлт');
          } else if (content.response_type === 'type_22_5_2') {
            setHongololtTitle('ААНОАТ-ын 22.5.2-р хөнгөлөлт:');
          } else if (content.response_type === 'type_22_5_6') {
            setHongololtTitle('ААНОАТ-ын 22.5.6-р хөнгөлөлт:');
          } else if (content.response_type === 'type_22_5_11') {
            setHongololtTitle('ААНОАТ-ын 22.5.11-р хөнгөлөлт:');
          }
          setActionType(content.response_type);
          setSelectedMonth(content.selected_month);

          setCurrentValue({
            tatvar_nogdoh_orlogo: content.tatvar_nogdoh_orlogo,
            hasagdah_zardal: content.hasagdah_zardal,
            noogduulah_orlogo: content.noogduulah_orlogo,
            nogdson_tatvar: content.nogdson_tatvar,
            hongololt_huvi: content.hongololt_huvi,
            hongologdoh_tatvar_22_1: content.hongologdoh_tatvar,
            hongologdoh_tatvar_22_5: '',
          });
        }
      }
      if (content.response_type === 'others_list') {
        setActionType(content.response_type);
        setSelectedMonth(content.selected_month);

        setColumnData(
          content?.columns &&
            content?.columns.map((i) => ({
              titleAlign: 'center',
              align: 'right',
              render: (_) => roundFloat(_),
              ...i,
            }))
        );
        const new_temp_data = content?.hongololt_data.map(
          (inner_data, key) => ({
            id: key,
            ...inner_data,
          })
        );

        if (new_temp_data) {
          console.log(new_temp_data);
          setTableData(new_temp_data);
        }
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_00_get_dun_holboh_data_by_selected_type',
      content: { month: data_date?.month },
    });
    // dispatch(start_loading({}));
  };

  function Dunholboh() {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');
      newAlert({
        type: data_temp.status === '0' ? 'success' : 'error',
        msg: data_temp.content.Message,
        functions: { onSuccess: mainData() },
      });
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_00_hungulult_manage',
      content: {
        action_type: 'hungulult_set_dun',
        month: String(selectedMonth),
      },
    });
    dispatch(start_loading({}));
  }

  useEffect(() => {
    console.log('>> Getting old list...');
    mainData();
  }, []);

  if (columnData.length === 0 && actionType === 'others_list') {
    return <div>Loading..</div>;
  }

  const title = () => (
    <>
      <CanView allowedRole="002_1">
        <AntButton
          onClick={Dunholboh}
          className="cancel-btn"
          type="primary"
          shape="round"
        >
          Тайланд холбох
        </AntButton>
      </CanView>
    </>
  );
  const generateArr = () => {
    const list = firstData;
    list.splice.apply(list, [2, 0].concat(columnData));
    return list;
  };
  return (
    <>
      {(() => {
        if (typeList.includes(actionType)) {
          return (
            <>
              <Card title={hongololtTitle} className="custom-card">
                {!isEmpty(hongololtData) && (
                  <List
                    size="small"
                    dataSource={hongololtData}
                    className="custom-list"
                    renderItem={(item) => (
                      <List.Item>
                        <div>
                          <span>{item.title}</span>
                        </div>
                        <div>
                          {item?.type === 'editable' ? (
                            <Input bordered={false} defaultValue={item.value} />
                          ) : (
                            <span>{item.value}</span>
                          )}
                        </div>
                      </List.Item>
                    )}
                  />
                )}
                <CanView allowedRole="002_1">
                  <AntButton
                    onClick={Dunholboh}
                    className="cancel-btn"
                    type="primary"
                    shape="round"
                  >
                    Тайланд холбох
                  </AntButton>
                </CanView>
              </Card>
            </>
          );
        }
        if (actionType === 'others_list') {
          // other
          return (
            <>
              <div className="table-antd-div">
                <TableCommon
                  columns={generateArr()}
                  dataSource={tableData}
                  scroll={{
                    x: 'auto',
                  }}
                  isTitle
                  title={title}
                />
              </div>
            </>
          );
        }

        return <>!!! Тухайн цонхонд тохирох хөнгөлөлт байхгүй байна</>;
      })()}
    </>
  );
}
