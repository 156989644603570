import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Button as AntButton } from 'antd';
import axios from 'src/core/utils/axios';
import { PlusCircleOutlined } from '@ant-design/icons';
import { onlyNumber, useQuery } from 'src/core/utils/formater';

import newAlert from 'src/components/newAlert';

import Table from 'src/components/common/table';

import { start_loading, end_loading } from 'src/core/actions/loader';

import { customSummary, columns } from './dataStructure/data';
import Add from './add';

const Client = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);

  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});

  const FileDownload = require('js-file-download');
  /// update antd
  const [form] = Form.useForm();
  const [visible, seTvisible] = useState(false);
  const [total, seTtotal] = useState([]);
  const query = useQuery();
  const page = query.get('page');
  const size = query.get('size');

  const close = () => {
    form.resetFields();
    seTvisible(false);
  };
  const open = () => {
    seTvisible(true);
  };

  function send_confirm_row_id(file_id, dugnelt_file_name) {
    axios.get(
      'attach',
      '/get_file',
      {},
      {
        file_size: 999,
        file_id,
      },
      (event) => {
        FileDownload(event, dugnelt_file_name);
      }
    );
  }

  const mainData = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      if (status === '0') {
        console.log('gamshig_data', content);
        seTtotal(content?.total);
        setTableDataSum(content.sum_data);
        const new_temp_data = content?.json_array.map((inner_data, key) => ({
          id: key + 1,
          ...inner_data,
        }));
        if (new_temp_data) {
          setTableData(new_temp_data);
        }
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_service_gamshig',
      content: { action_type: 'get_gamshig_data', page, size, search: {} },
    });

    dispatch(start_loading({}));
  };

  const delete_row = (id) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: mainData(), onFail: mainData() },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_service_gamshig',
      content: {
        id,
        action_type: 'delete_gamshig_data',
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    console.log('>> Getting old list...');
    mainData();
  }, [query]);

  const onEdit = (data) => {
    const { hohirol_dun, ..._data } = data;
    const values = {
      hohirol_dun: onlyNumber(hohirol_dun),
      ..._data,
    };
    form.setFieldsValue(values);
    seTvisible(true);
  };

  const title = () => (
    <AntButton
      id="first"
      className="add-btn"
      type="primary"
      size="small"
      icon={<PlusCircleOutlined />}
      onClick={open}
    >
      Нэмэх
    </AntButton>
  );

  return (
    <>
      <div className="table-antd-div zardal-container-gamshig">
        <Table
          columns={columns({
            onEdit,
            delete_row,
            send_confirm_row_id,
          })}
          title={title}
          dataSource={tableData}
          scroll={{
            x: 'auto',
          }}
          total={total}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableDataSum,
          }}
        />
        <Modal
          className="custom-modal"
          title="Гамшигаас учирсан хохирлын зардал бүртгэх"
          visible={visible}
          closable={false}
          maskClosable={false}
          footer={null}
          width={700}
        >
          <Add
            form={form}
            onClose={close}
            socket={socket}
            dispatch={dispatch}
            refetch={mainData}
          />
        </Modal>
      </div>
    </>
  );
};
export default Client;
