import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'src/core/utils/formater';
import { Table } from 'src/components';
import { Row } from 'antd';
import newAlert from 'src/components/newAlert';
import { UsgalZasvarAllColumns } from './dataStructure/data';
import { searchList } from './dataStructure/search';
import { start_loading, end_loading } from '../../../core/actions/loader';

const DahinUnelgeeAll = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const socket = useSelector((state) => state.socket);
  const data_date = { month: '0' };

  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const { month } = data_date;
    const search = {
      hurungu: query.get('hurungu'),
      burtgel: query.get('burtgel'),
    };
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'ursgal_zasvar_page',
        page,
        size,
        search,
        month,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    select_data();
  }, [query]);

  const title = () => <Row />;

  return (
    <div className="table-antd-div no-scroll">
      <Table
        isSearch
        searchList={searchList}
        title={title}
        columns={UsgalZasvarAllColumns()}
        total={tableData?.total_size}
        dataSource={tableData?.result_list}
        scroll={{
          y: 'calc(100vh - 360px)',
        }}
      />
    </div>
  );
};

export default DahinUnelgeeAll;
