export const searchList = [
  {
    type: 'input',
    title: 'Хөрөнгийн код, нэр',
    children: {
      queryKey: 'hurungu',
    },
  },
  {
    type: 'select',
    title: 'Мэдээлэл',
    children: {
      queryKey: 'medeelel',
      list: [
        {
          value: 'Бүгд',
          name: 'Бүгд',
        },
        {
          value: 'Дутуу',
          name: 'Дутуу',
        },
        {
          value: 'Бүрэн',
          name: 'Бүрэн',
        },
      ],
    },
  },
  // {
  //   type: 'select',
  //   title: 'Зөрүү тооцох эсэх',
  //   children: {
  //     queryKey: 'zuruu',
  //     list: [
  //       {
  //         value: 'Бүгд',
  //         name: 'Бүгд',
  //       },
  //       {
  //         value: 'Тийм',
  //         name: 'Тийм',
  //       },
  //       {
  //         value: 'Үгүй',
  //         name: 'Үгүй',
  //       },
  //     ],
  //   },
  // },
  // {
  //   type: 'select',
  //   title: 'Бүлэг',
  //   children: {
  //     queryKey: 'buleg',
  //     list: [
  //       {
  //         value: 'Бүгд',
  //         name: 'Бүгд',
  //       },
  //       {
  //         value: 'Барилга байгууламж, газрын тохижилт',
  //         name: 'Барилга байгууламж, газрын тохижилт',
  //       },
  //       {
  //         value: 'Машин, механизм, техник, үйлдвэрлэлийн тоног төхөөрөмж',
  //         name: 'Машин, механизм, техник, үйлдвэрлэлийн тоног төхөөрөмж',
  //       },
  //       {
  //         value: 'Компьютер, дагалдах тоног төхөөрөмж, программ хангамж',
  //         name: 'Компьютер, дагалдах тоног төхөөрөмж, программ хангамж',
  //       },
  //       {
  //         value: 'Ашиглах хугацаа нь тодорхой биет бус хөрөнгө /Үүнд ашигт малтмалын хайгуулын болон ашиглалтын тусгай зөвшөөрөл хамаарна./',
  //         name: 'Ашиглах хугацаа нь тодорхой биет бус хөрөнгө /Үүнд ашигт малтмалын хайгуулын болон ашиглалтын тусгай зөвшөөрөл хамаарна./',
  //       },
  //       {
  //         value: 'Бусад хөрөнгө',
  //         name: 'Бусад хөрөнгө',
  //       },
  //       {
  //         value: 'Түрээсийн ашиглах эрхтэй хөрөнгө',
  //         name: 'Түрээсийн ашиглах эрхтэй хөрөнгө',
  //       },
  //       {
  //         value: 'Газар, уран зураг, гар урлал г.м элэгдэхгүй хөрөнгө 17.7',
  //         name: 'Газар, уран зураг, гар урлал г.м элэгдэхгүй хөрөнгө 17.7',
  //       },
  //     ],
  //   },
  // },
];
