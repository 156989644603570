import React from 'react';
import { Tooltip } from 'antd';
import {
  WarningOutlined,
  CheckOutlined,
} from '@ant-design/icons';

const Warning = ({ show = '0', listWarning = [] }) => (
  <div align="middle" style={{ width: '30px' }}>
    { show
      ? (
        <Tooltip align="center" placement="topRight" title={listWarning.join(', ').toString()}>
          <WarningOutlined />
        </Tooltip>
      ) : (
        <CheckOutlined
          className="success"
          style={{
            color: 'black' }}
          // color="success"
        />
      )}

  </div>
);

export default Warning;
