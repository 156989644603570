import React from 'react';
import { Pagination as AntPagination } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'src/core/utils/formater';

// const params = new URLSearchParams();
const Pagination = ({ tab = '', total = 500 }) => {
  const history = useHistory();

  const query = useQuery();

  const get = (type) => {
    if (!query.get(type)) {
      return type === `size${tab}` ? 20 : 1;
    }
    return parseInt(query.get(type), 10);
  };

  const onPush = (type, value) => {
    if (!query.get(type)) {
      query.append(type, value);
    } else {
      query.set(type, value);
    }
    history.push({ search: query.toString() });
  };
  const onChange = (e) => {
    onPush(`page${tab}`, e);
  };

  const onShowSizeChange = (_, pageSize) => {
    onPush(`size${tab}`, pageSize);
  };

  return (
    <AntPagination
      size="small"
      current={get(`page${tab}`)}
      onChange={onChange}
      total={total}
      showSizeChanger
      locale={{ items_per_page: 'хуудас' }}
      pageSize={get(`size${tab}`)}
      onShowSizeChange={onShowSizeChange}
    />
  );
};
export default Pagination;
