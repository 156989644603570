import React from 'react';

import { LockOutlined, UserOutlined } from '@ant-design/icons';

const iconBody = {
  style: {
    color: '#d9d9d9',
  },
  className: 'mr-10',
};

const userIcon = <UserOutlined {...iconBody} />;

const lockIcon = <LockOutlined {...iconBody} />;

const deviceTokenMaker = () => {
  const device_token = localStorage.getItem('device_token');
  if (device_token) {
    return device_token;
  }
  const token = (Math.random() + 1).toString(36).substring(2, 12);
  localStorage.setItem('device_token', token);
  return token;
};

const toast = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export default {
  userIcon,
  lockIcon,
  deviceTokenMaker,
  toast,
};
