import { roundFloat } from 'src/core/utils/formater';

export const summary = [
  {
    value: null,
  },
  {
    value: null,
  },
  { align: 'left', word: 'Нийт дүн:' },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'zardal',
  },

  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'hetrelt_dun',
  },
];

export const summary_2 = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {},

  {
    align: 'right',
    value: 'dans_sum',
  },
];
export const columns = [

  {
    title: '№',
    dataIndex: 'index',
    align: 'left',
    titleAlign: 'center',
    width: 20,
  },
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    titleAlign: 'center',
    key: 'code',
    align: 'left',
    width: 50,
  },
  {
    title: 'Хөрөнгийн нэр',
    dataIndex: 'ner',
    key: 'ner',
    align: 'left',
    width: 100,
  },
  {
    title: 'Бүртгэлийн дугаар',
    dataIndex: 'burtgel_dugaar',
    key: 'burtgel_dugaar',
    align: 'left',
    width: 100,
  },
  {
    title: 'Төрөл',
    dataIndex: 'turul_name',
    key: 'turul_name',
    align: 'left',
    width: 100,
  },

  {
    title: 'Огноо',
    dataIndex: 'ognoo',
    align: 'center',
    titleAlign: 'center',
    width: 50,
  },

  {
    title: 'Засварын дүн',
    dataIndex: 'zardal',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Засвар хийсэн хөрөнгийн үлдэгдэл өртөг',
    dataIndex: 'suuri_une',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Хязгаар тооцох хувь',
    dataIndex: 'zardal_huvi',
    align: 'right',
    titleAlign: 'center',
    width: 100,
  },
  {
    title: 'Зардлын хязгаар',
    dataIndex: 'huleen_zuvshuuruh_dun',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Хязгаарлалтаас хэтэрсэн зардал',
    dataIndex: 'hetrelt_dun',
    align: 'right',
    titleAlign: 'center',
    width: 120,
    render: (_) => roundFloat(_),
  },

];
export const columns_2 = [
  {
    title: 'Дансны',
    titleAlign: 'center',
    children: [
      {
        title: '№',
        align: 'center',
        dataIndex: 'key',
        titleAlign: 'center',
        width: '1vw',
      },
      {
        title: 'Дугаар',
        align: 'left',
        dataIndex: 'dans_dugaar',
        titleAlign: 'center',
        width: '3vw',
      },
      {
        title: 'Нэр',
        align: 'left',
        titleAlign: 'center',
        width: '7vw',
        dataIndex: 'dans_ner',
      },
      {
        title: 'Дүн',
        align: 'right',
        titleAlign: 'center',
        width: '3vw',
        dataIndex: 'dans_dun',
        render: (_) => roundFloat(_),
      },
    ],
  },
];
