/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import { Table } from 'src/components';
import { searchList } from './dataStructure/search';
import {
  ColumnsMain,
  customSummary,
} from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';

const HudaldanAvaltButsaalt = () => {
  const dispatch = useDispatch();

  const query = useQuery();

  const { socket, data_date } = useSelector((state) => state);

  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });
  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content);
        console.log(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const month = data_date?.month;
    const year = data_date?.year;
    const search = {
      hariltsagch: query.get('hariltsagch'),
      tohiruulga: query.get('tohiruulga'),
    };
    socket.emit('request_in', {
      channel: 'ss013_03_view',
      content: {
        action_type: 'hudaldan_avalt_butsaalt_hungulult_page',
        month,
        page,
        size,
        search,
        year,
      },
    });
    dispatch(start_loading({}));
  };
  useEffect(() => {
    select_data();
  }, [data_date, query]);
  const title = () => (<></>
  );
  const mainTable = () => (
    <div className="table-antd-div no-scroll">
      <Table
        isSearch
        searchList={searchList}
        title={title}
        columns={ColumnsMain()}
        total={tableData.total_size}
        dataSource={tableData.result_list}
        summaryList={{
          sumColumn: customSummary,
          sumDataSource: tableData.total_list,
        }}
        scroll={{
          y: 'calc(100vh - 370px)',
        }}
      />
    </div>
  );
  return (
    <>
      { mainTable()}
    </>
  );
};

export default HudaldanAvaltButsaalt;
