import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Typography, Row, Col, Table as AntTable } from 'antd';

import { roundFloat, useQuery, replaceFloat } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import Table from 'src/components/common/table';
import newAlert from 'src/components/newAlert';
import { set_step } from 'src/core/actions/hintStep';
import { customConfirm } from 'src/components/common/confirmModal';
import { hariltsagch_columns } from '../data/dataStructure';

import DelgerenguiTCModal from '../modals/modalTatvarSuuriDelgerengui';
import DelgerenguiNehemjleh from '../modals/nehemjlehDelgerengui';

const { Text } = Typography;

const TabDans = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [detailParam, setDetailParam] = useState([]);
  const [footerParam, setFooterParam] = useState([]);
  const [totalData, setTotalData] = useState(5);
  // Modal - tatvar suuri
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [currentHariltsagch, setCurrentHariltsagch] = useState(null);
  // Modal - nehemjleh delgerengui
  const [nehModalShow, setNehModalShow] = useState(false);
  const [nehModalData, setNehModalData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentNehemjleh, setCurrentNehemjleh] = useState({});
  const [valiutDunTotal, setValiutDunTotal] = useState(0.0);
  const [valiutZuruu, setValiutZuruu] = useState(0.0);

  const setActiveData = (data_temp) => {
    const active_rows = [];
    const active_ids = [];
    for (let i = 0; i < data_temp.length; i += 1) {
      if (data_temp[i]?.is_active) {
        active_rows.push(data_temp[i]);
        active_ids.push(data_temp[i].key);
      }
    }
    setSelectedRows(active_rows);
    setSelectedRowKeys(active_ids);
    setNehModalData(data_temp);
  };

  const handleOpenModal = (hariltsagch_code) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0') {
        setCurrentHariltsagch(hariltsagch_code);
        setModalData(data.content?.data_array);
        setDetailParam({
          hariltsagch: data.content?.data_array[0].hariltsagch_name,
          dans_code: query.get('dans_code'),
        });
        setFooterParam(data.content?.sum_list);
        setShowModal(true);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts002_00_tatvar_suuri',
      content: {
        hariltsagch_code,
        dans_code: query.get('dans_code'),
        version: version.version_number,
        year: version.year,
        month: data_date.month,
        tur_zuruu_turul: query.get('tur_zuruu_turul') || '',
        action_type: 'tatvar_suuri_detail',
      },
    });
    dispatch(start_loading({}));
  };

  const fetchData = (page, size) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0') {
        setTableData(data.content.json_array);
        setTotalData(data.content.total);
        setTableDataSum(data.content.sum_list);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts002_00_tatvar_suuri',
      content: {
        dans_code: query.get('dans_code'),
        month: data_date.month,
        action_type: 'tatvar_suuri_hariltsagchaar',
        page: page || 1,
        size: size || 20,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    const page = query.get('page');
    const size = query.get('size');
    if (query.get('tab') === '2' && query.get('dans_code')) {
      fetchData(page, size);
      dispatch(set_step(1));
    }
  }, [query, data_date]);

  const updateActiveData = () => {
    let valiut_dun_total = 0;
    const active_rows = [];
    for (let i = 0; i < nehModalData.length; i += 1) {
      if (selectedRowKeys.indexOf(nehModalData[i].key) > -1) {
        active_rows.push(nehModalData[i]);
        valiut_dun_total += replaceFloat(nehModalData[i]?.valiut_dun);
      }
    }
    setSelectedRows(active_rows);
    setValiutDunTotal(valiut_dun_total);
    setValiutZuruu(currentNehemjleh?.ets_uld_valiut_real - valiut_dun_total);
  };

  const clearNehemjlehData = () => {
    customConfirm({
      title: 'Нэхэмжлэхийн задаргааг устгах уу?',
      onOk: () => {
        socket.on('request_out', (data) => {
          socket.removeAllListeners('request_out');

          if (data.status === '0') {
            newAlert({ type: 'success', msg: 'Амжилттай устгагдлаа' });
            handleOpenModal(currentHariltsagch);
          } else {
            newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
            dispatch(end_loading({}));
          }
        });
        socket.emit('request_in', {
          channel: 'ts002_00_nemehmjleh_control',
          content: {
            action_type: 'clear_list',
            month: data_date.month,
            data: {
              hariltsagch_code: currentHariltsagch,
              dans_code: query.get('dans_code'),
            },
          },
        });
        dispatch(start_loading({}));
      },
    });
  };

  useEffect(() => {
    if (nehModalData) {
      updateActiveData();
    }
  }, [selectedRowKeys, nehModalData]);

  const showSum = () => (
    <AntTable.Summary fixed>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell align="right" colSpan={2}>
          Нийт дүн:
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(tableDataSum?.ashig)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(tableDataSum?.aldagdal)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(tableDataSum?.ehnii_uldegdel)}</p>
          <p className="bg-highlight">
            {roundFloat(tableDataSum?.ehnii_uldegdel_valiut)}
          </p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(tableDataSum?.deb)}</p>
          <p className="bg-highlight">{roundFloat(tableDataSum?.deb_valiut)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(tableDataSum?.cred)}</p>
          <p className="bg-highlight">
            {roundFloat(tableDataSum?.cred_valiut)}
          </p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(tableDataSum?.etssiin_uldegdel)}</p>
          <p className="bg-highlight">
            {roundFloat(tableDataSum?.etssiin_uldegdel_valiut)}
          </p>
        </AntTable.Summary.Cell>
      </AntTable.Summary.Row>
    </AntTable.Summary>
  );

  const showNehModal = (request_data) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setCurrentNehemjleh(request_data);
        setActiveData(data?.content?.table_data);
        setNehModalShow(true);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts002_00_nemehmjleh_control',
      content: {
        action_type: 'get_list',
        month: data_date.month,
        data: request_data,
      },
    });
    dispatch(start_loading({}));
  };

  const getModalValue = (values) => {
    setNehModalData([...values]);
  };

  const updateNehemjleh = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        handleOpenModal(currentHariltsagch);
      } else if (data.status === '4') {
        newAlert({
          type: 'error',
          msg: 'Нэхэмжлэхийн задаргаа дүн таарахгүй байна',
        });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts002_00_nemehmjleh_control',
      content: {
        action_type: 'update_list',
        data: selectedRows,
        nehemjleh: currentNehemjleh,
      },
    });
    dispatch(start_loading({}));
    setNehModalShow(false);
  };

  return (
    <>
      {query.get('dans_code') ? (
        <div className="table-wrapper">
          <Row>
            <Col xs={24}>
              <p>
                <span className="ml-3">
                  Дансны дугаар: {query.get('dans_code')}
                </span>
              </p>
            </Col>
            <Col xs={24}>
              <p>
                <span className="ml-3"> Түр зөрүүгийн төрөл: </span>
                {query.get('tur_zuruu_turul')}
              </p>
            </Col>
          </Row>
          <div className="inside-table no-scroll">
            <Table
              columns={hariltsagch_columns({ handleOpenModal })}
              total={totalData}
              dataSource={tableData}
              tab="2"
              scroll={{
                y: 'calc(100vh - 370px)',
              }}
              customize={showSum}
            />
          </div>
        </div>
      ) : (
        <Text className="ant-form-text">Дансаа сонгоно уу!</Text>
      )}

      <Modal
        className="custom-modal"
        title="Татварын суурийн дэлгэрэнгүй"
        open={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        width={1700}
        footer={null}
        centered
      >
        <DelgerenguiTCModal
          detailParam={detailParam}
          footerParam={footerParam}
          roundFloat={roundFloat}
          modalData={modalData}
          onClose={() => {
            setShowModal(false);
          }}
          onEdit={showNehModal}
          onClear={clearNehemjlehData}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Нэхэмжлэхийн жагсаалт"
        open={nehModalShow}
        onCancel={() => {
          setNehModalShow(false);
        }}
        width={1000}
        footer={null}
        centered
      >
        <DelgerenguiNehemjleh
          modalData={nehModalData}
          getModalValue={getModalValue}
          onClose={() => {
            setNehModalShow(false);
          }}
          setSelectedRows={setSelectedRows}
          selectedRowKeysList={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          saveData={updateNehemjleh}
          valiutDunTotal={valiutDunTotal}
          currentNehemjleh={currentNehemjleh}
          valiutZuruu={valiutZuruu}
        />
      </Modal>
    </>
  );
};

export default TabDans;
