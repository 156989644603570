import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Drawer, Input, Row } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Table } from 'src/components';
import CanView from 'src/components/permission/canview';
import newAlert from 'src/components/newAlert';
import { UsgalZasvarAllColumns } from './dataStructure/data';
import { UrsgalZasvar } from './UrsgalZasvar';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { AddUrsgalZasvarFile } from './AddUrsgalZasvarFile';

const { Search } = Input;

export const UrsgalZasvarAll = ({
  onCancel = () => {},
  visibleUrsgalZasvarAll,
}) => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);
  const [form] = Form.useForm();

  const [visibleUrsgalZasvarAdd, setVisibleUrsgalZasvarAdd] = useState(false);
  const [visibleFilersgalZasvar, seTvisibleFilersgalZasvar] = useState(false);
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });
  const [pagenationData, seTpagenationData] = useState({
    page: 1,
    size: 20,
    burtgel: '',
    hurungu: '',
  });

  const onSearch = (values) => {
    if (values) {
      seTpagenationData({
        ...pagenationData,
        burtgel: values,
        hurungu: form.getFieldValue('search'),
      });
    } else {
      seTpagenationData({
        ...pagenationData,
        burtgel: '',
        hurungu: form.getFieldValue('search'),
      });
    }
  };

  function select_data() {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const { page, size, burtgel, hurungu } = pagenationData;
    const month = '13';
    const search = { burtgel, hurungu };
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'ursgal_zasvar_page',
        page,
        size,
        search,
        month,
      },
    });
    dispatch(start_loading({}));
  }

  useEffect(() => {
    if (visibleUrsgalZasvarAll) {
      select_data();
    }
  }, [visibleUrsgalZasvarAll]);

  useEffect(() => {
    if (!visibleUrsgalZasvarAdd && visibleUrsgalZasvarAll) {
      select_data();
    }
  }, [visibleUrsgalZasvarAdd, pagenationData]);

  const deleteUrgalZasvar = (id) => {
    socket.on('request_out', (req_data) => {
      socket.removeAllListeners('request_out');
      if (req_data.status === '0') {
        select_data();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: { action_type: 'ursgal_zasvar_delete', id },
    });
    dispatch(start_loading({}));
  };

  const after_handleUpload = (content) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        select_data();
        newAlert({ type: 'success', msg: data.message });
      } else {
        newAlert({ type: 'error', msg: data.message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: {
        action_type: 'ursgal_zasvar_file',
        file_name: content?.json_array,
      },
    });
    dispatch(start_loading({}));
  };

  const title = () => (
    <Row>
      {version?.is_first_year && (
        <CanView allowedRole="006_1">
          <Button
            className="add-btn"
            type="primary"
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              setVisibleUrsgalZasvarAdd(true);
            }}
          >
            Урсгал засвар нэмэх
          </Button>
          <Button
            className="add-btn"
            type="primary"
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              seTvisibleFilersgalZasvar(true);
            }}
          >
            Файл оруулах
          </Button>
        </CanView>
      )}
      <Form.Item name="search" style={{ margin: 0, marginRight: 10 }}>
        <Input
          width={200}
          size="small"
          allowClear
          placeholder="Хөрөнгийн код..."
        />
      </Form.Item>
      <Form.Item name="search2" style={{ margin: 0 }}>
        <Search
          width={200}
          size="small"
          allowClear
          placeholder="Бүртгэлийн дугаар..."
          onSearch={onSearch}
        />
      </Form.Item>
    </Row>
  );

  const onCancelAdd = () => {
    setVisibleUrsgalZasvarAdd(false);
  };

  const getParm = (values) => {
    if (
      // eslint-disable-next-line operator-linebreak
      values.page !== pagenationData.page ||
      values.size !== pagenationData.size
    ) {
      seTpagenationData({
        ...pagenationData,
        page: values.page,
        size: values.size,
      });
    }
  };

  return (
    <Drawer
      title="Татварын суурь - Өртгийн өөрчлөлт"
      open={visibleUrsgalZasvarAll}
      width="80%"
      footer={null}
      closable
      maskClosable={false}
      onClose={onCancel}
      className="custom-drawer"
    >
      <AddUrsgalZasvarFile
        visible={visibleFilersgalZasvar}
        onClose={() => {
          seTvisibleFilersgalZasvar(false);
        }}
        after_handleUpload={after_handleUpload}
      />
      <UrsgalZasvar
        zasvarVisible={visibleUrsgalZasvarAdd}
        onCancel={onCancelAdd}
      />
      <div className="inside-table no-scroll">
        <Form form={form} className="register-form" size="small">
          <Table
            title={title}
            columns={UsgalZasvarAllColumns({ deleteUrgalZasvar })}
            dataSource={tableData.result_list}
            scroll={{
              y: 'calc(100vh - 310px)',
            }}
            subTotal={tableData.total_size}
            getParam={getParm}
          />
        </Form>
      </div>
    </Drawer>
  );
};
