export const searchList = [
  {
    type: 'input',
    title: 'Дансны дугаар',
    children: {
      queryKey: 'dans_dugaar',
    },
  },
  {
    type: 'select',
    title: 'Дансны төрөл',
    children: {
      queryKey: 'turul',
      list: [
        {
          value: 'Бүгд',
          name: 'Бүгд',
        },
        {
          value: 'Орлого',
          name: 'Орлого',
        },
        {
          value: 'Баланс',
          name: 'Баланс',
        },
        
      ],
    },
  },
];
