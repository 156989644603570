import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Upload,
  Select,
  message,
  Row,
  Col,
  Modal,
  Checkbox,
} from 'antd';
import newAlert from 'src/components/newAlert';
import { useSelector, useDispatch } from 'react-redux';
import { start_loading, end_loading } from 'src/core/actions/loader';
import axios from 'src/core/utils/axios';
import { CloudUploadOutlined } from '@ant-design/icons';

const sourceList = {
  1: 'Диамонд',
  2: 'Смарт ложик',
  3: 'Эрхэт',
  4: 'Такс',
};

const typesList = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
const { Option } = Select;

export const FileUpload = ({ visible = true, onCancel = () => {} }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [token, setToken] = useState('');
  const [fileList, setFileList] = useState({
    bor: false,
    ha: false,
    bor_file_has: false,
    ha_file_has: false,
  });
  const { socket, data_date } = useSelector((state) => state);

  function appendFormData(data, formData, parentKey = '') {
    if (data === null || data === undefined) return;
    if (data instanceof File) {
      formData.append(parentKey, data);
    } else if (data && typeof data === 'object' && !Array.isArray(data)) {
      Object.keys(data).forEach((key) => {
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;
        appendFormData(data[key], formData, fullKey);
      });
    } else {
      formData.append(parentKey, data);
    }
  }

  const onFinish = async (data) => {
    console.log('🚀 ~ onFinish ~ data:', data);
    dispatch(start_loading({}));
    const formData = new FormData();

    const dataToAppend = {
      bor_merge_ebarimt: data?.bor_merge_ebarimt || false,
      year: data_date?.year,
      month: data?.month,
      type: data?.file_type,
      token,
      borluulalt: {
        nehemjleh: data?.nehemjleh?.[0]?.originFileObj ?? null,
        borluulalt: data?.tolbor_barimt?.[0]?.originFileObj ?? null,
        nbb: data?.bor_file?.[0]?.originFileObj ?? null,
      },
      hudaldan_awalt: {
        gaaliin_medeelel: data?.gaali?.[0]?.originFileObj ?? null,
        ha_burtgel: data?.ha_burtgel?.[0]?.originFileObj ?? null,
        hoishlogdson_padan: data?.hoish_padaan?.[0]?.originFileObj ?? null,
        tolbor_barimt_jagsaalt:
          data?.ha_tolbor_barimt?.[0]?.originFileObj ?? null,
        uh_tentsuuleh: data?.huvi_tents?.[0]?.originFileObj ?? null,
      },
    };

    appendFormData(dataToAppend, formData);

    try {
      const { status, key } = await axios.post(
        'noat',
        '/request_web',
        formData
      );

      if (status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгаллаа' });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    } catch (error) {
      newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      dispatch(end_loading({}));
    }
  };

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setToken(data?.content.result_json.noat_token);
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss013_03_view',
      content: { action_type: 'get_noat_token' },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    select_data();
  }, []);

  const normFile = (e) => (Array.isArray(e) ? e : e?.fileList);

  const handleFormChange = () => {
    const temp_files = {
      bor: form.getFieldValue('bor_file')?.length > 0,
      ha: form.getFieldValue('ha_file')?.length > 0,
      bor_file_has: form.getFieldValue('bor_file_has'),
      ha_file_has: form.getFieldValue('ha_file_has'),
    };
    setFileList(temp_files);
  };

  const handleBeforeUpload = (file, name) => {
    const isValidFileType = typesList.includes(file.type);
    const isFileSizeValid = file.size / 1024 / 1024 < 15;

    if (!isValidFileType) {
      message.error(`${file.name} файл excel форматаар байх шаардлагатай`);
    } else if (!isFileSizeValid) {
      message.error(`${file.name} 15MB-аас доош хэмжээтэй байх шаардлагатай`);
    }

    return isValidFileType && isFileSizeValid;
  };

  return (
    <Modal
      visible={visible}
      className="file-upload-form"
      title="Файл оруулах"
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        className="file-upload-form"
        onFinish={onFinish}
        onFieldsChange={handleFormChange}
      >
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item
              name="month"
              label="Сар"
              rules={[{ required: true, message: 'Сар сонгоно уу' }]}
            >
              <Select showSearch>
                {Array.from({ length: 12 }, (_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="file_type"
              label="Файлын төрөл"
              rules={[{ required: true, message: 'Файлын төрөл сонгоно уу' }]}
            >
              <Select showSearch>
                {Object.values(sourceList).map((value, index) => (
                  <Option key={index} value={index + 1}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <div style={{ textAlign: 'center' }}>
              <span style={{ backgroundColor: 'white', padding: '0 10px' }}>
                Борлуулалт
              </span>
            </div>
            <hr />
          </Col>

          <Col span={24}>
            <Form.Item name="bor_file_has" valuePropName="checked">
              <Checkbox>Борлуулалт</Checkbox>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="bor_file"
              label="Борлуулалтын файл"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                { required: fileList.bor_file_has, message: 'Файл сонгоно уу' },
              ]}
            >
              <Upload
                disabled={!fileList.bor_file_has}
                accept={typesList.join()}
                showUploadList
                maxCount={1}
                beforeUpload={(file) => handleBeforeUpload(file, 'bor_file')}
              >
                <Button icon={<CloudUploadOutlined />} type="dashed">
                  Файл оруулах
                </Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="nehemjleh"
              label="Нэмэгдэл төлбөрийн падаан"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                disabled={!fileList.bor_file_has}
                accept={typesList.join()}
                showUploadList
                maxCount={1}
                beforeUpload={(file) => handleBeforeUpload(file, 'nehemjleh')}
              >
                <Button icon={<CloudUploadOutlined />} type="dashed">
                  Файл оруулах
                </Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="tolbor_barimt"
              label="Төлбөрийн баримт"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                disabled={!fileList.bor_file_has}
                accept={typesList.join()}
                showUploadList
                maxCount={1}
                beforeUpload={(file) =>
                  handleBeforeUpload(file, 'tolbor_barimt')
                }
              >
                <Button icon={<CloudUploadOutlined />} type="dashed">
                  Файл оруулах
                </Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={24}>
            <div style={{ textAlign: 'center' }}>
              <span style={{ backgroundColor: 'white', padding: '0 10px' }}>
                Худалдан авалт
              </span>
            </div>
            <hr />
          </Col>
          <Col span={24}>
            <Form.Item name="ha_file_has" valuePropName="checked">
              <Checkbox>Худалдан авалт</Checkbox>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="ha_burtgel"
              label="Худалдан авалтын бүртгэл"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                { required: fileList.ha_file_has, message: 'Файл сонгоно уу' },
              ]}
            >
              <Upload
                disabled={!fileList.ha_file_has}
                accept={typesList.join()}
                showUploadList
                maxCount={1}
                beforeUpload={(file) => handleBeforeUpload(file, 'ha_burtgel')}
              >
                <Button icon={<CloudUploadOutlined />} type="dashed">
                  Файл оруулах
                </Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="gaali"
              label="Гаалийн мэдүүлэг"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                disabled={!fileList.ha_file_has}
                accept={typesList.join()}
                showUploadList
                maxCount={1}
                beforeUpload={(file) => handleBeforeUpload(file, 'gaali')}
              >
                <Button icon={<CloudUploadOutlined />} type="dashed">
                  Файл оруулах
                </Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="huvi_tents"
              label="Хувь тэнцүүлэн хасах падаанаас хэрэгжүүлсэн"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                disabled={!fileList.ha_file_has}
                accept={typesList.join()}
                showUploadList
                maxCount={1}
                beforeUpload={(file) => handleBeforeUpload(file, 'huvi_tents')}
              >
                <Button icon={<CloudUploadOutlined />} type="dashed">
                  Файл оруулах
                </Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="hoish_padaan"
              label="Хойшлогдсон падаанаас хэрэгжүүлсэн"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                disabled={!fileList.ha_file_has}
                accept={typesList.join()}
                showUploadList
                maxCount={1}
                beforeUpload={(file) =>
                  handleBeforeUpload(file, 'hoish_padaan')
                }
              >
                <Button icon={<CloudUploadOutlined />} type="dashed">
                  Файл оруулах
                </Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="ha_tolbor_barimt"
              label="Төлбөрийн баримтаар шивсэн"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                disabled={!fileList.ha_file_has}
                accept={typesList.join()}
                showUploadList
                maxCount={1}
                beforeUpload={(file) =>
                  handleBeforeUpload(file, 'ha_tolbor_barimt')
                }
              >
                <Button icon={<CloudUploadOutlined />} type="dashed">
                  Файл оруулах
                </Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={24} className="text-align-right">
            <Form.Item noStyle>
              <Button
                onClick={() => {
                  form.resetFields();
                  onCancel();
                }}
                className="file-upload-cancel-btn"
                type="default"
                shape="round"
                style={{ marginRight: '8px' }}
              >
                Цуцлах
              </Button>
              <Button
                className="file-upload-confirm-btn"
                type="primary"
                shape="round"
                htmlType="submit"
                disabled={!fileList.ha_file_has && !fileList.bor_file_has}
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
