import React from 'react';
import { Tabs as AntTabs } from 'antd';
import { useQuery } from 'src/core/utils/formater';
import { useHistory } from 'react-router-dom';

const Table1 = () => (
  <div
    style={{
      background: 'red',
      height: '200px !important',
    }}
  >
    Tab 1
  </div>
);

const Table2 = () => (
  <div
    style={{
      background: 'yellow',
    }}
  >
    Tab 2
  </div>
);

const Table3 = () => (
  <div
    style={{
      background: 'black',
    }}
  >
    Tab 3
  </div>
);

const { TabPane } = AntTabs;

const _listTabs = [
  {
    key: '1',
    title: 'Table 1',
    content: <Table1 />,
  },
  {
    key: '2',
    title: 'Table 2',
    content: <Table2 />,
  },
  {
    key: '3',
    title: 'Table 3',
    content: <Table3 />,
  },
];

const TableTabs = ({ listTabs = _listTabs, noTabs = false }) => {
  const query = useQuery();
  const history = useHistory();

  const onChange = (key) => {
    const propName = 'tab';
    if (query.get(propName)) {
      query.set(propName, key);
    } else {
      query.append(propName, key);
    }
    history.push({ search: query.toString() });
  };
  return (
    <div className={`table-tabs-bottom ${noTabs ? 'no-tabs' : ''}`}>
      <AntTabs
        tabPosition="bottom"
        activeKey={query.get('tab') ? query.get('tab') : '1'}
        onChange={onChange}
        type="card"
        forceRender
      >
        {listTabs.map((item) => (
          <TabPane tab={item?.title} key={item.key}>
            <div className="inside-container">{item?.content}</div>
          </TabPane>
        ))}
      </AntTabs>
    </div>
  );
};

export default TableTabs;
