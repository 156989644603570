import React, { useState, useEffect } from 'react';
import { Input, Button, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { CloseCircleOutlined } from '@ant-design/icons';
import logo from 'src/assets/images/e-tax-logo.svg';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';

const Login = () => {
  const [error, seTerror] = useState(false);
  const socket = useSelector((state) => state.socket);
  const dispatch = useDispatch();
  const [regNum, seTregNum] = useState({ reg_num: null, ttd: null });
  const mainData = async () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        seTregNum({
          ttd: content?.company_data?.tatvar_tologch_dugaar,
          reg_num: content?.company_data?.company_register_num,
        });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss005_company_manage',
      content: { action_type: 'get_company_data' },
    });
    dispatch(start_loading({}));
  };

  const onFinish = async (values) => {
    if (regNum.ttd) {
      const temp_data = {
        values,
        action_type: 'etax_login',
        ttd: regNum.ttd,
        reg_num: regNum.reg_num,
      };
      socket.on('request_out', ({ status, content }) => {
        socket.removeAllListeners('request_out');
        newAlert({
          type: status === '0' ? 'success' : 'error',
          msg: content.Message,
          functions: {
            onSuccess: [
              // eslint-disable-next-line no-param-reassign
              delete content?.Message,
              localStorage.setItem('etaxtoken', JSON.stringify(content)),
              localStorage.setItem('ttd', content?.ttd),
              seTerror(false),
            ],
            onFail: seTerror(true),
          },
        });

        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ss012_manage',
        content: temp_data,
      });
      dispatch(start_loading({}));
    } else {
      newAlert({ type: 'error', msg: 'Татвар төлөгчийн дугаар бөглөнө үү' });
    }
  };

  useEffect(() => {
    console.log('>> Getting old list...');
    mainData();
  }, []);
  return (
    <div className="e-tax-login">
      <div className="container">
        <div className="header">
          <img
            style={{
              marginBottom: 32,
            }}
            width={200}
            src={logo}
            alt="e-tax"
          />
          {error && (
            <div className="error">
              <CloseCircleOutlined className="close-icon" />{' '}
              <span>Хэрэглэгчийн нэр эсвэл нууц үг буруу байна!</span>
            </div>
          )}
        </div>
        <Form className="ww-100" onFinish={onFinish}>
          <Form.Item name="pin" className="user">
            {regNum.ttd}
          </Form.Item>
          <Form.Item name="username">
            <Input placeholder="Нэвтрэх нэр" className="user" />
          </Form.Item>
          <Form.Item name="password">
            <Input.Password placeholder="Нууц үг" className="user" />
          </Form.Item>

          <Button htmlType="submit">Нэвтрэх</Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
