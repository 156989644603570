import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PlusCircleOutlined } from '@ant-design/icons';
import { onlyNumber, replaceFloat } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import CustomDatePicker from 'src/components/common/datePicker';
import Table from 'src/components/common/table';
import {
  Button as AntButton,
  Modal,
  Input,
  Form,
  Select,
  Row,
  Col,
  Radio,
} from 'antd';

import { start_loading, end_loading } from 'src/core/actions/loader';
import { columns, customSummary } from './dataStructure/data';

const { Option } = Select;

export default function Client() {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const [form] = Form.useForm();
  const [visible, seTvisible] = useState(false);
  const [update, setUpdate] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [hariltsagchList, setHariltsagchList] = useState([]);
  const data_date = useSelector((state) => state.data_date);

  const onCancel = () => {
    seTvisible(false);
  };
  const UpdateCancel = () => {
    setUpdate(false);
  };

  const send_tatvar_data = (data) => {
    const { tatvar_dun, tatvar_tailbar, tatvar_id, ..._data } = data;
    const values = {
      dun: onlyNumber(tatvar_dun),
      tatvar_id,
      tailbar: tatvar_tailbar,
      ..._data,
    };
    form.setFieldsValue({ ...values });
    seTvisible(true);
  };

  const mainData = () => {
    socket.on('request_out', ({ status, content }) => {
      console.log('🚀 ~ file: index.js:55 ~ socket.on ~ content', content);
      socket.removeAllListeners('request_out');
      if (status === '0') {
        setHariltsagchList(content.hariltsagch_list);
        socket.on(
          'request_out',
          ({ status: status_second, content: content_second }) => {
            socket.removeAllListeners('request_out');
            if (status_second === '0') {
              const new_temp_data = content_second?.json_array.map(
                (inner_data, key) => ({
                  id: key + 1,
                  ...inner_data,
                })
              );
              setTableDataSum(content_second?.sum_dun);
              if (new_temp_data) {
                setTableData(new_temp_data);
                dispatch(end_loading({}));
              }
            }
          }
        );
        socket.emit('request_in', {
          channel: 'ts004_00_manage_huugiin_zardal',
          content: { action_type: 'get_input_data' },
        });
      }
    });
    socket.emit('request_in', {
      channel: 'send_hariltsagch_list_zardal',
      content: { return_channel: 'request_out', month: data_date?.month },
    });

    dispatch(start_loading({}));
  };
  useEffect(() => {
    console.log('>> Getting old list...');
    mainData();
  }, []);

  const delete_row = (id) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: mainData(), onFail: mainData() },
      });
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_manage_huugiin_zardal',
      content: {
        zardal_id: id,
        action_type: 'delete_huugiin_zardal',
      },
    });

    dispatch(start_loading({}));
  };

  const title = () => (
    <>
      <AntButton
        id="first"
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          seTvisible(true);
        }}
      >
        Нэмэх
      </AntButton>
    </>
  );

  const AddValue = ({ onCancel = () => {} }) => {
    const onFinish = (data) => {
      console.log('-----onFinish------<', data);
      const findedData = hariltsagchList.find(
        (i) => i.hariltsagch_name === data?.hariltsagch
      );
      const confirmData = {
        ttd: findedData?.ttd,
        turul: findedData?.turul,
        horongo_dun: findedData?.dansnii_une,
        hariltsagch_turul: findedData?.hariltsagch_turul,
        ...data,
        dun: replaceFloat(data?.dun),
      };

      socket.on('request_out', ({ status, content }) => {
        socket.removeAllListeners('request_out');
        newAlert({
          type: status === '0' ? 'success' : 'error',
          msg: content.Message,
          functions: {
            onSuccess: [form.resetFields(), mainData(), onCancel()],
          },
        });

        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts004_00_manage_huugiin_zardal',
        content: {
          array_data: confirmData,
          action_type: 'add_huugiin_zardal',
        },
      });

      dispatch(start_loading({}));
    };
    return (
      <>
        <Form
          form={form}
          name="add"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          className="medium-form"
          initialValues={{ type: 1 }}
          size="medium"
          onFinish={onFinish}
        >
          <Form.Item
            label="Огноо:"
            name="ognoo"
            labelAlign="right"
            rules={[{ required: true, message: 'Огноо сонгоно уу' }]}
          >
            <CustomDatePicker customClass="ww-60" />
          </Form.Item>
          <Form.Item
            label="Харилцагч:"
            name="hariltsagch"
            labelAlign="right"
            rules={[{ required: true, message: 'Эх сурвалж сонгоно уу!' }]}
          >
            <Select
              placeholder="Сонгох"
              style={{ width: '100%', fontSize: 13 }}
            >
              {hariltsagchList.map((i) => (
                <Option value={i.hariltsagch_name}>{i.hariltsagch_name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const spider = getFieldValue('hariltsagch');
              const findedData = hariltsagchList.find(
                (i) => i.hariltsagch_name === spider
              );
              if (findedData) {
                return (
                  <>
                    <Form.Item
                      label="Татвар төлөгчийн дугаар"
                      labelAlign="right"
                      // name="ttd"
                    >
                      <Input value={findedData?.ttd || 'Хоосон'} disabled />
                    </Form.Item>
                    <Form.Item label="Төрөл" labelAlign="right">
                      <Input
                        value={findedData?.hariltsagch_turul || 'Хоосон'}
                        disabled
                      />
                    </Form.Item>
                  </>
                );
              }
            }}
          </Form.Item>

          <Form.Item
            label="Хүүгийн зардлын дүн:"
            name="dun"
            labelAlign="right"
            rules={[{ required: true, message: 'Хүүгийн зардлын дүн' }]}
            normalize={onlyNumber}
          >
            <Input bordered />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 16 }}
            label="Хүүгийн орлого олохтой холбогдон гарсан эсэх:"
            name="type"
            labelAlign="right"
            rules={[{ required: true, message: 'Заавал сонгох' }]}
          >
            <Radio.Group
              name="radiogroup"
              defaultValue={1}
              optionType="button"
              buttonStyle="solid"
            >
              <Radio value={1}>Тийм</Radio>
              <Radio value={2}>Үгүй</Radio>
            </Radio.Group>
          </Form.Item>

          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <AntButton
                  onClick={() => {
                    form.resetFields();
                    onCancel();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </AntButton>
                <AntButton
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </AntButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  return (
    <>
      <div className="table-antd-div zardal-container-huu">
        <Table
          columns={columns({
            send_tatvar_data,
            delete_row,
          })}
          dataSource={tableData}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableDataSum,
          }}
          title={title}
        />
      </div>

      <Modal
        title="Хүүгийн зардлын дүн бүртгэх"
        visible={visible}
        width={550}
        footer={null}
        onCancel={onCancel}
        wrapClassName="custom-modal"
      >
        <AddValue onCancel={onCancel} />
      </Modal>
      <Modal
        title="Хүүгийн зардлын дүн шинэчлэх"
        visible={update}
        width={550}
        footer={null}
        onCancel={UpdateCancel}
        wrapClassName="custom-modal"
      >
        <AddValue onCancel={UpdateCancel} />
      </Modal>
    </>
  );
}
