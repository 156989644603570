const notification_count = 0;

const reducer = (state = notification_count, action) => {
  switch (action.type) {
    case 'add_notification':
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
