import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Table as AntTable } from 'antd';
import newAlert from 'src/components/newAlert';
import Table from 'src/components/common/table';
import { roundFloat, useQuery } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { dans_columns } from '../dataStructure/data';

const TabDans = () => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);

  const history = useHistory();
  const query = useQuery();

  const [tableData2, setTableData2] = useState([]);
  const [avlagaSum, setAvlagaSum] = useState({});
  const [uglugSum, setUglugSum] = useState({});
  const [totalData, setTotalData] = useState(5);

  const handleSelectDans = (dans_code) => {
    const propName = 'dans_code';
    const propName2 = 'tab';
    const proplist = ['Hariltsagch', 'page2', 'size2', 'page3', 'size3'];
    if (query.get(propName)) {
      query.set(propName, dans_code);
    } else {
      query.append(propName, dans_code);
    }
    if (query.get(propName2)) {
      query.set(propName2, '2');
    } else {
      query.append(propName2, '2');
    }
    proplist.forEach((name) => {
      if (query.get(name)) {
        query.delete(name);
      }
    });
    history.push({ search: query.toString() });
  };

  useEffect(() => {
    const fetchData = async () => {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');

        if (data.content?.status === '0') {
          setTableData2(data.content?.json_array);
          setAvlagaSum(data.content?.avlaga_sum);
          setUglugSum(data.content?.uglug_sum);
          setTotalData(data.content?.total);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }

        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts002_00_tur_zuruu',
        content: {
          action_type: 'fetch_tur_zuruu_dansaar',
          is_first_year: true,
          version: version.version_number,
          year: version.year,
          page: query.get('page') || 1,
          size: query.get('size') || 20,
        },
      });
      dispatch(start_loading({}));
    };

    if (query.get('tab')) {
      if (query.get('tab') === '1') {
        fetchData();
      }
    } else {
      fetchData();
    }
  }, [query]);

  return (
    <>
      <div className="table-wrapper">
        <div className="inside-table no-scroll">
          <Table
            columns={dans_columns(handleSelectDans)}
            dataSource={tableData2}
            scroll={{
              y: 'calc(100vh - 360px)',
            }}
            total={totalData}
            customize={() => (
              <AntTable.Summary fixed>
                <AntTable.Summary.Row>
                  <AntTable.Summary.Cell align="right">
                    Авлагын нийт дүн:
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(avlagaSum.etssiin_dun)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(avlagaSum.etssiin_dun_tatvar)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(avlagaSum.zuruu)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(avlagaSum.hz_hasagdah_tur_zuruu)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(avlagaSum.hz_tatvar_tur_zuruu)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(avlagaSum.hasagdah_tur_zuruu)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(avlagaSum.tatvar_tur_zuruu)}
                  </AntTable.Summary.Cell>
                </AntTable.Summary.Row>
                <AntTable.Summary.Row>
                  <AntTable.Summary.Cell align="right">
                    Өглөгийн нийт дүн:
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(uglugSum.etssiin_dun)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(uglugSum.etssiin_dun_tatvar)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(uglugSum.zuruu)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(uglugSum.hz_hasagdah_tur_zuruu)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(uglugSum.hz_tatvar_tur_zuruu)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(uglugSum.hasagdah_tur_zuruu)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(uglugSum.tatvar_tur_zuruu)}
                  </AntTable.Summary.Cell>
                </AntTable.Summary.Row>
              </AntTable.Summary>
            )}
          />
        </div>
      </div>
    </>
  );
};
export default TabDans;
