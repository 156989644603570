export const districts = [
  { name: 'Дүүрэг - Хан-Уул', val: 23 },
  { name: 'Дүүрэг - Баянзүрх', val: 24 },
  { name: 'Дүүрэг - Сүхбаатар', val: 25 },
  { name: 'Дүүрэг - Баянгол', val: 26 },
  { name: 'Дүүрэг - Багануур', val: 27 },
  { name: 'Дүүрэг - Багахангай', val: 28 },
  { name: 'Дүүрэг - Налайх', val: 29 },
  { name: 'Дүүрэг - Сонгинохайрхан', val: 34 },
  { name: 'Дүүрэг - Чингэлтэй', val: 35 },
  { name: 'Архангай', val: 1 },
  { name: 'Баян-Өлгий', val: 2 },
  { name: 'Баянхонгор', val: 3 },
  { name: 'Булган', val: 4 },
  { name: 'Говь-Алтай', val: 5 },
  { name: 'Дорноговь', val: 6 },
  { name: 'Дорнод', val: 7 },
  { name: 'Дундговь', val: 8 },
  { name: 'Завхан', val: 9 },
  { name: 'Өвөрхангай', val: 10 },
  { name: 'Өмнөговь', val: 11 },
  { name: 'Сүхбаатар', val: 12 },
  { name: 'Сэлэнгэ', val: 13 },
  { name: 'Төв', val: 14 },
  { name: 'Увс', val: 15 },
  { name: 'Ховд', val: 16 },
  { name: 'Хөвсгөл', val: 17 },
  { name: 'Хэнтий', val: 18 },
  { name: 'Дархан-Уул', val: 19 },
  { name: 'Орхон', val: 20 },
  { name: 'Говьсүмбэр', val: 32 },
];
