import React from 'react';
import { Collapse } from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  CloseSquareOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { open_search } from 'src/core/actions/searchField';
import componentList from './component';

const { Panel } = Collapse;

// eslint-disable-next-line no-confusing-arrow
const expandIcon = ({ isActive }) =>
  isActive ? (
    <MinusOutlined className="icon" />
  ) : (
    <PlusOutlined className="icon" />
  );

const Search = ({ hide = false }) => {
  const dispatch = useDispatch();

  const { search_field: list } = useSelector((state) => ({
    search_field: state?.search_field,
  }));
  const onClose = () => {
    dispatch(open_search(false));
  };

  const keyList = list.map((_, idx) => idx);

  if (list && list.length > 0) {
    return (
      <div className={`search-container ${hide ? 'hide' : ''} `}>
        <div className="header">
          <span>Хайлт хийх:</span>
          <CloseSquareOutlined onClick={onClose} className="fs-20" />
        </div>
        <Collapse
          className="custom-collapse"
          defaultActiveKey={keyList}
          expandIconPosition="right"
          ghost
          expandIcon={expandIcon}
        >
          {list.map((element, idx) => {
            const Compo = componentList[element?.type];
            return (
              <Panel
                header={element?.title}
                // eslint-disable-next-line react/no-array-index-key
                key={`pnl-${idx}`}
                className={`${idx === 0 ? 'first' : ''}`}
              >
                <Compo {...element?.children} />
              </Panel>
            );
          })}
        </Collapse>
      </div>
    );
  }

  return null;
};

export default Search;

// Type List

// const searchList = [
//   {
//     type: 'checkList',
//     title: 'Төрөл',
//     children: {
//       list: customList,
//     },
//   },
//   {
//     type: 'ranger',
//     title: 'Үнэ',
//     children: {
//       max: 200000,
//     },
//   },
//   {
//     type: 'date',
//     title: 'Дэйт',
//   },
//   {
//     type: 'dateRange',
//     title: 'ДэйтРанжэ',
//   },
//   {
//     type: 'input',
//     title: 'Инпут',
//   },
//   {
//     type: 'select',
//     title: 'Сэлэкт',
//     children: {
//       list: [
//         {
//           value: '1',
//           name: 'Сонголт 1',
//         },
//         {
//           value: '2',
//           name: 'Сонголт 2',
//         },
//         {
//           value: '3',
//           name: 'Сонголт 3',
//         },
//       ],
//     },
//   },
// ];
