import React, { useState, useEffect } from 'react';
import { PlusCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { start_loading, end_loading } from 'src/core/actions/loader';
import Table from 'src/components/common/table';
import { Form, Button as AntButton, Modal, Input, Row, Col } from 'antd';
import { onlyNumber, useQuery } from 'src/core/utils/formater';
import axios from 'src/core/utils/axios';
import { FileError } from 'src/components/common/file_error';
import newAlert from 'src/components/newAlert';
import { NewFileAddModal, NewValueAdd } from './newValueAdd';

import { customSummary, columns } from './dataStructure/data';

const { TextArea } = Input;

const Hansh = ({
  form = null,
  onClose = () => {},
  socket = null,
  dispatch = null,
  refetch = () => {},
}) => {
  const submit = (hashData) => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');

      newAlert({
        type: data_temp.status === '0' ? 'success' : 'error',
        msg:
          data_temp.status === '0'
            ? 'Дансны мэдээлэл амжилттай хадгалагдлаа'
            : 'Системд алдаа гарлаа',
        functions: { onSuccess: [onClose(), refetch()], onFail: refetch() },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_tomilolt_service',
      content: {
        array_data: hashData,
        action_type: 'update_hansh',
      },
    });

    dispatch(start_loading({}));
  };

  const onFinish = (values) => {
    const data = form.getFieldsValue(true);
    const confirmData = { ...data, ...values };
    submit(confirmData);
  };

  return (
    <>
      <Form
        form={form}
        name="hansh"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        className="medium-form"
        size="medium"
        onFinish={onFinish}
      >
        <Form.Item
          label="Ханш"
          name="hansh"
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Тайлбар"
          name="hansh_tailbar"
          // rules={[{ required: true, message: '' }]}
        >
          <TextArea
            autoSize={{ minRows: 3, maxRows: 5 }}
            // bordered={false}
            disabled={false}
            className="ww-60"
            maxLength={300}
            showCount
          />
        </Form.Item>
      </Form>
      <Row
        style={{
          marginTop: 8,
        }}
      >
        <Col span={24} style={{ textAlign: 'right' }}>
          <AntButton
            className="cancel-btn"
            type="primary"
            shape="round"
            size="small"
            onClick={onClose}
          >
            Буцах
          </AntButton>
          <AntButton
            form="hansh"
            className="confirm-btn ml-5"
            type="primary"
            shape="round"
            htmlType="submit"
            size="small"
          >
            Хадгалах
          </AntButton>
        </Col>
      </Row>
    </>
  );
};

const Client = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const page = query.get('page');
  const size = query.get('size');
  const { socket, version } = useSelector((state) => state);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [fileForm] = Form.useForm();
  const [tableDataSum, setTableDataSum] = useState({});
  const [visible, seTvisible] = useState({ main: false, sub: false });
  const [tableData, setTableData] = useState([]);
  const [tomiloltDunData, settomiloltDunData] = useState([]);
  const [fileVisible, setFileVisible] = useState(false);
  const [total, seTtotal] = useState([]);

  const mainData = () => {
    socket.on('request_out', ({ status, content }) => {
      console.log(content);
      socket.removeAllListeners('request_out');
      if (status === '0') {
        settomiloltDunData(content);
        setTableDataSum(content?.sum_data);
        seTtotal(content?.total);
        if (content?.json_array) {
          const new_temp_data = content?.json_array.map((inner_data, key) => ({
            id: key + 1,
            ...inner_data,
          }));
          if (new_temp_data) {
            setTableData(new_temp_data);
          }
        }
      } else {
        newAlert({ type: 'error', msg: content.Message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_tomilolt_service',
      content: {
        action_type: 'get_tomilolt_input_data',
        page,
        size,
        search: {},
      },
    });

    dispatch(start_loading({}));
  };

  const delete_row = (id) => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      newAlert({
        type: data_temp.status === '0' ? 'success' : 'error',
        msg:
          data_temp.status === '0'
            ? 'Томилолтын дүн амжилттай устлаа'
            : data_temp.content.Message,

        functions: { onSuccess: mainData() },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_tomilolt_service',
      content: {
        id,
        action_type: 'delete_tomilolt',
      },
    });

    dispatch(start_loading({}));
  };

  const handleSaveTable = (data) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content?.Message,
        functions: { onSuccess: mainData() },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_tomilolt_service',
      content: {
        action_type: 'insert_file_data',
        json_array: data,
      },
    });
  };

  const handleUpload = async (selectedFile) => {
    if (selectedFile) {
      dispatch(start_loading({}));
      const { size: file_size } = selectedFile.file[0];
      const formData = new FormData();
      formData.append('file', selectedFile.file[0].originFileObj);
      const { status, content } = await axios.post(
        'first',
        '/zardal_tomilolt',
        formData,
        {
          file_size,
          file_source: '',
          year: version?.year_end,
        }
      );
      if (status === '0') {
        handleSaveTable(content?.json_array);
      } else {
        if (content?.type === 'content') {
          FileError({
            text: content?.Message,
          });
        } else {
          newAlert({ type: 'error', msg: content.Message });
        }

        dispatch(end_loading({}));
      }
    }
  };

  useEffect(() => {
    mainData();
  }, [query]);

  const controller = (type = '', _visible = '') => {
    const name = !type ? 'main' : 'sub';
    seTvisible((prev) => ({ ...prev, [name]: !_visible }));
    if (_visible) {
      form.resetFields();
    }
  };
  const onEdit = (data) => {
    const {
      unaa_nisleg,
      tomilolt_hun_honog,
      guitsetgel_tomilolt_zardal,
      ..._data
    } = data;
    const values = {
      unaa_nisleg: onlyNumber(unaa_nisleg),
      tomilolt_hun_honog: onlyNumber(tomilolt_hun_honog),
      guitsetgel_tomilolt_zardal: onlyNumber(guitsetgel_tomilolt_zardal),
      ..._data,
    };
    form.setFieldsValue({ ...values });
    controller();
  };

  const hanshEdit = (data) => {
    form1.setFieldsValue({ ...data });
    controller('_');
  };
  const warning = () => {};

  const title = () => (
    <div>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => controller()}
        id="first"
      >
        Нэмэх
      </AntButton>

      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<UpCircleOutlined />}
        onClick={() => {
          setFileVisible(true);
        }}
      >
        Файл оруулах
      </AntButton>
    </div>
  );

  return (
    <>
      <div className="table-antd-div">
        <Table
          columns={columns({ onEdit, delete_row, hanshEdit, warning })}
          title={title}
          scroll={{
            x: 1500,
          }}
          total={total}
          dataSource={tableData}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableDataSum,
          }}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Томилолтын зардал нэмэх"
        visible={visible?.main}
        closable={false}
        maskClosable={false}
        width={700}
        footer={null}
      >
        <NewValueAdd
          form={form}
          close={() => controller(null, '_')}
          data={tomiloltDunData}
          socket={socket}
          dispatch={dispatch}
          refetch={mainData}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Ханшийн дүн өөрчлөх"
        visible={visible?.sub}
        closable={false}
        maskClosable={false}
        footer={null}
        width={500}
      >
        <Hansh
          form={form1}
          onClose={() => controller('_', '_')}
          socket={socket}
          dispatch={dispatch}
          refetch={mainData}
        />
      </Modal>

      <Modal
        className="custom-modal"
        title="Томилолтын зардал файлаас бүртгэх"
        visible={fileVisible}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <NewFileAddModal
          onClose={() => {
            setFileVisible(false);
          }}
          form={fileForm}
          handleUpload={handleUpload}
        />
      </Modal>
    </>
  );
};
export default Client;
