import React from 'react';
import { roundFloat } from 'src/core/utils/formater';

const setFloat = (_) => roundFloat(_);
export const columns = [
  {
    title: 'Огноо',
    align: 'center',
    dataIndex: 'ognoo',
    titleAlign: 'center',
    width: 50,
  },
  {
    title: 'Томилолтын төрөл',
    align: 'left',
    titleAlign: 'center',
    width: 60,
    render: (_, row) => {
      if (row.tomilolt_torol === 1) {
        return (
          <div
            style={{
              width: '3vw',
              flexWrap: 'wrap',
              textAlign: 'center',
            }}
          >
            Гадаад
          </div>
        );
      }
      if (row.tomilolt_torol === 2) {
        return (
          <div
            style={{
              width: '3vw',
              flexWrap: 'wrap',
              textAlign: 'center',
            }}
          >
            Дотоод
          </div>
        );
      }
      return <div />;
    },
  },
  {
    title: 'Томилолтоор явсан газар',
    align: 'left',
    width: 120,
    titleAlign: 'center',
    dataIndex: 'gazar_torol',
  },
  {
    title: 'Хүн хоног',
    align: 'center',
    width: 30,
    titleAlign: 'center',
    dataIndex: 'hun_honog',
  },
  {
    title: 'Томилолтын зардлын хязгаар',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'tomilolt_hyazgaar',
    render: (_) => roundFloat(_),
  },
  {
    title: 'Гүйцэтгэлээрх томилолтын зардал',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'guitsetgel_tomilolt_zardal',
    render: (_) => roundFloat(_),
  },
  {
    title: 'Үүнээс унаа, нислэгийн зардал',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'unaa_nisleg',
    render: (_) => roundFloat(_),
  },
  {
    title: 'Томилолтын зардлын хэтэрсэн дүн',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'hetersen_dun',
    render: (_) => roundFloat(_),
  },
];
export const columns_2 = [
  {
    title: 'Дансны',
    titleAlign: 'center',
    children: [
      {
        title: '№',
        align: 'center',
        dataIndex: 'key',
        titleAlign: 'center',
        width: 20,
        render: (_, row) => row.id,
      },
      {
        title: 'Дугаар',
        align: 'left',
        dataIndex: 'dans_dugaar',
        titleAlign: 'center',
        width: 100,
      },
      {
        title: 'Нэр',
        align: 'left',
        titleAlign: 'center',
        width: 150,
        ellipsis: true,
        dataIndex: 'dans_ner',
      },
      {
        title: 'Дүн',
        align: 'right',
        titleAlign: 'center',
        width: 100,
        dataIndex: 'dans_dun',
        render: setFloat,
      },
    ],
  },
];

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'tomilolt_hyazgaar',
    render: setFloat,
  },
  {
    align: 'right',
    value: 'guitsetgel_tomilolt_zardal',
    render: setFloat,
  },
  {
    align: 'right',
    value: 'unaa_nisleg',
    render: setFloat,
  },
  {
    align: 'right',
    value: 'hetersen_dun',
    render: setFloat,
  },
];

export const customSummary_2 = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    value: null,
  },

  {
    align: 'right',
    value: 'dans_dun',
    render: setFloat,
  },
];
