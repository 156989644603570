import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CalculatorOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { Table as AntTable, Button as AntButton } from 'antd';

import { roundFloat, useQuery } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import Table from 'src/components/common/table';
import newAlert from 'src/components/newAlert';
import { dans_columns } from '../data/dataStructure';

export default function TabDans() {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [avlagaSum, setAvlagaSum] = useState({});
  const [uglugSum, setUglugSum] = useState({});
  const [totalData, setTotalData] = useState(5);

  const handleSelectDans = (dans_code) => {
    const propName = 'dans_code';
    const propName2 = 'tab';
    const proplist = ['Hariltsagch', 'page2', 'size2', 'page3', 'size3'];
    if (query.get(propName)) {
      query.set(propName, dans_code);
    } else {
      query.append(propName, dans_code);
    }
    if (query.get(propName2)) {
      query.set(propName2, '2');
    } else {
      query.append(propName2, '2');
    }
    proplist.forEach((name) => {
      if (query.get(name)) {
        query.delete(name);
      }
    });
    history.push({ search: query.toString() });
  };

  const fetchData = async (page, size) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0') {
        setTableData(data.content.json_array);
        setTotalData(data.content.total);
        setAvlagaSum(data.content.avlaga_sum);
        setUglugSum(data.content.uglug_sum);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_turul',
      content: {
        month: data_date.month,
        receive_channel: 'ts002_00_dansnii_une',
        action_type: 'get_list_journal_type',
        data_type: '1',
        dans_only: true,
        return_action_type: 'dansnii_une_dansaar',
        page: page || 1,
        size: size || 20,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    const page = query.get('page');
    const size = query.get('size');

    if (query.get('tab')) {
      if (query.get('tab') === '1') {
        fetchData(page, size);
      }
    } else fetchData(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, data_date]);

  const cal_sanhuu_tovchoo = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай тооцооллоо' });
        fetchData();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_turul',
      content: {
        action_type: 'get_list_journal_type',
        receive_channel: 'calculate_sanhuu_step1',
        data_type: 1,
        dans_only: true,
      },
    });
    dispatch(start_loading({}));
  };

  const title = () => (
    <>
      <AntButton
        id="first"
        className="add-btn"
        type="primary"
        size="small"
        icon={<CalculatorOutlined />}
        onClick={cal_sanhuu_tovchoo}
      >
        Товчоо тооцоолох
      </AntButton>
    </>
  );

  const showSum = () => (
    <AntTable.Summary fixed>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell align="right">
          Авлагын нийт дүн:
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(avlagaSum.eh_dun)}</p>
          <p>{roundFloat(avlagaSum.eh_dun_valiut)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(avlagaSum.hanshiin_zuruu || 0)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(avlagaSum.deb)}</p>
          <p>{roundFloat(avlagaSum.deb_valiut)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(avlagaSum.cred)}</p>
          <p>{roundFloat(avlagaSum.cred_valiut)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(avlagaSum.ets_dun)}</p>
          <p>{roundFloat(avlagaSum.ets_dun_valiut)}</p>
        </AntTable.Summary.Cell>
      </AntTable.Summary.Row>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell align="right">
          Өглөгийн нийт дүн:
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(uglugSum.eh_dun)}</p>
          <p>{roundFloat(uglugSum.eh_dun_valiut)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(uglugSum.hanshiin_zuruu || 0)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(uglugSum.deb)}</p>
          <p>{roundFloat(uglugSum.deb_valiut)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(uglugSum.cred)}</p>
          <p>{roundFloat(uglugSum.cred_valiut)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(uglugSum.ets_dun)}</p>
          <p>{roundFloat(uglugSum.ets_dun_valiut)}</p>
        </AntTable.Summary.Cell>
      </AntTable.Summary.Row>
    </AntTable.Summary>
  );

  return (
    <div className="table-wrapper">
      <div className="inside-table no-scroll">
        <Table
          title={title}
          columns={dans_columns({ handleSelectDans })}
          total={totalData}
          dataSource={tableData}
          scroll={{
            y: 'calc(100vh - 400px)',
          }}
          customize={showSum}
        />
      </div>
    </div>
  );
}
