import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { roundFloat } from 'src/core/utils/formater';
import { LoadingOutlined } from '@ant-design/icons';
import { Table, Row, Col, Spin } from 'antd';
import { start_loading, end_loading } from 'src/core/actions/loader';

import {
  expandColumns,
  exampleColumn,
  expandColumn1 as expandNext,
} from './dataStructure/data';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const columns = exampleColumn;

const Tailan = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const [tableData, setTableData] = useState([]);

  const [loading, seTloading] = useState(true);
  const generateHmTailan = (month) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        for (const data in content.mayagt_data) {
          if ('uzuulelt' in content.mayagt_data[data]) {
            content.mayagt_data[data].dun = roundFloat(
              content.mayagt_data[data].dun
            );
          }
          if ('subRow' in content.mayagt_data[data]) {
            for (const inner_data in content.mayagt_data[data].subRow.child) {
              content.mayagt_data[data].subRow.child[inner_data].dun =
                roundFloat(
                  content.mayagt_data[data].subRow.child[inner_data].dun
                );
            }
          }
          if ('subChildren' in content.mayagt_data[data]) {
            for (const inner_data in content.mayagt_data[data].subChildren) {
              content.mayagt_data[data].subChildren[inner_data].dun =
                roundFloat(
                  content.mayagt_data[data].subChildren[inner_data].dun
                );
            }
          }
        }

        setTableData(content.mayagt_data);
        seTloading(false);
      } else {
        //
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_02_manage_aldagdal_data',
      content: {
        action_type: 'get_hm02_data',
        month_start: '1',
        month_end: String(month),
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (tableData) {
      generateHmTailan(data_date.month);
    }
  }, [data_date]);

  const mainSummary = (list = []) => (
    <Table.Summary>
      {list.map((j) => (
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={2}>
            <Row
              style={{
                width: '100% !important',
              }}
            >
              <Col
                style={{
                  textAlign: 'right',
                  paddingRight: 20,
                }}
                span={13}
              >
                {j.ner}
              </Col>
              <Col
                style={{
                  textAlign: 'center',
                }}
                span={1}
              >
                {j.mur}
              </Col>
              <Col
                style={{
                  textAlign: 'right',
                }}
                span={10}
              >
                {j.dun}
              </Col>
            </Row>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      ))}
    </Table.Summary>
  );

  const expandedRowRender = (data = null) => {
    if (data?.title) {
      return (
        <Table
          columns={expandColumns({ title: data?.title })}
          dataSource={data?.child}
          pagination={false}
        />
      );
    }
    if (data) {
      return (
        <Table columns={expandNext} dataSource={data} pagination={false} />
      );
    }
    return null;
  };

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin indicator={antIcon} />
      </div>
    );
  }

  const title = () => (
    <>
      <div className="table-title-text-center">
        ТАТВАРЫН ТАЙЛАНГААР ГАРСАН АЛДАГДЛЫГ ИРЭЭДҮЙД ШИЛЖҮҮЛЭН ТООЦОХ МЭДЭЭ
        /ХМ-02(4)/
      </div>
    </>
  );

  return (
    <>
      <div
        style={{
          minWidth: 900,
        }}
      >
        <Table
          className="aa-tatvar-tailan"
          columns={columns}
          dataSource={tableData}
          expandable={{
            defaultExpandAllRows: true,
            expandedRowRender: (row) =>
              expandedRowRender(row?.subRow || row?.subChildren),
            expandIcon: () => '',
            rowExpandable: (row) => {
              if (!row?.subRow && !row?.subChildren) {
                return null;
              }
              return row;
            },
          }}
          rowClassName="role-row"
          scroll={{
            y: 'max-content',
            x: 'auto',
          }}
          pagination={false}
          summary={() => mainSummary([])}
          title={title}
        />
      </div>
    </>
  );
};

export default Tailan;
