import React from 'react';
import { Space, Tag, message, Tooltip } from 'antd';
import { date } from 'src/core/utils/date';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { customConfirm } from 'src/components/common/confirmModal';

const color = (lvl, id) => {
  if (lvl === 1) return 'gold';
  return lvl <= id ? 'volcano' : 'cyan';
};
export const mainColumns = ({
  onEdit = () => {},
  id = null,
  remove_user = () => {},
}) => [
  {
    title: '№',
    dataIndex: 'id',
    key: 'id',
    width: 30,
    render: (_, __, index) => <a>{index + 1}</a>,
  },
  {
    title: 'Хэрэглэгчийн нэр',
    align: 'center',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Утас',
    align: 'center',
    dataIndex: 'phone_number',
    key: 'phone_number',
  },
  { title: 'И-мэйл хаяг', align: 'center', dataIndex: 'email', key: 'email' },
  {
    title: 'Эрхийн төрөл',
    key: 'type',
    dataIndex: 'role_name',
    align: 'center',
    width: 300,
    render: (_, row) => {
      if (row?.role_level < 6) {
        return (
          <>
            <Tag color={color(row?.role_level, id)}>{_}</Tag>
            <EditOutlined
              style={{
                marginLeft: 10,
                fontSize: 15,
              }}
              onClick={() => {
                if (row?.role_level <= id) {
                  message.error('Эрх алга', 1);
                } else {
                  onEdit(row);
                }
              }}
            />
          </>
        );
      }
      return 'Идэвхгүй';
    },
  },
  {
    title: 'Огноо',
    key: 'date',
    dataIndex: 'updated_date',
    align: 'center',
    render: (text, row) => (text ? date(text) : date(row?.created_date)),
  },
  {
    title: 'Устгах',
    key: 'action',
    align: 'center',
    width: 80,
    render: (text, record) => {
      if (id < record?.role_level || !record?.role_level) {
        return (
          <Space size="middle">
            <Tooltip title="Устгах">
              <DeleteOutlined
                onClick={() => {
                  customConfirm({
                    onOk: () => remove_user(record),
                  });
                }}
              />
            </Tooltip>
          </Space>
        );
      }
      return null;
    },
  },
];
