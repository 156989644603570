import { Modal } from 'antd';
import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';

const Content = ({ text = '' }) => (
  <div className="inside-container">{text}</div>
);

export const CompanyNotification = ({
  title = 'Боловсруулалт дууслаа',
  width = 450,
  type = 'warning',
  text = 'АЛДАА',
}) => {
  const handleOk = () => {
    // Check if onOk prop is provided and call it
    if (window.location.pathname === '/login') {
      window.location.reload(true);
    }
  };

  Modal[type]({
    title,
    className: 'notification-modal',
    icon: (
      <ExclamationCircleFilled
        style={{
          color: 'white',
        }}
      />
    ),
    okButtonProps: {
      className: 'file-error-btn',
      type: 'primary',
      shape: 'round',
    },
    footer: null,
    width,
    content: <Content text={text} />,
    okText: type === 'warning' ? 'Хаах' : 'Тийм',
    onOk: handleOk,
  });
};
