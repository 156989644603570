import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  Tag,
  Tooltip,
  Space,
  // DatePicker,
  Alert,
  Button,
  Input,
  Form,
  Modal,
  Row,
  Col,
  Checkbox,
} from 'antd';
import {
  DownOutlined,
  UpOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  LockOutlined,
  CheckCircleTwoTone,
  CopyOutlined,
  DatabaseTwoTone,
  RightCircleTwoTone,
  DeleteTwoTone,
  StopOutlined,
  CheckOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { get } from 'lodash';
import DeleteConfirm from 'src/components/common/deleteConfirm';
import newAlert from 'src/components/newAlert';
import { set_version } from '../../core/actions/version';
import { start_loading, end_loading } from '../../core/actions/loader';

const tagList = {
  0: {
    text: 'Алдаатай',
    icon: <StopOutlined />,
    color: 'red',
  },
  1: {
    text: 'Идэвхгүй',
    icon: <MinusCircleOutlined />,
  },
  2: {
    text: 'Идэвхтэй',
    icon: <CheckCircleOutlined />,
    color: '#02c0df',
  },
  3: {
    text: 'Архивлагдсан',
    icon: <LockOutlined />,
    color: 'processing',
  },
};

const getTag = (value = '') => {
  const props = get(tagList, `${value}`, null);

  if (props) {
    return (
      <Tag
        style={{
          width: 120,
        }}
        {...props}
      >
        {' '}
        {props?.text}
      </Tag>
    );
  }
  return null;
};

const _expandedColumn = ({ onEdit = () => {}, editComment = () => {} }) => [
  {
    align: 'center',
    dataIndex: 'ver_number',
    title: 'Хувилбарын дугаар',
    width: 100,
  },
  {
    align: 'center',
    dataIndex: 'state',
    title: 'Төлөв',
    width: 150,
    render: getTag,
  },
  {
    align: 'center',
    dataIndex: 'prev_version',
    title: 'Үүсгэсэн хувилбар',
    width: 100,
  },
  {
    align: 'center',
    dataIndex: 'created_at',
    title: 'Үүсгэсэн огноо',
    width: 120,
  },
  {
    align: 'center',
    dataIndex: 'comment',
    title: 'Тайлбар',
    width: 200,
  },
  {
    align: 'center',
    width: 30,
    render: (_, row) => (
      <div>
        <EditOutlined onClick={() => editComment(row)} />
      </div>
    ),
    fixed: 'right',
  },
  {
    align: 'center',
    key: 'action',
    dataIndex: 'action',
    title: 'Үйлдэл',
    width: 250,
    render: (_, row) => (
      <Space size="middle">
        {'activate' in row?.actions ? (
          <Tooltip title="Идэвхжүүлэх">
            <CheckCircleTwoTone
              twoToneColor="#4BB543"
              onClick={() => {
                onEdit(row, 'activate');
              }}
              style={{
                fontSize: 22,
              }}
            />
          </Tooltip>
        ) : (
          <></>
        )}
        {'next_year' in row?.actions ? (
          <Tooltip title="Дараагийн жил үүсгэх">
            <RightCircleTwoTone
              twoToneColor="#02c0df"
              onClick={() => {
                onEdit(row, 'next_year');
              }}
              style={{
                fontSize: 22,
              }}
            />
          </Tooltip>
        ) : (
          <></>
        )}
        {'duplicate' in row?.actions ? (
          <Tooltip title="Хуулбарлах">
            <CopyOutlined
              onClick={() => {
                onEdit(row, 'duplicate');
              }}
              style={{
                fontSize: 22,
              }}
            />
          </Tooltip>
        ) : (
          <></>
        )}
        {'archive' in row?.actions ? (
          <Tooltip title="Архивлах">
            <DatabaseTwoTone
              onClick={() => {
                DeleteConfirm({
                  text: 'Та хувилбар архивлах гэж байна. Тайлант жилд нийтдээ 4 архив үүсгэх боломжтой. Архив нь устгах боломжгүй тул та зөв хувилбараа архивлана уу.',
                  onConfirm: () => {
                    onEdit(row, 'archive');
                  },
                  width: 650,
                  confirmText: 'yes',
                  icon: <></>,
                });
              }}
              style={{
                fontSize: 22,
              }}
            />
          </Tooltip>
        ) : (
          <></>
        )}
        {'delete' in row?.actions ? (
          <Tooltip title="Устгах">
            <DeleteTwoTone
              twoToneColor="#FA113D"
              onClick={() => {
                DeleteConfirm({
                  text: 'Та тайлант жилийн хувилбар устгахдаа итгэлтэй байна уу? Устгагдсан мэдээллийг дахин сэргээх боломжгүйг анхаарна уу.',
                  onConfirm: () => {
                    onEdit(row, 'delete');
                  },
                  width: 550,
                });
              }}
              style={{
                fontSize: 22,
              }}
            />
          </Tooltip>
        ) : (
          <></>
        )}
      </Space>
    ),
  },
];

const column = [
  {
    align: 'center',
    key: 'year_number',
    dataIndex: 'year_number',
    title: 'Татварын жил',
  },
  {
    align: 'center',
    key: 'year_text',
    dataIndex: 'year_text',
    title: 'Тайлагнах хугацаа',
  },
  {
    align: 'center',
    key: 'state',
    dataIndex: 'state',
    title: 'Төлөв',
    width: 200,
    render: getTag,
  },
  Table.EXPAND_COLUMN,
];

const expandIcon = ({ expanded, onExpand, record }) => {
  if (!record?.child) return null;
  return expanded ? (
    <UpOutlined onClick={(e) => onExpand(record, e)} />
  ) : (
    <DownOutlined onClick={(e) => onExpand(record, e)} />
  );
};

const checkFirstYear = (data) => data?.state === 2 && !('duplicate' in data?.actions);

const TatvarJil = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const [tableData, setTableData] = useState([]);
  const [IsNew, setIsNew] = useState(true);
  const [selectedYear, seTselectedYear] = useState('');
  const [visible, setVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [nextYearChecked, setNextYearChecked] = useState(false);
  const [targetYearVer, setTargetYearVer] = useState({});

  const [form] = Form.useForm();

  function select_year() {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data?.status === '0') {
        if ('version_list' in data?.content) {
          setTableData(data.content.version_list);

          if (data.content.version_list.length > 0) {
            setIsNew(false);
          } else {
            seTselectedYear(data?.content?.first_year);
          }
        } else {
          newAlert({
            type: 'error',
            msg: data?.content?.Message || 'Системд алдаа гарлаа',
          });
        }
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', { channel: 'ts006_get_versions', content: {} });
    dispatch(start_loading({}));
  }

  function activate_version(function_data) {
    socket.on('change_jwt_rsp', (data) => {
      socket.removeAllListeners('change_jwt_rsp');
      if (data.status === '0') {
        dispatch(set_version(data.content));
        newAlert({ type: 'success', msg: 'Амжилттай шилжлээ' });
        select_year();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts006_activate_year',
      content: {
        year_number: function_data.year_number,
        version_number: function_data.ver_number,
      },
    });
    dispatch(start_loading({}));
  }

  function create_year(function_data, is_first_year) {
    if (is_first_year) {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');
        if (data.status === '0') {
          activate_version({
            year_number: data.content.year_data.year_number,
            ver_number: data.content.year_data.ver_number,
          });
        } else if (data.status === '3') {
          newAlert({
            type: 'error',
            msg: 'Хойшлогдсон татварын дундаж хувь хэмжээг бүртгэж, идэвхжүүлнэ үү',
          });
          dispatch(end_loading({}));
        } else if (data.status === '4') {
          newAlert({
            type: 'error',
            msg: 'Тухайн жилд үүсгэх хувилбарын тоо дүүрсэн байна',
          });
          dispatch(end_loading({}));
        } else if (data.status === '5') {
          newAlert({
            type: 'error',
            msg: 'Тухайн жилийг үүсгэх лицензгүй байна',
          });
          dispatch(end_loading({}));
        } else if (data.status === '6') {
          newAlert({
            type: 'error',
            msg: 'Дүн баталгаажуулна уу',
          });
          dispatch(end_loading({}));
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
          dispatch(end_loading({}));
          select_year();
        }
      });
      socket.emit('request_in', {
        channel: 'ts006_add_company',
        content: function_data,
      });
      dispatch(start_loading({}));
    } else {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');
        if (data.status === '0') {
          socket.on('request_out', (data_inner) => {
            socket.removeAllListeners('request_out');
            if (data_inner.status === '0') {
              activate_version({
                year_number: data_inner.content.year_data.year_number,
                ver_number: data_inner.content.year_data.ver_number,
              });
            } else if (data_inner.status === '3') {
              newAlert({
                type: 'error',
                msg: 'Хойшлогдсон татварын дундаж хувь хэмжээг бүртгэж, идэвхжүүлнэ үү',
              });
              dispatch(end_loading({}));
            } else if (data_inner.status === '4') {
              newAlert({
                type: 'error',
                msg: 'Тухайн жилд үүсгэх хувилбарын тоо дүүрсэн байна',
              });
              dispatch(end_loading({}));
            } else if (data_inner.status === '5') {
              newAlert({
                type: 'error',
                msg: 'Тухайн жилийг үүсгэх лицензгүй байна',
              });
              dispatch(end_loading({}));
            } else if (data_inner.status === '6') {
              newAlert({
                type: 'error',
                msg: 'Дүн баталгаажуулна уу',
              });
              dispatch(end_loading({}));
            } else {
              newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
              dispatch(end_loading({}));
              select_year();
            }
          });
          socket.emit('request_in', {
            channel: 'ts001_01_check_completion',
            content: function_data,
          });
        } else if (data.status === '5') {
          newAlert({ type: 'error', msg: data?.content?.message });
          dispatch(end_loading({}));
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
          dispatch(end_loading({}));
        }
      });
      socket.emit('request_in', {
        channel: 'ts001_02_tailan_control',
        content: {
          month: function_data?.first_year ? 13 : 12,
          step_type: 'shalgah_balance',
          action_type: 'validate_values',
        },
      });
      dispatch(start_loading({}));
    }
  }

  function delete_version(function_data) {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        // dispatch(set_version(data.content));
        newAlert({ type: 'success', msg: 'Амжилттай устгагдлаа' });
        select_year();
      } else if (data.status === '5') {
        newAlert({ type: 'error', msg: 'Устгах үйлдэл хязгаарлагдсан байна' });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ss005_company_manage',
      content: {
        action_type: 'delete_company_version',
        year_id: function_data.year_id,
        year_number: function_data.year_number,
        version_number: function_data.ver_number,
      },
    });
    dispatch(start_loading({}));
  }

  function duplicate_version(function_data) {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хуулбарлалаа' });
        select_year();
      } else if (data.status === '4') {
        newAlert({
          type: 'error',
          msg: 'Тухайн жилд үүсгэх хувилбарын тоо дүүрсэн байна',
        });
        dispatch(end_loading({}));
      } else if (data.status === '5') {
        newAlert({
          type: 'error',
          msg: 'Тухайн жилийг үүсгэх лицензгүй байна',
        });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts006_company_controller',
      content: {
        action_type: 'duplicate_version',
        year_number: function_data.year_number,
        version_number: function_data.ver_number,
      },
    });
    dispatch(start_loading({}));
  }

  const archive_version = (function_data) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай архивлагдлаа' });
        select_year();
      } else if (data.status === '4') {
        newAlert({
          type: 'error',
          msg: 'Тухайн жилд үүсгэх архивын тоо дүүрсэн байна',
        });
        dispatch(end_loading({}));
      } else if (data.status === '5') {
        newAlert({
          type: 'error',
          msg: 'Тухайн жилийн лицензгүй байна',
        });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts006_company_controller',
      content: {
        action_type: 'archive_version',
        year_number: function_data.year_number,
        version_number: function_data.ver_number,
      },
    });
    dispatch(start_loading({}));
  };

  const prepareNextYear = (data) => {
    setTargetYearVer(data);
    setConfirmVisible(true);
  };

  const onEdit = (data, action_type) => {
    if (action_type === 'activate') {
      activate_version(data);
    } else if (action_type === 'next_year') {
      prepareNextYear({
        year_number: data.year_number,
        next_year: true,
        first_year: checkFirstYear(data),
      });
    } else if (action_type === 'delete') {
      delete_version(data);
    } else if (action_type === 'duplicate') {
      duplicate_version(data);
    } else if (action_type === 'archive') {
      archive_version(data);
    }
  };

  const create_next_year = () => {
    if (targetYearVer) {
      create_year(targetYearVer, false);
    }
  };

  const editComment = (row) => {
    form.setFieldsValue({
      ver_id: row?.ver_id,
      comment: row?.comment,
    });
    setVisible(true);
  };

  const updateComment = (update_data) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        select_year();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts006_company_controller',
      content: {
        action_type: 'update_comment',
        data: update_data,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    select_year();
  }, []);

  // function onChangeYear(_, dateString) {
  //   seTselectedYear(dateString);
  // }

  const expandedRowRender = (record) => {
    if (record?.child) {
      return (
        <div className="expanded-child">
          <Table
            className="tatvar-jil-table"
            bordered
            rowClassName="row"
            columns={_expandedColumn({ onEdit, editComment })}
            dataSource={record?.child}
            pagination={false}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="tatvar-jil">
      {IsNew ? (
        <>
          <div className="header">
            <span>Эхний үлдэгдлийн жил үүсгэх</span>
          </div>

          <div className="container">
            <Alert
              message="Татварын жил үүсгэхэд сонгогдсон жилийн 12 сарын 31 өдрийн байдлаар үүснэ."
              type="info"
              showIcon
              style={{ marginBottom: 10 }}
            />
            <div>
              {/* <DatePicker
                picker="year"
                placeholder="Жил сонгоно уу"
                dropdownClassName="date-picker-year"
                onChange={onChangeYear}
              /> */}
              <Input style={{ width: 250 }} value={selectedYear} disabled />
              <Button
                className="add-btn"
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => {
                  create_year({ year_number: selectedYear, first_year: false }, true);
                }}
              >
                Татварын жил үүсгэх
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="header">
            <span>Татварын жил</span>
          </div>
          <div className="container">
            <Table
              className="tatvar-jil-table"
              bordered
              rowClassName="row"
              columns={column}
              expandable={{
                expandedRowRender,
                rowExpandable: (row) => (!row?.child ? null : row),
                expandedRowClassName: () => 'expanded-row',
                expandIcon,
              }}
              dataSource={tableData}
              pagination={false}
            />
          </div>
        </>
      )}
      <Modal
        className="custom-modal"
        title="Тайлбар шинэчлэх"
        open={visible}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <CustomModal
          onClose={() => {
            setVisible(false);
          }}
          form={form}
          saveValues={updateComment}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Дараагийн татварын жил үүсгэх"
        open={confirmVisible}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <ConfirmModal
          onClose={() => {
            setConfirmVisible(false);
          }}
          confirmAction={() => { create_next_year(); }}
          isChecked={nextYearChecked}
          setChecked={setNextYearChecked}
        />
      </Modal>
    </div>
  );
};

const layout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 18 },
    sm: { span: 18 },
  },
};

const CustomModal = ({
  onClose = () => {},
  form = null,
  saveValues = () => {},
}) => {
  const onFinish = (values) => {
    saveValues(values);
    onClose();
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="ver_id" hidden />
        <Form.Item name="comment" label="Тайлбар" labelAlign="left">
          <Input className="w-100" />
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const ConfirmModal = ({
  onClose = () => {},
  confirmAction = () => {},
  isChecked = false,
  setChecked = () => {},
}) => {
  const onFinish = () => {
    confirmAction();
    setChecked(false);
    onClose();
  };

  const onCancel = () => {
    setChecked(false);
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <div className="register-form">
        <Row>
          <Alert message="Тайлант жилд хамаарах баталгаажсан алдагдал байгаа бол бүртгэнэ үү. Бүртгэсэн болон бүртгэх шаардлагагүй бол цааш үргэлжлүүлнэ үү." type="warning" />
        </Row>
        <Row style={{ marginBottom: 10, marginTop: 10 }}>
          <Checkbox
            checked={isChecked}
            onChange={({ target }) => {
              const { checked } = target;
              setChecked(checked);
            }}
          >
            Зөвшөөрч байна
          </Checkbox>
        </Row>
        <Row>
          <Col span={24} className="text-align-r">
            <Button
              onClick={onCancel}
              className="cancel-btn"
              type="primary"
              shape="round"
            >
              Цуцлах
            </Button>
            <Button
              onClick={onFinish}
              className="confirm-btn ml-5"
              type="primary"
              shape="round"
              disabled={!isChecked}
            >
              Татварын жил үүсгэх
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TatvarJil;
