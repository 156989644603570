import { Modal } from 'antd';
import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';

const Content = ({ text = '' }) => (
  <div className="inside-container">{text}</div>
);

export const FileError = ({
  title = 'АЛДАА ГАРЛАА, ДАХИН ОРОЛДОНО УУ.',
  width = 450,
  type = 'warning',
  text = 'АЛДАА',
}) => {
  Modal[type]({
    title,
    className: 'file-error-modal',
    icon: (
      <ExclamationCircleFilled
        style={{
          color: 'white',
        }}
      />
    ),
    okButtonProps: {
      className: 'file-error-btn',
      type: 'primary',
      shape: 'round',
    },
    footer: null,
    width,
    content: <Content text={text} />,
    okText: type === 'warning' ? 'Хаах' : 'Тийм',
  });
};
