import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Modal } from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';
import { Table } from 'src/components';
import newAlert from 'src/components/newAlert';
import { customColumns, customSummary } from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';

import TuuhenHanshModal from './tuuhenHanshModal';

const nullValue = {
  dans_id: '',
  dans_code: '',
  dans_valiut: '',
  dansnii_une_valiut: 0,
};

export default function TatvarSuuriMungunHurungu() {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);

  const [tableData, setTableData] = useState({});
  const [modalHanshVisivle, seTmodalHanshVisivle] = useState(false);
  const [dialogHanshValues, setDialogHanshValues] = useState(nullValue);

  const fetchTableData = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setTableData(data.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_04_mh_controller',
      content: {
        action_type: 'get_dun_list',
        first_year: true,
      },
    });

    dispatch(start_loading({}));
  };

  const handleCalculateTC = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({
          type: 'success',
          msg: 'Татварын суурь амжилттай тооцооллоо',
        });
        fetchTableData();
      } else if (data.status === '4') {
        newAlert({
          type: 'error',
          msg: `Түүхэн ханш дутуу бүртгэсэн байна: ${data.content.error_list}`,
        });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_04_mh_controller',
      content: {
        action_type: 'calculate_tatvar_suuri_eh',
        first_year: true,
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (dialogHanshValues !== nullValue) {
      seTmodalHanshVisivle(true);
    }
  }, [dialogHanshValues]);

  useEffect(() => {
    fetchTableData();
  }, []);

  const handleOpenModalHansh = (rowData) => {
    setDialogHanshValues(() => ({
      dans_id: rowData.dans_id,
      dans_code: rowData.dans_dugaar,
      dans_valiut: rowData.valiut,
      dansnii_une_valiut: rowData.dansnii_une_valiut,
    }));
  };

  const title = () => (
    <>
      <Button
        className="add-btn"
        type="primary"
        icon={<CalculatorOutlined />}
        onClick={handleCalculateTC}
        size="small"
      >
        Татварын суурь тооцоолох
      </Button>
    </>
  );

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          columns={customColumns({
            customEdit: handleOpenModalHansh,
          })}
          dataSource={tableData?.json_array}
          scroll={{
            y: 'calc(100vh - 280px)',
          }}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableData?.sum_data,
          }}
          title={title}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Түүхэн ханш"
        visible={modalHanshVisivle}
        closable={false}
        maskClosable={false}
        width={600}
        footer={null}
      >
        <TuuhenHanshModal
          initialValues={dialogHanshValues}
          onClose={() => {
            seTmodalHanshVisivle(false);
            setDialogHanshValues(nullValue);
          }}
        />
      </Modal>
    </>
  );
}
