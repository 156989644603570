import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Button, Drawer, Input, Form } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Table, Warning } from 'src/components';
import { AddNewBurtgel } from 'src/components/undsenHurungu/addNewBurtgel';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { dansColumns, CustomSum } from './dataStructure/data';
import { DahinUnelgee } from './DahinUnelgee';
import { UpdateAnhUrtugBurtgel } from './updateAnhUrtugBurtgel';
import { UpdateBurtgel } from './updateBurtgel';

const { Text } = Typography;
const { Search } = Input;

export const BurtgelDans = ({
  hurunguData = {},
  closeDrawer = () => {},
  open = false,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);

  const [BurtgelOpenDialog, setBurtgelOpenDialog] = useState(false);
  const [burtgelUpdate, setBurtgelUpdate] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [updateDahin, setUpdateDahin] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [dahinVisible, setDahinVisible] = useState(false);
  const [updateBurtgelVisible, setUpdateBurtgelVisible] = useState(false);
  const [pagenationData, seTpagenationData] = useState({
    page: 1,
    size: 20,
    burtgel: '',
  });
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });

  const select_hurungu_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const search = {
      page2: pagenationData?.page,
      size2: pagenationData?.size,
      dans_dugaar: hurunguData?.dans_dugaar,
      hurungu: hurunguData?.code,
      burtgel: pagenationData?.burtgel,
    };
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'ehnii_uldegdel_burtgel_dans',
        search,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      seTpagenationData({
        ...pagenationData,
        page: 1,
        burtgel: '',
      });
    } else {
      select_hurungu_data();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      select_hurungu_data();
    }
  }, [pagenationData]);

  const deleteBurtgel = (id) => {
    socket.on('request_out', (req_data) => {
      socket.removeAllListeners('request_out');
      if (req_data.status === '0') {
        select_hurungu_data();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: { action_type: 'burtgel_delete', id },
    });
    dispatch(start_loading({}));
  };

  const getParm = (values) => {
    if (
      // eslint-disable-next-line operator-linebreak
      values.page !== pagenationData.page ||
      values.size !== pagenationData.size
    ) {
      seTpagenationData({
        ...pagenationData,
        page: values.page,
        size: values.size,
      });
    }
  };

  useEffect(() => {
    if (burtgelUpdate && !updateVisible) {
      select_hurungu_data();
      setBurtgelUpdate(false);
    }
  }, [updateVisible]);

  useEffect(() => {
    if (burtgelUpdate && !BurtgelOpenDialog) {
      select_hurungu_data();
      setBurtgelUpdate(false);
    }
  }, [BurtgelOpenDialog]);

  useEffect(() => {
    if (burtgelUpdate && !updateBurtgelVisible) {
      select_hurungu_data();
      setBurtgelUpdate(false);
    }
  }, [updateBurtgelVisible]);

  useEffect(() => {
    if (burtgelUpdate && !dahinVisible) {
      select_hurungu_data();
      setBurtgelUpdate(false);
    }
  }, [dahinVisible]);

  const onSearch = (values) => {
    if (values) {
      seTpagenationData({
        ...pagenationData,
        burtgel: values,
      });
    } else {
      seTpagenationData({
        ...pagenationData,
        burtgel: '',
      });
    }
  };

  const title = () => (
    <>
      {!tableData?.validtion_info?.validated && (
        <Warning show listWarning={['Холбосон дүн таараагүй байна']} />
      )}
      {version?.is_first_year && (
        <Button
          className="add-btn"
          type="primary"
          size="small"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setBurtgelOpenDialog(true);
            setBurtgelUpdate(true);
          }}
        >
          Бүртгэлийн дугаар үүсгэх
        </Button>
      )}
      <Form.Item name="search" className="ant-form-item-small">
        <Search
          size="small"
          allowClear
          placeholder="Бүртгэлийн дугаар..."
          onSearch={onSearch}
        />
      </Form.Item>
    </>
  );

  const editDahin = (row) => {
    setUpdateDahin(row);
    setDahinVisible(true);
    setBurtgelUpdate(true);
  };

  const onCancelDahin = () => {
    setDahinVisible(false);
    setUpdateDahin(null);
  };

  const updateAnhUrtug = (row) => {
    setUpdateData(row);
    setUpdateVisible(true);
    setBurtgelUpdate(true);
  };

  const onCancelUpdate = () => {
    setUpdateVisible(false);
    setUpdateData(null);
  };

  const editUpdateBurtgel = (row) => {
    setUpdateData(row);
    setUpdateBurtgelVisible(true);
    setBurtgelUpdate(true);
  };

  const onCancelUpdateBurtgel = () => {
    setUpdateVisible(false);
    setUpdateBurtgelVisible(null);
  };

  return (
    <Drawer
      title="Үндсэн хөрөнгийн данс ба бүртгэл холбох"
      open={open}
      width="90%"
      footer={null}
      closable
      maskClosable={false}
      onClose={() => {
        closeDrawer();
      }}
      className="custom-drawer"
    >
      <AddNewBurtgel
        data={hurunguData}
        openDialog={BurtgelOpenDialog}
        setOpenDialog={setBurtgelOpenDialog}
      />
      <DahinUnelgee
        data={updateDahin}
        hurungu_info={hurunguData}
        dahinVisible={dahinVisible}
        onCancel={onCancelDahin}
      />
      <UpdateAnhUrtugBurtgel
        data={updateData}
        hurungu_info={hurunguData}
        openDialog={updateVisible}
        onCancel={onCancelUpdate}
      />
      <UpdateBurtgel
        data={updateData}
        hurungu_info={hurunguData}
        openDialog={updateBurtgelVisible}
        onCancel={onCancelUpdateBurtgel}
      />
      <div className="dans-medeelel-container">
        <Form
          form={form}
          className="register-form"
          size="small"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Form.Item
            label="Дансны дугаар"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{hurunguData?.dans_dugaar}</Text>
          </Form.Item>
          <Form.Item
            label="Үндсэн хөрөнгийн нэр"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{hurunguData?.ner}</Text>
          </Form.Item>
          <Form.Item
            label="Үндсэн хөрөнгийн код"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{hurunguData?.code}</Text>
          </Form.Item>
          <Table
            title={title}
            columns={dansColumns({
              customEdit: editDahin,
              updateAnhUrtug,
              editUpdateBurtgel,
              deleteRow: deleteBurtgel,
              version,
            })}
            subTotal={tableData.total_size}
            dataSource={tableData?.result_list}
            scroll={{
              y: 'calc(100vh - 330px)',
            }}
            customize={() => <CustomSum data={tableData.total_list} />}
            getParam={getParm}
          />
        </Form>
      </div>
    </Drawer>
  );
};
