import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { EditOutlined } from '@ant-design/icons';

export const customSummary = [
  {
    align: 'left',
    word: '',
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'center',
    word: '',
  },
  {
    align: 'right',
    value: 'dans_dun_deb',
  },
  {
    align: 'right',
    value: 'dans_dun_cred',
  },
  {
    align: 'center',
    word: '',
  },
];

export const customColumns = ({ customEdit = () => {}, version }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    titleAlign: 'center',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    align: 'left',
    titleAlign: 'center',
    width: 300,
    ellipsis: true,
  },
  {
    title: 'Валютын төрөл',
    dataIndex: 'valiut_torol',
    align: 'center',
    titleAlign: 'center',
    width: 80,
  },
  {
    title: 'Дебет',
    dataIndex: 'dans_dun_deb',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => {
      if (row.valiut_torol === 'MNT') {
        return (
          <div>
            <p>{roundFloat(row?.dans_dun_deb)}</p>
          </div>
        );
      }
      return (
        <div>
          <p>{roundFloat(row?.dans_dun_deb)}</p>
          <p className="bg-highlight">{roundFloat(row?.dans_dun_valiut_deb)}</p>
        </div>
      );
    },
  },
  {
    title: 'Кредит',
    dataIndex: 'dans_dun_cred',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => {
      if (row.valiut_torol === 'MNT') {
        return (
          <div>
            <p>{roundFloat(row?.dans_dun_cred)}</p>
          </div>
        );
      }
      return (
        <div>
          <p>{roundFloat(row?.dans_dun_cred)}</p>
          <p className="bg-highlight">
            {roundFloat(row?.dans_dun_valiut_cred)}
          </p>
        </div>
      );
    },
  },
  {
    title: 'Засах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    render: (_, row) => (
      <>
        {version?.is_first_year && (
          <EditOutlined onClick={() => customEdit(row)} />
        )}
      </>
    ),
  },
];
