import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { Button as AntButton, Tooltip, Table as AntTable } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { customConfirm } from 'src/components/common/confirmModal';

const clear = (inputString) => {
  const stringWithoutCommas = String(inputString).replace(/,/g, '');
  const numberValue = parseFloat(stringWithoutCommas);
  return numberValue;
};
const setFloat = (_) => roundFloat(_);
export const columns = ({ edit_row = () => {}, delete_row = () => {} }) => [
  {
    title: 'Мөнгөн хөрөнгийн зүйлс',
    dataIndex: 'zuils',
    width: 250,
    key: 'zuils',
  },
  {
    title: 'Эхний үлдэгдэл',
    dataIndex: 'eh_uld',
    key: 'eh_uld',
    width: 150,
    render: setFloat,
  },
  {
    title: 'Эцсийн үлдэгдэл',
    dataIndex: 'ets_uld',
    key: 'ets_uld',
    width: 150,
    render: setFloat,
  },
];

export const customSummary = [
  {
    word: 'Нийт дүн:',
    align: 'right',
  },
  {
    align: 'right',
    value: 'eh_uld',
    key: 'eh_uld',
    render: setFloat,
  },
  {
    align: 'right',
    value: 'ets_uld',
    key: 'ets_uld',
    render: setFloat,
  },
];
