import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'src/core/utils/formater';
import axios from 'src/core/utils/axios';
import { isEmpty } from 'lodash';

import { Table } from 'src/components';
import { FileError } from 'src/components/common/file_error';
import {
  PlusCircleOutlined,
  UpCircleOutlined,
  CloudUploadOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  Upload,
  message,
  Select as AntSelect,
  Typography,
  Button as AntButton,
} from 'antd';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { customColumns } from './dataStructure/data';
import { searchList } from './dataStructure/search';
import CustomAdd from './add';

const { Text } = Typography;
const FileDownload = require('js-file-download');

const typesList = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
const sourceTypes = [
  { source_type: '1', source_name: 'Диамонд' },
  { source_type: '2', source_name: 'Такс' },
];

const MainPage = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const socket = useSelector((state) => state.socket);

  const [tableData, setTableData] = useState({ json_array: [], total: 0 });
  const [dansAngilal, setDansAngilal] = useState(null);
  const [visible, setVisible] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [fileVisible, setFileVisible] = useState(false);
  const [loading, seTloading] = useState(false);

  const [form] = Form.useForm();
  const [addForm] = Form.useForm();
  const [deleteForm] = Form.useForm();
  const [fileForm] = Form.useForm();
  const tailan_type_map = {
    file_name: 'temp.xlsx',
  };

  const generateTailan = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setTableData(data.content.table_data);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const search = {
      dans_dugaar: query.get('dans_dugaar'),
      angilalt: query.get('angilalt'),
    };

    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: {
        action_type: 'get_sanhuu_dans_list',
        paging: { page, size, search },
      },
    });

    dispatch(start_loading({}));
  };

  const getProps = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setDansAngilal(data?.content);
        generateTailan();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: { action_type: 'get_sanhuu_dans_props_tree' },
    });

    dispatch(start_loading({}));
  };

  const saveValues = (saveData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: { action_type: 'set_sanhuu_dans_info', data: saveData },
    });

    dispatch(start_loading({}));
  };

  const addValue = (saveData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan();
      } else if (data.status === '5') {
        newAlert({ type: 'error', msg: 'Дансны дугаар бүртгэлтэй байна' });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: { action_type: 'add_dans', data: saveData },
    });

    dispatch(start_loading({}));
  };

  const deleteValue = (deleteData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай устгагдлаа' });
        generateTailan();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: {
        action_type: 'delete_dans',
        dans_id: deleteData.dans_id,
      },
    });

    dispatch(start_loading({}));
  };

  const handleSaveTable = (dans_data) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data?.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: {
        action_type: 'add_dans_with_dun',
        json_array: dans_data,
      },
    });
  };

  const handleUpload = async (selectedFile) => {
    if (selectedFile) {
      dispatch(start_loading({}));
      const { size: file_size } = selectedFile.file[0];
      const { file_source } = selectedFile;
      const formData = new FormData();
      formData.append('file', selectedFile.file[0].originFileObj);
      const { status, content } = await axios.post(
        'first',
        '/guilgee_balance',
        formData,
        {
          file_size,
          file_source,
        }
      );
      if (status === '0') {
        handleSaveTable(content?.json_array);
      } else if (content?.type === 'content') {
        FileError({
          text: content?.message,
        });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: content?.message });
        dispatch(end_loading({}));
      }
    }
  };

  const getAngilalName = (data, dans_turul, dans_angilal_id) => {
    if (!data) return '---';
    const type = dans_turul === 'Баланс' ? 'balance_angilal' : 'orlogo_angilal';
    const list = data?.[type];
    let findedName = '';
    list.forEach((item) => {
      const { children } = item;
      const findedData = children.find((i) => i.angilal_id === dans_angilal_id);
      if (findedData) {
        findedName = findedData?.angilal_ner;
      }
    });
    return findedName || '---';
  };

  const getTurZuruuType = (turZuruuType) => {
    const obj = dansAngilal.tur_zuruu_turul_list.find(
      (elem) => elem.id === turZuruuType
    );
    if (obj !== undefined) {
      return obj.ner;
    }
    return '---';
  };

  const getTuslahJournalType = (tuslahJournalType) => {
    const obj = dansAngilal.tuslah_journal_list.find(
      (elem) => elem.id === tuslahJournalType
    );
    if (obj !== undefined) {
      return obj.ner;
    }
    return '---';
  };

  const exportTailan = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        const file_export_address_link = `/get_file?file_id=${data.content.file_id}`;
        axios.get('export', file_export_address_link, {}, {}, (event) => {
          FileDownload(event, tailan_type_map.file_name);
        });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });

    // const { month } = data_date;
    // const search = {
    //   type: sumType || 'hurungu',
    //   dans_dugaar: query.get('dans_dugaar'),
    //   hurungu: query.get('hurungu'),
    // };

    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: {
        action_type: 'get_sanhuu_dans_list',
        return_channel: 'ss010_00_export_file',
        paging: { page: 1, size: 10000000, search: {} },
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    seTloading(true);
    getProps();
  }, [query]);

  const edit = (row) => {
    form.setFieldsValue({
      dans_id: row?.dans_id,
      dans_dugaar: row?.dans_dugaar,
      dans_ner: row?.dans_ner,
      dans_turul: row?.dans_turul,
      valiut_torol: row?.valiut_torol,
      dans_angilal_id: row?.dans_angilal_id,
      tuslah_journal_type: row?.tuslah_journal_type,
      tur_zuruu_turul_id: row?.tur_zuruu_turul_id,
      sub_angilal_id: row?.sub_angilal_id,
    });

    setVisible(true);
  };

  const addDans = () => {
    addForm.setFieldsValue({
      valiut_torol: 'MNT',
      tuslah_journal_type: '',
      tur_zuruu_turul_id: '',
    });

    setAddVisible(true);
  };

  const deleteDans = (row) => {
    deleteForm.setFieldsValue({
      dans_id: row?.dans_id,
    });

    setDeleteVisible(true);
  };

  useEffect(() => {
    if (!isEmpty(dansAngilal)) {
      seTloading(false);
    }
  }, [dansAngilal]);

  if (loading) {
    return '';
  }

  const title = () => (
    <>
      {/* <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<ExportOutlined />}
        onClick={() => { exportTailan(); }}
      >
        Экспорт
      </AntButton> */}
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<UpCircleOutlined />}
        onClick={() => {
          setFileVisible(true);
        }}
      >
        Гүйлгээ баланс оруулах
      </Button>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          addDans();
        }}
      >
        Данс нэмэх
      </Button>
    </>
  );

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          columns={customColumns({
            customEdit: edit,
            customDelete: deleteDans,
            dansProps: dansAngilal,
            getTypeAngilal: getAngilalName,
            getTypeTurZuruu: getTurZuruuType,
            getTypeJournal: getTuslahJournalType,
          })}
          dataSource={tableData.json_array}
          scroll={{ y: 'calc(100vh - 280px)' }}
          title={title}
          total={tableData.total}
          isSearch
          searchList={searchList}
        />
      </div>
      <CustomAdd
        edit
        visible={visible}
        form={form}
        onClose={() => {  
          setVisible(false);
        }}
        listData={dansAngilal}
        saveValues={saveValues}
      />
      <Modal
        className="custom-modal"
        title="Данс бүртгэх"
        visible={addVisible}
        closable={false}
        maskClosable={false}
        width={700}
        footer={null}
      >
        <CustomAdd
          form={addForm}
          onClose={() => {
            setAddVisible(false);
          }}
          listData={dansAngilal}
          saveValues={addValue}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Данс устгах"
        visible={deleteVisible}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <DeleteModal
          onClose={() => {
            setDeleteVisible(false);
          }}
          form={deleteForm}
          deleteValue={deleteValue}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Гүйлгээ баланс оруулах"
        open={fileVisible}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <NewFileAddModal
          onClose={() => {
            setFileVisible(false);
          }}
          form={fileForm}
          handleUpload={handleUpload}
        />
      </Modal>
    </>
  );
};

const layout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 17 },
  },
};

const DeleteModal = ({
  onClose = () => {},
  form = null,
  deleteValue = () => {},
}) => {
  const onFinish = (values) => {
    deleteValue(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="dans_id" hidden />
        <Text>Та энэ дансыг устгахдаа итгэлтэй байна уу?</Text>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Устгах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const NewFileAddModal = ({
  onClose = () => {},
  form = null,
  handleUpload = () => {},
}) => {
  const onFinish = (values) => {
    handleUpload(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item
          name="file_source"
          label="Эх сурвалж"
          labelAlign="left"
          rules={[{ required: true, message: '' }]}
        >
          <AntSelect
            placeholder="Сонгох"
            style={{ width: '100%', fontSize: 13 }}
          >
            {sourceTypes.map((i) => (
              <AntSelect.Option value={i.source_type}>
                {i.source_name}
              </AntSelect.Option>
            ))}
          </AntSelect>
        </Form.Item>
        <Form.Item
          name="file"
          label="Файл"
          labelAlign="left"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: 'Файл сонгоно уу' }]}
        >
          <Upload
            accept={typesList.join()}
            showUploadList
            maxCount={1}
            beforeUpload={(File) => {
              const isXls = typesList.find((i) => i === File.type);
              if (!isXls) {
                message.error('Зөвхөн xls, xlsx өргөтгөлтэй файл оруулна уу');
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
          >
            <CloudUploadOutlined className="fs-30" />
          </Upload>
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default MainPage;
