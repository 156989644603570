import React, { useEffect, useState } from 'react';
// loader
import loader from 'src/assets/images/loading.json';
// unshuulah
import read from 'src/assets/images/read.json';
// error
import error from 'src/assets/images/error.json';
// unshigdsan
import { useSelector, useDispatch } from 'react-redux';
import success from 'src/assets/images/success.json';
import { ReactComponent as DltLogo } from 'src/assets/images/delete.svg';
import Lottie from 'react-lottie';
import { Tooltip } from 'antd';
import { get } from 'lodash';
import newAlert from 'src/components/newAlert';

const typeList = {
  error: {
    text: 'Алдаатай',
    svg: error,
  },
  read: {
    text: 'Уншуулах',
    svg: read,
  },
  success: {
    text: 'Уншигдсан',
    svg: success,
  },
  loader: {
    text: 'Ачааллаж байна',
    svg: loader,
  },
  delete: {
    text: 'Устгах',
  },
};

const CustomIcon = ({ type = 'error', row = [], onClick = () => {} }) => {
  const dispatch = useDispatch();
  const [_type, seTtype] = useState(type);
  const loading = useSelector((state) => state.is_loading);
  const typeMaker = () => {
    let value = type;
    if (type === 'read' && row.includes(1)) {
      value = 'loader';
    }
    seTtype(value);
  };

  useEffect(() => {
    typeMaker();
  }, [type]);
  useEffect(() => {
    if (loading === 'start') {
      if (_type === 'read') {
        seTtype('loader');
      }
    }
    if (loading === 'done') {
      seTtype(type);
    }
  }, [loading]);
  if (!type) return null;

  return (
    <Tooltip title={get(typeList, `${_type}.text`, null)}>
      {get(typeList, `${_type}.svg`, null) ? (
        <div className="click-div" onClick={onClick}>
          <Lottie
            options={{
              animationData: get(typeList, `${_type}.svg`, null),
            }}
          />
        </div>
      ) : (
        <DltLogo
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            if (!loading === 'start' || loading === 'done' || !loading) {
              onClick();
            } else {
              newAlert({
                type: 'warning',
                msg: 'Ачааллаж байна файл уншигдаж дууссаны дараа дахин оролдоно уу',
              });
            }
          }}
        />
      )}
    </Tooltip>
  );
};

export default CustomIcon;
