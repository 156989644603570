const notificationType = '';

const reducer = (state = notificationType, action) => {
  switch (action.type) {
    case 'set_notification_type':
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
