import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { EditOutlined } from '@ant-design/icons';
import CanView from 'src/components/permission/canview';
import { Button as AntButton } from 'antd';
import { Warning } from 'src/components';

export const HurunguColumns = () => [
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    align: 'left',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Хөрөнгийн нэр',
    dataIndex: 'ner',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Хөрөнгийн төрөл',
    dataIndex: 'turul_ner',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Хөрөнгийн бүлэг',
    dataIndex: 'buleg_ner',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
];

export const ColumnsTab1 = ({
  onEdit = () => {},
  onSelectHurungu = () => {},
}) => [
  {
    align: 'center',
    width: 30,
    fixed: 'left',
    render: (_, row) => (
      <>
        {!row?.validated && (
          <Warning show listWarning={['Хөрөнгийн мэдээлэл дутуу байна']} />
        )}
      </>
    ),
  },
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    align: 'left',
    width: 120,
    fixed: 'left',
    render: (_, row) => (
      <AntButton
        align="center"
        type="link"
        size="small"
        className="underline"
        onClick={() => onSelectHurungu(row)}
      >
        {row?.code}
      </AntButton>
    ),
  },
  {
    title: 'Үндсэн хөрөнгийн',
    children: [
      {
        title: 'Нэр',
        dataIndex: 'ner',
        align: 'left',
        width: 200,
        ellipsis: true,
      },
      {
        title: 'Төрөл',
        dataIndex: 'turul_ner',
        align: 'left',
        width: 150,
        ellipsis: true,
      },
      {
        title: 'Бүлэг',
        dataIndex: 'buleg_ner',
        align: 'left',
        width: 150,
        ellipsis: true,
      },
      {
        title: 'Хэмжих нэгж',
        dataIndex: 'hemjih_negj',
        align: 'center',
        width: 100,
      },
    ],
  },
  {
    title: 'Санхүү',
    children: [
      {
        title: 'Анхны өртөг',
        dataIndex: 'urtug_anh',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Үлдэх өртөг',
        dataIndex: 'urtug_uldeh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Эхэлсэн',
        dataIndex: 'ashiglaj_ehelsen',
        align: 'right',
        width: 100,
        render: (_) => (_ === '' ? '---' : _),
      },
      {
        title: 'Дуусах',
        dataIndex: 'ashiglaj_duusah',
        align: 'right',
        width: 100,
        render: (_) => (_ === '' ? '---' : _),
      },
      {
        title: 'Норм',
        dataIndex: 'ashiglah_norm',
        align: 'right',
        width: 100,
        render: (_) => (_ === '' ? '---' : roundFloat(_)),
      },
    ],
  },
  {
    title: 'Татвар',
    children: [
      {
        title: ' Анхны өртөг',
        dataIndex: 'tat_urtug_anh',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Эхэлсэн',
        dataIndex: 'tat_ashiglaj_ehelsen',
        align: 'right',
        width: 100,
        render: (_) => (_ === '' ? '---' : _),
      },
      {
        title: 'Дуусах',
        dataIndex: 'tat_ashiglaj_duusah',
        align: 'right',
        width: 100,
        render: (_) => (_ === '' ? '---' : _),
      },
      {
        title: 'Норм',
        dataIndex: 'tat_ashiglah_norm',
        align: 'right',
        width: 100,
        render: (_) => (_ === '' ? '---' : roundFloat(_)),
      },
    ],
  },
  {
    title: 'Зөрүү тооцох эсэх',
    dataIndex: 'not_tax',
    align: 'center',
    width: 70,
    render: (_) => (_ ? 'Үгүй' : 'Тийм'),
  },
  {
    title: 'Засах',
    align: 'center',
    width: 50,
    fixed: 'right',
    render: (_, row) => {
      const {
        ner,
        hurungu_turul,
        ashiglah_jil,
        ashiglaj_duusah,
        ashiglaj_ehelsen,
        code,
        hurungu_buleg_dugaar,
        urtug_anh,
        urtug_uldeh,
        hemjih_negj,
        merge_burtgel,
        not_tax,
      } = row;
      return (
        ner && (
          <CanView allowedRole="007_1">
            <EditOutlined
              onClick={() => {
                onEdit({
                  ner,
                  hurungu_turul,
                  ashiglah_jil,
                  merge_burtgel,
                  ashiglaj_duusah,
                  ashiglaj_ehelsen,
                  code,
                  hurungu_buleg_dugaar,
                  urtug_anh: roundFloat(urtug_anh),
                  urtug_uldeh: roundFloat(urtug_uldeh),
                  hemjih_negj,
                  not_tax,
                });
              }}
            />
          </CanView>
        )
      );
    },
  },
];

export const ColumnsTab2 = ({ selectRow = () => {} }) => [
  {
    align: 'center',
    width: 30,
    fixed: 'left',
    render: (_, row) => (
      <>
        {row?.state !== 1 && (
          <Warning show listWarning={['Хөрөнгийн мэдээлэл дутуу байна']} />
        )}
      </>
    ),
  },
  {
    title: 'Бүртгэлийн дугаар',
    dataIndex: 'burtgel_dugaar',
    align: 'left',
    width: 150,
    fixed: 'left',
  },
  {
    title: 'Санхүү',
    children: [
      {
        title: 'Анхны өртөг',
        dataIndex: 'urtug_anh',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Үлдэх өртөг',
        dataIndex: 'urtug_uldeh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Норм',
        dataIndex: 'ashiglah_norm',
        align: 'right',
        width: 100,
        render: (_) => (_ === '' ? '---' : roundFloat(_)),
      },
      {
        title: 'Эхэлсэн',
        dataIndex: 'ashiglaj_ehelsen',
        align: 'right',
        width: 100,
        render: (_) => (_ === '' ? '---' : _),
      },
      {
        title: 'Дуусах',
        dataIndex: 'ashiglaj_duusah',
        align: 'right',
        width: 100,
        render: (_) => (_ === '' ? '---' : _),
      },
    ],
  },
  {
    title: 'Татвар',
    children: [
      {
        title: 'Анхны өртөг',
        dataIndex: 'tat_urtug_anh',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Норм',
        dataIndex: 'tat_ashiglah_norm',
        align: 'right',
        width: 100,
        // eslint-disable-next-line no-confusing-arrow
        render: (_, row) =>
          row?.tat_not_use_edit
            ? roundFloat(row?.tat_ashiglah_norm)
            : roundFloat(row?.tat_edited_ashiglah_norm),
      },
      {
        title: 'Эхэлсэн',
        dataIndex: 'tat_ashiglaj_ehelsen',
        align: 'right',
        width: 100,
        // eslint-disable-next-line no-confusing-arrow
        render: (_, row) =>
          row?.tat_not_use_edit
            ? row?.tat_ashiglaj_ehelsen
            : row?.tat_edited_ashiglaj_ehelsen,
      },
      {
        title: 'Дуусах',
        dataIndex: 'tat_ashiglaj_duusah',
        align: 'right',
        width: 100,
        // eslint-disable-next-line no-confusing-arrow
        render: (_, row) =>
          row?.tat_not_use_edit
            ? row?.tat_ashiglaj_duusah
            : row?.tat_edited_ashiglaj_duusah,
      },
      {
        title: '',
        align: 'center',
        dataIndex: 'tat_not_use_edit',
        width: 120,
        render: (_) => (_ ? 'Тооцоолсон' : 'Өөрчилсөн'),
      },
    ],
  },
  {
    title: 'Түрээсийн өглөгийн данс',
    dataIndex: 'turees_uglug_dans',
    align: 'right',
    width: 200,
  },
  {
    title: 'Засах',
    align: 'center',
    width: 50,
    fixed: 'right',
    render: (_, row) => <EditOutlined onClick={() => selectRow(row)} />,
  },
];

export const ErrorListColumns = () => [
  {
    title: 'Үндсэн данс',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
  },
  {
    title: 'Элэгдэл данс',
    dataIndex: 'dans_dugaar_elegdel',
    align: 'left',
    width: 250,
  },
  {
    title: '',
    align: 'left',
    width: 250,
    render: () => 'Дүн таарсангүй',
  },
];
