import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  InputNumber,
  Row,
  Col,
  Spin,
  Button,
  Collapse,
  Form,
  Input,
  DatePicker,
  Tooltip,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  LoadingOutlined,
  DownOutlined,
  UpOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { roundFloat } from 'src/core/utils/formater';
import moment from 'moment';
import { enable_state, disable_state } from 'src/core/actions/route_state';
import { customConfirm } from 'src/components/common/confirmModal';

const { Panel } = Collapse;

const EditableCell2 = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave3,
  ...restProps
}) => {
  const [editingValue, setEditingValue] = useState(null);

  const handleChange = (value) => {
    setEditingValue(roundFloat(value));
    if (handleSave3) {
      handleSave3(record.key, dataIndex, value);
    } else {
      console.error('handleSave is not defined');
    }
  };

  let childNode = children || '';

  if (editable && record) {
    childNode = (
      <InputNumber
        min={0}
        className="wide-input-number" // Apply the custom class
        value={
          editingValue !== null ? editingValue : roundFloat(record[dataIndex])
        }
        onChange={handleChange}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableCellAddRow = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm(); // Use Form.useForm() for better handling
  const inputRef = useRef(null);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus(); // Ensure input is focused
    }
  }, [editing]);

  const toggleEdit = () => {
    if (editable) {
      setEditing(!editing);
      console.log('Editing toggled for:', dataIndex);
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields(); // Use form.validateFields()
      setEditing(false);
      handleSave({ ...record, [dataIndex]: values[dataIndex] }); // Call with updated values
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form
        form={form}
        component={false}
        onFinish={save}
        initialValues={{ [dataIndex]: record[dataIndex] }} // Set initial values
      >
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {dataIndex === 'turul' ? (
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
            />
          ) : (
            <InputNumber
              className="wide-input-number" // Apply the custom class
              ref={inputRef}
              min={0}
              step={0.01}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
              formatter={(value) => `${value}`}
              parser={(value) => value}
              precision={2}
            />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children || ' '}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableCellAddRow2 = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave2,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm(); // Use Form.useForm() for better handling
  const inputRef = useRef(null);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus(); // Ensure input is focused
    }
  }, [editing]);

  const toggleEdit = () => {
    if (editable) {
      setEditing(!editing);
      console.log('Editing toggled for:', dataIndex);
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields(); // Use form.validateFields()
      setEditing(false);
      handleSave2({ ...record, [dataIndex]: values[dataIndex] }); // Call with updated values
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form
        form={form}
        component={false}
        onFinish={save}
        initialValues={{ [dataIndex]: record[dataIndex] }} // Set initial values
      >
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {dataIndex === 'turul' ? (
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
            />
          ) : (
            <InputNumber
              className="wide-input-number" // Apply the custom class
              ref={inputRef}
              min={0}
              step={0.01}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
              formatter={(value) => `${value}`}
              parser={(value) => value}
              precision={2}
            />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children || ' '}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableCellAddRow4 = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave4,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm(); // Use Form.useForm() for better handling
  const inputRef = useRef(null);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus(); // Ensure input is focused
    }
  }, [editing]);

  const toggleEdit = () => {
    if (editable) {
      setEditing(!editing);
      console.log('Editing toggled for:', dataIndex);
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields(); // Use form.validateFields()
      setEditing(false);
      handleSave4({ ...record, [dataIndex]: values[dataIndex] }); // Call with updated values
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form
        form={form}
        component={false}
        onFinish={save}
        initialValues={{ [dataIndex]: record[dataIndex] }} // Set initial values
      >
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {dataIndex === 'turul' ? (
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
            />
          ) : (
            <InputNumber
              className="wide-input-number" // Apply the custom class
              ref={inputRef}
              min={0}
              step={0.01}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
              formatter={(value) => `${value}`}
              parser={(value) => value}
              precision={2}
            />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children || ' '}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const App = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });

  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));
  const dispatch = useDispatch();
  const [totalSum, setTotalSum] = useState({});
  const [totalSum2, setTotalSum2] = useState({});
  const [totalSum4, setTotalSum4] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [dataSource2, setDataSource2] = useState([]);
  const [dataSource3, setDataSource3] = useState([]);
  const [dataSource4, setDataSource4] = useState([]);
  const [initialSummary, seTinitialSummary] = useState({});
  const [newRows, setNewRows] = useState([]); // Track newly added rows
  const [newRows2, setNewRows2] = useState([]); // Track newly added rows
  const [newRows4, setNewRows4] = useState([]); // Track newly added rows

  const handleSend = async () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: {
          onSuccess: [get_data(data_date.month)],
        },
      });
      dispatch(end_loading({}));
    });
    const content = {
      month: data_date?.month,
      action_type: 'save_sa20',
      table_1: dataSource,
      table_2: dataSource2,
      table_3: dataSource3,
      table_4: dataSource4,
    };

    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content,
    });
    dispatch(start_loading({}));
  };

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const get_data = (month) => {
    socket.on('request_out', ({ status, content }) => {
      console.log('🚀 ~ socket.on ~ content:', content);
      socket.removeAllListeners('request_out');
      if (status === '0') {
        setDataSource(content?.table1);
        setDataSource2(content?.table2);
        setDataSource3(content?.table3);
        setDataSource4(content?.table4);
        setTotalSum(content?.total_table1);
        setTotalSum2(content?.total_table2);
        setTotalSum4(content?.total_table4);
        seTinitialSummary(content?.initial_summary);
      } else {
        newAlert({ msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'get_sa20',
        month,
      },
    });
    dispatch(start_loading({}));
  };

  const handleConfirm = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: {
          onSuccess: [get_data(data_date.month)],
        },
      });
      dispatch(end_loading({}));
    });
    const content = {
      month: data_date?.month,
      action_type: 'save_sa20',
      table_1: dataSource,
      table_2: dataSource2,
      table_3: dataSource3,
      table_4: dataSource4,
    };

    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content,
    });
    dispatch(start_loading({}));
  };

  // Helper function to compare floating-point numbers with tolerance
  const areNumbersEqual = (a, b, epsilon = 0.000001) =>
    Math.abs(a - b) < epsilon;

  useEffect(() => {
    get_data(data_date.month);
  }, [data_date.month]);

  const handleSave = (row) => {
    setDataSource((prevDataSource) =>
      prevDataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    );
    setNewRows((prevNewRows) =>
      prevNewRows.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    ); // Update in newRows
  };

  const handleSave2 = (row) => {
    setDataSource2((prevDataSource) =>
      prevDataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    );
    setNewRows2((prevNewRows) =>
      prevNewRows.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    ); // Update in newRows
  };

  const handleSave3 = (key, dataIndex, value) => {
    const newData = [...dataSource3];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, [dataIndex]: value });
    setDataSource3(newData);
  };

  const handleSave4 = (row) => {
    setDataSource4((prevDataSource) =>
      prevDataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    );
    setNewRows4((prevNewRows) =>
      prevNewRows.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    ); // Update in newRows
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleDelete = (key) => {
    setDataSource((prevDataSource) =>
      prevDataSource.filter((item) => item.key !== key)
    );
    setNewRows((prevNewRows) => prevNewRows.filter((item) => item.key !== key)); // Remove from newRows
  };

  const handleDelete2 = (key) => {
    setDataSource2((prevDataSource) =>
      prevDataSource.filter((item) => item.key !== key)
    );
    setNewRows2((prevNewRows) =>
      prevNewRows.filter((item) => item.key !== key)
    ); // Remove from newRows
  };

  const handleDelete4 = (key) => {
    setDataSource4((prevDataSource) =>
      prevDataSource.filter((item) => item.key !== key)
    );
    setNewRows4((prevNewRows) =>
      prevNewRows.filter((item) => item.key !== key)
    ); // Remove from newRows
  };

  const handleAddRow = () => {
    const newData = {
      key: (dataSource.length + 1).toString(),
      turul: '',
      omnoh_bor: 0.0,
      omnoh_er: 0.0,
      tailant_bor: 0.0,
      tailant_er: 0.0,
      editable: {
        turul: true,
        omnoh_bor: true,
        omnoh_er: true,
        tailant_bor: true,
        tailant_er: true,
      },
    };
    setNewRows([...newRows, newData]); // Keep track of the newly added row
    setDataSource([...dataSource, newData]);
    dispatch(enable_state({}));
  };

  const handleAddRow2 = () => {
    const newData = {
      key: (dataSource2.length + 1).toString(),
      turul: '',
      omnoh_on: 0.0,
      tailant_on: 0.0,
      editable: {
        turul: true,
        omnoh_on: true,
        tailant_on: true,
      },
    };
    setNewRows2([...newRows2, newData]); // Keep track of the newly added row
    setDataSource2([...dataSource2, newData]);
    dispatch(enable_state({}));
  };

  const handleAddRow4 = () => {
    const newData = {
      key: (dataSource4.length + 1).toString(),
      turul: '',
      omnoh_on: 0.0,
      tailant_on: 0.0,
      editable: {
        turul: true,
        omnoh_on: true,
        tailant_on: true,
      },
    };
    setNewRows4([...newRows4, newData]); // Keep track of the newly added row
    setDataSource4([...dataSource4, newData]);
    dispatch(enable_state({}));
  };

  // Calculate the sums of only the newly added rows
  const newSummary = newRows.reduce(
    (acc, item) => {
      const omnohBor = parseFloat(item.omnoh_bor) || 0;
      const omnohEr = parseFloat(item.omnoh_er) || 0;
      const tailantBor = parseFloat(item.tailant_bor) || 0;
      const tailantEr = parseFloat(item.tailant_er) || 0;
      acc.omnoh_bor += omnohBor;
      acc.omnoh_er += omnohEr;
      acc.tailant_bor += tailantBor;
      acc.tailant_er += tailantEr;
      return acc;
    },
    { omnoh_bor: 0, omnoh_er: 0, tailant_bor: 0, tailant_er: 0 }
  );

  // Use the initialSummary prop for confirmation logic
  const isConfirmDisabled =
    newRows.length > 0 &&
    (!areNumbersEqual(newSummary.omnoh_bor, initialSummary?.t1?.omnoh_bor) ||
      !areNumbersEqual(newSummary.omnoh_er, initialSummary?.t1?.omnoh_er) ||
      !areNumbersEqual(
        newSummary.tailant_bor,
        initialSummary?.t1?.tailant_bor
      ) ||
      !areNumbersEqual(newSummary.tailant_er, initialSummary?.t1?.tailant_er));

  // Calculate the sums of only the newly added rows
  const newSummary2 = newRows2.reduce(
    (acc, item) => {
      const omnohOn = parseFloat(item.omnoh_on) || 0;
      const tailantOn = parseFloat(item.tailant_on) || 0;
      acc.omnoh_on += omnohOn;
      acc.tailant_on += tailantOn;
      return acc;
    },
    { omnoh_on: 0, tailant_on: 0 }
  );

  // Use the initialSummary prop for confirmation logic
  const isConfirmDisabled2 =
    newRows2.length > 0 &&
    (!areNumbersEqual(newSummary2.omnoh_on, initialSummary?.t2?.omnoh_on) ||
      !areNumbersEqual(newSummary2.tailant_on, initialSummary?.t2?.tailant_on));

  // Calculate the sums of only the newly added rows
  const newSummary4 = newRows4.reduce(
    (acc, item) => {
      const omnohOn = parseFloat(item.omnoh_on) || 0;
      const tailantOn = parseFloat(item.tailant_on) || 0;
      acc.omnoh_on += omnohOn;
      acc.tailant_on += tailantOn;
      return acc;
    },
    { omnoh_on: 0, tailant_on: 0 }
  );

  // Use the initialSummary prop for confirmation logic
  const isConfirmDisabled4 =
    newRows4.length > 0 &&
    (!areNumbersEqual(newSummary4.omnoh_on, initialSummary?.t4?.omnoh_on) ||
      !areNumbersEqual(newSummary4.tailant_on, initialSummary?.t4?.tailant_on));

  const columns = [
    {
      title: 'Үзүүлэлт',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      render: (text, record) => text,
      editable: true,
    },
    {
      title: 'Өмнөх оны дүн',
      children: [
        {
          title: 'БорМар',
          dataIndex: 'omnoh_bor',
          key: 'omnoh_bor',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'ЕрУд',
          dataIndex: 'omnoh_er',
          key: 'omnoh_er',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
      ],
    },
    {
      title: 'Тайлант оны дүн',
      children: [
        {
          title: 'БорМар',
          dataIndex: 'tailant_bor',
          key: 'tailant_bor',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'ЕрУд',
          dataIndex: 'tailant_er',
          key: 'tailant_er',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
      ],
    },
    {
      title: 'Устгах',
      key: 'action',
      render: (_, record) => {
        const isKeyGreaterThanThree = record.key > 22;
        const isTurulNiiit =
          record.turul && record.turul.toLowerCase() === 'нийт дүн';

        if (isKeyGreaterThanThree && !isTurulNiiit) {
          return (
            <Tooltip title="Устгах">
              <DeleteOutlined
                onClick={() =>
                  customConfirm({
                    onOk: () => handleDelete(record.key),
                  })
                }
              />
            </Tooltip>
          );
        }
        return null;
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }

    // If column has children, map over the child columns as well
    if (col.children) {
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editable && record.editable[childCol.dataIndex],
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave,
          }),
        })),
      };
    }

    // Otherwise, handle non-nested columns
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editable && record.editable[col.dataIndex],
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const columns2 = [
    {
      title: 'Зардлын төрөл',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      editable: true,
      render: (text, record) => text,
    },
    {
      title: 'Өмнөх оны дүн',
      dataIndex: 'omnoh_on',
      key: 'omnoh_on',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Тайлант оны дүн',
      dataIndex: 'tailant_on',
      key: 'tailant_on',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Устгах',
      key: 'action',
      render: (_, record) => {
        const isKeyGreaterThanThree = record.key > 4;
        const isTurulNiiit =
          record.turul && record.turul.toLowerCase() === 'нийт';

        if (isKeyGreaterThanThree && !isTurulNiiit) {
          return (
            <Tooltip title="Устгах">
              <DeleteOutlined
                onClick={() =>
                  customConfirm({
                    onOk: () => handleDelete2(record.key),
                  })
                }
              />
            </Tooltip>
          );
        }
        return null;
      },
    },
  ];

  const mergedColumns2 = columns2.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }

    // If column has children, map over the child columns as well
    if (col.children) {
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editable && record.editable[childCol.dataIndex],
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave2,
          }),
        })),
      };
    }

    // Otherwise, handle non-nested columns
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editable && record.editable[col.dataIndex],
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave2,
      }),
    };
  });

  const columns3 = [
    {
      title: 'Ангилал',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      render: (text, record) => text,
      editable: true,
    },
    {
      title: 'Ажиллагчдын дундаж тоо',
      dataIndex: 'dundaj_too',
      key: 'dundaj_too',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Цалингийн зардлын дүн',
      children: [
        {
          title: 'Өмнөх оны дүн',
          dataIndex: 'omnoh_on',
          key: 'omnoh_on',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'Тайлант оны дүн',
          dataIndex: 'tailant_on',
          key: 'tailant_on',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
      ],
    },
  ];
  const mergedColumns3 = columns3.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }
    if (col.children) {
      // Handle child columns
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editableFields
              ? record.editableFields[childCol.dataIndex]
              : false,
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave3,
          }),
        })),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editableFields
          ? record.editableFields[col.dataIndex]
          : false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave3,
      }),
    };
  });

  const columns4 = [
    {
      title: 'Үзүүлэлт',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      render: (text, record) => text,
      editable: true,
    },
    {
      title: 'Өмнөх оны дүн',
      dataIndex: 'omnoh_on',
      key: 'omnoh_on',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Тайлант оны дүн',
      dataIndex: 'tailant_on',
      key: 'tailant_on',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Устгах',
      key: 'action',
      render: (_, record) => {
        const isKeyGreaterThanThree = record.key > 6;
        const isTurulNiiit =
          record.turul && record.turul.toLowerCase() === 'нийт дүн';

        if (isKeyGreaterThanThree && !isTurulNiiit) {
          return (
            <Tooltip title="Устгах">
              <DeleteOutlined
                onClick={() =>
                  customConfirm({
                    onOk: () => handleDelete4(record.key),
                  })
                }
              />
            </Tooltip>
          );
        }
        return null;
      },
    },
  ];

  const mergedColumns4 = columns4.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }

    // If column has children, map over the child columns as well
    if (col.children) {
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editable && record.editable[childCol.dataIndex],
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave4,
          }),
        })),
      };
    }

    // Otherwise, handle non-nested columns
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editable && record.editable[col.dataIndex],
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave4,
      }),
    };
  });

  const {
    omnoh_bor: omnoh_bor_t1,
    omnoh_er: omnoh_er_t1,
    tailant_bor: tailant_bor_t1,
    tailant_er: tailant_er_t1,
  } = totalSum;

  const { omnoh_on: omnoh_on_t2, tailant_on: tailant_on_t2 } = totalSum2;

  const { omnoh_on: omnoh_on_t4, tailant_on: tailant_on_t4 } = totalSum4;

  return (
    <div className="tax-account-table-container">
      <Collapse
        defaultActiveKey={['1', '2', '3']}
        expandIconPosition="right"
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ? <UpOutlined /> : <DownOutlined />
        }
      >
        <Panel
          header="20.1 Борлуулалт маркетингийн болон ерөнхий удирдлагын зардлууд"
          key="1"
        >
          <div className="table-add-row">
            <div className="table-container">
              <Table
                components={{
                  body: {
                    cell: EditableCellAddRow,
                  },
                }}
                dataSource={dataSource}
                columns={mergedColumns}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey="key"
                summary={() => (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Нийт</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      {roundFloat(omnoh_bor_t1)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      {roundFloat(omnoh_er_t1)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      {roundFloat(tailant_bor_t1)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      {roundFloat(tailant_er_t1)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
                footer={() => (
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      type="primary"
                      className="add-btn"
                      onClick={handleAddRow}
                    >
                      <PlusCircleOutlined />
                      Мөр нэмэх
                    </Button>
                    <Tooltip
                      title={
                        isSubmitDisabled
                          ? 'Хоосон мөрнүүдийг заавал бөглөнө үү'
                          : ''
                      }
                    >
                      <Button
                        type="primary"
                        className="confirm-btn"
                        onClick={handleConfirm}
                        style={{ marginLeft: 8 }}
                        disabled={isConfirmDisabled}
                      >
                        Хадгалах
                      </Button>
                    </Tooltip>
                  </div>
                )}
              />
            </div>
          </div>
        </Panel>
        <Panel header="20.2 Бусад зардал" key="2">
          <div className="table-add-row">
            <div className="table-container">
              <Table
                components={{
                  body: {
                    cell: EditableCellAddRow2,
                  },
                }}
                dataSource={dataSource2}
                columns={mergedColumns2}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey="key"
                summary={() => (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Нийт</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      {roundFloat(omnoh_on_t2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      {roundFloat(tailant_on_t2)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
                footer={() => (
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      type="primary"
                      className="add-btn"
                      onClick={handleAddRow2}
                    >
                      <PlusCircleOutlined />
                      Мөр нэмэх
                    </Button>
                    <Tooltip
                      title={
                        isConfirmDisabled2
                          ? 'Хоосон мөрнүүдийг заавал бөглөнө үү'
                          : ''
                      }
                    >
                      <Button
                        type="primary"
                        className="confirm-btn"
                        onClick={handleConfirm}
                        style={{ marginLeft: 8 }}
                        disabled={isConfirmDisabled2}
                      >
                        Хадгалах
                      </Button>
                    </Tooltip>
                  </div>
                )}
              />
            </div>
          </div>
        </Panel>
        <Panel header="20.3 Цалингийн зардал" key="3">
          <Table
            dataSource={dataSource3}
            columns={mergedColumns3}
            rowClassName={() => 'editable-row'}
            bordered
            pagination={false}
            components={{
              body: {
                cell: EditableCell2,
              },
            }}
            footer={() => (
              <Button
                type="primary"
                className="tax-account-button-unique-mini"
                onClick={() => handleSend(1)}
              >
                Хадгалах
              </Button>
            )}
          />
        </Panel>
        <Panel header="20.4 Санхүүгийн зардал" key="4">
          <div className="table-add-row">
            <div className="table-container">
              <Table
                components={{
                  body: {
                    cell: EditableCellAddRow4,
                  },
                }}
                dataSource={dataSource4}
                columns={mergedColumns4}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey="key"
                summary={() => (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Нийт дүн</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      {roundFloat(omnoh_on_t4)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      {roundFloat(tailant_on_t4)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
                footer={() => (
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      type="primary"
                      className="add-btn"
                      onClick={handleAddRow4}
                    >
                      <PlusCircleOutlined />
                      Мөр нэмэх
                    </Button>
                    <Tooltip
                      title={
                        isConfirmDisabled4
                          ? 'Хоосон мөрнүүдийг заавал бөглөнө үү'
                          : ''
                      }
                    >
                      <Button
                        type="primary"
                        className="confirm-btn"
                        onClick={handleConfirm}
                        style={{ marginLeft: 8 }}
                        disabled={isConfirmDisabled4}
                      >
                        Хадгалах
                      </Button>
                    </Tooltip>
                  </div>
                )}
              />
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default App;
