import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { Button, Table as AntTable } from 'antd';

import EditableTable from 'src/components/common/editableTable';

const render = (item) => (typeof item === 'string' ? item : roundFloat(item));

const DelgerenguiTCModal = ({
  modalData,
  getModalValue = () => {},
  onClose = () => {},
  setSelectedRows = () => {},
  selectedRowKeysList = [],
  setSelectedRowKeys = () => {},
  saveData = () => {},
  valiutDunTotal = 0.0,
  currentNehemjleh = {},
  valiutZuruu = 0.0,
}) => (
  <>
    <EditableTable
      columns={[
        {
          title: 'Огноо',
          dataIndex: 'ognoo',
          align: 'center',
          width: 70,
          fixed: 'left',
          render: (_, row) => (
            <>{row?.is_nemelt_guilgee ? <></> : row?.ognoo}</>
          ),
        },
        {
          title: 'Гүйлгээний утга',
          dataIndex: 'guilgeenii_utga',
          align: 'left',
          ellipsis: true,
          width: 300,
          render: (_, row) => (
            <>{row?.is_nemelt_guilgee ? <></> : row?.guilgeenii_utga}</>
          ),
        },
        {
          title: 'Монгол банкны ханш',
          dataIndex: 'mb_hansh',
          align: 'right',
          width: 100,
          render: (_, row) => <>{roundFloat(row?.mb_hansh)}</>,
        },
        {
          title: 'Үлдэгдэл валютын дүн',
          dataIndex: 'valiut_dun_total',
          align: 'right',
          width: 150,
          render: (_, row) => <>{roundFloat(row?.valiut_dun_total)}</>,
        },
        {
          title: 'Гүйлгээний валютын дүн',
          dataIndex: 'valiut_dun',
          align: 'right',
          width: 150,
          render,
          editable: true,
          type: 'number',
        },
      ]}
      dataSource={modalData}
      scroll={{
        y: 250,
      }}
      geTvalue={getModalValue}
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys: selectedRowKeysList,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows([...selectedRows]);
          setSelectedRowKeys([...selectedRowKeys]);
        },
      }}
      customize={() => (
        <AntTable.Summary fixed>
          <AntTable.Summary.Row>
            <AntTable.Summary.Cell align="right" colSpan={4}>
              Сонгогдсон нэхэмжлэхийн валютын дүн:
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell align="right" colSpan={2}>
              <p>{roundFloat(valiutDunTotal)}</p>
            </AntTable.Summary.Cell>
          </AntTable.Summary.Row>
          <AntTable.Summary.Row>
            <AntTable.Summary.Cell align="right" colSpan={4}>
              Гүйлгээний валютын дүн:
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell align="right" colSpan={2}>
              <p>{roundFloat(currentNehemjleh?.ets_uld_valiut_real)}</p>
            </AntTable.Summary.Cell>
          </AntTable.Summary.Row>
          <AntTable.Summary.Row>
            <AntTable.Summary.Cell align="right" colSpan={4}>
              Зөрүү:
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell align="right" colSpan={2}>
              <p>{roundFloat(valiutZuruu)}</p>
            </AntTable.Summary.Cell>
          </AntTable.Summary.Row>
        </AntTable.Summary>
      )}
    />
    <div style={{ textAlign: 'right', marginTop: 10 }}>
      <Button
        onClick={onClose}
        className="cancel-btn"
        type="primary"
        shape="round"
      >
        Буцах
      </Button>
      <Button
        onClick={saveData}
        className="confirm-btn ml-5"
        type="primary"
        shape="round"
        htmlType="submit"
      >
        Хадгалах
      </Button>
    </div>
  </>
);

export default DelgerenguiTCModal;
