export const searchList = [
  {
    type: 'input',
    title: 'Дансны дугаар',
    children: {
      queryKey: 'dans_dugaar',
    },
  },
  {
    type: 'select',
    title: 'Данс ангилалт',
    children: {
      queryKey: 'angilalt',
      list: [
        {
          value: 'Ангилсан',
          name: 'Ангилсан',
        },
        {
          value: 'Ангилаагүй',
          name: 'Ангилаагүй',
        },
      ],
    },
  },
];
