import React from 'react';
import { EditOutlined } from '@ant-design/icons';

export const customColumns = ({ customEdit = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'ner',
    align: 'left',
    width: 300,
    ellipsis: true,
  },
  {
    title: 'Валютын төрөл',
    dataIndex: 'valiut',
    align: 'center',
    width: 80,
  },
  {
    title: 'Ангилал',
    children: [
      {
        title: 'Дугаар',
        dataIndex: 'code',
        align: 'center',
        width: 80,
      },
      {
        title: 'Нэр',
        dataIndex: 'angilal_ner',
        align: 'left',
        width: 300,
        ellipsis: true,
      },
    ],
  },
  {
    title: 'Дэд ангилал',
    dataIndex: 'angilal_sub_text',
    align: 'left',
    width: 100,
    ellipsis: true,
  },
  {
    title: 'Засах',
    align: 'center',
    width: 50,
    fixed: 'right',
    render: (_, row) => (
      <div>
        <EditOutlined onClick={() => customEdit(row)} />
      </div>
    ),
  },
];
