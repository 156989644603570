import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  InputNumber,
  Row,
  Col,
  Spin,
  Button,
  Collapse,
  Form,
  Input,
  DatePicker,
  Tooltip,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  LoadingOutlined,
  DownOutlined,
  UpOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { roundFloat, replaceFloat } from 'src/core/utils/formater';
import { enable_state, disable_state } from 'src/core/actions/route_state';
import { customConfirm } from 'src/components/common/confirmModal';
import debounce from 'lodash.debounce';

const { Panel } = Collapse;

const EditableCellAddRow = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave5,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm(); // Use Form.useForm() for better handling
  const inputRef = useRef(null);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus(); // Ensure input is focused
    }
  }, [editing]);

  const toggleEdit = () => {
    if (editable) {
      setEditing(!editing);
      console.log('Editing toggled for:', dataIndex);
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields(); // Use form.validateFields()
      setEditing(false);
      handleSave5({ ...record, [dataIndex]: values[dataIndex] }); // Call with updated values
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form
        form={form}
        component={false}
        onFinish={save}
        initialValues={{ [dataIndex]: record[dataIndex] }} // Set initial values
      >
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {dataIndex === 'turul' ? (
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
            />
          ) : (
            <InputNumber
              className="wide-input-number" // Apply the custom class
              ref={inputRef}
              min={0}
              step={0.01}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
              formatter={(value) => `${value}`}
              parser={(value) => value}
              precision={2}
            />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children || ' '}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableCellAddRow2 = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave6,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm(); // Use Form.useForm() for better handling
  const inputRef = useRef(null);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus(); // Ensure input is focused
    }
  }, [editing]);

  const toggleEdit = () => {
    if (editable) {
      setEditing(!editing);
      console.log('Editing toggled for:', dataIndex);
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields(); // Use form.validateFields()
      setEditing(false);
      handleSave6({ ...record, [dataIndex]: values[dataIndex] }); // Call with updated values
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form
        form={form}
        component={false}
        onFinish={save}
        initialValues={{ [dataIndex]: record[dataIndex] }} // Set initial values
      >
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {dataIndex === 'turul' ? (
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
            />
          ) : (
            <InputNumber
              className="wide-input-number" // Apply the custom class
              ref={inputRef}
              min={0}
              step={0.01}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
              formatter={(value) => `${value}`}
              parser={(value) => value}
              precision={2}
            />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children || ' '}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableCell3 = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave3,
  ...restProps
}) => {
  const [editingValue, setEditingValue] = useState(null);

  // Debounce handler for the value change to avoid immediate rounding
  const debouncedSave = debounce((value) => {
    const roundedValue = roundFloat(value); // Round after debounce
    handleSave3(record.key, dataIndex, roundedValue);
  }, 300); // Adjust debounce delay (300ms here)

  const handleChange = (value) => {
    setEditingValue(value); // Temporarily hold the raw input before rounding
    debouncedSave(value);
  };

  useEffect(
    () =>
      // Clean up debounce on unmount
      () => {
        debouncedSave.cancel();
      },
    []
  );

  let childNode = children || '';

  if (editable && record) {
    childNode = (
      <InputNumber
        min={0}
        value={editingValue !== null ? editingValue : record[dataIndex]}
        onChange={handleChange}
        className="wide-input-number" // Apply the custom class
        step={0.01} // Allow smooth input for decimal numbers
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editingValue, setEditingValue] = useState(null);

  // Debounce handler for the value change to avoid immediate rounding
  const debouncedSave = debounce((value) => {
    const roundedValue = roundFloat(value); // Round after debounce
    handleSave(record.key, dataIndex, roundedValue);
  }, 300); // Adjust debounce delay (300ms here)

  const handleChange = (value) => {
    setEditingValue(value); // Temporarily hold the raw input before rounding
    debouncedSave(value);
  };

  useEffect(
    () =>
      // Clean up debounce on unmount
      () => {
        debouncedSave.cancel();
      },
    []
  );

  let childNode = children || '';

  if (editable && record) {
    childNode = (
      <InputNumber
        min={0}
        value={editingValue !== null ? editingValue : record[dataIndex]}
        onChange={handleChange}
        className="wide-input-number" // Apply the custom class
        step={0.01} // Allow smooth input for decimal numbers
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableCell4 = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave4,
  ...restProps
}) => {
  const [editingValue, setEditingValue] = useState(null);

  // Debounce handler for the value change to avoid immediate rounding
  const debouncedSave = debounce((value) => {
    const roundedValue = roundFloat(value); // Round after debounce
    handleSave4(record.key, dataIndex, roundedValue);
  }, 300); // Adjust debounce delay (300ms here)

  const handleChange = (value) => {
    setEditingValue(value); // Temporarily hold the raw input before rounding
    debouncedSave(value);
  };

  useEffect(
    () =>
      // Clean up debounce on unmount
      () => {
        debouncedSave.cancel();
      },
    []
  );

  let childNode = children || '';

  if (editable && record) {
    childNode = (
      <InputNumber
        min={0}
        value={editingValue !== null ? editingValue : record[dataIndex]}
        onChange={handleChange}
        className="wide-input-number" // Apply the custom class
        step={0.01} // Allow smooth input for decimal numbers
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const App = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });

  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));
  const dispatch = useDispatch();

  const [dataSource, setDataSource] = useState([]);
  const [dataSource2, setDataSource2] = useState([]);
  const [dataSource3, setDataSource3] = useState([]);
  const [dataSource4, setDataSource4] = useState([]);
  const [dataSource5, setDataSource5] = useState([]);
  const [dataSource6, setDataSource6] = useState([]);
  const [tableTotal6, seTtableTotal6] = useState({});
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
    console.log(dataSource5);

    const allFieldsFilled = dataSource5.every(
      (row) =>
        row.turul !== '' &&
        row.eh_uld !== '' &&
        row.uls !== '' &&
        row.huvi !== '' &&
        row.gadaad !== '' &&
        row.dun
    );
    setIsSubmitDisabled(allFieldsFilled);
  }, [dataSource5]);

  const handleDelete1 = (key) => {
    setDataSource5((prevDataSource) =>
      prevDataSource.filter((item) => item.key !== key)
    );
  };

  const handleDelete2 = (key) => {
    setDataSource6((prevDataSource) =>
      prevDataSource.filter((item) => item.key !== key)
    );
    setNewRows((prevNewRows) => prevNewRows.filter((item) => item.key !== key)); // Remove from newRows
  };

  const handleConfirm = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        onSuccess: [get_data(data_date.month), dispatch(disable_state({}))],
      });
      dispatch(end_loading({}));
    });
    const content = {
      month: data_date?.month,
      action_type: 'save_sa16',
      table_1: dataSource,
      table_2: dataSource2,
      table_3: dataSource3,
      table_4: dataSource4,
      table_5: dataSource5,
      table_6: dataSource6,
      text_1: text1,
      text_2: text2,
      text_3: text3,
    };

    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content,
    });
    dispatch(start_loading({}));
  };

  const handleAddRow = () => {
    const newData = {
      key: (dataSource5.length + 1).toString(),
      turul: '',
      eh_uld: 0.0,
      uls: 0.0,
      huvi: 0.0,
      gadaad: 0.0,
      dun: 0.0,
      editable: {
        turul: true,
        eh_uld: true,
        uls: true,
        huvi: true,
        gadaad: true,
        dun: true,
      },
    };

    // Get the second-to-last index (before the last row)
    const insertPosition = dataSource5.length - 1;

    // If there's at least one row, insert before the last row
    if (dataSource5.length > 0) {
      const updatedData = [...dataSource5];
      updatedData.splice(insertPosition, 0, newData); // Insert at second-to-last position
      setDataSource5(updatedData);
    } else {
      // If the table is empty, just add the new row
      setDataSource5([newData]);
    }

    dispatch(enable_state({}));
  };
  const [newRows, setNewRows] = useState([]); // Track newly added rows
  const handleAddRow2 = () => {
    const newData = {
      key: (dataSource6.length + 1).toString(),
      turul: '',
      eh_uld: 0.0,
      uls: 0.0,
      huvi: 0.0,
      gadaad: 0.0,
      dun: 0.0,
      editable: {
        turul: true,
        eh_uld: true,
        uls: true,
        huvi: true,
        gadaad: true,
        dun: true,
      },
    };

    // If the table is empty, just add the new row
    setDataSource6((prev) => [...prev, newData]);
    setNewRows([...newRows, newData]); // Keep track of the newly added row
    dispatch(enable_state({}));
  };

  // Helper function to compare floating-point numbers with tolerance
  const areNumbersEqual = (a, b, epsilon = 0.000001) =>
    Math.abs(a - b) < epsilon;

  // Calculate the sums of only the newly added rows
  const newSummary = newRows.reduce(
    (acc, item) => {
      const firstBalance = parseFloat(item.eh_uld) || 0;
      const Uls = parseFloat(item.uls) || 0;
      const Gadaad = parseFloat(item.gadaad) || 0;
      const Huvi = parseFloat(item.huvi) || 0;
      const etsUld = parseFloat(item.dun) || 0;
      acc.eh_uld += firstBalance;
      acc.uls += Uls;
      acc.gadaad += Gadaad;
      acc.huvi += Huvi;
      acc.dun += etsUld;
      return acc;
    },
    { eh_uld: 0, uls: 0, gadaad: 0, huvi: 0, dun: 0 }
  );

  // Enable confirmation when newRows is empty OR when the new summary matches tableTotal6
  const isConfirmDisabled =
    newRows.length > 0 &&
    (!areNumbersEqual(newSummary.eh_uld, tableTotal6.eh_uld) ||
      !areNumbersEqual(newSummary.uls, tableTotal6.uls) ||
      !areNumbersEqual(newSummary.gadaad, tableTotal6.gadaad) ||
      !areNumbersEqual(newSummary.huvi, tableTotal6.huvi) ||
      !areNumbersEqual(newSummary.dun, tableTotal6.dun));

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };
  const handleChange1 = (e) => {
    setText1(e.target.value);
  };

  const handleChange2 = (e) => {
    setText2(e.target.value);
  };
  const handleChange3 = (e) => {
    setText3(e.target.value);
  };

  const handleSend = async () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        onSuccess: [get_data(data_date.month), dispatch(disable_state({}))],
      });
      dispatch(end_loading({}));
    });
    const content = {
      month: data_date?.month,
      action_type: 'save_sa16',
      table_1: dataSource,
      table_2: dataSource2,
      table_3: dataSource3,
      table_4: dataSource4,
      table_5: dataSource5,
      table_6: dataSource6,
      text_1: text1,
      text_2: text2,
      text_3: text3,
    };

    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content,
    });
    dispatch(start_loading({}));
  };

  const get_data = (month) => {
    socket.on('request_out', ({ status, content }) => {
      console.log('🚀 ~ socket.on ~ content:', content);
      socket.removeAllListeners('request_out');
      if (status === '0') {
        setDataSource(content?.table1);
        setDataSource2(content?.table2);
        setDataSource3(content?.table3);
        setDataSource4(content?.table4);
        setDataSource5(content?.table5);
        setDataSource6(content?.table6);
        seTtableTotal6(content?.total_table6);
        setText1(content.text1);
        setText2(content.text2);
        setText3(content.text3);
      } else {
        newAlert({ torol: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'get_sa16',
        month,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    get_data(data_date.month);
  }, [data_date.month]);

  const handleSave = (key, dataIndex, value) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];

    // Round the value before saving it
    const roundedValue = replaceFloat(value);
    console.log('Original value:', value);
    console.log('Rounded value:', roundedValue);
    // Update the data source with the rounded value
    newData.splice(index, 1, { ...item, [dataIndex]: roundedValue });

    setDataSource(newData);
  };

  const handleSave2 = (key, dataIndex, value) => {
    const newData = [...dataSource2];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, [dataIndex]: value });
    setDataSource2(newData);
  };
  const handleSave3 = (key, dataIndex, value) => {
    const newData = [...dataSource3];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];

    // Round the value before saving it
    const roundedValue = replaceFloat(value);
    console.log('Original value:', value);
    console.log('Rounded value:', roundedValue);
    // Update the data source with the rounded value
    newData.splice(index, 1, { ...item, [dataIndex]: roundedValue });

    setDataSource3(newData);
  };
  const handleSave4 = (key, dataIndex, value) => {
    const newData = [...dataSource4];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];

    // Round the value before saving it
    const roundedValue = replaceFloat(value);
    console.log('Original value:', value);
    console.log('Rounded value:', roundedValue);
    // Update the data source with the rounded value
    newData.splice(index, 1, { ...item, [dataIndex]: roundedValue });

    setDataSource4(newData);
  };

  const handleSave5 = (updatedRecord) => {
    const newData = [...dataSource5];
    const index = newData.findIndex((item) => updatedRecord.key === item.key);
    if (index >= 0) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...updatedRecord });
    } else {
      // Handle case for new rows if needed
      newData.push(updatedRecord);
    }
    setDataSource5(newData);
  };

  // const handleSave6 = (updatedRecord) => {
  //   const newData = [...dataSource6];
  //   const index = newData.findIndex((item) => updatedRecord.key === item.key);
  //   if (index >= 0) {
  //     const item = newData[index];
  //     newData.splice(index, 1, { ...item, ...updatedRecord });
  //   } else {
  //     // Handle case for new rows if needed
  //     newData.push(updatedRecord);
  //   }
  //   setDataSource6(newData);
  //   setNewRows((prevNewRows) =>
  //     prevNewRows.map((item) =>
  //       item.key === row.key ? { ...item, ...row } : item
  //     )
  //   );
  // };

  const handleSave6 = (row) => {
    setDataSource6((prevDataSource) =>
      prevDataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    );
    setNewRows((prevNewRows) =>
      prevNewRows.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    ); // Update in newRows
  };

  const columns = [
    {
      title: 'Ангилал',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      align: 'left',
      render: (text, record) => text,
    },
    {
      title: 'Эхний үлдэгдэл',
      dataIndex: 'eh_uld',
      key: 'eh_uld',
      editable: true,
      width: 200,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Эцсийн үлдэгдэл',
      dataIndex: 'ets_uld',
      key: 'ets_uld',
      editable: true,
      width: 200,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable:
          record.editable && col.dataIndex !== 'value8' ? col.editable : false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const columns2 = [
    {
      title: 'Татварын өрийн төрөл',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      align: 'left',
      render: (text, record) => text,
    },
    {
      title: 'Эхний үлдэгдэл',
      dataIndex: 'eh_uld',
      key: 'eh_uld',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Эцсийн үлдэгдэл',
      dataIndex: 'ets_uld',
      key: 'ets_uld',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
  ];

  const mergedColumns2 = columns2.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable:
          record.editable && col.dataIndex !== 'value8' ? col.editable : false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const columns3 = [
    {
      title: 'Үзүүлэлт',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      align: 'left',
      render: (text, record) => text,
    },
    {
      title: 'Эхний үлдэгдэл',
      children: [
        {
          title: 'төгрөгөөр',
          dataIndex: 'eh_uld_mnt',
          key: 'eh_uld_mnt',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'валютаар',
          dataIndex: 'eh_uld_valiut',
          key: 'eh_uld_valiut',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
      ],
    },
    {
      title: 'Эцсийн үлдэгдэл',
      children: [
        {
          title: 'төгрөгөөр',
          dataIndex: 'ets_uld_mnt',
          key: 'ets_uld_mnt',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'валютаар',
          dataIndex: 'ets_uld_valiut',
          key: 'ets_uld_valiut',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
      ],
    },
  ];

  const mergedColumns3 = columns3.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }
    if (col.children) {
      // Handle child columns
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editableFields
              ? record.editableFields[childCol.dataIndex]
              : false,
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave3,
          }),
        })),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editableFields
          ? record.editableFields[col.dataIndex]
          : false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave3,
      }),
    };
  });

  const columns4 = [
    {
      title: 'Ангилал',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      align: 'left',
      render: (text) => text,
    },
    {
      title: 'Эхний үлдэгдэл',
      dataIndex: 'eh_uld',
      key: 'eh_uld',
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Нэмэгдсэн',
      dataIndex: 'nemegdsen',
      key: 'nemegdsen',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Хасагдсан (ашигласан нөөц)',
      dataIndex: 'hasagdsan',
      key: 'hasagdsan',
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Ашиглаагүй буцаан бичсэн дүн',
      dataIndex: 'butsaasan_dun',
      key: 'butsaasan_dun',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Эцсийн үлдэгдэл',
      dataIndex: 'ets_uld',
      key: 'ets_uld',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
  ];

  const mergedColumns4 = columns4.map((col) => {
    if (!col.editable) {
      return col;
    }
    // Handle the specific case for 'butsaasan_dun'
    if (col.dataIndex === 'butsaasan_dun') {
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: true, // Ensure it's editable for all rows
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave4,
        }),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable:
          record.editable && col.dataIndex !== 'value8' ? col.editable : false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave4,
      }),
    };
  });
  const columns5 = [
    {
      title: 'Үзүүлэлт',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      editable: true,
      align: 'left',
      render: (text, record) => text,
    },
    {
      title: 'Эхний үлдэгдэл',
      dataIndex: 'eh_uld',
      key: 'eh_uld',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Эцсийн үлдэгдэл',
      children: [
        {
          title: 'Улсын байгууллагад төлөх өр',
          dataIndex: 'uls',
          key: 'uls',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'Хувийн байгууллагад төлөх өр',
          dataIndex: 'huvi',
          key: 'huvi',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'Гадаад байгууллагад төлөх өр',
          dataIndex: 'gadaad',
          key: 'gadaad',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'Дүн',
          dataIndex: 'dun',
          key: 'dun',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'Устгах',
          key: 'action',
          render: (_, record) => {
            const isKeyGreaterThanThree = record.key > 11;
            const isTurulNiiit =
              record.turul && record.turul.toLowerCase() === 'нийт';

            if (isKeyGreaterThanThree && !isTurulNiiit) {
              return (
                <Tooltip title="Устгах">
                  <DeleteOutlined
                    onClick={() =>
                      customConfirm({
                        onOk: () => handleDelete1(record.key),
                      })
                    }
                  />
                </Tooltip>
              );
            }
            return null;
          },
        },
      ],
    },
  ];

  const mergedColumns5 = columns5.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }

    // If column has children, map over the child columns as well
    if (col.children) {
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editable && record.editable[childCol.dataIndex],
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave5,
          }),
        })),
      };
    }

    // Otherwise, handle non-nested columns
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editable && record.editable[col.dataIndex],
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave5,
      }),
    };
  });

  const totalSum = dataSource5.reduce(
    (acc, item) => acc + (parseFloat(item.niit_urtug) || 0),
    0
  );

  const columns6 = [
    {
      title: 'Үзүүлэлт',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      align: 'left',
      render: (text, record) => text,
      editable: true,
    },
    {
      title: 'Эхний үлдэгдэл',
      dataIndex: 'eh_uld',
      key: 'eh_uld',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Эцсийн үлдэгдэл',
      children: [
        {
          title: 'Улсын байгууллагад төлөх өр',
          dataIndex: 'uls',
          key: 'uls',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'Хувийн байгууллагад төлөх өр',
          dataIndex: 'huvi',
          key: 'huvi',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'Гадаадын байгууллагад төлөх өр',
          dataIndex: 'gadaad',
          key: 'gadaad',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'Дүн',
          dataIndex: 'dun',
          key: 'dun',
          editable: true,
          render: (text, record) => {
            if (record.title) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return typeof text === 'number' ? roundFloat(text) : text;
          },
        },
        {
          title: 'Устгах',
          key: 'action',
          render: (_, record) => {
            const isKeyGreaterThanThree = record.key > 4;
            const isTurulNiiit =
              record.turul && record.turul.toLowerCase() === 'нийт';

            if (isKeyGreaterThanThree && !isTurulNiiit) {
              return (
                <Tooltip title="Устгах">
                  <DeleteOutlined
                    onClick={() =>
                      customConfirm({
                        onOk: () => handleDelete2(record.key),
                      })
                    }
                  />
                </Tooltip>
              );
            }
            return null;
          },
        },
      ],
    },
  ];

  const mergedColumns6 = columns6.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }

    // If column has children, map over the child columns as well
    if (col.children) {
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editable && record.editable[childCol.dataIndex],
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave6,
          }),
        })),
      };
    }

    // Otherwise, handle non-nested columns
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editable && record.editable[col.dataIndex],
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave6,
      }),
    };
  });
  return (
    <Collapse
      defaultActiveKey={['1']}
      expandIconPosition="right"
      bordered={false}
      expandIcon={({ isActive }) =>
        isActive ? <UpOutlined /> : <DownOutlined />
      }
    >
      <Panel header="16.1 Дансны өглөг" key="1">
        <Table
          className="tax-account-table-panel mw-1200"
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName={() => 'editable-row'}
          bordered
          pagination={false}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          footer={() => (
            <Button
              type="primary"
              className="tax-account-button-unique-mini"
              onClick={() => handleSend(1)}
            >
              Хадгалах
            </Button>
          )}
        />
      </Panel>

      <Panel header="16.2 Татварын өр" key="2">
        <Table
          className="tax-account-table-panel mw-1200"
          dataSource={dataSource2}
          columns={mergedColumns2}
          rowClassName={() => 'editable-row'}
          bordered
          pagination={false}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          footer={() => (
            <Button
              type="primary"
              className="tax-account-button-unique-mini"
              onClick={() => handleSend(1)}
            >
              Хадгалах
            </Button>
          )}
        />
      </Panel>
      <Panel header="16.3 Богино хугацаат зээл" key="3">
        <Table
          className="tax-account-table-panel"
          dataSource={dataSource3}
          columns={mergedColumns3}
          rowClassName={() => 'editable-row'}
          bordered
          pagination={false}
          components={{
            body: {
              cell: EditableCell3,
            },
          }}
          footer={() => (
            <Button
              type="primary"
              className="tax-account-button-unique-mini"
              onClick={() => handleSend(1)}
            >
              Хадгалах
            </Button>
          )}
        />
      </Panel>
      <Panel header="16.4 Богино хугацаат нөөц өр төлбөр" key="4">
        <Table
          className="tax-account-table-panel"
          dataSource={dataSource4}
          columns={mergedColumns4}
          rowClassName={() => 'editable-row'}
          bordered
          pagination={false}
          components={{
            body: {
              cell: EditableCell4,
            },
          }}
          footer={() => (
            <Button
              type="primary"
              className="tax-account-button-unique-mini"
              onClick={() => handleSend(1)}
            >
              Хадгалах
            </Button>
          )}
        />
      </Panel>
      <Panel
        header="16.4.1 Тэмдэглэл(Урт хугацаат нөөцийн дүнг тодруулна. Нөөцийн төрлөөр тайлбар, тэмдэглэл хийнэ. )"
        key="5"
      >
        <div className="tax-account-notes-container">
          <table className="tax-account-table-unique">
            <thead>
              <tr>
                <th>Тайлбар</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <textarea
                    value={text1}
                    onChange={handleChange1}
                    rows={10}
                    className="tax-account-textarea-unique"
                    placeholder="Бөглөнө үү"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            type="primary"
            className="tax-account-button-unique-mini"
            onClick={() => handleSend(2)}
          >
            Хадгалах
          </Button>
        </div>
      </Panel>
      <Panel header="16.5 Богино хугацаат өр төлбөр" key="6">
        <div className="table-add-row">
          <div className="table-container">
            <Table
              components={{
                body: {
                  cell: EditableCellAddRow,
                },
              }}
              dataSource={dataSource5}
              columns={mergedColumns5}
              pagination={pagination}
              onChange={handleTableChange}
              rowKey="key"
              // summary={() => (
              //   <Table.Summary.Row>
              //     <Table.Summary.Cell index={0}>Нийт</Table.Summary.Cell>
              //     <Table.Summary.Cell index={1} />
              //     <Table.Summary.Cell index={2} />
              //     <Table.Summary.Cell index={3}>
              //       {roundFloat(totalSum)}
              //     </Table.Summary.Cell>
              //     <Table.Summary.Cell index={4} />
              //   </Table.Summary.Row>
              // )}
              footer={() => (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    type="primary"
                    className="add-btn"
                    onClick={handleAddRow}
                  >
                    <PlusCircleOutlined />
                    Мөр нэмэх
                  </Button>
                  <Tooltip
                    title={
                      isSubmitDisabled
                        ? 'Нэмсэн мөрүүдийн дүн дээрх мөртэй таарах ёстой'
                        : ''
                    }
                  >
                    <Button
                      type="primary"
                      className="confirm-btn"
                      onClick={handleConfirm}
                      style={{ marginLeft: 8 }}
                      disabled={isSubmitDisabled}
                    >
                      Хадгалах
                    </Button>
                  </Tooltip>
                </div>
              )}
            />
          </div>
        </div>
      </Panel>
      <Panel
        header="16.5.1 Тэмдэглэл. * Улсын байгууллага ангилалд Төрийн болон орон нутгийн өмчит байгууллагатай холбоотой өр болон татварын өрийг оруулна.**Гадаадын байгууллагад төлөх өртэй бол тайлбар хэсэгт зээлдэгчийн нэр, зээлийн зориулалтыг товч байдлаар оруулна."
        key="7"
      >
        <div className="tax-account-notes-container">
          <table className="tax-account-table-unique">
            <thead>
              <tr>
                <th>Тайлбар</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <textarea
                    value={text2}
                    onChange={handleChange2}
                    rows={10}
                    className="tax-account-textarea-unique"
                    placeholder="Бөглөнө үү"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            type="primary"
            className="tax-account-button-unique-mini"
            onClick={() => handleSend(2)}
          >
            Хадгалах
          </Button>
        </div>
      </Panel>
      <Panel
        header="16.6 Урт хугацаат зээл болон урт хугацаат өр төлбөр"
        key="8"
      >
        <div className="table-add-row">
          <div className="table-container">
            <Table
              components={{
                body: {
                  cell: EditableCellAddRow2,
                },
              }}
              dataSource={dataSource6}
              columns={mergedColumns6}
              pagination={pagination}
              onChange={handleTableChange}
              rowKey="key"
              summary={() => (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>5. Нийт дүн</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    {roundFloat(tableTotal6?.eh_uld)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    {roundFloat(tableTotal6?.uls)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    {roundFloat(tableTotal6?.huvi)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    {roundFloat(tableTotal6?.gadaad)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    {roundFloat(tableTotal6?.dun)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
              footer={() => (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    type="primary"
                    className="add-btn"
                    onClick={handleAddRow2}
                  >
                    <PlusCircleOutlined />
                    Мөр нэмэх
                  </Button>
                  <Tooltip
                    title={
                      isConfirmDisabled
                        ? 'Нэмсэн мөрүүдийн дүн дээрх мөртэй таарах ёстой'
                        : ''
                    }
                  >
                    <Button
                      type="primary"
                      className="confirm-btn"
                      onClick={handleConfirm}
                      style={{ marginLeft: 8 }}
                      disabled={isConfirmDisabled}
                    >
                      Хадгалах
                    </Button>
                  </Tooltip>
                </div>
              )}
            />
          </div>
        </div>
      </Panel>
      <Panel
        header="16.6.1 Тэмдэглэл. * Улсын байгууллага ангилалд Төрийн болон орон нутгийн өмчит байгууллагатай холбоотой өр болон татварын өрийг оруулна."
        key="9"
      >
        <div className="tax-account-notes-container">
          <table className="tax-account-table-unique">
            <thead>
              <tr>
                <th>Тайлбар</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <textarea
                    value={text3}
                    onChange={handleChange3}
                    rows={10}
                    className="tax-account-textarea-unique"
                    placeholder="Бөглөнө үү"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            type="primary"
            className="tax-account-button-unique-mini"
            onClick={() => handleSend(2)}
          >
            Хадгалах
          </Button>
        </div>
      </Panel>
    </Collapse>
  );
};

export default App;
