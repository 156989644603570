import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button as AntButton, Form, Row, Col, Modal, Typography } from 'antd';
import newAlert from 'src/components/newAlert';
import { Table } from 'src/components';
import { start_loading, end_loading } from '../../core/actions/loader';
import { ZuruuColumns } from './dataStructure/data';
import { ElegdelHuvaarilalt } from './elegdelHuvaarilalt';

const { Text } = Typography;

export const ElegdelDelgerenguiZuruu = ({
  visible,
  data,
  onCancel = () => {},
  year_type,
}) => {
  const dispatch = useDispatch();

  const { socket, data_date, version } = useSelector((state) => state);
  const [form] = Form.useForm();

  const [tableData, setTableData] = useState([]);
  const [EHVisible, seTEHVisible] = useState(false);
  const [EHdata, seTEHdata] = useState({
    code: '',
    ner: '',
    burtgel_dugaar: '',
    ognoo: '',
    hurumt_elegdel_du_nemegdel: 0.0,
    hurumt_elegdel_capital_zasvar: 0.0,
  });

  const select_data = () => {
    socket.on('request_out', (req_data) => {
      socket.removeAllListeners('request_out');
      if (req_data.status === '0') {
        setTableData(req_data?.content?.result_list);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'sanhuu_delgerengui',
        dugaar: data?.code,
        year_type,
        month: data_date?.month,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (visible) {
      select_data();
    }
  }, [visible]);

  useEffect(() => {
    if (EHdata?.code !== '') {
      seTEHVisible(true);
    }
  }, [EHdata]);

  const onCancelElegdel = () => {
    seTEHVisible(false);
    select_data();
  };

  const getDate = (cur_year, month) => {
    const year = new Date(cur_year).getFullYear();
    const lastDay = new Date(year, month, 0).getDate();
    const formattedDate = `${year}/${month}/${lastDay}`;
    return formattedDate;
  };

  const editElegdel = () => {
    seTEHdata({
      code: data?.hurungu,
      ner: data?.hurungu_ner,
      burtgel_dugaar: data?.code,
      month: data_date?.month,
      year: version?.year_end,
      ognoo: getDate(version?.year_end, data_date?.month),
    });
  };

  const title = () => (
    <>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        onClick={() => {
          editElegdel();
        }}
      >
        Элэгдэл хуваарилалт
      </AntButton>
    </>
  );

  return (
    <Modal
      title="Дансны үнэ Татварын суурь - Нийт түр зөрүү"
      open={visible}
      width="90%"
      footer={null}
      closable={false}
      maskClosable={false}
      onCancel={onCancel}
      wrapClassName="custom-modal"
      bodyStyle={{ height: 'calc(100vh - 100px)' }}
      centered
    >
      <ElegdelHuvaarilalt
        visible={EHVisible}
        onCancel={onCancelElegdel}
        butgelData={EHdata}
      />
      <div className="dans-medeelel-container">
        <Form
          form={form}
          name="basic"
          className="register-form"
          size="small"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Form.Item
            label="Үндсэн хөрөнгийн нэр"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{data?.hurungu_ner}</Text>
          </Form.Item>
          <Form.Item
            label="Үндсэн хөрөнгийн код"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{data?.hurungu}</Text>
          </Form.Item>
          <Form.Item
            label="Бүртгэлийн дугаар"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{data?.code}</Text>
          </Form.Item>
          <Table
            title={title}
            isTitle
            columns={ZuruuColumns({ onSelect: editElegdel })}
            dataSource={tableData}
            scroll={{
              y: 'calc(100vh - 410px)',
            }}
          />
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <AntButton
                  onClick={() => {
                    onCancel();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </AntButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
