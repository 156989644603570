import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, InputNumber, Table, Form, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { roundFloat } from 'src/core/utils/formater';
import { customConfirm } from 'src/components/common/confirmModal';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { enable_state, disable_state } from 'src/core/actions/route_state';

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useRef(null);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async () => {
    try {
      const values = await form.current.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form ref={form} component={false} onFinish={save}>
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          initialValue={record[dataIndex]}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {dataIndex === 'eh_uld' || dataIndex === 'ets_uld' ? (
            <InputNumber
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              className="wide-input-number" // Apply the custom class
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          ) : (
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children || ' '}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const AddRowTable = () => {
  const dispatch = useDispatch();
  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [summary, seTsummary] = useState({});
  const [initialsummary, seTinitialsummary] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });

  const get_data = (month) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        setDataSource(content?.table_data);
        seTsummary(content?.total_sum);
        seTinitialsummary(content?.initial_sum);
      } else {
        newAlert({ torol: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'get_sa08',
        month,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    get_data(data_date.month);
  }, []);

  const [newRows, setNewRows] = useState([]); // Track newly added rows

  const handleAddRow = () => {
    const newData = {
      key: (dataSource.length + 1).toString(),
      torol: '',
      eh_uld: 0.0,
      ets_uld: 0.0,
      editable: true,
    };
    setDataSource([...dataSource, newData]);
    setNewRows([...newRows, newData]); // Keep track of the newly added row
    dispatch(enable_state({}));
  };

  const handleDelete = (key) => {
    setDataSource((prevDataSource) =>
      prevDataSource.filter((item) => item.key !== key));
    setNewRows((prevNewRows) => prevNewRows.filter((item) => item.key !== key)); // Remove from newRows
  };

  const handleSave = (row) => {
    setDataSource((prevDataSource) =>
      prevDataSource.map((item) =>
        (item.key === row.key ? { ...item, ...row } : item)));
    setNewRows((prevNewRows) =>
      prevNewRows.map((item) =>
        (item.key === row.key ? { ...item, ...row } : item))); // Update in newRows
  };

  // Helper function to compare floating-point numbers with tolerance
  const areNumbersEqual = (a, b, epsilon = 0.000001) =>
    Math.abs(a - b) < epsilon;

  // Calculate the sums of only the newly added rows
  const newSummary = newRows.reduce(
    (acc, item) => {
      const firstBalance = parseFloat(item.eh_uld) || 0;
      const etsUld = parseFloat(item.ets_uld) || 0;
      acc.eh_uld += firstBalance;
      acc.ets_uld += etsUld;
      return acc;
    },
    { eh_uld: 0, ets_uld: 0 }
  );

  // Use the initialSummary prop for confirmation logic
  const isConfirmDisabled = newRows.length > 0
    && (!areNumbersEqual(newSummary.eh_uld, initialsummary.eh_uld)
      || !areNumbersEqual(newSummary.ets_uld, initialsummary.ets_uld));

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleConfirm = () => {
    // Example: Save data to backend or dispatch Redux action

    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: get_data(data_date.month) },
      });
      if (status === '0') {
        dispatch(disable_state({}));
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'save_sa08',
        month: data_date?.month,
        table_data: dataSource,
        summary,
      },
    });
    dispatch(start_loading({}));
  };

  const columns = [
    {
      title: 'Төрөл',
      dataIndex: 'torol',
      key: 'torol',
      editable: true,
    },
    {
      title: 'Эхний үлдэгдэл',
      dataIndex: 'eh_uld',
      key: 'eh_uld',
      editable: true,
      width: 150,
      render: (_, row) => roundFloat(row?.eh_uld),
    },
    {
      title: 'Эцсийн үлдэгдэл',
      dataIndex: 'ets_uld',
      key: 'ets_uld',
      editable: true,
      width: 150,
      render: (_, row) => roundFloat(row?.ets_uld),
    },
    {
      title: 'Устгах',
      key: 'action',
      render: (_, record) =>
        record.editable !== false && (
          <Tooltip title="Устгах">
            <DeleteOutlined
              onClick={() =>
                customConfirm({
                  onOk: () => handleDelete(record.key),
                })}
            />
          </Tooltip>
        ),
    },
  ];

  const mergedColumns = columns.map((col) => ({
    ...col,
    onCell: (record) => ({
      record,
      editable: col.editable && record.editable !== false,
      dataIndex: col.dataIndex,
      title: col.title,
      handleSave,
    }),
  }));

  // const summary = dataSource.reduce(
  //   (acc, item) => {
  //     const firstBalance = parseFloat(item.eh_uld) || 0;
  //     const etsUld = parseFloat(item.ets_uld) || 0;
  //     acc.eh_uld += firstBalance;
  //     acc.ets_uld += etsUld;
  //     return acc;
  //   },
  //   { eh_uld: 0, ets_uld: 0 }
  // );

  return (
    <div className="table-add-row">
      <div className="table-container">
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={dataSource}
          columns={mergedColumns}
          pagination={pagination}
          onChange={handleTableChange}
          rowKey="key"
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Нийт</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {roundFloat(summary.eh_uld)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                {roundFloat(summary.ets_uld)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} />
            </Table.Summary.Row>
          )}
          footer={() => (
            <div style={{ textAlign: 'center' }}>
              <Button
                torol="primary"
                className="add-btn"
                onClick={handleAddRow}
              >
                <PlusCircleOutlined />
                Мөр нэмэх
              </Button>
              <Tooltip
                title={
                  isConfirmDisabled
                    ? 'Нэмсэн мөрүүдийн дүн дээрх мөртэй таарах ёстой'
                    : ''
                }
              >
                <Button
                  torol="primary"
                  className="confirm-btn"
                  onClick={handleConfirm}
                  disabled={isConfirmDisabled}
                  style={{ marginLeft: 8 }}
                >
                  Хадгалах
                </Button>
              </Tooltip>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default AddRowTable;
