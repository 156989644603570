import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Table as AntTable } from 'antd';
import newAlert from 'src/components/newAlert';
import { roundFloat, useQuery } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import Table from 'src/components/common/table';
import { get } from 'lodash';
import { dans_columns } from '../dataStructure/data';

const TabDans = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  const { socket, data_date } = useSelector((state) => ({
    socket: state?.socket,
    data_date: state?.socket,
  }));

  const [incomingData, seTincomingData] = useState({
    list: [],
    sum: null,
    total: 10,
  });

  const handleSelectDans = (dans_code) => {
    const propName = 'dans_code';
    const propName2 = 'tab';
    const proplist = ['Hariltsagch', 'page2', 'size2'];
    if (query.get(propName)) {
      query.set(propName, dans_code);
    } else {
      query.append(propName, dans_code);
    }
    if (query.get(propName2)) {
      query.set(propName2, '2');
    } else {
      query.append(propName2, '2');
    }
    proplist.forEach((name) => {
      if (query.get(name)) {
        query.delete(name);
      }
    });
    history.push({ search: query.toString() });
  };

  useEffect(() => {
    const fetchData = async (page, size) => {
      socket.on('request_out', ({ content = null }) => {
        socket.removeAllListeners('request_out');

        if (content?.status === '0') {
          const { json_array, total, avlaga_sum, uglug_sum } = content;
          const sum = [
            { title: 'Авлагын нийт дүн:', ...avlaga_sum },
            {
              title: 'Өглөгийн нийт дүн:',
              ...uglug_sum,
            },
          ];

          seTincomingData({
            list: json_array,
            total,
            sum,
          });
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }
        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts002_00_dansnii_une',
        content: {
          month: data_date.month,
          action_type: 'ehnii_uldegdel_dansaar',
          page: page || 1,
          size: size || 20,
        },
      });
      dispatch(start_loading({}));
    };
    const page = query.get('page');
    const size = query.get('size');

    if (query.get('tab')) {
      if (query.get('tab') === '1') {
        fetchData(page, size);
      }
    } else fetchData(page, size);
  }, [query, data_date]);

  const showSum = () => {
    const arr = incomingData?.sum;
    if (!arr || arr.length === 0) return null;
    return (
      <AntTable.Summary fixed>
        {arr.map((element) => (
          <AntTable.Summary.Row>
            <AntTable.Summary.Cell align="right">
              {element.title}
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell />
            <AntTable.Summary.Cell align="right">
              <p>{roundFloat(element?.ets_uld)}</p>
              <p className="bg-highlight">
                {roundFloat(element?.ets_uld_valiut)}
              </p>
            </AntTable.Summary.Cell>
          </AntTable.Summary.Row>
        ))}
      </AntTable.Summary>
    );
  };

  return (
    <div className="table-wrapper">
      <div className="inside-table no-scroll">
        <Table
          columns={dans_columns({ handleSelectDans })}
          total={get(incomingData, 'total', null)}
          dataSource={get(incomingData, 'list', [])}
          scroll={{
            y: 'calc(100vh - 370px)',
          }}
          customize={showSum}
        />
      </div>
    </div>
  );
};
export default TabDans;
