import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, message, Collapse } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Table from 'src/components/common/table';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';
import { columns, customSummary } from './dataStructure/data';

const { Panel } = Collapse;

const Sm_03 = ({ description }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState(description);
  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const get_data = (month) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        setText(content?.text);
        setTableData(content?.json_array);
        setTableDataSum(content?.total);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'get_sa03',
        month,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date?.month) get_data(data_date?.month);
  }, [data_date]);

  const handleSend = async () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
      });
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'save_sa03',
        month: data_date?.month,
        text,
      },
    });
    dispatch(start_loading({}));
  };

  return (
    <div className="tax-account-table-container">
      <Collapse
        expandIconPosition="right"
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ? <UpOutlined /> : <DownOutlined />
        }
        className="tax-account-collapse"
        defaultActiveKey={['1', '2']}
      >
        <Panel header="3. Мөнгө түүнтэй адилтгах хөрөнгө" key="1">
          <div className="tax-account-table-panel" style={{ maxWidth: 1200 }}>
            <Table
              columns={columns({})}
              dataSource={tableData}
              scroll={{ y: 'calc(100vh)', x: 'auto' }}
              summaryList={{
                sumColumn: customSummary,
                sumDataSource: tableDataSum,
              }}
            />
          </div>
        </Panel>
        <Panel
          header="3.1 Тэмдэглэл (Мөнгө түүнтэй адилтгах хөрөнгөтэй холбоотой тайлбар, тэмдэглэлийг хийнэ.)"
          key="2"
        >
          <div className="tax-account-table-panel">
            <div className="tax-account-notes-container">
              <table className="tax-account-table-unique">
                <thead>
                  <tr>
                    <th>Тайлбар</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <textarea
                        value={text}
                        onChange={handleChange}
                        rows={10}
                        className="tax-account-textarea-unique"
                        placeholder="Бөглөнө үү"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Button
                type="primary"
                className="tax-account-button-unique-mini"
                onClick={handleSend}
              >
                Хадгалах
              </Button>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default Sm_03;
