/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React from 'react';
import { EditOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Table as AntTable, Row, Col } from 'antd';

import Table from '../../../../components/common/table';

const DelgerenguiTCModal = ({
  detailParam,
  footerParam,
  roundFloat,
  modalData,
  onClose = () => {},
  onEdit = () => {},
  onClear = () => {},
}) => (
  <>
    <div style={{ marginBottom: '5px' }}>
      <Row>
        <Col xs={24}>
          <p>
            <span className="ml-3">Данс: {detailParam.dans_code}</span>
          </p>
        </Col>
        <Col xs={24}>
          <p>
            <span className="ml-3">Харилцагч: {detailParam.hariltsagch}</span>
          </p>
        </Col>
      </Row>
    </div>
    <Table
      columns={[
        {
          title: 'Огноо',
          dataIndex: 'ognoo',
          align: 'center',
          width: 70,
          fixed: 'left',
          render: (_, row) => (
            <>{row?.is_nemelt_guilgee ? <></> : row?.ognoo}</>
          ),
        },
        {
          title: 'Гүйлгээний утга',
          dataIndex: 'guilgeenii_utga',
          align: 'left',
          ellipsis: true,
          width: 250,
          render: (_, row) => (
            <>{row?.is_nemelt_guilgee ? <></> : row?.guilgeenii_utga}</>
          ),
        },
        {
          title: 'Авлага/Өглөг бууруулах ханш',
          dataIndex: 'hansh',
          align: 'right',
          width: 100,
          render: (_, row) => <>{roundFloat(row?.hansh)}</>,
        },
        {
          title: 'Монгол банкны ханш',
          dataIndex: 'mb_hansh',
          align: 'right',
          width: 100,
          render: (_, row) => (
            <>{row?.mb_hansh ? roundFloat(row?.mb_hansh) : roundFloat(0.0)}</>
          ),
        },
        {
          title: 'Түүхэн ханшаар тооцсон ГВХЗ үлдэгдэл /Бодит/',
          align: 'center',
          children: [
            {
              title: 'Ашиг',
              dataIndex: 'ashig',
              align: 'right',
              width: 150,
              render: (_, row) => (
                <>{row?.mb_hansh ? roundFloat(row?.ashig) : roundFloat(0.0)}</>
              ),
            },
            {
              title: 'Алдагдал',
              dataIndex: 'aldagdal',
              align: 'right',
              width: 150,
              render: (_, row) => (
                <>
                  {row?.mb_hansh ? roundFloat(row?.aldagdal) : roundFloat(0.0)}
                </>
              ),
            },
          ],
        },
        {
          title: 'Гүйлгээ',
          align: 'center',
          children: [
            {
              title: 'Дебет',
              dataIndex: 'deb',
              align: 'right',
              width: 150,
              render: (_, row) => (
                <>
                  {row?.valiut === 'MNT' || !row?.valiut ? (
                    roundFloat(row?.deb)
                  ) : (
                    <div>
                      <p>{roundFloat(row?.deb)}</p>
                      <p className="bg-highlight">
                        {roundFloat(row?.deb_valiut)}
                      </p>
                    </div>
                  )}
                </>
              ),
            },
            {
              title: 'Кредит',
              dataIndex: 'cred',
              align: 'right',
              width: 150,
              render: (_, row) => (
                <>
                  {row?.valiut === 'MNT' || !row?.valiut ? (
                    roundFloat(row?.cred)
                  ) : (
                    <div>
                      <p>{roundFloat(row?.cred)}</p>
                      <p className="bg-highlight">
                        {roundFloat(row?.cred_valiut)}
                      </p>
                    </div>
                  )}
                </>
              ),
            },
          ],
        },
        {
          title: 'Эцсийн үлдэгдэл /Татварын суурь/',
          dataIndex: 'ets_uld',
          align: 'right',
          width: 150,
          render: (_, row) => (
            <>
              {row?.valiut === 'MNT' || !row?.valiut ? (
                roundFloat(row?.ets_uld)
              ) : (
                <div>
                  <p>{roundFloat(row?.ets_uld)}</p>
                  <p className="bg-highlight">
                    {roundFloat(row?.ets_uld_valiut)}
                  </p>
                </div>
              )}
            </>
          ),
        },
        {
          title: 'Бууралт / Валют',
          dataIndex: 'ets_uld_real',
          align: 'right',
          width: 100,
          render: (_, row) => (
            <>
              {row?.nemelt_burtgel === 'nehemjleh' ? (
                <div>{roundFloat(row?.ets_uld_valiut_real)}</div>
              ) : null}
            </>
          ),
        },
        {
          align: 'center',
          fixed: 'right',
          width: 50,
          render: (_, row) => (
            <>
              {row?.is_calculated === true &&
              row?.nemelt_burtgel === 'nehemjleh' ? (
                <CheckOutlined />
              ) : (
                <></>
              )}
            </>
          ),
        },
        {
          title: 'Засах',
          align: 'center',
          fixed: 'right',
          width: 50,
          render: (_, row) => (
            <>
              {row?.nemelt_burtgel === 'nehemjleh' ? (
                <EditOutlined onClick={() => onEdit(row)} />
              ) : (
                <></>
              )}
            </>
          ),
        },
      ]}
      dataSource={modalData}
      scroll={{
        y: 250,
      }}
      title={() => (
        <>
          <Button
            id="first"
            className="add-btn"
            type="primary"
            size="small"
            icon={<DeleteOutlined />}
            onClick={onClear}
          >
            Нэхэмжлэхийн задаргааг устгах
          </Button>
        </>
      )}
      customize={() => (
        <AntTable.Summary fixed>
          <AntTable.Summary.Row>
            <AntTable.Summary.Cell align="right" colSpan={2}>
              Нийт дүн:
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell colSpan={2} />
            <AntTable.Summary.Cell align="right">
              <p>{roundFloat(footerParam?.ashig)}</p>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell align="right">
              <p>{roundFloat(footerParam?.aldagdal)}</p>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell align="right">
              <p>{roundFloat(footerParam?.deb)}</p>
              <p className="bg-highlight">
                {roundFloat(footerParam?.deb_valiut)}
              </p>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell align="right">
              <p>{roundFloat(footerParam?.cred)}</p>
              <p className="bg-highlight">
                {roundFloat(footerParam?.cred_valiut)}
              </p>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell />
          </AntTable.Summary.Row>
        </AntTable.Summary>
      )}
    />
    <div style={{ textAlign: 'right', marginTop: 10 }}>
      <Button
        onClick={onClose}
        className="cancel-btn"
        type="primary"
        shape="round"
      >
        Буцах
      </Button>
    </div>
  </>
);

export default DelgerenguiTCModal;
