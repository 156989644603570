import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Modal, Row, Col, Form, Typography } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Table } from 'src/components';

import newAlert from 'src/components/newAlert';
import { hanshColumns, hanshSumColumns } from './dataStructure/data';
import { roundFloat } from '../../../core/utils/formater';
import { start_loading, end_loading } from '../../../core/actions/loader';

import TuuhenHanshRegister from './tuuhenHanshRegister';
import TuuhenHanshDelete from './tuuhenHanshDelete';

const TuuhenHanshModal = ({ initialValues = null, onClose = () => {} }) => {
  const dispatch = useDispatch();

  const [addVisible, setAddVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [hanshList, setHanshList] = useState([]);

  const [deleteForm] = Form.useForm();
  const [addForm] = Form.useForm();

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);

  const handleAddRow = (item_data) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0') {
        setHanshList(data?.content);
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        const temp_data = { ...item_data };
        temp_data.date = new Date(temp_data.guilgee_ognoo).getTime() / 1000;
        resolve(temp_data);
      }, 1000);
    });
    promise.then((temp_data) => {
      socket.emit('request_in', {
        channel: 'ts002_00_eh_uld_register',
        content: {
          action_type: 'register_tuuhen_hansh',
          avlaga_uglug_dun_id_tc: initialValues.dun_id,
          data_type: initialValues.dans_type,
          valiut: '',
          hansh_value: temp_data,
        },
      });
    });
    dispatch(start_loading({}));
  };

  const deleteValue = (formData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0') {
        setHanshList(data?.content);
        newAlert({ type: 'success', msg: 'Амжилттай устгагдлаа' });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts002_00_eh_uld_register',
      content: {
        action_type: 'delete_tuuhen_hansh',
        version: version.version_number,
        year: version.year,
        tuuhen_hansh_id: formData?.tuuhen_hansh_id,
        dun_id: initialValues?.dun_id,
      },
    });
    dispatch(start_loading({}));
  };

  const handleDeleteRow = (tuuhen_hansh_id) => {
    deleteForm.setFieldsValue({
      tuuhen_hansh_id,
    });

    setDeleteVisible(true);
  };

  useEffect(() => {
    if (initialValues?.dun_id) {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');

        if (data.content.status === '0') {
          setHanshList(data?.content);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }

        dispatch(end_loading({}));
      });

      socket.emit('request_in', {
        channel: 'ts002_00_eh_uld_register',
        content: {
          action_type: 'list_tuuhen_hansh',
          avlaga_uglug_dun_id_tc: initialValues.dun_id,
          version: version.version_number,
          year: version.year,
        },
      });
      dispatch(start_loading({}));
    }
  }, [initialValues]);

  const title = () => (
    <>
      <div className="table-title-text">
        <Typography level={5}>
          Эхний үлдэгдэл дүн /валют/ : {roundFloat(initialValues.valiut_dun)}
        </Typography>
      </div>
      <Button
        className="add-btn"
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => setAddVisible(true)}
        size="small"
      >
        Түүхэн ханш бүртгэх
      </Button>
    </>
  );

  return (
    <>
      <div className="table-antd-div">
        <Table
          columns={hanshColumns(handleDeleteRow)}
          dataSource={hanshList?.json_array}
          summaryList={{
            sumColumn: hanshSumColumns,
            sumDataSource: hanshList?.sum_list,
          }}
          title={title}
        />
      </div>
      <Form className="register-form" size="small">
        <Row>
          <Col span={24} className="text-align-r">
            <Button
              onClick={onClose}
              className="cancel-btn"
              type="primary"
              shape="round"
            >
              Буцах
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        className="custom-modal"
        title="Түүхэн ханш бүртгэх"
        visible={addVisible}
        closable={false}
        maskClosable={false}
        width={400}
        footer={null}
      >
        <TuuhenHanshRegister
          onClose={() => {
            setAddVisible(false);
          }}
          form={addForm}
          valiut={initialValues?.dans_valiut}
          handleAddRow={handleAddRow}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Түүхэн ханш устгах"
        visible={deleteVisible}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <TuuhenHanshDelete
          onClose={() => {
            setDeleteVisible(false);
          }}
          form={deleteForm}
          deleteValue={deleteValue}
        />
      </Modal>
    </>
  );
};
export default TuuhenHanshModal;
