import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { roundFloat } from 'src/core/utils/formater';

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'dans_une',
  },
  {
    align: 'right',
    value: 'tatvar_suuri',
  },
  {
    align: 'right',
    value: 'zuruu',
  },
  {
    align: 'right',
    value: 'hz_hasagdah_tur_zuruu',
  },
  {
    align: 'right',
    value: 'hz_tatvar_tur_zuruu',
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'hasagdah_tur_zuruu',
  },
  {
    align: 'right',
    value: 'tatvar_tur_zuruu',
  },
];

export const customColumns = ({ customEdit = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    titleAlign: 'center',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    align: 'left',
    titleAlign: 'center',
    width: 300,
    ellipsis: true,
  },
  {
    title: 'Дансны үнэ',
    dataIndex: 'dans_une',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.dans_une),
    }),
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'tatvar_suuri',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.tatvar_suuri),
    }),
  },
  {
    title: 'Зөрүү',
    dataIndex: 'zuruu',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.zuruu),
    }),
  },
  {
    title: 'Түр зөрүүгээр хүлээн зөвшөөрөхгүй зөрүү',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'hz_hasagdah_tur_zuruu',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.hz_hasagdah_tur_zuruu),
        }),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'hz_tatvar_tur_zuruu',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.hz_tatvar_tur_zuruu),
        }),
      },
    ],
  },
  {
    title: '',
    align: 'center',
    width: 30,
    render: (_, row) => {
      if (row?.mor_type !== 'sum' && row?.can_edit === true) {
        return (
          <div>
            <EditOutlined onClick={() => customEdit(row)} />
          </div>
        );
      }
      return '';
    },
  },
  {
    title: 'Түр зөрүү',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'hasagdah_tur_zuruu',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.hasagdah_tur_zuruu),
        }),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'tatvar_tur_zuruu',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.tatvar_tur_zuruu),
        }),
      },
    ],
  },
];
