import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from 'src/components';

import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { customColumns, customSummary } from './dataStructure/data';

const MainPage = () => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');
      setTableData([]);

      if (data_temp?.status === '0') {
        if ('json_array' in data_temp?.content) {
          setTableData(data_temp.content.json_array);
          setTableDataSum(data_temp.content.sum_data);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: { action_type: 'get_sanhuu_hm02_1', month: String(month) },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
  }, [data_date]);

  const title = () => (
    <>
      <div className="table-title-text-center">
        АЛБАН ТАТВАРААС ЧӨЛӨӨЛӨГДӨХ ОРЛОГЫН МЭДЭЭ /ХМ-02(1)/
      </div>
    </>
  );

  return (
    <>
      <div className="table-antd-div tailan-container">
        <Table
          columns={customColumns()}
          dataSource={tableData}
          scroll={
            {
              // y: 'max-content',
              // x: 'max-content',
            }
          }
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableDataSum,
          }}
          title={title}
        />
      </div>
    </>
  );
};

export default MainPage;
