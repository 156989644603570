import React from 'react';
import {
  SendOutlined,
  DeleteOutlined,
  LoadingOutlined,
  WarningOutlined,
  CommentOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Tooltip, Spin } from 'antd';
import CanView from 'src/components/permission/canview';
import { customConfirm } from 'src/components/common/confirmModal';
import moment from 'moment';
import Icon from 'src/components/common/icon';

const antIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

const dict = {
  src: {
    1: 'Диамонд',
    2: 'Такс',
    3: 'Смарт ложик',
    4: 'Импакт',
    5: 'Юникус',
    6: 'Эрхэт',
  },
  types: {
    2: 'Гүйлгээний жагсаалт',
    1: 'Авлага өглөг баримтын жагсаалт',
    3: 'Үндсэн хөрөнгийн баримтын жагсаалт',
    4: 'Үндсэн хөрөнгийн товчоо',
    5: 'Үндсэн хөрөнгийн баримтын жагсаалт',
  },
  state: {
    0: 'Түүхий',
    1: 'Ачааллаж байна',
    2: 'Уншигдсан',
    3: 'Холбогдсон',
  },
};

export const columns = ({
  send_parse_request = () => {},
  delete_row = () => {},
  showError = () => {},
}) => [
  {
    title: 'Файл',
    align: 'left',
    dataIndex: 'file_source',
    titleAlign: 'center',
    children: [
      {
        title: 'Эх сурвалж',
        align: 'left',
        dataIndex: 'file_source',
        titleAlign: 'center',
        width: 150,
        render: (value) => dict?.src[value],
      },
      {
        title: 'Төрөл',
        align: 'left',
        dataIndex: 'file_type',
        titleAlign: 'center',
        width: 200,
        render: (value) => dict?.types[value],
      },
      {
        title: 'Нэр',
        align: 'left',
        titleAlign: 'center',
        dataIndex: 'received_file_name',
        width: 300,
      },
      {
        title: 'Үүсгэсэн огноо',
        align: 'left',
        titleAlign: 'left',
        dataIndex: 'created_date',
        width: 150,
      },
      {
        title: 'Үүсгэсэн хэрэглэгч',
        align: 'left',
        titleAlign: 'center',
        dataIndex: 'user_name',
        width: 100,
      },
      {
        title: 'Төлөв',
        align: 'center',
        titleAlign: 'center',
        dataIndex: 'file_state',
        width: 100,
        render: (value) => {
          if (value !== -1) {
            return dict?.state[value];
          }
          return 'Алдаатай';
        },
      },
    ],
  },

  {
    title: 'Уншуулах',
    align: 'center',
    titleAlign: 'center',
    width: 100,
    render: (_, row) => {
      if (row.file_state === 0) {
        return (
          // <CanView allowedRole="001_1">
          <Tooltip title="Уншуулах">
            <SendOutlined
              onClick={() => send_parse_request(row.file_id)}
              style={{ color: '#08c' }}
            />
          </Tooltip>
          // </CanView>
        );
      }
      if (row.file_state === 1) {
        return (
          <Tooltip title="Ачааллаж байна">
            <div className="loading-spin">
              <Spin indicator={antIcon} />
            </div>
          </Tooltip>
        );
      }
      if (row.file_state === 2) {
        return (
          <Tooltip title="Уншигдсан">
            <SendOutlined style={{ color: 'disabled' }} />
          </Tooltip>
        );
      }
      if (row.file_state === -1) {
        return (
          <Tooltip title="Алдаатай мөр харах">
            <WarningOutlined
              style={{ color: 'red' }}
              onClick={() => showError(row?.error_msg)}
            />
          </Tooltip>
        );
      }
      return <div />;
    },
  },
  {
    title: 'Устгах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    render: (_, row) => {
      if (row.file_state === 3) {
        return (
          <div>
            <Tooltip title="Файлыг боловсруулалтанд ашигласан байна">
              <DeleteOutlined />
            </Tooltip>
          </div>
        );
      }
      return (
        <div>
          {/* <CanView allowedRole="001_1"> */}
          <Tooltip title="Устгах">
            <DeleteOutlined
              style={{ color: '#08c' }}
              onClick={() =>
                customConfirm({
                  onOk: () => delete_row(row?.file_id),
                })
              }
            />
          </Tooltip>
          {/* </CanView> */}
        </div>
      );
    },
  },
];

export const newColumns = ({
  send_parse_request = () => {},
  manageCom = () => {},
  delete_row = () => {},
  showError = () => {},
  rowList = [],
}) => [
  {
    title: 'Эх сурвалж',
    align: 'left',
    dataIndex: 'file_source',
    titleAlign: 'center',
    width: 130,
    render: (value) => dict?.src[value],
  },
  {
    title: 'Төрөл',
    align: 'left',
    dataIndex: 'file_type',
    titleAlign: 'center',
    width: 250,
    render: (value) => dict?.types[value],
  },
  {
    title: 'Нэр',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'received_file_name',
    width: 230,
    ellipsis: true,
  },
  {
    title: 'Тэмдэглэл',
    align: 'center',
    titleAlign: 'center',
    dataIndex: 'comment',
    width: 100,
    ellipsis: true,
    render: (_) => <>{_}</>,
  },
  {
    title: <EditOutlined style={{ color: '#08c' }} />,
    align: 'center',
    titleAlign: 'center',
    dataIndex: 'comment',
    width: 50,
    ellipsis: true,
    render: (_, row) => (
      <>
        <CommentOutlined
          onClick={() =>
            manageCom({ state: true, file_id: row.file_id, text: _ })
          }
        />
      </>
    ),
  },
  {
    title: 'Үүсгэсэн',
    align: 'center',
    titleAlign: 'center',
    width: 300,
    children: [
      {
        title: 'Огноо',
        align: 'center',
        titleAlign: 'center',
        dataIndex: 'created_date',
        width: 150,
        render: (_) => moment(_).format('YYYY-MM-DD H:m'),
      },
      {
        title: 'Хэрэглэгч',
        align: 'center',
        titleAlign: 'center',
        dataIndex: 'user_name',
        width: 150,
      },
    ],
  },
  {
    title: 'Төлөв',
    align: 'center',
    titleAlign: 'center',
    dataIndex: 'file_state',
    width: 150,
    render: (value) => {
      if (value !== -1) {
        return (
          <div className={` red-status ${value === 2 ? 'success' : ''}`}>
            {dict?.state[value]}
          </div>
        );
      }
      return <div className="red-status error">Алдаатай</div>;
    },
  },

  {
    align: 'center',
    titleAlign: 'center',
    width: 55,
    render: (_, row) => {
      if (row.file_state === 0) {
        return (
          // <CanView allowedRole="001_1">
          <Icon
            row={rowList}
            type="read"
            onClick={() => send_parse_request(row.file_id)}
          />
          // </CanView>
        );
      }
      if (row.file_state === 1) {
        return <Icon row={rowList} type="loader" />;
      }
      if (row.file_state === 2) {
        return <Icon row={rowList} type="success" />;
      }
      if (row.file_state === -1) {
        return <Icon row={rowList} onClick={() => showError(row?.error_msg)} />;
      }
      return <div />;
    },
  },
  {
    align: 'center',
    titleAlign: 'center',
    width: 50,
    render: (_, row) => {
      if (row.file_state === 3) {
        return (
          <div>
            <Tooltip title="Файлыг боловсруулалтанд ашигласан байна">
              <Icon type="delete" />
            </Tooltip>
          </div>
        );
      }
      return (
        <div>
          {/* <CanView allowedRole="001_1"> */}
          <Icon
            type="delete"
            onClick={() =>
              customConfirm({
                onOk: () => delete_row(row?.file_id),
              })
            }
          />
          {/* </CanView> */}
        </div>
      );
    },
  },
];
