import React, { useEffect } from 'react';
import { Redirect, useLocation, Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwt from 'jwt-decode';
import Error from 'src/pages/error';
import UnAuth from 'src/pages/unAuth';
import CompanyList from 'src/pages/initialCompanySelect';
import ChangePassword from 'src/pages/changePassword';
import MainLayout from '../layout/MainLayout';
import NoatLayout from '../layout/NoatLayout';
import landing from './auth_utils/routesLanding';
import Noat from './auth_utils/routesNoat';
import Routes from './Routes';
import { settingsSlicer, routes, routesN } from './auth_utils';

let allRoute = [];
let allNoatList = [];

const AuthChecker = () => {
  const history = useHistory();
  const location = useLocation();

  const { jwt_token, permissionList, version } = useSelector((state) => ({
    jwt_token: state?.jwt_token,
    permissionList: state?.role,
    version: state?.version,
  }));

  let jwt_decoded = null;
  const { jwt_1 } = jwt_token;
  if (jwt_1) {
    jwt_decoded = jwt(jwt_1);
  }

  const { pathname = '' } = location;
  const _permissionList = permissionList.map((i) => i.route);
  allRoute = routes(_permissionList);
  allNoatList = routesN();
  const _allNoatList = allNoatList.map((k) => k.path);
  const isPermission = allRoute.find((i) => i.path === pathname);
  const _mainRoute = allRoute.map((k) => k.path);
  const mainRoute = settingsSlicer(_mainRoute, version?.is_first_year);
  // const token = localStorage.getItem('taxjwt');

  useEffect(() => {
    const { jwt_1: jwt_1_TEMP } = jwt_token;
    let _jwt_decoded = null;
    if (jwt_1_TEMP) {
      _jwt_decoded = jwt(jwt_1_TEMP);
    }

    if (!_jwt_decoded) {
      if (landing.checkList.includes(pathname)) {
        history.push(pathname);
      } else {
        history.push('/login');
      }
    } else if (_jwt_decoded?.user_state === 1) {
      if (_jwt_decoded?.company_state === 0) {
        history.push('/companyList');
      } else if (_jwt_decoded?.company_state === 1) {
        if (location?.pathname === '/companyList') {
          if (localStorage.getItem('modul') === 'noat') {
            history.push('/noat');
          } else {
            history.push('/main');
          }
        } else {
          // history.push(location?.pathname);
        }
      }
    } else if (_jwt_decoded?.user_state === 0) {
      history.push('/changepassword');
    } else {
      history.push('/login');
    }
    // if (!token) {
    //   if (_mainRoute.includes(pathname)) {
    //     history.push('/login');
    //   }
    //   if (landing.checkList.includes(location?.pathname)) {
    //     history.push(location?.pathname);
    //   }
    // }
  }, [jwt_token]);

  if (jwt_decoded?.user_state === 1) {
    if (jwt_decoded?.company_state === 0) {
      return <Route exact path="/companyList" component={CompanyList} />;
    }
    if (localStorage.getItem('modul') === 'noat') {
      return (
        <NoatLayout pathName={pathname}>
          {
            // eslint-disable-next-line no-nested-ternary
            _allNoatList.includes(pathname) ? (
              <Noat />
            ) : (
              <Route
                render={
                  // eslint-disable-next-line no-confusing-arrow
                  () =>
                    !['/', '/login'].includes(pathname) ? (
                      <Error />
                    ) : (
                      <Redirect to="/noat" />
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            )
          }
        </NoatLayout>
      );
    }
    return (
      <MainLayout pathName={pathname}>
        {
          // eslint-disable-next-line no-nested-ternary
          mainRoute.includes(pathname) ? (
            // eslint-disable-next-line no-nested-ternary
            isPermission?.permission ? (
              _permissionList.includes(pathname) ? (
                <Routes />
              ) : (
                <Route exact path={pathname} component={UnAuth} />
              )
            ) : (
              <Routes />
            )
          ) : (
            <Route
              render={
                // eslint-disable-next-line no-confusing-arrow
                () =>
                  !['/', '/login'].includes(pathname) ? (
                    <Error />
                  ) : (
                    <Redirect to="/main" />
                  )
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          )
        }
      </MainLayout>
    );
  }

  if (jwt_decoded?.user_state === 0) {
    return <Route exact path="/changepassword" component={ChangePassword} />;
  }

  if (!jwt_decoded?.user_state) {
    if (landing.checkList.includes(pathname)) {
      return (
        <>
          {landing.routes.map(({ path, component: Component, key }) => (
            <Route
              key={key}
              exact
              path={path}
              render={
                // eslint-disable-next-line no-confusing-arrow
                (props) =>
                  jwt_decoded?.user_state !== 1 ? (
                    <Component {...props} />
                  ) : (
                    <Redirect to="/main" />
                  )
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          ))}
        </>
      );
    }
    return (
      <Route
        render={() => (pathname !== '/' ? <Error /> : <Redirect to="/login" />)}
      />
    );
  }

  if (!jwt_decoded) {
    history.push('/login');
  }
  return null;
};

export default AuthChecker;
