import React from 'react';
import { Button, Table } from 'antd';
import { roundFloat } from 'src/core/utils/formater';

const { Summary } = Table;

export const customSummary = ({ sum_data = {} }) => (
  <Summary fixed>
    <Summary.Row>
      <Summary.Cell />
      <Summary.Cell align="left" colSpan={2}>
        Нийт дүн:
      </Summary.Cell>
      <Summary.Cell align="right">
        <p>{roundFloat(sum_data?.ehnii_uldegdel)}</p>
        <p className="bg-highlight">
          {roundFloat(sum_data?.ehnii_uldegdel_valiut)}
        </p>
      </Summary.Cell>
      <Summary.Cell align="right">
        {roundFloat(sum_data?.hansh_zuruu)}
      </Summary.Cell>
      <Summary.Cell align="right">
        <p>{roundFloat(sum_data?.deb)}</p>
        <p className="bg-highlight">{roundFloat(sum_data?.deb_valiut)}</p>
      </Summary.Cell>
      <Summary.Cell align="right">
        <p>{roundFloat(sum_data?.cred)}</p>
        <p className="bg-highlight">{roundFloat(sum_data?.cred_valiut)}</p>
      </Summary.Cell>
      <Summary.Cell align="right">
        <p>{roundFloat(sum_data?.etssiin_uldegdel)}</p>
        <p className="bg-highlight">
          {roundFloat(sum_data?.etssiin_uldegdel_valiut)}
        </p>
      </Summary.Cell>
    </Summary.Row>
  </Summary>
);

export const customColumns = ({ handleOpenModal = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    titleAlign: 'center',
    width: 250,
    fixed: 'left',
    render: (_, row) => (
      <Button
        id="second"
        color="primary"
        size="small"
        type="link"
        className="underline"
        onClick={() => handleOpenModal(row)}
      >
        {row?.dans_dugaar}
      </Button>
    ),
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    align: 'left',
    titleAlign: 'center',
    width: 300,
    ellipsis: true,
  },
  {
    title: 'Валютын төрөл',
    dataIndex: 'valiut',
    align: 'center',
    titleAlign: 'center',
    width: 80,
  },
  {
    title: 'Эхний үлдэгдэл',
    dataIndex: 'eh_uld',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => {
      if (row?.valiut === 'MNT') {
        return (
          <div>
            <p>{roundFloat(row?.ehnii_uldegdel)}</p>
          </div>
        );
      }
      return (
        <div>
          <p>{roundFloat(row?.ehnii_uldegdel)}</p>
          <p className="bg-highlight">
            {roundFloat(row?.ehnii_uldegdel_valiut)}
          </p>
        </div>
      );
    },
  },
  {
    title: 'Ханшийн зөрүү',
    dataIndex: 'hansh_zuruu',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => <>{roundFloat(row?.hansh_zuruu)}</>,
  },
  {
    title: 'Гүйлгээ',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Дебет',
        dataIndex: 'deb',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => {
          if (row?.valiut === 'MNT') {
            return (
              <div>
                <p>{roundFloat(row?.deb)}</p>
              </div>
            );
          }
          return (
            <div>
              <p>{roundFloat(row?.deb)}</p>
              <p className="bg-highlight">{roundFloat(row?.deb_valiut)}</p>
            </div>
          );
        },
      },
      {
        title: 'Кредит',
        dataIndex: 'cred',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => {
          if (row?.valiut === 'MNT') {
            return (
              <div>
                <p>{roundFloat(row?.cred)}</p>
              </div>
            );
          }
          return (
            <div>
              <p>{roundFloat(row?.cred)}</p>
              <p className="bg-highlight">{roundFloat(row?.cred_valiut)}</p>
            </div>
          );
        },
      },
    ],
  },
  {
    title: 'Эцсийн үлдэгдэл',
    dataIndex: 'eh_uld',
    align: 'left',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => {
      if (row?.valiut === 'MNT') {
        return (
          <div>
            <p>{roundFloat(row?.etssiin_uldegdel)}</p>
          </div>
        );
      }
      return (
        <div>
          <p>{roundFloat(row?.etssiin_uldegdel)}</p>
          <p className="bg-highlight">
            {roundFloat(row?.etssiin_uldegdel_valiut)}
          </p>
        </div>
      );
    },
  },
];

export const modalSummary = ({ sum_data = {} }) => (
  <Summary fixed>
    <Summary.Row>
      <Summary.Cell />
      <Summary.Cell align="left" colSpan={3}>
        Нийт дүн:
      </Summary.Cell>
      <Summary.Cell align="right">
        {roundFloat(sum_data?.hansh_zuruu)}
      </Summary.Cell>
      {sum_data?.valiut === 'MNT' ? (
        <Summary.Cell align="right">
          <p>{roundFloat(sum_data?.deb)}</p>
        </Summary.Cell>
      ) : (
        <Summary.Cell align="right">
          <p>{roundFloat(sum_data?.deb)}</p>
          <p className="bg-highlight">{roundFloat(sum_data?.deb_valiut)}</p>
        </Summary.Cell>
      )}
      {sum_data?.valiut === 'MNT' ? (
        <Summary.Cell align="right">
          <p>{roundFloat(sum_data?.cred)}</p>
        </Summary.Cell>
      ) : (
        <Summary.Cell align="right">
          <p>{roundFloat(sum_data?.cred)}</p>
          <p className="bg-highlight">{roundFloat(sum_data?.cred_valiut)}</p>
        </Summary.Cell>
      )}
      <Summary.Cell colSpan={2} />
    </Summary.Row>
  </Summary>
);

export const modalColumns = [
  {
    title: 'Огноо',
    dataIndex: 'ognoo',
    align: 'left',
    width: 70,
    fixed: 'left',
  },
  {
    title: 'Гүйлгээний утга',
    dataIndex: 'guilgee_utga',
    align: 'left',
    width: 250,
    ellipsis: true,
  },
  {
    title: 'Журналын ханш',
    dataIndex: 'hansh',
    align: 'right',
    width: 80,
    render: (_, row) => <>{roundFloat(row?.hansh)}</>,
  },
  {
    title: 'Монгол банкны ханш',
    dataIndex: 'mb_hansh',
    align: 'right',
    width: 80,
    render: (_, row) => <>{roundFloat(row?.mb_hansh)}</>,
  },
  {
    title: 'Ханшийн зөрүү',
    dataIndex: 'hansh_zuruu',
    align: 'right',
    width: 150,
    render: (_, row) => <>{roundFloat(row?.hansh_zuruu)}</>,
  },
  {
    title: 'Гүйлгээ',
    align: 'center',
    children: [
      {
        title: 'Дебет',
        dataIndex: 'deb',
        align: 'right',
        width: 120,
        render: (_, row) => {
          if (row?.valiut === 'MNT') {
            return (
              <div>
                <p>{roundFloat(row?.deb)}</p>
              </div>
            );
          }
          return (
            <div>
              <p>{roundFloat(row?.deb)}</p>
              <p className="bg-highlight">{roundFloat(row?.deb_valiut)}</p>
            </div>
          );
        },
      },
      {
        title: 'Кредит',
        dataIndex: 'cred',
        key: 'cred',
        align: 'right',
        width: 120,
        render: (_, row) => {
          if (row?.valiut === 'MNT') {
            return (
              <div>
                <p>{roundFloat(row?.cred)}</p>
              </div>
            );
          }
          return (
            <div>
              <p>{roundFloat(row?.cred)}</p>
              <p className="bg-highlight">{roundFloat(row?.cred_valiut)}</p>
            </div>
          );
        },
      },
    ],
  },
  {
    title: 'Эцсийн үлдэгдэл',
    dataIndex: 'ets_uld',
    align: 'right',
    width: 120,
    render: (_, row) => {
      if (row?.valiut === 'MNT') {
        return (
          <div>
            <p>{roundFloat(row?.ets_uld)}</p>
          </div>
        );
      }
      return (
        <div>
          <p>{roundFloat(row?.ets_uld)}</p>
          <p className="bg-highlight">{roundFloat(row?.ets_uld_valiut)}</p>
        </div>
      );
    },
  },
];
