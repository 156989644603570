import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { roundFloat } from 'src/core/utils/formater';

export const customSummary = [
  {
    align: 'center',
    word: '',
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'center',
    word: '',
  },
  {
    align: 'right',
    value: 'dun_eh_deb',
  },
  {
    align: 'right',
    value: 'dun_eh_cred',
  },
  {
    align: 'right',
    value: 'dun_deb',
  },
  {
    align: 'right',
    value: 'dun_cred',
  },
  {
    align: 'right',
    value: 'dun_ets_deb',
  },
  {
    align: 'right',
    value: 'dun_ets_cred',
  },
  {
    align: 'center',
    word: '',
  },
];

export const customColumns = ({ customEdit = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    titleAlign: 'center',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    align: 'left',
    titleAlign: 'center',
    width: 300,
    ellipsis: true,
  },
  {
    title: 'Валютын төрөл',
    dataIndex: 'dans_valiut',
    align: 'center',
    titleAlign: 'center',
    width: 100,
  },
  {
    title: 'Эхний үлдэгдэл',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Дебет',
        dataIndex: 'dun_eh_deb',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => {
          if (row?.dans_valiut === 'MNT') {
            return (
              <div>
                <p>{roundFloat(row?.dun_eh_deb)}</p>
              </div>
            );
          }
          return (
            <div>
              <p>{roundFloat(row?.dun_eh_deb)}</p>
              <p className="bg-highlight">
                {roundFloat(row?.dun_eh_deb_valiut)}
              </p>
            </div>
          );
        },
      },
      {
        title: 'Кредит',
        dataIndex: 'dun_eh_cred',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => {
          if (row?.dans_valiut === 'MNT') {
            return (
              <div>
                <p>{roundFloat(row?.dun_eh_cred)}</p>
              </div>
            );
          }
          return (
            <div>
              <p>{roundFloat(row?.dun_eh_cred)}</p>
              <p className="bg-highlight">
                {roundFloat(row?.dun_eh_cred_valiut)}
              </p>
            </div>
          );
        },
      },
    ],
  },
  {
    title: 'Гүйлгээ',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Дебет',
        dataIndex: 'dun_deb',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => {
          if (row?.dans_valiut === 'MNT') {
            return (
              <div>
                <p>{roundFloat(row?.dun_deb)}</p>
              </div>
            );
          }
          return (
            <div>
              <p>{roundFloat(row?.dun_deb)}</p>
              <p className="bg-highlight">{roundFloat(row?.dun_deb_valiut)}</p>
            </div>
          );
        },
      },
      {
        title: 'Кредит',
        dataIndex: 'dun_cred',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => {
          if (row?.dans_valiut === 'MNT') {
            return (
              <div>
                <p>{roundFloat(row?.dun_cred)}</p>
              </div>
            );
          }
          return (
            <div>
              <p>{roundFloat(row?.dun_cred)}</p>
              <p className="bg-highlight">{roundFloat(row?.dun_cred_valiut)}</p>
            </div>
          );
        },
      },
    ],
  },
  {
    title: 'Эцсийн үлдэгдэл',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Дебет',
        dataIndex: 'dun_ets_deb',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => {
          if (row?.dans_valiut === 'MNT') {
            return (
              <div>
                <p>{roundFloat(row?.dun_ets_deb)}</p>
              </div>
            );
          }
          return (
            <div>
              <p>{roundFloat(row?.dun_ets_deb)}</p>
              <p className="bg-highlight">
                {roundFloat(row?.dun_ets_deb_valiut)}
              </p>
            </div>
          );
        },
      },
      {
        title: 'Кредит',
        dataIndex: 'dun_ets_cred',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => {
          if (row?.dans_valiut === 'MNT') {
            return (
              <div>
                <p>{roundFloat(row?.dun_ets_cred)}</p>
              </div>
            );
          }
          return (
            <div>
              <p>{roundFloat(row?.dun_ets_cred)}</p>
              <p className="bg-highlight">
                {roundFloat(row?.dun_ets_cred_valiut)}
              </p>
            </div>
          );
        },
      },
    ],
  },
  {
    title: 'Засах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    render: (_, row) => {
      if (row?.can_edit === true) {
        return (
          <div>
            <EditOutlined onClick={() => customEdit(row)} />
          </div>
        );
      }
      return '';
    },
  },
];
