import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import CanView from 'src/components/permission/canview';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { Button as AntButton, Form, Row, Col, Divider, Popover } from 'antd';
import { customConfirm } from 'src/components/common/confirmModal';
import newAlert from 'src/components/newAlert';

import { Confirm, Progress, Actived } from './components';

const NewOgogdolBatalgaajuulah = () => {
  const dispatch = useDispatch();
  const { socket, data_date } = useSelector((state) => state);
  const [step, seTstep] = useState(0);

  const [activeData, seTactiveData] = useState(null);

  const [isLoading, setLoading] = useState(false);
  const [accepted, seTaccepted] = useState(true);
  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );

  const stepController = () => {
    socket.on('request_out', ({ content = null }) => {
      console.log('🚀 ~ socket.on ~ content:', content);
      socket.removeAllListeners('request_out');

      seTactiveData(content);
      seTaccepted(content?.accepted);

      seTstep(content?.step + 1);
      dispatch(end_loading({}));
    });

    socket.emit('request_in', {
      channel: 'ts005_00_manage_validation',
      content: {
        action_type: 'get_validation_data',
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    stepController();
  }, []);

  const getPrecent = (isDone) => {
    if (isDone) {
      stepController();
    }
  };

  const send_validation_request = (data) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: stepController() },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: {
        file_list: data,
        start_date: '1',
        end_date: data_date?.month,
        action_type: 'get_file_datas_for_validation',
      },
    });

    dispatch(start_loading({}));
  };

  const delete_validation = (id) => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      newAlert({
        type: data_temp.status === '0' ? 'success' : 'error',
        msg:
          data_temp.status === '0'
            ? 'Амжилттай устлаа'
            : data_temp.content.Message,
        functions: { onSuccess: stepController(), onFail: stepController() },
      });
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts005_00_delete_validation_data',
      content: {
        validation_id: id,
      },
    });

    dispatch(start_loading({}));
  };

  const send_validation_process_request = (id) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: stepController(), onFail: stepController() },
      });
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts005_start_validation',
      content: {
        validation_id: id,
      },
    });

    dispatch(start_loading({}));
  };

  const confirm = async () => {
    try {
      if (step === 1) {
        send_validation_request();
      } else {
        const { id: validation_id } = activeData.json_array;
        if (validation_id) {
          customConfirm({
            onOk: () => send_validation_process_request(validation_id),
            title:
              'Та идэвхжүүлэхдээ итгэлтэй байна уу ? (Идэвхжүүлсэн тохиолдолд өмнөх дүнгүүдийг дарж хадгална !!!)',
          });
        }
      }
    } catch ({ errorFields }) {
      console.warn('validate Fields error', errorFields[0].errors);
    }
  };

  // fetch step
  const comp = {
    1: {
      content: (
        <Confirm
          isLoading={isLoading}
          setLoading={setLoading}
          activeData={activeData}
        />
      ),
      title: ' Баталгаажуулах',
    },
    2: {
      content: (
        <Progress
          socket={socket}
          getPrecent={getPrecent}
          currentPercent={activeData?.json_array?.percent}
        />
      ),
      title: ' Баталгаажуулж байна ...',
    },
    3: {
      content: <Actived data={activeData?.json_array} />,
      title: ' Идэвхжүүлэх',
    },
    4: {
      content: (
        <Progress
          socket={socket}
          getPrecent={getPrecent}
          currentPercent={activeData?.json_array?.percent}
        />
      ),
      title: ' Идэвхжүүлж байна',
    },
    5: {
      content: <Actived data={activeData?.json_array} />,
      title: 'Өгөгдөл идэвхжүүлэх',
    },
  };
  if (!step) return 'loading...';
  const buttonRender = () => {
    if (step === 1) {
      return (
        <AntButton
          disabled={accepted}
          className="upload-btn ml-5"
          shape="round"
          onClick={confirm}
          icon={<CloudUploadOutlined />}
        >
          {accepted ? (
            <Popover placement="topLeft" content={content}>
              <span style={{ color: 'red' }}>Баталгаажуулах</span>
            </Popover>
          ) : (
            <>Баталгаажуулах</>
          )}
        </AntButton>
      );
    }
    if (step === 3) {
      return (
        <div>
          <AntButton
            className="upload-btn delete ml-5"
            shape="round"
            onClick={() => {
              customConfirm({
                onOk: () => delete_validation(activeData?.json_array?.id),
              });
            }}
            icon={<DeleteOutlined />}
          >
            Устгах
          </AntButton>
          {activeData?.json_array?.error_msg?.type !== 'error' ? (
            <AntButton
              className="upload-btn ml-5"
              shape="round"
              onClick={confirm}
              icon={<CloudUploadOutlined />}
            >
              Идэвхжүүлэх
            </AntButton>
          ) : (
            <></>
          )}
        </div>
      );
    }
    if (step === 5) {
      return (
        <div>
          <AntButton
            className="upload-btn delete ml-5"
            shape="round"
            onClick={() =>
              customConfirm({
                onOk: () => delete_validation(activeData?.json_array?.id),
              })
            }
            icon={<CloudUploadOutlined />}
          >
            Устгах
          </AntButton>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="file-confirm">
      <div className="header">
        <div>
          <p className="title">{comp[step].title}</p>
          {() => {
            if (step === 3) {
              return (
                <p
                  className="fs-13"
                  style={{
                    color: '#c6cbd6',
                  }}
                >
                  Өгөгдөл баталгаажуулах төрөл болон харгалзах жагсаалтаа
                  сонгоно уу.
                </p>
              );
            }
          }}
        </div>
      </div>
      <Divider className="mt-10" />
      {comp[step].content}
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Form.Item noStyle>
            {buttonRender()}
            {/* <CanView allowedRole="001_1">{buttonRender()}</CanView> */}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default NewOgogdolBatalgaajuulah;
