import React, { useState } from 'react';

import { Button, Form, Input, Row, Col, Select, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import newAlert from 'src/components/newAlert';

const { Option } = Select;
const { confirm } = Modal;
const UserCreate = ({
  onClose = () => {},
  roleList = [],
  socket = null,
  reRender = () => {},
}) => {
  const [form] = Form.useForm();
  const [btnLoad, seTbtnLoad] = useState(false);
  const createTmz = () => {
    const user_data = form.getFieldsValue();
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        newAlert({ type: 'success', msg: content?.Message });
        onClose();
        reRender();
      } else {
        newAlert({ type: 'error', msg: content?.Message });
      }
    });
    socket.emit('request_in', {
      channel: 'ss004_user_management',
      content: {
        action_type: 'invite_user_rec',
        ...user_data,
      },
    });
  };

  const showPromiseConfirm = () => {
    confirm({
      title: 'Та ТМЗ хэрэглэгч нэмэх гэж байна итгэлтэй байна уу?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        createTmz();
      },
      cancelText: 'Үгүй',
      okText: 'Тийм',
      onCancel() {},
    });
  };

  const submit = (values) => {
    console.log(
      '🚀galaa develop ~ file: index.js ~ line 66 ~ submit ~ values',
      values
    );
    socket.on('request_out', ({ content, status }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        if (content?.type === 'tmz') {
          newAlert({ type: 'warning', msg: content?.Message });
          showPromiseConfirm();
        } else {
          newAlert({ type: 'success', msg: content?.Message });
          onClose();
          reRender();
        }
      } else {
        newAlert({ type: 'error', msg: content?.Message });
      }
      seTbtnLoad(false);
    });
    socket.emit('request_in', {
      channel: 'ss004_user_management',
      content: {
        action_type: 'invite_user_check_company_type',
        ...values,
      },
    });
  };

  const onFinish = (values) => {
    const temp_data = values;
    const result = roleList.filter(
      (word) => word.role_id === temp_data.role_id
    );
    temp_data.role_level = result[0]?.default_role_id;
    submit(temp_data);
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={{
          role_id: roleList[0]?.role_id.toString(),
        }}
      >
        <Form.Item
          name="email"
          label="И-мэйл хаяг"
          labelAlign="right"
          rules={[
            {
              required: true,
              message: 'И-мэйл ээ бөглөнө үү...',
            },
            {
              type: 'email',
              message: 'И-мэйл ээ зөв бөглөнө үү',
            },
          ]}
        >
          <Input placeholder="tax@acc.com" />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, getFieldError }) => {
            const email = getFieldValue('email');
            const error = getFieldError('email');
            if (email && error.length === 0) {
              return (
                <Form.Item
                  name="role_id"
                  label="Эрхийн төрөл сонгох"
                  labelAlign="right"
                >
                  <Select>
                    {roleList.map((i) => (
                      <Option value={i?.role_id}>{i?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }
          }}
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={() => {
                  form.resetFields();
                  onClose();
                }}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Буцах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
                loading={btnLoad}
                onClick={() => {
                  // seTbtnLoad(true);
                }}
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default UserCreate;
