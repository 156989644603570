import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from 'src/components';
import { useQuery } from 'src/core/utils/formater';
import { Modal, Form, Button } from 'antd';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { mainColumns, infoColumns } from './dataStructure/data';
import { ModifySection } from './add';

import { DeleteSelection } from './delete';

const _modalBody = {
  className: 'custom-modal',
  title: '',
  visible: false,
  closable: false,
  maskClosable: false,
  width: 700,
  footer: null,
  key: 'addMain',
  isModifying: false,
};

const _deleteModalBody = {
  className: 'custom-modal',
  title: '',
  visible: false,
  closable: false,
  maskClosable: false,
  width: 500,
  footer: null,
  key: 'deleteMain',
};

const defaultHeader = 'Дансны жагсаалт';

const MainPage = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const socket = useSelector((state) => state.socket);
  const [form] = Form.useForm();
  const [deleteForm] = Form.useForm();
  const history = useHistory();

  const [tableData, setTableData] = useState([]);
  const [infoTable, seTinfoTable] = useState({ json_array: [], total: 0 });
  const [infoTableTitle, seTinfoTableTitle] = useState(defaultHeader);
  const [currentInfoId, seTcurrentInfoId] = useState(null);
  const [modalBody, seTmodalBody] = useState(_modalBody);
  const [dansList, seTdansList] = useState({});
  const [deleteModalBody, seTdeleteModalBody] = useState(_deleteModalBody);

  const resetInfoTable = () => {
    seTinfoTable([]);
    seTinfoTableTitle(defaultHeader);
  };

  const getBulegData = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setTableData(data.content.json_array);
        resetInfoTable();
      } else {
        setTableData([]);
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_holboos_nemelt',
      content: { action_type: 'buleg_get_list' },
    });

    dispatch(start_loading({}));
  };

  const getBulegDansData = (id) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        seTinfoTable(data.content);
        seTcurrentInfoId(id);
      } else {
        seTinfoTable([]);
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const search = {};

    socket.emit('request_in', {
      channel: 'ts001_02_dans_holboos_nemelt',
      content: {
        action_type: 'buleg_get_dans_list',
        id,
        paging: { page, size, search },
      },
    });

    dispatch(start_loading({}));
  };

  const saveFormData = (saveData, isMain) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        if (isMain) {
          getBulegData();
        } else {
          if (data.content.warning.length > 0) {
            newAlert({
              type: 'warning',
              msg: `Хувь хэтэрсэн:${data.content.warning.map(
                (dans) => ` ${dans.toString()}`
              )}`,
            });
          } else {
            newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
          }
          getBulegDansData(currentInfoId);
        }
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_holboos_nemelt',
      content: {
        action_type: isMain ? 'buleg_add' : 'buleg_add_dans',
        data: saveData,
        buleg_data: { id: currentInfoId },
      },
    });

    dispatch(start_loading({}));
  };

  const deleteFormData = (id, isMain) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });

        if (isMain) {
          getBulegData();
        } else if (currentInfoId) {
          getBulegDansData(currentInfoId);
        }
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_holboos_nemelt',
      content: {
        action_type: isMain ? 'buleg_delete' : 'buleg_delete_dans',
        id,
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    getBulegData();
  }, []);

  useEffect(() => {
    if (currentInfoId) {
      getBulegDansData(currentInfoId);
    }
  }, [query]);

  const addMain = (isModifying = false) => {
    seTmodalBody((prev) => ({
      ...prev,
      visible: true,
      title: isModifying ? 'Бүлэг шинэчлэх' : 'Бүлэг нэмэх',
      width: 600,
      key: 'addMain',
      isModifying,
    }));
  };
  const addInfo = (isModifying = false) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        seTdansList(data.content.json_data);
        seTmodalBody((prev) => ({
          ...prev,
          visible: true,
          title: isModifying
            ? 'Дансны жагсаалт шинэчлэх'
            : 'Дансны жагсаалт нэмэх',
          width: 700,
          key: 'addInfo',
          isModifying,
        }));
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_holboos_nemelt',
      content: {
        action_type: 'buleg_get_dans_props',
        id: currentInfoId,
      },
    });

    dispatch(start_loading({}));
  };

  const deleteMain = (data) => {
    deleteForm.setFieldsValue(data);
    seTdeleteModalBody((prev) => ({
      ...prev,
      visible: true,
      title: 'Бүлэг устгах',
      key: 'deleteMain',
    }));
  };
  const deleteInfo = (data) => {
    deleteForm.setFieldsValue(data);
    seTdeleteModalBody((prev) => ({
      ...prev,
      visible: true,
      title: 'Дансны жагсаалт устгах',
      key: 'deleteInfo',
    }));
  };

  const editMain = (data) => {
    form.setFieldsValue(data);
    addMain(true);
  };
  const editInfo = (data) => {
    form.setFieldsValue({
      id: data?.id,
      undsen_dans_ner: data?.dans_ner,
      undsen_dans: data?.dans_id,
      holboh_huvi: data?.perc_deb + data?.perc_cred,
      haritsah_dans: data?.harits_dans_id,
    });
    addInfo(true);
  };

  const _title = (title = '', info = false) => (
    <>
      <div className="title">
        <span> {title || ''}</span>
      </div>
      <Button
        className="add-btn btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          if (info) {
            addInfo();
          } else {
            addMain();
          }
        }}
        disabled={info ? infoTableTitle === defaultHeader : false}
      >
        Нэмэх
      </Button>
    </>
  );

  const onCancel = () => {
    form.resetFields();
    seTmodalBody((prev) => ({
      ...prev,
      visible: false,
    }));
  };

  const onCancelDelete = () => {
    deleteForm.resetFields();
    seTdeleteModalBody((prev) => ({
      ...prev,
      visible: false,
    }));
  };

  const addTitle = () => _title('Бүлэг');
  const infoTitle = () => _title(infoTableTitle, true);

  const title = {
    addTitle,
    infoTitle,
  };

  const makeInfo = (data) => {
    seTinfoTableTitle(`Дансны жагсаалт: ${data?.ner}`);
    getBulegDansData(data.id);
    // Delete page search from query
    query.delete('page');
    history.push({ search: query.toString() });
  };

  const deleteValues = (data, isMain) => {
    deleteFormData(data.id, isMain);
  };

  return (
    <>
      <div className="tohirgo-zagvar-burtgel">
        <div className="main-container">
          <Table
            columns={mainColumns({
              customEdit: editMain,
              customDelete: deleteMain,
              makeInfo,
            })}
            dataSource={tableData}
            scroll={{
              y: 'calc(100vh - 260px)',
            }}
            title={title.addTitle}
          />
        </div>
        <div className="info-container">
          <Table
            columns={infoColumns({
              customEdit: editInfo,
              customDelete: deleteInfo,
            })}
            dataSource={infoTable?.json_array}
            scroll={{
              y: 'calc(100vh - 260px)',
            }}
            title={title.infoTitle}
            total={infoTable?.total}
          />
        </div>
      </div>

      <Modal {...modalBody}>
        <ModifySection
          isMain={modalBody?.key === 'addMain'}
          form={form}
          onCancel={onCancel}
          saveValues={saveFormData}
          dansList={dansList}
          isModifying={modalBody?.isModifying}
        />
      </Modal>
      <Modal {...deleteModalBody}>
        <DeleteSelection
          isMain={deleteModalBody?.key === 'deleteMain'}
          form={deleteForm}
          onCancel={onCancelDelete}
          deleteValues={deleteValues}
        />
      </Modal>
    </>
  );
};

export default MainPage;
