import React from 'react';
import {
  DatabaseOutlined,
  WalletOutlined,
  AuditOutlined,
} from '@ant-design/icons';

export const routeList = [
  {
    key: 'sub21',
    title: 'Өгөгдөл боловсруулах',
    text: 'Өгөгдөл боловсруулах',
    tText: 'noatBolovsruulah',
    icon: <DatabaseOutlined className="fs-20" />,
    url: '/noat/history',
    nodeId: '1',
  },
  {
    key: 'sub22',
    title: 'Борлуулалтын ибаримт тулгалт',
    text: 'Борлуулалтын ибаримт тулгалт',
    tText: 'noatBorluulalt',
    icon: <WalletOutlined className="fs-20" />,
    url: '/noat/borluulalt',
    nodeId: '2',
  },
  {
    key: 'sub23',
    title: 'Борлуулалтын буцаалт',
    text: 'Борлуулалтын буцаалт',
    tText: 'noatBorluulaltButsaalt',
    icon: <WalletOutlined className="fs-20" />,
    url: '/noat/borluulalt_butsaalt',
    nodeId: '3',
  },
  {
    key: 'sub24',
    title: 'Худалдан авалтын ибаримт тулгалт',
    text: 'Худалдан авалтын ибаримт тулгалт',
    tText: 'noatHudaldanavalt',
    icon: <WalletOutlined className="fs-20" />,
    url: '/noat/hudaldanavalt',
    nodeId: '4',
  },
  {
    key: 'sub25',
    title: 'Худалдан авалтын буцаалт',
    text: 'Худалдан авалтын буцаалт',
    tText: 'noatHudaldanavaltButsaalt',
    icon: <WalletOutlined className="fs-20" />,
    url: '/noat/hudaldanavalt_butsaalt',
    nodeId: '5',
  },
  {
    key: 'sub26',
    title: 'НӨАТ тайлан /ТТ-03а/',
    text: 'НӨАТ тайлан /ТТ-03а/',
    tText: 'noatTailan',
    icon: <AuditOutlined className="fs-20" />,
    url: '/noat/tailan',
    nodeId: '6',
  },
  {
    key: 'sub27',
    title: 'НӨАТ нэгтгэлийн хүснэгт',
    text: 'НӨАТ нэгтгэлийн хүснэгт',
    tText: 'noatNegtgel',
    icon: <AuditOutlined className="fs-20" />,
    url: '/noat/negtgel',
    nodeId: '7',
  },
];

export const _menu = [
  [1, 2, 3],
  [4, 5, 6],
  [7, 8, 9],
  [10, 11, 12],
];

export const settings = {
  dots: false,
  infinite: true,
  arrows: false,
  swipe: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
};
