import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { Button, Tooltip } from 'antd';
import { HistoryOutlined, DeleteOutlined } from '@ant-design/icons';
import { date } from '../../../../core/utils/date';

export const dans_columns = ({ handleSelectDans = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_code',
    align: 'left',
    width: 250,
    render: (_, row) => (
      <Button
        type="link"
        size="small"
        className="underline"
        onClick={() => handleSelectDans(row?.dans_code, row?.tur_zuruu_turul)}
      >
        {row?.dans_code}
      </Button>
    ),
  },
  {
    title: 'Түр зөрүүгийн төрөл',
    dataIndex: 'tur_zuruu_turul',
    align: 'center',
    width: 250,
  },
  {
    title: '',
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'ets_uld',
    align: 'right',
    width: 200,
    render: (_, row) => (
      <>
        <p>{roundFloat(row?.ets_uld)}</p>
        <p className="bg-highlight">{roundFloat(row?.ets_uld_valiut)}</p>
      </>
    ),
  },
];

export const hariltsagch_columns = (
  handleOpenModalHansh = () => {},
  tur_zuruu_turul
) => [
  {
    title: 'Харилцагч',
    align: 'center',
    children: [
      {
        title: 'Код',
        dataIndex: 'hariltsagch_code',
        width: 100,
        align: 'left',
      },
      {
        title: 'Нэр',
        dataIndex: 'hariltsagch_ner',
        width: 350,
        align: 'left',
        ellipsis: true,
      },
    ],
  },
  {
    title: '',
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'ets_uld',
    align: 'right',
    width: 200,
    render: (_, row) => (
      <>
        <p>{roundFloat(row?.ets_uld)}</p>
        <p className="bg-highlight">{roundFloat(row?.ets_uld_valiut)}</p>
      </>
    ),
  },
  {
    title: 'Түүхэн ханш',
    dataIndex: 'action',
    align: 'center',
    width: 80,
    render: (_, row) => (
      <>
        {tur_zuruu_turul === 'Гадаад валютын ханшийн зөрүү' ? (
          <HistoryOutlined onClick={() => handleOpenModalHansh(row)} />
        ) : (
          <></>
        )}
      </>
    ),
  },
];

export const hanshColumns = (customDelete = () => {}) => [
  {
    title: 'Огноо',
    dataIndex: 'guilgee_ognoo',
    align: 'left',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => date(row.guilgee_ognoo, true),
  },
  {
    title: 'Валютын дүн',
    dataIndex: 'valiut_dun',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => roundFloat(row.valiut_dun),
  },
  {
    title: 'Ханш',
    dataIndex: 'valiut_hansh',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => roundFloat(row.valiut_hansh),
  },
  {
    title: '',
    dataIndex: 'action',
    align: 'center',
    width: 50,
    render: (_, row) => (
      <>
        <Tooltip title="Устгах">
          <DeleteOutlined onClick={() => customDelete(row.id)} />
        </Tooltip>
      </>
    ),
  },
];

export const hanshSumColumns = [
  { align: 'right', word: 'Нийт валютын дүн:' },
  { align: 'right', value: 'valiut_dun' },
  { value: null },
  { value: null },
];
