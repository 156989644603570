import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';

import { customConfirm } from 'src/components/common/confirmModal';
import { roundFloat } from 'src/core/utils/formater';

export const expColumns = ({ deleteRow = () => {} }) => [
  {
    title: 'Огноо',
    dataIndex: 'ognoo',
    key: 'ognoo',
    align: 'center',
    className: 'children-75',
    width: 75,
  },
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    key: 'code',
    align: 'left',
    className: 'children-100',
    width: 100,
  },
  {
    title: 'Хөрөнгийн нэр',
    dataIndex: 'ner',
    key: 'ner',
    align: 'left',
    className: 'fixed-200',
    ellipsis: true,
    width: 200,
  },
  {
    title: 'Бүртгэлийн дугаар',
    dataIndex: 'burtgel_dugaar',
    key: 'burtgel_dugaar',
    align: 'left',
    className: 'children-100',
    width: 100,
  },
  {
    title: 'Хөрөнгийн төрөл',
    dataIndex: 'hurungu_turul',
    key: 'hurungu_turul',
    align: 'left',
    className: 'children-100',
    width: 100,
  },

  {
    title: 'Засварын дүн',
    dataIndex: 'dun',
    key: 'dun',
    align: 'right',
    className: 'children-200',
    width: 200,
    render: (_) => roundFloat(_),
  },

  {
    title: 'Устгах',
    align: 'center',
    titleAlign: 'center',
    className: 'children-75',
    width: 75,
    render: (_, row) => (
      <>
        <div>
          {row?.id !== 0 && row?.id !== '' ? (
            <DeleteOutlined
              onClick={() =>
                customConfirm({
                  onOk: () => deleteRow(row?.id),
                })}
            />
          ) : (
            <></>
          )}
        </div>
      </>
    ),
  },
];
