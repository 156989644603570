import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import 'moment/locale/mn';
import Table from 'src/components/common/table';
import {
  PlusSquareOutlined,
  PlusOutlined,
  LoadingOutlined,
  EditOutlined,
  InfoCircleOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import CustomDatePicker from 'src/components/common/datePicker';
import ImgUpload from 'src/components/common/imgUpload';
import {
  Typography,
  Drawer,
  Form,
  Input,
  Switch,
  Button as AntButton,
  Row,
  Col,
  Select,
  Modal,
  Tooltip,
  Spin,
  Divider,
} from 'antd';
import { get } from 'lodash';

import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';
import { columns } from './dataStructure/data';

const { Title } = Typography;
const { Option } = Select;

const CreateType = ({
  form = null,
  data = {},
  onCancel = () => {},
  socket = null,
  refetch = () => {},
  dispatch = () => {},
}) => {
  const handleSaveValue = (values) => {
    socket.on('request_out', (data_temp) => {
      socket.removeAllListeners('request_out');

      if (data_temp?.status === '0') {
        onCancel();
        refetch();
        dispatch(end_loading({}));
        newAlert({ type: 'success', msg: 'Дүн амжилттай хадгалагдлаа' });
      } else {
        onCancel();
        refetch();
        dispatch(end_loading({}));
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
    });
    socket.emit('request_in', {
      channel: 'ss005_add_uil_ajillagaa_torol',
      content: {
        array_data: values,
      },
    });
    dispatch(start_loading({}));
  };
  const onFinish = (values) => {
    const { yesOrNo, ..._values } = values;
    handleSaveValue(_values);
  };

  return (
    <div>
      <Form
        form={form}
        className="form"
        style={{
          fontSize: 13,
        }}
        initialValues={{
          torol: 2,
          angilal_torol: null,
        }}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        onFinish={onFinish}
        size="small"
      >
        <Form.Item
          label="Тусгай зөвшөөрөлтэй эсэх"
          name="torol"
          labelAlign="Тусгай зөвшөөрөлтэй эсэх"
        >
          <Select
            className="ww-60"
            onChange={() => {
              form.setFieldsValue({
                angilal_torol: null,
              });
            }}
          >
            <Option value={2}>Тийм</Option>
            <Option value={1}>Үгүй</Option>
          </Select>
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const _type = getFieldValue('torol') === 2
              ? 'zowshooroltei_list'
              : 'zowshoorolgui_list';
            return (
              <Form.Item
                label="Үйл ажиллагааны төрлийн ангилал"
                name="angilal_torol"
                labelAlign="right"
                rules={[
                  {
                    required: true,
                    message: 'Үйл ажиллагааны төрлийн ангилал оруулна уу',
                  },
                ]}
              >
                <Select
                  onChange={() => {
                    form.setFieldsValue({ uil_ajillagaa_torol: null });
                  }}
                >
                  <Option value={null}>Сонгоно уу?</Option>
                  {get(data, _type, []).map((i) => (
                    <Option value={i?.torol_id}>{i?.torol_ner}</Option>
                  ))}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const type = getFieldValue('torol') === 2
              ? 'zowshooroltei_list_inner'
              : 'zowshoorolgui_list_inner';
            const typeId = getFieldValue('angilal_torol');
            if (!typeId || !type) return;
            const list = get(data, type, []).filter(
              (i) => i.torol_id === typeId
            );
            return (
              <Form.Item
                label="Үйл ажиллагааны төрлийн ангилал"
                name="uil_ajillagaa_torol"
                labelAlign="right"
                rules={[
                  {
                    required: true,
                    message: 'Үйл ажиллагааны төрлийн ангилал оруулна уу',
                  },
                ]}
              >
                <Select>
                  <Option value={null}>Сонгоно уу?</Option>
                  {list.map((i) => (
                    <Option value={i?.inner_id}>{i?.torol_ner}</Option>
                  ))}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item noStyle>
              <AntButton
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </AntButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const Client = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [disable, seTdisable] = useState(false);
  const socket = useSelector((state) => state.socket);
  const [toggle, seTtoggle] = useState({
    typeToggle: false,
    listToggle: false,
  });

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [loading, seTloading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [companyData, setCompanyData] = useState({
    company_ner: '',
    company_register_num: '',
    aimag_hot_ner: '',
    aimag_hot_id: '',
    sum_duureg_id: '',
    sum_duureg_ner: '',
    tatvar_tologch_dugaar: '',
    address: '',
    image: '',
    tatvar_tologch_torol: '',
    uussen_ognoo: moment(),
    hariutslaga_helber: '',
    omchiin_helber: '',
    km: '',
    tt_alba: '',
  });

  const toggleControl = (type, bool) => {
    seTtoggle((prev) => ({ ...prev, [type]: !!bool }));
  };

  const mainData = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      if (status === '0') {
        const { company_data } = content;
        setCompanyData({
          company_ner: company_data?.company_ner,
          company_register_num: company_data?.company_register_num,
          aimag_hot_ner: company_data?.aimag_hot_ner,
          aimag_hot_id: company_data?.aimag_hot_id,
          sum_duureg_id: company_data?.sum_duureg_id,
          sum_duureg_ner: company_data?.sum_duureg_ner,
          tatvar_tologch_dugaar: company_data?.tatvar_tologch_dugaar,
          address: company_data?.address,
          tatvar_tologch_torol: company_data?.tatvar_tologch_torol,
          uussen_ognoo: company_data?.uussen_ognoo,
          hariutslaga_helber: company_data?.hariutslaga_helber,
          omchiin_helber: company_data?.omchiin_helber,
          km: company_data?.km,
          image: company_data?.image,
          segment: company_data?.segment,
          tt_alba: company_data?.tt_alba,
        });
        const new_temp_data = content?.uil_ajillagaa_list.map(
          (inner_data, key) => ({
            id: key + 1,
            ...inner_data,
          })
        );
        if (new_temp_data) {
          setTableData(new_temp_data);
        }

        setAddressList(content);
        seTloading(false);
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss005_company_manage',
      content: { action_type: 'get_company_data' },
    });

    dispatch(start_loading({}));
  };
  useEffect(() => {
    mainData();
  }, []);

  const delete_row = (id) => {
    socket.on('request_out', ({ status }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      if (status === '0') {
        newAlert({ type: 'success', msg: 'Дүн амжилттай устлаа' });
        mainData();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        mainData();
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss005_delete_uil_ajillagaa_torol',
      content: {
        id,
        action_type: 'delete',
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (companyData) {
      form.setFieldsValue({
        ...companyData,
      });
    }
  }, [companyData]);

  const handleSaveValue = (_companyData) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');

      if (status === '0') {
        mainData();
        newAlert({ type: 'success', msg: content.Message });
        dispatch(end_loading({}));
        seTdisable(false);
      } else {
        newAlert({ type: 'error', msg: content.Message });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ss005_company_manage',
      content: {
        array_data: _companyData,
        action_type: 'update_company_data',
      },
    });
    dispatch(start_loading({}));
  };

  const onFinish = (values) => {
    handleSaveValue(values);
  };
  const onChange = (value) => {
    if (!value) {
      form.setFieldsValue({
        ...companyData,
      });
    }
    seTdisable(!disable);
  };

  const plusIcon = (
    <PlusSquareOutlined
      style={{
        fontSize: 25,
        color: '#02c0df',
      }}
    />
  );
  const plusButton = () => (
    <>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusOutlined />}
        onClick={() => {
          toggleControl('typeToggle', 'open');
        }}
      >
        Нэмэх
      </AntButton>
    </>
  );

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin indicator={antIcon} />;
      </div>
    );
  }

  return (
    <>
      <div className="container-tatvar-info">
        <div className="box">
          <div className="header">
            <Title level={3}>Татвар төлөгчийн мэдээлэл</Title>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Switch
                checkedChildren="Буцах"
                unCheckedChildren="Засах"
                onChange={onChange}
              />
              <span
                style={{
                  color: '#02c0df',
                  marginLeft: 20,
                  fontWeight: 'bold',
                }}
              >
                Үйл ажиллагааны чиглэл
              </span>
              <Tooltip title="Үйл ажиллагааны жагсаалт">
                <AntButton
                  style={{
                    marginLeft: 5,
                  }}
                  type="link"
                  onClick={() => {
                    toggleControl('listToggle', 'open');
                  }}
                  icon={plusIcon}
                  size="middle"
                />
              </Tooltip>
            </div>
          </div>
          <Row>
            <Col md={{ span: 14, offset: 5 }} lg={{ span: 18, offset: 3 }}>
              <Form
                form={form}
                className="form"
                id="tax-info"
                style={{
                  fontSize: 13,
                }}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                onFinish={onFinish}
                initialValues={{
                  aimag_hot_id: null,
                }}
              >
                <Divider>
                  {/* <InfoCircleOutlined className="mr-10" /> */}
                  Үндсэн мэдээлэл
                </Divider>
                <Form.Item
                  label="Нэр"
                  name="company_ner"
                  labelAlign="right"
                  className="mb-20 mt-20"
                  rules={[
                    {
                      required: true,
                      message: 'Нэр оруулна уу',
                    },
                  ]}
                >
                  <Input
                    className="ww-60"
                    bordered={disable}
                    disabled={!disable}
                  />
                </Form.Item>
                <Divider />
                <Form.Item
                  className="mb-20 mt-20"
                  label="Регистрийн дугаар"
                  name="company_register_num"
                  labelAlign="right"
                >
                  <Input
                    className="ww-60"
                    placeholder="**** ***"
                    bordered={disable}
                    disabled
                  />
                </Form.Item>
                <Divider />

                <Form.Item
                  className="mb-20 mt-20"
                  label="Татвар төлөгчийн дугаар"
                  name="tatvar_tologch_dugaar"
                  labelAlign="right"
                  rules={[
                    {
                      required: true,
                      message: 'Татвар төлөгчийн дугаар оруулна уу',
                    },
                  ]}
                >
                  <Input
                    className="ww-60"
                    placeholder="**** **** ***"
                    bordered={disable}
                    disabled={!disable}
                  />
                </Form.Item>
                <Divider />
                <Form.Item
                  className="mb-20 mt-20"
                  label="Татвар төлөгчийн төрөл"
                  name="hariutslaga_helber"
                  labelAlign="right"
                >
                  <Input
                    placeholder="ХХК..."
                    className="ww-60"
                    bordered={disable}
                    disabled={!disable}
                  />
                </Form.Item>
                <Divider />

                <Form.Item
                  className="mb-20 mt-20"
                  label="Компанийн лого"
                  name="image"
                >
                  <ImgUpload disabled={!disable} />
                </Form.Item>

                <Divider />
                <Form.Item
                  label="Сегмент"
                  name="segment"
                  className="mb-20 mt-20"
                  labelAlign="right"
                >
                  <Input
                    className="ww-60"
                    placeholder="Сегмент ээ бичнэ үү...."
                    bordered={disable}
                    disabled
                  />
                </Form.Item>
                <Divider />

                <Form.Item
                  label="Өмчийн хэлбэр"
                  name="omchiin_helber"
                  labelAlign="right"
                  className="mb-20 mt-20"
                >
                  <Input
                    placeholder="Өмчийн хэлбэрээ бичнэ үү..."
                    className="ww-60"
                    bordered={disable}
                    disabled={!disable}
                  />
                </Form.Item>
                <Divider />

                <Form.Item
                  label="УБЕГ-д бүртгэгдсэн огноо"
                  name="uussen_ognoo"
                  labelAlign="right"
                  className="mb-20 mt-20"
                  rules={[
                    {
                      required: true,
                      message: 'огноо оруулна уу',
                    },
                  ]}
                >
                  <CustomDatePicker
                    noBorder={!disable}
                    disable={!disable}
                    customClass="ww-60"
                  />
                </Form.Item>
                <Divider>
                  {/* <InfoCircleOutlined className="mr-10" /> */}
                  Хаяг байршил
                </Divider>

                <Form.Item
                  label="Аймаг, хот"
                  name="aimag_hot_id"
                  labelAlign="right"
                  className="mb-20 mt-20"
                  rules={[
                    {
                      required: true,
                      message: 'Аймаг, хот оруулна уу',
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: 200,
                    }}
                    bordered={disable}
                    disabled={!disable}
                    onChange={() => {
                      form.setFieldsValue({ sum_duureg_id: null });
                    }}
                  >
                    <Option value={null}>Сонгоно уу?</Option>
                    {get(addressList, 'aimag_list', []).map((i) => (
                      <Option value={i?.aimag_id}>{i?.aimag_ner}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const aimagId = getFieldValue('aimag_hot_id');
                    const { sum_list = [] } = addressList;
                    if (!aimagId) return;
                    const foundedSumList = sum_list.filter(
                      (i) => i.aimag_id === aimagId
                    );
                    if (foundedSumList && foundedSumList.length > 0) {
                      return (
                        <Form.Item
                          label="Сум, дүүрэг"
                          name="sum_duureg_id"
                          labelAlign="right"
                          rules={[
                            {
                              required: true,
                              message: 'Сум, дүүрэг оруулна уу',
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: 200,
                            }}
                            bordered={disable}
                            disabled={!disable}
                          >
                            <Option value={null}>Сонгоно уу?</Option>
                            {foundedSumList.map((i) => (
                              <Option value={i?.sum_hot_id}>
                                {i?.sum_ner}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }
                  }}
                </Form.Item>
                <Divider />

                <Form.Item
                  label="Харьяалагдах татварын алба"
                  className="mb-20 mt-20"
                  name="tt_alba"
                  labelAlign="right"
                  rules={[
                    {
                      required: true,
                      message: 'Заавал сонгоно уу',
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: 250,
                    }}
                    bordered={disable}
                    disabled={!disable}
                  >
                    <Option value={null}>Сонгоно уу?</Option>
                    {get(addressList, 'tt_alba_types', []).map((i) => (
                      <Option value={i}>{i}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Divider />
                <Form.Item
                  className="mb-20 mt-20"
                  label="Хаяг"
                  name="address"
                  labelAlign="right"
                >
                  <Input.TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    bordered={disable}
                    disabled={!disable}
                    placeholder="....."
                    className="ww-60"
                  />
                </Form.Item>
                <Divider />
                <Form.Item
                  className="mb-20 mt-20"
                  label="Алслагдсан зай"
                  name="km"
                  labelAlign="right"
                >
                  <Input
                    placeholder="** км"
                    className="ww-60"
                    bordered={disable}
                    disabled
                  />
                </Form.Item>
                <div
                  className="steps-action-admin right"
                  style={{ left: 'auto' }}
                >
                  <AntButton
                    form="tax-info"
                    className="confirm-btn ml-5"
                    type="primary"
                    shape="round"
                    htmlType="submit"
                    disabled={!disable}
                  >
                    Хадгалах
                    <ArrowRightOutlined className="ml-5" />
                  </AntButton>
                </div>
                <div
                  className="steps-action-admin left"
                  style={{ right: 'auto' }}
                >
                  <AntButton
                    className="confirm-btn ml-5 mt-5"
                    type="primary"
                    shape="round"
                    onClick={() => {
                      history.push('/main');
                    }}
                  >
                    Буцах
                  </AntButton>
                </div>
              </Form>
            </Col>
          </Row>
          <Drawer
            title="Үйл ажиллагааны жагсаалт"
            placement="right"
            closable={false}
            visible={toggle?.listToggle}
            onClose={() => {
              toggleControl('listToggle');
            }}
            getContainer={false}
            style={{ position: 'absolute' }}
            width={750}
            className="custom-drawer"
          >
            <div style={{ maxWidth: '100%', maxHeight: '100%' }}>
              <Table
                title={plusButton}
                columns={columns({
                  delete_row,
                })}
                dataSource={tableData}
                scroll={{
                  y: 400,
                  x: 'auto',
                }}
              />
              <div
                className="steps-action-admin right"
                style={{ left: 'auto' }}
              >
                <AntButton
                  className="confirm-btn ml-5 mt-5"
                  type="primary"
                  shape="round"
                  onClick={() => {
                    toggleControl('listToggle');
                  }}
                >
                  Буцах
                </AntButton>
              </div>
            </div>
          </Drawer>
        </div>
      </div>

      <Modal
        title="Үйл ажиллагааны төрөл нэмэх"
        visible={toggle?.typeToggle}
        width={700}
        footer={null}
        onCancel={() => {
          form1.resetFields();
          toggleControl('typeToggle');
        }}
        wrapClassName="custom-modal"
      >
        <CreateType
          data={addressList}
          onCancel={() => {
            form1.resetFields();
            toggleControl('typeToggle');
          }}
          form={form1}
          socket={socket}
          refetch={mainData}
          dispatch={dispatch}
        />
      </Modal>
    </>
  );
};
export default Client;
