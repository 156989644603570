const oDefaultState = {
  month: localStorage.getItem('activeMonth') || '12',
  year: localStorage.getItem('activeYear') || new Date().getFullYear(),
};

const reducer = (state = oDefaultState, action) => {
  switch (action.type) {
    case 'set_month':
      return { ...state, month: action.payload };
    case 'set_year':
      return { ...state, year: action.payload };
    default:
      return state;
  }
};

export default reducer;
