import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import Login from './login';
import { Connecting } from './utils';
import comp from './utils/components';

const tabList = [
  {
    id: 1,
    text: 'Тайлан хадгалах',
    content: comp.send,
  },
  {
    id: 2,
    text: 'Тайлангийн түүх',
    content: comp.history,
  },
  {
    id: 3,
    text: 'Хоцорсон тайлан',
    content: comp.late,
  },
];
const Etax = () => {
  const [connecting, seTconnecting] = useState(false);
  const etaxtoken = localStorage.getItem('etaxtoken');
  const [isActive, seTisActive] = useState(false);

  const [tab, seTtab] = useState(2);

  const mainData = () => {
    if (etaxtoken) {
      const { access_token, received_timestamp, expires_duration } =
        JSON.parse(etaxtoken);
      if (
        access_token &&
        parseFloat(received_timestamp) + expires_duration >
          Math.floor(Date.now() / 1000)
      ) {
        seTisActive(true);
      } else {
        localStorage.removeItem('etaxtoken');
        seTisActive(false);
      }
    }

    if (isActive) {
      seTconnecting(true);
      setTimeout(() => {
        seTconnecting(false);
      }, 3000);
    }
  };
  useEffect(() => {
    mainData();
  }, [isActive, etaxtoken]);

  if (!isActive) {
    return <Login />;
  }

  const clearToken = () => {
    localStorage.removeItem('etaxtoken');
    seTisActive(false);
  };

  const setTabs = (id) => {
    seTtab(id);
  };
  const { content: Content, text = '' } = tabList.find(
    (element) => element?.id === tab
  );

  return (
    <div className={`e-tax ${connecting && 'connecting'}`}>
      {connecting ? (
        <Connecting />
      ) : (
        <div className="inside-e-tax">
          <div className="header-section">
            <div className="title-section">
              <span className="title"> E-TAX </span>

              <Button className="e-tax-btn" onClick={clearToken}>
                Гарах
              </Button>
            </div>
            <div className="tab-section">
              {tabList.map((t) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                  className={`one-tab ${t?.id === tab ? 'active' : ''} `}
                  onClick={() => {
                    setTabs(t?.id);
                  }}
                >
                  {t?.text}
                </div>
              ))}
            </div>
          </div>
          <div className="container-section">
            <div className="title">
              <p>{text} хүснэгт</p>
            </div>
            <Content />
          </div>
        </div>
      )}
    </div>
  );
};

export default Etax;
