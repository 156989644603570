import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Input,
  InputNumber,
  Table,
  Form,
  Tooltip,
  Collapse,
  Spin,
} from 'antd';
import {
  PlusCircleOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { roundFloat, replaceFloat } from 'src/core/utils/formater';
import { customConfirm } from 'src/components/common/confirmModal';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';
import { enable_state, disable_state } from 'src/core/actions/route_state';

const { Panel } = Collapse;

const isTableFilled = (dataSource) =>
  // Check if every item in the dataSource has the necessary fields filled
  dataSource.every(
    (item) => item.torol && item.eh_uld !== '' && item.ets_uld !== ''
  );
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useRef(null);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async () => {
    try {
      const values = await form.current.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form ref={form} component={false} onFinish={save}>
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          initialValue={record[dataIndex]}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {dataIndex === 'eh_uld' || dataIndex === 'ets_uld' ? (
            <InputNumber
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              className="wide-input-number" // Apply the custom class
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          ) : (
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children || ' '}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const AddRowTable = ({
  dataSource,
  setDataSource,
  initialSummary,
  table_type,
  tablevalue,
}) => {
  const dispatch = useDispatch();
  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });

  const [newRows, setNewRows] = useState([]); // Track newly added rows
  const handleAddRow = () => {
    let baseTorol;

    // Determine the base torol value based on the table type
    if (table_type === 2) {
      baseTorol = 4.0;
    } else if (table_type === 3) {
      baseTorol = 7.0;
    } else {
      baseTorol = 0.0;
    }

    const maxTorol = dataSource.reduce((max, item) => {
      const itemTorol = parseFloat(item.torol) || 0;
      return Math.max(max, itemTorol);
    }, baseTorol);

    const newTorol = (maxTorol + 0.1).toFixed(1);

    const newData = {
      key: (dataSource.length + 1).toString(),
      torol: newTorol,
      eh_uld: '',
      ets_uld: '',
      editable: true,
    };

    setDataSource([...dataSource, newData]);
    setNewRows([...newRows, newData]); // Keep track of the newly added row
    dispatch(enable_state({}));
  };

  const handleDelete = (key) => {
    setDataSource((prevDataSource) =>
      prevDataSource.filter((item) => item.key !== key)
    );
    setNewRows((prevNewRows) => prevNewRows.filter((item) => item.key !== key)); // Remove from newRows
  };

  const handleSave = (row) => {
    setDataSource((prevDataSource) =>
      prevDataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    );
    setNewRows((prevNewRows) =>
      prevNewRows.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    ); // Update in newRows
  };

  // Helper function to compare floating-point numbers with tolerance
  const areNumbersEqual = (a, b, epsilon = 0.000001) =>
    Math.abs(a - b) < epsilon;

  // Calculate the sums of only the newly added rows
  const newSummary = newRows.reduce(
    (acc, item) => {
      const firstBalance = parseFloat(item.eh_uld) || 0;
      const etsUld = parseFloat(item.ets_uld) || 0;
      acc.eh_uld += firstBalance;
      acc.ets_uld += etsUld;
      return acc;
    },
    { eh_uld: 0, ets_uld: 0 }
  );

  // Use the single tablevalue prop for confirmation logic
  const isConfirmDisabled =
    newRows.length > 0 &&
    (!areNumbersEqual(newSummary.eh_uld, tablevalue.eh_uld) ||
      !areNumbersEqual(newSummary.ets_uld, tablevalue.ets_uld));

  const handleSend = async () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        onSuccess: [dispatch(disable_state({}))],
      });
      dispatch(end_loading({}));
    });
    const content = {
      month: data_date?.month,
      action_type: 'save_sa04',
      table_type,
      table_data: dataSource,
    };

    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content,
    });
    dispatch(start_loading({}));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleConfirm = () => {
    // Example: Save data to backend or dispatch Redux action
    console.log('Saved data:', dataSource);
    // Implement your save logic here
  };

  const columns = [
    {
      title: 'Төрөл',
      dataIndex: 'torol',
      key: 'torol',
      editable: true,
    },
    {
      title: 'Эхний үлдэгдэл',
      dataIndex: 'eh_uld',
      key: 'eh_uld',
      editable: true,
      width: 150,
      render: (_, row) => roundFloat(row?.eh_uld),
    },
    {
      title: 'Эцсийн үлдэгдэл',
      dataIndex: 'ets_uld',
      key: 'ets_uld',
      editable: true,
      width: 150,
      render: (_, row) => roundFloat(row?.ets_uld),
    },
    {
      title: 'Устгах',
      key: 'action',
      render: (_, record) =>
        record.editable !== false && (
          <Tooltip title="Устгах">
            <DeleteOutlined
              onClick={() =>
                customConfirm({
                  onOk: () => handleDelete(record.key),
                })
              }
            />
          </Tooltip>
        ),
    },
  ];

  const mergedColumns = columns.map((col) => ({
    ...col,
    onCell: (record) => ({
      record,
      editable: col.editable && record.editable !== false,
      dataIndex: col.dataIndex,
      title: col.title,
      handleSave,
    }),
  }));

  return (
    <div className="table-add-row">
      <div className="table-container">
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={dataSource}
          columns={mergedColumns}
          onChange={handleTableChange}
          rowKey="key"
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Нийт</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {roundFloat(initialSummary.eh_uld)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                {roundFloat(initialSummary.ets_uld)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} />
            </Table.Summary.Row>
          )}
          footer={() => (
            <div style={{ textAlign: 'center' }}>
              <Button
                torol="primary"
                className="add-btn"
                onClick={handleAddRow}
              >
                <PlusCircleOutlined />
                Мөр нэмэх
              </Button>
              <Tooltip
                title={
                  isConfirmDisabled
                    ? 'Нэмсэн мөрүүдийн дүн дээрх мөртэй таарах ёстой'
                    : ''
                }
              >
                <Button
                  torol="primary"
                  className="confirm-btn"
                  onClick={() => handleSend(table_type)}
                  disabled={isConfirmDisabled}
                  style={{ marginLeft: 8 }}
                >
                  Хадгалах
                </Button>
              </Tooltip>
            </div>
          )}
        />
      </div>
    </div>
  );
};
const initialData = [
  {
    key: '1',
    category: '1. Эхний үлдэгдэл',
    accountReceivable: 0.0,
    badDebtDeduction: 0.0,
    netAccountReceivable: 0.0,
  },
  {
    key: '2',
    category: '2. Нэмэгдсэн',
    accountReceivable: 0.0,
    badDebtDeduction: 0.0,
    netAccountReceivable: 0.0,
  },
  {
    key: '3',
    category: '3. Хасагдсан',
    accountReceivable: 0.0,
    badDebtDeduction: 0.0,
    netAccountReceivable: 0.0,
  },
  {
    key: '3.1',
    category: '3.1 Төлөгдсөн',
    accountReceivable: 0.0,
    badDebtDeduction: 0.0,
    netAccountReceivable: 0.0,
    sub: true,
  },
  {
    key: '3.2',
    category: '3.2 Найдваргүй болсон',
    accountReceivable: 0.0,
    badDebtDeduction: 0.0,
    netAccountReceivable: 0.0,
    editable: true,
    sub: true,
  },
  {
    key: '4',
    category: '4. Эцсийн үлдэгдэл',
    accountReceivable: 0.0,
    badDebtDeduction: 0.0,
    netAccountReceivable: 0.0,
  },
];

const CombinedTables = ({ description }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState(description);
  const [secondTableData, setSecondTableData] = useState([]);
  const [thirdTableData, setThirdTableData] = useState([]);
  const [tablevalue2, seTtablevalue2] = useState({});
  const [tablevalue3, seTtablevalue3] = useState({});
  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));
  const [dataSource, setDataSource] = useState(initialData);
  const [summarys, seTsummarys] = useState({});
  const [loading, seTloading] = useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const handleSaveTable1 = (key, dataIndex, value) => {
    const newData = dataSource.map((item) =>
      item.key === key ? { ...item, [dataIndex]: value } : item
    );
    setDataSource(newData);
  };

  const handleChangeText = (e) => {
    setText(e.target.value);
  };

  const get_data = (month) => {
    socket.on('request_out', ({ status, content }) => {
      console.log('🚀 ~ socket.on ~ content:', content);
      socket.removeAllListeners('request_out');
      if (status === '0') {
        const {
          text: tailbar,
          first_table,
          second_table,
          third_table,
          table_summary,
          table_2_busad,
          table_3_busad,
        } = content;
        seTsummarys(table_summary);
        setDataSource(first_table);
        setText(tailbar);
        setSecondTableData(second_table);
        setThirdTableData(third_table);
        seTtablevalue3(table_3_busad);
        seTtablevalue2(table_2_busad);
        seTloading(false);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'get_sa04',
        month,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date?.month) get_data(data_date?.month);
  }, [data_date]);

  const columns = [
    {
      title: 'Дансны авлага',
      children: [
        {
          title: 'Үзүүлэлт',
          dataIndex: 'category',
          key: 'category',
          render: (text, record) => (
            <span className={record.sub ? 'sasub-row' : ''}>{text}</span>
          ),
        },
        {
          title: 'Дансны авлага',
          dataIndex: 'accountReceivable',
          key: 'accountReceivable',
          editable: true,
          render: (_, row) => roundFloat(row?.accountReceivable),
        },
        {
          title: 'Найдваргүй авлагын хасагдуулга',
          dataIndex: 'badDebtDeduction',
          key: 'badDebtDeduction',
          editable: true,
          render: (_, row) => roundFloat(row?.badDebtDeduction),
        },
        {
          title: 'Дансны авлага (цэвэр дүнгээр)',
          dataIndex: 'netAccountReceivable',
          key: 'netAccountReceivable',
          render: (_, row) => roundFloat(row?.netAccountReceivable),
        },
      ],
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.children) {
      return col;
    }
    return {
      ...col,
      children: col.children.map((childCol) => {
        const isEditable = (record) => childCol.editable && record.editable;
        return {
          ...childCol,
          render: (text, record) => {
            // Apply roundFloat to both editable and non-editable cells
            const value = roundFloat(record[childCol.dataIndex]);
            return value;
          },
          onCell: (record) => ({
            record,
            editable: isEditable(record),
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave_1: handleSaveTable1,
          }),
        };
      }),
    };
  });
  const EditableCellTable1Sa03 = ({
    title,
    editable,
    dataIndex,
    record,
    handleSave_1,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useRef(null);

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
    };

    const save = async () => {
      try {
        const value = form.current.getFieldValue(dataIndex);
        toggleEdit();
        handleSave_1(record.key, dataIndex, value);
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };

    const renderValue = (value) =>
      typeof value === 'number' ? roundFloat(value) : value;

    const childNode = editable ? (
      <Form ref={form} component={false} onFinish={save}>
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          initialValue={record[dataIndex]}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <InputNumber
            ref={inputRef}
            min={0}
            className="wide-input-number" // Apply the custom class
            onPressEnter={save}
            onBlur={save}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {renderValue(record[dataIndex]) || ' '}
      </div>
    );

    return <td {...restProps}>{childNode}</td>;
  };

  // useEffect(() => {
  //   const isSecondTableFilled = isTableFilled(secondTableData);
  //   const isThirdTableFilled = isTableFilled(thirdTableData);

  //   // If any table is not filled, show an error and return
  //   if (isSecondTableFilled && isThirdTableFilled) {
  //     dispatch(disable_state({}));
  //   }
  // }, [secondTableData, thirdTableData]);

  const handleSend = async (t_type) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: {
          onSuccess: [get_data(data_date.month), dispatch(disable_state({}))],
        },
      });

      dispatch(end_loading({}));
    });
    const content = {
      month: data_date?.month,
      action_type: 'save_sa04',
      table_type: t_type,
      text,
      table_data:
        t_type === 1
          ? dataSource
          : t_type === 2
          ? secondTableData
          : t_type === 3
          ? thirdTableData
          : null,
    };

    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content,
    });
    dispatch(start_loading({}));
  };

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin indicator={antIcon} />;
      </div>
    );
  }

  return (
    <Collapse defaultActiveKey={['1', '2', '3']} expandIconPosition="end">
      <Panel header="4.1 Дансны авлага" key="1">
        <Table
          className="tax-account-table-panel"
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName={(record) => (record.sub ? 'sub-row' : '')}
          bordered
          components={{
            body: {
              cell: EditableCellTable1Sa03,
            },
          }}
          footer={() => (
            <Button
              type="primary"
              className="tax-account-button-unique-mini"
              onClick={() => handleSend(1)}
            >
              Хадгалах
            </Button>
          )}
        />
      </Panel>
      <Panel header="4.2 Татвар, НДШ – ийн авлага" key="2">
        <div className="table-add-row">
          <div className="table-container">
            <AddRowTable
              dataSource={secondTableData}
              setDataSource={setSecondTableData}
              initialSummary={summarys?.table_2}
              table_type={2}
              tablevalue={tablevalue2}
            />
          </div>
        </div>
      </Panel>
      <Panel header="4.3 Бусад богино хугацаат авлага" key="3">
        <div className="table-add-row">
          <div className="table-container">
            <AddRowTable
              dataSource={thirdTableData}
              setDataSource={setThirdTableData}
              initialSummary={summarys?.table_3}
              table_type={3}
              tablevalue={tablevalue3}
            />
          </div>
        </div>
      </Panel>
      <Panel
        header="4.4 Тэмдэглэл: (Дансны авлагыг төлөгдөх хугацаандаа байгаа, хугацаа хэтэрсэн, төлөгдөх найдваргүй гэж ангилна. Найдваргүй авлагын хасагдуулга байгуулсан арга, гадаад валютаар илэрхийлэгдсэн авлагын талаар болон бусад тайлбар, тэмдэглэлийг хийнэ.)"
        key="4"
      >
        <div className="notes-container">
          <table className="tax-account-table-unique">
            <thead>
              <tr>
                <th>Тайлбар</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <textarea
                    value={text}
                    onChange={handleChangeText}
                    rows={10}
                    className="tax-account-textarea-unique"
                    placeholder="Бөглөнө үү"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            type="primary"
            className="tax-account-button-unique-mini"
            onClick={() => handleSend(4)}
          >
            Хадгалах
          </Button>
        </div>
      </Panel>
    </Collapse>
  );
};

export default CombinedTables;
