import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'src/core/utils/formater';
import { Button as AntButton, Row } from 'antd';
import { Table } from 'src/components';
import newAlert from 'src/components/newAlert';
import { EditOutlined } from '@ant-design/icons';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { columns, customSummary } from './dataStructure/data';
import { searchList } from './dataStructure/search';
import EditOZND from './EditOZND';
import EditHA from './EditHA';


const SanhuuShalgahBalance = () => {
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });
  const query = useQuery();
  const [visible, seTvisible] = useState(false);
  const [visible2, seTvisible2] = useState(false);
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        console.log(data?.content);
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const { month } = data_date;
    const search = {
      dans_dugaar: query.get('dans_dugaar') || '',
      turul: query.get('turul')|| '',
    };
    socket.emit('request_in', {
      channel: 'ts001_05_view',
      content: {
        action_type: 'sanhuu_tohirgoo_page',
        month,
        page,
        size,
        search,
      },
    });
    dispatch(start_loading({}));
  };
  const onCancel = () => {
    seTvisible(false);
    select_data();
  };
  const onCancel2 = () => {
    seTvisible2(false);
    select_data();
  };
  const title = () => (
    <Row>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<EditOutlined />}
        onClick={() => {
          seTvisible(true);
        }}
      >
        ОЗНД тохируулга
      </AntButton>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<EditOutlined />}
        onClick={() => {
          seTvisible2(true);
        }}
      >
        ХА тохируулга
      </AntButton>
    </Row>
  );
  useEffect(() => {
    select_data();
  }, [query, data_date]);
  return (
    <>
      <EditOZND visible={visible} onCancel={onCancel} />
      <EditHA visible={visible2} onCancel={onCancel2} />
      <div className="table-antd-div no-scroll">
        <Table
          title={title}
          isSearch
          searchList={searchList}
          dataSource={tableData.result_list}
          columns={columns()}
          scroll={{
            y: 'calc(100vh - 390px)',
          }}
          total={tableData.total_size}
          customize={() => customSummary({ data: tableData.total_list })}
        />
      </div>
    </>
  );
};

export default SanhuuShalgahBalance;
