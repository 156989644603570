import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Form, Row } from 'antd';
import { Table } from 'src/components';
import newAlert from 'src/components/newAlert';
import { DahinAllColumns } from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';

const { Search } = Input;

export const DahinUnelgeeAll = ({ dahinAllVisible }) => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);

  const [form] = Form.useForm();

  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });
  const [pagenationData, seTpagenationData] = useState({
    page: 1,
    size: 20,
    burtgel: '',
    hurungu: '',
  });

  const onSearch = (values) => {
    if (values) {
      seTpagenationData({
        ...pagenationData,
        burtgel: values,
        hurungu: form.getFieldValue('search'),
      });
    } else {
      seTpagenationData({
        ...pagenationData,
        burtgel: '',
        hurungu: form.getFieldValue('search'),
      });
    }
  };

  const title = () => (
    <Row style={{ width: '500px' }}>
      <Form.Item name="search" style={{ margin: 0, marginRight: 10 }}>
        <Input
          width={200}
          size="small"
          allowClear
          placeholder="Хөрөнгийн код..."
        />
      </Form.Item>
      <Form.Item name="search2" style={{ margin: 0 }}>
        <Search
          width={200}
          size="small"
          allowClear
          placeholder="Бүртгэлийн дугаар..."
          onSearch={onSearch}
        />
      </Form.Item>
    </Row>
  );

  const select_data = () => {
    socket.on('request_out', (burtgel_data) => {
      socket.removeAllListeners('request_out');
      if (burtgel_data.status === '0') {
        setTableData(burtgel_data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const { page, size, burtgel, hurungu } = pagenationData;
    const month = '13';
    const search = { burtgel, hurungu };
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'dahin_unelgee_burtgel_page',
        page,
        size,
        search,
        month,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (!dahinAllVisible) {
      form.resetFields();
      seTpagenationData({
        ...pagenationData,
        page: 1,
        burtgel: '',
        hurungu: '',
      });
    } else {
      select_data();
    }
  }, [dahinAllVisible]);

  useEffect(() => {
    if (dahinAllVisible) {
      select_data();
    }
  }, [pagenationData]);

  const getParm = (values) => {
    if (
      // eslint-disable-next-line operator-linebreak
      values.page !== pagenationData.page ||
      values.size !== pagenationData.size
    ) {
      seTpagenationData({
        ...pagenationData,
        page: values.page,
        size: values.size,
      });
    }
  };

  return (
    <div className="inside-table no-scroll">
      <Form form={form} name="basic" className="register-form" size="small">
        <Table
          title={title}
          columns={DahinAllColumns()}
          dataSource={tableData.result_list}
          scroll={{
            y: 'calc(100vh - 280px)',
          }}
          subTotal={tableData.total_size}
          getParam={getParm}
        />
      </Form>
    </div>
  );
};
