import React from 'react';

export const sampleData = [
  {
    window: 'Харилцагчийн үндсэн мэдээлэл',
    action: '"01 - Харилцагч 1" харилцагчийн эхний үлдэгдэл нэмсэн',
    user: 'Хэрэглэгч1',
    date: '2019-12-16',
  },
  {
    window: 'Дансны үндсэн мэдээлэл',
    action: '"120000-Авлага" дансны үндсэн мэдээлэл зассан',
    user: 'Хэрэглэгч2',
    date: '2019-12-15',
  },
  {
    window: 'Үндсэн хөрөнгийн үндсэн мэдээлэл',
    action: 'Бүртгэлийн дугаартай үндсэн хөрөнгийн мэдээлэл зассан',
    user: 'Хэрэглэгч3',
    date: '2019-12-14',
  },
  {
    window: 'Татварын суурь-Харилцагч',
    action: 'Харилцагчдын татварын суурь тооцоолсон',
    user: 'Хэрэглэгч4',
    date: '2019-12-13',
  },
];

export const columns = [
  {
    dataIndex: 'key',
    title: '',
    align: 'center',
    width: 50,
  },
  {
    dataIndex: 'time',
    title: 'Хугацаа',
    align: 'center',
    width: 100,
    render: (_, row) =>
      (<>{row.date} {row.time}</>),
  },
  {
    dataIndex: 'service_name',
    title: 'Сервис',
    align: 'center',
    width: 50,
  },
  {
    dataIndex: 'user_name',
    title: 'Хэрэглэгч',
    align: 'center',
    width: 100,
  },
  {
    dataIndex: 'messege',
    title: 'Үйлдэл',
    align: 'center',
    width: 300,
  },

];
