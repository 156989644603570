import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table as AntTable, Button } from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';
import newAlert from 'src/components/newAlert';
import { roundFloat, useQuery } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import Table from 'src/components/common/table';
import { dans_columns } from '../dataStructure/data';

const TabDans = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const [tableData, setTableData] = useState([]);
  const [avlagaSum, setAvlagaSum] = useState({});
  const [uglugSum, setUglugSum] = useState({});
  const [turZuruuList, setTurZuruuList] = useState([]);

  const handleSelectDans = (dans_code, tur_zuruu_turul) => {
    const object = { dans_code, tur_zuruu_turul, tab: 2 };
    const proplist = ['Hariltsagch', 'page2', 'size2', 'page3', 'size3'];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(object)) {
      query[query.get(key) ? 'set' : 'append'](key, value);
    }
    proplist.forEach((name) => {
      if (query.get(name)) {
        query.delete(name);
      }
    });
    history.push({ search: query.toString() });
  };

  const handleCalculateTC = () => {
    const dans_list = turZuruuList
      .filter((i) => i.tur_zuruu_turul === 'Гадаад валютын ханшийн зөрүү')
      .map((d) => d.dans_dugaar);
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0' || data.status === '0' ) {
        
        // setTableData(data.content.json_array);
        // setAvlagaSum(data.content.avlaga_sum);
        // setUglugSum(data.content.uglug_sum);
        newAlert({
          type: 'success',
          msg: 'Татварын суурь амжилттай тооцоологдлоо',
        });
        if (query.get('tab')) {
          if (query.get('tab') === '1') {
            fetchData();
          }
        } else {
          fetchData();
        }
      } else if (data.content.status === '2') {
        newAlert({ type: 'warning', msg: data.content.msg });
      } else {
        newAlert({ type: 'error', msg: data.content.msg });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'calculate_tc',
      content: {
        action_type: 'eh_uld_calculate_tc',
        month: '13',
        dans_list,
        page: query.get('page') || 1,
        size: query.get('size') || 20,
      },
    });

    dispatch(start_loading({}));
  };

  const fetchData = () => {
    if (turZuruuList.length > 0) {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');

        if (data.content.status === '0') {
          setAvlagaSum(data.content.avlaga_sum);
          setUglugSum(data.content.uglug_sum);

          const merged = [];
          const result_array = data.content.json_array;
          for (let i = 0; i < result_array.length; i += 1) {
            merged.push({
              ...result_array[i],
              ...turZuruuList.find(
                (x) => x.dans_dugaar === result_array[i].dans_code
              ),
            });
          }
          setTableData(merged);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }
        dispatch(end_loading({}));
      });

      socket.emit('request_in', {
        channel: 'ts002_00_tatvar_suuri',
        content: {
          month: data_date.month,
          action_type: 'ehnii_uldegdel_dansaar',
          page: query.get('page') || 1,
          size: query.get('size') || 20,
        },
      });
      dispatch(start_loading({}));
    }
  };

  const fetch_TC_diff_type = async () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data?.status === '0') {
        setTurZuruuList(data.content.json_array);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });

    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_turul',
      content: {
        action_type: 'get_list',
        data_type: '0',
      },
    });
  };

  useEffect(() => {
    if (query.get('tab')) {
      if (query.get('tab') === '1') {
        fetchData();
      }
    } else {
      fetchData();
    }
  }, [query, turZuruuList]);

  useEffect(() => {
    fetch_TC_diff_type();
  }, []);

  const showSum = () => (
    <AntTable.Summary fixed>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell />
        <AntTable.Summary.Cell align="right">
          Авлагын нийт дүн:
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell />
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(avlagaSum?.ets_uld)}</p>
          <p className="bg-highlight">
            {roundFloat(avlagaSum?.ets_uld_valiut)}
          </p>
        </AntTable.Summary.Cell>
      </AntTable.Summary.Row>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell />
        <AntTable.Summary.Cell align="right">
          Өглөгийн нийт дүн:
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell />
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(uglugSum?.ets_uld)}</p>
          <p className="bg-highlight">{roundFloat(uglugSum?.ets_uld_valiut)}</p>
        </AntTable.Summary.Cell>
      </AntTable.Summary.Row>
    </AntTable.Summary>
  );

  return (
    <div className="table-wrapper">
      <div className="inside-table">
        <Table
          columns={dans_columns({ handleSelectDans })}
          dataSource={tableData}
          scroll={{
            y: 'calc(100vh - 370px)',
          }}
          title={() => (
            <Button
              className="add-btn"
              type="primary"
              size="small"
              icon={<CalculatorOutlined />}
              onClick={handleCalculateTC}
            >
              Татварын суурь тооцоолох
            </Button>
          )}
          customize={showSum}
        />
      </div>
    </div>
  );
};
export default TabDans;
