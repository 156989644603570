import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import newAlert from 'src/components/newAlert';
import { FileError } from 'src/components/common/file_error';
import axios from 'src/core/utils/axios';

import {
  Button as AntButton,
  Form,
  Select,
  Row,
  Col,
  Upload,
  message,
} from 'antd';

import { CloudUploadOutlined } from '@ant-design/icons';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { typesList, layout, sourceList, fileList } from './data';

const { Option } = Select;

const RegisterFirstModal = ({ onClose = () => {}, setClients = () => {} }) => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);

  const [form] = Form.useForm();

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const registerBalance = (balance_list) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0') {
        newAlert({
          type: 'success',
          msg: 'Харилцагчийн эхний үлдэгдэл амжилттай хадгалагдлаа',
        });
        setClients(data.content.json_array);
        onCancel();
      } else {
        newAlert({ type: 'error', msg: data.content.msg });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts002_00_eh_uld_register',
      content: {
        action_type: 'register_avlaga_uglug_tovchoo',
        version: version.version_number,
        year: version.year,
        data_type: form.getFieldValue('file_type').toString(),
        array_data: balance_list,
      },
    });
    dispatch(start_loading({}));
  };

  const validateDans = (list_data) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      const dans_data = data.content.json_array;
      const avlaga_uglug_journal = dans_data.filter(
        (x) => x.tuslah_journal_id === 1
      );
      const sums = [
        ...list_data
          .reduce((map, item) => {
            // eslint-disable-next-line no-param-reassign
            item.ets_uld = parseFloat(item.ets_uld || 0);
            const { dans_code: key, ets_uld } = item;
            const prev = map.get(key);

            if (prev) {
              prev.ets_uld += parseFloat(ets_uld);
            } else {
              map.set(key, { ...item });
            }

            return map;
          }, new Map())
          .values(),
      ];
      const angilagdsan_dans = sums.filter(
        (o1) =>
          avlaga_uglug_journal.some(
            (o2) => o1.dans_code?.toString() === o2.dans_dugaar?.toString()
          )
        // eslint-disable-next-line function-paren-newline
      );

      const filtered_dans_list = avlaga_uglug_journal.filter(
        (o1) =>
          sums.some(
            (o2) => o1.dans_dugaar?.toString() === o2.dans_code?.toString()
          )
        // eslint-disable-next-line function-paren-newline
      );

      const diff_arrays = angilagdsan_dans.filter(
        (o1) =>
          !filtered_dans_list.some(
            (o2) =>
              // eslint-disable-next-line operator-linebreak
              o1.dans_code === o2.dans_dugaar &&
              // eslint-disable-next-line operator-linebreak
              (parseFloat(o2?.dans_dun_cred)?.toFixed(2).toString() ===
                // eslint-disable-next-line operator-linebreak
                o1?.ets_uld?.toFixed(2).toString() ||
                // eslint-disable-next-line operator-linebreak
                parseFloat(o2?.dans_dun_deb)?.toFixed(2).toString() ===
                  o1?.ets_uld?.toFixed(2).toString())
          )
      );
      if (diff_arrays.length > 0) {
        newAlert({
          type: 'warning',
          msg: `${diff_arrays.map(
            (x) => x.dans_code
          )} данснуудын үнэ дансны эхний үлдэгдэлтэй таарахгүй байна`,
        });
      } else {
        const angilagdsan_dun = list_data.filter(
          (o1) =>
            angilagdsan_dans.some(
              (o2) => o1.dans_code?.toString() === o2.dans_code?.toString()
            )
          // eslint-disable-next-line function-paren-newline
        );
        registerBalance(angilagdsan_dun);
      }

      dispatch(end_loading({}));
    });

    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: {
        action_type: 'get_dans_dun_list',
        month: 'ets',
        first_year: true,
      },
    });
  };

  const handleUpload = async (selectedFile) => {
    if (selectedFile) {
      dispatch(start_loading({}));
      const { size: file_size } = selectedFile.file[0];
      const { file_source, file_type } = selectedFile;
      const formData = new FormData();
      formData.append('file', selectedFile.file[0].originFileObj);
      const result = await axios.post('first', `/${file_type}`, formData, {
        file_size,
        file_source,
      });
      if (result?.status === '0') {
        validateDans(result?.content?.json_array);
      } else if (result?.content?.type === 'content') {
        FileError({
          text: result?.content?.message,
        });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: result?.content?.message });
        dispatch(end_loading({}));
      }
    }
  };

  const onFinish = (values) => {
    handleUpload(values);
  };

  return (
    <>
      <div style={{ fontStyle: 'italic' }} className="ml-10 mb-10">
        Авлага, өглөгийн товчоо тайлан оруулна уу!
      </div>

      <div className="hariltsagch-medeelel">
        <Form
          form={form}
          onFinish={onFinish}
          className="register-form"
          {...layout}
          size="small"
        >
          <Form.Item
            name="file_type"
            label="Файлын төрөл"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: 'Файлын төрөл сонгоно уу!',
              },
            ]}
          >
            <Select placeholder="Сонгох">
              {fileList.map((x) => (
                <Option value={x.id} key={x.id}>
                  {x.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="file_source"
            label="Эх сурвалж"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: 'Эх сурвалж сонгоно уу!',
              },
              { min: 0, max: 100 },
            ]}
          >
            <Select placeholder="Сонгох">
              {sourceList.map((x) => (
                <Option value={x.id} key={x.id}>
                  {x.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="file"
            label="Файл"
            labelAlign="left"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: 'Файл сонгоно уу' }]}
          >
            <Upload
              accept={typesList.join()}
              showUploadList
              maxCount={1}
              beforeUpload={(File) => {
                const isXls = typesList.find((i) => i === File.type);
                if (!isXls) {
                  message.error('Зөвхөн xls, xlsx өргөтгөлтэй файл оруулна уу');
                  return Upload.LIST_IGNORE;
                }
                return false;
              }}
            >
              <CloudUploadOutlined className="fs-30" />
            </Upload>
          </Form.Item>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <AntButton
                  onClick={onCancel}
                  className="cancel-btn mr-5"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </AntButton>
                <AntButton
                  className="confirm-btn"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </AntButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
export default RegisterFirstModal;
