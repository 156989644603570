import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Modal, Row, Col, Form, Typography } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Table } from 'src/components';
import newAlert from 'src/components/newAlert';
import { hanshColumns, hanshSummary } from './dataStructure/data';
import { roundFloat } from '../../../core/utils/formater';
import { start_loading, end_loading } from '../../../core/actions/loader';

import TuuhenHanshRegister from './tuuhenHanshRegister';
import TuuhenHanshDelete from './tuuhenHanshDelete';

const TuuhenHanshModal = ({ initialValues = null, onClose = () => {} }) => {
  const dispatch = useDispatch();

  const [addVisible, setAddVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [hanshList, setHanshList] = useState({});

  const [deleteForm] = Form.useForm();
  const [addForm] = Form.useForm();

  const socket = useSelector((state) => state.socket);

  const fetchTableData = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setHanshList(data?.content);
      } else {
        setHanshList({});
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_04_mh_controller',
      content: {
        action_type: 'get_tuuhen_hansh_list',
        dans_id: initialValues?.dans_id,
        first_year: true,
      },
    });

    dispatch(start_loading({}));
  };

  const handleAddRow = (item_data) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        fetchTableData();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_04_mh_controller',
      content: {
        action_type: 'add_tuuhen_hansh',
        dans_id: initialValues?.dans_id,
        valiut: initialValues?.dans_valiut,
        data: item_data,
        first_year: true,
      },
    });
    dispatch(start_loading({}));
  };

  const deleteValue = (formData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай устгагдлаа' });
        fetchTableData();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_04_mh_controller',
      content: {
        action_type: 'delete_tuuhen_hansh',
        tuuhen_hansh_id: formData?.tuuhen_hansh_id,
        first_year: true,
      },
    });
    dispatch(start_loading({}));
  };

  const handleDeleteRow = (tuuhen_hansh_id) => {
    deleteForm.setFieldsValue({
      tuuhen_hansh_id,
    });

    setDeleteVisible(true);
  };

  useEffect(() => {
    if (initialValues?.dans_id !== '') {
      fetchTableData();
    }
  }, [initialValues]);

  const title = () => (
    <>
      <div className="table-title-text">
        <Typography level={5}>
          Эхний үлдэгдэл дүн /валют/ :{' '}
          {roundFloat(initialValues.dansnii_une_valiut)}
        </Typography>
      </div>
      <Button
        className="add-btn"
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => setAddVisible(true)}
        size="small"
      >
        Түүхэн ханш бүртгэх
      </Button>
    </>
  );

  return (
    <>
      <div className="table-antd-div">
        <Table
          columns={hanshColumns({
            customDelete: handleDeleteRow,
          })}
          dataSource={hanshList?.json_array}
          scroll={{ y: 200 }}
          summaryList={{
            sumColumn: hanshSummary,
            sumDataSource: hanshList?.sum_data,
          }}
          title={title}
        />
      </div>
      <Form className="register-form" size="small">
        <Row>
          <Col span={24} className="text-align-r">
            <Button
              onClick={onClose}
              className="cancel-btn"
              type="primary"
              shape="round"
            >
              Буцах
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        className="custom-modal"
        title="Түүхэн ханш бүртгэх"
        visible={addVisible}
        closable={false}
        maskClosable={false}
        width={400}
        footer={null}
      >
        <TuuhenHanshRegister
          onClose={() => {
            setAddVisible(false);
          }}
          form={addForm}
          valiut={initialValues?.dans_valiut}
          handleAddRow={handleAddRow}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Түүхэн ханш устгах"
        visible={deleteVisible}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <TuuhenHanshDelete
          onClose={() => {
            setDeleteVisible(false);
          }}
          form={deleteForm}
          deleteValue={deleteValue}
        />
      </Modal>
    </>
  );
};

export default TuuhenHanshModal;
