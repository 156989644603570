import React, { useState, useEffect } from 'react';
import { BellOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, List, Avatar, Badge, Dropdown } from 'antd';
import { useSelector } from 'react-redux';

const NotificationComponent = () => {
  const [notifications, setNotifications] = useState(
    JSON.parse(localStorage.getItem('notifications')) || []
  );
  const { notification_count } = useSelector((state) => ({
    notification_count: state.notification_count,
  }));
  const [unreadCount, seTunreadCount] = useState(notifications.length || 0);

  const clear_all = () => {
    localStorage.setItem('notifications', JSON.stringify([]));
    setNotifications([]);
    seTunreadCount(0);
  };
  useEffect(() => {
    setNotifications(JSON.parse(localStorage.getItem('notifications')) || []);
    setTimeout(() => {
      seTunreadCount(
        JSON.parse(localStorage.getItem('notifications')).length || 0
      );
    }, 2000);
  }, [notification_count]);

  const markAsRead = (id) => {
    const updatedNotifications = notifications.filter(
      (notification) => notification.id !== id
    );
    setNotifications(updatedNotifications);
    seTunreadCount(unreadCount - 1);
    localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
  };

  return (
    <Dropdown
      overlay={
        <div className="notification-dropdown">
          <List
            className="notification-list"
            itemLayout="horizontal"
            dataSource={notifications}
            renderItem={(item) => (
              <List.Item className="notification-item">
                <List.Item.Meta
                  avatar={<Avatar src="https://via.placeholder.com/40" />}
                  title={item.title}
                  description={item.description}
                />
                {!item.read && (
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => markAsRead(item.id)}
                  />
                )}
              </List.Item>
            )}
            footer={
              <div className="clear-all-button">
                <Button type="text" onClick={() => clear_all()}>
                  Clear All
                </Button>
              </div>
            }
          />
        </div>
      }
    >
      <Badge count={unreadCount}>
        <Button
          shape="circle"
          icon={<BellOutlined style={{ color: '#0094bc' }} />}
        />
      </Badge>
    </Dropdown>
  );
};

export default NotificationComponent;
