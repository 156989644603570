import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EditOutlined, MenuOutlined, LoadingOutlined } from '@ant-design/icons';
import Table from 'src/components/common/table';
import CanView from 'src/components/permission/canview';
import {
  Typography,
  Button,
  Modal,
  message,
  Form,
  Popover,
  Checkbox,
  Row,
  Col,
  Spin,
} from 'antd';
import { remove } from 'lodash';
import ProCard from '@ant-design/pro-card';
import RoleCreate from './add';

const { Title } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const IconTitle = (text, userList = []) => (
  <>
    {text}
    <Popover
      className="custom-popover"
      // content={() => userChange(roleId, row_data?.user_id)}
      content={() => (
        <div className="m-5">
          <Row className="fw-600 fs-12">
            <Col span={12}>Овог</Col>
            <Col span={12}>Нэр</Col>
          </Row>
          {userList.length > 0
            ? userList.map((i) => (
                <Row
                  style={{
                    maxWidth: 250,
                  }}
                  className="fs-12"
                >
                  <Col className="ellipsis-col" span={12}>
                    {i.firstName}
                  </Col>
                  <Col span={12} className="ellipsis-col">
                    {i.lastName}
                  </Col>
                </Row>
              ))
            : 'Хэрэглэгч алга'}
        </div>
      )}
      title="Хэрэглэгчид"
      trigger="click"
    >
      <MenuOutlined style={{ marginLeft: 10 }} />
    </Popover>
  </>
);

const _column = [
  {
    title: () => (
      <div className="box">
        <div className="clipped">
          <span
            style={{
              position: 'absolute',
              left: '70%',
            }}
          >
            Эрхийн төрөл
          </span>
        </div>
        <span
          style={{
            position: 'absolute',
            left: '10%',
            top: '30%',
          }}
        >
          Үйлдлүүд
        </span>
      </div>
    ),
    dataIndex: 'permissionName',
    key: 'permissionName',
    width: 400,
  },
];

const RoleAdd = () => {
  const [form] = Form.useForm();
  const socket = useSelector((state) => state.socket);
  const role = useSelector((state) => state.role);
  const myRole = useSelector((state) => state.user_role);
  const myRoleId = myRole?.default_role_id;
  const myRoleList = role.map((i) => i.code);
  const [visible, seTvisible] = useState(false);
  const [permissionList, seTpermissionList] = useState([]);

  const [loading, seTloading] = useState(true);
  const [onEdit, seTonEdit] = useState(false);

  const [roleData, setRoleData] = useState([]);
  const fetchData = () => {
    socket.on('request_out', (data) => {
      const { content } = data;
      socket.removeAllListeners('request_out');
      setRoleData(content?.role_list);
      seTpermissionList(content?.permission_list);

      seTloading(false);
    });
    socket.emit('request_in', {
      channel: 'ss006_manage_permission',
      content: {
        action_type: 'get_permission_list',
      },
    });
  };

  const permissionCodeMaker = (list = [], name, code) => {
    let isHave = false;
    let codes = [];
    const finded = list.find((i) => i.RoleName === name);
    if (finded) {
      const { permissionCodes = [] } = finded;
      // if (permissionCodes.length === 0) return;
      isHave = permissionCodes.includes(code);
      if (isHave) {
        const newArray = remove(permissionCodes, (n) => n !== code);
        codes = newArray;
      } else {
        permissionCodes.push(code);
        codes = permissionCodes;
      }
    }
    return list.map((i) => {
      if (i.RoleName === name) {
        return {
          ...i,
          RoleName: name,
          permissionCodes: codes,
        };
      }
      return i;
    });
  };
  const editCheck = (name, code) => {
    setRoleData((prev) => permissionCodeMaker(prev, name, code));
  };

  useEffect(() => {
    /// initial data
    fetchData();
  }, []);
  useEffect(() => {
    // reRender
    seTloading(false);
  }, [onEdit]);

  const generateColumn = (list = []) => {
    const arr = _column;
    const _arr = list.map((i) => {
      const { RoleName, permissionCodes, userList = [], role_lvl } = i;
      return {
        title: IconTitle(RoleName, userList),
        align: 'center',
        width: 150,
        permissionCodes,
        render: (_, row, index) => {
          const { code } = row;
          const isChecked = permissionCodes.includes(code);
          if (onEdit) {
            if (role_lvl <= myRoleId || !myRoleList.includes(code)) {
              return <Checkbox checked={isChecked} disabled />;
            }
            return (
              <Checkbox
                defaultChecked={isChecked}
                onClick={() => {
                  editCheck(RoleName, row?.code, row?.role_id);
                }}
              />
            );
          }
          if (isChecked) {
            return <Checkbox checked={isChecked} />;
          }
          return null;
        },
      };
    });
    const _list = arr.concat(_arr);
    return _list;
    // seTdinamicColumn(_list);
  };

  const permissionEdit = () => {
    seTloading(true);
    seTonEdit(!onEdit);
  };

  const submit = () => {
    socket.on('request_out', (data) => {
      const { content, status } = data;
      socket.removeAllListeners('request_out');
      if (status === '0') {
        message.success(content.Message);
        permissionEdit();
        seTloading(false);
      } else {
        message.error(content.Message);
      }
    });
    socket.emit('request_in', {
      channel: 'ss006_manage_permission',
      content: {
        action_type: 'update_role_permission',
        role_data: roleData,
      },
    });
  };

  const title = () => {
    if (!onEdit) {
      return (
        <CanView allowedRole="016_1">
          <Button
            className="add-btn"
            type="primary"
            size="small"
            icon={<EditOutlined />}
            onClick={permissionEdit}
          >
            Эрхийн тохиргоо өөрчлөх
          </Button>
        </CanView>
      );
    }
    return null;
  };

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin indicator={antIcon} />;
      </div>
    );
  }
  return (
    <div className="role-add-container">
      <ProCard
        className="role-header-card"
        split="vertical"
        title={<Title level={2}>Эрхийн тохиргоо</Title>}
      >
        <div className="inside-container">
          <Form form={form} component={false}>
            {roleData.length > 0 && (
              <Table
                columns={generateColumn(roleData)}
                dataSource={permissionList}
                title={title}
                scroll={{
                  x: 500,
                }}
              />
            )}
            {onEdit && (
              <Row
                style={{
                  marginTop: 10,
                }}
              >
                <Col span={24} style={{ textAlign: 'right' }}>
                  <Form.Item noStyle>
                    <Button
                      className="cancel-btn"
                      type="primary"
                      size="small"
                      onClick={permissionEdit}
                    >
                      Буцах
                    </Button>
                    <Button
                      className="confirm-btn ml-5"
                      type="primary"
                      size="small"
                      onClick={submit}
                    >
                      Хадгалах
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
        </div>
      </ProCard>
      <Modal
        className="custom-modal"
        title="Эрх үүсгэх"
        visible={visible}
        maskClosable={false}
        width={710}
        onCancel={() => {
          form.setFieldsValue({ roleId: '' });
          seTvisible(false);
        }}
        footer={null}
      >
        <RoleCreate
          form={form}
          socket={socket}
          toggle={visible}
          list={permissionList}
          reRender={fetchData}
          onClose={() => {
            form.setFieldsValue({ roleId: '' });
            seTvisible(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default RoleAdd;
