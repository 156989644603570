import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { Table as AntTable, Button as AntButton } from 'antd';

export const customDansSummary = ({ data = null }) => (
  <AntTable.Summary fixed>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right">Нийт дүн:</AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.eh_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.eh_urtug)}</p>
        <p>{roundFloat(data?.eh_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.deb_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.deb_urtug)}</p>
        <p>{roundFloat(data?.deb_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.cred_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.cred_urtug)}</p>
        <p>{roundFloat(data?.cred_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.ets_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.ets_urtug)}</p>
        <p>{roundFloat(data?.ets_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
  </AntTable.Summary>
);

export const customSummary = ({ data = null }) => (
  <AntTable.Summary fixed>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell />
      <AntTable.Summary.Cell />
      <AntTable.Summary.Cell align="right">Нийт дүн:</AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.eh_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.eh_urtug)}</p>
        <p>{roundFloat(data?.eh_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.deb_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.deb_urtug)}</p>
        <p>{roundFloat(data?.deb_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.cred_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.cred_urtug)}</p>
        <p>{roundFloat(data?.cred_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.ets_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.ets_urtug)}</p>
        <p>{roundFloat(data?.ets_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
  </AntTable.Summary>
);

export const ColumnsDansMain = () => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Эхний үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'eh_too_shirheg',
        align: 'right',
        width: 70,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'eh_urtug',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.eh_urtug)}</p>
            <p>{roundFloat(row?.eh_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Дебет',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'deb_too_shirheg',
        align: 'right',
        width: 70,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'deb_urtug',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.deb_urtug)}</p>
            <p>{roundFloat(row?.deb_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Кредит',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'cred_too_shirheg',
        align: 'right',
        width: 70,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'cred_urtug',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.cred_urtug)}</p>
            <p>{roundFloat(row?.cred_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Эцсийн үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'ets_too_shirheg',
        align: 'right',
        width: 70,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'ets_urtug',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.ets_urtug)}</p>
            <p>{roundFloat(row?.ets_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
];

export const ColumnsMain = ({ onSelectHurungu = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    align: 'left',
    width: 120,
    fixed: 'left',
    render: (_, row) => (
      <AntButton
        align="center"
        type="link"
        size="small"
        className="underline"
        onClick={() => onSelectHurungu(row)}
      >
        {row?.code}
      </AntButton>
    ),
  },
  {
    title: 'Хөрөнгийн нэр',
    dataIndex: 'ner',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Эхний үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'eh_too_shirheg',
        width: 70,
        align: 'right',
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'eh_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.eh_urtug)}</p>
            <p>{roundFloat(row?.eh_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Дебет',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'deb_too_shirheg',
        width: 70,
        align: 'right',
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'deb_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.deb_urtug)}</p>
            <p>{roundFloat(row?.deb_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Кредит',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'cred_too_shirheg',
        width: 70,
        align: 'right',
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'cred_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.cred_urtug)}</p>
            <p>{roundFloat(row?.cred_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Эцсийн үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'ets_too_shirheg',
        width: 70,
        align: 'right',
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'ets_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.ets_urtug)}</p>
            <p>{roundFloat(row?.ets_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
];

export const ColumnsBurtgel = () => [
  {
    title: 'Бүртгэлийн дугаар',
    dataIndex: 'burtgel_dugaar',
    width: 150,
    fixed: 'left',
  },
  {
    title: 'Эхний үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'eh_too_shirheg',
        width: 70,
        align: 'right',
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'eh_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.eh_urtug)}</p>
            <p>{roundFloat(row?.eh_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Дебет ',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'deb_too_shirheg',
        width: 70,
        align: 'right',
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'deb_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.deb_urtug)}</p>
            <p>{roundFloat(row?.deb_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Кредит',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'cred_too_shirheg',
        width: 70,
        align: 'right',
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'cred_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.cred_urtug)}</p>
            <p>{roundFloat(row?.cred_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Эцсийн үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'ets_too_shirheg',
        width: 70,
        align: 'right',
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'ets_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.ets_urtug)}</p>
            <p>{roundFloat(row?.ets_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
];
