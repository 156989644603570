import React, { useEffect } from 'react';
import { Button, Form, Row, Col, Input } from 'antd';
import { roundFloat, onlyNumber } from 'src/core/utils/formater';

const UnrecognizeDiffModal = ({
  initialValues,
  onCancel = () => {},
  handleSave = () => {},
}) => {
  const [form] = Form.useForm();

  const onFinish = () => {
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        const json_data = { ...initialValues };
        json_data.hz_zuruu_amount = form
          .getFieldValue('hz_zuruu_amount')
          .toString()
          .replace(/,/g, '');
        resolve(json_data);
      }, 100);
    });
    promise.then((json_data) => {
      form.resetFields();
      onCancel();
      handleSave(json_data);
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      hz_zuruu_amount: roundFloat(initialValues.hz_zuruu_amount),
    });
  }, [initialValues]);

  const layout = {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 16 },
    },
  };

  return (
    <div className="hariltsagch-medeelel">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="dans_code" label="Данс:" labelAlign="left">
          <span className="ant-form-text">{initialValues.dans_code}</span>
        </Form.Item>
        <Form.Item name="hariltsagch_code" label="Харилцагч:" labelAlign="left">
          <span className="ant-form-text">{`${initialValues.hariltsagch_code} - ${initialValues.hariltsagch_ner}`}</span>
        </Form.Item>
        <Form.Item
          name="hz_zuruu_amount"
          label={
            initialValues.data_type === 'hasagdah_tur_zuruu'
              ? 'Хасагдах түр зөрүү:'
              : 'Татвар ногдох түр зөрүү:'
          }
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input />
        </Form.Item>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn mr-5"
                type="primary"
                shape="round"
              >
                Буцах
              </Button>
              <Button
                className="confirm-btn"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UnrecognizeDiffModal;
