import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Modal, Typography } from 'antd';
import newAlert from 'src/components/newAlert';
import { useSelector, useDispatch } from 'react-redux';
import { start_loading, end_loading } from 'src/core/actions/loader';

const { Paragraph } = Typography;

function isNowGreaterThan24Hours(token) {
  const dateTimeString = token.slice(0, 10); // Extracts "2023111613" (up to hours)
  const originalYear = parseInt(dateTimeString.substring(0, 4), 10);
  const originalMonth = parseInt(dateTimeString.substring(4, 6), 10) - 1;
  const originalDay = parseInt(dateTimeString.substring(6, 8), 10);
  const originalHour = parseInt(dateTimeString.substring(8, 10), 10);

  const originalDate = new Date(
    originalYear,
    originalMonth,
    originalDay,
    originalHour
  );

  const newDate = new Date(originalDate.getTime() + 24 * 60 * 60 * 1000);

  const now = new Date();
  const currentHourDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours()
  );
  return currentHourDate < newDate;
}

export const TokenView = ({ visible = true, onCancel = () => {} }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const socket = useSelector((state) => state.socket);
  const [token, seTtoken] = useState('');
  const [canUpdate, seTcanUpdate] = useState(false);

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        seTtoken(data?.content.result_json.noat_token);
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss013_03_view',
      content: {
        action_type: 'get_noat_token',
      },
    });
    dispatch(start_loading({}));
  };

  const generateToken = async (company_data) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data?.status === '0') {
        select_data();
      } else {
        newAlert({ type: 'error', msg: data.message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss013_03_modify',
      content: { action_type: 'generate_token', company_data },
    });
    dispatch(start_loading({}));
  };

  const mainData = async () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        generateToken({
          company_register_num: content?.company_data.company_register_num,
          tatvar_tologch_dugaar: content?.company_data.tatvar_tologch_dugaar,
        });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss005_company_manage',
      content: { action_type: 'get_company_data' },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (visible) {
      select_data();
    }
  }, [visible]);

  useEffect(() => {
    if (token) {
      seTcanUpdate(isNowGreaterThan24Hours(token));
    }
  }, [token]);

  return (
    <Modal
      className="custom-modal"
      title="Токен үүсгэх"
      open={visible}
      width={350}
      footer={null}
      // closable
      onCancel={onCancel}
    >
      <Form form={form} className="register-form" size="small">
        <Row justify="space-between">
          <Paragraph copyable={!!token}>
            {token || 'Токен үүсээгүй байна'}
          </Paragraph>
          <Button
            className="confirm-btn ml-5"
            type="primary"
            shape="round"
            htmlType="submit"
            onClick={mainData}
            disabled={canUpdate}
          >
            Шинэчлэх
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
