import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

export const customColumns = ({
  customDelete = () => {},
  customEdit = () => {},
}) => [
  {
    title: 'Үндсэн данс',
    children: [
      {
        title: 'Дугаар',
        dataIndex: 'dans_dugaar',
        align: 'left',
        width: 250,
      },
      {
        title: 'Нэр',
        dataIndex: 'dans_ner',
        align: 'left',
        width: 300,
        ellipsis: true,
      },
    ],
  },
  {
    title: 'Хуримтлагдсан элэгдлийн данс',
    children: [
      {
        title: 'Дугаар',
        dataIndex: 'dans_dugaar_elegdel',
        align: 'left',
        width: 250,
      },
      {
        title: 'Нэр',
        dataIndex: 'dans_ner_elegdel',
        align: 'left',
        width: 300,
        ellipsis: true,
      },
    ],
  },
  {
    title: 'Бүртгэл задлах',
    dataIndex: 'burtgel_zadlah_val',
    align: 'left',
    width: 70,
  },
  {
    title: 'Зөрүү тооцох',
    dataIndex: 'zuruu_tootsoh_val',
    align: 'left',
    width: 70,
  },
  {
    title: 'Хөрөнгийн төрөл',
    dataIndex: 'hurungu_turul_val',
    align: 'left',
    width: 80,
  },
  {
    title: 'Хөрөнгийн бүлэг',
    dataIndex: 'hurungu_buleg_val',
    align: 'left',
    width: 150,
    ellipsis: true,
  },
  {
    title: 'Засах',
    align: 'center',
    width: 50,
    fixed: 'right',
    render: (_, row) => (
      <div>
        <EditOutlined onClick={() => customEdit(row)} />
      </div>
    ),
  },
  {
    title: 'Устгах',
    align: 'center',
    width: 50,
    fixed: 'right',
    render: (_, row) => (
      <div>
        <DeleteOutlined onClick={() => customDelete(row)} />
      </div>
    ),
  },
];
