import { roundFloat } from 'src/core/utils/formater';

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'dun',
  },
];

export const customColumns = () => [
  {
    title: 'Д/Д',
    dataIndex: 'counter',
    align: 'center',
    titleAlign: 'center',
    width: 50,
  },
  {
    title: 'Чөлөөлөгдөх орлогын төрөл',
    dataIndex: 'ner',
    align: 'left',
    titleAlign: 'center',
    width: 585,
  },
  {
    title: 'Нийт орлого',
    dataIndex: 'dun',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.dun),
    }),
  },
];
