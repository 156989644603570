import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Typography, Row, Col } from 'antd';

import { useQuery } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';

import Table from 'src/components/common/table';

import {
  hariltsagch_columns,
  hariltsagch_sum_columns,
} from '../data/dataStructure';

const { Text } = Typography;

export default function TabDans() {
  const dispatch = useDispatch();
  const query = useQuery();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [totalData, setTotalData] = useState(5);

  useEffect(() => {
    const fetchData = async (page, size) => {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');

        if (data.content?.status === '0') {
          setTableData(data.content?.json_array);
          setTotalData(data.content?.total);
          setTableDataSum(data.content?.sum_dict);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }

        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts002_00_zuruu',
        content: {
          dans_code: query.get('dans_code'),
          month: data_date.month,
          action_type: 'eh_uld_zuruu_hariltsagchaar',
          page: page || 1,
          size: size || 20,
        },
      });
      dispatch(start_loading({}));
    };
    const page = query.get('page2');
    const size = query.get('size2');
    if (query.get('tab') === '2' && query.get('dans_code')) {
      fetchData(page, size);
    }
  }, [query, data_date]);

  return (
    <>
      {query.get('dans_code') ? (
        <div className="table-wrapper">
          <Row>
            <Col xs={24}>
              <p>
                <span className="ml-3">
                  Дансны дугаар: {query.get('dans_code')}
                </span>
              </p>
            </Col>
            <Col xs={24}>
              <p>
                <span className="ml-3"> Түр зөрүүгийн төрөл: </span>
                {query.get('tur_zuruu_turul')}
              </p>
            </Col>
          </Row>
          <div className="inside-table">
            <Table
              columns={hariltsagch_columns()}
              total={totalData}
              dataSource={tableData}
              tab="2"
              scroll={{
                y: 'calc(100vh - 370px)',
              }}
              summaryList={{
                sumColumn: hariltsagch_sum_columns,
                sumDataSource: tableDataSum,
              }}
            />
          </div>
        </div>
      ) : (
        <Text className="ant-form-text">Дансаа сонгоно уу!</Text>
      )}
    </>
  );
}
