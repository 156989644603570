import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Row, Col, Button, Spin } from 'antd';
import Table from 'src/components/common/table';
import { LoadingOutlined } from '@ant-design/icons';
import { onlyNumber, roundFloat } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';

const { Option } = Select;

const columns = [
  {
    title: '№',
    align: 'left',
    titleAlign: 'left',
    width: '2vw',
    dataIndex: 'dans_dugaar',
    render: (_, row) => row.id,
  },
  {
    title: 'Зардлын дансны дугаар',
    align: 'left',
    titleAlign: 'left',
    width: '7vw',
    dataIndex: 'dans_dugaar',
  },
  {
    title: 'Зардлын дансны нэр',
    align: 'left',
    titleAlign: 'left',
    width: '7vw',
    dataIndex: 'dans_ner',
  },
  {
    title: 'Дансны дүн',
    align: 'left',
    titleAlign: 'left',
    width: '7vw',
    dataIndex: 'dans_dun',
    render: (_) => roundFloat(_),
  },
];

const NewValueAdd = ({
  visible = () => {},
  form = null,
  data = null,
  month,
  close = () => {},
  socket = null,
  refetch = () => {},
  dispatch = null,
}) => {
  const [tableData, setTableData] = useState([]);
  const [hasagdahDansList, setHasagdahDansList] = useState([]);
  const [hasagdahguiDansList, setHasagdahguiDansList] = useState([]);
  const [loading, seTloading] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  console.log('==============dsadasdasdas==============');
  console.log(form.getFieldsValue(true));
  console.log('==============dsadasdasdas==============');
  const { zaalt_id } = form.getFieldsValue(true);

  const onFinish = (values) => {
    const { hasagdah_dans, hasagdahgui_dans } = values;
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: [refetch(month), form.resetFields(), close()] },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_send_final_zardal_data',
      content: {
        month,
        hasagdah_dans_id: hasagdah_dans,
        hasagdahgui_dans_id: hasagdahgui_dans,
        zaalt_id,
      },
    });

    dispatch(start_loading({}));
  };

  const mainData = () => {
    console.log('requesting to get dans');
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        console.log('dans_data', content);
        const new_temp_data = content?.dans_data.map((inner_data, key) => ({
          id: key + 1,
          ...inner_data,
        }));
        if (new_temp_data) {
          setTableData(new_temp_data);
        }
        setHasagdahDansList(content.holboh_dans.hasagdah_dans);
        setHasagdahguiDansList(content.holboh_dans.hasagdahgui_dans);
      }
      dispatch(end_loading({}));
      seTloading(false);
    });
    socket.emit('request_in', {
      channel: 'ts004_select_journal_data',
      content: {
        zardal_type_id: zaalt_id,
        month,
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    console.log('================================');
    console.log(zaalt_id, '------------', visible);
    console.log('================================');
    if (visible === true && zaalt_id !== undefined) {
      console.log('omg');
      mainData();
    }
  }, [visible, zaalt_id]);

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin indicator={antIcon} />;
      </div>
    );
  }

  return (
    <>
      <div className="table-container-div">
        <Table
          columns={columns}
          dataSource={tableData}
          scroll={{
            x: 'auto',
          }}
        />
      </div>
      <Form
        form={form}
        name="add"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        className="small-form mt-5"
        initialValues={{
          unaa_nisleg: 0,
          guitsetgel_tomilolt_zardal: 0,
          tomilolt_hun_honog: 0,
          tomilolt_torol: 1,
          gazar_torol: null,
        }}
        size="small"
        onFinish={onFinish}
      >
        <Form.Item
          label="ХМ-03Б холбох дансны нэр:"
          name="hasagdah_dans"
          rules={[{ required: true, message: 'Заавал сонгоно уу' }]}
        >
          <Select className="ww-70" placeholder="Сонгох">
            {hasagdahDansList.map((i) => (
              <Option value={i?.dans_id}>{i?.dans_ner}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Хасагдах зардлын дүн:"
          name="hasagdah_dun"
          normalize={onlyNumber}
          rules={[{ required: true, message: '' }]}
        >
          <Input className="ww-70" disabled />
        </Form.Item>

        <Form.Item
          label="ХМ-03В холбох дансны нэр:"
          name="hasagdahgui_dans"
          rules={[{ required: true, message: 'Заавал сонгоно уу' }]}
        >
          <Select className="ww-70" placeholder="Сонгох">
            {hasagdahguiDansList.map((i) => (
              <Option value={i?.dans_id}>{i?.dans_ner}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Хасагдахгүй зардлын дүн:"
          name="hasagdahgui_dun"
          normalize={onlyNumber}
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input className="ww-70" disabled />
        </Form.Item>
      </Form>
      <Row
        style={{
          marginTop: 8,
        }}
      >
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            className="cancel-btn"
            type="primary"
            shape="round"
            size="small"
            onClick={close}
          >
            Буцах
          </Button>
          <Button
            form="add"
            className="confirm-btn ml-5"
            type="primary"
            shape="round"
            htmlType="submit"
            size="small"
          >
            Хадгалах
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default NewValueAdd;
