import React, { useEffect, useState } from 'react';
import { IdcardTwoTone, UnlockTwoTone, RightOutlined } from '@ant-design/icons';
import { Form, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { set } from 'lodash';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';
import NodeRSA from 'node-rsa';
import { EditProfile, ChangePassword } from './section';

const tabs = [
  {
    name: 'Хэрэглэгч',
    icon: <IdcardTwoTone className="mr-8" />,
  },
  {
    name: 'Нүүц үг солих',
    icon: <UnlockTwoTone className="mr-8" />,
  },
];

const UserProfile = () => {
  const socket = useSelector((state) => state.socket);
  const dispatch = useDispatch();
  const [active, seTactive] = useState(0);
  const [form] = Form.useForm();
  const rsa = useSelector((state) => state.rsa);

  const fetch = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');

      if (status === '0') {
        const number = content?.user_data?.phone_number.toString();
        if (number && number.length === 8) {
          set(
            content?.user_data,
            'phone_number',
            `${number.substr(0, 4)}-${number.substr(4, 8)}`
          );
        }
        form.setFieldsValue({
          ...content?.user_data,
        });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss004_user_management',
      content: { action_type: 'get_user_data' },
    });

    dispatch(start_loading({}));
  };

  const onClick = (idx) => {
    seTactive(idx);
  };

  useEffect(() => {
    fetch();
  }, []);

  const onFinish = (values) => {
    let confirmedData = { ...values };
    let encryption = null;
    let types = {
      channel: 'ss004_user_management',
      action_type: 'update_user_data',
    };
    if (values?.prev_pass && values?.new_pass && values?.confirm_pass) {
      encryption = true;
      types = {
        channel: 'ss002_auth_manage',
        action_type: 'logged_pass_change',
      };
      const key = new NodeRSA({ b: 512 });
      key.importKey(rsa.serverPublicKey, 'pkcs1-public-pem');
      const prev_pass = key.encrypt(values.prev_pass, 'base64');
      const new_pass = key.encrypt(values.new_pass, 'base64');
      const confirm_pass = key.encrypt(values.confirm_pass, 'base64');
      confirmedData = { prev_pass, new_pass, confirm_pass };
    } else {
      confirmedData = {
        ...values,
        phone_number: values?.phone_number.replace('-', ''),
      };
    }

    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        fetch();
        newAlert({ type: 'success', msg: content.Message });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: content.Message });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      encryption,
      channel: types?.channel,
      content: {
        user_data: confirmedData,
        action_type: types?.action_type,
      },
    });
    dispatch(start_loading({}));
  };

  return (
    <div className="new-user-profile">
      <div className="tab-section ">
        {tabs.map((j, idx) => (
          <div
            className={`tab  ${idx === active ? 'active' : ''}`}
            onClick={() => {
              onClick(idx);
            }}
          >
            {j?.icon}
            {j?.name}
            {idx === active && <RightOutlined />}
          </div>
        ))}
      </div>
      <div className="main-section">
        <span className="title">Хэрэглэгчийн мэдээлэл</span>
        <div className="form-container">
          {/* {!active && (
            <img src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
          )} */}

          <Form
            name="user-profile"
            style={{
              fontSize: 13,
            }}
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className="user-form"
          >
            {active ? <ChangePassword form={form} /> : <EditProfile />}
          </Form>
        </div>
        <div className="footer">
          <Button form="user-profile" htmlType="submit">
            Хадгалах
          </Button>
        </div>
      </div>
    </div>
  );
};
export default UserProfile;
