import React from 'react';
import { Form, Button, Input, Row, Col, InputNumber, Select } from 'antd';
import { get } from 'lodash';

import { onFilter } from 'src/core/utils/selectFilter';

const { Option } = Select;

const main = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const info = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const AddMain = () => (
  <>
    <Form.Item name="id" hidden />
    <Form.Item
      name="ner"
      label="Нэр"
      labelAlign="left"
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
    >
      <Input placeholder="Нэр оруулна уу..." />
    </Form.Item>
  </>
);

const Info = ({ dansList = null, isModifying = false }) => (
  <>
    <Form.Item name="id" hidden />
    <Form.Item
      name="undsen_dans"
      label="Үндсэн данс"
      labelAlign="left"
      className="multiple-select"
      hidden={isModifying}
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
    >
      <Select
        placeholder="Сонгох"
        mode={isModifying ? '' : 'multiple'}
        filterOption={onFilter}
        disabled={isModifying}
        autoClearSearchValue={false}
      >
        {get(dansList, 'undsen_dans', []).map(({ id, ner }) => (
          <Option value={id}>{ner}</Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item
      name="undsen_dans_ner"
      label="Үндсэн данс"
      labelAlign="left"
      hidden={!isModifying}
    >
      <Input placeholder="" disabled />
    </Form.Item>
    <Form.Item
      name="holboh_huvi"
      label="Холбох хувь"
      labelAlign="left"
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
    >
      <InputNumber
        style={{ width: '105px' }}
        min={0}
        max={100}
        addonAfter="%"
      />
    </Form.Item>
    <Form.Item
      name="haritsah_dans"
      label="Татварын ангилал"
      labelAlign="left"
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
    >
      <Select placeholder="Сонгох" showSearch filterOption={onFilter}>
        {get(dansList, 'haritsah_dans', []).map(({ id, ner }) => (
          <Option value={id}>{ner}</Option>
        ))}
      </Select>
    </Form.Item>
  </>
);

export const ModifySection = ({
  form = null,
  onCancel = () => {},
  isMain = false,
  saveValues = () => {},
  dansList = {},
  isModifying = false,
}) => {
  const onFinish = (values) => {
    saveValues(values, isMain);
    onCancel();
  };
  const _layout = isMain ? main : info;
  return (
    <div className="dans-medeelel-container">
      <Form
        {..._layout}
        form={form}
        onFinish={onFinish}
        size="small"
        className="register-form"
        initialValues={{
          holboh_huvi: 100,
        }}
      >
        {isMain ? (
          <AddMain />
        ) : (
          <Info dansList={dansList} isModifying={isModifying} />
        )}
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
