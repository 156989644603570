import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'src/core/utils/axios';
import newAlert from 'src/components/newAlert';
import { Table, CustomSelect } from 'src/components';
import {
  Typography,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Button,
  Divider,
} from 'antd';
import { PicLeftOutlined, ExportOutlined } from '@ant-design/icons';

import { roundFloat, onlyNumber } from 'src/core/utils/formater';
import { customColumns, customSummary } from './dataStructure/data';

import { start_loading, end_loading } from '../../../core/actions/loader';

const FileDownload = require('js-file-download');

const { Text } = Typography;

const MainPage = () => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [dansInfo, setDansInfo] = useState(null);
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      const array = [];
      socket.removeAllListeners('request_out');

      if (data?.status === '0') {
        data.content.json_array.hurungu.forEach((i) => {
          array.push(i);
        });
        array.push(data.content.sum_data_hurungu);
        data.content.json_array.ur_umch.forEach((i) => {
          array.push(i);
        });
        array.push(data.content.sum_data_ur_umch);
        data.content.json_array.busad_orlogo.forEach((i) => {
          array.push(i);
        });
        array.push(data.content.sum_data_busad_orlogo);

        setTableData(array);
        setTableDataSum(data.content.sum_data);
      } else {
        setTableData([]);
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_control',
      content: { action_type: 'get_tur_zuruu_dun', month: String(month) },
    });

    dispatch(start_loading({}));
  };

  /**
   * Export tailan to xlsx format
   */
  const exportTailan = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        const file_export_address_link = `/get_file?file_id=${data.content.file_id}`;
        axios.get('export', file_export_address_link, {}, {}, (event) => {
          FileDownload(event, 'Дансны үнэ - Татварын суурь зөрүү.xlsx');
        });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_control',
      content: {
        action_type: 'get_tur_zuruu_dun',
        month: String(data_date.month),
        receive_channel: 'ss010_00_export_file',
        receive_action_type: 'export_dans_zuruu',
      },
    });

    dispatch(start_loading({}));
  };

  const saveValues = (saveData) => {
    const tempData = saveData;
    delete tempData.uldegdel;
    delete tempData.undsen_dans_dugaar;
    delete tempData.undsen_dans_ner;
    tempData.month = String(data_date.month);

    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan(data_date.month);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tatvar_journal_burtgel',
      content: { action_type: 'add_journal_balance', data: tempData },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
  }, [data_date]);

  const editAction = (row) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setDansInfo(data.content.haritsah_dans_list);
        // Set form data
        form.setFieldsValue({
          undsen_dans_id: row?.dans_id,
          undsen_dans_dugaar: row?.dans_dugaar,
          undsen_dans_ner: row?.dans_ner,
          harits_dans_id: row?.default_dans_id,
          uldegdel: roundFloat(data?.content?.uldegdel),
          deb: roundFloat(data?.content?.deb),
          cred: roundFloat(data?.content?.cred),
        });

        setVisible(true);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tatvar_journal_burtgel',
      content: {
        action_type: 'get_dans_info',
        tohiruulga_type: 'Баланс',
        month: String(data_date.month),
        dans_id: row?.dans_id,
      },
    });

    dispatch(start_loading({}));
  };

  const calculateAll = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай тохиргоо хийгдлээ' });
        generateTailan(data_date.month);
      } else if (data.status === '5') {
        newAlert({
          type: 'error',
          msg: 'Нэгээс олон орлого зардлын нэгдсэн данс бүртгэгдсэн байгаа тул бөөн дүнгээр тохиргоо хийх боломжгүй',
        });
      } else if (data.status === '4') {
        newAlert({
          type: 'error',
          msg: 'Орлого зардлын нэгдсэн данс холбоогүй байна',
        });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tatvar_journal_burtgel',
      content: {
        action_type: 'add_journal_balance_auto',
        month: String(data_date.month),
      },
    });

    dispatch(start_loading({}));
  };

  const title = () => (
    <>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<ExportOutlined />}
        onClick={() => {
          exportTailan();
        }}
      >
        Экспорт
      </Button>
      <Button
        id="first"
        className="add-btn"
        type="primary"
        size="small"
        icon={<PicLeftOutlined />}
        onClick={() => {
          calculateAll();
        }}
      >
        Тохиргоог бөөнөөр бүртгэх
      </Button>
    </>
  );

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          columns={customColumns({
            customEdit: editAction,
          })}
          dataSource={tableData}
          scroll={{
            y: 'calc(100vh - 280px)',
          }}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableDataSum,
          }}
          title={title}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Татварын тохиргоо бүртгэх"
        visible={visible}
        closable={false}
        maskClosable={false}
        width={700}
        footer={null}
      >
        <CustomModal
          onClose={() => {
            setVisible(false);
          }}
          form={form}
          listData={dansInfo}
          saveValues={saveValues}
        />
      </Modal>
    </>
  );
};

const layout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 19 },
  },
};

const CustomModal = ({
  onClose = () => {},
  form = null,
  listData = [],
  saveValues = () => {},
}) => {
  const onFinish = (values) => {
    saveValues(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const customText = (item) => `${item?.dans_dugaar} ${item?.dans_ner}`;

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="undsen_dans_dugaar" hidden />
        <Form.Item name="undsen_dans_ner" hidden />
        <Form.Item name="undsen_dans_id" label="Үндсэн данс" labelAlign="left">
          <Text ellipsis>
            {form.getFieldValue('undsen_dans_dugaar')}{' '}
            {form.getFieldValue('undsen_dans_ner')}
          </Text>
        </Form.Item>
        <Form.Item name="uldegdel" label="Дансны зөрүү" labelAlign="left">
          <Text>{form.getFieldValue('uldegdel')}</Text>
        </Form.Item>
        <Divider />
        <Form.Item
          name="harits_dans_id"
          label="Харьцах данс"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          shouldUpdate={
            (prev, current) => prev.harits_dans_id !== current.harits_dans_id
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          <CustomSelect
            size="small"
            list={listData}
            valueProp="dans_id"
            customText={customText}
          />
        </Form.Item>
        <Form.Item
          name="deb"
          label="Дебет"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" disabled />
        </Form.Item>
        <Form.Item
          name="cred"
          label="Кредит"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" disabled />
        </Form.Item>
        <Form.Item
          name="guilgee_utga"
          label="Гүйлгээний утга"
          labelAlign="left"
        >
          <Input className="w-100" />
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MainPage;
