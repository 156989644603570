const loading = { loading: false };

const reducer = (state = loading, action) => {
  switch (action.type) {
    case 'start_loading':
      return { loading: true };
    case 'end_loading':
      return { loading: false };
    default:
      return state;
  }
};

export default reducer;
