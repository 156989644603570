import React from 'react';

const word = 'Э-такс системтэй холбогдлоо';

export const headerSrc =
  'https://auth.itc.gov.mn/auth/resources/lmrdn/login/keycloak/img/logo.svg';

export const Connecting = () => (
  <h1 className="jt --debug">
    <span className="jt__row">
      <span className="jt__text">{word}</span>
    </span>
    <span className="jt__row jt__row--sibling" aria-hidden="true">
      <span className="jt__text">{word}</span>
    </span>
    <span className="jt__row jt__row--sibling" aria-hidden="true">
      <span className="jt__text">{word}</span>
    </span>
    <span className="jt__row jt__row--sibling" aria-hidden="true">
      <span className="jt__text">{word}</span>
    </span>
  </h1>
);
