import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { DeleteOutlined } from '@ant-design/icons';

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'unelgee_dun',
  },
  {
    align: 'right',
    value: 'tur_notlogdoh_dun',
  },
  {
    align: 'right',
    value: 'busad_notlogdoh_dun',
  },
  {
    align: 'right',
    value: 'tatvar_nogdoh_dun',
  },
  {
    align: 'right',
    value: 'tatvar_nogduulsan_dun',
  },
  {
    value: null,
  },
];

export const customColumns = ({ customDelete = () => {} }) => [
  {
    title: 'Д/Д',
    dataIndex: 'counter',
    align: 'center',
    titleAlign: 'center',
    width: 50,
  },
  {
    title: 'Эрх худалдаж авсан, шилжүүлэн авсан этгээдийн',
    titleAlign: 'center',
    children: [
      {
        title: 'Нэр',
        dataIndex: 'ner',
        align: 'left',
        titleAlign: 'center',
        width: 150,
      },
      {
        title: 'Регистрийн дугаар',
        dataIndex: 'ttd',
        align: 'left',
        titleAlign: 'center',
        width: 100,
      },
    ],
  },
  {
    title: 'Эрхийн төрөл',
    dataIndex: 'erh_turul',
    align: 'left',
    titleAlign: 'center',
    width: 185,
  },
  {
    title: 'Тусгай зөвшөөрлийн дугаар',
    dataIndex: 'zuvshuurul_dugaar',
    align: 'left',
    titleAlign: 'center',
    width: 150,
  },
  {
    title: 'Эрх шилжүүлсэн',
    titleAlign: 'center',
    children: [
      {
        title: 'Огноо',
        dataIndex: 'ognoo',
        align: 'left',
        titleAlign: 'center',
        width: 100,
      },
      {
        title: 'Үнэлгээ',
        dataIndex: 'unelgee_dun',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.unelgee_dun),
        }),
      },
    ],
  },
  {
    title: 'Төрийн байгууллагад төлсөн баримтаар нотлогдох төлбөр',
    dataIndex: 'tur_notlogdoh_dun',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.tur_notlogdoh_dun),
    }),
  },
  {
    title:
      'Бусдаас худалдан авахад төлсөн, шилжүүлсэн баримтаар нотлогдох төлбөр',
    dataIndex: 'busad_notlogdoh_dun',
    align: 'right',
    titleAlign: 'center',
    width: 200,
    render: (_, row) => ({
      children: roundFloat(row?.busad_notlogdoh_dun),
    }),
  },
  {
    title: 'Татвар ногдуулах орлого',
    dataIndex: 'tatvar_nogdoh_dun',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.tatvar_nogdoh_dun),
    }),
  },
  {
    title: 'Ногдуулсан татвар',
    dataIndex: 'tatvar_nogduulsan_dun',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.tatvar_nogduulsan_dun),
    }),
  },
  {
    title: 'Устгах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    render: (_, row) => (
      <div>
        <DeleteOutlined onClick={() => customDelete(row)} />
      </div>
    ),
  },
];
