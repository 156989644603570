const oDefaultState = {
  type: '',
  msg: '',
  open: false,
};
const reducer = (state = oDefaultState, action) => {
  switch (action.type) {
    case 'set_alert':
      const oNewState = action.payload;
      return oNewState;
    case 'reset_alert':
      return oDefaultState;
    default:
      return state;
  }
};
export default reducer;
