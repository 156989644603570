import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { Warning } from 'src/components';
import { Table as AntTable, Button as AntButton } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const clear = (inputString) => {
  const stringWithoutCommas = String(inputString).replace(/,/g, '');
  const numberValue = parseFloat(stringWithoutCommas);
  return numberValue;
};
export const ErrorListColumnshurungu = () => [
  {
    title: 'Хөрөнгийн шинэ код',
    dataIndex: 'hurungu_code',
    align: 'left',
    width: 200,
  },
  {
    title: 'Хөрөнгийн шинэ нэр',
    dataIndex: 'hurungu_ner',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
];

export const ErrorListColumnsDans = () => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 200,
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
];

export const ErrorListColumns = () => [
  {
    title: 'Үндсэн данс',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
  },
  {
    title: 'Элэгдэл данс',
    dataIndex: 'dans_dugaar_elegdel',
    align: 'left',
    width: 250,
  },
  {
    title: '',
    align: 'left',
    width: 250,
    render: () => 'Дүн таарсангүй',
  },
];
export const ErrorListDahinColumns = () => [
  {
    title: 'Алдаа тайлбар',
    dataIndex: 'error',
    align: 'left',
    width: 100,
  },
  {
    title: 'Алдаатай мөрүүд',
    dataIndex: 'error_rows',
    align: 'left',
    width: 300,
    ellipsis: true,
  },
];
export const ErrorListDahinRowsColumns = () => [
  {
    title: 'Мөрийн дугаар',
    dataIndex: 'row_number',
    align: 'left',
    width: 50,
  },
  {
    title: 'Алдаа',
    dataIndex: 'errors',
    align: 'left',
    width: 350,
    ellipsis: true,
  },
];

export const customDansSummary = ({ data = null }) => (
  <AntTable.Summary fixed>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell />
      <AntTable.Summary.Cell align="right">Нийт дүн:</AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.eh_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.eh_urtug)}</p>
        <p>{roundFloat(data?.eh_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.deb_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.deb_urtug)}</p>
        <p>{roundFloat(data?.deb_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.cred_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.cred_urtug)}</p>
        <p>{roundFloat(data?.cred_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.ets_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.ets_urtug)}</p>
        <p>{roundFloat(data?.ets_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
  </AntTable.Summary>
);

export const customSummary = ({ data = null }) => (
  <AntTable.Summary fixed>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell />
      <AntTable.Summary.Cell />
      <AntTable.Summary.Cell />
      <AntTable.Summary.Cell align="right">Нийт дүн:</AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.eh_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.eh_urtug)}</p>
        <p>{roundFloat(data?.eh_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.deb_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.deb_urtug)}</p>
        <p>{roundFloat(data?.deb_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.cred_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.cred_urtug)}</p>
        <p>{roundFloat(data?.cred_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.ets_too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.ets_urtug)}</p>
        <p>{roundFloat(data?.ets_huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
  </AntTable.Summary>
);

export const ColumnsDansMain = () => [
  {
    align: 'center',
    width: 30,
    fixed: 'left',
    render: (_, row) => (
      <>
        {(row?.dah_result > 0.00 || row?.zuruu > 0.00) && (
          <Warning
            show
            listWarning={[
              row?.dah_result > 0.00 ? 'Өртгийн өөрчлөлт дүн таараагүй байна' : '',
              row?.zuruu > 0.00 ? 'Холбосон дүн таараагүй байна' : '',
            ].filter((text) => text !== '')}
          />
        )}
      </>
    ),
  },
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Эхний үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'eh_too_shirheg',
        align: 'right',
        width: 70,
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'eh_urtug',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.eh_urtug)}</p>
            <p>{roundFloat(row?.eh_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Дебет',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'deb_too_shirheg',
        align: 'right',
        width: 70,
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'deb_urtug',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.deb_urtug)}</p>
            <p>{roundFloat(row?.deb_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Кредит',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'cred_too_shirheg',
        align: 'right',
        width: 70,
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'cred_urtug',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.cred_urtug)}</p>
            <p>{roundFloat(row?.cred_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Эцсийн үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'ets_too_shirheg',
        align: 'right',
        width: 70,
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'ets_urtug',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.ets_urtug)}</p>
            <p>{roundFloat(row?.ets_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
];

export const ColumnsMain = ({ onSelectHurungu = () => {} }) => [
  {
    align: 'center',
    width: 30,
    fixed: 'left',
    render: (_, row) => (
      <>
        {(row?.dah_result > 0.00 || row?.zuruu > 0.00) && (
          <Warning
            show
            listWarning={[
              row?.dah_result > 0.00 ? 'Өртгийн өөрчлөлт дүн таараагүй байна' : '',
              row?.zuruu > 0.00 ? 'Холбосон дүн таараагүй байна' : '',
            ].filter((text) => text !== '')}
          />
        )}
      </>
    ),
  },
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    align: 'left',
    width: 120,
    fixed: 'left',
    render: (_, row) => (
      <AntButton
        align="center"
        type="link"
        size="small"
        className="underline"
        onClick={() => onSelectHurungu(row)}
      >
        {row?.code}
      </AntButton>
    ),
  },
  {
    title: 'Хөрөнгийн нэр',
    dataIndex: 'ner',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Эхний үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'eh_too_shirheg',
        width: 70,
        align: 'right',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'eh_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.eh_urtug)}</p>
            <p>{roundFloat(row?.eh_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Дебет',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'deb_too_shirheg',
        width: 70,
        align: 'right',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'deb_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.deb_urtug)}</p>
            <p>{roundFloat(row?.deb_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Кредит',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'cred_too_shirheg',
        width: 70,
        align: 'right',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'cred_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.cred_urtug)}</p>
            <p>{roundFloat(row?.cred_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Эцсийн үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'ets_too_shirheg',
        width: 70,
        align: 'right',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'ets_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.ets_urtug)}</p>
            <p>{roundFloat(row?.ets_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
];

export const ColumnsBurtgel = () => [
  {
    title: 'Бүртгэлийн дугаар',
    dataIndex: 'burtgel_dugaar',
    width: 150,
    fixed: 'left',
  },
  {
    title: 'Эхний үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'eh_too_shirheg',
        width: 70,
        align: 'right',
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'eh_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.eh_urtug)}</p>
            <p>{roundFloat(row?.eh_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Дебет ',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'deb_too_shirheg',
        width: 70,
        align: 'right',
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'deb_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.deb_urtug)}</p>
            <p>{roundFloat(row?.deb_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Кредит',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'cred_too_shirheg',
        width: 70,
        align: 'right',
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'cred_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.cred_urtug)}</p>
            <p>{roundFloat(row?.cred_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Эцсийн үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'ets_too_shirheg',
        width: 70,
        align: 'right',
      },
      {
        title: 'Өртөг/элэгдэл',
        dataIndex: 'ets_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>
            <p>{roundFloat(row?.ets_urtug)}</p>
            <p>{roundFloat(row?.ets_huramt_elegdel)}</p>
          </>
        ),
      },
    ],
  },
];

export const ColumnsBurtgelEdit = ({ deleteRow = () => {} }) => [
  {
    title: 'Бүртгэлийн дугаар',
    dataIndex: 'burtgel_dugaar',
    width: 150,
    fixed: 'left',
  },
  {
    title: 'Эхний үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'eh_too_shirheg',
        width: 70,
        align: 'right',
      },
      {
        title: 'Өртөг',
        dataIndex: 'eh_urtug',
        width: 150,
        align: 'right',

      },
      {
        title: 'Элэгдэл',
        dataIndex: 'eh_huramt_elegdel',
        width: 150,
        align: 'right',
      },
    ],
  },
  {
    title: 'Дебет ',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'deb_too_shirheg',
        width: 70,
        align: 'right',
        editable: true,
      },
      {
        title: 'Өртөг',
        dataIndex: 'deb_urtug',
        width: 150,
        align: 'right',
        editable: true,
      },
      {
        title: 'Элэгдэл',
        dataIndex: 'deb_huramt_elegdel',
        width: 150,
        align: 'right',
        editable: true,
      },
    ],
  },
  {
    title: 'Кредит',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'cred_too_shirheg',
        width: 70,
        align: 'right',
        editable: true,
      },
      {
        title: 'Өртөг',
        dataIndex: 'cred_urtug',
        width: 150,
        align: 'right',
        editable: true,
      },
      {
        title: 'Элэгдэл',
        dataIndex: 'cred_huramt_elegdel',
        width: 150,
        align: 'right',
        editable: true,
      },
    ],
  },
  {
    title: 'Эцсийн үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'ets_too_shirheg',
        width: 70,
        align: 'right',
        render: (_, row) => (
          <>{roundFloat(clear(row?.eh_too_shirheg)
          + clear(row?.deb_too_shirheg) - clear(row?.cred_too_shirheg)) }
          </>
        ),
      },
      {
        title: 'Өртөг',
        dataIndex: 'ets_urtug',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>{roundFloat(clear(row?.eh_urtug)
          + clear(row?.deb_urtug) - clear(row?.cred_urtug)) }
          </>
        ),
      },
      {
        title: 'Элэгдэл',
        dataIndex: 'ets_huramt_elegdel',
        width: 150,
        align: 'right',
        render: (_, row) => (
          <>{roundFloat(clear(row?.eh_huramt_elegdel)
          + clear(row?.cred_huramt_elegdel) - clear(row?.deb_huramt_elegdel)) }
          </>
        ),
      },
    ],
  },
  {
    title: 'Устгах',
    align: 'center',
    width: 50,
    fixed: 'right',
    render: (_, row) => (
      <DeleteOutlined id="second" onClick={() => deleteRow(row)} />
    ),
  },
];

export const EditSum = ({ data = null }) => (
  <AntTable.Summary fixed>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right">
        <>
          <p>Холбосон</p>
          <p>Дансны</p>
        </>
      </AntTable.Summary.Cell>

      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.burt_eh_too_shirheg)}</p>
          <p>{roundFloat(data?.eh_too_shirheg)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.burt_eh_urtug)}</p>
          <p>{roundFloat(data?.eh_urtug)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.burt_eh_huramt_elegdel)}</p>
          <p>{roundFloat(data?.eh_huramt_elegdel)}</p>
        </>
      </AntTable.Summary.Cell>

      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.burt_deb_too_shirheg)}</p>
          <p>{roundFloat(data?.deb_too_shirheg)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.burt_deb_urtug)}</p>
          <p>{roundFloat(data?.deb_urtug)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.burt_deb_huramt_elegdel)}</p>
          <p>{roundFloat(data?.deb_huramt_elegdel)}</p>
        </>
      </AntTable.Summary.Cell>

      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.burt_cred_too_shirheg)}</p>
          <p>{roundFloat(data?.cred_too_shirheg)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.burt_cred_urtug)}</p>
          <p>{roundFloat(data?.cred_urtug)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.burt_cred_huramt_elegdel)}</p>
          <p>{roundFloat(data?.cred_huramt_elegdel)}</p>
        </>
      </AntTable.Summary.Cell>

      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.burt_ets_too_shirheg)}</p>
          <p>{roundFloat(data?.ets_too_shirheg)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.burt_ets_urtug)}</p>
          <p>{roundFloat(data?.ets_urtug)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.burt_ets_huramt_elegdel)}</p>
          <p>{roundFloat(data?.ets_huramt_elegdel)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <></>
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
  </AntTable.Summary>
);
