import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'src/components';
import { Drawer, Form, Input, Typography } from 'antd';
import newAlert from 'src/components/newAlert';
import { ElegdelDelgerenguiZuruu } from 'src/components/undsenHurungu/elegdelDelgerenguiZuruu';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { ColumnsBurtgel } from './dataStructure/data';

const { Text } = Typography;
const { Search } = Input;

export default function BurtgeList({
  hurunguData = {},
  closeDrawer = () => {},
  open = false,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [updateData, setUpdateData] = useState(null);
  const [elegdelVisible, setElegdelVisible] = useState(false);
  const [tableData, setTableData] = useState({});
  const [pagenationData, seTpagenationData] = useState({
    page: 1,
    size: 20,
    burtgel: '',
  });

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const { page, size } = pagenationData;
    const { month } = data_date;
    const search = {
      page2: pagenationData?.page,
      size2: pagenationData?.size,
      dans: hurunguData.dans_dugaar,
      hurungu: hurunguData.hurungu_id,
      burtgel: pagenationData?.burtgel,
    };
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'tur_zuruu_burtgel_page',
        month,
        page,
        size,
        search,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      seTpagenationData({
        ...pagenationData,
        page: 1,
        burtgel: '',
      });
    } else {
      select_data();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      select_data();
    }
  }, [pagenationData]);

  const getParm = (values) => {
    if (
      // eslint-disable-next-line operator-linebreak
      values.page !== pagenationData.page ||
      values.size !== pagenationData.size
    ) {
      seTpagenationData({
        ...pagenationData,
        page: values.page,
        size: values.size,
      });
    }
  };

  const onSearch = (values) => {
    if (values) {
      seTpagenationData({
        ...pagenationData,
        burtgel: values,
      });
    } else {
      seTpagenationData({
        ...pagenationData,
        burtgel: '',
      });
    }
  };

  const title = () => (
    <>
      <div className="table-title-text-center">Бүртгэлийн мэдээлэл</div>
      <div style={{ width: '350px' }}>
        <Form.Item name="search" className="ant-form-item-small">
          <Search
            size="small"
            allowClear
            placeholder="Бүртгэлийн дугаар..."
            onSearch={onSearch}
          />
        </Form.Item>
      </div>
    </>
  );

  const onCancelElegdel = () => {
    setElegdelVisible(false);
  };

  const setBurtgelDataRow = (row) => {
    setUpdateData({
      ...row,
      code: row.burtgel_dugaar,
      hurungu: hurunguData?.code,
      hurungu_ner: hurunguData?.ner,
    });
    setElegdelVisible(true);
  };

  return (
    <Drawer
      title="Үндсэн хөрөнгийн бүртгэл"
      open={open}
      width="90%"
      footer={null}
      closable
      maskClosable={false}
      onClose={() => {
        closeDrawer();
      }}
      className="custom-drawer"
    >
      <ElegdelDelgerenguiZuruu
        data={updateData}
        visible={elegdelVisible}
        year_type="first"
        onCancel={onCancelElegdel}
      />
      <div className="dans-medeelel-container">
        <Form
          form={form}
          name="basic"
          className="register-form"
          size="small"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Form.Item
            label="Дансны дугаар"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{hurunguData?.dans_dugaar}</Text>
          </Form.Item>
          <Form.Item
            label="Үндсэн хөрөнгийн нэр"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{hurunguData?.ner}</Text>
          </Form.Item>
          <Form.Item
            label="Үндсэн хөрөнгийн код"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{hurunguData?.code}</Text>
          </Form.Item>
          <Table
            title={title}
            columns={ColumnsBurtgel({ setBurtgelDataRow })}
            subTotal={tableData.total_size}
            dataSource={tableData?.result_list}
            scroll={{
              y: 'calc(100vh - 330px)',
            }}
            getParam={getParm}
          />
        </Form>
      </div>
    </Drawer>
  );
}
