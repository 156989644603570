import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography,
  Button,
  Table,
  Select,
  Modal,
  Form,
  Row,
  Col,
  Tag,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import ProCard from '@ant-design/pro-card';
import CanView from 'src/components/permission/canview';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';
import UserCreate from './add';
import { mainColumns } from './utils/data';

const { Title } = Typography;
const { Option } = Select;
const scroll = {
  y: 600,
  x: 'auto',
};

const ChangeRole = ({
  form = null,
  roleList = [],
  onCancel = () => {},
  socket = null,
  fetchData = () => {},
}) => {
  const dispatch = useDispatch();

  const submit = (data) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        newAlert({ type: 'success', msg: content?.Message });
        fetchData();
        onCancel();
      } else {
        newAlert({ type: 'error', msg: content?.Message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss006_manage_permission',
      content: {
        action_type: 'update_user_role',
        ...data,
      },
    });
    dispatch(start_loading({}));
  };

  const onFinish = (values) => {
    const user_id = form.getFieldValue('user_id');
    submit({ user_id, ...values });
  };

  useEffect(() => {
    form.setFieldsValue({ role_id: roleList[0]?.role_id });
  }, [roleList]);
  return (
    <div>
      <Form
        style={{
          fontSize: 13,
          fontWeight: 600,
        }}
        form={form}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        onFinish={onFinish}
      >
        <Form.Item label="Хэрэглэгч нэр">
          {form.getFieldValue('name')}
        </Form.Item>
        {roleList.length > 0 ? (
          <Form.Item label="Эрхийн төрөл" name="role_id">
            <Select className="ant-select-blue">
              {roleList.map((i) => (
                <Option value={i?.role_id}>{i?.name}</Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              marginBottom: 20,
            }}
          >
            <Tag color="red">Эрхийн төрөл сонголт алга</Tag>
          </div>
        )}
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item noStyle>
              <Button
                onClick={() => {
                  form.resetFields();
                  onCancel();
                }}
                size="small"
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Буцах
              </Button>
              <Button
                size="small"
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
                disabled={roleList.length === 0}
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const UserAdd = () => {
  const socket = useSelector((state) => state.socket);
  const [form] = Form.useForm();
  const [visible, seTvisible] = useState(false);
  const [toggle, seTtoggle] = useState(false);
  const [roleList, seTroleList] = useState([]);
  const [userList, seTuserList] = useState([]);
  const dispatch = useDispatch();

  const myRole = useSelector((state) => state.user_role);
  const myRoleId = myRole?.default_role_id;
  const open = () => {
    seTvisible(true);
  };

  const close = () => {
    seTvisible(false);
  };

  const fetchData = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        const { user_list } = content;
        seTuserList(user_list);
      }
    });
    socket.emit('request_in', {
      channel: 'ss004_user_management',
      content: {
        action_type: 'get_company_user_list',
      },
    });
  };

  const remove_user = (data) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        newAlert({ type: 'success', msg: content?.Message });
        setTimeout(() => {
          fetchData();
        }, 100);
      } else {
        newAlert({ type: 'error', msg: content?.Message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss006_manage_permission',
      content: {
        action_type: 'remove_user_from_company',
        guest_role_id: data?.role_id !== undefined ? data?.role_id : '',
        guest_user_id: data?.user_id,
        guest_role_lvl: data?.role_level,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      fetchData();
      if (content?.role_list && content?.role_list.length > 0) {
        seTroleList([...content?.role_list]);
      }
    });
    socket.emit('request_in', {
      channel: 'ss006_manage_permission',
      content: {
        action_type: 'get_role_list',
      },
    });
  }, []);
  const extraButton = (
    <CanView allowedRole="015_1">
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          open();
        }}
      >
        Хэрэглэгч нэмэх
      </Button>
    </CanView>
  );
  const onEdit = (row) => {
    form.setFieldsValue({ ...row });
    seTtoggle(true);
  };
  return (
    <div className="role-add-container">
      <ProCard
        className="role-header-card"
        split="vertical"
        title={<Title level={3}>Хэрэглэгчийн тохиргоо</Title>}
        extra={extraButton}
      >
        <Table
          className="role-table"
          columns={mainColumns({ onEdit, id: myRoleId, remove_user })}
          dataSource={userList}
          rowClassName="role-row"
          scroll={scroll}
          pagination={false}
        />
      </ProCard>
      <Modal
        className="custom-modal"
        title="Хэрэглэгч урих"
        visible={visible}
        // closable={false}
        maskClosable={false}
        width={710}
        onCancel={close}
        footer={null}
      >
        <UserCreate
          roleList={roleList}
          data={[]}
          onClose={close}
          socket={socket}
          reRender={fetchData}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Эрхийн төрөл сонгох"
        visible={toggle}
        maskClosable={false}
        width={300}
        onCancel={() => {
          seTtoggle(false);
        }}
        footer={null}
      >
        <ChangeRole
          form={form}
          onCancel={() => {
            seTtoggle(false);
          }}
          fetchData={fetchData}
          socket={socket}
          roleList={roleList.filter(
            (i) =>
              i?.default_role_id > myRoleId &&
              i?.role_id > form.getFieldValue('role_id')
          )}
        />
      </Modal>
    </div>
  );
};
export default UserAdd;
