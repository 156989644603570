import React from 'react';
import { Button, Form, Select, Upload, message, Row, Col } from 'antd';
import newAlert from 'src/components/newAlert';
import { useDispatch } from 'react-redux';
import { start_loading, end_loading } from 'src/core/actions/loader';
import axios from 'src/core/utils/axios';
import { FileError } from 'src/components/common/file_error';
import { CloudUploadOutlined } from '@ant-design/icons';

const { Option } = Select;

export const NewFileAddDahin = ({
  typesList = [],
  after_handleUpload = () => {},
  onCancel = () => {},
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = async (data) => {
    const { file } = data;
    if (file) {
      dispatch(start_loading({}));
      const { size: file_size } = file[0];
      const { file_source } = data;
      const formData = new FormData();
      formData.append('file', file[0].originFileObj);
      const { status, content } = await axios.post(
        'first',
        '/undsen_horongo_dahin_unelgee',
        formData,
        { file_size, file_source }
      );
      if (status === '0') {
        after_handleUpload(content);
      } else if (content?.type === 'content') {
        FileError({ text: content?.message });
        dispatch(end_loading({}));
        // form.resetFields();
        // onCancel();
      } else {
        newAlert({ type: 'error', msg: content?.message });
        dispatch(end_loading({}));
        // form.resetFields();
        // onCancel();
      }
    }
    form.resetFields();
    onCancel();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const layout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 17 },
    },
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item
          name="file_source"
          label="Эх сурвалж"
          labelAlign="left"
          rules={[{ required: true, message: '' }]}
        >
          <Select placeholder="Сонгох" style={{ width: '100%', fontSize: 13 }}>
            <Option value="2">Такс</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="file"
          label="Файл"
          labelAlign="left"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: 'Файл сонгоно уу' }]}
        >
          <Upload
            accept={typesList.join()}
            showUploadList
            maxCount={1}
            beforeUpload={(File) => {
              const isXls = typesList.find((i) => i === File.type);
              if (!isXls) {
                message.error('Зөвхөн xls, xlsx өргөтгөлтэй файл оруулна уу');
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
          >
            <CloudUploadOutlined className="fs-30" />
          </Upload>
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={() => {
                  form.resetFields();
                  onCancel();
                }}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
