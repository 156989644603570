import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { onlyNumber } from 'src/core/utils/formater';
import DatePicker from 'src/components/common/datePicker';
import {
  Typography,
  Button,
  Form,
  Row,
  Col,
  Modal,
  Input,
  Select,
  Divider,
} from 'antd';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from '../../../core/actions/loader';

const { Option } = Select;
const { Text } = Typography;

export const UpdateDahinUnelgee = ({
  updateDahinVisible,
  onCancel,
  updateData,
}) => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const [form] = Form.useForm();

  const dateFormat = 'YYYY/MM/DD';

  const [listDef, setlistDef] = useState(null);
  const [listArga, setlistArga] = useState(null);

  const handleDialogClose = () => {
    form.resetFields();
    onCancel();
  };

  function select_hurungu_def() {
    socket.on('request_out', (def_data) => {
      socket.removeAllListeners('request_out');
      if (def_data.status === '0') {
        setlistDef(def_data?.content?.result_list);
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: { action_type: 'undsen_medeelel_dahin_unelgee_arga_def' },
    });
    dispatch(start_loading({}));
  }

  useEffect(() => {
    if (updateDahinVisible) {
      select_hurungu_def();
    }
  }, [updateDahinVisible]);

  const onChangeHelber = (value) => {
    setlistArga(null);
    if (listDef) {
      const info = listDef.find((item) => item.helber === value);
      if (info) {
        setlistArga([...info.arga]);
      }
    }
  };

  useEffect(() => {
    if (listDef) {
      if (updateDahinVisible) {
        form.setFieldsValue({
          update_id: null,
          helber: null,
          unelgee_arga: null,
          urtug: 0.0,
          huramt_elegdel: 0.0,
          dun_hasah: '0.00',
          dun_nemeh: '0.00',
          dun_hasah_elegdel: '0.00',
          dun_nemeh_elegdel: '0.00',
          urtug_uldeh_uurchlult: '0.00',
        });
        if (updateData?.dahin_unelgee) {
          form.setFieldsValue({
            ...updateData?.dahin_unelgee,
          });
          if (listDef) {
            const info = listDef.find(
              (item) => item.helber === updateData?.dahin_unelgee.helber
            );
            if (info) {
              setlistArga([...info.arga]);
            }
          }
        }
      }
    }
  }, [listDef]);

  const handleSave = (values) => {
    const new_data = {
      update_id: updateData?.dahin_unelgee?.id,
      burtgel_id: updateData?.burtgel_info.burtgel_id,
      unelgee_arga: values.unelgee_arga,
      ognoo: values.ognoo,
      ashiglaj_duusah_uurchlult: values.ashiglaj_duusah_uurchlult
        ? values.ashiglaj_duusah_uurchlult
        : '',
      dun_nemeh: values?.dun_nemeh ? values.dun_nemeh : '0.00',
      dun_hasah: values?.dun_hasah ? values.dun_hasah : '0.00',
      dun_hasah_elegdel: values?.dun_hasah_elegdel
        ? values.dun_hasah_elegdel
        : '0.00',
      dun_nemeh_elegdel: values?.dun_nemeh_elegdel
        ? values.dun_nemeh_elegdel
        : '0.00',
      urtug_uldeh_uurchlult: values?.urtug_uldeh_uurchlult
        ? values.urtug_uldeh_uurchlult
        : '0.00',
    };
    socket.on('request_out', (req_data) => {
      socket.removeAllListeners('request_out');
      if (req_data.status === '0') {
        handleDialogClose();
        newAlert({ type: 'success', msg: req_data.message });
      } else {
        newAlert({ type: 'error', msg: req_data.message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: {
        action_type: 'insert_dahin_unelgee',
        json_array: new_data,
      },
    });
    dispatch(start_loading({}));
  };

  const onFinish = (values) => {
    handleSave(values);
  };

  const onChangeUrtugNemeh = () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      dun_hasah: '0.00',
    });
  };

  const onChangeUrtugHasah = () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      dun_nemeh: '0.00',
    });
  };

  const onChangeUrtugNemehElegdel = () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      dun_hasah_elegdel: '0.00',
    });
  };

  const onChangeUrtugHasahElegdel = () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      dun_nemeh_elegdel: '0.00',
    });
  };

  const layout = {
    labelCol: {
      xs: { span: 10 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 14 },
      sm: { span: 14 },
    },
  };

  return (
    <Modal
      className="custom-modal"
      title="Анхны өртгийн өөрчлөлт нэмэх"
      open={updateDahinVisible}
      closable={false}
      maskClosable={false}
      width={800}
      footer={null}
      onCancel={handleDialogClose}
    >
      <div className="dans-medeelel-container">
        <Form
          form={form}
          onFinish={onFinish}
          className="register-form"
          {...layout}
          size="small"
        >
          <Form.Item name="burtgel_id" hidden />
          <Form.Item label="Үндсэн хөрөнгийн нэр" labelAlign="left">
            <Text ellipsis>{updateData?.hurungu_info?.ner}</Text>
          </Form.Item>
          <Form.Item label="Үндсэн хөрөнгийн код" labelAlign="left">
            <Text>{updateData?.hurungu_info?.code}</Text>
          </Form.Item>
          <Form.Item
            name="burtgel_dugaar"
            label="Бүртгэлийн дугаар"
            labelAlign="left"
          >
            <Text>{updateData?.burtgel_info?.burtgel_dugaar}</Text>
          </Form.Item>
          <Divider />
          <Form.Item
            name="helber"
            label="Анхны өртгийн өөрчлөлтийн хэлбэр"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Select placeholder="Сонгох" allowClear onChange={onChangeHelber}>
              {
                // eslint-disable-next-line operator-linebreak
                listDef &&
                  listDef.map((value) => (
                    <Option value={value.helber}>{value.helber_text}</Option>
                  ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            name="unelgee_arga"
            label="Анхны өртгийн өөрчлөлт хийсэн арга"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Select placeholder="Сонгох" allowClear>
              {
                // eslint-disable-next-line operator-linebreak
                listArga &&
                  listArga.map((value) => (
                    <Option value={value.val}>{value.text} </Option>
                  ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            name="ognoo"
            label="Анхны өртгийн өөрчлөлт хийсэн огноо"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <DatePicker format={dateFormat} customClass="ww-100 h-25" />
          </Form.Item>
          <Form.Item
            name="ashiglaj_duusah_uurchlult"
            label="Шинэчилсэн ашиглаж дуусах огноо"
            labelAlign="left"
          >
            <DatePicker format={dateFormat} customClass="ww-100 h-25" />
          </Form.Item>
          <Form.Item
            name="urtug_uldeh_uurchlult"
            label="Шинэчилсэн үлдэх өртөг"
            labelAlign="left"
            normalize={onlyNumber}
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input placeholder="Дүн оруулна уу..." className="w-100" />
          </Form.Item>
          <Form.Item
            name="dun_nemeh"
            label="Анхны өртөгт нэмэгдсэн дүн"
            labelAlign="left"
            normalize={onlyNumber}
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input
              onChange={onChangeUrtugNemeh}
              defaultValue="0.00"
              placeholder="Дүн оруулна уу..."
              className="w-100"
            />
          </Form.Item>
          <Form.Item
            name="dun_hasah"
            label="Анхны өртгөөс хасагдсан дүн"
            labelAlign="left"
            normalize={onlyNumber}
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input
              onChange={onChangeUrtugHasah}
              defaultValue="0.00"
              placeholder="Дүн оруулна уу..."
              className="w-100"
            />
          </Form.Item>
          <Form.Item
            name="dun_nemeh_elegdel"
            label="Хуримтлагдсан элэгдэлд нэмэгдсэн дүн"
            labelAlign="left"
            normalize={onlyNumber}
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input
              onChange={onChangeUrtugNemehElegdel}
              defaultValue="0.00"
              placeholder="Дүн оруулна уу..."
              className="w-100"
            />
          </Form.Item>
          <Form.Item
            name="dun_hasah_elegdel"
            label="Хуримтлагдсан элэгдлээс хасагдсан дүн"
            labelAlign="left"
            normalize={onlyNumber}
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input
              onChange={onChangeUrtugHasahElegdel}
              defaultValue="0.00"
              placeholder="Дүн оруулна уу..."
              className="w-100"
            />
          </Form.Item>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <Button
                  onClick={() => {
                    form.resetFields();
                    handleDialogClose();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </Button>
                <Button
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
