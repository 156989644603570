import React from 'react';
import { Form, Button, Row, Col } from 'antd';

export const ConfirmData = ({
  form = null,
  onCancel = () => {},
  confirmData = () => {},
}) => {
  const onFinish = () => {
    confirmData();
    onCancel();
  };
  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        size="small"
        className="register-form"
      >
        Та энэ үйлдлийг хийхдээ итгэлтэй байна уу?
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Тохиргоо хийх
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
