const token = localStorage.getItem('accessToken') || null;

const reducer = (state = token, action) => {
  switch (action.type) {
    case 'set_access_token': {
      localStorage.setItem('accessToken', action.payload);
      return action.payload;
    }
    case 'remove_access_token':
      localStorage.removeItem('accessToken');
      return null;
    default:
      return state;
  }
};

export default reducer;
