import React from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const UnAuth = () => {
  const history = useHistory();
  const toHome = () => {
    history.push('/main');
  };
  const extraButton = (
    <Button type="primary" onClick={toHome}>
      Үндсэн цэс рүү буцах
    </Button>
  );
  return (
    <div className="error-container">
      <Result
        status="403"
        title="403"
        subTitle="Уучлаарай та энэ цонхонд хандах эрхгүй байна."
        extra={extraButton}
      />
    </div>
  );
};

export default UnAuth;
