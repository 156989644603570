import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { roundFloat } from 'src/core/utils/formater';
import { customConfirm } from 'src/components/common/confirmModal';

const setFloat = (_) => roundFloat(_);

export const columns = ({ delete_row = () => {} }) => [
  {
    title: 'Огноо',
    align: 'center',
    dataIndex: 'ognoo',
    titleAlign: 'center',
    width: 100,
  },
  {
    title: 'Хүүгийн орлого олгосон харилцагчийн нэр',
    align: 'left',
    titleAlign: 'center',
    width: 150,
    dataIndex: 'hariltsagch_name',
  },
  {
    title: 'Татвар төлөгчийн дугаар',
    align: 'left',
    width: 100,
    titleAlign: 'center',
    dataIndex: 'ttd',
  },
  {
    title: 'Төрөл',
    align: 'left',
    titleAlign: 'center',
    dataIndex: 'hariltsagch_turul',
    width: 150,
  },
  {
    title: 'Хүүгийн зардлын дүн',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'dun',
    render: setFloat,
  },
  {
    title: 'Хүүгийн орлого олохтой холбогдон гарсан эсэх',
    align: 'center',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'type',
    render: (_) => {
      if (_ == 1) {
        return 'Тийм';
      }
      return 'Үгүй';
    },
  },
  {
    title: 'Устгах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    render: (_, row) => (
      <Tooltip title="Устгах">
        <DeleteOutlined
          onClick={() =>
            customConfirm({
              onOk: () => delete_row(row?.id),
            })
          }
        />
      </Tooltip>
    ),
  },
];

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'center',
    word: 'Нийт:',
  },
  {
    align: 'right',
    value: null,
  },
  {
    align: 'right',
    value: '',
  },
  {
    align: 'right',
    value: 'dun',
  },
  {
    align: 'right',
    value: null,
  },
  {
    align: 'right',
    value: null,
  },
];
