export const exampleColumn = [
  {
    title: 'Үзүүлэлт',
    dataIndex: 'uzuulelt',
    align: 'center',
    key: 'uzuulelt',
    render: (_, row, index) => {
      if (row?.title) {
        const obj = {
          children: row?.title,
          props: {},
        };
        obj.props.colSpan = 4;

        return obj;
      }
      return _;
    },
  },
  {
    title: 'Мөр',
    dataIndex: 'mur',
    align: 'left',
    key: 'mur',
    width: 50,
  },
  {
    title: 'Дүн',
    dataIndex: 'dun',
    titleAlign: 'center',
    align: 'center',
    key: 'dun',
    width: 150,
  },
];

export const expandColumns = ({ title = 'no Title' }) => [
  {
    key: 'title',
    align: 'center',
    render: (_, row, index) => {
      const obj = {
        children: title,
        props: {},
      };
      if (!index) {
        obj.props.rowSpan = 4;
      }
      if (index) {
        obj.props.rowSpan = 0;
      }
      return obj;
    },
  },
  {
    dataIndex: 'aldagdal',
    key: 'aldagdal',
    width: 300,
  },
  {
    dataIndex: 'jil',
    key: 'jil',
    width: 300,
  },
  {
    dataIndex: 'mur',
    align: 'center',
    key: 'mur',
    width: 50,
  },
  {
    dataIndex: 'dun',
    titleAlign: 'center',
    align: 'center',
    key: 'dun',
    width: 150,
  },
];

export const expandColumn1 = [
  {
    dataIndex: 'title',
    align: 'left',
    key: 'mur',
    width: 50,
    className: 'fw-600',
    render: (value) => {
      if (value) {
        const obj = {
          children: value,
          props: {},
        };
        obj.props.colSpan = 3;
        return obj;
      }
      return value;
    },
  },
  {
    dataIndex: 'lvl2',
    align: 'left',
    key: 'mur',
    width: 50,
    render: (value, row) => {
      if (row?.title) {
        const obj = {
          children: value,
          props: {},
        };
        obj.props.colSpan = 0;
        return obj;
      }
      if (value) {
        const obj = {
          children: value,
          props: {},
        };
        obj.props.colSpan = 2;
        return obj;
      }
      return value;
    },
  },
  {
    dataIndex: 'lvl3',
    align: 'left',
    key: 'mur',
    render: (value, row, index) => {
      if (row?.title) {
        const obj = {
          children: value,
          props: {},
        };

        obj.props.colSpan = 0;
        return obj;
      }
      if (row?.lvl2) {
        const obj = {
          children: value,
          props: {},
        };

        obj.props.colSpan = 0;
        return obj;
      }
      return value;
    },
  },
  {
    dataIndex: 'mur',
    align: 'center',
    key: 'mur',
    width: 50,
  },
  {
    dataIndex: 'dun',
    titleAlign: 'center',
    align: 'center',
    key: 'dun',
    width: 150,
  },
];
