import React from 'react';
import { roundFloat } from 'src/core/utils/formater';

// Constants
const BOLD_TITLES = [
  'Алдагдал шилжүүлэхэд тооцох татвар ногдох орлогын дүн',
  'Алдагдал шилжүүлэхэд хасаж тооцох зардал',
  'Алдагдлын шилжүүлэлтэнд хамаарах татвар ногдуулах орлогын дүн',
];

const IGNORE_TITLES = [
  'Алдагдал шилжүүлэхэд тооцох татвар ногдох орлого:',
  'Алдагдал шилжүүлэхэд хасаж тооцох зардал:',
];

// Helper Functions
const renderTitle = (row) => {
  if (BOLD_TITLES.includes(row.dans_ner)) {
    return <b style={{ fontWeight: 'bold' }}>{row.dans_ner}</b>;
  }
  return row.dans_ner;
};

const renderDansDun = (row) => {
  if (BOLD_TITLES.includes(row.dans_ner)) {
    return <b style={{ fontWeight: 'bold' }}>{roundFloat(row.dans_dun)}</b>;
  }
  if (row.title === 'test') {
    return '';
  }
  return roundFloat(row.dans_dun);
};

// Columns Configuration
export const aldagdalColumns = [
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    width: '15vw',
    editable: false,
    render: (_, row) => renderTitle(row),
  },
  {
    title: 'Дансны дүн',
    dataIndex: 'dans_dun',
    align: 'right',
    width: '5vw',
    editable: false,
    render: (_, row) => renderDansDun(row),
  },
];

// Summary Configuration
export const customSummary = [
  {
    align: 'center',
    word: 'Нийт:',
  },
  {
    align: 'right',
    value: 'dans_dun',
  },
];
