//
import React from 'react';
import {
  Button as AntButton,
  Form,
  Input,
  Select as AntSelect,
  Row,
  Col,
  Divider,
  InputNumber,
} from 'antd';

import { onFilter } from 'src/core/utils/selectFilter';
import { onlyNumber } from 'src/core/utils/formater';

const { Option } = AntSelect;

export default function RegisterModal({
  dictionaryValues,
  modalValues,
  actionType,
  handleSave,
  onClose = () => {},
}) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    handleSave(values, actionType);
    form.resetFields();
    onClose();
  };

  const seTcountryTtd = (code) => {
    const hariltsgach = dictionaryValues.hariltsagch_list.find(
      (x) => x.code === code
    );
    if (hariltsgach) {
      form.setFieldsValue({
        ttd: hariltsgach?.ttd,
      });
    }
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const layout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 17 },
    },
  };
  if (modalValues) form.setFieldsValue(modalValues);
  return (
    <div className="hariltsagch-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        size="small"
        initialValues={{
          hariltsagch_code: null,
          turul_id: null,
        }}
        {...layout}
      >
        <Form.Item
          name="hariltsagch_code"
          label="Харилцагч"
          rules={[
            {
              required: true,
              message: 'Харилцагч сонгоно уу!',
            },
          ]}
        >
          <AntSelect
            showSearch
            disabled={actionType === 'edit'}
            onFilter={onFilter}
            onChange={seTcountryTtd}
            placeholder="Бүртгэлтэй харилцагчаас сонгоно уу!"
          >
            {dictionaryValues.hariltsagch_list.map((x) => (
              <Option value={x.code}>
                {x.code} - {x.ner}
              </Option>
            ))}
          </AntSelect>
        </Form.Item>

        <Form.Item name="ttd" label="Регистрийн дугаар">
          <Input className="w-30" disabled />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Төрөл сонгоно уу',
            },
          ]}
          name="turul_id"
          label="Төрөл"
        >
          <AntSelect placeholder="Сонгох">
            {dictionaryValues.turul.map((x) => (
              <Option value={x.id}>{x.ner}</Option>
            ))}
          </AntSelect>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Хаяг оруулна уу!',
            },
          ]}
          name="hayg"
          label="Хаяг"
        >
          <Input className="w-100" maxLength={200} showCount />
        </Form.Item>

        <Divider />

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Хувь нийлүүлсэн хөрөнгийн хэмжээ оруулна уу!',
            },
          ]}
          name="hurungu_amount"
          label="Хувь нийлүүлсэн хөрөнгийн хэмжээ"
        >
          <InputNumber
            className="w-30"
            normalize={onlyNumber}
            defaultValue={0}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Эзэмшиж буй хувьцааны тоо оруулна уу!',
            },
          ]}
          name="huvitsaa_amount"
          label="Эзэмшиж буй хувьцааны тоо"
        >
          <InputNumber
            className="w-30"
            normalize={onlyNumber}
            defaultValue={0}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Нэгж хувьцааны үнэ оруулна уу!',
            },
          ]}
          name="huvitsaa_price"
          label="Нэгж хувьцааны үнэ"
        >
          <InputNumber
            className="w-30"
            normalize={onlyNumber}
            defaultValue={0}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Эзэмшлийн хувь оруулна уу!',
            },
          ]}
          name="ezemshliin_huvi"
          label="Эзэмшлийн хувь (%)"
        >
          <InputNumber
            className="w-30"
            max={100}
            defaultValue={0}
            normalize={onlyNumber}
          />
        </Form.Item>

        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item noStyle>
              <AntButton
                onClick={onCancel}
                className="cancel-btn mr-5"
                type="primary"
                shape="round"
              >
                Буцах
              </AntButton>
              <AntButton
                className="confirm-btn"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </AntButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
