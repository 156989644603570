import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Button, Row, Col, Modal, Tag, Tooltip } from 'antd';
import {
  MinusCircleOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  SmileTwoTone,
} from '@ant-design/icons';

import { get } from 'lodash';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from '../../core/actions/loader';
import InsertAvgPercentModal from './insertAvgPercentModal';

const { Text, Title } = Typography;

export default function FirstBalanceAvgPer() {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);
  const data_date = useSelector((state) => state.data_date);

  const [avgPercent, setAvgPercent] = useState(0);
  const [avgPercentList, setAvgPercentList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const tagList = {
    0: {
      text: 'Идэвхгүй',
      icon: <MinusCircleOutlined />,
    },
    1: {
      text: 'Идэвхтэй',
      icon: <CheckCircleOutlined />,
      color: '#02c0df',
    },
  };

  const handleChangeStatus = (is_active, is_calculated) => {
    if (is_active !== '1') {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');

        if (data.content.status === '0') {
          setAvgPercentList(data.content.json_array);
        }

        dispatch(end_loading({}));
      });

      socket.emit('request_in', {
        channel: 'change_avg_per_status',
        content: {
          month: '13',
          is_first_year: true,
          is_calculated,
        },
      });
      dispatch(start_loading({}));
    }
  };

  const getTag = (row) => {
    const props = get(tagList, `${row.is_active}`, null);
    if (props) {
      return row?.can_activate ? (
        <div style={{ display: 'flex', marginTop: '5px', textAlign: 'center' }}>
          <Tag
            style={{
              width: 120,
            }}
            {...props}
          >
            {' '}
            {props?.text}
          </Tag>
          {row?.is_active === '1' ? (
            <Tooltip title="Идэвхтэй">
              <SmileTwoTone
                twoToneColor="#02c0df"
                style={{
                  fontSize: 22,
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Идэвхжүүлэх">
              <CheckCircleTwoTone
                twoToneColor="#4BB543"
                style={{
                  fontSize: 22,
                }}
                onClick={
                  () => handleChangeStatus(row?.is_active, row?.is_calculated)
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            </Tooltip>
          )}
        </div>
      ) : (
        <></>
      );
    }
    return null;
  };

  const header_list = [
    { value: 'Төрөл', span: 10 },
    { value: 'Дундаж хувь хэмжээ', span: 4 },
    { value: 'Төлөв', span: 4 },
    { value: 'Үйлдэл', span: 4 },
  ];

  const handleSave = (param) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0') {
        setOpenDialog(false);
        socket.on('request_out', (data_inner) => {
          socket.removeAllListeners('request_out');

          if (data_inner.content.status === '0') {
            setAvgPercentList(data_inner.content.json_array);
            newAlert({
              type: 'success',
              msg: 'Дундаж хувь хэмжээ хадгалагдлаа',
            });
          } else {
            newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
          }

          dispatch(end_loading({}));
        });
        socket.emit('request_in', {
          channel: 'get_avg_per_list',
          content: {
            month: '13',
            type: 'manual',
            is_first_year: true,
          },
        });
        dispatch(start_loading({}));
      } else {
        dispatch(end_loading({}));
      }
    });

    socket.emit('request_in', {
      channel: 'register_avg_per',
      content: {
        avg_per: param,
        month: '13',
        is_first_year: true,
      },
    });
    dispatch(start_loading({}));
  };

  const handleOpenDialog = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      // console.log('data', data)
      if (data.content.status === '0') {
        setAvgPercent(data.content.avg_per);
        setOpenDialog(true);
      } else {
        setAvgPercent(10);
        setOpenDialog(true);
      }
      dispatch(end_loading({}));
    });

    socket.emit('request_in', {
      channel: 'get_avg_per',
      content: {
        month: '13',
        is_first_year: true,
        type: 'manual',
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    socket.on('request_out', (data) => {
      if (data.content.status === '0') {
        setAvgPercentList(data.content.json_array);
      }
      socket.removeAllListeners('request_out');
      dispatch(end_loading({}));
    });

    socket.emit('request_in', {
      channel: 'get_avg_per_list',
      content: {
        month: '13',
        type: 'manual',
        is_first_year: true,
      },
    });
    dispatch(start_loading({}));
  }, [data_date.month]);

  return (
    <>
      {' '}
      <Modal
        className="custom-modal"
        closable={false}
        maskClosable={false}
        width={400}
        footer={null}
        open={openDialog}
        title="Дундаж хувь хэмжээ бүртгэх"
      >
        <InsertAvgPercentModal
          avgPercent={avgPercent}
          onCancel={() => {
            setOpenDialog(false);
          }}
          handleSave={handleSave}
        />
      </Modal>
      <div className="avg-per-container">
        <div className="avg-per-title">
          <Title level={5}>Дундаж хувь хэмжээ</Title>
        </div>
        <Row className="avg-per-header">
          {header_list.map((i) => (
            <Col span={i.span}>
              <Text type="secondary" strong>
                {i.value}
              </Text>
            </Col>
          ))}
        </Row>
        {avgPercentList.map((x, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="avg-per-body">
            <Row>
              <Col span={10}>
                <Text strong>
                  {x.is_calculated === '1'
                    ? 'Тооцоолсон татварын дундаж хувь'
                    : 'Гараар оруулсан татварын дундаж хувь'}
                </Text>
              </Col>
              <Col span={4}>
                <Text className="fw-600" variant="subtitle2">
                  {x.percent} %
                </Text>
              </Col>
              <Col span={4}>{getTag(x)}</Col>
              <Col span={4}>
                {x.is_calculated === '0' ? (
                  <Button
                    className="confirm-btn"
                    type="primary"
                    onClick={handleOpenDialog}
                    size="small"
                    shape="round"
                    style={{ width: '100px' }}
                    disabled={!version?.is_first_year}
                  >
                    Бүртгэх
                  </Button>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </>
  );
}
