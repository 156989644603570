import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Form, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { start_loading, end_loading } from 'src/core/actions/loader';
import Table from 'src/components/common/table';
import { roundFloat } from 'src/core/utils/formater';
import { columns } from './dataStructure/data';
import NewValueAdd from './dansHolbohNew';

export default function Client() {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const [form] = Form.useForm();
  const [visible, seTvisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, seTloading] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const handleValueOpen = () => {
    seTvisible(true);
  };

  const handleValueClose = () => {
    seTvisible(false);
    form.resetFields();
  };

  function check_zardal_type(zaalt_id, hasagdah_dun, hasagdahgui_dun) {
    console.log('==================================');
    form.setFieldsValue({
      hasagdah_dun: roundFloat(hasagdah_dun),
      hasagdahgui_dun: roundFloat(hasagdahgui_dun),
      zaalt_id,
    });
    handleValueOpen();
  }

  const mainData = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      if (status === '0') {
        const new_temp_data = content?.map((inner_data, key) => ({
          id: key + 1,
          ...inner_data,
        }));
        if (new_temp_data) {
          setTableData(new_temp_data);
        }
        console.log('Getting dans table list');
        dispatch(end_loading({}));
      } else {
        dispatch(end_loading({}));
      }

      seTloading(false);
    });
    socket.emit('request_in', {
      channel: 'ts004_00_get_zardal_main_data',
      content: { month: data_date.month },
    });

    dispatch(start_loading({}));
  };
  useEffect(() => {
    console.log('>> Getting old list...');
    mainData();
  }, [data_date.month]);

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin indicator={antIcon} />;
      </div>
    );
  }

  return (
    <>
      <Modal
        className="custom-modal"
        title="Хязгаартай зардлын журнал холбох"
        visible={visible}
        closable={false}
        maskClosable={false}
        width={700}
        footer={null}
      >
        <NewValueAdd
          form={form}
          visible={visible}
          close={handleValueClose}
          socket={socket}
          dispatch={dispatch}
          refetch={mainData}
          month={data_date.month}
        />
      </Modal>

      <div className="table-antd-div zardal-container-niit">
        <Table
          columns={columns({
            check_zardal_type,
          })}
          dataSource={tableData}
          scroll={{
            x: 'auto',
          }}
        />
      </div>
    </>
  );
}
