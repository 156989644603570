// eslint-disable-next-line import/no-extraneous-dependencies
import { io } from 'socket.io-client';

const initialState = null;

const reducer = (state = initialState, action) => {
  const query_jwt = localStorage.taxjwt ? `token=${localStorage.taxjwt}` : '';

  switch (action.type) {
    case 'WS_CONNECT':
      if (!state) {
        const options = {
          withCredentials: true,
          secure: true,
          reconnection: true,
          reconnectionDelay: 500,
          reconnectionAttempts: 10,
          transports: ['websocket'],
        };

        // Create the socket connection
        const socket = io(`${action.payload.ip}?${query_jwt}`, {
          ...options,
          query: { device_token: localStorage.getItem('device_token') },
        });

        // Log socket events for debugging
        socket.on('connect', () => {
          console.log('WebSocket connected:', socket.id);
        });

        socket.on('disconnect', (reason) => {
          console.log('WebSocket disconnected:', reason);
        });

        socket.on('connect_error', (error) => {
          console.error('Connection error:', error);
        });

        socket.on('reconnect_attempt', () => {
          console.log('Attempting to reconnect...');
        });

        socket.on('reconnect_failed', () => {
          console.error('Reconnection failed');
        });

        socket.on('error', (error) => {
          console.error('WebSocket error:', error);
        });

        return socket;
      }
      return state;

    case 'WS_DISCONNECT':
      if (state !== null) {
        state.disconnect();
      }
      return null;

    default:
      return state;
  }
};

export default reducer;
