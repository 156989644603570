import React, { useEffect, useState } from 'react';
import { isArray } from 'lodash';
import Tour from 'reactour';
import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { set_step, force_stop, is_empty } from 'src/core/actions/hintStep';
import { tourList } from './data';

const CustomTour = ({ location = '' }) => {
  const dispatch = useDispatch();

  const [isTourOpen, setIsTourOpen] = useState(false);
  const [stepList, seTstepList] = useState([]);

  const { _step, forceStop, isEmpty } = useSelector((state) => ({
    _step: state?.hintStep,
    forceStop: state?.force_stop,
    isEmpty: state?.is_empty,
  }));

  const [show, setShow] = useState(false);

  const checker = (el) => {
    if (el && el?.[0] && isArray(el?.[0])) {
      return true;
    }
    return false;
  };

  const isWatched = (id = null, _forceStop = false) => {
    if (_forceStop) {
      return true;
    }
    const _list = JSON.parse(localStorage.getItem('watchedId'));
    return !_list || !_list.includes(id);
  };

  // useEffect(() => {
  //   if (location) {
  //     const finded = tourList.find((t) => t?.path === location);
  //     const element = finded?.list;
  //     if (element) {
  //       if (checker(element)) {
  //         seTstepList(element[_step]);
  //         setIsTourOpen(true);
  //       }
  //     }
  //   }
  // }, [location]);

  const onClose = (
    id
    // isStepped = true
  ) => {
    // if (isStepped) dispatch(set_step(null));
    dispatch(force_stop(false));
    setIsTourOpen(false);
    const _list = JSON.parse(localStorage.getItem('watchedId'));
    // eslint-disable-next-line no-nested-ternary
    const savedList = _list
      ? _list.includes(id)
        ? _list
        : [..._list, id]
      : [id];
    localStorage.setItem('watchedId', JSON.stringify(savedList));
  };

  useEffect(() => {
    setShow(true);
    dispatch(is_empty(false));
    setTimeout(() => setShow(false), 400);
    const finded = tourList.find((t) => t?.path === location);
    const element = finded?.list;
    if (element && !isEmpty) {
      if (checker(element) && isWatched(finded?.id, forceStop)) {
        if (_step + 1 === element.length) {
          seTstepList(
            element[_step].map((i, idx) => ({
              selector: i?.selector,
              content: ({ goTo, inDOM }) => (
                <div className="tour-modal">
                  <div className="header">
                    <span />
                  </div>
                  <div className="body">
                    <span color="#e5e5e5">{i?.content}</span>
                    {idx + 1 === element[_step].length && (
                      <Button
                        className="btn"
                        type="primary"
                        shape="round"
                        size="small"
                        onClick={() => {
                          onClose(finded?.id, true);
                        }}
                      >
                        Хаах
                      </Button>
                    )}
                  </div>
                </div>
              ),
            }))
          );
        } else {
          seTstepList(
            element[_step].map((k) => ({
              selector: k?.selector,
              content: ({ goTo, inDOM }) => (
                <div className="tour-modal">
                  <div className="header">
                    <span />
                  </div>
                  <div className="body">
                    <span color="#e5e5e5">{k?.content}</span>
                  </div>
                </div>
              ),
            }))
          );
        }
        setIsTourOpen(true);
      } else if (isWatched(finded?.id, forceStop)) {
        seTstepList(
          element.map((i, idx) => ({
            selector: i?.selector,
            content: ({ goTo, inDOM }) => (
              <div className="tour-modal">
                <div className="header">
                  <span />
                </div>
                <div className="body">
                  {i?.content}
                  {idx + 1 === element.length && (
                    <Button
                      className="btn"
                      type="primary"
                      size="small"
                      shape="round"
                      onClick={() => {
                        onClose(finded?.id);
                      }}
                    >
                      Хаах
                    </Button>
                  )}
                </div>
              </div>
            ),
          }))
        );
        setIsTourOpen(true);
      }
    }
  }, [location, _step, forceStop]);

  useEffect(() => {
    if (_step > 0) {
      dispatch(set_step(0));
    }
  }, [location]);

  useEffect(() => {
    if (isEmpty) {
      const finded = tourList.find((t) => t?.path === location);
      const element = finded?.list;
      if (checker(element) && _step === 0) {
        const _element = element[0];

        seTstepList(
          [_element[0]].map((i) => ({
            selector: i?.selector,
            content: () => (
              <div className="tour-modal">
                <div className="header">
                  <span />
                </div>
                <div className="body">
                  {i?.content}
                  <Button
                    className="btn"
                    type="primary"
                    size="small"
                    shape="round"
                    onClick={() => {
                      setIsTourOpen(false);
                      dispatch(force_stop(false));
                    }}
                  >
                    Хаах
                  </Button>
                </div>
              </div>
            ),
          }))
        );

        setIsTourOpen(true);
      } else {
        setIsTourOpen(false);
      }
    }
  }, [isEmpty]);

  if (show) return '';
  const test = {
    shape: 'round',
    size: 'small',
  };

  const next = <antbutton {...test}>Буцах</antbutton>;
  const prev = (
    <antbutton shape="round" size="small">
      Дараах
    </antbutton>
  );

  return (
    <Tour
      className="tour"
      steps={stepList}
      accentColor="#02c0df"
      isOpen={false}
      showNumber={false}
      showCloseButton={false}
      // goToStep={step}
      prevButton={next}
      nextButton={prev}
      maskClassName="mark"
      onRequestClose={() => setIsTourOpen(true)}
      // onRequestClose={() => setIsTourOpen(false)}
    />
  );
};

export default CustomTour;
