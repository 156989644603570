import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spin, Input } from 'antd';
import { CalculatorOutlined, LoadingOutlined } from '@ant-design/icons';
import CanView from 'src/components/permission/canview';
import Table from 'src/components/common/table';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { onlyNumber, roundFloat } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import { aldagdalColumns } from './dataStructure/data';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function Client() {
  const dispatch = useDispatch();

  const columns = aldagdalColumns;

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const [tableData, setTableData] = useState([]);
  const [shiljuulehDun, seTshiljuulehDun] = useState(0);
  const [loading, seTloading] = useState(true);

  const mainData = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      if (status === '0') {
        seTshiljuulehDun(roundFloat(content?.shiljuuleh_dun));
        const new_temp_data = content?.niitleg_tatvar.map(
          (inner_data, key) => ({
            id: key + 1,
            ...inner_data,
          })
        );
        const title = {
          title: 'test',
          dans_ner: 'Алдагдал шилжүүлэхэд тооцох татвар ногдох орлого:',
          dans_dun: '',
          id: 1,
        };
        new_temp_data.unshift(title);
        const new_temp_data_2 = content?.hasagdah_zardal.map(
          (inner_data, key) => ({
            id: key + 1,
            ...inner_data,
          })
        );
        const hasagdah_zardal = {
          title: 'test',
          dans_ner: 'Алдагдал шилжүүлэхэд хасаж тооцох зардал:',
          dans_dun: '',
          id: 2,
        };
        new_temp_data_2.unshift(hasagdah_zardal);

        if (new_temp_data.length > 2) {
          setTableData(new_temp_data.concat(new_temp_data_2));
        } else {
          const new_row_data = [
            {
              dans_ner: 'Дансны дүн хоосон байна',
              dans_dun: '',
              id: 1,
            },
          ];
          setTableData(new_row_data);
        }
        seTloading(false);
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts007_02_get_data_from_shalgah_balance',
      content: { month_end: data_date.month },
    });
    dispatch(start_loading({}));
  };

  const save_shiljuuleh_dun = (dun) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: mainData() },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_02_manage_aldagdal_data',
      content: {
        month: data_date.month,
        shiljuuleh_dun: dun,
        action_type: 'save_shiljuuleh_dun',
      },
    });
    dispatch(start_loading({}));
  };
  const title = () => <></>;

  useEffect(() => {
    console.log('>> Getting old list...');
    mainData();
  }, [data_date.month]);

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin indicator={antIcon} />;
      </div>
    );
  }

  return (
    <>
      <div className="table-antd-div" style={{ maxWidth: '60%' }}>
        <Table
          columns={columns}
          dataSource={tableData}
          scroll={{
            x: 'auto',
          }}
          title={title}
        />{' '}
        <CanView allowedRole="003_1">
          <div className="aldagdal-shilj-container">
            <div className="text">Шилжүүлэх дүн</div>
            <Input
              className="none-border-input cursor-pointer aldagdal_input"
              bordered={false}
              defaultValue={shiljuulehDun}
              onChange={({ target }) => {
                seTshiljuulehDun(onlyNumber(target.value));
              }}
            />
            <Button
              id="first"
              className="add-btn aldagdal-btn"
              type="primary"
              size="small"
              icon={<CalculatorOutlined />}
              onClick={() => {
                save_shiljuuleh_dun(shiljuulehDun);
              }}
            >
              Алдагдал шилжүүлэх
            </Button>
          </div>
        </CanView>
      </div>
    </>
  );
}
