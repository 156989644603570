import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button as AntButton, Form, Row, Col, Input, Modal } from 'antd';
import { onlyNumber } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from '../../core/actions/loader';

export const ElegdelHuvaarilalt = ({
  onCancel = () => {},
  visible,
  butgelData,
}) => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...butgelData,
        hurumt_elegdel_capital_zasvar: 0.0,
        hurumt_elegdel_du_nemegdel: 0.0,
        ognoo: butgelData.ognoo,
      });
    }
  }, [visible]);

  const handleSave = (values) => {
    socket.on('request_out', (req_data) => {
      socket.removeAllListeners('request_out');
      if (req_data.status === '0') {
        newAlert({ type: 'success', msg: req_data.message });
        onCancel();
      } else {
        newAlert({ type: 'error', msg: req_data.message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: {
        action_type: 'insert_elegdel_huvaarilalt',
        json_array: { ...values },
      },
    });
    dispatch(start_loading({}));
  };

  const onFinish = (values) => {
    handleSave(values);
  };

  const layout = {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 16 },
    },
  };

  return (
    <Modal
      className="custom-modal"
      title="Элэгдэл хуваарилалт"
      open={visible}
      closable={false}
      maskClosable={false}
      width={800}
      footer={null}
      onCancel={onCancel}
    >
      <div className="dans-medeelel-container">
        <Form
          form={form}
          onFinish={onFinish}
          className="register-form"
          {...layout}
          size="small"
          initialValues={{ remember: true }}
        >
          <Form.Item label="Үндсэн хөрөнгө" labelAlign="left">
            {butgelData?.ner}
          </Form.Item>
          <Form.Item label="Үндсэн хөрөнгийн код" labelAlign="left">
            {butgelData?.code}
          </Form.Item>
          <Form.Item
            name="burtgel_dugaar"
            label="Бүртгэлийн дугаар"
            values={butgelData?.burtgel_dugaar}
            labelAlign="left"
          >
            {butgelData?.burtgel_dugaar}
          </Form.Item>
          <Form.Item
            name="ognoo"
            label="Огноо"
            values={butgelData?.ognoo}
            labelAlign="left"
          >
            {butgelData?.ognoo}
          </Form.Item>
          <Form.Item
            name="hurumt_elegdel_capital_zasvar"
            label="Их засвар"
            normalize={onlyNumber}
            labelAlign="left"
          >
            <Input placeholder="Дүн оруулна уу..." className="w-100" />
          </Form.Item>
          <Form.Item
            name="hurumt_elegdel_du_nemegdel"
            label="Дахин үнэлгээ"
            normalize={onlyNumber}
            labelAlign="left"
          >
            <Input placeholder="Дүн оруулна уу..." className="w-100" />
          </Form.Item>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <AntButton
                  onClick={() => {
                    form.resetFields();
                    onCancel();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </AntButton>
                <AntButton
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </AntButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
