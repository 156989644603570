import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Tooltip } from 'antd';
import { roundFloat } from 'src/core/utils/formater';
import { customConfirm } from 'src/components/common/confirmModal';

const setRound = (_) => roundFloat(_);
export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'dun',
  },
  {
    align: 'right',
    value: null,
  },
  {
    align: 'right',
    value: null,
  },
  {
    align: 'right',
    value: null,
  },
  {
    align: 'right',
    value: null,
  },
];

export const columns = ({
  delete_row = () => {},
  send_confirm_row_id = () => {},
}) => [
  {
    title: 'Огноо',
    align: 'center',
    dataIndex: 'ognoo',
    titleAlign: 'center',
    width: 100,
  },
  {
    title: 'Дүгнэлт гаргасан байгууллагын нэр',
    align: 'left',
    titleAlign: 'center',
    className: 'fixed-100',
    dataIndex: 'dugnelt_gargasan_company_ner',
  },
  {
    title: 'Хохирлын дүн',
    align: 'right',
    className: 'children-50',
    titleAlign: 'center',
    dataIndex: 'hohirol_dun',
    render: setRound,
  },
  {
    title: 'Тайлбар',
    align: 'left',
    className: 'fixed-200',
    titleAlign: 'center',
    dataIndex: 'tailbar',
  },
  {
    title: 'Дүгнэлт хавсаргах',
    className: 'children-100',
    align: 'left',
    titleAlign: 'left',
    render: (_, row) => (
      <>
        <Tooltip title={row?.dugnelt_file_name}>
          <Popconfirm
            title="Файл татах уу?"
            okText="Тийм"
            cancelText="Үгүй"
            onConfirm={() =>
              send_confirm_row_id(row.file_id, row.dugnelt_file_name)
            }
          >
            <div className="link-wrap"> {row.dugnelt_file_name}</div>
          </Popconfirm>
        </Tooltip>
      </>
    ),
  },

  {
    title: 'Устгах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    render: (_, row) => (
      <Tooltip title="Устгах">
        <DeleteOutlined
          onClick={() =>
            customConfirm({
              onOk: () => delete_row(row?.id),
            })
          }
        />
      </Tooltip>
    ),
  },
];
