import React, { useEffect, useState, useRef } from 'react';
import { Input, Button, List, Avatar, Popover, Badge } from 'antd';
import { useSelector } from 'react-redux';
import { SendOutlined, InboxOutlined, UserOutlined } from '@ant-design/icons';
// import jwt from 'jwt-decode';

// Import your MP3 file
// import messageSound from '../../assets/mp3/chat.mp3';

const ChatComponent = () => {
  const { socket } = useSelector((state) => ({ socket: state.socket }));

  const [message, setMessage] = useState('');
  const [visible, setVisible] = useState(false); // Track popover visibility
  const [messages, setMessages] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState(0); // State for tracking unread messages
  const [totalMessages, setTotalMessages] = useState(0); // Number of initially loaded messages

  const messageListRef = useRef(null);

  const fetchMoreMessages = () => {
    socket.emit(
      'fetch_more',
      {
        content: {
          existing: messages.length,
        },
      },
      (response) => {
        const new_temp_data = [];
        response.chat_data.forEach((e) => {
          new_temp_data.push({
            ...e,
            sender: e.user_name,
            content: e.msg,
          });
        });
        setMessages((prevMessages) => [...new_temp_data, ...prevMessages]);
        setTotalMessages(response.total);
      }
    );
  };

  useEffect(() => {
    socket.on('chat_out', (data) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: data.msg, sender: data.sender },
      ]);
      setTotalMessages(totalMessages + 1);
      setUnreadMessages(unreadMessages + 1);
    });
  }, []); // Added visible to the dependency array

  // Play sound only when chat is hidden
  // if (!visible) {
  //   new Audio(messageSound).play();
  // }

  useEffect(() => {
    if (visible) {
      // setMessages([]);
      socket.on('chat_response', ({ chat_data, total }) => {
        const new_temp_data = [];
        chat_data.forEach((e) => {
          new_temp_data.push({
            ...e,
            sender: e.user_name,
            content: e.msg,
          });
        });
        setMessages(new_temp_data);
        setTotalMessages(total);
      });
      socket.emit('chat_fetch', {
        content: {
          existing: messages.length,
        },
      });
    }
  }, [visible]); // Added visible to the dependency array

  const handleSendMessage = () => {
    if (message.trim() !== '') {
      socket.emit('chat_in', {
        content: { msg: message },
      });
      setMessage('');
    }
  };

  // const handleDeleteMessage = (index) => {
  //   const updatedMessages = [...messages];
  //   updatedMessages.splice(index, 1);
  //   setMessages(updatedMessages);
  // };

  const handleVisibleChange = (isVisible) => {
    setVisible(isVisible);
    if (isVisible) {
      setUnreadMessages(0); // Reset unread messages count when popover is opened
    }
  };

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="chat-icon-container">
      <Popover
        placement="topRight"
        // title={`Уншаагүй (${unreadMessages}) чат`}
        content={(
          <div className="chat-container">
            {messages.length < totalMessages && (
              <div className="load-more-container">
                <Button type="primary" onClick={fetchMoreMessages}>
                  Өмнөх чат унших
                </Button>
              </div>
            )}
            <List
              className="message-list"
              itemLayout="horizontal"
              dataSource={messages}
              renderItem={(item) => (
                <List.Item className={`message-item ${item.sender}`}>
                  <List.Item.Meta
                    avatar={(
                      <Avatar>
                        <UserOutlined />
                      </Avatar>
                    )}
                    title={item.sender}
                    description={item.content}
                  />
                </List.Item>
              )}
              ref={messageListRef}
            />
            <div className="input-container">
              <Input
                className="input-message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onPressEnter={handleSendMessage}
                placeholder="Энд бичнэ үү..."
              />
              <Button
                type="primary"
                onClick={handleSendMessage}
                icon={<SendOutlined />}
              />
            </div>
          </div>
        )}
        trigger="click"
        open={visible}
        onOpenChange={handleVisibleChange}
      >
        <Badge count={unreadMessages} offset={[-8, 8]}>
          <Button
            type="primary"
            shape="circle"
            className="chat-starter"
            icon={(
              <span role="img" aria-label="chat">
                <InboxOutlined style={{ fontSize: 40 }} />
              </span>
            )}
          />
        </Badge>
      </Popover>
    </div>
  );
};

export default ChatComponent;
