export const searchList = [
  {
    type: 'input',
    title: 'Дансны дугаар',
    children: {
      queryKey: 'dans_dugaar',
    },
  },
  {
    type: 'input',
    title: 'Хөрөнгийн код, нэр',
    children: {
      queryKey: 'hurungu',
    },
  },
  {
    type: 'select',
    title: 'Холбосон',
    children: {
      queryKey: 'holbolt',
      list: [
        {
          value: 'Бүгд',
          name: 'Бүгд',
        },
        {
          value: 'Холбосон',
          name: 'Холбосон',
        },
        {
          value: 'Холбоогүй',
          name: 'Холбоогүй',
        },
      ],
    },
  },
];
