import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { EditOutlined } from '@ant-design/icons';
import { Button as AntButton } from 'antd';

import CanView from 'src/components/permission/canview';

export const customSummary = [
  {},
  {},
  {
    align: 'right',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'dans_une',
  },
  {
    align: 'right',
    value: 'tatvar_suuri',
  },
  {
    align: 'right',
    value: 'zuruu',
  },
  {
    align: 'right',
    value: 'zuvshuurugduhgui_turuu_zur_hasagdah',
  },
  {
    align: 'right',
    value: 'zuvshuurugduhgui_turuu_zur_tatvar_nogdoh',
  },
  {
    align: 'right',
    value: 'turuu_zur_hasagdah',
  },
  {
    align: 'right',
    value: 'turuu_zur_tatvar_nogdoh',
  },
];

export const customDansSummary = [
  {
    align: 'right',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'dans_une',
  },
  {
    align: 'right',
    value: 'tatvar_suuri',
  },
  {
    align: 'right',
    value: 'zuruu',
  },
  {
    align: 'right',
    value: 'zuvshuurugduhgui_turuu_zur_hasagdah',
  },
  {
    align: 'right',
    value: 'zuvshuurugduhgui_turuu_zur_tatvar_nogdoh',
  },
  {
    align: 'right',
    value: 'turuu_zur_hasagdah',
  },
  {
    align: 'right',
    value: 'turuu_zur_tatvar_nogdoh',
  },
];

export const ColumnsDansMain = () => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Дансны үнэ',
    dataIndex: 'dans_une',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'tatvar_suuri',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Зөрүү',
    dataIndex: 'zuruu',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Түр зөрүүгээр хүлээн зөвшөөрөхгүй зөрүү',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'zuvshuurugduhgui_turuu_zur_hasagdah',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'zuvshuurugduhgui_turuu_zur_tatvar_nogdoh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Түр зөрүү',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'turuu_zur_hasagdah',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'turuu_zur_tatvar_nogdoh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];

export const ColumnsMain = ({ onSelectHurungu = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    align: 'left',
    width: 120,
    fixed: 'left',
    render: (_, row) => (
      <AntButton
        align="center"
        type="link"
        size="small"
        className="underline"
        onClick={() => onSelectHurungu(row)}
      >
        {row?.code}
      </AntButton>
    ),
  },
  {
    title: 'Хөрөнгийн нэр',
    dataIndex: 'ner',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Дансны үнэ',
    dataIndex: 'dans_une',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'tatvar_suuri',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Зөрүү',
    dataIndex: 'zuruu',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Түр зөрүүгээр хүлээн зөвшөөрөхгүй зөрүү',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'zuvshuurugduhgui_turuu_zur_hasagdah',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'zuvshuurugduhgui_turuu_zur_tatvar_nogdoh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Түр зөрүү',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'turuu_zur_hasagdah',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'turuu_zur_tatvar_nogdoh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];

export const ColumnsBurtgel = ({ openModal = () => {} }) => [
  {
    title: 'Бүртгэлийн дугаар',
    dataIndex: 'burtgel_dugaar',
    width: 150,
    fixed: 'left',
  },
  {
    title: 'Дансны үнэ',
    dataIndex: 'dans_une',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'tatvar_suuri',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Зөрүү',
    dataIndex: 'zuruu',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
  {
    title: 'Түр зөрүүгээр хүлээн зөвшөөрөхгүй зөрүү',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'zuvshuurugduhgui_turuu_zur_hasagdah',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'zuvshuurugduhgui_turuu_zur_tatvar_nogdoh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: '',
        align: 'center',
        width: 40,
        render: (_, row) => (
          <>
            <CanView allowedRole="006_1">
              {
                // eslint-disable-next-line operator-linebreak
                row.burtgel_dugaar !== '' &&
                  Math.round(Number(row.zuruu)) !== 0 && (
                    <EditOutlined onClick={() => openModal(row)} />
                    // eslint-disable-next-line indent
                  )
              }
            </CanView>
          </>
        ),
      },
    ],
  },
  {
    title: 'Түр зөрүү',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'turuu_zur_hasagdah',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'turuu_zur_tatvar_nogdoh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];
