import React from 'react';
import { Button, Form, Row, Col, Modal, Alert } from 'antd';
import { Table } from 'src/components';
import { ErrorListColumnshurungu, ErrorListColumnsDans } from './dataStructure/data';

export const ErrorListHurungu = ({
  onCancel = () => {},
  errorList = {
    error_type: 'hurungu',
    have_error: false,
    errors: [],
    burtgel_errors: {},
  },
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      className="custom-modal"
      title={errorList.error_type !== 'hurungu'
        ? 'Үндсэн хөрөнгийн дансаа холбо уу!'
        : 'Орлогогүй, бүртгэлгүй хөрөнгө'}
      open={errorList.have_error}
      closable={false}
      maskClosable={false}
      width="500px"
      footer={null}
      onCancel={onCancel}
    >
      <div className="dans-medeelel-container">
        <Form
          form={form}
          className="register-form"
          size="small"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Alert
            message={errorList.error_type !== 'hurungu'
              ? 'Түр зөрүү тооцохоор ангилсан дансуудын "Данс холболт - Үндсэн хөрөнгө" тохиргоог хийнэ үү'
              : "Үндсэн мэдээлэл > Үндсэн хөрөнгийн мэдээлэл 'Үндсэн хөрөнгийн код шинэчлэх' дээр өөрчлөлтөө оруулна уу!!"}
            type="error"
            style={{ Margin: '10' }}
          />
          <>
            {errorList.error_type !== 'hurungu'
              ? (
                <Table
                  columns={ErrorListColumnsDans()}
                  dataSource={errorList?.errors}
                  scroll={{
                    y: 'calc(100vh - 330px)',
                  }}
                />
              ) : (
                <Table
                  columns={ErrorListColumnshurungu()}
                  dataSource={errorList?.errors}
                  scroll={{
                    y: 'calc(100vh - 330px)',
                  }}
                />
              )}
          </>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <Button
                  onClick={() => {
                    onCancel();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
