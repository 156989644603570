import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { roundFloat } from 'src/core/utils/formater';
import { Typography } from 'antd';

const { Text } = Typography;

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'deb',
  },
  {
    align: 'right',
    value: 'cred',
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
];

export const customColumns = ({
  customDelete = () => {},
  // dansInfo = [],
  version,
}) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    titleAlign: 'center',
    fixed: 'left',
    width: 250,
    render: (_, row) => (
      <div>
        <div>{row?.dans_dugaar}</div>
        <div className="bg-highlight">{row?.harits_dans_dugaar}</div>
      </div>
    ),
    // filters: dansInfo,
    // onFilter: (value, record) => {
    //   if (
    //     // eslint-disable-next-line operator-linebreak
    //     record.dans_dugaar.indexOf(value) === 0 ||
    //     record.harits_dans_dugaar.indexOf(value) === 0
    //   ) {
    //     return true;
    //   }
    //   return false;
    // },
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    align: 'left',
    titleAlign: 'center',
    width: 290,
    render: (_, row) => (
      <div>
        <div>
          <Text ellipsis={{ tooltip: true }}>{row?.dans_ner}</Text>
        </div>
        <div className="bg-highlight">
          <Text ellipsis={{ tooltip: true }}>{row?.harits_dans_ner}</Text>
        </div>
      </div>
    ),
  },
  {
    title: 'Огноо',
    dataIndex: 'ognoo',
    align: 'center',
    titleAlign: 'center',
    width: 80,
  },
  {
    title: 'Дүн',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Дебет',
        dataIndex: 'deb',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => (
          <div>
            <p>{roundFloat(row?.deb)}</p>
            <p className="bg-highlight">{roundFloat(row?.harits_deb)}</p>
          </div>
        ),
      },
      {
        title: 'Кредит',
        dataIndex: 'cred',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => (
          <div>
            <div>{roundFloat(row?.cred)}</div>
            <div className="bg-highlight">{roundFloat(row?.harits_cred)}</div>
          </div>
        ),
      },
    ],
  },
  {
    title: 'Гүйлгээний утга',
    dataIndex: 'guilgee_utga',
    align: 'left',
    titleAlign: 'center',
    width: 200,
    render: (_, row) => ({
      children: (
        <Text ellipsis={{ tooltip: true }} className="cell-padding-5">
          {row?.guilgee_utga}
        </Text>
      ),
    }),
  },
  {
    title: 'Гадаад улсад олсон орлого эсэх',
    dataIndex: 'ext_gadaad_olson',
    align: 'center',
    titleAlign: 'center',
    width: 130,
  },
  {
    title: 'Орлого хүлээн зөвшөөрөх нөхцөл',
    dataIndex: 'ext_aanoat_nohtsol',
    align: 'center',
    titleAlign: 'center',
    width: 160,
    render: (_, row) => ({
      children: (
        <Text ellipsis={{ tooltip: true }} className="cell-padding-5">
          {row?.ext_aanoat_nohtsol}
        </Text>
      ),
    }),
  },
  {
    title: 'ААНОАТ 18.4-т заасан зардал эсэх',
    dataIndex: 'ext_aanoat_zardal',
    align: 'center',
    titleAlign: 'center',
    width: 140,
  },
  {
    title: 'Устгах',
    align: 'center',
    titleAlign: 'center',
    fixed: 'right',
    width: 50,
    render: (_, row) => {
      if (version?.is_first_year) {
        return (
          <div>
            <DeleteOutlined onClick={() => customDelete(row)} />
          </div>
        );
      }
      return '';
    },
  },
];
