import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import newAlert from 'src/components/newAlert';

const CompanyNoti = () => {
  const socket = useSelector((state) => state.socket);
  const listener = () => {
    socket.on('company_noti', (data) => {
      newAlert({
        type: data.status === '0' ? 'success' : 'error',
        msg: data.content.Message,
      });
    });
  };
  useEffect(() => {
    listener();
  }, []);
  return <div />;
};

export default CompanyNoti;
