import React from 'react';
import { Form, Button, Row, Col, Input } from 'antd';
import { UserOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';
import NodeRSA from 'node-rsa';
import { set } from 'lodash';
import backImg from 'src/assets/images/flag.jpg';
import headerImg from 'src/assets/images/logo70.jpg';
import { useHistory } from 'react-router-dom';

const numberRegex = /^[0-9]*$/g;

const UserCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const socket = useSelector((state) => state.socket);
  const rsa = useSelector((state) => state.rsa);
  const key = new NodeRSA({ b: 512 });
  const [form] = Form.useForm();

  const createUser = (_data) => {
    socket.on('register_rsp', ({ status, content }) => {
      socket.removeAllListeners('register_rsp');
      if (status === '0') {
        newAlert({ type: 'success', msg: content?.message });
        history.push('/login');
      } else {
        newAlert({
          type: 'error',
          msg: content?.Message || 'Системд алдаа гарлаа',
        });
      }
      dispatch(end_loading({}));
    });
    socket.emit('user_register', {
      content: { ..._data },
    });
    dispatch(start_loading({}));
  };

  const onFinish = (values) => {
    const temp_ = {};
    key.importKey(rsa.serverPublicKey, 'pkcs1-public-pem');
    Object.entries(values).forEach((i) => {
      set(temp_, i[0], key.encrypt(i[1], 'base64'));
    });
    createUser(temp_);
  };

  const layout = {
    labelCol: {
      xs: { span: 5 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 20 },
      sm: { span: 20 },
    },
  };

  const onlyNumber = (value = '') => {
    const _value = value.match(numberRegex);
    return _value ? value : form.getFieldValue('number');
  };

  return (
    <Row type="flex" className="login-container">
      <Col xs={0} sm={0} md={9} lg={12} className="white-color">
        <div className="back-img">
          <img src={backImg} alt="flag" />
        </div>
      </Col>
      <Col xs={24} sm={24} md={15} lg={12} className="white-color">
        <div className="form-container-user">
          <div className="containers">
            <div>
              <div className="field-container big">
                <div className="header">
                  <div className="header-image">
                    <img src={headerImg} alt="tax-acc-logo" />
                  </div>
                </div>
                <Form {...layout} form={form} onFinish={onFinish}>
                  <Form.Item
                    name="email"
                    label="И-мэйл"
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Имэйл хаяг оруулна уу',
                      },
                      { type: 'email', message: 'Зөв имэйл оруулна уу' },
                    ]}
                    className="mb-35"
                  >
                    <Input
                      type="email"
                      size="large"
                      placeholder="Имэйл хаяг"
                      style={{
                        width: '100%',
                      }}
                      prefix={<MailOutlined className="mr-10" />}
                    />
                  </Form.Item>
                  <Form.Item
                    name="firstName"
                    label="Овог"
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Овог оруулна уу',
                      },
                    ]}
                    className="mb-35"
                  >
                    <Input
                      size="large"
                      placeholder="Овог"
                      style={{
                        width: '100%',
                      }}
                      prefix={<UserOutlined className="mr-10" />}
                    />
                  </Form.Item>
                  <Form.Item
                    name="lastName"
                    label="Нэр"
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Нэр оруулна уу',
                      },
                    ]}
                    className="mb-35"
                  >
                    <Input
                      size="large"
                      placeholder="Нэр"
                      style={{
                        width: '100%',
                      }}
                      prefix={<UserOutlined className="mr-10" />}
                    />
                  </Form.Item>
                  <Form.Item
                    name="phoneNumber"
                    label="Утас"
                    labelAlign="left"
                    normalize={onlyNumber}
                    rules={[
                      {
                        required: true,
                        message: 'Утас оруулна уу',
                      },
                      () => ({
                        validator(_, value) {
                          if (!value || value.length === 8) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              'Зөв дугаар оруулна уу, дугаар 8 оронтой байна'
                            )
                          );
                        },
                      }),
                    ]}
                    className="mb-35"
                  >
                    <Input
                      size="large"
                      placeholder="Утас"
                      style={{
                        width: '100%',
                      }}
                      maxLength={8}
                      prefix={<PhoneOutlined className="mr-10" />}
                    />
                  </Form.Item>
                  <div className="footer">
                    <Button
                      className="confirm-btn"
                      type="primary"
                      shape="round"
                      onClick={() => {
                        history.push('/login');
                      }}
                    >
                      Буцах
                    </Button>
                    <Button
                      className="confirm-btn"
                      type="primary"
                      shape="round"
                      htmlType="submit"
                    >
                      Бүртгүүлэх
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default UserCreate;
