import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { Table as AntTable } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const clear = (inputString) => {
  const stringWithoutCommas = String(inputString).replace(/,/g, '');
  const numberValue = parseFloat(stringWithoutCommas);
  return numberValue;
};

export const ColumnsHA = ({ deleteRow = () => { },clear = () => { } }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    key: 'dans_dugaar',
    width: 200,
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    key: 'dans_ner',
    width: 250,
    ellipsis: true,
  },
  {
    title: 'ХА тохируулга',
    children:
      [{
        title: 'Дебет',
        dataIndex: 'ha_deb',
        align: 'right',
        editable: true,
        render: (_) => roundFloat(clear(_)),
      },
      {
        title: 'Кредит',
        dataIndex: 'ha_cred',
        align: 'right',
        editable: true,
        render: (_) => roundFloat(clear(_)),
      },]
  },
  {
    title: 'Устгах',
    align: 'center',
    width: 50,
    fixed: 'right',
    render: (_, row) => (
      <DeleteOutlined id="second" onClick={() => deleteRow(row)} />
    ),
  },
];

export const HAEditSum = ({ data = null }) => (
  <AntTable.Summary fixed>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right">
        <></>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>Холбосон</p>
          <p>ХА тохируулга</p>
        </>
      </AntTable.Summary.Cell>

      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.ha_deb)|| 0.00}</p>
          <p>{roundFloat(data?.niit_deb)|| 0.00}</p>
        </> 
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.ha_cred)|| 0.00}</p>
          <p>{roundFloat(data?.niit_cred)|| 0.00}</p>
        </>
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
  </AntTable.Summary>
);

export const ColumnsOZND = ({ deleteRow = () => { },clear = () => { } }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    key: 'dans_dugaar',
    width: 200,
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    key: 'dans_ner',
    width: 250,
    ellipsis: true,
  },
  {
    title: 'ОЗНД тохируулга',
    children:
      [{
        title: 'Дебет',
        dataIndex: 'oznd_deb',
        align: 'right',
        editable: true,
        render: (_) => roundFloat(clear(_)),
      },
      {
        title: 'Кредит',
        dataIndex: 'oznd_cred',
        align: 'right',
        editable: true,
        render: (_) => roundFloat(clear(_)),
      },]
  },
  {
    title: 'Устгах',
    align: 'center',
    width: 50,
    fixed: 'right',
    render: (_, row) => (
      <DeleteOutlined id="second" onClick={() => deleteRow(row)} />
    ),
  },
];

export const OZNDEditSum = ({ data = null }) => (
  <AntTable.Summary fixed>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right">
        <></>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>Холбосон</p>
          <p>ОЗНД тохируулга</p>
        </>
      </AntTable.Summary.Cell>

      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.oznd_deb)|| 0.00}</p>
          <p>{roundFloat(data?.jr_ets_cred)|| 0.00}</p>
        </> 
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.oznd_cred)|| 0.00}</p>
          <p>{roundFloat(data?.jr_ets_deb)|| 0.00}</p>
        </>
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
  </AntTable.Summary>
);

export const totalSum = ({ data = null }) => (
  <AntTable.Summary fixed>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right">
        <></>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <></>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>Нийт</p>
        </>
      </AntTable.Summary.Cell>

      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.deb)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.cred)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.deb)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.cred)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.deb)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.cred)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.deb)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.cred)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.deb)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.cred)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.deb)}</p>
        </>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <>
          <p>{roundFloat(data?.cred)}</p>
        </>
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
  </AntTable.Summary>
);

const setFloat = (_) => roundFloat(_);

export const columns = () => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dugaar',
    key: 'dugaar',
    width: 200,
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'ner',
    key: 'ner',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Валютын төрөл',
    dataIndex: 'valiut',
    key: 'valiut',
    width: 80,
  },
  {
    title: 'Эцсийн үлдэгдэл',
    children:
      [{
        title: 'Дебет',
        dataIndex: 'ets_deb',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Кредит',
        dataIndex: 'ets_cred',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },]
  },
  {
    title: 'ОАТ тохируулга',
    children:
      [{
        title: 'Дебет',
        dataIndex: 'jr_deb',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Кредит',
        dataIndex: 'jr_cred',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },]
  },
  {
    title: 'ТДШБ',
    children:
      [{
        title: 'Дебет',
        dataIndex: 'jr_ets_deb',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Кредит',
        dataIndex: 'jr_ets_cred',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },]
  },
  {
    title: 'ОЗНД тохируулга',
    children:
      [{
        title: 'Дебет',
        dataIndex: 'oznd_deb',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Кредит',
        dataIndex: 'oznd_cred',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },]
  },
  {
    title: 'ХА тохируулга',
    children:
      [{
        title: 'Дебет',
        dataIndex: 'ha_deb',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Кредит',
        dataIndex: 'ha_cred',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },]
  },
  {
    title: 'Баланс',
    children:
      [{
        title: 'Дебет',
        dataIndex: 'balans_deb',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Кредит',
        dataIndex: 'balans_cred',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },]
  }
];

export const customSummary = ({ data = null }) => (
  <AntTable.Summary fixed>
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right">
        <></>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <></>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="left">
        <p>Нийт дүн:</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.ets_deb)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.ets_cred)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.jr_deb)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.jr_cred)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.jr_ets_deb)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.jr_ets_cred)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.oznd_deb)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.oznd_cred)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.ha_deb)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.ha_cred)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.balans_deb)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.balans_cred)}</p>
      </AntTable.Summary.Cell>
    </AntTable.Summary.Row>
  </AntTable.Summary>
);