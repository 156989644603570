export const currencyList = [
  'MNT',
  'USD',
  'EUR',
  'JPY',
  'GBP',
  'RUB',
  'CNY',
  'KRW',
  'SGD',
  'CAD',
  'AUD',
  'HKD',
  'CHF',
  'THB',
  'NZD',
  'KZT',
  'TWD',
  'INR',
];
