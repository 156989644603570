import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Drawer, Input, Row } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Table } from 'src/components';
import CanView from 'src/components/permission/canview';
import newAlert from 'src/components/newAlert';
import { ZalruulgaAllColumns } from './dataStructure/data';
import { TatvarZalruulga } from './TatvarZalruulga';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { AddZalruulgaFile } from './AddZalruulgaFile';

const { Search } = Input;

export const TatvarZalruulgaAll = ({
  onCancel = () => {},
  visibleTatvarZalruulgaAll,
}) => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);
  const [form] = Form.useForm();

  // eslint-disable-next-line operator-linebreak
  const [visibleTatvarZalruulgaAdd, setVisibleTatvarZalruulgaAdd] =
    useState(false);
  // eslint-disable-next-line operator-linebreak
  const [visibleFilersgalZalruulga, seTvisibleFilersgalZalruulga] =
    useState(false);
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });
  const [pagenationData, seTpagenationData] = useState({
    page: 1,
    size: 20,
    burtgel: '',
    hurungu: '',
  });

  const onSearch = (values) => {
    if (values) {
      seTpagenationData({
        ...pagenationData,
        burtgel: values,
        hurungu: form.getFieldValue('search'),
      });
    } else {
      seTpagenationData({
        ...pagenationData,
        burtgel: '',
        hurungu: form.getFieldValue('search'),
      });
    }
  };

  function select_data() {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const { page, size, burtgel, hurungu } = pagenationData;
    const month = '13';
    const search = { burtgel, hurungu };
    const type = 'zal_ts';
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'dahin_unelgee_burtgel_page',
        type,
        page,
        size,
        search,
        month,
      },
    });
    dispatch(start_loading({}));
  }

  useEffect(() => {
    if (visibleTatvarZalruulgaAll) {
      select_data();
    }
  }, [visibleTatvarZalruulgaAll]);

  useEffect(() => {
    if (!visibleTatvarZalruulgaAdd && visibleTatvarZalruulgaAll) {
      select_data();
    }
  }, [visibleTatvarZalruulgaAdd, pagenationData]);

  const delete_id = (deleteRowId) => {
    socket.on('request_out', (req_data) => {
      socket.removeAllListeners('request_out');
      if (req_data.status === '0') {
        select_data();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: { action_type: 'delete_dahin_unelgee', id: deleteRowId },
    });
    dispatch(start_loading({}));
  };

  const after_handleUpload = (content) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        select_data();
        newAlert({ type: 'success', msg: data.message });
      } else {
        newAlert({ type: 'error', msg: data.message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: {
        action_type: 'zalruulga_file',
        file_name: content?.json_array,
      },
    });
    dispatch(start_loading({}));
  };

  const title = () => (
    <Row>
      {version?.is_first_year && (
        <CanView allowedRole="006_1">
          <Button
            className="add-btn"
            type="primary"
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              setVisibleTatvarZalruulgaAdd(true);
            }}
          >
            Залруулга нэмэх
          </Button>
          <Button
            className="add-btn"
            type="primary"
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              seTvisibleFilersgalZalruulga(true);
            }}
          >
            Файл оруулах
          </Button>
        </CanView>
      )}
      <Form.Item name="search" style={{ margin: 0, marginRight: 10 }}>
        <Input
          width={200}
          size="small"
          allowClear
          placeholder="Хөрөнгийн код..."
        />
      </Form.Item>
      <Form.Item name="search2" style={{ margin: 0 }}>
        <Search
          width={200}
          size="small"
          allowClear
          placeholder="Бүртгэлийн дугаар..."
          onSearch={onSearch}
        />
      </Form.Item>
    </Row>
  );

  const onCancelAdd = () => {
    setVisibleTatvarZalruulgaAdd(false);
  };

  const getParm = (values) => {
    if (
      // eslint-disable-next-line operator-linebreak
      values.page !== pagenationData.page ||
      values.size !== pagenationData.size
    ) {
      seTpagenationData({
        ...pagenationData,
        page: values.page,
        size: values.size,
      });
    }
  };

  return (
    <Drawer
      title="Татварын суурь - Залруулга"
      open={visibleTatvarZalruulgaAll}
      width="80%"
      footer={null}
      closable
      maskClosable={false}
      onClose={onCancel}
      className="custom-drawer"
    >
      <AddZalruulgaFile
        visible={visibleFilersgalZalruulga}
        onClose={() => {
          seTvisibleFilersgalZalruulga(false);
        }}
        after_handleUpload={after_handleUpload}
      />
      <TatvarZalruulga
        zasvarVisible={visibleTatvarZalruulgaAdd}
        onCancel={onCancelAdd}
      />
      <div className="inside-table no-scroll">
        <Form form={form} className="register-form" size="small">
          <Table
            title={title}
            columns={ZalruulgaAllColumns({ delete_id })}
            dataSource={tableData.result_list}
            scroll={{
              y: 'calc(100vh - 310px)',
            }}
            subTotal={tableData.total_size}
            getParam={getParm}
          />
        </Form>
      </div>
    </Drawer>
  );
};
