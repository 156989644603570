import React from 'react';

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CanView from 'src/components/permission/canview';

export const columns = ({ editRow = () => {}, deleteRow = () => {} }) => [
  {
    title: '№',
    dataIndex: 'index',
    align: 'left',
    width: 50,
    fixed: 'left',
  },
  {
    title: 'Харилцагчийн код',
    dataIndex: 'code',
    align: 'left',
    width: 150,
    fixed: 'left',
  },
  {
    title: 'Харилцагчийн нэр',
    dataIndex: 'name',
    align: 'left',
    width: 250,
    ellipsis: true,
  },
  {
    title: 'Регистрийн дугаар',
    dataIndex: 'ttd',
    align: 'left',
    width: 180,
  },
  {
    title: 'Улсын харьяалал',
    dataIndex: 'is_foreign',
    align: 'left',
    width: 100,
    render: (_, row) => <>{row?.is_foreign === '1' ? 'Гадаад' : 'Дотоод'}</>,
  },
  {
    title: 'Улсын нэр',
    dataIndex: 'country_name',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Засах',
    align: 'center',
    width: 50,
    fixed: 'right',
    render: (_, row) => (
      <div>
        <CanView allowedRole="007_1">
          <EditOutlined onClick={() => editRow(row)} />
        </CanView>
      </div>
    ),
  },
  // {
  //   title: 'Устгах',
  //   align: 'center',
  //   width: 50,
  //   fixed: 'right',
  //   render: (_, row) => (
  //     <div>
  //       <CanView allowedRole="007_1">
  //         <DeleteOutlined onClick={() => deleteRow(row)} />
  //       </CanView>
  //     </div>
  //   ),
  // },
];

export const modalBody = {
  className: 'custom-modal',
  closable: false,
  maskClosable: false,
  width: 500,
  title: 'Харилцагч нэмэх',
  footer: null,
};

export const typesList = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const layout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 17 },
  },
};

export const sourceList = [
  { id: '1', name: 'Диамонд' },
  { id: '2', name: 'Такс' },
];

export const fileList = [
  { id: 'avlagiin_tovchoo', name: 'Авлагын товчоо' },
  { id: 'oglogiin_tovchoo', name: 'Өглөгийн товчоо' },
];
