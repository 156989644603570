import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { Checkbox } from 'antd';
import { EditOutlined } from '@ant-design/icons';

export const ColumnsMain = ({
  selectData = () => {},
  checkAll,
  indeterminate,
  onCheckAllChange = () => {},
  selectedRowKeys,
  onClickEdit = () => {},
}) => [
  {
    title: 'Харилцагчийн нэр',
    dataIndex: 'har_ner',
    align: 'left',
    width: 200,
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'РД',
    dataIndex: 'har_reg',
    align: 'left',
    width: 100,
    fixed: 'left',
  },
  {
    title: 'Тэмдэглэл',
    dataIndex: 'note',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
  {
    title: '',
    align: 'center',
    width: 30,
    render: (_, row) => <EditOutlined onClick={() => onClickEdit(row)} />,
  },
  {
    title: 'Тохируулга',
    children: [
      {
        title: 'Хойшлуулах',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'toh_hoish_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ ',
            dataIndex: 'toh_hoish_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
      {
        title: 'Илүү шивсэн',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'toh_iluu_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ ',
            dataIndex: 'toh_iluu_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
    ],
  },
  {
    title: (
      <Checkbox
        indeterminate={indeterminate}
        onClick={() => onCheckAllChange()}
        checked={checkAll}
      />
    ),
    align: 'center',
    width: 30,
    render: (row) => (
      <Checkbox
        checked={selectedRowKeys.indexOf(row.key) > -1}
        onChange={() => selectData(row)}
      />
    ),
  },
  {
    title: 'Зөрүү',
    children: [
      {
        title: 'Илүү',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'zuruu_iluu_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ',
            dataIndex: 'zuruu_iluu_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
      {
        title: 'Дутуу',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'zuruu_dutuu_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ',
            dataIndex: 'zuruu_dutuu_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
    ],
  },
  {
    title: 'Борлуулалтын бүртгэл',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'bor_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'bor_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Нийт ибаримтаар шивсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'ebar_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'ebar_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'TaxAcс Төлбөрийн баримтаар нөхөж шивсэн',
    children: [
      {
        title: 'ДДТД',
        dataIndex: 'vat_ddtd',
        align: 'center',
        width: 300,
      },
      {
        title: 'Огноо',
        dataIndex: 'vat_date',
        align: 'center',
        width: 150,
      },
      {
        title: 'Нийт дүн',
        dataIndex: 'vat_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'vat_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Төлбөрийн баримтаар шивсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'tolbor_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'tolbor_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Нэхэмжлэхээр шивсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'nehemj_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'nehemj_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Үлдэгдэл',
        dataIndex: 'nehemj_uldegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];

export const ColumnsMainShort = () => [
  {
    title: 'Харилцагчийн нэр',
    dataIndex: 'har_ner',
    align: 'left',
    width: 200,
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'РД',
    dataIndex: 'har_reg',
    align: 'left',
    width: 100,
    fixed: 'left',
  },
  {
    title: 'Тохируулга',
    children: [
      {
        title: 'Хойшлуулах',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'toh_hoish_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ ',
            dataIndex: 'toh_hoish_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
      {
        title: 'Илүү шивсэн',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'toh_iluu_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ ',
            dataIndex: 'toh_iluu_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
    ],
  },
  {
    title: 'Зөрүү',
    children: [
      {
        title: 'Илүү',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'zuruu_iluu_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ',
            dataIndex: 'zuruu_iluu_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
      {
        title: 'Дутуу',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'zuruu_dutuu_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ',
            dataIndex: 'zuruu_dutuu_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
    ],
  },
  {
    title: 'Борлуулалтын бүртгэл',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'bor_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'bor_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Нийт ибаримтаар шивсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'ebar_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'ebar_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'TaxAcс Төлбөрийн баримтаар нөхөж шивсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'vat_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'vat_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Төлбөрийн баримтаар шивсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'tolbor_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'tolbor_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Нэхэмжлэхээр шивсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'nehemj_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'nehemj_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Үлдэгдэл',
        dataIndex: 'nehemj_uldegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];

export const ColumnsAngilal = () => [
  {
    title: '',
    dataIndex: 'type',
    align: 'left',
    width: 100,
    fixed: 'left',
  },
  {
    title: 'Хойшлуулах',
    dataIndex: 'har_ner',
    align: 'left',
    width: 100,
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Зөрүү Илүү',
    dataIndex: 'har_reg',
    align: 'left',
    width: 100,
  },
  {
    title: 'Нийт',
    dataIndex: 'har_reg',
    align: 'left',
    width: 100,
  },
];

export const customSummary = [
  {
    word: ' ',
  },
  {
    align: 'right',
    word: 'Нийт дүн:',
  },
  {
    word: ' ',
  },
  {},
  {
    align: 'right',
    value: 'toh_hoish_niit',
  },
  {
    align: 'right',
    value: 'toh_hoish_noat',
  },
  {
    align: 'right',
    value: 'toh_iluu_niit',
  },
  {
    align: 'right',
    value: 'toh_iluu_noat',
  },
  {},
  {
    align: 'right',
    value: 'zuruu_iluu_niit',
  },
  {
    align: 'right',
    value: 'zuruu_iluu_noat',
  },
  {
    align: 'right',
    value: 'zuruu_dutuu_niit',
  },
  {
    align: 'right',
    value: 'zuruu_dutuu_noat',
  },
  {
    align: 'right',
    value: 'bor_niit',
  },
  {
    align: 'right',
    value: 'bor_noat',
  },
  {
    align: 'right',
    value: 'ebar_niit',
  },
  {
    align: 'right',
    value: 'ebar_noat',
  },
  {},
  {},
  {
    align: 'right',
    value: 'vat_niit',
  },
  {
    align: 'right',
    value: 'vat_noat',
  },
  {
    align: 'right',
    value: 'tolbor_niit',
  },
  {
    align: 'right',
    value: 'tolbor_noat',
  },
  {
    align: 'right',
    value: 'nehemj_niit',
  },
  {
    align: 'right',
    value: 'nehemj_noat',
  },
  {
    align: 'right',
    value: 'nehemj_uldegdel',
  },
];

export const customSummaryShort = [
  {
    word: ' ',
  },
  {
    align: 'right',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'toh_hoish_niit',
  },
  {
    align: 'right',
    value: 'toh_hoish_noat',
  },
  {
    align: 'right',
    value: 'toh_iluu_niit',
  },
  {
    align: 'right',
    value: 'toh_iluu_noat',
  },
  {
    align: 'right',
    value: 'zuruu_iluu_niit',
  },
  {
    align: 'right',
    value: 'zuruu_iluu_noat',
  },
  {
    align: 'right',
    value: 'zuruu_dutuu_niit',
  },
  {
    align: 'right',
    value: 'zuruu_dutuu_noat',
  },
  {
    align: 'right',
    value: 'bor_niit',
  },
  {
    align: 'right',
    value: 'bor_noat',
  },
  {
    align: 'right',
    value: 'ebar_niit',
  },
  {
    align: 'right',
    value: 'ebar_noat',
  },
  {
    align: 'right',
    value: 'vat_niit',
  },
  {
    align: 'right',
    value: 'vat_noat',
  },
  {
    align: 'right',
    value: 'tolbor_niit',
  },
  {
    align: 'right',
    value: 'tolbor_noat',
  },
  {
    align: 'right',
    value: 'nehemj_niit',
  },
  {
    align: 'right',
    value: 'nehemj_noat',
  },
  {
    align: 'right',
    value: 'nehemj_uldegdel',
  },
];
