import React, { useEffect, useState } from 'react';
import {
  Table as AntTable,
  Row,
  Col,
  Button,
  Empty,
  Typography,
  Tooltip,
} from 'antd';
import { isEmpty, get, set } from 'lodash';
import { roundFloat } from 'src/core/utils/formater';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import Pagination from 'src/components/common/pagination';
import SubPagination from 'src/components/common/subPagination';
import { open_search, search_field } from 'src/core/actions/searchField';
import { useDispatch } from 'react-redux';
import SearchField from './searchField';

const { Text } = Typography;

// summaryList.sumColumn uusgehde value = null ==> hooson
// summaryList.sumColumn word Label bolno
// summaryList.sumColumn uldsen tohioldold datataiga match hiij garna

const AllColumnsLength = (columns) => {
  const sum = columns.reduce(
    (prev, current) => prev + get(current, 'children.length', 1),
    0
  );
  return sum;
};

const renderContent = (value, row) => {
  const obj = {
    children: value,
    props: {},
  };
  if (row?.title) obj.props.colSpan = 0;
  return obj;
};

const Table = ({
  isSearch = false,
  columns = [],
  dataSource = [],
  scroll = {},
  rowSelection = {},
  hide = false,
  customize = null,
  summaryList = null,
  isTitle = false,
  isSticky = true,
  isBordered = false,
  title = null,
  components = null,
  customAdd = null,
  tab = '',
  total = null,
  id = null,
  searchList = [],
  subTotal = null,
  getParam = () => {},
  withEmptyIcon = true,
}) => {
  const dispatch = useDispatch();

  const [data, seTdata] = useState([]);
  const [loading, seTloading] = useState(true);

  const renderFirst = (row) => {
    if (!row?.title) {
      return {
        props: {
          colSpan: 0,
        },
      };
    }
    return {
      children: <span>{row?.title}</span>,
      props: {
        colSpan: AllColumnsLength(columns),
      },
    };
  };

  useEffect(() => {
    if (isTitle && dataSource.length !== 0) {
      const _data = columns.map((i) => {
        if (!i.render) {
          set(i, 'render', renderContent);
        }
        return { ...i };
      });
      _data.push({ colSpan: 0, render: renderFirst });
      seTdata(_data);
    } else {
      seTdata([...columns]);
    }
    seTloading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const empty = (
    <div style={{ textAlign: 'center' }}>
      <Empty description="Дата байхгүй" />
    </div>
  );

  const openSearch = () => {
    dispatch(open_search(true));
  };

  const CustomAdd = () => {
    if (customAdd) {
      return (
        <Button
          className="add-btn"
          type="primary"
          size="small"
          icon={<PlusOutlined />}
          onClick={customAdd}
        >
          Нэмэх
        </Button>
      );
    }
    return null;
  };

  const titleMaker = () => {
    const Compontent = title;
    if (isSearch) {
      return (
        <div className="is-search">
          <Tooltip title="Хайлт хийх">
            <SearchOutlined onClick={openSearch} className="icon" />
          </Tooltip>

          <div>{customAdd ? <CustomAdd /> : <Compontent />}</div>
        </div>
      );
    }
    return customAdd ? <CustomAdd /> : <Compontent />;
  };

  useEffect(() => {
    try {
      if (searchList && searchList.length > 0) {
        dispatch(search_field(searchList));
      }
    } catch (error) {
      console.warn('error', error);
    }
  }, [searchList]);

  return (
    <div>
      <Row>
        <Col span={24}>{isSearch && <SearchField />}</Col>
      </Row>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <AntTable
            id={id}
            title={title ? titleMaker : null}
            loading={loading}
            components={components}
            className="custom-antd-table-one"
            columns={data}
            bordered={isBordered}
            sticky={isSticky}
            dataSource={dataSource}
            rowSelection={!isEmpty(rowSelection) ? rowSelection : undefined}
            scroll={scroll}
            pagination={false}
            rowClassName={
              (record) =>
                `table-row-class ${record?.title ? 'title' : ''} ${
                  record?.align ? 'left' : ''
                } ${record?.mor_type === 'sum' ? 'unique-row' : ''} `
              // eslint-disable-next-line react/jsx-curly-newline
            }
            onHeaderRow={
              (column) =>
                column.map((i) => {
                  set(i, 'align', i?.titleAlign || 'center');
                  return i;
                })
              // eslint-disable-next-line react/jsx-curly-newline
            }
            size="small"
            locale={{
              emptyText: withEmptyIcon ? empty : <></>,
              filterConfirm: <span>Шүүлт хийх</span>,
              filterEmptyText: <span>Дата байхгүй</span>,
              filterReset: <span>Шүүлтийг цэвэрлэх</span>,
            }}
            summary={
              // eslint-disable-next-line operator-linebreak
              customize ||
              // eslint-disable-next-line no-confusing-arrow
              (() =>
                !isEmpty(summaryList) && summaryList?.sumDataSource ? (
                  <AntTable.Summary fixed>
                    <AntTable.Summary.Row>
                      {summaryList?.sumColumn.map((item, idx) => {
                        if (item?.word) {
                          return (
                            <AntTable.Summary.Cell
                              align={item?.align}
                              index={idx}
                            >
                              <Text ellipsis={{ tooltip: true }}>
                                {item?.word}
                              </Text>
                            </AntTable.Summary.Cell>
                          );
                        }
                        if (!item?.value) {
                          return <AntTable.Summary.Cell />;
                        }
                        if (idx === summaryList?.sumColumn.length - 1) {
                          return (
                            <AntTable.Summary.Cell
                              align={item.align}
                              index={idx}
                              className="last-item"
                            >
                              {roundFloat(
                                summaryList?.sumDataSource[item.value]
                              )}
                            </AntTable.Summary.Cell>
                          );
                        }
                        return (
                          <AntTable.Summary.Cell align={item.align} index={idx}>
                            {roundFloat(summaryList?.sumDataSource[item.value])}
                          </AntTable.Summary.Cell>
                        );
                      })}
                    </AntTable.Summary.Row>
                  </AntTable.Summary>
                ) : null)
            }
            hideSelectAll={hide}
            footer={() => {
              if (total) {
                return <Pagination tab={tab} total={total} />;
              }
              if (subTotal) {
                return <SubPagination getParam={getParam} total={subTotal} />;
              }
              return null;
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Table;
