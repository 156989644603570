const oDefaultState = {
  clientPrivateKey: '',
  clientPublicKey: '',
  serverPublicKey: '',
};

const reducer = (state = oDefaultState, action) => {
  switch (action.type) {
    case 'RSA_STORE_CLIENT_KEY':
      const oNewState = {
        ...state,
        clientPrivateKey: action.payload.sPrivate,
        clientPublicKey: action.payload.sPublic,
      };
      return oNewState;
    case 'RSA_STORE_SERVER_KEY':
      const oNewState2 = {
        ...state,
        serverPublicKey: action.payload.sPublic,
      };
      return oNewState2;
    default:
      return state;
  }
};

export default reducer;
