import React from 'react';
import { Button, Form, Row, Col, InputNumber } from 'antd';
import { onlyNumber } from 'src/core/utils/formater';

const InsertAvgPercentModal = ({
  avgPercent,
  onCancel = () => {},
  handleSave = () => {},
}) => {
  const [form] = Form.useForm();
  form.setFieldsValue({ avgPercent });

  const onFinish = (values) => {
    form.resetFields();
    onCancel();
    handleSave(values.avgPercent);
  };

  return (
    <div className="hariltsagch-medeelel">
      <Form
        form={form}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        onFinish={onFinish}
        className="register-form mt-10"
        size="small"
      >
        <Form.Item name="avgPercent" label="Дундаж хувь хэмжээ(%):">
          <InputNumber
            normalize={onlyNumber}
            max={100}
            style={{ width: '80%' }}
          />
        </Form.Item>
        <Row>
          <Col span={24} style={{ textAlign: 'right', marginTop: '24px' }}>
            <Form.Item noStyle>
              <Button
                onClick={() => {
                  onCancel();
                }}
                className="cancel-btn mr-5"
                type="primary"
                shape="round"
              >
                Буцах
              </Button>
              <Button
                className="confirm-btn"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default InsertAvgPercentModal;
