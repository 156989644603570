import React from 'react';

import { Form, Button, Row, Col, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import backImg from 'src/assets/images/flag.jpg';
import { useHistory } from 'react-router-dom';
import headerImg from 'src/assets/images/logo70.jpg';
import { useSelector, useDispatch } from 'react-redux';
import NodeRSA from 'node-rsa';
import { remove_jwt } from 'src/core/actions/jwt_token';
import newAlert from 'src/components/newAlert';

const layout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 15 },
  },
};

const ChangePassword = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const socket = useSelector((state) => state.socket);
  const jwt_token = useSelector((state) => state.jwt_token);
  const rsa = useSelector((state) => state.rsa);

  const logout = () => {
    dispatch(remove_jwt());
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    localStorage.removeItem('activeMonth');
    window.location.href = '/';
  };

  const onFinish = (values) => {
    socket.on('change_password_rsp', ({ status, content }) => {
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: {
          onSuccess: [
            logout(),
            localStorage.removeItem('taxjwt'),
            history.push('/login'),
          ],
        },
      });
    });
    const key = new NodeRSA({ b: 512 });
    key.importKey(rsa.serverPublicKey, 'pkcs1-public-pem');
    const encrypted_new_password = key.encrypt(values?.newPassword, 'base64');
    socket.emit('change_password', {
      jwt_1: jwt_token?.jwt_1,
      new_password: encrypted_new_password,
    });
  };

  return (
    <Row type="flex" className="login-container">
      <Col xs={0} sm={0} md={9} lg={10} className="white-color">
        <div className="back-img">
          <img src={backImg} alt="flag" />
        </div>
      </Col>
      <Col xs={24} sm={24} md={15} lg={14} className="white-color">
        <div className="form-container-user">
          <div className="containers">
            <div>
              <div className="field-container big">
                <div className="header-image">
                  <img src={headerImg} alt="tax-acc-logo" />
                </div>
                <Form {...layout} form={form} onFinish={onFinish}>
                  <Form.Item
                    name="newPassword"
                    label="Шинэ нууц үг"
                    rules={[
                      {
                        required: true,
                        message: 'Шинэ нууц үг хоосон байна',
                      },
                    ]}
                    className="mb-35"
                    hasFeedback
                  >
                    <Input.Password
                      size="large"
                      placeholder="***"
                      prefix={<LockOutlined className="mr-10" />}
                    />
                  </Form.Item>
                  <Form.Item
                    name="rePassword"
                    label="Дахин оруулна уу"
                    rules={[
                      {
                        required: true,
                        message: 'Нууц үг хоосон байна',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            // eslint-disable-next-line operator-linebreak
                            !value ||
                            getFieldValue('newPassword') === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('Шинэ нууц үгтэй таарахгүй байна')
                          );
                        },
                      }),
                    ]}
                    dependencies={['newPassword']}
                    className="mb-35"
                    hasFeedback
                  >
                    <Input.Password
                      size="large"
                      placeholder="***"
                      prefix={<LockOutlined className="mr-10" />}
                    />
                  </Form.Item>

                  <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                      <Form.Item noStyle>
                        <Button
                          className="confirm-btn ml-5"
                          type="primary"
                          shape="round"
                          htmlType="submit"
                        >
                          Нууц үг солих
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ChangePassword;
