import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CloudUploadOutlined } from '@ant-design/icons';
import CanView from 'src/components/permission/canview';
import { FileError } from 'src/components/common/file_error';
import { CompanyNotification } from 'src/components/common/company_notification';
import { useQuery } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import {
  Button as AntButton,
  Modal,
  Form,
  Select as AntSelect,
  Upload,
  message,
  Row,
  Col,
  Divider,
  Table,
  Input,
  Select,
} from 'antd';
import axios from 'src/core/utils/axios';
import {
  start_loading,
  end_loading,
  is_loading,
} from 'src/core/actions/loader';
import { is_empty } from 'src/core/actions/hintStep';
import { newColumns } from './dataStructure/data';

const jagsaaltTypes = [
  { name: '...', value: null },
  { name: 'Гүйлгээний жагсаалт', value: 'guilgee' },
  { name: 'Үндсэн хөрөнгийн баримтын жагсаалт', value: 'uh' },
  { name: 'Авлага өглөг баримтын жагсаалт', value: 'ao' },
  { name: 'Үндсэн хөрөнгийн товчтоо', value: 'uh_tovchoo' },
];
const { TextArea } = Input;
const { Option } = Select;
const Client = () => {
  const typesList = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  const { socket } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [form_2] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [sourceTypes, seTsourceTypes] = useState({
    file_types: [],
    source_types: [],
  });
  const [jtype, seTtype] = useState(null);
  const [total, seTtotal] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 8,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const [visible, seTvisible] = useState(false);
  const [comment, seTcomment] = useState(false);

  const mainData = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        const { src_types, file_types, file_list } = content;
        if (!content?.file_list || content?.file_list.length === 0) {
          dispatch(is_empty(true));
        }
        const new_temp_data = [];
        dispatch(is_loading(false));
        file_list.forEach((e, index) => {
          if (e.file_state === 1) {
            dispatch(is_loading('start'));
          }
          new_temp_data.push({ id: index + 1, ...e });
        });
        seTsourceTypes({ file_types, source_types: src_types });
        setTableData(new_temp_data);
        seTtotal({ ...tableParams.pagination, total: content.total });
      } else {
        console.log('Error');
      }
    });
    socket.emit('request_in', {
      channel: 'ss009_03_manage_jagsaalt',
      content: {
        action_type: 'get_file_list',
        pagination: tableParams.pagination,
        jagsaalt_type: jtype,
        search: {},
      },
    });
  };

  const onCancel = () => {
    seTvisible(false);
  };

  const manageCom = ({ state, text, file_id }) => {
    if (file_id) {
      form.setFieldsValue({ comment: text, file_id });
    }
    seTcomment(state);
  };

  const onChange = (value) => {
    seTtype(value);
    console.log('==============>', value);
  };

  useEffect(() => {
    socket.on('receive_notification', (data) => {
      if (data) {
        setTimeout(() => {
          dispatch(is_loading('done'));
          mainData();
          dispatch(is_loading(false));
        }, 2000);
      }
    });
  }, [socket]);

  const delete_row = async (file_id) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: {
          onSuccess: [mainData()],
        },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss009_03_manage_jagsaalt',
      content: {
        file_id,
        action_type: 'delete_file',
      },
    });
    dispatch(start_loading({}));
  };
  const showError = (msg) => {
    FileError({
      text: msg,
    });
  };

  const send_parse_request = (id) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: {
          onSuccess: [dispatch(is_loading('start')), mainData()],
          onFail: [dispatch(is_loading(false)), mainData()],
        },
        form,
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss009_03_manage_jagsaalt',
      content: {
        file_id: id,
        action_type: 'start_job_json_parse',
      },
    });
    dispatch(start_loading({}));
  };

  const AddValue = () => {
    const finishCom = (data) => {
      const temp_data = { file_id: form.getFieldValue('file_id'), ...data };
      socket.on('request_out', ({ status, content }) => {
        socket.removeAllListeners('request_out');

        newAlert({
          type: status === '0' ? 'success' : 'error',
          msg: content.message,
          functions: {
            onSuccess: [
              mainData(),
              manageCom({ state: false }),
              form_2.resetFields(),
            ],
          },
        });
      });
      socket.emit('request_in', {
        channel: 'ss009_03_manage_jagsaalt',
        content: {
          temp_data,
          action_type: 'add_comment',
        },
      });
    };
    return (
      <>
        <Form
          form={form_2}
          accept-charset="UTF-8"
          name="basic"
          style={{
            fontSize: 13,
          }}
          initialValues={{ comment: form.getFieldValue('comment') }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          onFinish={finishCom}
        >
          <Form.Item label="Тэмдэглэл:" name="comment" labelAlign="left">
            <TextArea
              autoSize={{ minRows: 3, maxRows: 5 }}
              disabled={false}
              className="ww-100"
              maxLength={300}
              showCount
            />
          </Form.Item>

          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <AntButton
                  onClick={() => {
                    form_2.resetFields();
                    manageCom({ state: false });
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </AntButton>
                <AntButton
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </AntButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const onFinish = async (values) => {
    if (values) {
      console.log('🚀 ~ onFinish ~ values:', values);
      dispatch(start_loading({}));
      const { size: file_size, name } = values.file[0];
      const {
        file_source,
        file_type,
        comment: new_com = '',
        tovchoo_month = null,
      } = values;

      const formData = new FormData();
      formData.append('file', values.file[0].originFileObj);
      formData.append(
        'file_data',
        JSON.stringify({
          name,
          file_size,
          file_source,
          file_type,
          new_com,
          tovchoo_month,
        })
      );
      console.log(formData);
      const { status, content } = await axios.post(
        'storage',
        '/excel_upload',
        formData,
        {}
      );
      if (status === 0) {
        newAlert({
          type: 'success',
          msg: content.msg,
          functions: {
            onSuccess: [onCancel(), form.resetFields(), mainData()],
          },
        });
      } else {
        newAlert({ type: 'error', msg: content.msg });
      }
      dispatch(end_loading({}));
    }
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    console.log('>> Getting old list...');
    mainData();
  }, [tableParams.pagination, jtype]);

  return (
    <>
      <div className="file-input">
        <div className="header">
          <div>
            <p className="title">Файл</p>
            <AntSelect
              defaultValue={null}
              placeholder="Сонгох"
              onChange={onChange}
              style={{ width: 250 }}
            >
              {jagsaaltTypes.map((i) => (
                <AntSelect.Option value={i.value}>{i.name}</AntSelect.Option>
              ))}
            </AntSelect>
          </div>
          <CanView allowedRole="001_1">
            <AntButton
              className="upload-btn"
              shape="round"
              onClick={() => {
                seTvisible(true);
              }}
              icon={<CloudUploadOutlined />}
            >
              Жагсаалт оруулах
            </AntButton>
          </CanView>
        </div>
        <Divider className="mt-10" />
        <div className="table-container">
          <Table
            pagination={total}
            className="file-table"
            dataSource={tableData}
            columns={newColumns({
              send_parse_request,
              manageCom,
              delete_row,
              showError,
              rowList:
                tableData && tableData.length > 0
                  ? tableData.map((r) => r?.file_state)
                  : [],
            })}
            scroll={{
              y: 'calc(100vh - 300px)',
            }}
            rowClassName={() => 'file-row'}
            onChange={handleTableChange}
            sticky
          />
        </div>
      </div>
      <Modal
        title="Шинээр файл оруулах"
        visible={visible}
        width={550}
        footer={null}
        onCancel={onCancel}
        wrapClassName="custom-modal"
      >
        <Form
          form={form}
          name="basic"
          style={{
            fontSize: 13,
          }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={onFinish}
          acceptCharset="utf-8"
        >
          <Form.Item
            name="file_source"
            label="Эх сурвалж"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <AntSelect placeholder="Сонгох">
              {sourceTypes?.source_types?.map((i) => (
                <AntSelect.Option value={i.value}>{i.name}</AntSelect.Option>
              ))}
            </AntSelect>
          </Form.Item>
          <Form.Item
            name="file_type"
            label="Файлын төрөл"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <AntSelect placeholder="Сонгох">
              {sourceTypes?.file_types?.map((i) => (
                <AntSelect.Option value={i.value}>{i.name}</AntSelect.Option>
              ))}
            </AntSelect>
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const fileType = getFieldValue('file_type');
              if (fileType === 4) {
                return (
                  <Form.Item
                    name="tovchoo_month"
                    label="Хамаарах сар"
                    rules={[
                      {
                        required: true,
                        message: 'Хамаарах сарыг сонгоно уу !',
                      },
                    ]}
                  >
                    <Select placeholder="Сар сонгоно уу">
                      {Array.from({ length: 12 }, (_, i) => (
                        <Option key={i + 1} value={i + 1}>
                          {`${i + 1} -р сар`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                );
              }
            }}
          </Form.Item>

          <Form.Item
            name="file"
            label="Файл"
            labelAlign="left"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: 'Файл сонгоно уу' }]}
          >
            <Upload
              accept={typesList.join()}
              showUploadList
              maxCount={1}
              beforeUpload={(File) => {
                const isXls = typesList.find((i) => i === File.type);
                if (!isXls) {
                  message.error('Зөвхөн xls, xlsx өргөтгөлтэй файл оруулна уу');
                  return Upload.LIST_IGNORE;
                }
                return false;
              }}
            >
              <CloudUploadOutlined className="fs-30" />
            </Upload>
          </Form.Item>
          <Form.Item label="Тэмдэглэл:" name="comment" labelAlign="left">
            <TextArea
              autoSize={{ minRows: 3, maxRows: 5 }}
              disabled={false}
              className="ww-100"
              maxLength={300}
              showCount
            />
          </Form.Item>
          <Row>
            <Col span={24} className="text-align-r">
              <Form.Item noStyle>
                <AntButton
                  onClick={onCancel}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Цуцлах
                </AntButton>
                <AntButton
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </AntButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Тэмдэглэл"
        visible={comment}
        width={550}
        footer={null}
        onCancel={onCancel}
        wrapClassName="custom-modal"
      >
        <AddValue onCancel={onCancel} />
      </Modal>
    </>
  );
};
export default Client;
