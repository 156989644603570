import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from 'src/components';
// import { useQuery } from 'src/core/utils/formater';
import EditableTable from 'src/components/common/editableTable';
import { Modal, Form, Button } from 'antd';

import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';
import { PicLeftOutlined } from '@ant-design/icons';
// import { useHistory } from 'react-router-dom';
import { mainColumns, infoColumns } from './dataStructure/data';
import { ConfirmData } from './confirm';

const _modalBody = {
  className: 'custom-modal',
  title: 'Тохиргооны загвар тооцооллын дагуу тохиргоо хийх',
  visible: false,
  closable: false,
  maskClosable: false,
  width: 600,
  footer: null,
};

const defaultHeader = 'Дансны жагсаалт';

const MainPage = () => {
  const dispatch = useDispatch();

  // const query = useQuery();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const [form] = Form.useForm();
  // const history = useHistory();

  const [tableData, setTableData] = useState([]);
  const [infoTable, seTinfoTable] = useState({ json_array: [], total: 0 });

  const [infoTableTitle, seTinfoTableTitle] = useState(defaultHeader);
  const [currentInfoId, seTcurrentInfoId] = useState(null);
  const [modalBody, seTmodalBody] = useState(_modalBody);

  const getBulegData = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setTableData(data.content.json_array);
      } else {
        setTableData([]);
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_holboos_nemelt',
      content: { action_type: 'buleg_get_list' },
    });

    dispatch(start_loading({}));
  };

  const getBulegDansData = (id, month) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        seTinfoTable(data.content);
        seTcurrentInfoId(id);
      } else {
        seTinfoTable([]);
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    // const page = query.get('page');
    // const size = query.get('size');
    // const search = {};

    socket.emit('request_in', {
      channel: 'ts001_02_dans_holboos_nemelt',
      content: {
        action_type: 'buleg_get_dans_list_dun',
        id,
        month,
        // paging: { page, size, search },
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    getBulegData();
  }, []);

  useEffect(() => {
    if (currentInfoId) {
      getBulegDansData(currentInfoId, data_date.month);
    }
    // }, [data_date, query]);
  }, [data_date]);

  const calculateAll = () => {
    const content = {
      data: infoTable,
      action_type: 'buleg_dans_calculate',
      month: data_date.month,
    };
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Тохиргоо амжилттай хийгдлээ' });
        getBulegDansData(currentInfoId, data_date.month);
      } else if (data.status === '5') {
        newAlert({ type: 'error', msg: 'Жагсаалт хоосон байна' });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_holboos_nemelt',
      content,
    });
    dispatch(start_loading({}));
  };

  const calculateAction = () => {
    seTmodalBody((prev) => ({
      ...prev,
      visible: true,
    }));
  };

  const _title = (title = '', info = false) => (
    <>
      <div className="title">
        <span> {title || ''}</span>
      </div>
      {info ? (
        <Button
          className="add-btn"
          type="primary"
          size="small"
          icon={<PicLeftOutlined />}
          onClick={calculateAction}
          disabled={infoTableTitle === defaultHeader}
        >
          Тохиргоо хийх
        </Button>
      ) : (
        <></>
      )}
    </>
  );

  const onCancel = () => {
    seTmodalBody((prev) => ({
      ...prev,
      visible: false,
    }));
  };

  const addTitle = () => _title('Бүлэг');
  const infoTitle = () => _title(infoTableTitle, true);

  const title = {
    addTitle,
    infoTitle,
  };

  const makeInfo = (data) => {
    seTinfoTableTitle(`Дансны жагсаалт: ${data?.ner}`);
    getBulegDansData(data.id, data_date.month);
    // // Delete page search from query
    // query.delete('page');
    // history.push({ search: query.toString() });
  };

  const geTvalue = (values) => {
    seTinfoTable((prev) => ({
      ...prev,
      json_array: [...values],
    }));
  };

  return (
    <>
      <div className="tohirgo-zagvar-burtgel">
        <div className="main-container">
          <Table
            columns={mainColumns({
              makeInfo,
            })}
            dataSource={tableData}
            scroll={{
              y: 'calc(100vh - 230px)',
            }}
            title={title.addTitle}
          />
        </div>
        <div className="info-container">
          <EditableTable
            columns={infoColumns()}
            dataSource={infoTable?.json_array}
            scroll={{
              y: 'calc(100vh - 230px)',
            }}
            title={title.infoTitle}
            geTvalue={geTvalue}
            // total={infoTable?.total}
          />
        </div>
      </div>

      <Modal {...modalBody}>
        <ConfirmData
          form={form}
          onCancel={onCancel}
          confirmData={calculateAll}
        />
      </Modal>
    </>
  );
};

export default MainPage;
