import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from 'src/components';
import { Modal, Form, Row, Col, Button, Select, Input, Divider } from 'antd';
import newAlert from 'src/components/newAlert';
import { customColumns } from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';

const { Option } = Select;

const MainPage = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const [tableData, setTableData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const generateTailan = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data.content.json_array);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: { action_type: 'get_dans_angilal_sub_list' },
    });
    dispatch(start_loading({}));
  };

  const saveValues = (saveData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: { action_type: 'set_dans_angilal_sub', data: saveData },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    generateTailan();
  }, []);

  const editAction = (row) => {
    form.setFieldsValue({
      dans_id: row?.dans_id,
      dugaar: row?.dugaar,
      ner: row?.ner,
      angilal: `${row?.code} : ${row?.angilal_ner}`,
      angilal_sub: row?.angilal_sub,
    });
    setVisible(true);
  };

  const title = () => (
    <>
      <div className="table-title-text-center">Дансны дэд ангилал</div>
    </>
  );

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          columns={customColumns({
            customEdit: editAction,
          })}
          dataSource={tableData}
          scroll={{
            y: 'calc(100vh - 250px)',
          }}
          title={title}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Дансны дэд ангилал шинэчлэх"
        open={visible}
        closable={false}
        maskClosable={false}
        width={600}
        footer={null}
      >
        <CustomModal
          onClose={() => {
            setVisible(false);
          }}
          form={form}
          saveValues={saveValues}
        />
      </Modal>
    </>
  );
};

const layout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 17 },
    sm: { span: 17 },
  },
};

const CustomModal = ({
  onClose = () => {},
  form = null,
  saveValues = () => {},
}) => {
  const onFinish = (values) => {
    saveValues(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="dans_id" hidden />
        {/* <Divider>Данс</Divider> */}
        <Form.Item name="dugaar" label="Дансны дугаар" labelAlign="left">
          <Input className="w-100" disabled />
        </Form.Item>
        <Form.Item name="ner" label="Дансны нэр" labelAlign="left">
          <Input className="w-100" disabled />
        </Form.Item>
        <Divider>Ангилал</Divider>
        <Form.Item name="angilal" label="Үндсэн ангилал" labelAlign="left">
          <Input className="w-100" disabled />
        </Form.Item>
        <Form.Item name="angilal_sub" label="Дэд ангилал" labelAlign="left">
          <Select defaultValue={null}>
            <Option value={null}>---</Option>
            <Option value="bodit">Бодит</Option>
            <Option value="bodit_bus">Бодит бус</Option>
          </Select>
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MainPage;
