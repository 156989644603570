import React from 'react';
import { roundFloat } from 'src/core/utils/formater';

export const customSummary = [
  {
    align: 'left',
    word: 'Нийт:',
  },
  {
    align: 'right',
    value: 'bor_ebar_niit',
  },
  {
    align: 'right',
    value: 'choloogdoh_orlogo',
  },
  {
    align: 'right',
    value: 'choloogdohgui_orlogo',
  },
  {
    align: 'right',
    value: 'bor_nhat',
  },
  {
    align: 'right',
    value: 'bor_noat_dun',
  },
  {
    align: 'right',
    value: 'hu_ebar_niit',
  },
  {
    align: 'right',
    value: 'hu_import',
  },
  {
    align: 'right',
    value: 'hu_dotood',
  },
  {
    align: 'right',
    value: 'uh_huvi',
  },
  {
    align: 'right',
    value: 'hu_noat_dun',
  },
  {
    align: 'right',
    value: 'suudliin_mashin',
  },
  {
    align: 'right',
    value: 'huvi_heregtseend',
  },
  {
    align: 'right',
    value: 'choloologdoh_uildver',
  },
  {
    align: 'right',
    value: 'ashiglah_umnuh',
  },
  {
    align: 'right',
    value: 'tailan_hamaaralgui',
  },
  {
    align: 'right',
    value: 'hasagdahgui_noat_dun',
  },
  {
    align: '',
    value: 'zalruulgiin_noat',
  },
  {
    align: 'right',
    value: 'tulbur_zohih_noat',
  },
];

export const columns = [
  {
    title: 'Сар',
    dataIndex: 'month',
    align: 'center',
    width: 80,
    fixed: 'left',
    render: (_, row) => <>{`${row.month} сар`}</>,
  },
  {
    title: 'Борлуулалт',
    className: 'cell-left-border',
    children: [
      {
        title: 'Нийт дүн (НӨАТ-тэй)',
        dataIndex: 'bor_ebar_niit',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Чөлөөгдөх орлого',
        dataIndex: 'choloogdoh_orlogo',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Чөлөөлөгдөхгүй орлого',
        dataIndex: 'choloogdohgui_orlogo',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НХАТ',
        dataIndex: 'bor_nhat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ-ын дүн',
        dataIndex: 'bor_noat_dun',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Худалдан авалт',
    className: 'cell-left-border',
    children: [
      {
        title: 'Нийт дүн (НӨАТ-тэй)',
        dataIndex: 'hu_ebar_niit',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
      {
        title: 'Импортын',
        dataIndex: 'hu_import',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Дотоод',
        dataIndex: 'hu_dotood',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Үндсэн хөрөнгө (хувь тэнцүүлэх)',
        dataIndex: 'uh_huvi',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ-ын дүн',
        dataIndex: 'hu_noat_dun',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Хасагдахгүй НӨАТ-ын дүн',
        className: 'cell-left-border',
        children: [
          {
            title: 'Суудлын автомашин, түүний сэлбэгт',
            dataIndex: 'suudliin_mashin',
            align: 'right',
            width: 150,
            className: 'cell-left-border',
            render: (_) => roundFloat(_),
          },
          {
            title: 'Хувьдаа, ажиллагсдын хэрэгцээнд',
            dataIndex: 'huvi_heregtseend',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'Чөлөөлөгдөх үйлдвэрлэл үйлчилгээнд (13-р зүйл)',
            dataIndex: 'choloologdoh_uildver',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'Ашиглалтын өмнөх үйл ажиллагаанд',
            dataIndex: 'ashiglah_umnuh',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'Тайлант хугацаанд хамааралгүй',
            dataIndex: 'tailan_hamaaralgui',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
      {
        title: 'НӨАТ-ын дүн (хасагдахгүй НӨАТ хассан)',
        dataIndex: 'hasagdahgui_noat_dun',
        align: 'right',
        width: 150,
        className: 'cell-left-border',
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Залруулгын НӨАТ-ын дүн (буцаалт хөнгөлөлт)',
    dataIndex: 'zalruulgiin_noat',
    align: 'right',
    width: 150,
    className: 'cell-left-border',
    render: (_) => roundFloat(_),
  },
  {
    title: 'Төлбөл зохих НӨАТ-ын дүн',
    dataIndex: 'tulbur_zohih_noat',
    align: 'right',
    width: 150,
    render: (_) => roundFloat(_),
  },
];
