import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from 'src/components';
import { PlusCircleOutlined } from '@ant-design/icons';
import {
  Modal,
  Empty,
  Form,
  Row,
  Col,
  Button,
  Select,
  Typography,
  Divider,
  Checkbox,
} from 'antd';

import { isEmpty } from 'lodash';
import { onFilter } from 'src/core/utils/selectFilter';
import newAlert from 'src/components/newAlert';
import { customColumns } from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';

const { Text } = Typography;
const { Option } = Select;

const MainPage = () => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);

  const [tableData, setTableData] = useState([]);
  const [dansInfo, setDansInfo] = useState(null);
  const [dansProp, setDansProp] = useState(null);
  const [visible, setVisible] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [addForm] = Form.useForm();
  const [deleteForm] = Form.useForm();

  const generateTailan = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setTableData(data.content.json_array);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_holboos_elegdel',
      content: { action_type: 'get_list' },
    });

    dispatch(start_loading({}));
  };

  const getProps = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setDansInfo(data?.content?.json_array);
        setDansProp(data?.content?.props);
        generateTailan();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_holboos_elegdel',
      content: { action_type: 'get_list_available' },
    });

    dispatch(start_loading({}));
  };

  const saveValues = (saveData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_holboos_elegdel',
      content: { action_type: 'update', data: saveData },
    });

    dispatch(start_loading({}));
  };

  const addValue = (saveData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan();
      } else if (data.status === '5') {
        newAlert({ type: 'error', msg: 'Дансны холболт бүртгэлтэй байна' });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_holboos_elegdel',
      content: {
        action_type: 'add',
        data: saveData,
      },
    });

    dispatch(start_loading({}));
  };

  const deleteValue = (deleteData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай устгагдлаа' });
        generateTailan();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_holboos_elegdel',
      content: {
        action_type: 'delete',
        id: deleteData.delete_id,
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    setLoading(true);
    getProps();
  }, []);

  const editAction = (row) => {
    form.setFieldsValue({
      id: row?.id,
      dans_id: row?.dans_id,
      dans_id_elegdel: row?.dans_id_elegdel,
      burtgel_zadlah: row?.burtgel_zadlah,
      zuruu_tootsoh: row?.zuruu_tootsoh,
      hurungu_turul: row?.hurungu_turul,
      hurungu_buleg: row?.hurungu_buleg,
    });

    setVisible(true);
  };

  const addAction = () => {
    setAddVisible(true);
  };

  const deleteAction = (row) => {
    deleteForm.setFieldsValue({
      delete_id: row?.id,
    });

    setDeleteVisible(true);
  };

  useEffect(() => {
    if (!isEmpty(dansInfo)) {
      setLoading(false);
    }
  }, [dansInfo]);

  if (loading) {
    return <Empty description="Үндсэн хөрөнгийн данс ангилна уу..." />;
  }

  const title = () => (
    <>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          addAction();
        }}
      >
        Данс холболт нэмэх
      </Button>
    </>
  );

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          columns={customColumns({
            customEdit: editAction,
            customDelete: deleteAction,
          })}
          dataSource={tableData}
          scroll={{
            y: 'calc(100vh - 250px)',
          }}
          title={title}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Элэгдлийн данс холболт шинэчлэх"
        visible={visible}
        closable={false}
        maskClosable={false}
        width={820}
        footer={null}
      >
        <CustomModal
          onClose={() => {
            setVisible(false);
          }}
          form={form}
          listData={dansInfo}
          saveValues={saveValues}
          dansProp={dansProp}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Данс холболт нэмэх"
        visible={addVisible}
        closable={false}
        maskClosable={false}
        width={820}
        footer={null}
      >
        <AddModal
          onClose={() => {
            setAddVisible(false);
          }}
          form={addForm}
          listData={dansInfo}
          saveValues={addValue}
          dansProp={dansProp}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Элэгдлийн данс холболт устгах"
        visible={deleteVisible}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <DeleteModal
          onClose={() => {
            setDeleteVisible(false);
          }}
          form={deleteForm}
          deleteValue={deleteValue}
        />
      </Modal>
    </>
  );
};

const layout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 17 },
    sm: { span: 17 },
  },
};

const CustomModal = ({
  onClose = () => {},
  form = null,
  listData = [],
  saveValues = () => {},
  dansProp = {},
}) => {
  const onFinish = (values) => {
    saveValues(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="id" hidden />
        <Form.Item
          name="dans_id"
          label="Үндсэн данс"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Select
            defaultValue={null}
            showSearch
            filterOption={onFilter}
            placeholder="Сонгох"
          >
            {listData.map((i) => (
              <Option value={i?.dans_id}>
                {i?.dans_dugaar} {i?.dans_ner}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="dans_id_elegdel"
          label="Хуримтлагдсан элэгдлийн данс"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Select
            defaultValue={null}
            showSearch
            filterOption={onFilter}
            placeholder="Сонгох"
          >
            {listData.map((i) => (
              <Option value={i?.dans_id}>
                {i?.dans_dugaar} {i?.dans_ner}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Divider plain>Нэмэлт тохиргоо</Divider>
        <Form.Item
          name="burtgel_zadlah"
          label="Бүртгэл задлах"
          labelAlign="left"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="zuruu_tootsoh"
          label="Зөрүү тооцох"
          labelAlign="left"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="hurungu_turul"
          label="Хөрөнгийн төрөл"
          labelAlign="left"
        >
          <Select defaultValue={null}>
            <Select.Option value={null}>---</Select.Option>
            {dansProp?.uh_turul.map((i) => (
              <Select.Option value={i?.value}>{i?.data}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="hurungu_buleg"
          label="Хөрөнгийн бүлэг"
          labelAlign="left"
        >
          <Select defaultValue={null}>
            <Select.Option value={null}>---</Select.Option>
            {dansProp?.uh_buleg.map((i) => (
              <Select.Option value={i?.value}>{i?.data}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const AddModal = ({
  onClose = () => {},
  form = null,
  listData = [],
  saveValues = () => {},
  dansProp = {},
}) => {
  const onFinish = (values) => {
    saveValues(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div
      className="dans-medeelel-container"
      style={{ overflowWrap: 'break-word' }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item
          name="dans_id"
          label="Үндсэн данс"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Select
            defaultValue={null}
            showSearch
            filterOption={onFilter}
            placeholder="Сонгох"
          >
            {listData.map((i) => (
              <Option value={i?.dans_id}>
                {i?.dans_dugaar} {i?.dans_ner}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="dans_id_elegdel"
          label="Хуримтлагдсан элэгдлийн данс"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Select
            defaultValue={null}
            showSearch
            filterOption={onFilter}
            placeholder="Сонгох"
          >
            {listData.map((i) => (
              <Option value={i?.dans_id}>
                {i?.dans_dugaar} {i?.dans_ner}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Divider plain>Нэмэлт тохиргоо</Divider>
        <Form.Item
          name="burtgel_zadlah"
          label="Бүртгэл задлах"
          labelAlign="left"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="zuruu_tootsoh"
          label="Зөрүү тооцох"
          labelAlign="left"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="hurungu_turul"
          label="Хөрөнгийн төрөл"
          labelAlign="left"
        >
          <Select defaultValue={null}>
            <Select.Option value={null}>---</Select.Option>
            {dansProp?.uh_turul.map((i) => (
              <Select.Option value={i?.value}>{i?.data}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="hurungu_buleg"
          label="Хөрөнгийн бүлэг"
          labelAlign="left"
        >
          <Select defaultValue={null}>
            <Select.Option value={null}>---</Select.Option>
            {dansProp?.uh_buleg.map((i) => (
              <Select.Option value={i?.value}>{i?.data}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const DeleteModal = ({
  onClose = () => {},
  form = null,
  deleteValue = () => {},
}) => {
  const onFinish = (values) => {
    deleteValue(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="delete_id" hidden />
        <Text>Та энэ үйлдлийг хийхдээ итгэлтэй байна уу?</Text>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Устгах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MainPage;
