const oDefaultState = {
  year: 'Сонгогдоогүй',
  year_end: 'Сонгогдоогүй',
  is_first_year: false,
};

const reducer = (state = oDefaultState, action) => {
  switch (action.type) {
    case 'set_version':
      const oNewState = {
        year: action?.payload?.year?.toString(),
        year_end: action?.payload?.year_end?.toString(),
        is_first_year: action?.payload?.is_first_year,
      };

      return oNewState;
    case 'remove_version':
      return oDefaultState;
    default:
      return state;
  }
};

export default reducer;
