import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'src/core/utils/axios';
import { Button } from 'antd';

import { ExpandedTable } from 'src/components';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ExportOutlined } from '@ant-design/icons';

import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { customColumns, customSummary } from './dataStructure/data';

const FileDownload = require('js-file-download');

const MainPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data?.status === '0') {
        setTableData(data?.content);
      } else {
        setTableData([]);
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_control',
      content: { action_type: 'generate_ajil_husnegt', month },
    });

    dispatch(start_loading({}));
  };

  /**
   * Export tailan to xlsx format
   */
  const exportTailan = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        const file_export_address_link = `/get_file?file_id=${data.content.file_id}`;
        axios.get('export', file_export_address_link, {}, {}, (event) => {
          FileDownload(event, 'Ажлын хүснэгт.xlsx');
        });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_control',
      content: {
        action_type: 'generate_ajil_husnegt',
        month: data_date.month,
        receive_channel: 'ss010_00_export_file',
        receive_action_type: 'export_ajliin_husnegt',
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
  }, [data_date, t]);

  useEffect(() => {
    if (!isEmpty(tableData)) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [tableData]);

  if (loading) {
    return '';
  }

  const title = () => (
    <>
      <div className="table-title-text-center">Ажлын хүснэгт /СТ-30(А)/</div>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<ExportOutlined />}
        onClick={() => {
          exportTailan();
        }}
      >
        Экспорт
      </Button>
    </>
  );

  return (
    <>
      <div className="table-antd-div no-scroll">
        <ExpandedTable
          columns={customColumns()}
          dataSource={tableData?.json_array}
          scroll={{
            x: 'max-content',
            y: 'calc(100vh - 250px)',
          }}
          summaryList={{
            sumColumn: customSummary({ t }),
            sumDataSource: tableData?.sum_data,
          }}
          title={title}
        />
      </div>
    </>
  );
};

export default MainPage;
