import React from 'react';
import { Table } from 'antd';
import { columns, dataSource, getTag } from './dataStructure/data';

const TmzMonitoring = () => {
  console.log('pp');

  return (
    <div className="tmz-monitoring">
      <div className="header">
        <p>ТМЗ мониторинг</p>
      </div>
      <div className="container">
        <div className="form-section">
          <p
            style={{
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            Компанийн жагсаалт
          </p>
          <div
            style={{
              marginLeft: 45,
            }}
          >
            <p>
              <span>Нэр:</span> 112109 Монгол Такс
            </p>
            <p>
              <span>Компани төрөл:</span> ТМЗ
            </p>
            <p>
              <span>Лизенз төлөв:</span> {getTag('active')}
            </p>
            <p>
              <span>Лиценз эхлэсэн огноо:</span> 2021/01/11
            </p>
            <p>
              <span>Лиценз дууссан огноо:</span> 2021/11/11
            </p>
          </div>
        </div>
        <div className="table-section">
          <p>Уригдсан компани</p>
          <div
            style={{
              margin: '0px 12px',
            }}
          >
            <Table
              className="audit-table"
              columns={columns}
              dataSource={dataSource}
              rowClassName="audit-row"
              pagination={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default TmzMonitoring;
