import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { onlyNumber, roundFloat } from 'src/core/utils/formater';
import CustomDatePicker from 'src/components/common/datePicker';
import {
  Typography,
  Button,
  Form,
  Row,
  Col,
  Modal,
  Input,
  Divider,
} from 'antd';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from '../../../core/actions/loader';

const { Text } = Typography;

export const UpdateAnhUrtugBurtgel = ({
  openDialog,
  onCancel,
  data,
  hurungu_info,
}) => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const [form] = Form.useForm();

  const handleDialogClose = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (data) {
      const new_values = {
        urtug_anh_update:
          data?.bodit_urtug_anh > 0 ? data?.bodit_urtug_anh : data?.urtug_anh,
        ashiglaj_ehelsen_update: '',
        ashiglaj_duusah_update: '',
        burtgel_id: data?.burtgel_id,
      };
      if (data?.ashiglaj_ehelsen) {
        new_values.ashiglaj_ehelsen_update = data?.bodit_ashiglaj_ehelsen
          ? data?.bodit_ashiglaj_ehelsen
          : data?.ashiglaj_ehelsen;
      }
      if (data?.ashiglaj_duusah) {
        new_values.ashiglaj_duusah_update = data?.ashiglaj_duusah;
      }
      form.setFieldsValue(new_values);
    }
  }, [data]);

  const handleSave = () => {
    const new_data = {
      burtgel_id: form.getFieldValue('burtgel_id'),
      bodit_ashiglaj_ehelsen: form.getFieldValue('ashiglaj_ehelsen_update'),
      ashiglaj_duusah: form.getFieldValue('ashiglaj_duusah_update'),
      bodit_urtug_anh: form.getFieldValue('urtug_anh_update'),
    };
    socket.on('request_out', (req_data) => {
      socket.removeAllListeners('request_out');
      if (req_data.status === '0') {
        form.resetFields();
        onCancel();
        newAlert({ type: 'success', msg: req_data.message });
      } else {
        newAlert({ type: 'error', msg: req_data.message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: {
        action_type: 'anh_urtug_burtgel',
        json_array: { ...new_data },
      },
    });
    dispatch(start_loading({}));
  };

  const onFinish = (values) => {
    handleSave(values);
  };

  const layout = {
    labelCol: {
      xs: { span: 9 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 15 },
      sm: { span: 15 },
    },
  };

  return (
    <Modal
      className="custom-modal"
      title="Анхны өртөг өөрчлөх"
      open={openDialog}
      closable={false}
      maskClosable={false}
      width={600}
      footer={null}
      onCancel={handleDialogClose}
    >
      <div className="dans-medeelel-container">
        <Form
          form={form}
          onFinish={onFinish}
          className="register-form"
          {...layout}
          size="small"
        >
          <Form.Item name="burtgel_id" hidden />
          <Form.Item label="Үндсэн хөрөнгийн нэр" labelAlign="left">
            <Text ellipsis>{hurungu_info?.ner}</Text>
          </Form.Item>
          <Form.Item label="Үндсэн хөрөнгийн код" labelAlign="left">
            <Text>{hurungu_info?.code}</Text>
          </Form.Item>
          <Form.Item
            name="burtgel_dugaar"
            label="Бүртгэлийн дугаар"
            labelAlign="left"
          >
            <Text>{data?.burtgel_dugaar}</Text>
          </Form.Item>
          <Divider />
          <Form.Item label="Анхны өртөг" labelAlign="left">
            <Text>
              {roundFloat(
                data?.bodit_urtug_anh > 0
                  ? data?.bodit_urtug_anh
                  : data?.urtug_anh
              )}
            </Text>
          </Form.Item>
          <Form.Item
            name="urtug_anh_update"
            label="Анхны өртөг (Шинэ)"
            labelAlign="left"
            normalize={onlyNumber}
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input className="w-100" placeholder="Дүн оруулна уу..." />
          </Form.Item>
          <Form.Item
            name="ashiglaj_ehelsen"
            label="Ашиглаж эхэлсэн"
            labelAlign="left"
          >
            <Text>
              {data?.bodit_ashiglaj_ehelsen
                ? data?.bodit_ashiglaj_ehelsen
                : data?.ashiglaj_ehelsen}
            </Text>
          </Form.Item>
          <Form.Item
            name="ashiglaj_ehelsen_update"
            label="Ашиглаж эхэлсэн (Шинэ)"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <CustomDatePicker customClass="ww-100 h-25" />
          </Form.Item>
          <Form.Item
            name="ashiglaj_duusah"
            label="Ашиглаж дуусах"
            labelAlign="left"
          >
            <Text>{data?.ashiglaj_duusah}</Text>
          </Form.Item>
          <Form.Item
            name="ashiglaj_duusah_update"
            label="Ашиглаж дуусах (Шинэ)"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <CustomDatePicker customClass="ww-100 h-25" />
          </Form.Item>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <Button
                  onClick={() => {
                    form.resetFields();
                    handleDialogClose();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </Button>
                <Button
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
