import { roundFloat } from 'src/core/utils/formater';

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Хойшлогдсон татварын өр төлбөрийн нийт дүн:',
  },
  {
    align: 'right',
    value: 'tur_zuruu_eh',
  },
  {
    align: 'right',
    value: 'ehnii_uld_zalruulga',
  },
  {
    align: 'right',
    value: 'tur_zuruu_deb',
  },
  {
    align: 'right',
    value: 'tur_zuruu_cred',
  },
  {
    align: 'right',
    value: 'tur_zuruu_ets',
  },
];

export const customColumns = () => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    titleAlign: 'center',
    fixed: 'left',
    width: 250,
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    align: 'left',
    titleAlign: 'center',
    width: 300,
    ellipsis: true,
  },
  {
    title: 'Татвар ногдох түр зөрүүгийн эхний үлдэгдэл',
    dataIndex: 'tur_zuruu_eh',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => {
      if (row?.mor_type === 'percent') {
        return {
          children: `${roundFloat(row?.tur_zuruu_eh).toString()} %`,
        };
      }
      return {
        children: roundFloat(row?.tur_zuruu_eh),
      };
    },
  },
  {
    title: 'Эхний үлдэгдлийн залруулга',
    dataIndex: 'ehnii_uld_zalruulga',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => {
      if (row?.mor_type === 'percent') {
        return {};
      }
      return {
        children: roundFloat(row?.ehnii_uld_zalruulga),
      };
    },
  },
  {
    title: 'Дебет',
    dataIndex: 'tur_zuruu_deb',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => {
      if (row?.mor_type === 'percent') {
        return {
          children: `${roundFloat(row?.tur_zuruu_deb).toString()} %`,
        };
      }
      return {
        children: roundFloat(row?.tur_zuruu_deb),
      };
    },
  },
  {
    title: 'Кредит',
    dataIndex: 'tur_zuruu_cred',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => {
      if (row?.mor_type === 'percent') {
        return {
          children: `${roundFloat(row?.tur_zuruu_cred).toString()} %`,
        };
      }
      return {
        children: roundFloat(row?.tur_zuruu_cred),
      };
    },
  },
  {
    title: 'Татвар ногдох түр зөрүүгийн эцсийн үлдэгдэл',
    dataIndex: 'tur_zuruu_ets',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => {
      if (row?.mor_type === 'percent') {
        return {
          children: `${roundFloat(row?.tur_zuruu_ets).toString()} %`,
        };
      }
      return {
        children: roundFloat(row?.tur_zuruu_ets),
      };
    },
  },
];
