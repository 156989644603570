import React from 'react';
import { SendOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { roundFloat } from 'src/core/utils/formater';

export const columns = ({ check_zardal_type = () => {} }) => [
  {
    title: 'Заалт',
    align: 'center',
    dataIndex: 'zaalt',
    titleAlign: 'center',
    width: 50,
  },
  {
    title: 'Зардлын төрөл',
    align: 'left',
    titleAlign: 'center',
    width: 200,
    dataIndex: 'zardliin_torol',
  },
  {
    title: 'Хязгаар, нөхцөл',
    align: 'left',
    // className: 'fixed-150',
    // ellipsis: true,
    titleAlign: 'center',
    dataIndex: 'hyazgaar_nohtsol',
  },
  {
    title: 'Дансны дүн',
    align: 'right',
    width: 100,
    titleAlign: 'center',
    dataIndex: 'dans_dun',
    render: (_) => roundFloat(_),
  },
  {
    title: 'Хүлээн зөвшөөрөх зардал',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'huleen_zowshooroh_zardal',
    render: (_) => roundFloat(_),
  },
  {
    title: 'Хэтэрсэн дүн',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'hetersen_dun',
    render: (_) => roundFloat(_),
  },
  {
    title: 'Журналын төлөв',
    align: 'center',
    titleAlign: 'center',
    width: 100,
    render: (_, row) => {
      if (row.state === 0) {
        return (
          <div>
            <Tooltip title="Журнал холбох">
              <SendOutlined
                onClick={() =>
                  check_zardal_type(
                    row.zaalt_id,
                    row.huleen_zowshooroh_zardal,
                    row.hetersen_dun
                  )
                }
                style={{ color: 'green', fontSize: 15 }}
              />
            </Tooltip>
          </div>
        );
      }
      if (row.state === 1) {
        return (
          <div>
            <Tooltip title="Дүн өөрчилөгдсөн">
              <SendOutlined
                onClick={() =>
                  check_zardal_type(
                    row.zaalt_id,
                    row.huleen_zowshooroh_zardal,
                    row.hetersen_dun
                  )
                }
                style={{ color: 'red', fontSize: 15 }}
              />
            </Tooltip>
          </div>
        );
      }
      if (row.state === 2) {
        return (
          <div>
            <Tooltip title="Холбогдсон">
              <SendOutlined style={{ color: 'grey', fontSize: 15 }} />
            </Tooltip>
          </div>
        );
      }
      if (row.state === 3) {
        return (
          <div>
            <Tooltip title="Дүн зөрүүтэй">
              <SendOutlined style={{ color: 'orange', fontSize: 15 }} />
            </Tooltip>
          </div>
        );
      }
      return <div />;
    },
  },
];
