import React, { useState, useEffect } from 'react';
import { Alert, Progress as AntProgress, Spin, Button, Card, List } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import DescriptionContainer from 'src/components/common/descriptionContainer';

export const Progress = ({
  socket = null,
  getPrecent = () => {},
  currentPercent,
}) => {
  const [percent, setPercent] = useState(currentPercent);

  useEffect(() => {
    socket.on('update_percentage_ogogdol', ({ content }) => {
      console.log('🚀 ~ file: index.js:25 ~ socket.on ~ content:', content);
      if (content?.percent) {
        setPercent(content.percent);
      }
    });
  }, [socket]);

  useEffect(() => {
    if (percent === 100) {
      setTimeout(() => {
        getPrecent(true);
      }, 1000);
    }
  }, [percent]);

  return (
    <div className="progress-container">
      <p className="blink">Өгөгдөл шалгаж байна ... </p>
      <AntProgress
        strokeColor={{
          '0%': '#108ee9',
          '100%': '#87d068',
        }}
        type="circle"
        percent={percent}
        width={200}
      />
    </div>
  );
};

export const Confirm = ({ isLoading, activeData }) => {
  const temp_data = [
    {
      title: 'Гүйлгээ жагсаалт:',
      type: 'guilgee',
    },
    {
      title: 'Авлага өглөгийн жагсаалт:',
      type: 'au',
    },
    {
      title: 'Үндсэн хөрөнгийн жагсаалт:',
      type: 'uh',
    },
    // {
    //   title: 'Үндсэн хөрөнгийн товчоо:',
    //   type: 'uh_tovchoo',
    // },
    // {
    //   title: 'Үндсэн хөрөнгийн товчоо(Бүртгэлийн дугаартай)',
    //   type: 'uh_burtgel',
    // },
  ];
  const dispatch = useDispatch();

  return (
    <>
      <div className="validation-container">
        <div className="head-container">
          <p>Баталгаажуулах гэж буй төрлүүд (хамаарах уншуулсан файлын тоо)</p>
          <p className="validation-description">
            Таны өгөгдөл оруулах цонхон дээр уншуулсан жагсаалтуудын дүнг тэнцэж
            байгаа эсэхийг шалгах гэж байна
          </p>
        </div>

        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 6,
            xxl: 3,
          }}
          dataSource={temp_data}
          renderItem={(item) => (
            <List.Item key={item.type}>
              <Card className="card">
                <p className="card-title">{item.title}</p>
                <div className="card-content">
                  {activeData.counter[item.type]}
                </div>
              </Card>
            </List.Item>
          )}
        />
        {activeData.accepted ? (
          <p className="warning-validation">{activeData?.msg}</p>
        ) : null}
      </div>
    </>
  );
};

export const Actived = ({ data = null }) => {
  console.log('🚀 ~ file: index.js:104 ~ Actived ~ data:', data);
  const [isExpanded, setIsExpanded] = useState(false);
  const [errorData, setErrorData] = useState([]);

  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };
  const renderListWithCommas = (list) => list.join(', ');

  useEffect(() => {
    const temp_list = [];

    if (data.error_dans_list?.error_au) {
      temp_list.push({
        title: 'Авлага өглөг',
        content: renderListWithCommas(data.error_dans_list.error_au),
      });
    }

    if (data.error_dans_list?.error_uh) {
      temp_list.push({
        title: 'Үндсэн хөрөнгө',
        content: renderListWithCommas(data.error_dans_list.error_uh),
      });
    }

    setErrorData(temp_list);
  }, [data]);

  return (
    <div className="actived-container">
      <div className="section-1">
        <p>
          Баталгаажуулсан хэрэглэгч : <span className="user">{data?.name}</span>{' '}
        </p>
        <p className="date">Баталгаажуулсан огноо : {data?.ognoo}</p>
      </div>
      <Alert
        className="fs-13"
        message={data?.error_msg?.title || 'Хоосон'}
        description={data?.error_msg?.text || 'Хоосон'}
        type={data?.error_msg?.type}
        showIcon
      />

      {data.error_dans_list && (
        <Button
          icon={<CloudUploadOutlined />}
          className="upload-btn ml-5 mt-10"
          shape="round"
          onClick={handleButtonClick}
        >
          {isExpanded ? 'Хураах' : 'Алдаатай дансны жагсаалт харах'}
        </Button>
      )}

      {isExpanded && (
        <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
          <div>
            <DescriptionContainer data={errorData} />
          </div>
        </div>
      )}

      <div className="section-2">
        <p>
          Эхлэх, дуусах сар: {data?.start_date} ~ {data?.end_date}{' '}
        </p>
      </div>

      <div className="section-3">
        <p className="title">Баталгаажуулсан жагсаалтын төрлүүд</p>
        <table className="table">
          <tbody>
            <tr>
              <th className="p-5">Гүйлгээ</th>
              <th>Авлага өглөг</th>
              <th>Үндсэн хөрөнгө</th>
            </tr>
            <tr>
              <td width="30%" className="p-5">
                <CheckCircleOutlined />
              </td>
              <td width="30%" className="p-5">
                {data?.avlaga_oglogo_jagsaalt === 1 ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )}
              </td>
              <td width="30%" className="p-5">
                {data?.undsen_horongo_jagsaalt === 1 ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
