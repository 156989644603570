import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from 'src/components';
import { useQuery } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import { guilgeeColumns, customSummary } from './dataStructure/data';
import { searchList } from './dataStructure/search';

import { start_loading, end_loading } from '../../../core/actions/loader';

const GuilgeeBalanceTailan = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState({
    json_array: [],
    sum_data: {},
    total: 0,
  });

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        setTableData(data.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const search = {
      dans_dugaar: query.get('dans_dugaar'),
    };

    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: {
        action_type: 'get_gb_sanhuu',
        month_start: '1',
        month_end: String(month),
        paging: { page, size, search },
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_date, query]);

  const title = () => <div style={{ height: '24px' }} />;

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          columns={guilgeeColumns}
          dataSource={tableData?.json_array}
          scroll={{
            y: 'calc(100vh - 330px)',
          }}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableData?.sum_data,
          }}
          title={title}
          total={tableData?.total}
          isSearch
          searchList={searchList}
        />
      </div>
    </>
  );
};

export default GuilgeeBalanceTailan;
