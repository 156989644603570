import { roundFloat } from 'src/core/utils/formater';

const hText = 'T7_1_AjliinHusnegt.headerText';

export const customSummary = ({ t = null }) => [
  {
    align: 'right',
    word: t(`${hText}.${'total'}`, 'Нийт дүн:'),
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    align: 'right',
    value: 'zuruu_turul_hasagdah_eh',
  },
  {
    align: 'right',
    value: 'zuruu_turul_tatvar_eh',
  },
  {
    align: 'right',
    value: 'zuruu_turul_hasagdah',
  },
  {
    align: 'right',
    value: 'zuruu_turul_tatvar',
  },
];

export const customColumns = () => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dugaar',
    align: 'left',
    titleAlign: 'center',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'ner',
    align: 'left',
    titleAlign: 'center',
    width: 300,
  },
  {
    title: 'Дансны үнэ',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Эхний үлдэгдэл',
        dataIndex: 'dans_une_eh',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.dans_une_eh),
        }),
      },
      {
        title: 'Эцсийн үлдэгдэл',
        dataIndex: 'dans_une_ets',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.dans_une_ets),
        }),
      },
    ],
  },
  {
    title: 'Татварын суурь',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Эхний үлдэгдэл',
        dataIndex: 'tatvar_suuri_eh',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.tatvar_suuri_eh),
        }),
      },
      {
        title: 'Эцсийн үлдэгдэл',
        dataIndex: 'tatvar_suuri_ets',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.tatvar_suuri_ets),
        }),
      },
    ],
  },
  {
    title: 'Зөрүү',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Эхний үлдэгдэл',
        dataIndex: 'zuruu_eh',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.zuruu_eh),
        }),
      },
      {
        title: 'Эцсийн үлдэгдэл',
        dataIndex: 'zuruu_ets',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.zuruu_ets),
        }),
      },
    ],
  },
  {
    title: 'Түр зөрүүгээр хүлээн зөвшөөрөхгүй зөрүү',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Эхний үлдэгдэл',
        dataIndex: 'hz_zuruu_eh',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.hz_zuruu_eh),
        }),
      },
      {
        title: 'Эцсийн үлдэгдэл',
        dataIndex: 'hz_zuruu_ets',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.hz_zuruu_ets),
        }),
      },
    ],
  },
  {
    title: 'Түр зөрүү',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Эхний үлдэгдэл',
        dataIndex: 'tur_zuruu_eh',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.tur_zuruu_eh),
        }),
      },
      {
        title: 'Эцсийн үлдэгдэл',
        dataIndex: 'tur_zuruu_ets',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.tur_zuruu_ets),
        }),
      },
    ],
  },
  {
    title: 'Хүлээн зөвшөөрсөн түр зөрүүгийн тайлант үеийн өөрчлөлт',
    dataIndex: 'tailan_uye_zuruu',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.tailan_uye_zuruu),
    }),
  },
  {
    title: 'Зөрүүгийн төрөл С1',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'zuruu_turul_hasagdah_eh',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.zuruu_turul_hasagdah_eh),
        }),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'zuruu_turul_tatvar_eh',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.zuruu_turul_tatvar_eh),
        }),
      },
    ],
  },
  {
    title: 'Зөрүүгийн төрөл С2',
    align: 'center',
    titleAlign: 'center',
    children: [
      {
        title: 'Хасагдах түр зөрүү',
        dataIndex: 'zuruu_turul_hasagdah',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.zuruu_turul_hasagdah),
        }),
      },
      {
        title: 'Татвар ногдох түр зөрүү',
        dataIndex: 'zuruu_turul_tatvar',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.zuruu_turul_tatvar),
        }),
      },
    ],
  },
];
