export const set_step = (step) => ({
  type: 'set_step',
  payload: step,
});

export const force_stop = (stop) => ({
  type: 'force_stop',
  payload: stop,
});

export const is_empty = (isEmpty) => ({
  type: 'is_empty',
  payload: isEmpty,
});
