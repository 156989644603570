export const searchList = [
  {
    type: 'input',
    title: 'Хөрөнгийн код, нэр',
    children: {
      queryKey: 'hurungu',
    },
  },
  {
    type: 'input',
    title: 'Бүртгэлийн дугаар',
    children: {
      queryKey: 'burtgel',
    },
  },
];
