import { roundFloat } from 'src/core/utils/formater';

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'dotood',
  },
  {
    align: 'right',
    value: 'gadaad',
  },
  {
    align: 'right',
    value: 'niit',
  },
];

export const customColumns = () => [
  {
    title: 'Д/Д',
    dataIndex: 'counter',
    align: 'center',
    titleAlign: 'center',
    width: 80,
  },
  {
    title: 'Борлуулалтын орлогын төрөл',
    dataIndex: 'ner',
    align: 'left',
    titleAlign: 'center',
    width: 455,
  },
  {
    title: 'Монгол Улсад болон Монгол улсаас эх үүсвэртэй олсон орлого',
    dataIndex: 'dotood',
    align: 'right',
    titleAlign: 'center',
    width: 250,
    render: (_, row) => ({
      children: roundFloat(row?.dotood),
    }),
  },
  {
    title: 'Гадаад улсад олсон орлого',
    dataIndex: 'gadaad',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.gadaad),
    }),
  },
  {
    title: 'Нийт орлого',
    dataIndex: 'niit',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.niit),
    }),
  },
];
