import React from 'react';
import { Card } from 'antd';

const DescriptionContainer = ({ data }) => (
  <div>
    {data.map((item, index) => (
      <Card key={index} title={item.title} bordered={false}>
        <p>{item.content}</p>
      </Card>
    ))}
  </div>
);

export default DescriptionContainer;
