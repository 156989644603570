import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  InputNumber,
  Row,
  Col,
  Spin,
  Button,
  Collapse,
  Form,
  Input,
  DatePicker,
  Tooltip,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  LoadingOutlined,
  DownOutlined,
  UpOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { roundFloat } from 'src/core/utils/formater';
import moment from 'moment';
import { enable_state, disable_state } from 'src/core/actions/route_state';
import { customConfirm } from 'src/components/common/confirmModal';

const { Panel } = Collapse;

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editingValue, setEditingValue] = useState('');

  const handleChange = (e) => {
    const { value } = e.target;
    setEditingValue(value);
    if (handleSave) {
      handleSave(record.key, dataIndex, value);
    } else {
      console.error('handleSave is not defined');
    }
  };

  let childNode = children || '';

  if (editable && record) {
    childNode = (
      <Input
        value={editingValue !== '' ? editingValue : record[dataIndex]}
        onChange={handleChange}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableCellAddRow2 = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave3,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const inputRef = useRef(null);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (editable) {
      setEditing(!editing);
      console.log('Editing toggled for:', dataIndex);
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      setEditing(false);
      handleSave3({ ...record, [dataIndex]: values[dataIndex] });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form
        form={form}
        component={false}
        onFinish={save}
        initialValues={{ [dataIndex]: record[dataIndex] }}
      >
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {['turul', 'guilgee_utga', 'tailbar'].includes(dataIndex) ? (
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
            />
          ) : (
            <InputNumber
              className="wide-input-number" // Apply the custom class
              ref={inputRef}
              min={0}
              step={0.01}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
              formatter={(value) => `${value}`}
              parser={(value) => value}
              precision={2}
            />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children || ' '}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableCell2 = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave2,
  ...restProps
}) => {
  const [editingValue, setEditingValue] = useState(null);

  const handleChange = (value) => {
    setEditingValue(roundFloat(value));
    if (handleSave2) {
      handleSave2(record.key, dataIndex, value);
    } else {
      console.error('handleSave is not defined');
    }
  };

  let childNode = children || '';

  if (editable && record) {
    childNode = (
      <InputNumber
        className="wide-input-number" // Apply the custom class
        min={0}
        value={
          editingValue !== null ? editingValue : roundFloat(record[dataIndex])
        }
        onChange={handleChange}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const App = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
  });

  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));
  const dispatch = useDispatch();

  const [dataSource, setDataSource] = useState([]);
  const [dataSource2, setDataSource2] = useState([]);
  const [dataSource3, setDataSource3] = useState([]);

  const get_data = (month) => {
    socket.on('request_out', ({ status, content }) => {
      console.log('🚀 ~ socket.on ~ content:', content);
      socket.removeAllListeners('request_out');
      if (status === '0') {
        setDataSource(content?.table1);
        setDataSource2(content?.table2);
        setDataSource3(content?.table3);
      } else {
        newAlert({ msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'get_sa22',
        month,
      },
    });
    dispatch(start_loading({}));
  };
  const handleSend = async () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        onSuccess: [get_data(data_date.month), dispatch(disable_state({}))],
      });
      dispatch(end_loading({}));
    });
    const content = {
      month: data_date?.month,
      action_type: 'save_sa22',
      table_1: dataSource,
      table_2: dataSource2,
      table_3: dataSource3,
    };

    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content,
    });
    dispatch(start_loading({}));
  };

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const handleConfirm = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        onSuccess: [get_data(data_date.month), dispatch(disable_state({}))],
      });
      dispatch(end_loading({}));
    });
    const content = {
      month: data_date?.month,
      action_type: 'save_sa22',
      table_1: dataSource,
      table_2: dataSource2,
      table_3: dataSource3,
    };

    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content,
    });
    dispatch(start_loading({}));
  };
  // useEffect(() => {
  //   console.log(dataSource);

  //   const allFieldsFilled = dataSource.every(
  //     (row) =>
  //       row.turul !== '' &&
  //       row.eh_uld !== '' &&
  //       row.uls !== '' &&
  //       row.huvi !== '' &&
  //       row.gadaad !== '' &&
  //       row.dun
  //   );
  //   setIsSubmitDisabled(allFieldsFilled);
  // }, [dataSource]);

  useEffect(() => {
    get_data(data_date.month);
  }, [data_date.month]);

  const handleSave = (key, dataIndex, value) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    if (index >= 0) {
      const item = newData[index];
      // Update the record with the string value
      newData.splice(index, 1, { ...item, [dataIndex]: value });
    } else {
      // Handle case for new rows if needed
      const newItem = { key, [dataIndex]: value };
      newData.push(newItem);
    }
    setDataSource(newData);
  };
  const handleSave2 = (key, dataIndex, value) => {
    const newData = [...dataSource2];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, [dataIndex]: value });
    setDataSource2(newData);
  };

  const handleSave3 = (updatedRecord) => {
    setDataSource3((prevDataSource) => {
      const index = prevDataSource.findIndex(
        (item) => updatedRecord.key === item.key
      );

      if (index >= 0) {
        // Update the existing row
        const newData = [...prevDataSource];
        const item = newData[index];
        newData[index] = { ...item, ...updatedRecord }; // Immutably update row
        return newData;
      }
      // Handle new row case if no matching key is found
      return [...prevDataSource, updatedRecord];
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleDelete = (key) => {
    setDataSource((prevDataSource) =>
      prevDataSource.filter((item) => item.key !== key)
    );
  };

  const handleDelete2 = (key) => {
    setDataSource3((prevDataSource) =>
      prevDataSource.filter((item) => item.key !== key)
    );
  };

  const handleAddRow2 = () => {
    const newData = {
      key: (dataSource3.length + 1).toString(),
      turul: '',
      guilgee_utga: '',
      dun: 0.0,
      tailbar: '',
      editable: {
        turul: true,
        guilgee_utga: true,
        dun: true,
        tailbar: true,
      },
    };

    // Correctly append newData to the current dataSource3
    setDataSource3((prevDataSource) => [...prevDataSource, newData]);

    // Dispatch your redux action
    dispatch(enable_state({}));
  };

  const columns = [
    {
      title: 'Үзүүлэлт',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      editable: true,
      render: (text, record) => text || '', // Ensure text is treated as a string
    },
    {
      title: 'Толгой компани',
      dataIndex: 'tolgoi',
      key: 'tolgoi',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return text || ''; // Ensure it's treated as a string
      },
    },
    {
      title: 'Хамгийн дээд хяналт тавигч толгой компани',
      dataIndex: 'hamgiin_deed_comp',
      key: 'hamgiin_deed_comp',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return text || ''; // Ensure it's treated as a string
      },
    },
    {
      title: 'Хамгийн дээд хяналт тавигч хувь хүн',
      dataIndex: 'huvi_hun',
      key: 'huvi_hun',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return text || ''; // Ensure it's treated as a string
      },
    },
    {
      title: 'Тайлбар',
      dataIndex: 'tailbar',
      key: 'tailbar',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return text || ''; // Ensure it's treated as a string
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }
    if (col.children) {
      // Handle child columns
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editableFields
              ? record.editableFields[childCol.dataIndex]
              : false,
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave,
          }),
        })),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editableFields
          ? record.editableFields[col.dataIndex]
          : false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const columns2 = [
    {
      title: 'Нөхөн олговрын нэр',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      render: (text, record) => text,
    },
    {
      title: 'Өмнөх оны дүн',
      dataIndex: 'omnoh_on',
      key: 'omnoh_on',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Тайлант оны дүн',
      dataIndex: 'tailant_on',
      key: 'tailant_on',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
  ];

  const mergedColumns2 = columns2.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }
    if (col.children) {
      // Handle child columns
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editableFields
              ? record.editableFields[childCol.dataIndex]
              : false,
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave2,
          }),
        })),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editableFields
          ? record.editableFields[col.dataIndex]
          : false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave2,
      }),
    };
  });

  const columns3 = [
    {
      title: 'Үзүүлэлт',
      dataIndex: 'turul',
      key: 'turul',
      width: 400,
      render: (text, record) => text,
      editable: true,
    },
    {
      title: 'Ажил гүйлгээний утга',
      dataIndex: 'guilgee_utga',
      key: 'guilgee_utga',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Дүн',
      dataIndex: 'dun',
      key: 'dun',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Тайлбар',
      dataIndex: 'tailbar',
      key: 'tailbar',
      editable: true,
      render: (text, record) => {
        if (record.title) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        }
        return typeof text === 'number' ? roundFloat(text) : text;
      },
    },
    {
      title: 'Устгах',
      key: 'action',
      render: (_, record) => {
        const isTurulNiiit =
          record.turul && record.turul.toLowerCase() === 'нийт дүн';

        if (!isTurulNiiit) {
          return (
            <Tooltip title="Устгах">
              <DeleteOutlined
                onClick={() =>
                  customConfirm({
                    onOk: () => handleDelete2(record.key),
                  })
                }
              />
            </Tooltip>
          );
        }
        return null;
      },
    },
  ];

  const mergedColumns3 = columns3.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }

    // If column has children, map over the child columns as well
    if (col.children) {
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editable && record.editable[childCol.dataIndex],
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave3,
          }),
        })),
      };
    }

    // Otherwise, handle non-nested columns
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editable && record.editable[col.dataIndex],
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave3,
      }),
    };
  });
  return (
    <div className="tax-account-table-container">
      <Collapse
        defaultActiveKey={['1', '2', '3']}
        expandIconPosition="right"
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ? <UpOutlined /> : <DownOutlined />
        }
      >
        <Panel
          header="22.1 Толгой компани, хамгийн дээд хяналт тавигч компани, хувь хүний талаарх мэдээлэл"
          key="1"
        >
          <Table
            dataSource={dataSource}
            columns={mergedColumns}
            rowClassName={() => 'editable-row'}
            bordered
            pagination={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            footer={() => (
              <Button
                type="primary"
                className="tax-account-button-unique-mini"
                onClick={() => handleSend(1)}
              >
                Хадгалах
              </Button>
            )}
          />
        </Panel>
        <Panel
          header="22.2 Тэргүүлэх удирдлагын бүрэлдэхүүнд олгосон нөхөн олговрын тухай мэдээлэл Тэргүүлэх удирдлага гэдэгт ............................................. бүрэлдэхүүнийг хамруулав"
          key="2"
        >
          <Table
            dataSource={dataSource2}
            columns={mergedColumns2}
            rowClassName={() => 'editable-row'}
            bordered
            pagination={false}
            components={{
              body: {
                cell: EditableCell2,
              },
            }}
            footer={() => (
              <Button
                type="primary"
                className="tax-account-button-unique-mini"
                onClick={() => handleSend(1)}
              >
                Хадгалах
              </Button>
            )}
          />
        </Panel>
        <Panel header="22.3 Холбоотой талуудтай хийсэн ажил гүйлгээ" key="3">
          <div className="table-add-row">
            <div className="table-container">
              <Table
                components={{
                  body: {
                    cell: EditableCellAddRow2,
                  },
                }}
                dataSource={dataSource3}
                columns={mergedColumns3}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey="key"
                // summary={() => (
                //   <Table.Summary.Row>
                //     <Table.Summary.Cell index={0}>Нийт</Table.Summary.Cell>
                //     <Table.Summary.Cell index={1} />
                //     <Table.Summary.Cell index={2} />
                //     <Table.Summary.Cell index={3}>
                //       {roundFloat(totalSum)}
                //     </Table.Summary.Cell>
                //     <Table.Summary.Cell index={4} />
                //   </Table.Summary.Row>
                // )}
                footer={() => (
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      type="primary"
                      className="add-btn"
                      onClick={handleAddRow2}
                    >
                      <PlusCircleOutlined />
                      Мөр нэмэх
                    </Button>
                    <Tooltip
                      title={
                        isSubmitDisabled
                          ? 'Хоосон мөрнүүдийг заавал бөглөнө үү'
                          : ''
                      }
                    >
                      <Button
                        type="primary"
                        className="confirm-btn"
                        onClick={handleConfirm}
                        style={{ marginLeft: 8 }}
                        disabled={isSubmitDisabled}
                      >
                        Хадгалах
                      </Button>
                    </Tooltip>
                  </div>
                )}
              />
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default App;
