import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Button as AntButton, Form, Row, Col, Drawer } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Table } from 'src/components';
import { roundFloat } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import { DahinColumns } from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { UpdateDahinUnelgee } from './updateDahinUnelgee';

const { Text } = Typography;

export const DahinUnelgee = ({
  onCancel = () => {},
  data,
  hurungu_info,
  dahinVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const socket = useSelector((state) => state.socket);

  const [tableData1, settableData1] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });
  const [updateData, seTupdateData] = useState({
    hurungu_info: {},
    burtgel_info: {},
    dahin_unelgee: {},
  });
  // eslint-disable-next-line operator-linebreak
  const [updateDahinUnelgeeVisible, setUpdateDahinUnelgeeVisible] =
    useState(false);

  const select_data = () => {
    socket.on('request_out', (burtgel_data) => {
      socket.removeAllListeners('request_out');
      if (burtgel_data.status === '0') {
        settableData1(burtgel_data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'dahin_unelgee_burtgel_id',
        burtgel_id: data.burtgel_id,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (dahinVisible) {
      select_data();
    }
  }, [dahinVisible]);

  const deleteDahinUnelgee = (id) => {
    socket.on('request_out', (req_data) => {
      socket.removeAllListeners('request_out');
      if (req_data.status === '0') {
        select_data();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: { action_type: 'delete_dahin_unelgee', id },
    });
    dispatch(start_loading({}));
  };

  const onCancelUpdateDahinUnelgee = () => {
    seTupdateData({ hurungu_info, burtgel_info: {}, dahin_unelgee: {} });
    setUpdateDahinUnelgeeVisible(false);
    select_data();
  };

  const VisibleUpdateDahinUnelgee = (row) => {
    seTupdateData({ hurungu_info, burtgel_info: data, dahin_unelgee: row });
    setUpdateDahinUnelgeeVisible(true);
  };

  const title = () => (
    <>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          VisibleUpdateDahinUnelgee({});
        }}
      >
        Анхны өртгийн өөрчлөлт нэмэх
      </AntButton>
    </>
  );

  return (
    <Drawer
      title="Дахин үнэлгээ, их засварын бүртгэл"
      open={dahinVisible}
      width="70%"
      footer={null}
      closable
      maskClosable={false}
      onCancel={onCancel}
      onClose={onCancel}
      className="custom-drawer"
    >
      <UpdateDahinUnelgee
        updateData={updateData}
        updateDahinVisible={updateDahinUnelgeeVisible}
        onCancel={onCancelUpdateDahinUnelgee}
      />
      <div className="dans-medeelel-container">
        <Form
          form={form}
          name="basic"
          className="register-form"
          size="small"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
        >
          <Form.Item
            label="Үндсэн хөрөнгийн нэр"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{hurungu_info?.ner}</Text>
          </Form.Item>
          <Form.Item
            label="Үндсэн хөрөнгийн код"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{hurungu_info?.code}</Text>
          </Form.Item>
          <Form.Item
            label="Бүртгэлийн дугаар"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{data?.burtgel_dugaar}</Text>
          </Form.Item>
          <Form.Item
            label="Анхны өртөг"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">
              {data?.bodit_urtug_anh > 0
                ? roundFloat(data?.bodit_urtug_anh)
                : roundFloat(data?.urtug_anh)}
            </Text>
          </Form.Item>
          <Form.Item
            label="Дахин үнэлгээ, их засварын дүн"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">
              {roundFloat(data?.dahin_unelgee_urtug)}
            </Text>
          </Form.Item>
          <Form.Item
            label="Холбосон анхны өртөг"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{roundFloat(data?.urtug)}</Text>
          </Form.Item>
          {dahinVisible ? (
            <Table
              title={title}
              columns={DahinColumns({
                deleteRow: deleteDahinUnelgee,
                updateRow: VisibleUpdateDahinUnelgee,
              })}
              dataSource={tableData1?.result_list}
              scroll={{
                y: 'calc(100vh - 430px)',
              }}
            />
          ) : null}
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <AntButton
                  onClick={() => {
                    form.resetFields();
                    onCancel();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </AntButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Drawer>
  );
};
