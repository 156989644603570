import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Button, Popover, Card, List } from 'antd';
import Table from 'src/components/common/table';
import TableTabs from 'src/components/common/tableTabs';
import { roundFloat } from 'src/core/utils/formater';
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { summary, columns, columns_2, summary_2 } from './dataStructure/data';

const UrsgalZasvar = () => {
  const dispatch = useDispatch();

  const { month } = useSelector((state) => state.data_date);
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [tableData_2, setTableData_2] = useState([]);
  const [tableDataSum_2, setTableDataSum_2] = useState({});
  const [tableDataSum, setTableDataSum] = useState({});
  const [loading, seTloading] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [disabled, seTdisabled] = useState({
    state: false,
    dans_une: 0.0,
    zardal: 0.0,
    zoruu: 0.0,
    total_hetersen_dun: 0.0,
  });
  const WarningContent = (
    <div className="white">
      <WarningOutlined className="white" /> Хүүгийн зардлын дүн Хүүгийн зардалд{' '}
      <br />
      холбогдсон дүнгээс зөрүүтэй байна.
    </div>
  );
  const CardData = [
    {
      title: 'ТШБ дансны дүн:',
      elementId: 'second',
      value: roundFloat(disabled.dans_une),
    },
    {
      title: 'Өгөгдлийн дүн:',
      elementId: 'third',
      value: roundFloat(disabled.zardal),
    },
    {
      title: 'Зөрүү:',
      elementId: 'fourth',
      value: roundFloat(disabled.zoruu),
    },
  ];

  const generateTailan = (current_month) => {
    seTloading(true);
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      console.log(data);
      if (data.status === '0') {
        const { result_json } = data.content;
        console.log(result_json);
        setTableData(result_json.hurungu);
        setTableDataSum(result_json.sum_data);

        const temp_dans_data = result_json.dans_list.map(
          (inner_data, key) => ({
            key: key + 1,
            ...inner_data,
          })
        );
        setTableData_2(temp_dans_data);
        setTableDataSum_2(result_json.sum_dans);
        seTdisabled(result_json.link_state);
        console.log('Asdlad[;akd');
        seTloading(false);
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_dans_holboos_hyazgaar',
      content: {
        action_type: 'get_list_dun',
        response_type: 'usgal_zasvar_zardal_hyazgaar',
        hyazgaar_type: '8',
        month: current_month,
        receive_channel: 'ts003_view',
      },
    });

    dispatch(start_loading({}));
  };
  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
  }, [data_date]);

  const handleSaveValue = (total_dans_dun, total_hetersen_dun) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
      });
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_manage_ursgal_zaswar',
      content: {
        month,
        action_type: 'set_ursgal_zaswar_dun_to_main',
        total_hetersen_dun,
        total_dans_dun,
      },
    });
  };
  const Table1 = () => (
    <div className="display-hyazgaar-dans">
      <div className="hyazgaar-card">
        <Card title="Зардлын дүн" className="custom-card">
          <List
            size="small"
            dataSource={CardData}
            className="custom-list"
            renderItem={(item) => (
              <List.Item>
                <span id={item?.elementId}>{item.title}</span>
                <span>{item.value}</span>
              </List.Item>
            )}
          />
          <div className="link-btn">
            {disabled.state ? (
              <Popover overlayClassName="Warning" content={WarningContent}>
                <Button
                  type="primary"
                  size="small"
                  disabled={disabled.state}
                  className="add-btn"
                  shape="round"
                >
                  Нийт цонх руу холбох
                </Button>
              </Popover>
            ) : (
              <Button
                type="primary"
                size="small"
                className="add-btn"
                shape="round"
                onClick={() => {
                  handleSaveValue(
                    disabled.dans_une,
                    disabled.total_hetersen_dun
                  );
                }}
              >
                Нийт цонх руу холбох
              </Button>
            )}
          </div>
        </Card>
      </div>
      <div className="hyazgaar-dans-table ww-60 mt-10">
        <Table
          columns={columns_2}
          dataSource={tableData_2}
          scroll={{
            x: 'auto',
          }}
          summaryList={{
            sumColumn: summary_2,
            sumDataSource: tableDataSum_2,
          }}
        />
      </div>
    </div>
  );

  const Table2 = () => (
    <div className="table-antd-div">
      <Table
        columns={columns}
        dataSource={tableData}
        summaryList={{
          sumColumn: summary,
          sumDataSource: tableDataSum,
        }}
      />
    </div>
  );

  const list = [
    {
      key: '1',
      title: 'Засварын зардал',
      content: <Table1 />,
    },
    {
      key: '2',
      title: <span id="first">Урсгал засварын зардлын хэтрэлт </span>,
      content: <Table2 />,
    },
  ];

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin indicator={antIcon} />;
      </div>
    );
  }
  return (
    <>
      <div>
        <TableTabs listTabs={list} />
      </div>
    </>
  );
};

export default UrsgalZasvar;
