import { message } from 'antd';
import axios from 'axios';

const getEnv = (type) => {
  let _env = '';
  switch (type) {
    case 'export':
      _env = 'REACT_APP_FILE_EXPORT';
      break;
    case 'storage':
      _env = 'REACT_APP_FILE_STORAGE';
      break;
    case 'first':
      _env = 'REACT_APP_FILE_FIRST_BALANCE';
      break;
    case 'attach':
      _env = 'REACT_APP_FILE_STORAGE_ATTACHMENT';
      break;
    case 'noat':
      _env = 'REACT_APP_FILE_NOAT';
      break;
    default:
      break;
  }
  return process.env[_env];
};

const catchAxios = (error) => {
  message.error(error?.message);
};

const thenAxios = (response = {}) => response?.data || {};

const isFallBack = (type, urlParams, params, fallBack = () => {}, headers) => {
  const axiosParams = {
    headers: {
      params,
      jwt: localStorage.getItem('taxjwt'),
      responseType: type === 'get' ? 'blob' : 'json',
      'Content-Type': 'application/json; charset=utf-8',
      ...headers,
    },
  };
  if (type === 'get') {
    axios[type](urlParams, {
      ...axiosParams,
      responseType: 'blob',
    })
      .then((response) => {
        fallBack(thenAxios(response));
      })
      .catch(catchAxios);
  } else {
    axios[type](urlParams, params, {
      ...axiosParams,
    })
      .then((response) => {
        fallBack(thenAxios(response));
      })
      .catch(catchAxios);
  }
};

const isAsync = async (type, urlParams, params, headers) => {
  try {
    const axiosParams = {
      headers: {
        params,
        jwt: localStorage.getItem('taxjwt'),
        responseType: type === 'get ' ? 'blob' : 'json',
        'Content-Type': 'application/json; charset=utf-8',
        ...headers,
      },
    };
    if (type === 'get') {
      const response = await axios[type](urlParams, {
        ...axiosParams,
      });
      return thenAxios(response);
    }
    const response = await axios[type](urlParams, params, {
      ...axiosParams,
    });
    return thenAxios(response);
  } catch (error) {
    catchAxios(error);
    return null;
  }
};

const _axios = (
  type = 'get',
  urlType = '',
  url = '',
  params = {},
  headers = {},
  fallBack = false
) => {
  const urlParams = `${getEnv(urlType)}${url}`;
  if (fallBack) {
    isFallBack(type, urlParams, params, fallBack, headers);
  } else {
    return isAsync(type, urlParams, params, headers);
  }
  return null;
};

const get = (urlType, url, params, headers, fallBack) =>
  _axios('get', urlType, url, params, headers, fallBack);
const post = (urlType, url, params, headers, fallBack) =>
  _axios('post', urlType, url, params, headers, fallBack);

export default { get, post };
