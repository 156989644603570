import React, { useState, useEffect } from 'react';
import { InputNumber, Button, Table } from 'antd';
import { useQuery, roundFloat, replaceFloat } from 'src/core/utils/formater';
import { useSelector, useDispatch } from 'react-redux';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';
import { set_alert } from 'src/core/actions/alert';
import debounce from 'lodash.debounce';

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editingValue, setEditingValue] = useState(null);

  const debouncedSave = debounce((value) => {
    const roundedValue = roundFloat(value);
    handleSave(record.key, dataIndex, roundedValue);
  }, 300);

  const handleChange = (value) => {
    setEditingValue(value);
    debouncedSave(value);
  };

  useEffect(
    () => () => {
      debouncedSave.cancel();
    },
    []
  );

  let childNode = children || '';

  // Add safety check for record and record.editable
  const isFieldEditable = record?.editable?.[dataIndex];

  if (isFieldEditable) {
    childNode = (
      <InputNumber
        min={0}
        value={editingValue !== null ? editingValue : record[dataIndex]}
        onChange={handleChange}
        className="wide-input-number"
        step={0.01}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
      />
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const App = () => {
  const initialData = [
    {
      key: '1',
      title: '1. ХӨРӨНГӨ',
      editable: { ets_uld: false, eh_uld: false }, // Non-editable title row
    },
    {
      key: '1.1',
      category: '1.1 Эргэлтийн хөрөнгө',
      eh_uld: 0.0,
      ets_uld: 0.0,
      editable: { ets_uld: true, eh_uld: true }, // This row is editable for both fields
      lvl2: true,
    },
    {
      key: '1.1.1',
      category: '1.1.1 Мөнгө,түүнтэй адилтгах хөрөнгө',
      eh_uld: 0.0,
      ets_uld: 0.0,
      editable: { ets_uld: false, eh_uld: false }, // This row is not editable
      sub: true,
    },
    {
      key: '1.1.2',
      category: '1.1.2 Дансны авлага',
      eh_uld: 0.0,
      ets_uld: 0.0,
      editable: { ets_uld: true, eh_uld: false }, // Editable for eh_uld only
      sub: true,
    },
    // Ensure all data entries have an 'editable' field
  ];

  const [dataSource, setDataSource] = useState(initialData);
  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));
  const query = useQuery();
  const dispatch = useDispatch();

  const handleSave = (key, dataIndex, value) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];

    // Round the value before saving it
    const roundedValue = replaceFloat(value);
    console.log('Original value:', value);
    console.log('Rounded value:', roundedValue);
    // Update the data source with the rounded value
    newData.splice(index, 1, { ...item, [dataIndex]: roundedValue });

    setDataSource(newData);
  };
  const handleSend = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: get_data(data_date.month) },
      });
      dispatch(end_loading({}));
    });

    const content = {
      month: data_date?.month,
      action_type: 'save_mungun_guilgee',
      table_data: dataSource,
    };

    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content,
    });

    dispatch(start_loading({}));
  };

  const get_data = (month) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        setDataSource(content?.result_list?.tailan_data);
        if (content?.result_list?.zoruu) {
          dispatch(
            set_alert({
              type: 'warning',
              msg: (
                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <b>Тайланд зөрүү гарсан байна</b>
                </span>
              ),
              open: true,
            })
          );
        }
      } else {
        newAlert({ torol: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });

    const search = {
      dans_dugaar: query.get('dans_dugaar') || '',
      ded_agilal: query.get('ded_agilal') || '',
    };

    socket.emit('request_in', {
      channel: 'ts001_05_view',
      content: {
        action_type: 'mungun_guilgee',
        month,
        page: query.get('page') || 1,
        size: query.get('size') || 10,
        search,
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    get_data(data_date.month);
  }, [data_date.month]);

  const columns = [
    {
      title: 'Үзүүлэлт',
      dataIndex: 'category',
      key: 'category',
      width: 600,
      align: 'left',
      render: (text, record) => {
        if (record.title) {
          return {
            children: (
              <strong style={{ textAlign: 'left', display: 'block' }}>
                {record.title}
              </strong>
            ),
            props: { colSpan: 9 },
          };
        }
        return (
          <span
            style={{ textAlign: 'left', display: 'block' }}
            className={record.lvl2 ? 'mlvl2' : record.sub ? 'mlvl3' : ''}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: 'Эхний үлдэгдэл',
      dataIndex: 'eh_uld',
      key: 'eh_uld',
      editable: true, // Mark this column as editable
      width: 200,
      render: (text, record) =>
        record.title
          ? { children: null, props: { colSpan: 0 } }
          : roundFloat(text),
    },
    {
      title: 'Эцсийн үлдэгдэл /төгрөгөөр/',
      dataIndex: 'ets_uld',
      key: 'ets_uld',
      editable: true, // Mark this column as editable
      width: 200,
      render: (text, record) =>
        record.title
          ? { children: null, props: { colSpan: 0 } }
          : roundFloat(text),
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) return col;
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editable && record.editable[col.dataIndex], // Check if the field is editable
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div className="tax-account-table-antd-div tailan-container">
      <Table
        dataSource={dataSource}
        columns={mergedColumns}
        rowClassName={() => 'editable-row'}
        bordered
        pagination={false}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        footer={() => (
          <Button
            type="primary"
            className="tax-account-button-unique-mini"
            onClick={() => handleSend(1)}
          >
            Хадгалах
          </Button>
        )}
      />
    </div>
  );
};

export default App;
