import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PlusCircleOutlined } from '@ant-design/icons';
import { onlyNumber, useQuery, replaceFloat } from 'src/core/utils/formater';
import CustomDatePicker from 'src/components/common/datePicker';
import Table from 'src/components/common/table';

import {
  Button as AntButton,
  Modal,
  Input,
  Form,
  Select,
  Row,
  Col,
} from 'antd';

import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';
import { columns, customSummary } from './dataStructure/data';

const { Option } = Select;

export default function Client() {
  const dispatch = useDispatch();
  const { socket, month } = useSelector((state) => ({
    socket: state.socket,
    month: state.data_date?.month,
  }));
  const [form] = Form.useForm();
  const [tatvarTypes, setTatvarTypes] = useState([]);
  const [tableData, setTableData] = useState();
  const [tableDataSum, setTableDataSum] = useState({});
  const [visible, seTvisible] = useState(false);
  const [update, setUpdate] = useState(false);
  const { TextArea } = Input;
  const [total, seTtotal] = useState([]);

  const query = useQuery();
  const page = query.get('page');
  const size = query.get('size');

  const onCancel = () => {
    seTvisible(false);
  };

  const UpdateCancel = () => {
    setUpdate(false);
  };

  const mainData = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        setTableDataSum(content.sum_data);
        seTtotal(content?.total);
        setTatvarTypes(content?.tatvar_types);
        const new_temp_data = content?.json_array.map((inner_data, key) => ({
          id: key + 1,
          ...inner_data,
        }));
        if (new_temp_data) {
          setTableData(new_temp_data);
        }
      } else {
        newAlert({ type: 'error', msg: content.Message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_tatvar_service',
      content: {
        action_type: 'get_tatvar_input',
        page,
        size,
        search: {},
        month,
      },
    });

    dispatch(start_loading({}));
  };
  useEffect(() => {
    console.log('>> Getting old list...');
    mainData();
  }, [query]);

  const delete_row = (id) => {
    socket.on('request_out', ({ status, content }) => {
      const { Message: msg } = content;
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg,
        functions: { onSuccess: mainData(), onFail: mainData() },
        form,
      });
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_tatvar_service',
      content: {
        tatvar_id: id,
        action_type: 'delete_tatvar',
      },
    });

    dispatch(start_loading({}));
  };

  const handleSaveValue = (tatvar_data) => {
    const { dun } = tatvar_data;
    tatvar_data.dun = replaceFloat(dun);
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: [form.resetFields(), mainData(), onCancel()] },
      });

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_tatvar_service',
      content: {
        array_data: tatvar_data,
        action_type: 'add_tatvar',
      },
    });

    dispatch(start_loading({}));
  };

  const title = () => (
    <>
      <AntButton
        id="first"
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          seTvisible(true);
        }}
      >
        Нэмэх
      </AntButton>
    </>
  );

  const AddValue = ({ onCancel = () => {} }) => {
    const onFinish = (data) => {
      console.log('-----onFinish------<', data);
      const tatvar_data = form.getFieldValue();
      if ('tatvar_id' in tatvar_data) {
        handleSaveValue(tatvar_data);
      } else {
        handleSaveValue(data);
      }
    };
    return (
      <>
        <Form
          form={form}
          name="basic"
          style={{
            fontSize: 13,
          }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Огноо:"
            name="ognoo"
            labelAlign="right"
            rules={[{ required: true, message: 'Огноо сонгоно уу' }]}
          >
            <CustomDatePicker customClass="ww-60" />
          </Form.Item>
          <Form.Item
            label="Татварын төрөл:"
            name="tatvar_torol"
            labelAlign="right"
            rules={[{ required: true, message: 'Эх сурвалж сонгоно уу!' }]}
          >
            <Select
              placeholder="Сонгох"
              style={{ width: '100%', fontSize: 13 }}
            >
              {tatvarTypes.map((i) => (
                <Option value={i.tatvar_torol_id}>{i.tatvar_torol}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Дүн:"
            name="dun"
            labelAlign="right"
            rules={[{ required: true, message: 'Татварын дүн оруулна уу' }]}
            normalize={onlyNumber}
          >
            <Input
              bordered
              // defaultValue={currentValue.geree_ner}
            />
          </Form.Item>
          <Form.Item label="Тайлбар:" name="tailbar" labelAlign="right">
            <TextArea
              autoSize={{ minRows: 3, maxRows: 5 }}
              // bordered={false}
              disabled={false}
              className="ww-60"
              maxLength={300}
              showCount
            />
          </Form.Item>

          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item noStyle>
                <AntButton
                  onClick={() => {
                    form.resetFields();
                    onCancel();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </AntButton>
                <AntButton
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </AntButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  return (
    <>
      <div className="table-antd-div zardal-container-tatvar">
        <Table
          columns={columns({
            delete_row,
          })}
          dataSource={tableData}
          scroll={{
            y: 'calc(100vh)',
            x: 'auto',
          }}
          total={total}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableDataSum,
          }}
          title={title}
        />
      </div>

      <Modal
        title="Татварын зардал бүртгэх"
        visible={visible}
        width={550}
        footer={null}
        onCancel={onCancel}
        wrapClassName="custom-modal"
      >
        <AddValue onCancel={onCancel} />
      </Modal>
      <Modal
        title="Татварын дүн шинэчлэх"
        visible={update}
        width={550}
        footer={null}
        onCancel={UpdateCancel}
        wrapClassName="custom-modal"
      >
        <AddValue onCancel={UpdateCancel} />
      </Modal>
    </>
  );
}
