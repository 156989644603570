const modules = {
  options: [],
  header: [
    navigator.platform,
    navigator.userAgent,
    navigator.appVersion,
    navigator.vendor,
  ],
  dataos: [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Acer', value: 'Acer', version: 'rv' },
    { name: 'Asus', value: 'Asus', version: 'rv' },
    { name: 'Dell', value: 'Dell', version: 'rv' },
    { name: 'Lenovo', value: 'Lenovo', version: 'rv' },
    { name: 'HP', value: 'HP', version: 'rv' },
  ],
  databrowser: [
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
  ],
};

const matchItem = (string, data) => {
  let i = 0;
  let regex;
  let regexv;
  let match;
  let matches;
  let version;

  for (i = 0; i < data.length; i += 1) {
    regex = new RegExp(data[i].value, 'i');
    match = regex.test(string);
    if (match) {
      regexv = new RegExp(`${data[i].version}[- /:;]([\\d._]+)`, 'i');
      matches = string.match(regexv);
      version = matches ? matches[1].split(/[._]+/).join('.') : '0';
      return {
        name: data[i].name,
        version: parseFloat(version),
      };
    }
  }
  return { name: 'Unknown', version: 0 };
};

const getDeviceInfo = () => {
  const agent = modules.header.join(' ');

  const { name: device_name, version: device_version } = matchItem(
    agent,
    modules.dataos
  );
  const { name: browser_name, version: browser_version } = matchItem(
    agent,
    modules.databrowser
  );
  const return_data = {
    device_name,
    device_version,
    browser_name,
    browser_version,
  };

  return return_data;
};

export default getDeviceInfo;
