const oDefaultState = {
	file: ""
};

const reducer = (state = oDefaultState, action) => {
	switch (action.type) {
		case "set_config":
			const oNewState  = action.payload.config
			return oNewState;
		default:
			return state;
	}
};

export default reducer;
