import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { Checkbox } from 'antd';
import { EditOutlined } from '@ant-design/icons';

export const ColumnsMain = ({ selectData = () => {},
  checkAll, indeterminate, onCheckAllChange = () => {},
  onClickEdit = () => {}, selectedRowKeys, optionList }) => [
  {
    title: 'Харилцагчийн нэр',
    dataIndex: 'har_ner',
    align: 'left',
    width: 200,
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Харилцагчийн РД',
    dataIndex: 'har_reg',
    align: 'left',
    fixed: 'left',
    width: 100,
  },
  {
    title: 'Тэмдэглэл',
    dataIndex: 'note',
    align: 'left',
    width: 150,
    ellipsis: true,
  },
  {
    title: '',
    dataIndex: 'note',
    align: 'left',
    width: 50,
    // ellipsis: true,
    render: (_, row) => <EditOutlined onClick={() => onClickEdit(row)} />,
  },
  {
    title: 'Тохируулга',
    children: [
      {
        title: 'Хэрэгжээгүй',
        align: 'right',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'toh_hereg_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ ',
            dataIndex: 'toh_hereg_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],

      },
      {
        title: 'Хасагдахгүй',
        align: 'right',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'toh_hasag_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ ',
            dataIndex: 'toh_hasag_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'Төрөл ',
            dataIndex: 'toh_hasag_type',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (_, row) => (
              <div>{row?.toh_hasag_type ? optionList[row.toh_hasag_type] : ''}</div>
            ),
            // render: (row) => (row?.toh_hasag_type ? optionList[row.toh_hasag_type] : null),
          },
        ],
      },
      {
        title: 'Хойшлуулах',
        align: 'right',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'toh_hoish_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ ',
            dataIndex: 'toh_hoish_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
    ],
  },
  {
    title: <Checkbox
      indeterminate={indeterminate}
      onClick={() => onCheckAllChange()}
      checked={checkAll}
    />,
    // dataIndex: 'edited',
    align: 'right',
    width: 25,
    render: (row) => (
      <Checkbox
        checked={selectedRowKeys.indexOf(row.key) > -1}
        onChange={() => selectData(row)}
      />
    ),
  },
  {
    title: 'Зөрүү',
    children: [
      {
        title: 'Илүү ',
        align: 'right',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'zuruu_iluu_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ',
            dataIndex: 'zuruu_iluu_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
      {
        title: 'Дутуу',
        align: 'right',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'zuruu_dutuu_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ',
            dataIndex: 'zuruu_dutuu_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
    ],
  },
  {
    title: 'ХА бүртгэл ',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'ha_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'ha_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Нийт ибаримтаар шивсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'ebar_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'ebar_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Төлбөрийн баримтаар шивсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'tolbor_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'tolbor_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Хойшлогдсон падаанаас хэрэгжүүлсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'ho_padaan_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'ho_padaan_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },

  {
    title: 'Гаалийн мэдүүлэг',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'gaal_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'gaal_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },

  {
    title: 'Хувь тэнцүүлэн хасах падаанаас хэрэгжүүлсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'huvi_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'huvi_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];

export const customSummary = [
  {
    word: ' ',
  },
  {
    align: 'right',
    word: 'Нийт дүн:',
  },
  {
    word: ' ',
  },
  {},
  {
    align: 'right',
    value: 'toh_hereg_niit',
  },
  {
    align: 'right',
    value: 'toh_hereg_noat',
  },
  {
    align: 'right',
    value: 'toh_hasag_niit',
  },
  {
    align: 'right',
    value: 'toh_hasag_noat',
  },
  {},
  {
    align: 'right',
    value: 'toh_hoish_niit',
  },
  {
    align: 'right',
    value: 'toh_hoish_noat',
  },
  {},
  {
    align: 'right',
    value: 'zuruu_iluu_niit',
  },
  {
    align: 'right',
    value: 'zuruu_iluu_noat',
  },

  {
    align: 'right',
    value: 'zuruu_dutuu_niit',
  },
  {
    align: 'right',
    value: 'zuruu_dutuu_noat',
  },

  {
    align: 'right',
    value: 'ha_niit',
  },
  {
    align: 'right',
    value: 'ha_noat',
  },

  {
    align: 'right',
    value: 'ebar_niit',
  },
  {
    align: 'right',
    value: 'ebar_noat',
  },

  {
    align: 'right',
    value: 'tolbor_niit',
  },
  {
    align: 'right',
    value: 'tolbor_noat',
  },

  {
    align: 'right',
    value: 'ho_padaan_niit',
  },
  {
    align: 'right',
    value: 'ho_padaan_noat',
  },

  {
    align: 'right',
    value: 'gaal_niit',
  },
  {
    align: 'right',
    value: 'gaal_noat',
  },

  {
    align: 'right',
    value: 'huvi_niit',
  },
  {
    align: 'right',
    value: 'huvi_noat',
  },

];

export const ColumnsMainShort = () => [
  {
    title: 'Харилцагчийн нэр',
    dataIndex: 'har_ner',
    align: 'left',
    width: 200,
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Харилцагчийн РД',
    dataIndex: 'har_reg',
    align: 'left',
    fixed: 'left',
    width: 100,
  },
  {
    title: 'Тохируулга',
    children: [
      {
        title: 'Хэрэгжээгүй',
        align: 'right',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'toh_hereg_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ ',
            dataIndex: 'toh_hereg_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],

      },
      {
        title: 'Хасагдахгүй',
        align: 'right',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'toh_hasag_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ ',
            dataIndex: 'toh_hasag_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
      {
        title: 'Хойшлуулах',
        align: 'right',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'toh_hoish_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ ',
            dataIndex: 'toh_hoish_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
    ],
  },
  {
    title: 'Зөрүү',
    children: [
      {
        title: 'Илүү ',
        align: 'right',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'zuruu_iluu_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ',
            dataIndex: 'zuruu_iluu_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
      {
        title: 'Дутуу',
        align: 'right',
        children: [
          {
            title: 'Нийт дүн',
            dataIndex: 'zuruu_dutuu_niit',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
          {
            title: 'НӨАТ',
            dataIndex: 'zuruu_dutuu_noat',
            align: 'right',
            width: 150,
            render: (_) => roundFloat(_),
          },
        ],
      },
    ],
  },
  {
    title: 'ХА бүртгэл ',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'ha_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'ha_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Нийт ибаримтаар шивсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'ebar_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'ebar_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Төлбөрийн баримтаар шивсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'tolbor_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'tolbor_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Хойшлогдсон падаанаас хэрэгжүүлсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'ho_padaan_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'ho_padaan_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },

  {
    title: 'Гаалийн мэдүүлэг',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'gaal_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'gaal_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },

  {
    title: 'Хувь тэнцүүлэн хасах падаанаас хэрэгжүүлсэн',
    children: [
      {
        title: 'Нийт дүн',
        dataIndex: 'huvi_niit',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'НӨАТ',
        dataIndex: 'huvi_noat',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];

export const customSummaryShort = [
  {
    word: ' ',
  },
  {
    align: 'right',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'toh_hereg_niit',
  },
  {
    align: 'right',
    value: 'toh_hereg_noat',
  },
  {
    align: 'right',
    value: 'toh_hasag_niit',
  },
  {
    align: 'right',
    value: 'toh_hasag_noat',
  },
  {
    align: 'right',
    value: 'toh_hoish_niit',
  },
  {
    align: 'right',
    value: 'toh_hoish_noat',
  },
  {
    align: 'right',
    value: 'zuruu_iluu_niit',
  },
  {
    align: 'right',
    value: 'zuruu_iluu_noat',
  },

  {
    align: 'right',
    value: 'zuruu_dutuu_niit',
  },
  {
    align: 'right',
    value: 'zuruu_dutuu_noat',
  },

  {
    align: 'right',
    value: 'ha_niit',
  },
  {
    align: 'right',
    value: 'ha_noat',
  },

  {
    align: 'right',
    value: 'ebar_niit',
  },
  {
    align: 'right',
    value: 'ebar_noat',
  },

  {
    align: 'right',
    value: 'tolbor_niit',
  },
  {
    align: 'right',
    value: 'tolbor_noat',
  },

  {
    align: 'right',
    value: 'ho_padaan_niit',
  },
  {
    align: 'right',
    value: 'ho_padaan_noat',
  },

  {
    align: 'right',
    value: 'gaal_niit',
  },
  {
    align: 'right',
    value: 'gaal_noat',
  },

  {
    align: 'right',
    value: 'huvi_niit',
  },
  {
    align: 'right',
    value: 'huvi_noat',
  },

];
