import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import Table from 'src/components/common/table';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';
import { modal_columns } from './data';

const TailanIlgeeh = ({
  dataDetail = null,
  closeModal = () => {},
  socket = null,
  dispatch = null,
  data_date = null,
  mayagtType = null,
}) => {
  const [tableData, setTableData] = useState([]);
  const mainData = () => {
    socket.on('request_out', ({ content }) => {
      socket.removeAllListeners('request_out');
      setTableData(content?.report_data);
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss012_manage',
      content: {
        action_type: 'get_all_tailan_info',
        parent_id: dataDetail?.parent_id,
        report_no: dataDetail?.reportNo,
        form_no: dataDetail?.formNo,
        mayagt_type: mayagtType,
        ttd: localStorage.getItem('ttd'),
        access_token: JSON.parse(localStorage.getItem('etaxtoken'))
          ?.access_token,
      },
    });

    dispatch(start_loading({}));
  };

  const sendTailan = ({ tailan_key, form_no }) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        newAlert({ type: 'success', msg: content.Message });
        socket.on('request_out', ({ content }) => {
          socket.removeAllListeners('request_out');
          setTableData(content?.report_data);
          dispatch(end_loading({}));
        });
        socket.emit('request_in', {
          channel: 'ss012_manage',
          content: {
            action_type: 'get_all_tailan_info',
            parent_id: dataDetail?.parent_id,
            mayagt_type: mayagtType,
            form_no: dataDetail?.formNo,
            ttd: localStorage.getItem('ttd'),
            access_token: JSON.parse(localStorage.getItem('etaxtoken'))
              ?.access_token,
          },
        });
      } else {
        newAlert({ type: 'error', msg: content.Message });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ss012_manage',
      content: {
        action_type: 'save_tailan',
        month: data_date?.month,
        ttd: localStorage.getItem('ttd'),
        tailan_type: tailan_key,
        form_no,
        mayagt_type: mayagtType,
        access_token: JSON.parse(localStorage.getItem('etaxtoken'))
          ?.access_token,
        dataDetail,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    mainData();
  }, [dataDetail]);

  return (
    <>
      <div className="table-container-div">
        <Table
          columns={modal_columns({
            sendTailan,
          })}
          dataSource={tableData}
          scroll={{
            x: 'auto',
          }}
        />
      </div>
      <Row
        style={{
          marginTop: 8,
        }}
      >
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            className="cancel-btn"
            type="primary"
            shape="round"
            size="small"
            onClick={closeModal}
          >
            Буцах
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default TailanIlgeeh;
