import { roundFloat } from 'src/core/utils/formater';

export const DahinAllColumns = () => [
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    align: 'left',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Үндсэн хөрөнгийн',
    children: [
      {
        title: 'Нэр',
        dataIndex: 'ner',
        align: 'left',
        width: 200,
        ellipsis: true,
      },
      {
        title: 'Бүртгэлийн дугаар',
        dataIndex: 'burtgel_dugaar',
        align: 'left',
        width: 150,
      },
      {
        title: 'Ашиглаж эхэлсэн огноо',
        dataIndex: 'ashiglaj_ehelsen',
        align: 'center',
        width: 100,
      },
      {
        title: 'Анхны өртөг',
        dataIndex: 'urtug_anh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Өөрчлөлтийн',
    children: [
      {
        title: 'Огноо',
        dataIndex: 'ognoo',
        align: 'center',
        width: 100,
      },
      {
        title: 'Дахин үнэлгээ / Их засвар',
        dataIndex: 'helber_text',
        align: 'center',
        width: 150,
      },
      {
        title: 'Арга',
        dataIndex: 'arga_text',
        align: 'center',
        width: 150,
      },
    ],
  },
  {
    title: 'Анхны өртөгт',
    children: [
      {
        title: 'Нэмэгдсэн',
        dataIndex: 'dun_nemeh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Хасагдсан',
        dataIndex: 'dun_hasah',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Хуримтлагдсан элэгдэлд',
    children: [
      {
        title: 'Нэмэгдсэн',
        dataIndex: 'dun_nemeh_elegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Хасагдсан',
        dataIndex: 'dun_hasah_elegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Шинэчилсэн',
    children: [
      {
        title: 'Ашиглаж дуусах огноо',
        dataIndex: 'ashiglaj_duusah_uurchlult',
        align: 'center',
        width: 100,
      },
      {
        title: 'Үлдэх өртөг',
        dataIndex: 'urtug_uldeh_uurchlult',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];
