import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import newAlert from 'src/components/newAlert';
import { Table } from 'src/components';
import { PlusCircleOutlined } from '@ant-design/icons';
import {
  Modal,
  // Empty,
  Form,
  Row,
  Col,
  Input,
  Button,
  Select as AntSelect,
  Divider,
  Typography,
} from 'antd';

import { isEmpty } from 'lodash';
import { customColumns } from './dataStructure/data';

import { start_loading, end_loading } from '../../../core/actions/loader';

const { Text } = Typography;

const MainPage = () => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);

  const [tableData, setTableData] = useState([]);
  const [dansInfo, setDansInfo] = useState(null);
  const [visible, setVisible] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [addForm] = Form.useForm();
  const [deleteForm] = Form.useForm();

  const generateTailan = () => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');

      if (data_temp.status === '0') {
        setTableData(data_temp.content.json_array);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_turul',
      content: { action_type: 'get_list_turul' },
    });

    dispatch(start_loading({}));
  };

  const getProps = () => {
    socket.on('request_out', (data) => {
      const data_temp_props = data;
      socket.removeAllListeners('request_out');

      if (data_temp_props.status === '0') {
        setDansInfo(data_temp_props?.content.json_array);
        generateTailan();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_turul',
      content: { action_type: 'get_available_dans' },
    });

    dispatch(start_loading({}));
  };

  const saveValues = (saveData) => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');

      if (data_temp.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_turul',
      content: { action_type: 'set_dans', data: saveData },
    });

    dispatch(start_loading({}));
  };

  const addValue = (saveData) => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');

      if (data_temp.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan();
      } else if (data_temp.status === '5') {
        newAlert({ type: 'error', msg: 'Түр зөрүүгийн төрөл давхцаж байна' });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_turul',
      content: {
        action_type: 'add',
        tur_zuruu_ner: saveData?.tur_zuruu_ner,
        tur_zuruu_dans_aguulga: saveData?.tur_zuruu_dans_aguulga,
      },
    });

    dispatch(start_loading({}));
  };

  const deleteValue = (deleteData) => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');

      if (data_temp.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай устгагдлаа' });
        generateTailan();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_turul',
      content: {
        action_type: 'delete',
        id: deleteData.delete_id,
      },
    });

    dispatch(start_loading({}));
  };

  // const getDansInfoVal = (dans_id) => {
  //   const obj = dansInfo?.find((elem) => elem?.dans_id === dans_id);
  //   if (obj !== undefined) {
  //     return obj?.dans_dugaar;
  //   }
  //   return '---';
  // };

  useEffect(() => {
    setLoading(true);
    getProps();
  }, []);

  // const editAction = (row) => {
  //   form.setFieldsValue({
  //     holbolt_id: row?.holbolt_id,
  //     ner: row?.ner,
  //     dans_id: row?.dans_id,
  //     dans_aguulga: row?.dans_aguulga,
  //   });

  //   setVisible(true);
  // };

  const addAction = () => {
    setAddVisible(true);
    // Force set field value
    addForm.setFieldsValue({
      tur_zuruu_dans_aguulga: 'Бусад',
    });
  };

  const deleteAction = (row) => {
    deleteForm.setFieldsValue({
      delete_id: row?.id,
    });

    setDeleteVisible(true);
  };

  useEffect(() => {
    if (!isEmpty(dansInfo)) {
      setLoading(false);
    }
  }, [dansInfo]);

  if (loading) {
    // return <Empty description={false} />;
    return '';
  }

  const title = () => (
    <>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          addAction();
        }}
      >
        Түр зөрүүгийн төрөл нэмэх
      </Button>
    </>
  );

  return (
    <>
      <div className="table-antd-div tailan-container-920 no-scroll">
        <Table
          columns={customColumns({
            // customEdit: editAction,
            customDelete: deleteAction,
            // getDansInfo: getDansInfoVal,
          })}
          dataSource={tableData}
          scroll={{
            y: 'calc(100vh - 230px)',
          }}
          title={title}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Түр зөрүүгийн данс холбох"
        visible={visible}
        closable={false}
        maskClosable={false}
        width={700}
        footer={null}
      >
        <CustomModal
          onClose={() => {
            setVisible(false);
          }}
          form={form}
          listData={dansInfo}
          saveValues={saveValues}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Түр зөрүүгийн төрөл нэмэх"
        visible={addVisible}
        closable={false}
        maskClosable={false}
        width={700}
        footer={null}
      >
        <AddModal
          onClose={() => {
            setAddVisible(false);
          }}
          form={addForm}
          saveValues={addValue}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Түр зөрүүгийн төрөл устгах"
        visible={deleteVisible}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <DeleteModal
          onClose={() => {
            setDeleteVisible(false);
          }}
          form={deleteForm}
          deleteValue={deleteValue}
        />
      </Modal>
    </>
  );
};

const layout = {
  labelCol: {
    xs: { span: 10 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 14 },
  },
};

const CustomModal = ({
  onClose = () => {},
  form = null,
  listData = [],
  saveValues = () => {},
}) => {
  const onFinish = (values) => {
    saveValues(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="holbolt_id" hidden />
        <Form.Item
          name="ner"
          label="Түр зөрүүгийн төрөл"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input disabled className="w-100" />
        </Form.Item>
        <Form.Item
          name="dans_aguulga"
          label="Түр зөрүүгийн нөлөөлөл бүртгэх данс"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input disabled className="w-100" />
        </Form.Item>
        <Divider />
        <Form.Item name="dans_id" label="Данс" labelAlign="left">
          <AntSelect defaultValue={null}>
            <AntSelect.Option value={null}>---</AntSelect.Option>
            {listData.map((i) => (
              <AntSelect.Option value={i?.dans_id}>
                {i?.dans_dugaar} {i?.dans_ner}
              </AntSelect.Option>
            ))}
          </AntSelect>
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const AddModal = ({
  onClose = () => {},
  form = null,
  saveValues = () => {},
}) => {
  const onFinish = (values) => {
    saveValues(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item
          name="tur_zuruu_ner"
          label="Түр зөрүүгийн төрөл"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input className="w-100" />
        </Form.Item>
        <Form.Item
          name="tur_zuruu_dans_aguulga"
          label="Түр зөрүүгийн нөлөөлөл бүртгэх данс"
          labelAlign="left"
          hidden
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input className="w-100" />
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const DeleteModal = ({
  onClose = () => {},
  form = null,
  deleteValue = () => {},
}) => {
  const onFinish = (values) => {
    deleteValue(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="delete_id" hidden />
        <Text>Та энэ үйлдлийг хийхдээ итгэлтэй байна уу?</Text>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Устгах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MainPage;
