import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Button, message } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import newAlert from 'src/components/newAlert';
import { useQuery } from 'src/core/utils/formater';
import Table from 'src/components/common/table';
import { start_loading, end_loading } from 'src/core/actions/loader';
import RegisterModal from './registerModal';
import utils from './dataStructure';

const HhEtgeedMedeelel = () => {
  const [tableData, setTableData] = useState([]);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalValues, setModalValues] = useState(utils?.initialValues);
  const [dictionaryValues, setDictionaryValues] = useState({});
  const [actionType, setActionType] = useState('register');
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);

  const query = useQuery();

  const handleOpenRegisterModal = (rowData = {}) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0') {
        setDictionaryValues({
          hariltsagch_code: data.content.dictionary_data_list.hariltsagch_list,
          oroltsoo: data.content.dictionary_data_list.hh_etgeed_oroltsoo,
          turul: data.content.dictionary_data_list.hh_etgeed_turul,
          helber: data.content.dictionary_data_list.hh_etgeed_helber_list,
        });
        setActionType(rowData ? 'edit' : 'register');
        setModalValues(rowData);
        setIsVisibleModal(true);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts002_00_hh_etgeed_medeelel',
      content: {
        action_type: 'hh_etgeed_dictionary_list',
        version: version.version_number,
        year: version.year,
      },
    });

    dispatch(start_loading({}));
  };

  const handleSave = (values) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      setIsVisibleModal(false);
      dispatch(end_loading({}));
      if (data.content.status === '0') {
        setTableData(data.content?.hh_etgeed_list);
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
      } else if (data?.content?.status === '2') {
        newAlert({ type: 'error', msg: data?.content?.msg });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
    });
    socket.emit('request_in', {
      channel: 'ts002_00_hh_etgeed_medeelel',
      content: {
        action_type:
          actionType === 'register' ? 'hh_etgeed_register' : 'hh_etgeed_edit',
        json_data: values,
        page: query.get('page') || 1,
        size: query.get('size') || 20,
      },
    });

    dispatch(start_loading({}));
  };

  const fetch = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      dispatch(end_loading({}));
      if (data.content.status === '0') {
        setTableData(data.content?.hh_etgeed_list);
        setTotal(data.content?.total);
      }
    });
    socket.emit('request_in', {
      channel: 'ts002_00_hh_etgeed_medeelel',
      content: {
        action_type: 'hh_etgeed_list',
        page: query.get('page') || 1,
        size: query.get('size') || 20,
      },
    });

    dispatch(start_loading({}));
  };
  useEffect(() => {
    fetch();
  }, [query]);

  const onDelete = (hariltsagch_code = '') => {
    if (hariltsagch_code) {
      socket.on('request_out', ({ content }) => {
        socket.removeAllListeners('request_out');
        if (content?.status === '0') {
          message.success(content?.msg, 2);
          setTimeout(fetch(), 1000);
        }

        if (content?.status === '1') {
          message.error(content?.msg, 2);
        }

        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts002_00_hh_etgeed_medeelel',
        content: {
          action_type: 'hh_etgeed_delete',
          hariltsagch_code,
        },
      });
      dispatch(start_loading({}));
    }
  };

  return (
    <>
      <div className="table-antd-div">
        <Table
          dataSource={tableData}
          columns={utils?.columns({ handleOpenRegisterModal, onDelete })}
          scroll={{
            y: 'calc(100vh - 280px)',
          }}
          total={total}
          title={() => (
            <Button
              className="add-btn"
              type="primary"
              size="small"
              icon={<PlusCircleOutlined />}
              onClick={() => handleOpenRegisterModal(null)}
            >
              Бүртгэл нэмэх
            </Button>
          )}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Харилцан хамааралтай этгээд бүртгэх"
        visible={isVisibleModal}
        closable={false}
        maskClosable={false}
        width={1100}
        footer={null}
        style={{ top: 50 }}
      >
        <RegisterModal
          dictionaryValues={dictionaryValues}
          modalValues={modalValues}
          actionType={actionType}
          handleSave={handleSave}
          onClose={() => {
            setIsVisibleModal(false);
          }}
        />
      </Modal>
    </>
  );
};
export default HhEtgeedMedeelel;
