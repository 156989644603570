import { roundFloat } from 'src/core/utils/formater';

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Хойшлогдсон татварын хөрөнгө, өр төлбөрийн нийт дүн:',
  },
  {
    align: 'right',
    value: 'tur_zuruu_eh',
  },
  {
    align: 'right',
    value: 'ehnii_uld_zalruulga',
  },
  {
    align: 'right',
    value: 'tur_zuruu_deb',
  },
  {
    align: 'right',
    value: 'tur_zuruu_cred',
  },
  {
    align: 'right',
    value: 'tur_zuruu_ets',
  },
  {
    align: 'right',
    value: 'ur_tur_zuruu_eh',
  },
  {
    align: 'right',
    value: 'ur_ehnii_uld_zalruulga',
  },
  {
    align: 'right',
    value: 'ur_tur_zuruu_deb',
  },
  {
    align: 'right',
    value: 'ur_tur_zuruu_cred',
  },
  {
    align: 'right',
    value: 'ur_tur_zuruu_ets',
  },
];

export const customColumns = () => [
  {
    title: 'Ангилал',
    dataIndex: 'angilal_code',
    align: 'left',
    titleAlign: 'center',
    width: 80,
    fixed: 'left',
  },
  {
    title: 'Үзүүлэлт',
    dataIndex: 'angilal_ner',
    align: 'left',
    titleAlign: 'center',
    ellipsis: true,
    width: 350,
  },
  {
    title: 'Хойшлогдсон татварын хөрөнгө',
    titleAlign: 'center',
    children: [
      {
        title: 'Хасагдах түр зөрүү эхний үлдэгдэл',
        dataIndex: 'tur_zuruu_eh',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => {
          if (row?.mor_type === 'percent') {
            return {
              children: `${roundFloat(row?.tur_zuruu_eh).toString()} %`,
            };
          }
          return {
            children: roundFloat(row?.tur_zuruu_eh),
          };
        },
      },
      {
        title: 'Эхний үлдэгдлийн залруулга',
        dataIndex: 'ehnii_uld_zalruulga',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => {
          if (row?.mor_type === 'percent') {
            return {};
          }
          return {
            children: roundFloat(row?.ehnii_uld_zalruulga),
          };
        },
      },
      {
        title: 'Түр зөрүү',
        titleAlign: 'center',
        children: [
          {
            title: 'Дебет',
            dataIndex: 'tur_zuruu_deb',
            align: 'right',
            titleAlign: 'center',
            width: 150,
            render: (_, row) => {
              if (row?.mor_type === 'percent') {
                return {
                  children: `${roundFloat(row?.tur_zuruu_deb).toString()} %`,
                };
              }
              return {
                children: roundFloat(row?.tur_zuruu_deb),
              };
            },
          },
          {
            title: 'Кредит',
            dataIndex: 'tur_zuruu_cred',
            align: 'right',
            titleAlign: 'center',
            width: 150,
            render: (_, row) => {
              if (row?.mor_type === 'percent') {
                return {
                  children: `${roundFloat(row?.tur_zuruu_cred).toString()} %`,
                };
              }
              return {
                children: roundFloat(row?.tur_zuruu_cred),
              };
            },
          },
        ],
      },
      {
        title: 'Хасагдах түр зөрүү эцсийн үлдэгдэл',
        dataIndex: 'tur_zuruu_ets',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => {
          if (row?.mor_type === 'percent') {
            return {
              children: `${roundFloat(row?.tur_zuruu_ets).toString()} %`,
            };
          }
          return {
            children: roundFloat(row?.tur_zuruu_ets),
          };
        },
      },
    ],
  },
  {
    title: 'Хойшлогдсон хөрөнгийн өр төлбөр',
    titleAlign: 'center',
    children: [
      {
        title: 'Татвар ногдох түр зөрүүгийн эхний үлдэгдэл',
        dataIndex: 'ur_tur_zuruu_eh',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => {
          if (row?.mor_type === 'percent') {
            return {
              children: `${roundFloat(row?.ur_tur_zuruu_eh).toString()} %`,
            };
          }
          return {
            children: roundFloat(row?.ur_tur_zuruu_eh),
          };
        },
      },
      {
        title: 'Эхний үлдэгдлийн залруулга',
        dataIndex: 'ur_ehnii_uld_zalruulga',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => {
          if (row?.mor_type === 'percent') {
            return {};
          }
          return {
            children: roundFloat(row?.ur_ehnii_uld_zalruulga),
          };
        },
      },
      {
        title: 'Түр зөрүү',
        titleAlign: 'center',
        children: [
          {
            title: 'Дебет',
            dataIndex: 'ur_tur_zuruu_deb',
            align: 'right',
            titleAlign: 'center',
            width: 150,
            render: (_, row) => {
              if (row?.mor_type === 'percent') {
                return {
                  children: `${roundFloat(row?.ur_tur_zuruu_deb).toString()} %`,
                };
              }
              return {
                children: roundFloat(row?.ur_tur_zuruu_deb),
              };
            },
          },
          {
            title: 'Кредит',
            dataIndex: 'ur_tur_zuruu_cred',
            align: 'right',
            titleAlign: 'center',
            width: 150,
            render: (_, row) => {
              if (row?.mor_type === 'percent') {
                return {
                  children: `${roundFloat(
                    row?.ur_tur_zuruu_cred
                  ).toString()} %`,
                };
              }
              return {
                children: roundFloat(row?.ur_tur_zuruu_cred),
              };
            },
          },
        ],
      },
      {
        title: 'Татвар ногдох түр зөрүүгийн эцсийн үлдэгдэл',
        dataIndex: 'ur_tur_zuruu_ets',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => {
          if (row?.mor_type === 'percent') {
            return {
              children: `${roundFloat(row?.ur_tur_zuruu_ets).toString()} %`,
            };
          }
          return {
            children: roundFloat(row?.ur_tur_zuruu_ets),
          };
        },
      },
    ],
  },
];
