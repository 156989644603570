import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'src/core/utils/axios';
import { ExpandedTable } from 'src/components';
import {
  Typography,
  // Empty,
  Tooltip,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Button,
  Divider,
  Checkbox,
  Radio,
  Select as AntSelect,
} from 'antd';
import { CheckOutlined, ExportOutlined } from '@ant-design/icons';

import { isEmpty } from 'lodash';
import { roundFloat, onlyNumber } from 'src/core/utils/formater';

import TreeSelect from 'src/components/common/treeSelect';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';
import { is_empty } from 'src/core/actions/hintStep';
import { customColumns, customSummary } from './dataStructure/data';

const FileDownload = require('js-file-download');

const { Text } = Typography;

const MainPage = () => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);
  const data_date = { month: '13' };

  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [dansInfo, setDansInfo] = useState(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validEnabled, setValidEnabled] = useState(false);
  const [validtext, setValidText] = useState('');

  const [form] = Form.useForm();

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data?.status === '0') {
        if ('json_array' in data?.content) {
          const sanhuu = data?.content?.json_array?.sanhuu;
          const tatvar = data?.content?.json_array?.tatvar;
          const sum = data?.content?.json_array?.sum;

          setTableData([...sanhuu, ...tatvar]);
          setTableDataSum(sum);
          setValidEnabled(data?.content?.validEnabled);
          setValidText(data?.content?.validTooltip);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }
      } else if (data?.status === '5') {
        dispatch(is_empty(true));
        newAlert({ type: 'error', msg: 'Санхүүгийн данс ангилна уу' });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_shalgah_balance_control',
      content: {
        action_type: 'generate_tailan',
        month_start: '1',
        month_end: String(month),
        first_year: true,
      },
    });
    dispatch(start_loading({}));
  };

  /**
   * Export tailan to xlsx format
   */
  const exportTailan = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        const file_export_address_link = `/get_file?file_id=${data.content.file_id}`;
        axios.get('export', file_export_address_link, {}, {}, (event) => {
          FileDownload(event, 'Татварын шалгах баланс.xlsx');
        });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_shalgah_balance_control',
      content: {
        action_type: 'generate_tailan',
        month_start: '1',
        month_end: String(data_date.month),
        first_year: true,
        receive_channel: 'ss010_00_export_file',
        receive_action_type: 'export_shalgah_balance',
      },
    });

    dispatch(start_loading({}));
  };

  const saveValues = (saveData) => {
    const tempData = saveData;
    delete tempData.uldegdel;
    delete tempData.undsen_dans_dugaar;
    delete tempData.undsen_dans_ner;
    tempData.month = String(data_date.month);
    tempData.tohiruulga_nemelt = dansInfo?.tohiruulga_nemelt;

    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');

      if (data_temp.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan(data_date.month);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tatvar_journal_burtgel',
      content: {
        action_type: 'add_journal_orlogo',
        data: tempData,
        first_year: true,
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
  }, []);

  const changeDunSongolt = (dunType) => {
    const uldegdel = dansInfo?.uldegdel;

    let uldegdel_hurimtlagdsan = 0.0;
    if (dunType?.target?.value === 'system') {
      const dun_system_calculated = dansInfo?.dun_system_calculated;
      uldegdel_hurimtlagdsan = uldegdel - dun_system_calculated;

      if (dansInfo?.dun_auto_type === 'debit') {
        form.setFieldsValue({
          deb: roundFloat(dun_system_calculated),
          uldegdel_hurimtlagdsan: roundFloat(uldegdel_hurimtlagdsan),
        });
      } else if (dansInfo?.dun_auto_type === 'credit') {
        form.setFieldsValue({
          cred: roundFloat(dun_system_calculated),
          uldegdel_hurimtlagdsan: roundFloat(uldegdel_hurimtlagdsan),
        });
      }
    } else if (dunType?.target?.value === 'user') {
      const dun_user_calculated = dansInfo?.dun_user_calculated;
      uldegdel_hurimtlagdsan = uldegdel - dun_user_calculated;

      if (dansInfo?.dun_auto_type === 'debit') {
        form.setFieldsValue({
          deb: roundFloat(dun_user_calculated),
          uldegdel_hurimtlagdsan: roundFloat(uldegdel_hurimtlagdsan),
        });
      } else if (dansInfo?.dun_auto_type === 'credit') {
        form.setFieldsValue({
          cred: roundFloat(dun_user_calculated),
          uldegdel_hurimtlagdsan: roundFloat(uldegdel_hurimtlagdsan),
        });
      }
    }
  };

  const editAction = (row) => {
    socket.on('request_out', (data) => {
      const data_temp_props = data;
      socket.removeAllListeners('request_out');

      if (data_temp_props.status === '0') {
        setDansInfo(data_temp_props.content);
        // Set form data
        form.setFieldsValue({
          undsen_dans_id: row?.dans_id,
          undsen_dans_dugaar: row?.dans_dugaar,
          undsen_dans_ner: row?.dans_ner,
          uldegdel: roundFloat(data_temp_props?.content?.uldegdel),
          deb: roundFloat(data_temp_props?.content?.deb),
          cred: roundFloat(data_temp_props?.content?.cred),
          dun_user_calculated: roundFloat(
            data_temp_props?.content?.dun_user_calculated
          ),
          dun_system_calculated: roundFloat(
            data_temp_props?.content?.dun_system_calculated
          ),
          uldegdel_hurimtlagdsan: roundFloat(0.0),
          harits_dans_id: data_temp_props?.content?.haritsah_dans_id_default,
        });

        setVisible(true);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tatvar_journal_burtgel',
      content: {
        action_type: 'get_dans_info',
        tohiruulga_type: 'Орлого',
        month: String(data_date.month),
        dans_id: row?.dans_id,
        first_year: true,
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (!isEmpty(tableData)) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [tableData]);

  if (loading) {
    // return <Empty description={false} />;
    return '';
  }

  const validateData = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай баталгаажлаа' });
        generateTailan(data_date.month);
      } else if (data.status === '5') {
        newAlert({ type: 'error', msg: data?.content?.message });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: {
        month: String(data_date.month),
        step_type: 'shalgah_balance',
        action_type: 'validate_values',
      },
    });

    dispatch(start_loading({}));
  };

  const title = () => (
    <>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<ExportOutlined />}
        onClick={() => {
          exportTailan();
        }}
      >
        Экспорт
      </Button>
      <Tooltip title={validtext}>
        <Button
          className="add-btn"
          type="primary"
          size="small"
          icon={<CheckOutlined />}
          onClick={() => {
            validateData();
          }}
          disabled={!validEnabled || !version?.is_first_year}
        >
          Дүн баталгаажуулах
        </Button>
      </Tooltip>
    </>
  );

  return (
    <>
      <div className="table-antd-div tailan-container-1535 no-scroll">
        <ExpandedTable
          columns={customColumns({
            customEdit: editAction,
            version,
          })}
          dataSource={tableData}
          scroll={{
            y: 'calc(100vh - 280px)',
          }}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableDataSum,
          }}
          isTitle
          title={title}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Татварын тохиргоо бүртгэх"
        open={visible}
        closable={false}
        maskClosable={false}
        width={810}
        footer={null}
      >
        <CustomModal
          onClose={() => {
            setVisible(false);
          }}
          form={form}
          listData={dansInfo}
          saveValues={saveValues}
          changeDunSongolt={changeDunSongolt}
        />
      </Modal>
    </>
  );
};

const layout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 15 },
  },
};

const CustomModal = ({
  onClose = () => {},
  form = null,
  listData = {},
  saveValues = () => {},
  changeDunSongolt = () => {},
}) => {
  const onFinish = (values) => {
    saveValues(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="undsen_dans_dugaar" hidden />
        <Form.Item name="undsen_dans_ner" hidden />
        <Form.Item name="dun_user_calculated" hidden />
        <Form.Item name="dun_system_calculated" hidden />
        <Form.Item name="undsen_dans_id" label="Үндсэн данс" labelAlign="left">
          <Text ellipsis>
            {form.getFieldValue('undsen_dans_dugaar')}{' '}
            {form.getFieldValue('undsen_dans_ner')}
          </Text>
        </Form.Item>
        <Form.Item name="uldegdel" label="Дансны үлдэгдэл" labelAlign="left">
          <Text>{form.getFieldValue('uldegdel')}</Text>
        </Form.Item>
        {listData?.tohiruulga_nemelt === 'hurimtlagdsan' ? (
          <div>
            <Divider plain>Дүн сонголт</Divider>
            <Form.Item
              name="dun_songolt"
              label="Тооцоолсон дүн"
              labelAlign="left"
            >
              <Radio.Group onChange={changeDunSongolt}>
                <Radio
                  value="user"
                  disabled={listData?.dun_user_calculated_disabled}
                >
                  {form.getFieldValue('dun_user_calculated')} /Хэрэглэгч/
                </Radio>
                <Radio value="system">
                  {form.getFieldValue('dun_system_calculated')} /Tax Acc/
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="uldegdel_hurimtlagdsan"
              label="Үүнээс хуримтлагдсан ашигт тохируулах"
              labelAlign="left"
            >
              <Input className="w-100 text-align-r" disabled />
            </Form.Item>
          </div>
        ) : (
          ''
        )}
        <Divider plain>Үндсэн тохиргоо</Divider>
        <Form.Item
          name="harits_dans_id"
          label="Харьцах данс"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <TreeSelect
            data={listData?.haritsah_dans_list}
            defaultExpandAll={false}
            disabled={listData.input_disabled}
          />
        </Form.Item>
        <Form.Item
          name="deb"
          label="Дебет"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input
            className="w-100 text-align-r"
            disabled={listData.input_disabled}
          />
        </Form.Item>
        <Form.Item
          name="cred"
          label="Кредит"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input
            className="w-100 text-align-r"
            disabled={listData.input_disabled}
          />
        </Form.Item>
        {listData?.tohiruulga_nemelt !== 'balance' ? (
          <div>
            <Divider plain>Нэмэлт тохиргоо</Divider>
            <Form.Item
              name="guilgee_utga"
              label="Гүйлгээний утга"
              labelAlign="left"
            >
              <Input className="w-100" />
            </Form.Item>
            <Form.Item
              name="aanoat_nohtsol"
              label="ААНОАТ-ын орлого ХЗ нөхцөл"
              labelAlign="left"
              hidden={listData?.aanoat_nohtsol_enabled}
            >
              <AntSelect defaultValue={null}>
                <AntSelect.Option value={null}>---</AntSelect.Option>
                {listData?.aanoat_nohtsol.map((i) => (
                  <AntSelect.Option value={i?.id}>
                    {i?.dugaar} {i?.ner}
                  </AntSelect.Option>
                ))}
              </AntSelect>
            </Form.Item>
            <Form.Item
              name="gadaad_orlogo"
              label="Гадаад улсад олсон орлого эсэх"
              labelAlign="left"
              hidden={listData?.gadaad_orlogo_enabled}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              name="aanoat_zardal"
              label="ААНОАТ 18.4-т заасан зардлал эсэх"
              labelAlign="left"
              hidden={listData?.aanoat_zardal_enabled}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </div>
        ) : (
          ''
        )}
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MainPage;
