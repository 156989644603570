import React from 'react';
import {
  EditOutlined,
  CheckOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Space } from 'antd';
import { roundFloat } from 'src/core/utils/formater';

export const customSummary = [
  {
    align: 'left',
    word: '',
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'dans_une',
  },
  {
    align: 'right',
    value: 'tatvar_suuri',
  },
  {
    align: 'right',
    value: 'zuruu',
  },
  {
    align: 'center',
    word: '',
  },
  {
    align: 'center',
    word: '',
  },
];

export const customColumns = ({ customEdit = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    titleAlign: 'center',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    align: 'left',
    titleAlign: 'center',
    ellipsis: true,
    width: 250,
  },
  {
    title: 'Дансны үнэ',
    dataIndex: 'dans_une',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.dans_une),
    }),
  },
  {
    title: 'Татварын суурь',
    dataIndex: 'tatvar_suuri',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.tatvar_suuri),
    }),
  },
  {
    title: 'Зөрүү',
    dataIndex: 'zuruu',
    align: 'right',
    titleAlign: 'center',
    width: 150,
    render: (_, row) => ({
      children: roundFloat(row?.zuruu),
    }),
  },
  {
    title: 'Түр зөрүүгийн төрөл',
    dataIndex: 'tur_zuruu_turul',
    align: 'center',
    titleAlign: 'center',
    width: 150,
    ellipsis: true,
    render: (_, row) => ({
      children: <span className="cell-padding-5">{row?.tur_zuruu_turul}</span>,
    }),
  },
  {
    title: 'Татварын тохиргоо',
    align: 'center',
    titleAlign: 'center',
    width: 70,
    fixed: 'right',
    render: (_, row) => {
      if (row?.mor_type !== 'sum' && row?.can_edit === true) {
        if (row?.has_tohirgoo === true) {
          if (row?.has_warning === true) {
            return (
              <Space size="middle">
                <div>
                  <EditOutlined onClick={() => customEdit(row)} />
                </div>
                <div>
                  <WarningOutlined />
                </div>
              </Space>
            );
          }
          return (
            <Space size="middle">
              <div>
                <EditOutlined onClick={() => customEdit(row)} />
              </div>
              <div>
                <CheckOutlined />
              </div>
            </Space>
          );
        }
        return (
          <Space size="middle">
            <div>
              <EditOutlined onClick={() => customEdit(row)} />
            </div>
            <div>
              <WarningOutlined />
            </div>
          </Space>
        );
      }
      return '';
    },
  },
];
