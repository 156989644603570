import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'antd';
import { UpCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { useQuery } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import Table from 'src/components/common/table';
import CanView from 'src/components/permission/canview';
import newAlert from 'src/components/newAlert';
import { set_alert } from 'src/core/actions/alert';
import { columns, modalBody } from './data';
import RegisterClientModal from './registerClientModal';
import RegisterFirstBalanceModal from './registerFirstBalance';

const HariltsagchBurtgeh = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);

  const [tableData, setTableData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [actionType, setActionType] = useState('');

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isVisibleFileModal, setIsVisibleFileModal] = useState(false);

  const [modalValues, setModalValues] = useState({
    code: '',
    name: '',
    country_code: '',
    is_foreign: '',
    ttd: '',
  });
  const [total, setTotal] = useState(0);

  const handleOpenRegisterModal = (rowData = {}) => {
    socket.on('request_out', (countryData) => {
      socket.removeAllListeners('request_out');
      if (countryData.content.status === '0') {
        setCountries(countryData.content.json_array);
        if (rowData) {
          setModalValues(rowData);
          setActionType('edit');
        } else {
          setModalValues({ is_foreign: '0', country_code: 976 });
          setActionType('register');
        }
        setIsVisibleModal(true);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts002_00_hariltsagch_medeelel',
      content: {
        action_type: 'country_list',
        version: version.version_number,
        year: version.year,
      },
    });
    dispatch(start_loading({}));
  };

  const handleSave = (paramObj) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0') {
        setTableData(data.content.json_array);
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
      setIsVisibleModal(false);
    });
    socket.emit('request_in', {
      channel: 'ts002_00_hariltsagch_medeelel',
      content: {
        action_type:
          actionType === 'edit' ? 'hariltsagch_edit' : 'hariltsagch_register',
        data_type: actionType === 'edit' ? '' : 'manual',
        array_data: [paramObj],
        page: query.get('page') || 1,
        size: query.get('size') || 20,
      },
    });
    dispatch(start_loading({}));
  };

  const handleOpenUploadFile = () => {
    setIsVisibleFileModal(true);
  };

  const handleDeleteRow = (row = {}) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0') {
        setTableData(data.content.json_array);
        newAlert({ type: 'success', msg: 'Амжилттай устгагдлаа' });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
      setIsVisibleModal(false);
    });
    socket.emit('request_in', {
      channel: 'ts002_00_delete_undsen_medeelel',
      content: {
        version: version.version_number,
        year: version.year,
        actionType: 'delete_hariltsagch',
        hariltsagch_code: row?.code,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0') {
        const resData = data.content.json_array;
        resData.forEach((element) => {
          // eslint-disable-next-line no-param-reassign
          element.country_json = {
            country_code: element.country_code,
            is_foreign: element.is_foreign,
          };
        });

        console.log('data.content',data.content);
        
        dispatch(set_alert({
          type: 'warning',
          msg: data?.content?.main_validtion.message,
          open: !data?.content?.main_validtion.validated,
        }))
        
        setTableData(resData);
        setTotal(data.content?.total);
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
    });
    socket.emit('request_in', {
      channel: 'ts002_00_hariltsagch_medeelel',
      content: {
        version: version.version_number,
        year: version.year,
        action_type: 'hariltsagch_list',
        page: query.get('page') || 1,
        size: query.get('size') || 20,
      },
    });

    dispatch(start_loading({}));
  }, [query]);

  return (
    <>
      <div className="table-wrapper">
        <div className="table-antd-div no-scroll">
          <Table
            dataSource={tableData}
            columns={columns({
              editRow: handleOpenRegisterModal,
              deleteRow: handleDeleteRow,
            })}
            scroll={{
              y: 'calc(100vh - 280px)',
            }}
            total={total}
            title={() => (
              <>
                <CanView allowedRole="006_1">
                  <Button
                    className="add-btn"
                    type="primary"
                    size="small"
                    icon={<UpCircleOutlined />}
                    onClick={() => {
                      handleOpenUploadFile(null);
                    }}
                  >
                    Эхний үлдэгдэл оруулах
                  </Button>
                </CanView>
                <CanView allowedRole="007_1">
                  <Button
                    className="add-btn"
                    type="primary"
                    size="small"
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                      handleOpenRegisterModal(null);
                    }}
                  >
                    Харилцагч нэмэх
                  </Button>
                </CanView>
              </>
            )}
          />
        </div>
      </div>
      <Modal
        {...modalBody}
        width={700}
        visible={isVisibleModal}
        title="Харилцагч нэмэх"
      >
        <RegisterClientModal
          countryList={countries}
          initialValues={modalValues}
          handleSave={handleSave}
          actionType={actionType}
          onClose={() => {
            setIsVisibleModal(false);
          }}
        />
      </Modal>
      <Modal
        {...modalBody}
        visible={isVisibleFileModal}
        title="Эхний үлдэгдэл оруулах"
      >
        <RegisterFirstBalanceModal
          onClose={() => {
            setIsVisibleFileModal(false);
          }}
          setClients={setTableData}
        />
      </Modal>
    </>
  );
};

export default HariltsagchBurtgeh;
