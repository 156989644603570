import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Modal } from 'antd';
import { useQuery } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';
import Table from 'src/components/common/table';
import {
  hariltsagch_columns,
  hariltsagch_sum_columns,
} from '../dataStructure/data';
import ModalTurZuruu from '../modals/modalTurZuruu';

const { Text } = Typography;

export default function TabHariltsagch() {
  const dispatch = useDispatch();
  const query = useQuery();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [totalData, setTotalData] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [modalValues, setModalValues] = useState({
    dans_code: '',
    hariltsagch_code: '',
    hariltsagch_ner: '',
    zuruu: 0,
    hz_zuruu_amount: 0,
    data_type: '',
  });

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const pushDialogValuesData = (rowData) => {
    if (
      // eslint-disable-next-line operator-linebreak
      (rowData?.hasagdah_tur_zuruu !== 0 ||
        // eslint-disable-next-line operator-linebreak
        rowData?.hz_hasagdah_tur_zuruu !== 0) &&
      query.get('dans_code') !== null
    ) {
      setModalValues({
        dans_code: query.get('dans_code'),
        hariltsagch_code: rowData.hariltsagch_code,
        hariltsagch_ner: rowData.hariltsagch_ner,
        zuruu: rowData.zuruu,
        hz_zuruu_amount: rowData.hz_hasagdah_tur_zuruu,
        data_type: 'hasagdah_tur_zuruu',
      });
      setOpenModal(true);
    } else if (
      // eslint-disable-next-line operator-linebreak
      (rowData?.tatvar_tur_zuruu !== 0 || rowData?.hz_tatvar_tur_zuruu !== 0) &&
      query.get('dans_code') !== null
    ) {
      setModalValues({
        dans_code: query.get('dans_code'),
        hariltsagch_code: rowData.hariltsagch_code,
        hariltsagch_ner: rowData.hariltsagch_ner,
        zuruu: rowData.zuruu,
        hz_zuruu_amount: rowData.hz_tatvar_tur_zuruu,
        data_type: 'tatvar_tur_zuruu',
      });
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  const saveUnrecognizeTempDiff = (json_data) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0') {
        setTableData(data.content?.json_array);
        setTotalData(data.content?.total);
        setTableDataSum(data.content?.sum_dict);
        newAlert({ type: 'success', msg: 'Түр зөрүү амжилттай хадгалагдлаа' });
        handleModalClose();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts002_00_tur_zuruu',
      content: {
        action_type: 'recognize_temp_diff',
        json_data,
        month: data_date.month,
        page: query.get('page2') || 1,
        size: query.get('size2') || 20,
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    const fetchData = async (page, size) => {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');

        if (data.content.status === '0') {
          setTableData(data.content?.json_array);
          setTotalData(data.content?.total);
          setTableDataSum(data.content?.sum_dict);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }

        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts002_00_tur_zuruu',
        content: {
          action_type: 'fetch_tur_zuruu_hariltsagchaar',
          month: data_date.month,
          page: page || 1,
          size: size || 20,
          dans_code: query.get('dans_code'),
        },
      });
      dispatch(start_loading({}));
    };
    const page = query.get('page2');
    const size = query.get('size2');
    if (query.get('tab') === '2' && query.get('dans_code')) {
      fetchData(page, size);
    }
  }, [query, data_date]);

  return (
    <>
      {query.get('dans_code') ? (
        <div className="table-wrapper">
          <p>
            <span className="ml-3 "> Дансны дугаар: </span>
            {query.get('dans_code')}
          </p>
          <div className="inside-table">
            <Table
              columns={hariltsagch_columns(pushDialogValuesData)}
              dataSource={tableData}
              scroll={{
                y: 'calc(100vh - 360px)',
              }}
              tab="2"
              total={totalData}
              summaryList={{
                sumColumn: hariltsagch_sum_columns,
                sumDataSource: tableDataSum,
              }}
            />
          </div>
        </div>
      ) : (
        <Text className="ant-form-text">Дансаа сонгоно уу!</Text>
      )}
      <Modal
        className="custom-modal"
        closable={false}
        maskClosable={false}
        width={600}
        footer={null}
        visible={openModal}
        title="Хүлээн зөвшөөрөөгүй түр зөрүү бүртгэх"
      >
        <ModalTurZuruu
          initialValues={modalValues}
          onCancel={handleModalClose}
          handleSave={saveUnrecognizeTempDiff}
        />
      </Modal>
    </>
  );
}
