import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Input,
  InputNumber,
  Table,
  Form,
  Tooltip,
  DatePicker,
  Collapse,
} from 'antd';
import {
  LoadingOutlined,
  DownOutlined,
  UpOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { roundFloat } from 'src/core/utils/formater';
import { customConfirm } from 'src/components/common/confirmModal';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { enable_state, disable_state } from 'src/core/actions/route_state';
import moment from 'moment';

const { Panel } = Collapse;

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm(); // Use Form.useForm() for better handling
  const inputRef = useRef(null);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus(); // Ensure input is focused
    }
  }, [editing]);

  const toggleEdit = () => {
    if (editable) {
      setEditing(!editing);
      console.log('Editing toggled for:', dataIndex);
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields(); // Use form.validateFields()
      setEditing(false);
      handleSave({ ...record, [dataIndex]: values[dataIndex] }); // Call with updated values
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form
        form={form}
        component={false}
        onFinish={save}
        initialValues={{ [dataIndex]: record[dataIndex] }} // Set initial values
      >
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {dataIndex === 'turul' || dataIndex === 'sex' ? (
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
            />
          ) : (
            <InputNumber
              className="wide-input-number" // Apply the custom class
              ref={inputRef}
              min={0}
              step={0.01}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
              formatter={(value) => `${value}`}
              parser={(value) => value}
              precision={2}
            />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children || ' '}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
const AddRowTable = () => {
  const dispatch = useDispatch();
  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });
  const [initialSummary, seTinitialSummary] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [text, setText] = useState('');
  const handleChange = (e) => {
    setText(e.target.value);
  };

  const get_data = (month) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        setDataSource(content?.table_data);
        setText(content?.text);
        seTinitialSummary(content?.initial_summary);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'get_sa12',
        month,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date?.month) get_data(data_date?.month);
  }, [data_date]);

  useEffect(() => {
    const allFieldsFilled = dataSource.every(
      (row) =>
        row.turul !== '' &&
        row.age !== '' &&
        row.sex !== '' &&
        row.eh_too !== '' &&
        row.eh_une !== '' &&
        row.ets_too !== '' &&
        row.ets_une !== ''
    );
    // if (allFieldsFilled) {
    //   dispatch(disable_state({}));
    // }

    setIsSubmitDisabled(!allFieldsFilled);
  }, [dataSource]);

  const [newRows, setNewRows] = useState([]); // Track newly added rows

  const handleAddRow = () => {
    const newData = {
      key: (dataSource.length + 1).toString(),
      turul: '', // Initialize correctly
      age: '',
      sex: '',
      eh_too: 0.0, // Ensure numeric initial values
      eh_une: 0.0, // Ensure numeric initial values
      ets_too: 0.0, // Ensure numeric initial values
      ets_une: 0.0, // Ensure numeric initial values
      editable: true,
    };
    setDataSource([...dataSource, newData]);
    setNewRows([...newRows, newData]); // Keep track of the newly added row
    dispatch(enable_state({}));
  };

  const handleDelete = (key) => {
    setDataSource((prevDataSource) =>
      prevDataSource.filter((item) => item.key !== key)
    );
    setNewRows((prevNewRows) => prevNewRows.filter((item) => item.key !== key)); // Remove from newRows
  };

  const handleSave = (row) => {
    setDataSource((prevDataSource) =>
      prevDataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    );
    setNewRows((prevNewRows) =>
      prevNewRows.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    ); // Update in newRows
  };
  // Helper function to compare floating-point numbers with tolerance
  const areNumbersEqual = (a, b, epsilon = 0.000001) =>
    Math.abs(a - b) < epsilon;

  // Calculate the sums of only the newly added rows
  const newSummary = newRows.reduce(
    (acc, item) => {
      const firstBalance = parseFloat(item.eh_une) || 0;
      const etsUld = parseFloat(item.ets_une) || 0;
      acc.eh_une += firstBalance;
      acc.ets_une += etsUld;
      return acc;
    },
    { eh_une: 0, ets_une: 0 }
  );

  // Use initialSummary for confirmation logic
  const isConfirmDisabled =
    newRows.length > 0 &&
    (!areNumbersEqual(newSummary.eh_une, initialSummary.eh_une) ||
      !areNumbersEqual(newSummary.ets_une, initialSummary.ets_une));

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleConfirm = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: get_data(data_date.month) },
      });
      dispatch(end_loading({}));
      if (status === '0') {
        dispatch(disable_state({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'save_sa12',
        month: data_date?.month,
        table_data: dataSource,
        text,
      },
    });
    dispatch(start_loading({}));
  };

  const columns = [
    {
      title: 'Биологийн хөрөнгийн төрөл',
      dataIndex: 'turul',
      key: 'turul',
      editable: true,
      width: 300,
    },
    {
      title: 'Нас',
      dataIndex: 'age',
      key: 'age',
      editable: true,
      width: 150,
    },
    {
      title: 'Хүйс',
      dataIndex: 'sex',
      key: 'sex',
      editable: true,
      width: 150,
    },
    {
      title: (
        <div>
          <div>Эхний үлдэгдэл</div>
          <div>Тоо</div>
        </div>
      ),
      dataIndex: 'eh_too',
      key: 'eh_too',
      editable: true,
      width: 150,
    },
    {
      title: (
        <div>
          <div>Эхний үлдэгдэл</div>
          <div>Дансны үнэ</div>
        </div>
      ),
      dataIndex: 'eh_une',
      key: 'eh_une',
      editable: true,
      width: 150,
    },
    {
      title: (
        <div>
          <div>Эцсийн үлдэгдэл</div>
          <div>Тоо</div>
        </div>
      ),
      dataIndex: 'ets_too',
      key: 'ets_too',
      editable: true,
      width: 150,
    },
    {
      title: (
        <div>
          <div>Эцсийн үлдэгдэл</div>
          <div>Дансны үнэ</div>
        </div>
      ),
      dataIndex: 'ets_une',
      key: 'ets_une',
      editable: true,
      width: 150,
    },
    {
      title: 'Устгах',
      key: 'action',
      width: 150,
      render: (_, record) =>
        record.editable !== false && (
          <Tooltip title="Устгах">
            <DeleteOutlined
              onClick={() =>
                customConfirm({
                  onOk: () => handleDelete(record.key),
                })
              }
            />
          </Tooltip>
        ),
    },
  ];

  const mergedColumns = columns.map((col) => ({
    ...col,
    onCell: (record) => ({
      record,
      editable: col.editable && record.editable !== false,
      dataIndex: col.dataIndex,
      title: col.title,
      handleSave,
    }),
  }));

  const totalSum = dataSource.reduce(
    (acc, item) => ({
      ehTooSum: acc.ehTooSum + (parseFloat(item.eh_too) || 0),
      ehUneSum: acc.ehUneSum + (parseFloat(item.eh_une) || 0),
      etsTooSum: acc.etsTooSum + (parseFloat(item.ets_too) || 0),
      etsUneSum: acc.etsUneSum + (parseFloat(item.ets_une) || 0),
    }),
    { ehTooSum: 0, ehUneSum: 0, etsTooSum: 0, etsUneSum: 0 }
  );

  const { ehTooSum, ehUneSum, etsTooSum, etsUneSum } = totalSum;

  return (
    <div className="tax-account-table-container">
      <Collapse
        defaultActiveKey={['4']}
        expandIconPosition="right"
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ? <UpOutlined /> : <DownOutlined />
        }
      >
        <Panel header="12. Биологийн хөрөнгө" key="1">
          <div className="table-add-row">
            <div className="table-container">
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                dataSource={dataSource}
                columns={mergedColumns}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey="key"
                // summary={() => (
                //   <Table.Summary.Row>
                //     <Table.Summary.Cell index={0}>Нийт</Table.Summary.Cell>
                //     <Table.Summary.Cell index={1} />
                //     <Table.Summary.Cell index={2} />
                //     <Table.Summary.Cell index={3}>
                //       {roundFloat(ehTooSum)}
                //     </Table.Summary.Cell>
                //     <Table.Summary.Cell index={4}>
                //       {roundFloat(ehUneSum)}
                //     </Table.Summary.Cell>
                //     <Table.Summary.Cell index={5}>
                //       {roundFloat(etsTooSum)}
                //     </Table.Summary.Cell>
                //     <Table.Summary.Cell index={6}>
                //       {roundFloat(etsUneSum)}
                //     </Table.Summary.Cell>
                //   </Table.Summary.Row>
                // )}
                footer={() => (
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      type="primary"
                      className="add-btn"
                      onClick={handleAddRow}
                    >
                      <PlusCircleOutlined />
                      Мөр нэмэх
                    </Button>
                    <Tooltip
                      title={
                        isConfirmDisabled
                          ? 'Нэмсэн мөрүүдийн дүн дээрх мөртэй таарах ёстой'
                          : ''
                      }
                    >
                      <Button
                        torol="primary"
                        className="confirm-btn"
                        onClick={handleConfirm}
                        disabled={isConfirmDisabled}
                        style={{ marginLeft: 8 }}
                      >
                        Хадгалах
                      </Button>
                    </Tooltip>
                  </div>
                )}
              />
            </div>
          </div>
        </Panel>

        <Panel
          header="12.1 Тэмдэглэл (Биологийн хөрөнгийн хэмжилтийн суурь болон бусад тайлбар, тэмдэглэлийг хийнэ.)"
          key="4"
        >
          <div className="tax-account-notes-container">
            <table className="tax-account-table-unique">
              <thead>
                <tr>
                  <th>Тайлбар</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <textarea
                      value={text}
                      onChange={handleChange}
                      rows={10}
                      className="tax-account-textarea-unique"
                      placeholder="Бөглөнө үү"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <Button
              type="primary"
              className="tax-account-button-unique-mini"
              onClick={() => handleConfirm(2)}
            >
              Хадгалах
            </Button>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default AddRowTable;
