import React, { useState, useEffect } from 'react';

import { Tag, Button, Table, Form, Input, Row, Col, message } from 'antd';

import { set } from 'lodash';

const columns = [
  {
    title: 'Нэр',
    dataIndex: 'name',
  },
];

const RoleCreate = ({
  form = null,
  list = [],
  socket,
  onClose = () => {},
  reRender = () => {},
}) => {
  const roleId = form.getFieldValue('roleId');
  const [selectedRowKeys, seTselectedRowKeys] = useState([]);
  const onFinish = ({ role_name }) => {
    const _content = {};
    if (selectedRowKeys.length === 0) {
      message.error('no permission list selected', 2);
      return;
    }
    set(_content, 'permission_list', selectedRowKeys);

    const actionType = roleId
      ? 'update_role_permission'
      : 'add_role_permission';
    if (roleId) {
      set(_content, 'role_id', roleId);
    } else {
      set(_content, 'role_name', role_name);
    }
    socket.on('request_out', ({ content, status }) => {
      console.log(content, status);
      socket.removeAllListeners('request_out');
      reRender();
      onClose();
    });
    socket.emit('request_in', {
      channel: 'ss006_manage_permission',
      content: {
        action_type: actionType,
        ..._content,
      },
    });
  };

  useEffect(() => {
    if (roleId) {
      socket.on('request_out', ({ content, status }) => {
        if (status !== '0') {
          message.error('Алдаа!!!', 2);
          return;
        }
        const { name, permission_list = [] } = content;
        form.setFieldsValue({
          role_name: name,
        });

        if (permission_list.length > 0) {
          seTselectedRowKeys([...permission_list]);
        }
      });
      socket.emit('request_in', {
        channel: 'ss006_manage_permission',
        content: {
          action_type: 'get_list_by_role_id',
          role_id: roleId,
        },
      });
    }
  }, [roleId]);

  const dataSource = list.map((i) => ({
    ...i,
    key: i?.code,
  }));
  const handleClose = (removedTag) => {
    const _list = selectedRowKeys.filter((tag) => tag !== removedTag);
    seTselectedRowKeys(_list);
  };

  const slice = (str) => {
    if (!str) return;
    const _str = str.slice(0, 16);
    return `${_str}`;
  };
  const forMap = (tag) => {
    const finded = dataSource.find((i) => i?.code === tag);
    if (!finded) return;
    const tagElem = (
      <Tag
        closable
        style={{
          margin: 3,
        }}
        color="purple"
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {slice(finded?.name)}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  const close = () => {
    form.resetFields();
    seTselectedRowKeys([]);
    onClose();
  };

  const onSelectChange = (_) => {
    seTselectedRowKeys(_);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const tagChild = selectedRowKeys.map(forMap);
  return (
    <>
      <Form
        style={{
          fontSize: 13,
        }}
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onFinish}
      >
        <Form.Item
          name="role_name"
          label="Эрхийн төрөл нэр"
          labelAlign="right"
          rules={[
            {
              required: true,
              message: 'Эрхийн төрөл нэрээ оруулна уу!!',
            },
          ]}
        >
          <Input
            disabled={!!roleId}
            style={{
              width: '90%',
            }}
            placeholder="Эрхийн төрөл оруулна уу..."
          />
        </Form.Item>
        <div
          style={{
            margin: '10px 20px',
          }}
        >
          {tagChild}
        </div>
        <Table
          className="role-table"
          scroll={{
            y: 400,
            x: 'auto',
          }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item noStyle>
              <Button
                className="confirm-btn"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
              <Button
                onClick={close}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Буцах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RoleCreate;
