import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'src/components';
import newAlert from 'src/components/newAlert';
import { columns, customSummary } from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';

const PageData = () => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const [tableData, setTableData] = useState({
    result_list: [],
    total_list: {},
  });

  const generateTailan = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    // const month = data_date?.month;
    const year = data_date?.year;
    socket.emit('request_in', {
      channel: 'ss013_03_view',
      content: {
        action_type: 'noat_negtgel_page',
        // month,
        year,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan();
    }
  }, [data_date]);

  // const title = () => <div style={{ height: '24px' }} />;

  return (
    <>
      <div className="table-antd-div no-scroll">
        <Table
          columns={columns}
          dataSource={tableData?.result_list}
          scroll={{
            y: 'calc(100vh - 280px)',
          }}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableData?.total_list,
          }}
          // title={title}
        />
      </div>
    </>
  );
};

export default PageData;
