import React from 'react';
import { Form, Row, Col, Button, Upload, message, Modal } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';

import { FileError } from 'src/components/common/file_error';
import axios from 'src/core/utils/axios';

const typesList = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
export const AddUrsgalZasvarFile = ({
  onClose = () => {},
  visible,
  after_handleUpload = () => {},
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const onFinish = async (data) => {
    console.log('-----onFinish------<', data);
    const selectedFile = data.file;

    if (selectedFile) {
      dispatch(start_loading({}));
      const { size: file_size } = selectedFile[0];
      const { file_source } = data;
      const formData = new FormData();
      formData.append('file', selectedFile[0].originFileObj);
      const { status, content } = await axios.post(
        'first',
        '/zardal_ursgal_zaswar',
        formData,
        {
          file_size,
          file_source,
        }
      );
      if (status === '0') {
        after_handleUpload(content);
        console.log('File data', content?.json_array);
      } else if (content?.type === 'content') {
        FileError({
          text: content?.Message,
        });
        dispatch(end_loading({}));
        form.resetFields();
        onCancel();
      } else {
        newAlert({ type: 'error', msg: content?.Message });
        dispatch(end_loading({}));
        form.resetFields();
        onCancel();
      }
    }

    form.resetFields();
    onCancel();
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <>
      <Modal
        className="custom-modal"
        title="Урсгал засварын жагсаалт оруулах"
        visible={visible}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <Form
          form={form}
          onFinish={onFinish}
          className="register-form"
          size="small"
        >
          <Form.Item
            name="file"
            label="Файл"
            labelAlign="left"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: 'Файл сонгоно уу' }]}
          >
            <Upload
              accept={typesList.join()}
              showUploadList
              maxCount={1}
              beforeUpload={(File) => {
                const isXls = typesList.find((i) => i === File.type);
                if (!isXls) {
                  message.error('Зөвхөн xls, xlsx өргөтгөлтэй файл оруулна уу');
                  return Upload.LIST_IGNORE;
                }
                return false;
              }}
            >
              <CloudUploadOutlined className="fs-30" />
            </Upload>
          </Form.Item>
          <Row>
            <Col span={24} className="text-align-r">
              <Form.Item noStyle>
                <Button
                  onClick={onCancel}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Цуцлах
                </Button>
                <Button
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
