import React from 'react';
import { roundFloat } from 'src/core/utils/formater';

import { Button } from 'antd';

export const dans_columns = ({ handleSelectDans = () => {} }) => [
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_code',
    align: 'left',
    width: 250,
    fixed: 'left',
    render: (_, row) => (
      <Button
        type="link"
        size="small"
        className="underline"
        onClick={() => handleSelectDans(row?.dans_code)}
      >
        {row?.dans_code}
      </Button>
    ),
  },
  {
    title: 'Эхний үлдэгдэл',
    dataIndex: 'eh_dun',
    align: 'right',
    width: 150,
    render: (_, row) => (
      <>
        {row?.is_foreign ? (
          <div>
            <p>{roundFloat(row?.eh_dun)}</p>
            <p className="bg-highlight">{roundFloat(row?.eh_dun_valiut)}</p>
          </div>
        ) : (
          roundFloat(row?.eh_dun)
        )}
      </>
    ),
  },
  {
    title: 'Ханшийн зөрүү',
    dataIndex: 'hanshiin_zuruu',
    align: 'right',
    width: 150,
    render: (_, row) => roundFloat(row?.zuruu || 0),
  },
  {
    title: 'Гүйлгээ ',
    align: 'center',
    children: [
      {
        title: 'Дебет',
        dataIndex: 'deb',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            {row?.is_foreign ? (
              <div>
                <p>{roundFloat(row?.deb)}</p>
                <p className="bg-highlight">{roundFloat(row?.deb_valiut)}</p>
              </div>
            ) : (
              roundFloat(row?.deb)
            )}
          </>
        ),
      },
      {
        title: 'Кредит',
        dataIndex: 'cred',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            {row?.is_foreign ? (
              <div>
                <p>{roundFloat(row?.cred)}</p>
                <p className="bg-highlight">{roundFloat(row?.cred_valiut)}</p>
              </div>
            ) : (
              roundFloat(row?.cred)
            )}
          </>
        ),
      },
    ],
  },
  {
    title: 'Эцсийн үлдэгдэл',
    dataIndex: 'ets_dun',
    width: 150,
    align: 'right',
    render: (_, row) => (
      <>
        {row?.is_foreign ? (
          <div>
            <p>{roundFloat(row?.ets_dun)}</p>
            <p className="bg-highlight">{roundFloat(row?.ets_dun_valiut)}</p>
          </div>
        ) : (
          roundFloat(row?.ets_dun)
        )}
      </>
    ),
  },
];

export const hariltsagch_columns = ({ handleOpenModal = () => {} }) => [
  {
    title: 'Харилцагч',
    align: 'center',
    children: [
      {
        title: 'Код',
        dataIndex: 'hariltsagch_code',
        align: 'left',
        width: 150,
        fixed: 'left',
        render: (_, row) => (
          <Button
            size="small"
            type="link"
            className="underline"
            onClick={() => {
              handleOpenModal(row?.hariltsagch_code);
            }}
          >
            {row?.hariltsagch_code}
          </Button>
        ),
      },
      {
        title: 'Нэр',
        dataIndex: 'hariltsagch_ner',
        align: 'left',
        width: 250,
        ellipsis: true,
        fixed: 'left',
      },
    ],
  },
  {
    title: 'Эхний үлдэгдэл',
    dataIndex: 'eh_dun',
    align: 'right',
    width: 150,
    render: (_, row) => (
      <>
        {row?.is_foreign ? (
          <div>
            <p>{roundFloat(row?.eh_dun)}</p>
            <p className="bg-highlight">{roundFloat(row?.eh_dun_valiut)}</p>
          </div>
        ) : (
          roundFloat(row?.eh_dun)
        )}
      </>
    ),
  },
  {
    title: 'Ханшийн зөрүү',
    dataIndex: 'hanshiin_zuruu',
    align: 'right',
    width: 150,
    render: (_, row) => roundFloat(row?.zuruu || 0),
  },
  {
    title: 'Гүйлгээ ',
    align: 'center',
    children: [
      {
        title: 'Дебет',
        dataIndex: 'deb',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            {row?.is_foreign ? (
              <div>
                <p>{roundFloat(row?.deb)}</p>
                <p className="bg-highlight">{roundFloat(row?.deb_valiut)}</p>
              </div>
            ) : (
              roundFloat(row?.deb)
            )}
          </>
        ),
      },
      {
        title: 'Кредит',
        dataIndex: 'cred',
        align: 'right',
        width: 150,
        render: (_, row) => (
          <>
            {row?.is_foreign ? (
              <div>
                <p>{roundFloat(row?.cred)}</p>
                <p className="bg-highlight">{roundFloat(row?.cred_valiut)}</p>
              </div>
            ) : (
              roundFloat(row?.cred)
            )}
          </>
        ),
      },
    ],
  },
  {
    title: 'Эцсийн үлдэгдэл',
    dataIndex: 'ets_dun',
    width: 150,
    align: 'right',
    render: (_, row) => (
      <>
        {row?.is_foreign ? (
          <div>
            <p>{roundFloat(row?.ets_dun)}</p>
            <p className="bg-highlight">{roundFloat(row?.ets_dun_valiut)}</p>
          </div>
        ) : (
          roundFloat(row?.ets_dun)
        )}
      </>
    ),
  },
];
