import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Input,
  InputNumber,
  Table,
  Form,
  Tooltip,
  DatePicker,
  Collapse,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { roundFloat } from 'src/core/utils/formater';
import { customConfirm } from 'src/components/common/confirmModal';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import moment from 'moment';
import { enable_state, disable_state } from 'src/core/actions/route_state';

const { Panel } = Collapse;
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useRef(null);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async () => {
    try {
      const values = await form.current.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form ref={form} component={false} onFinish={save}>
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          initialValue={
            dataIndex === 'eh_ognoo' || dataIndex === 'ets_ognoo'
              ? record[dataIndex]
                ? moment(record[dataIndex], 'YYYY/MM/DD')
                : null
              : record[dataIndex]
          }
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
            {
              validator: (_, value) => {
                if (
                  !value ||
                  (typeof value === 'string' && value.trim() === '')
                ) {
                  return Promise.reject(
                    new Error(`${title} must not be empty.`)
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {dataIndex === 'eh_ognoo' || dataIndex === 'ets_ognoo' ? (
            <DatePicker
              ref={inputRef}
              format="YYYY/MM/DD"
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
            />
          ) : dataIndex === 'duusgalt_huvi' ? (
            <InputNumber
              className="wide-input-number" // Apply the custom class
              ref={inputRef}
              min={0}
              max={100}
              step={0.01}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
              formatter={(value) => `${value}`}
              parser={(value) => value.replace('%', '')}
              precision={2}
            />
          ) : (
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children || ' '}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const AddRowTable = () => {
  const dispatch = useDispatch();
  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const get_data = (month) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        setDataSource(content?.table_data);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'get_sa11',
        month,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date?.month) get_data(data_date?.month);
  }, [data_date]);

  useEffect(() => {
    const allFieldsFilled = dataSource.every(
      (row) =>
        row.ner &&
        row.eh_ognoo &&
        row.duusgalt_huvi !== '' &&
        row.niit_urtug !== '' &&
        row.ets_ognoo
    );
    // if (allFieldsFilled) {
    //   dispatch(disable_state({}));
    // }
    setIsSubmitDisabled(!allFieldsFilled);
  }, [dataSource]);

  const handleAddRow = () => {
    const newData = {
      key: (dataSource.length + 1).toString(),
      ner: '',
      eh_ognoo: '',
      duusgalt_huvi: '',
      niit_urtug: '',
      ets_ognoo: '',
      editable: true,
    };
    dispatch(enable_state({}));
    setDataSource([...dataSource, newData]);
  };

  const handleDelete = (key) => {
    setDataSource((prevDataSource) =>
      prevDataSource.filter((item) => item.key !== key)
    );
  };

  const handleSave = (row) => {
    setDataSource((prevDataSource) =>
      prevDataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    );
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleConfirm = () => {
    const formattedData = dataSource.map((item) => ({
      ...item,
      eh_ognoo: item.eh_ognoo ? moment(item.eh_ognoo).format('YYYY/MM/DD') : '',
      ets_ognoo: item.ets_ognoo
        ? moment(item.ets_ognoo).format('YYYY/MM/DD')
        : '',
    }));

    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: get_data(data_date.month) },
      });
      if (status === '0') {
        dispatch(disable_state({}));
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'save_sa11',
        month: data_date?.month,
        table_data: formattedData,
      },
    });
    dispatch(start_loading({}));
  };

  const columns = [
    {
      title: 'Дуусаагүй барилгын нэр',
      dataIndex: 'ner',
      key: 'ner',
      editable: true,
    },
    {
      title: 'Эхэлсэн он',
      dataIndex: 'eh_ognoo',
      key: 'eh_ognoo',
      editable: true,
      width: 150,
      render: (_, row) =>
        row.eh_ognoo && moment(row.eh_ognoo).format('YYYY/MM/DD'),
    },
    {
      title: 'Дуусгалтын хувь',
      dataIndex: 'duusgalt_huvi',
      key: 'duusgalt_huvi',
      editable: true,
      width: 150,
      render: (_, row) => `${row.duusgalt_huvi}`,
    },
    {
      title: 'Нийт төсөвт өртөг',
      dataIndex: 'niit_urtug',
      key: 'niit_urtug',
      editable: true,
      width: 150,
      render: (_, row) => roundFloat(row?.niit_urtug),
    },
    {
      title: 'Ашиглалтанд орох эцсийн хугацаа',
      dataIndex: 'ets_ognoo',
      key: 'ets_ognoo',
      editable: true,
      width: 150,
      render: (_, row) =>
        row.ets_ognoo && moment(row.ets_ognoo).format('YYYY/MM/DD'),
    },
    {
      title: 'Устгах',
      key: 'action',
      render: (_, record) =>
        record.editable !== false && (
          <Tooltip title="Устгах">
            <DeleteOutlined
              onClick={() =>
                customConfirm({
                  onOk: () => handleDelete(record.key),
                })
              }
            />
          </Tooltip>
        ),
    },
  ];

  const mergedColumns = columns.map((col) => ({
    ...col,
    onCell: (record) => ({
      record,
      editable: col.editable && record.editable !== false,
      dataIndex: col.dataIndex,
      title: col.title,
      handleSave,
    }),
  }));

  const totalSum = dataSource.reduce(
    (acc, item) => acc + (parseFloat(item.niit_urtug) || 0),
    0
  );

  return (
    <Collapse defaultActiveKey={['1']} expandIconPosition="end">
      <Panel header="11. Дуусаагүй барилга" key="1">
        <div className="table-add-row">
          <div className="table-container">
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              dataSource={dataSource}
              columns={mergedColumns}
              pagination={pagination}
              onChange={handleTableChange}
              rowKey="key"
              summary={() => (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Нийт</Table.Summary.Cell>
                  <Table.Summary.Cell index={1} />
                  <Table.Summary.Cell index={2} />
                  <Table.Summary.Cell index={3}>
                    {roundFloat(totalSum)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} />
                </Table.Summary.Row>
              )}
              footer={() => (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    type="primary"
                    className="add-btn"
                    onClick={handleAddRow}
                  >
                    <PlusCircleOutlined />
                    Мөр нэмэх
                  </Button>
                  <Tooltip
                    title={
                      isSubmitDisabled
                        ? 'Нэмсэн мөрүүдийн дүн дээрх мөртэй таарах ёстой'
                        : ''
                    }
                  >
                    <Button
                      torol="primary"
                      className="confirm-btn"
                      onClick={handleConfirm}
                      disabled={isSubmitDisabled}
                      style={{ marginLeft: 8 }}
                    >
                      Хадгалах
                    </Button>
                  </Tooltip>
                </div>
              )}
            />
          </div>
        </div>
      </Panel>
    </Collapse>
  );
};

export default AddRowTable;
