import React from 'react';

import TableTabs from 'src/components/common/tableTabs';
import TabDans from './tabs/tabDans';
import TabHariltsagch from './tabs/tabHariltsagch';

const Client = () => {
  const list = () => [
    {
      key: '1',
      title: 'Данс',
      content: <TabDans />,
    },
    {
      key: '2',
      title: 'Харилцагч',
      content: <TabHariltsagch />,
    },
  ];
  return <TableTabs listTabs={list()} noTabs />;
};
export default Client;
