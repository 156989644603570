import React from 'react';
import {
  // EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

export const customColumns = ({
  // customEdit = () => {},
  customDelete = () => {},
  // getDansInfo = () => {},
}) => [
  {
    title: 'Түр зөрүүгийн төрөл',
    dataIndex: 'ner',
    align: 'left',
    titleAlign: 'center',
    width: 300,
    ellipsis: true,
  },
  // {
  //   title: 'Түр зөрүүгийн нөлөөлөл бүртгэх данс',
  //   dataIndex: 'dans_aguulga',
  //   align: 'left',
  //   titleAlign: 'center',
  //   width: 300,
  //   ellipsis: true,
  // },
  // {
  //   title: 'Холбосон данс',
  //   dataIndex: 'dans_id',
  //   align: 'left',
  //   titleAlign: 'center',
  //   width: 150,
  //   render: (_, row) => ({
  //     children: getDansInfo(row?.dans_id),
  //   }),
  // },
  // {
  //   title: 'Данс холбох',
  //   align: 'center',
  //   titleAlign: 'center',
  //   width: 100,
  //   render: (_, row) => (
  //     <div>
  //       <EditOutlined onClick={() => customEdit(row)} />
  //     </div>
  //   ),
  // },
  {
    title: 'Устгах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    render: (_, row) => {
      if (row?.turul !== 'system') {
        return (
          <div>
            <DeleteOutlined onClick={() => customDelete(row)} />
          </div>
        );
      }
      return '';
    },
  },
];
