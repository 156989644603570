import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography,
  Modal,
  Form,
  Row,
  Col,
  InputNumber,
  Button,
  Divider,
} from 'antd';
import { roundFloat, onlyNumber } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from '../../../core/actions/loader';

const { Text } = Typography;

const JournalDialogue = ({
  openDialog,
  handleDialogClose = () => {},
  dansData,
}) => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const [form] = Form.useForm();

  const [dunZuruu, setDunZuruu] = useState(0.0);
  const [IsTatvar, setIsTatvar] = useState(false);
  const [TurZuruuId, setTurZuruuId] = useState('');

  useEffect(() => {
    if (dansData) {
      form.setFieldsValue({
        tur_zuruu_id: dansData?.id,
        burtgel_dugaar: dansData?.burtgel_dugaar,
        hurungu_ner: dansData?.ner,
        hurungu_code: dansData?.code,
        zuruu: dansData?.zuruu,
        dans_une: dansData?.dans_une,
        tatvar_suuri: dansData?.tatvar_suuri,
        hasagdah_zuruu: dansData?.zuvshuurugduhgui_turuu_zur_hasagdah,
        tatvar_zuruu: dansData?.zuvshuurugduhgui_turuu_zur_tatvar_nogdoh,
      });
      setDunZuruu(Math.abs(Number(dansData.zuruu)));
      setTurZuruuId(dansData.id);
      if (Number(dansData.zuruu) > 0) {
        setIsTatvar(true);
      } else {
        setIsTatvar(false);
      }
    }
  }, [openDialog]);

  const layout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 16 },
    },
  };

  const onCancel = () => {
    form.resetFields();
    handleDialogClose();
  };

  const sendRequest = (values) => {
    if (TurZuruuId !== '') {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');
        if (data.status === '0') {
          newAlert({ type: 'success', msg: data.message });
        } else {
          newAlert({ type: 'error', msg: data.message });
        }
        onCancel();
        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts003_modify',
        content: {
          action_type: 'tur_zuruu_zuvshuurugduhgui',
          tur_zuruu_id: TurZuruuId,
          hasagdah_zuruu: values.hasagdah_zuruu,
          tatvar_zuruu: values.tatvar_zuruu,
          zuruu: dunZuruu,
          IsTatvar,
        },
      });
      dispatch(start_loading({}));
    } else {
      newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
    }
  };

  return (
    <Modal
      className="custom-modal"
      title="Түр зөрүүгээр хүлээн зөвшөөрөхгүй зөрүү бүртгэх"
      open={openDialog}
      closable={false}
      maskClosable={false}
      width={800}
      footer={null}
      onCancel={handleDialogClose}
    >
      <div className="dans-medeelel-container">
        <Form
          form={form}
          onFinish={sendRequest}
          className="register-form"
          {...layout}
          size="small"
        >
          <Form.Item name="tur_zuruu_id" hidden />
          <Form.Item
            name="hurungu_code"
            label="Хөрөнгийн код"
            labelAlign="left"
          >
            <Text ellipsis>{form.getFieldValue('hurungu_code')}</Text>
          </Form.Item>
          <Form.Item name="hurungu_ner" label="Хөрөнгийн нэр" labelAlign="left">
            <Text ellipsis>{form.getFieldValue('hurungu_ner')}</Text>
          </Form.Item>
          <Form.Item
            name="burtgel_dugaar"
            label="Бүртгэлийн дугаар"
            labelAlign="left"
          >
            <Text ellipsis>{form.getFieldValue('burtgel_dugaar')}</Text>
          </Form.Item>
          <Divider />
          <Form.Item name="dans_une" label="Дансны үнэ" labelAlign="left">
            <Text Align="right">
              {roundFloat(form.getFieldValue('dans_une'))}
            </Text>
          </Form.Item>
          <Form.Item
            name="tatvar_suuri"
            label="Татварын суурь"
            labelAlign="left"
          >
            <Text Align="right">
              {roundFloat(form.getFieldValue('tatvar_suuri'))}
            </Text>
          </Form.Item>
          <Form.Item name="zuruu" label="Зөрүү" labelAlign="left">
            <Text Align="right">{roundFloat(form.getFieldValue('zuruu'))}</Text>
          </Form.Item>
          <Divider />
          <Form.Item
            name="hasagdah_zuruu"
            label="Хасагдах түр зөрүү"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <InputNumber
              className="w-100 text-align-r"
              defaultValue="0.00"
              formatter={onlyNumber}
              disabled={IsTatvar}
            />
          </Form.Item>
          <Form.Item
            name="tatvar_zuruu"
            label="Татвар ногдох түр зөрүү"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <InputNumber
              className="w-100 text-align-r"
              defaultValue="0.00"
              formatter={onlyNumber}
              disabled={!IsTatvar}
            />
          </Form.Item>
          <Row>
            <Col span={24} className="text-align-r">
              <Form.Item noStyle>
                <Button
                  onClick={onCancel}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </Button>
                <Button
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default JournalDialogue;
