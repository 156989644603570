import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import axios from 'src/core/utils/axios';
import { onlyNumber, roundFloat } from 'src/core/utils/formater';
import { Table } from 'src/components';
import { Button, Row, Col, Input, Form, Modal } from 'antd';
import { PrinterOutlined, ExportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { customColumns } from './dataStructure/data';

const FileDownload = require('js-file-download');

const tText = 'T7_2_TailanZuruu';
const tTitle = `${tText}.tableText.title`;

const MainPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const componentRef = useRef(null);
  const [form] = Form.useForm();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const [tableData, setTableData] = useState([]);
  const [modalType, setModalType] = useState(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Зөрүүгийн тайлан',
  });

  const titleTextRender = (item, key) => {
    const { title, ..._item } = item;
    return {
      title: t(`${tTitle}.${key}`, item?.title),
      ..._item,
    };
  };

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        if (t) {
          const arr = data.content.json_array.map((i, key) => {
            if (i?.title) {
              return titleTextRender(i, key);
            }
            return i;
          });
          setTableData(arr);
        }
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: {
        action_type: 'get_zuruu_tailan',
        month_start: '1',
        month_end: String(month),
      },
    });

    dispatch(start_loading({}));
  };

  /**
   * Export tailan to xlsx format
   */
  const exportTailan = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        const file_export_address_link = `/get_file?file_id=${data.content.file_id}`;
        axios.get('export', file_export_address_link, {}, {}, (event) => {
          FileDownload(event, 'Зөрүүг зохицуулах тайлан.xlsx');
        });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: {
        action_type: 'export_zuruu_tailan',
        month_start: '1',
        month_end: String(data_date.month),
      },
    });

    dispatch(start_loading({}));
  };

  const saveValues = (saveData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan(data_date.month);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: {
        action_type: 'set_zuruu_tailan_dun',
        month: data_date.month,
        dun_type: saveData?.dun_type,
        dun: saveData?.dun,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
  }, [data_date, t]);

  const editAction = (row) => {
    form.setFieldsValue({
      dun_type: row?.dun_type,
      dun: roundFloat(row?.dun),
    });
    setModalType(row?.dun_type);
  };

  const title = () => (
    <>
      <div className="table-title-text">
        {t(
          `${tText}.tableTitle`,
          'Санхүүгийн болон татварын тайлангийн үзүүлэлт хоорондын зөрүүг зохицуулах тайлан'
          // 'САНХҮҮГИЙН БОЛОН ТАТВАРЫН ТАЙЛАНГИЙН ҮЗҮҮЛЭЛТ ХООРОНДЫН ЗӨРҮҮГ ЗОХИЦУУЛАХ ТАЙЛАН'
        )}
      </div>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<PrinterOutlined />}
        onClick={() => {
          handlePrint();
        }}
      >
        {t(`${tText}.printBtn`, 'Хэвлэх')}
      </Button>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<ExportOutlined />}
        onClick={() => {
          exportTailan();
        }}
      >
        {t(`${tText}.exportBtn`, 'Экспорт')}
      </Button>
    </>
  );

  return (
    <>
      <div className="table-antd-div tailan-container-825" ref={componentRef}>
        <Table
          columns={customColumns({ t, editAction })}
          dataSource={tableData}
          isTitle
          title={title}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Дүн шинэчлэх"
        open={modalType !== null}
        closable={false}
        maskClosable={false}
        width={450}
        footer={null}
      >
        <CustomModal
          onClose={() => {
            setModalType(null);
          }}
          form={form}
          saveValues={saveValues}
        />
      </Modal>
    </>
  );
};

const layout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 18 },
    sm: { span: 18 },
  },
};

const CustomModal = ({
  onClose = () => {},
  form = null,
  saveValues = () => {},
}) => {
  const onFinish = (values) => {
    saveValues(values);
    onClose();
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="dun_type" hidden />
        <Form.Item
          name="dun"
          label="Дүн"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" />
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MainPage;
