/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  Upload,
  Select,
  message,
  Input,
  Divider,
  Card,
  Checkbox,
} from 'antd';
import { WarningOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { Table } from 'src/components';
import { ColumnsMain, ColumnsMainShort, customSummary, customSummaryShort } from './dataStructure/data';
import { searchList } from './dataStructure/search';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { AnglalModal } from './anglalModal';
import { districts } from './utils/data';

const Borluulalt = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { socket, data_date } = useSelector((state) => state);

  const [visible, setVisible] = useState(false);
  const [fileVisible, setFileVisible] = useState(false);
  const [resultVisible, setResultVisible] = useState(false);
  const [resultData, setResultData] = useState({});
  const [visibleEdit, seTvisibleEdit] = useState({
    visible: false,
    rowData: null,
  });
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });
  const [selectedRowKeys, seTselectedRowKeys] = useState([]);
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const [showPrevMonth, setShowPrevMonth] = useState(false);

  const [fileForm] = Form.useForm();

  const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const checkAll = tableData.result_list.length === selectedRowKeys.length;
  // eslint-disable-next-line operator-linebreak
  const indeterminate =
    // eslint-disable-next-line operator-linebreak
    tableData.result_list.length > 0 &&
    // eslint-disable-next-line operator-linebreak
    selectedRowKeys.length < tableData.result_list.length &&
    selectedRowKeys.length !== 0;

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content);
        seTselectedRowKeys([]);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const month = data_date?.month;
    const year = data_date?.year;
    const search = {
      hariltsagch: query.get('hariltsagch'),
      tohiruulga: query.get('tohiruulga'),
      zuruu: query.get('zuruu'),
      noat: query.get('noat'),
    };
    socket.emit('request_in', {
      channel: 'ss013_03_view',
      content: {
        action_type: 'borluulalt_page',
        month,
        page,
        size,
        search,
        year,
      },
    });
    dispatch(start_loading({}));
  };

  const saveSelected = (type) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгаллаа' });
        select_data();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ss013_03_modify',
      content: {
        action_type: 'borluulalt_angilal',
        main_type: type,
        selected_list: selectedRowKeys,
        year: data_date.year,
      },
    });
    dispatch(start_loading({}));
  };

  const handleUpload = async (input_data) => {
    if (input_data) {
      const file = input_data.file[0].originFileObj;
      if (file) {
        const reader = new FileReader();

        reader.onload = () => {
          const base64Data = reader.result.split(',')[1];
          socket.on('request_out', (data) => {
            socket.removeAllListeners('request_out');
            if (data.status === '0') {
              setResultData(data?.content?.result_counter);
              setResultVisible(true);
              select_data();
            } else {
              newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
              dispatch(end_loading({}));
            }
          });
          socket.emit('request_in', {
            channel: 'ss014_noat_control',
            content: {
              action_type: 'reg_to_ebarimt',
              year: data_date?.year,
              month: data_date?.month,
              token_file: base64Data,
              vat_list: selectedRowKeys,
              district: input_data?.district,
              branch_no: input_data?.branch_no,
              code: input_data?.code,
              name: input_data?.name,
              prev_enabled: input_data?.nuhuj_ilgeeh,
              prev_year: input_data?.year_prev,
              prev_month: input_data?.month_prev,
            },
          });
          dispatch(start_loading({}));
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const selectData = (row) => {
    if (selectedRowKeys.indexOf(row.key) > -1) {
      // Key exists in the row, remove it from selectedRowKeys
      seTselectedRowKeys(
        selectedRowKeys.filter((selectedKey) => selectedKey !== row.key)
      );
    } else {
      // Key doesn't exist in the row, add it to selectedRowKeys
      seTselectedRowKeys([...selectedRowKeys, row.key]);
    }
  };

  const onCheckAllChange = () => {
    if (checkAll) {
      seTselectedRowKeys([]);
    } else {
      seTselectedRowKeys(tableData.result_list.map((item) => item.key));
    }
  };

  const onClickEdit = (row) => {
    seTvisibleEdit({
      visible: true,
      rowData: row,
    });
  };

  useEffect(() => {
    if (!visibleEdit.visible) {
      select_data();
    }
  }, [visibleEdit]);

  useEffect(() => {
    if (data_date) {
      if (isFirstTimeLoading) {
        setIsFirstTimeLoading(false);
      } else {
        select_data();
      }
    }
  }, [data_date, query]);

  const title = () => (
    <Row>
      {indeterminate || checkAll ? (
        <>
          <Button
            className="add-btn"
            type="primary"
            size="small"
            onClick={() => {
              setFileVisible(true);
            }}
          >
            НӨАТ шивэх
          </Button>
          <Button
            className="add-btn"
            type="primary"
            size="small"
            onClick={() => {
              setVisible({ visible: true, type: 'hoish' });
            }}
          >
            Хойшлуулах
          </Button>
          <Button
            className="add-btn"
            type="primary"
            size="small"
            onClick={() => {
              setVisible({ visible: true, type: 'iluu' });
            }}
          >
            {' '}
            Илүү шивсэн
          </Button>
          <Button
            className="add-btn"
            type="primary"
            size="small"
            onClick={() => {
              setVisible({ visible: true, type: 'clear' });
            }}
          >
            {' '}
            Буцаах
          </Button>
        </>
      ) : (
        <Button className="add-btn" type="primary" size="small" />
      )}
    </Row>
  );

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCancelEdit = () => {
    seTvisibleEdit({
      visible: false,
      rowData: null,
    });
  };

  return (
    <>
      <div className="table-antd-div no-scroll">
        {data_date?.month && (String(data_date?.month).split(',')).length === 1 ? (
          <Table
            isSearch
            searchList={searchList}
            title={title}
            columns={ColumnsMain({
              selectData,
              checkAll,
              indeterminate,
              onCheckAllChange,
              selectedRowKeys,
              onClickEdit,
            })}
            total={tableData.total_size}
            dataSource={tableData.result_list}
            summaryList={{
              sumColumn: customSummary,
              sumDataSource: tableData.total_list,
            }}
            scroll={{
              y: 'calc(100vh - 320px)',
            }}
          />
        ) : (
          <Table
            isSearch
            searchList={searchList}
            title={title}
            columns={ColumnsMainShort()}
            total={tableData.total_size}
            dataSource={tableData.result_list}
            summaryList={{
              sumColumn: customSummaryShort,
              sumDataSource: tableData.total_list,
            }}
            scroll={{
              y: 'calc(100vh - 320px)',
            }}
          />
        )}
        <Modal
          closable={false}
          open={visible.visible}
          icon={<WarningOutlined />}
          okText="Тийм"
          cancelText="Үгүй"
          onOk={() => {
            saveSelected(visible.type);
            setVisible(false);
          }}
          className="noat-modal"
          onCancel={handleCancel}
        >
          {visible.type === 'hoish' ? (
            <div>
              <h1>Хойшлуулах НӨАТ-ын ангилалд тохируулах уу?</h1>
            </div>
          ) : visible.type === 'iluu' ? (
            <div>
              <h1>Илүү шивсэн НӨАТ-ын ангилалд тохируулах уу?</h1>
            </div>
          ) : (
            <div>
              <h1>НӨАТ-ын ангиллыг буцаах уу?</h1>
            </div>
          )}
        </Modal>
        <AnglalModal
          closable={false}
          visibleEdit={visibleEdit}
          seTvisibleEdit={seTvisibleEdit}
          onCancel={handleCancelEdit}
          select={select_data}
        />
        <Modal
          className="custom-modal"
          title={`eBarimt системд НӨАТ шивэх : ${selectedRowKeys.length} баримт`}
          open={fileVisible}
          closable={false}
          maskClosable={false}
          width={700}
          footer={null}
        >
          <NoatModal
            onClose={() => {
              setFileVisible(false);
            }}
            form={fileForm}
            handleUpload={handleUpload}
            showPrevMonth={showPrevMonth}
            setShowPrevMonth={setShowPrevMonth}
            monthList={monthList}
          />
        </Modal>
        <Modal
          className="custom-modal"
          title="Үр дүн"
          open={resultVisible}
          closable={false}
          maskClosable={false}
          width={700}
          footer={null}
        >
          <ResultModal
            onClose={() => {
              setResultVisible(false);
            }}
            resultData={resultData}
          />
        </Modal>
      </div>
    </>
  );
};

const layout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 14 },
  },
};

const NoatModal = ({
  onClose = () => {},
  form = null,
  handleUpload = () => {},
  showPrevMonth = false,
  setShowPrevMonth = () => {},
  monthList = [],
}) => {
  const onFinish = (values) => {
    handleUpload(values);
    setShowPrevMonth(false);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    setShowPrevMonth(false);
    form.resetFields();
    onClose();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getYears = (n) => {
    const currentYear = new Date().getFullYear();
    const previousYears = [];
    for (let i = 0; i <= n; i += 1) {
      previousYears.push(currentYear - i);
    }
    return previousYears;
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item
          name="district"
          label="Байршил"
          labelAlign="left"
          rules={[{ required: true, message: '' }]}
        >
          <Select placeholder="Сонгох" style={{ width: '100%', fontSize: 13 }}>
            {districts.map((i) => (
              <Select.Option value={i.val}>{i.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="code"
          label="Бараа, үйлчилгээний код"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
            { min: 0, max: 100 },
          ]}
        >
          <Input className="w-100" />
        </Form.Item>
        <Form.Item
          name="name"
          label="Бараа, үйлчилгээний нэр"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
            { min: 0, max: 100 },
          ]}
        >
          <Input className="w-100" />
        </Form.Item>
        <Form.Item
          name="nuhuj_ilgeeh"
          label="Өмнөх сар нөхөж илгээх"
          labelAlign="left"
          valuePropName="checked"
        >
          <Checkbox
            onChange={(val) => {
              setShowPrevMonth(val.target.checked);
            }}
          />
        </Form.Item>
        <Form.Item
          name="year_prev"
          label="Он"
          labelAlign="left"
          rules={[{ required: showPrevMonth, message: '' }]}
          hidden={!showPrevMonth}
        >
          <Select placeholder="Сонгох" style={{ width: '100%', fontSize: 13 }}>
            {getYears(1).map((i) => (
              <Select.Option value={i}>{i}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="month_prev"
          label="Сар"
          labelAlign="left"
          rules={[{ required: showPrevMonth, message: '' }]}
          hidden={!showPrevMonth}
        >
          <Select placeholder="Сонгох" style={{ width: '100%', fontSize: 13 }}>
            {monthList.map((i) => (
              <Select.Option value={i}>{i}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="branch_no"
          label="PosAPI ашиглаж буй салбарын дугаар"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '3 оронтой дугаар оруулна уу',
            },
            { min: 3, max: 3, message: '3 оронтой дугаар оруулна уу' },
          ]}
        >
          <Input className="w-100" />
        </Form.Item>
        <Divider style={{ marginTop: 20 }} />
        <Form.Item
          name="file"
          label="PosAPI токен файл"
          labelAlign="left"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: 'Файл сонгоно уу' }]}
        >
          <Upload
            accept=".so"
            showUploadList
            maxCount={1}
            beforeUpload={(File) => {
              console.log(File);
              if (!File.name.endsWith('.so')) {
                message.error('Зөвхөн .so өргөтгөлтэй файл сонгоно уу');
                return Upload.LIST_IGNORE;
              }
              if (File.size > 150000) {
                message.error('Файлын хэмжээ том байна');
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
          >
            <CloudUploadOutlined className="fs-30" />
          </Upload>
        </Form.Item>
        <Divider style={{ marginTop: 50 }} />
        <Card
          style={{ margin: 20, backgroundColor: '#54B4D3', color: 'white' }}
        >
          <p>
            - Дээрх мэдээллийн дагуу сонгосон харилцагч бүрээр “Төлбөрийн
            баримт” үүсэх ба “ebarimt.mn” сайтад ПОС-р үүссэн төлбөрийн баримт
            цэсэнд харагдана.
          </p>
          <p>
            - Төлбөрийн баримт нь “Бизнесийн үйл ажиллагаанд” төрлөөр зөвхөн
            байгууллагууд руу үүснэ.
          </p>
          <p>
            - Амжилттай үүссэн Төлбөрийн баримтын ДДТД, шивсэн огноо, дүн зэрэг
            нь хүснэгтийн “TaxAcс Төлбөрийн баримтаар нөхөж шивсэн” баганад
            харагдана.
          </p>
          <p>
            - PosApi ашиглаж буй салбарын дугаар нь таны PosAPI хүсэлт гаргахад
            “Салбарын дугаар” нүдэнд оруулсан 3 оронтой код байна.
          </p>
        </Card>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Баримт гаргах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const ResultModal = ({ onClose = () => {}, resultData = {} }) => (
  <div className="dans-medeelel-container">
    <div className="register-form">
      {resultData?.success ? (
        <Card
          style={{ margin: 10, backgroundColor: '#54B4D3', color: 'white' }}
        >
          <p>- Амжилттай шивэгдсэн: {resultData?.success}</p>
        </Card>
      ) : (
        <></>
      )}
      {
        // eslint-disable-next-line operator-linebreak
        (resultData?.not_found_or_already_reg ||
          // eslint-disable-next-line operator-linebreak
          resultData?.vat_value_invalid ||
          // eslint-disable-next-line operator-linebreak
          resultData?.vat_reg_error ||
          resultData?.invalid_reg) && (
          <Card
            style={{ margin: 10, backgroundColor: '#ec942c', color: 'white' }}
          >
            {resultData?.not_found_or_already_reg > 0 && (
              <p>
                - Өмнө нь бүртгэлтэй байсан:{' '}
                {resultData?.not_found_or_already_reg}
              </p>
            )}
            {resultData?.vat_value_invalid > 0 && (
              <p>- Алдаатай дүн гарсан: {resultData?.vat_value_invalid}</p>
            )}
            {resultData?.vat_reg_error > 0 && (
              <p>
                - eBarimt-д бүртгэхэд алдаа гарсан: {resultData?.vat_reg_error}
              </p>
            )}
            {resultData?.invalid_reg > 0 && (
              <p>- Регистрийн дугаар алдаатай: {resultData?.invalid_reg}</p>
            )}
          </Card>
        )
      }
      <Row>
        <Col span={24} className="text-align-r">
          <Button
            onClick={onClose}
            className="cancel-btn"
            type="primary"
            shape="round"
          >
            Хаах
          </Button>
        </Col>
      </Row>
    </div>
  </div>
);

export default Borluulalt;
