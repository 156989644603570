import React, { useState, useEffect } from 'react';
import { useQuery } from 'src/core/utils/formater';
import { useHistory } from 'react-router-dom';
import { Tag } from 'antd';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const mergedList = [
  ['startDate', 'endDate'],
  ['min', 'max'],
  ['startInput', 'endInput'],
];

const temp = JSON.stringify(mergedList);

const SearchField = () => {
  const query = useQuery();
  const history = useHistory();
  const [tags, seTtags] = useState([]);
  const { search_field: searchList = [] } = useSelector((state) => ({
    search_field: state?.search_field,
  }));

  const handleClose = (t, value = null) => {
    if (t?.id || t?.id === 0) {
      const fields = t?.key.split(',');
      fields.forEach((f) => {
        query.delete(f);
      });
    }

    if (t?.key === 'checkList') {
      const arr = query.getAll('checkList').filter((i) => i !== value);
      query.delete('checkList');
      arr.forEach((j) => query.append('checkList', j));
    }

    if (t?.key !== 'checkList') {
      query.delete(t?.key);
    }

    history.push({ search: query.toString() });
  };

  useEffect(() => {
    let arr = [];

    const sliceList = JSON.parse(temp);
    query.forEach((value, key) => {
      let notDetected = true;
      sliceList.forEach((k, idx) => {
        if (k.includes(key)) {
          if (arr.some((q) => q?.id === idx)) {
            arr = arr.map((b) => {
              if (b?.id === idx) {
                notDetected = false;
                return {
                  ...b,
                  key: `${b?.key},${key}`,
                  value: `${b?.value} ~ ${value}`,
                };
              }
              return b;
            });
          } else {
            notDetected = false;
            arr.push({ id: idx, key, value });
          }
          const index = k.indexOf(key);
          if (index !== -1) {
            k.splice(index, 1);
          }
        }
      });
      if (notDetected) {
        arr.push({ key, value });
      }
    });

    seTtags(arr);
  }, [query]);

  const clearAll = () => {
    const allKeys = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item of query.entries()) {
      allKeys.push(item[0]);
    }
    if (allKeys.length > 0) {
      allKeys.forEach((m) => {
        if (m !== 'page' && m !== 'size') {
          query.delete(m);
        }
      });
    }

    history.push({ search: query.toString() });
  };
  const _checkList = searchList.find((j) => j?.type === 'checkList')?.children
    ?.list;

  const _select = searchList.find((j) => j?.type === 'select');
  const valueMaker = (val) => {
    if (val?.key === 'select') {
      const name = get(_select, 'children.list', []).find(
        (c) => c?.value === val?.value
      )?.name;
      const title = get(_select, 'title', '');
      return `${title}: ${name}`;
    }

    if (val?.key !== 'checkList') {
      return val?.value;
    }

    return _checkList.find((c) => c?.value === val?.value)?.name;
  };
  if (tags && tags.length > 0) {
    let current_length = tags.length;
    tags.forEach((t) => {
      if (t.key === 'page' || t.key === 'size' || t.key === 'tab' || t.key === 'Hurungu') {
        current_length -= 1;
      }
    });
    return (
      <div className="search-field">
        {tags.map(
          (t) =>
            !(t.key === 'page' || t.key === 'size' || t.key === 'tab' || t.key === 'Hurungu') && (
              <Tag
                closable
                onClose={(e) => {
                  e.preventDefault();
                  handleClose(t, t?.key === 'checkList' && t?.value);
                }}
                key={`${t?.key}${t?.value}`}
                color="cyan"
              >
                {valueMaker(t)}
              </Tag>
            )
        )}
        {current_length > 0 && (
          <span className="clear" onClick={clearAll}>
            Цэвэрлэх
          </span>
        )}
      </div>
    );
  }
  return null;
};
export default SearchField;
