import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { roundFloat } from 'src/core/utils/formater';
import TableTabs from 'src/components/common/tableTabs';
import newAlert from 'src/components/newAlert';

import Table from 'src/components/common/table';

import { Spin, Card, List, Popover, Button } from 'antd';

import { start_loading, end_loading } from 'src/core/actions/loader';
import {
  columns,
  columns_2,
  customSummary,
  customSummary_2,
} from './dataStructure/data';

const Client = () => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const { month } = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [tableData_2, setTableData_2] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [tableDataSum_2, setTableDataSum_2] = useState({});
  const [total, seTtotal] = useState([]);

  const [disabled, seTdisabled] = useState({
    state: false,
    dans_une: 0.0,
    huugiin_zardal: 0.0,
    zoruu: 0.0,
    total_hetersen_dun: 0.0,
  });
  const [loading, seTloading] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const CardData = [
    {
      title: 'ТШБ дансны дүн:',
      elementId: 'first',
      value: roundFloat(disabled.dans_une),
    },
    {
      elementId: 'second',
      title: 'Өгөгдлийн дүн:',
      value: roundFloat(disabled.huugiin_zardal),
    },
    {
      elementId: 'third',
      title: 'Зөрүү:',
      value: roundFloat(disabled.zoruu),
    },
  ];
  const WarningContent = (
    <div className="white">
      <WarningOutlined className="white" /> Гүйцэтгэлээрх томилолтын зардлын дүн
      Томилолтын зардалд <br />
      холбогдсон дүнгээс зөрүүтэй байна.
    </div>
  );
  const mainData = (month) => {
    seTloading(true);
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        const new_temp_data = content?.tomilolt_data.map((inner_data, key) => ({
          id: key + 1,
          ...inner_data,
        }));
        if (new_temp_data) {
          setTableData(new_temp_data);
        }
        seTtotal(content?.total);
        setTableDataSum(content.tomilolt_sum);
        setTableDataSum_2(content.dans_sum);
        const new_temp_data_2 = content?.dans_data.map((inner_data, key) => ({
          id: key + 1,
          ...inner_data,
        }));
        if (new_temp_data_2) {
          setTableData_2(new_temp_data_2);
        }
        // setTableDataSum_2(content.dans_sum);
        seTdisabled(content.link_state);
        dispatch(end_loading({}));
        seTloading(false);
      } else {
        dispatch(end_loading({}));
      }
      seTloading(false);
    });
    socket.emit('request_in', {
      channel: 'ts004_00_tomilolt_service',
      content: {
        month,
        action_type: 'calculate_tomilolt',
      },
    });

    dispatch(start_loading({}));
  };

  const handleSaveValue = (total_dans_dun, total_hetersen_dun) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
      });
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_tomilolt_service',
      content: {
        month,
        action_type: 'set_hetersen_dun_to_zardal_main',
        total_hetersen_dun,
        total_dans_dun,
      },
    });
  };

  const Table1 = () => (
    <div className="display-hyazgaar-dans">
      <div className="hyazgaar-card">
        <Card title="Зардлын дүн" className="custom-card">
          <List
            size="small"
            dataSource={CardData}
            className="custom-list"
            renderItem={(item) => (
              <List.Item>
                <span id={item?.elementId}>{item.title}</span>
                <span>{item.value}</span>
              </List.Item>
            )}
          />
          <div className="link-btn">
            {disabled.state ? (
              <Popover overlayClassName="Warning" content={WarningContent}>
                <Button
                  className="add-btn-hyaz"
                  shape="round"
                  type="primary"
                  size="small"
                  disabled={disabled.state}
                >
                  Нийт цонх руу холбох
                </Button>
              </Popover>
            ) : (
              <Button
                type="primary"
                size="small"
                shape="round"
                className="add-btn-hyaz"
                onClick={() => {
                  handleSaveValue(
                    disabled.dans_une,
                    disabled.total_hetersen_dun
                  );
                }}
              >
                Нийт цонх руу холбох
              </Button>
            )}
          </div>
        </Card>
      </div>
      <div className="hyazgaar-dans-table ww-60 mt-10">
        <Table
          columns={columns_2}
          dataSource={tableData_2}
          scroll={{
            x: 'auto',
          }}
          summaryList={{
            sumColumn: customSummary_2,
            sumDataSource: tableDataSum_2,
          }}
        />
      </div>
    </div>
  );

  const Table2 = () => (
    <div>
      <div className="table-antd-div">
        <Table
          columns={columns}
          dataSource={tableData}
          scroll={{
            x: 'auto',
          }}
          total={total}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableDataSum,
          }}
        />
      </div>
    </div>
  );

  const list = [
    {
      key: '1',
      title: <span>Зардлын дүн</span>,
      content: <Table1 />,
    },
    {
      key: '2',
      title: <span id="fourth">Томилолтын зардлын хэтрэлт</span>,
      content: <Table2 />,
    },
  ];

  useEffect(() => {
    mainData(month);
  }, [month]);

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin indicator={antIcon} />;
      </div>
    );
  }

  return (
    <div>
      <TableTabs listTabs={list} />
    </div>
  );
};
export default Client;
