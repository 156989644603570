import React, { useState, useEffect } from 'react';
import { onlyNumber } from 'src/core/utils/formater';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button as AntButton,
  Form,
  Input,
  Row,
  Col,
  Select as AntSelect,
  Switch,
  Space,
} from 'antd';
import moment from 'moment';
import CustomDatePicker from 'src/components/common/datePicker';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';

const { Option } = AntSelect;
const { TextArea } = Input;
export const RegZalruulga = ({
  handleSaveZalruulga = () => {},
  onCancel = () => {},
  socket,
  dispatch,
}) => {
  const [form] = Form.useForm();
  const [curData, seTcurData] = useState({});
  const [checkState, seTcheckState] = useState(true);

  const changeState = (checked) => {
    console.log(`switch to ${checked}`);
    seTcheckState(checked);
  };
  const { year_end, is_first_year } = useSelector((state) => state.version);
  const [prevYear, seTprevYear] = useState(
    parseInt(moment(year_end).format('YYYY')) - 1
  );
  console.log('🚀 ~ prevYear:', prevYear);
  const onFinish = async (data) => {
    const { file, ..._values } = data;
    const confirmData = {
      state: checkState,
      ..._values,
      tailbar: data?.tailbar || null,
      ognoo: moment(_values.ognoo).format('yyyy'),
    };
    handleSaveZalruulga(confirmData, form);
  };

  const mainData = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        seTcurData(content);
      } else {
        newAlert({ type: 'error', msg: content.Message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_02_manage_aldagdal_data',
      content: { action_type: 'get_zalruulga_data' },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    mainData();
  }, []);

  return (
    <>
      <Form
        form={form}
        name="basic"
        style={{
          fontSize: 13,
        }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onFinish}
      >
        <Switch
          style={{
            marginBottom: 13,
          }}
          checkedChildren="Өмнөх жил дээр залруулга бүртгэх"
          unCheckedChildren="Өмнөх жилд алдагдал бүртгэх"
          defaultChecked
          onChange={changeState}
        />

        {(() => {
          if (checkState) {
            return (
              <>
                <Form.Item
                  label="Татварын жил"
                  name="ognoo"
                  labelAlign="right"
                  rules={[
                    {
                      required: true,
                      message: 'огноо сонгоно уу',
                    },
                  ]}
                >
                  <AntSelect
                    className="ww-50"
                    placeholder="Сонгох"
                    onChange={() => {
                      form.setFieldsValue({ aldagdal_id: null });
                    }}
                  >
                    {curData?.ognoo_list?.map((i) => (
                      <Option value={i}>{i}</Option>
                    ))}
                  </AntSelect>
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const name = getFieldValue('ognoo');
                    if (name) {
                      return (
                        <Form.Item
                          name="aldagdal_id"
                          label="Актын дугаар/он"
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                        >
                          <AntSelect className="ww-50" placeholder="Сонгох">
                            {curData?.zalruulga_data[name]?.map((i) => (
                              <Option value={i?.aldagdal_id}>{i?.name}</Option>
                            ))}
                          </AntSelect>
                        </Form.Item>
                      );
                    }
                  }}
                </Form.Item>
              </>
            );
          }

          return is_first_year ? (
            <Form.Item
              label="Өмнөх жил"
              rules={[
                {
                  required: true,
                  message: 'огноо сонгоно уу',
                },
              ]}
              name="ognoo"
              labelAlign="right"
            >
              <Input bordered defaultValue="" disabled />
            </Form.Item>
          ) : (
            <>
              <Form.Item label="Өмнөх жил" name="ognoo" labelAlign="right">
                <Input bordered defaultValue={prevYear} disabled />
              </Form.Item>
              <Form.Item
                label="Татварын алба:"
                name="tatvar_alba"
                rules={[{ required: true, message: 'Заавал бөглөх' }]}
              >
                <Input bordered />
              </Form.Item>
              <Form.Item
                label="Актын огноо:"
                name="akt_ognoo"
                labelAlign="right"
                rules={[
                  {
                    required: true,
                    message: 'огноо оруулна уу',
                  },
                ]}
              >
                <CustomDatePicker customClass="ww-60" />
              </Form.Item>
              <Form.Item
                label="Актын №:"
                name="akt_number"
                rules={[{ required: true, message: 'Заавал бөглөх' }]}
              >
                <Input bordered />
              </Form.Item>
            </>
          );
        })()}

        <Form.Item
          label="Залруулгын дүн"
          name="aldagdal_dun"
          labelAlign="right"
          rules={[
            {
              required: true,
              message: 'Алдагдлын дүн оруулна уу',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input bordered />
        </Form.Item>
        <Form.Item label="Тайлбар:" name="tailbar" labelAlign="right">
          <TextArea bordered rows={4} maxLength={300} showCount />
        </Form.Item>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item noStyle>
              <AntButton
                onClick={() => {
                  form.resetFields();
                  onCancel();
                }}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Буцах
              </AntButton>
              <AntButton
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </AntButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
