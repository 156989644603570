const oDefaultState = {
  jwt_1: null,
};

const reducer = (state = oDefaultState, action) => {
  switch (action.type) {
    case 'set_jwt':
      return {
        jwt_1: action.payload?.jwt_1.toString(),
      };
    case 'remove_jwt':
      return oDefaultState;
    default:
      return state;
  }
};

export default reducer;
