import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Input,
  InputNumber,
  Table,
  Form,
  Tooltip,
  Collapse,
} from 'antd';
import {
  DownOutlined,
  UpOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { roundFloat } from 'src/core/utils/formater';
import { customConfirm } from 'src/components/common/confirmModal';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from 'src/core/actions/loader';
import { enable_state, disable_state } from 'src/core/actions/route_state';

const { Panel } = Collapse;

const EditableCell1 = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm(); // Use Form.useForm() for better handling
  const inputRef = useRef(null);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus(); // Ensure input is focused
    }
  }, [editing]);

  const toggleEdit = () => {
    if (editable) {
      setEditing(!editing);
      console.log('Editing toggled for:', dataIndex);
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields(); // Use form.validateFields()
      setEditing(false);
      handleSave({ ...record, [dataIndex]: values[dataIndex] }); // Call with updated values
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form
        form={form}
        component={false}
        onFinish={save}
        initialValues={{ [dataIndex]: record[dataIndex] }} // Set initial values
      >
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {dataIndex === 'turul' ? (
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
            />
          ) : (
            <InputNumber
              className="wide-input-number" // Apply the custom class
              ref={inputRef}
              min={0}
              step={0.01}
              onPressEnter={save}
              onBlur={save}
              style={{ width: '100%' }}
              formatter={(value) => `${value}`}
              parser={(value) => value}
              precision={2}
            />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children || ' '}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
const EditableCell2 = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave2,
  ...restProps
}) => {
  const [editing2, setEditing2] = useState(false);
  const inputRef = useRef(null);
  const form = useRef(null);

  useEffect(() => {
    if (editing2) {
      inputRef.current.focus();
    }
  }, [editing2]);

  const toggleEdit2 = () => {
    setEditing2(!editing2);
  };

  const save2 = async () => {
    try {
      const values = await form.current.validateFields();
      toggleEdit2();
      handleSave2({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    const isChildField = Array.isArray(dataIndex);

    childNode = editing2 ? (
      <Form ref={form} component={false} onFinish={save2}>
        <Form.Item
          style={{ margin: 0 }}
          name={isChildField ? dataIndex.join('.') : dataIndex}
          initialValue={record[dataIndex]}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {dataIndex === 'turul' ? (
            <Input ref={inputRef} onPressEnter={save2} onBlur={save2} />
          ) : (
            <InputNumber
              ref={inputRef}
              min={0}
              className="wide-input-number" // Apply the custom class
              onPressEnter={save2}
              onBlur={save2}
              style={{ width: '100%' }}
              formatter={(value) => `${value}`}
              parser={(value) => value.replace('%', '')}
              precision={2}
            />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit2}
      >
        {children || ' '}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const AddRowTable = () => {
  const dispatch = useDispatch();
  const { socket, data_date } = useSelector((state) => ({
    socket: state.socket,
    data_date: state.data_date,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [dataSource2, setDataSource2] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });
  const [initialSummary, seTinitialSummary] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const get_data = (month) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        setDataSource(content?.table_data);
        setDataSource2(content?.table_data2);
        seTinitialSummary(content?.initial_summary);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'get_sa13',
        month,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date?.month) get_data(data_date?.month);
  }, [data_date]);

  useEffect(() => {
    const allFieldsFilled = dataSource.every(
      (row) =>
        row.turul !== '' &&
        row.eh_huvi !== '' &&
        row.eh_dun !== '' &&
        row.ets_huvi !== '' &&
        row.ets_dun !== ''
    );
    if (allFieldsFilled) {
      dispatch(disable_state({}));
    }

    setIsSubmitDisabled(!allFieldsFilled);
  }, [dataSource]);
  const [newRows, setNewRows] = useState([]); // Track newly added rows

  const handleAddRow = () => {
    const newData = {
      key: (dataSource.length + 1).toString(),
      turul: '', // Initialize correctly
      eh_huvi: 0,
      eh_dun: 0,
      ets_huvi: 0, // Ensure numeric initial values
      ets_dun: 0, // Ensure numeric initial values
      editable: {
        turul: true,
        eh_huvi: true,
        eh_dun: true,
        ets_dun: true,
        ets_huvi: true,
      },
    };
    setDataSource([...dataSource, newData]);
    setNewRows([...newRows, newData]); // Keep track of the newly added row
    dispatch(enable_state({}));
  };

  const handleDelete = (key) => {
    setDataSource((prevDataSource) =>
      prevDataSource.filter((item) => item.key !== key)
    );
    setNewRows((prevNewRows) => prevNewRows.filter((item) => item.key !== key)); // Remove from newRows
  };

  const handleSave = (row) => {
    setDataSource((prevDataSource) =>
      prevDataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    );
    setNewRows((prevNewRows) =>
      prevNewRows.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    ); // Update in newRows
  };

  // Helper function to compare floating-point numbers with tolerance
  const areNumbersEqual = (a, b, epsilon = 0.000001) =>
    Math.abs(a - b) < epsilon;

  // Calculate the sums of only the newly added rows
  const newSummary = newRows.reduce(
    (acc, item) => {
      const firstBalance = parseFloat(item.eh_dun) || 0;
      const etsUld = parseFloat(item.ets_dun) || 0;
      acc.eh_dun += firstBalance;
      acc.ets_dun += etsUld;
      return acc;
    },
    { eh_dun: 0, ets_dun: 0 }
  );

  // Use initialSummary for confirmation logic
  const isConfirmDisabled =
    newRows.length > 0 &&
    (!areNumbersEqual(newSummary.eh_dun, initialSummary.eh_dun) ||
      !areNumbersEqual(newSummary.ets_dun, initialSummary.ets_dun));

  const handleSave2 = (row) => {
    setDataSource2((prevDataSource) =>
      prevDataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item
      )
    );
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleConfirm = () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: {
          onSuccess: [get_data(data_date.month), dispatch(disable_state({}))],
        },
      });
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_05_report',
      content: {
        action_type: 'save_sa13',
        month: data_date?.month,
        table_data: dataSource,
        table_data2: dataSource2,
        summary: totalSum,
      },
    });
    dispatch(start_loading({}));
  };

  const columns = [
    {
      title: 'Хөрөнгө оруулалтын төрөл',
      dataIndex: 'turul',
      key: 'turul',
      editable: (record, rowIndex) => rowIndex !== 0 || rowIndex === 0, // Editable for all rows and column 1 in the first row
    },
    {
      title: (
        <div>
          <div>Эхний үлдэгдэл</div>
          <div>Хөрөнгө оруулалтын хувь</div>
        </div>
      ),
      dataIndex: 'eh_huvi',
      key: 'eh_huvi',
      editable: (record, rowIndex) => rowIndex !== 0 || rowIndex === 0, // Editable for all rows and column 2 in the first row
      width: 150,
    },
    {
      title: (
        <div>
          <div>Эхний үлдэгдэл</div>
          <div>Хөрөнгө оруулалтын дүн</div>
        </div>
      ),
      dataIndex: 'eh_dun',
      key: 'eh_dun',
      width: 150,
      editable: (record, rowIndex) => rowIndex !== 0, // Not editable in the first row
    },
    {
      title: (
        <div>
          <div>Эцсийн үлдэгдэл</div>
          <div>Хөрөнгө оруулалтын хувь</div>
        </div>
      ),
      dataIndex: 'ets_huvi',
      key: 'ets_huvi',
      editable: (record, rowIndex) => rowIndex !== 0 || rowIndex === 0, // Editable for all rows and column 4 in the first row
      width: 150,
    },
    {
      title: (
        <div>
          <div>Эцсийн үлдэгдэл</div>
          <div>Хөрөнгө оруулалтын дүн</div>
        </div>
      ),
      dataIndex: 'ets_dun',
      key: 'ets_dun',
      width: 150,
      editable: (record, rowIndex) => rowIndex !== 0, // Not editable in the first row
    },
    {
      title: 'Устгах',
      key: 'action',
      render: (_, record) =>
        record.editable !== false && (
          <Tooltip title="Устгах">
            <DeleteOutlined
              onClick={() =>
                customConfirm({
                  onOk: () => handleDelete(record.key),
                })
              }
            />
          </Tooltip>
        ),
    },
  ];

  const columns2 = [
    {
      title: 'Үзүүлэлт',
      dataIndex: 'turul',
      key: 'turul',
    },
    {
      title: 'Эхний үлдэгдэл',
      dataIndex: 'eh_uld',
      key: 'eh_uld',
      width: 150,
      render: (_, row) => roundFloat(row?.eh_uld),
    },
    {
      title: (
        <div>
          <div>Нэмэгдсэн дүн</div>
          <div>Өөрийн хөрөнгөөр хийсэн</div>
        </div>
      ),
      dataIndex: 'uuriin_hurungu',
      key: 'uuriin_hurungu',
      width: 150,
      render: (_, row) => roundFloat(row?.uuriin_hurungu),
    },
    {
      title: (
        <div>
          <div>Нэмэгдсэн дүн</div>
          <div>Улс, орон төсвийн хөрөнгө оруулалтаар</div>
        </div>
      ),
      dataIndex: 'uls_oron_tusuv',
      key: 'uls_oron_tusuv',
      editable: true,
      width: 150,
      render: (_, row) => roundFloat(row?.uls_oron_tusuv),
    },
    {
      title: (
        <div>
          <div>Нэмэгдсэн дүн</div>
          <div>Бусад</div>
        </div>
      ),
      dataIndex: 'busad',
      key: 'busad',
      editable: true,
      width: 150,
      render: (_, row) => roundFloat(row?.busad),
    },
    {
      title: 'Хорогдсон дүн',
      dataIndex: 'horogdson_dun',
      key: 'horogdson_dun',
      width: 150,
      render: (_, row) => roundFloat(row?.horogdson_dun),
    },
    {
      title: 'Эцсийн үлдэгдэл',
      dataIndex: 'ets_uld',
      key: 'ets_uld',
      width: 150,
      render: (_, row) => roundFloat(row?.ets_uld),
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable && !col.children) {
      return col;
    }

    // If column has children, map over the child columns as well
    if (col.children) {
      return {
        ...col,
        children: col.children.map((childCol) => ({
          ...childCol,
          onCell: (record) => ({
            record,
            editable: record.editable && record.editable[childCol.dataIndex],
            dataIndex: childCol.dataIndex,
            title: childCol.title,
            handleSave,
          }),
        })),
      };
    }

    // Otherwise, handle non-nested columns
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: record.editable && record.editable[col.dataIndex],
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const mergedColumns2 = columns2.map((col) => ({
    ...col,
    onCell: (record) => ({
      record,
      editable: col.editable && record.editable !== false,
      dataIndex: col.dataIndex,
      title: col.title,
      handleSave2,
    }),
  }));

  const totalSum = dataSource.reduce(
    (acc, item) => ({
      eh_dun_sum: acc.eh_dun_sum + (parseFloat(item.eh_dun) || 0),
      ets_dun_sum: acc.ets_dun_sum + (parseFloat(item.ets_dun) || 0),
    }),
    { eh_dun_sum: 0, ets_dun_sum: 0 }
  );

  const { eh_dun_sum, ets_dun_sum } = totalSum;

  return (
    <div className="tax-account-table-container">
      <Collapse
        defaultActiveKey={['4', '1']}
        expandIconPosition="right"
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ? <UpOutlined /> : <DownOutlined />
        }
      >
        <Panel header="13. Урт хугацаат хөрөнгө оруулалт" key="1">
          <div className="table-add-row">
            <div className="table-container">
              <Table
                components={{
                  body: {
                    cell: EditableCell1,
                  },
                }}
                dataSource={dataSource}
                columns={mergedColumns}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey="key"
                // summary={() => (
                //   <Table.Summary.Row>
                //     <Table.Summary.Cell index={0}>Нийт</Table.Summary.Cell>
                //     <Table.Summary.Cell index={1} />
                //     <Table.Summary.Cell index={2}>
                //       {roundFloat(eh_dun_sum)}
                //     </Table.Summary.Cell>
                //     <Table.Summary.Cell index={3} />
                //     <Table.Summary.Cell index={4}>
                //       {roundFloat(ets_dun_sum)}
                //     </Table.Summary.Cell>
                //   </Table.Summary.Row>
                // )}
                footer={() => (
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      type="primary"
                      className="add-btn"
                      onClick={handleAddRow}
                    >
                      <PlusCircleOutlined />
                      Мөр нэмэх
                    </Button>
                    <Tooltip
                      title={
                        isConfirmDisabled
                          ? 'Хоосон мөрнүүдийг заавал бөглөнө үү'
                          : ''
                      }
                    >
                      <Button
                        type="primary"
                        className="confirm-btn"
                        onClick={handleConfirm}
                        style={{ marginLeft: 8 }}
                        disabled={isConfirmDisabled}
                      >
                        Хадгалах
                      </Button>
                    </Tooltip>
                  </div>
                )}
              />
            </div>
          </div>
        </Panel>

        <Panel header="13.1 Хайгуул үнэлгээний хөрөнгө" key="4">
          <div className="table-add-row">
            <div className="table-container">
              <Table
                components={{
                  body: {
                    cell: EditableCell2,
                  },
                }}
                dataSource={dataSource2}
                columns={mergedColumns2}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey="key"
                footer={() => (
                  <div style={{ textAlign: 'center' }}>
                    <Tooltip
                      title={
                        isSubmitDisabled
                          ? 'Хоосон мөрнүүдийг заавал бөглөнө үү'
                          : ''
                      }
                    >
                      <Button
                        type="primary"
                        className="confirm-btn"
                        onClick={handleConfirm}
                        style={{ marginLeft: 8 }}
                        disabled={isSubmitDisabled}
                      >
                        Хадгалах
                      </Button>
                    </Tooltip>
                  </div>
                )}
              />
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default AddRowTable;
