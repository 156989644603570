const route_state = false;

const reducer = (state = route_state, action) => {
  switch (action.type) {
    case 'enable_state':
      return true;
    case 'disable_state':
      return false;
    default:
      return state;
  }
};

export default reducer;
