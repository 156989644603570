import { useDispatch } from 'react-redux';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';
// Import the alert function if needed
export const calcDashboardData = (socket, data_date, dispatch) => {
  socket.on('request_out', (data) => {
    socket.removeAllListeners('request_out');

    if (data.status === '0') {
      //   getDashboardData();
    } else {
      newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
    }
    dispatch(end_loading({}));
  });

  socket.emit('request_in', {
    channel: 'ts001_02_tatvar_journal_burtgel',
    content: {
      action_type: 'get_hansh_tohirgoo_dun',
      month: data_date.month,
      is_dashboard: true,
    },
  });

  dispatch(start_loading({}));
};
