import { roundFloat } from 'src/core/utils/formater';

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'dun',
  },
  {
    align: 'right',
    value: 'huulid_zaasan',
  },
];

export const customColumns = () => [
  {
    title: 'Д/Д',
    dataIndex: 'counter',
    align: 'center',
    titleAlign: 'center',
    width: 50,
  },
  {
    title: 'Зардлын төрөл',
    dataIndex: 'ner',
    align: 'left',
    titleAlign: 'center',
    width: 685,
  },
  {
    title: 'Хуулийн 13.1-д заасан нөхцөлийг зэрэг хангасан зардал',
    titleAlign: 'center',
    children: [
      {
        title: 'Дүн',
        dataIndex: 'dun',
        align: 'right',
        titleAlign: 'center',
        width: 150,
        render: (_, row) => ({
          children: roundFloat(row?.dun),
        }),
      },
      {
        title: 'Үүнээс: хуулийн 18.4-т заасан зардлын дүн',
        dataIndex: 'huulid_zaasan',
        align: 'right',
        titleAlign: 'center',
        width: 200,
        render: (_, row) => ({
          children: roundFloat(row?.huulid_zaasan),
        }),
      },
    ],
  },
];
