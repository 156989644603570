/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import jwt from 'jwt-decode';
import { capitalize } from 'lodash';
import CompanyNoti from 'src/components/common/companyNoti';
import { WS_DISCONNECT } from 'src/core/actions/socket';
import {
  Layout,
  Menu,
  Button,
  Tooltip,
  Dropdown,
  Avatar,
  PageHeader,
  Modal,
  Breadcrumb,
  Divider,
} from 'antd';
import {
  UserOutlined,
  LeftOutlined,
  RightOutlined,
  WarningOutlined,
  CaretDownOutlined,
  FilePdfTwoTone,
  VideoCameraTwoTone,
} from '@ant-design/icons';
import Slider from 'react-slick';
import imgLogo from 'src/assets/images/newLogo.png';
import CustomTour from 'src/core/layout/hintBar/CustomTour';
import { remove_jwt } from '../actions/jwt_token';
import { set_month, set_year } from '../actions/data_date';
import { routeList, _menu, settings } from './utils/dataNoat';
import HintBar from './hintBar/hintBar';
import Search from './search';

const { Header, Content, Sider } = Layout;
const { confirm } = Modal;
const _routeList = [...routeList];
const _rootSubmenuKeys = [...Array(11).keys()].map((i) => `sub${i + 1}`);

const noBackList = [];

const NoatLayout = (props) => {
  const sliderRef = useRef();
  const dispatch = useDispatch();
  const { data_date, jwt_token, codeList, open_search, socket } = useSelector(
    (state) => ({
      version: state.version,
      data_date: state.data_date,
      jwt_token: state.jwt_token,
      codeList: state.role,
      open_search: state.open_search,
      socket: state.socket,
    })
  );

  const history = useHistory();
  const [index, seTindex] = useState(0);
  const [companyName, seTcompanyName] = useState('');
  const [userName, seTuserName] = useState('');
  const [collapse, seTcollapse] = useState(false);
  const [openKeys, setOpenKeys] = useState(['']);
  const [selectedKeys, seTselectedKeys] = useState('');
  const [visible, seTvisible] = useState(false);
  const [visibleYear, seTvisibleYear] = useState(false);
  const [selectMonth, seTselectMonth] = useState([]);
  const [breadList, seTbreadList] = useState([
    {
      title: 'Нүүр',
      to: '/noat',
    },
  ]);
  const changeMonth = () => {
    const sortedMonths = selectMonth.sort((a, b) => a - b);
    const Months = sortedMonths.join(',');
    dispatch(set_month(Months));
    localStorage.setItem('activeMonth', Months);
    seTvisible(!visible);
  };
  useEffect(() => {
    let _nodeId = '';
    let _id = '';
    const { jwt_1 } = jwt_token;
    const { company_name = '', user_name = '' } = jwt(jwt_1) || {};
    seTuserName(user_name);
    seTcompanyName(company_name);
    if (props?.pathName) {
      _routeList.forEach((i) => {
        const { children, title } = i;
        const child = children?.find((j) => {
          if (j?.url === props?.pathName || props?.pathName.includes(j?.url)) {
            return true;
          }
          return false;
        });
        if (child) {
          _nodeId = child?.nodeId;
          _id = i?.key;
          const _parent = { title, to: children[0].url };
          const _child = { title: child?.text, to: child?.url };
          seTbreadList([_parent, _child]);
        }
      });
      if (_nodeId) {
        setOpenKeys([_id]);
        seTselectedKeys(_nodeId);
      }
    }
  }, [props?.pathName]);

  useEffect(() => {
    if (collapse) return;
    sliderRef.current.slickGoTo(index);
    seTindex(open_search ? 0 : 1);
  }, [open_search]);

  const changeCompany = () => {
    localStorage.removeItem('etaxtoken');
    localStorage.removeItem('notifications');
    socket.emit('send_to_company_select', {
      channel: 'ss002_auth_manage',
      content: {},
      tag: { jwt_1: localStorage.getItem('taxjwt') },
    });
  };

  

  const changeSelectMonth = (month) => {
    // Check if the month already exists in the selectMonth array
    const monthIndex = selectMonth.indexOf(month);

    if (monthIndex === -1) {
      // If the month doesn't exist, add it to selectMonth
      seTselectMonth([...selectMonth, month]);
    } else {
      // If the month exists, remove it from selectMonth
      const updatedSelectMonth = [...selectMonth];
      updatedSelectMonth.splice(monthIndex, 1);
      seTselectMonth(updatedSelectMonth);
    }
  };

  const changeYear = (date) => {
    dispatch(set_year(date));
    localStorage.setItem('activeYear', date);
  };

  const onCollapse = () => {
    seTcollapse(!collapse);
    seTindex(1);
  };

  const logout = () => {
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    localStorage.removeItem('activeMonth');
    localStorage.removeItem('taxjwt');
    history.push('/login');
    dispatch(WS_DISCONNECT(socket));
    dispatch(remove_jwt());
  };

  const showOutConfirm = () => {
    confirm({
      title: 'Та гарахдаа итгэлтэй байна уу?',
      icon: <WarningOutlined />,
      okText: 'Тийм',
      okType: 'danger',
      cancelText: 'Үгүй',
      onOk() {
        logout();
      },
      onCancel() {},
    });
  };

  const menu = (
    <div>
      <Menu className="p-5">
        {_menu.map((item) => (
          <Menu.Item
            key={item.join('_')}
            style={{
              padding: 0,
            }}
          >
            {item.map((j) => (
              <Button
                type="text"
                className={`fw-600 noat-month ${
                  selectMonth.includes(j) ? 'noat-month-selected' : ''
                }`}
                onClick={() => {
                  changeSelectMonth(j);
                }}
                block
              >
                {`${j}-р сар`}
              </Button>
            ))}
          </Menu.Item>
        ))}
        <Divider />
        <Menu.Item
          key="buttons_month"
          style={{
            padding: 0,
          }}
        >
          <Button
            type="text"
            className="noat-month fw-600 noat-month-button"
            block
            onClick={() => {
              seTselectMonth([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
            }}
          >
            Бүгд
          </Button>
          <Button
            type="text"
            className="noat-month fw-600 noat-month-button"
            block
            onClick={() => {
              seTselectMonth([]);
            }}
          >
            Цэвэрлэх
          </Button>
          <Button
            type="text"
            className={`noat-month fw-600  ${
              selectMonth.length !== 0 ? 'noat-month-button' : ''
            }`}
            block
            onClick={changeMonth}
            disabled={selectMonth.length === 0}
          >
            Сонгоx
          </Button>
        </Menu.Item>
      </Menu>
    </div>
  );

  const getYears = (n) => {
    const currentYear = new Date().getFullYear();
    const previousYears = [];
    for (let i = 0; i <= n; i += 1) {
      previousYears.push(currentYear - i);
    }
    return previousYears.reverse();
  };

  const menuYear = () => {
    const years = getYears(2);
    return (
      <Menu className="p-5 fixed-150">
        <Menu.Item
          style={{
            padding: 0,
          }}
        >
          {years.map((j) => (
            <Button
              type="text"
              className="w-100 fw-600"
              onClick={() => {
                changeYear(j);
                seTvisibleYear(!visibleYear);
              }}
              block
            >
              {`${j} он`}
            </Button>
          ))}
        </Menu.Item>
      </Menu>
    );
  };

  const toUser = () => {
    history.push('/noat/hereglegchMedeelel');
  };

  const addUser = () => {
    history.push('/noat/tohirgoo/userAdd');
  };

  const ttd = () => {
    history.push('/noat/tatvarTulugch/medeelel');
  };

  const toArray = (input) => {
    if (typeof input === 'number') {
      return [input];
    }
    if (typeof input === 'string') {
      return input.split(',').map((str) => parseInt(str.trim(), 10));
    }
    return [];
  };

  const menuLogout = (
    <Menu>
      <Menu.Item className="custom-menu-item">{userName || ''}</Menu.Item>
      <Menu.Item className="custom-menu-item" onClick={toUser}>
        Хэрэглэгчийн мэдээлэл
      </Menu.Item>
      <Menu.Item className="custom-menu-item" onClick={addUser}>
        Хэрэглэгчийн тохиргоо
      </Menu.Item>
      <Menu.Item className="custom-menu-item" onClick={ttd}>
        Татвар төлөгчийн мэдээлэл
      </Menu.Item>
      <Menu.Item className="custom-menu-item bottom" onClick={changeCompany}>
        Компани солих
      </Menu.Item>
      <Menu.Item className="custom-menu-item bottom" onClick={showOutConfirm}>
        Системээс гарах
      </Menu.Item>
    </Menu>
  );

  const onOpenChange = (keys) => {
    const latestOpenKey = keys?.find((key) => openKeys.indexOf(key) === -1);
    if (_rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const subItem = (data) => (
    <Menu.Item key={data.nodeId} icon={data?.icon || <UserOutlined />}>
      {data?.text?.length > 27 ? (
        <Tooltip placement="rightTop" title={data?.text} key="leftButton">
          <Link to={data.url}>{data?.text}</Link>
        </Tooltip>
      ) : (
        <Link to={data.url}>{data?.text}</Link>
      )}
    </Menu.Item>
  );

  const mainSubItem = (data) => {
    const isPermited = codeList?.find((i) => i?.code === data?.code);
    if (data?.code) {
      if (isPermited) {
        return subItem(data);
      }
    } else {
      return subItem(data);
    }
    return null;
  };

  const breadCrumb = () => (
    <>
      <Breadcrumb className="inside-bread fs-12" separator=">">
        {breadList.map((i) => (
          <Breadcrumb.Item>
            <Link to={i?.to}>{i.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </>
  );

  return (
    <div className="App">
      <CompanyNoti />
      <Layout className="main-layout">
        <Sider
          className={`left-sider-layout ${collapse ? 'open' : 'closed'}`}
          breakpoint="lg"
          collapsedWidth="0"
          collapsed={collapse}
          trigger={null}
        >
          <div className={`icon-container ${collapse && 'collapsed'}`}>
            {collapse && (
              <RightOutlined
                className="t-5 fs-20 p-12 pb-5 ml-3 mb-3"
                onClick={onCollapse}
              />
            )}
            {!collapse && (
              <>
                <a href="/">
                  <img src={imgLogo} alt="Logo" />
                </a>
                <LeftOutlined className="open-icon" onClick={onCollapse} />
              </>
            )}
          </div>
          <Slider
            className={`searcher ${collapse ? 'open' : 'closed'}`}
            {...settings}
            ref={sliderRef}
          >
            {' '}
            <Menu
              className={`ant-left-menu noat ${collapse ? 'closed' : ''}`}
              mode="inline"
              openKeys={openKeys}
              selectedKeys={selectedKeys}
              onClick={(e) => {
                seTselectedKeys(e.key);
              }}
              inlineCollapsed={collapse}
              onOpenChange={onOpenChange}
            >
              {routeList.map((item) => mainSubItem(item))}
            </Menu>
            {!collapse && <Search hide={collapse} />}
          </Slider>
        </Sider>
        <Layout className="main-color">
          <Header className="header-layout">
            <PageHeader
              ghost={false}
              className="w-100 h-60 p-0 background-trans color-layout"
              title={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <div className="header-container">
                  <Tooltip
                    title={capitalize(companyName) || 'Бүртгэлгүй компани'}
                  >
                    <p className="mr-15 company-title">
                      {capitalize(companyName) || 'Бүртгэлгүй компани'}
                    </p>
                  </Tooltip>
                  {breadCrumb()}
                </div>
              }
              extra={[
                <span className="fs-14 fw-600 ml-10 mr-0 header-dropdown">
                  Тайлант үе:
                </span>,
                <Dropdown
                  placement="bottomRight"
                  open={visibleYear}
                  overlay={menuYear}
                  className="header-dropdown"
                >
                  <Button
                    onClick={() => {
                      seTvisibleYear(!visibleYear);
                    }}
                    type="text"
                    className="m-0 p-5 fs-14 fw-600 color-layout"
                  >
                    <CaretDownOutlined className="fs-12" />
                    {`${data_date?.year} он`}
                  </Button>
                </Dropdown>,
                <Dropdown
                  placement="bottomRight"
                  open={visible}
                  overlay={menu}
                  className="header-dropdown"
                >
                  <Button
                    onClick={() => {
                      seTselectMonth(toArray(data_date.month));
                      seTvisible(!visible);
                    }}
                    type="text"
                    className="m-0 p-5 fs-14 fw-600 color-layout"
                  >
                    <CaretDownOutlined className="fs-12" />
                    {`${data_date?.month} сар`}
                  </Button>
                </Dropdown>,
                <Tooltip placement="bottomLeft" title="Видео заавар">
                  <a
                    href="https://youtu.be/kemIo9fSCN8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <VideoCameraTwoTone
                      className="header-guidebook-download"
                      twoToneColor="#0094bc"
                    />
                  </a>
                </Tooltip>,
                <Tooltip
                  title="Хэрэглэгчийн гарын авлага"
                  placement="bottomRight"
                >
                  <a
                    href="https://drive.google.com/file/d/1PeQmsUnwiJ1s_iHXjGwwEiFjLQGSwPq2/view"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FilePdfTwoTone
                      className="header-guidebook-download"
                      twoToneColor="#0094bc"
                    />
                  </a>
                </Tooltip>,
                <Dropdown
                  placement="bottomRight"
                  overlay={menuLogout}
                  trigger={['click']}
                >
                  <Avatar className="custom-avatar">
                    {companyName.toUpperCase()[0]}
                  </Avatar>
                </Dropdown>,
              ]}
            />
          </Header>
          <Content className="main-content-layout">
            <main
              className={`mainContent ${
                // eslint-disable-next-line react/destructuring-assignment
                noBackList.includes(props?.pathName) ? 'no-background' : ''
              } `}
            >
              <div className="layout-tabs">
                <div
                  style={{
                    height: 20,
                  }}
                />
              </div>
              <div className="main-body  no-scroll }" id="modalMount">
                {props?.children}
                <HintBar location={props?.pathName} />
              </div>
            </main>
          </Content>
        </Layout>
      </Layout>
      <CustomTour location={props?.pathName} />
    </div>
  );
};

export default NoatLayout;
