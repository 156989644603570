import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { roundFloat } from 'src/core/utils/formater';
import { Popconfirm, Tooltip } from 'antd';
import CanView from 'src/components/permission/canview';
import { customConfirm } from 'src/components/common/confirmModal';

export const customSummary = [
  {
    value: null,
  },
  {
    align: 'left',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'dans_une',
  },
  {
    align: 'right',
    value: 'tatvar_suuri',
  },
  {
    align: 'right',
    value: 'zuruu',
  },
  {
    value: null,
  },
  {
    value: null,
  },
];

export const columns = ({
  send_confirm_row_id = () => {},
  send_aldagdal_data = () => {},
  delete_row = () => {},
}) => [
  {
    title: 'Огноо',
    align: 'center',
    dataIndex: 'ognoo',
    titleAlign: 'center',
    width: 100,
  },
  {
    title: 'Алдагдлын дүн',
    align: 'right',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'aldagdal_dun',
    render: (_, row) => roundFloat(row.aldagdal_dun),
  },
  // {
  //   title: 'Эхний үлдэгдлийн огноогоорх Ашиглагдаагүй алдагдлын үлдэгдэл',
  //   align: 'right',
  //   titleAlign: 'center',
  //   dataIndex: 'ehnii_uld_dun',
  //   width: 200,
  // },
  {
    title: 'Төрөл',
    align: 'right',
    titleAlign: 'center',
    dataIndex: 'type',
    width: 150,
    render: (_) => (_ === 2 ? 'Залруулга' : ''),
  },
  {
    title: 'Татварын алба',
    align: 'left',
    width: 150,
    titleAlign: 'center',
    dataIndex: 'tatvar_alba',
  },
  {
    title: 'Актын огноо',
    align: 'center',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'akt_ognoo',
  },
  {
    title: 'Актын №',
    align: 'left',
    titleAlign: 'center',
    width: 100,
    dataIndex: 'akt_number',
  },
  {
    title: 'Хавсаргасан файл',
    align: 'left',
    titleAlign: 'center',
    width: 150,
    ellipsis: true,
    render: (_, { file_id, file_ner, database_name }) => {
      console.log(file_id, file_ner, database_name);
      if (file_ner) {
        return (
          <>
            <CanView allowedRole="003_1">
              <Popconfirm
                title="Файл татах уу?"
                okText="Тийм"
                cancelText="Үгүй"
                onConfirm={() => send_confirm_row_id(file_id, file_ner)}
              >
                <div className="link-wrap"> {file_ner}</div>
              </Popconfirm>
            </CanView>
          </>
        );
      }
    },
  },
  {
    title: 'Тайлбар',
    align: 'left',
    titleAlign: 'center',
    width: 150,
    dataIndex: 'tailbar',
  },
  {
    title: 'Засах',
    align: 'center',
    titleAlign: 'center',
    width: 80,
    render: (_, row) => (
      <CanView allowedRole="003_1">
        <Tooltip title="Засах">
          <EditOutlined
            style={{ color: '#08c' }}
            onClick={() =>
              send_aldagdal_data(
                row.aldagdal_id,
                row.ognoo,
                row.aldagdal_dun,
                row.ehnii_uld_dun,
                row.tatvar_alba,
                row.akt_ognoo,
                row.akt_number,
                row.file_ner,
                row.file_id,
                row.tailbar
              )
            }
          />
        </Tooltip>
      </CanView>
    ),
  },
  {
    title: 'Устгах',
    align: 'center',
    titleAlign: 'center',
    width: 80,
    render: (_, row) => (
      <div>
        <CanView allowedRole="003_1">
          <Tooltip title="Устгах">
            <DeleteOutlined
              style={{ color: '#08c' }}
              onClick={() =>
                customConfirm({
                  onOk: () => delete_row(row?.aldagdal_id),
                })
              }
            />
          </Tooltip>
        </CanView>
      </div>
    ),
  },
];
