import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'src/components';
import { Drawer, Form, Input, Typography } from 'antd';
import newAlert from 'src/components/newAlert';
import { start_loading, end_loading } from '../../../core/actions/loader';
import { ColumnsTab2 } from './dataStructure/data';
import UpdateBurtgel from './updateBurtgel';

const { Text } = Typography;
const { Search } = Input;

export default function BurtgelList({
  hurunguData = {},
  closeDrawer = () => {},
  open = false,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const socket = useSelector((state) => state.socket);
  const [visible, setVisible] = useState(false);

  const [BurtgelData, setBurtgelData] = useState({});
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: false,
      error_message: [],
    },
  });
  const [pagenationData, seTpagenationData] = useState({
    page: 1,
    size: 20,
    burtgel: '',
  });

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const search = {
      page2: pagenationData?.page,
      size2: pagenationData?.size,
      hurungu: hurunguData.code,
      burtgel: pagenationData?.burtgel,
      type: 'burtgel',
    };
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: { action_type: 'undsen_medeelel_page', search },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      seTpagenationData({
        ...pagenationData,
        page: 1,
        burtgel: '',
      });
    } else {
      select_data();
    }
  }, [open]);

  const selectRow = (row) => {
    setBurtgelData(row);
    setVisible(true);
    console.log(row);
  };

  const onCancel = () => {
    setVisible(false);
    if (open) {
      select_data();
    }
  };

  useEffect(() => {
    if (open) {
      select_data();
    }
  }, [pagenationData]);

  const getParm = (values) => {
    if (
      // eslint-disable-next-line operator-linebreak
      values.page !== pagenationData.page ||
      values.size !== pagenationData.size
    ) {
      seTpagenationData({
        ...pagenationData,
        page: values.page,
        size: values.size,
      });
    }
  };

  const onSearch = (values) => {
    if (values) {
      seTpagenationData({
        ...pagenationData,
        burtgel: values,
      });
    } else {
      seTpagenationData({
        ...pagenationData,
        burtgel: '',
      });
    }
  };

  const title = () => (
    <>
      <div className="table-title-text-center">Бүртгэлийн мэдээлэл</div>
      <div style={{ width: '350px' }}>
        <Form.Item name="search" className="ant-form-item-small">
          <Search
            size="small"
            allowClear
            placeholder="Бүртгэлийн дугаар..."
            onSearch={onSearch}
          />
        </Form.Item>
      </div>
    </>
  );

  return (
    <Drawer
      title="Хөрөнгийн ашиглах хугацаа"
      open={open}
      width="90%"
      footer={null}
      closable
      maskClosable={false}
      onClose={() => {
        closeDrawer('visible3');
      }}
      className="custom-drawer"
    >
      <UpdateBurtgel
        visible={visible}
        burtgelData={BurtgelData}
        onCancel={onCancel}
      />
      <div className="dans-medeelel-container">
        <Form
          form={form}
          name="basic"
          className="register-form"
          size="small"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Form.Item
            label="Үндсэн хөрөнгийн нэр"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{hurunguData?.ner}</Text>
          </Form.Item>
          <Form.Item
            label="Үндсэн хөрөнгийн код"
            labelAlign="left"
            className="ant-form-item-small"
          >
            <Text className="ant-form-text">{hurunguData?.code}</Text>
          </Form.Item>
          <Table
            title={title}
            columns={ColumnsTab2({ selectRow })}
            subTotal={tableData?.total_size}
            dataSource={tableData?.result_list}
            scroll={{
              y: 'calc(100vh - 330px)',
            }}
            getParam={getParm}
          />
        </Form>
      </div>
    </Drawer>
  );
}
