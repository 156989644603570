import React from 'react';
import { Tag } from 'antd';

const statusEnum = {
  expired: { text: 'Хугацаа дууссан', color: '#ee677e' },
  new: {
    text: 'Шинэ',
    color: '#f6c26a',
  },

  active: { text: 'Идэвхтэй', color: '#6ec989' },
};

export const getTag = (status) => {
  const { color, text } = statusEnum[status];
  return <Tag color={color}>{text}</Tag>;
};
export const columns = [
  {
    title: '№',
    dataIndex: 'id',
    key: 'id',
    width: 30,
    render: (_, __, idx) => idx + 1,
  },
  {
    title: 'Регистр дугаар',
    dataIndex: 'register',
    key: 'register',
    align: 'center',
    width: 150,
  },
  {
    title: 'Нэр',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
  },
  {
    title: 'Төрөл',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: 100,
  },
  {
    title: 'Лиценз',
    children: [
      {
        title: 'Төлөв',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: getTag,
        width: 150,
      },
      {
        title: 'Эхлэсэн огноо',
        dataIndex: 'startDate',
        key: 'startDate',
        align: 'center',
        width: 120,
      },
      {
        title: 'Дууссан огноо',
        dataIndex: 'endDate',
        key: 'endDate',
        align: 'center',
        width: 120,
      },
    ],
  },
];

export const dataSource = [
  {
    name: 'Таван богд',
    register: '121331',
    type: 'ААН',
    status: 'active',
    startDate: '2021/01/01',
    endDate: '2022/02/28',
  },
  {
    name: 'KFC',
    register: '1111111',
    type: 'ААН',
    status: 'expired',
    startDate: '2021/01/01',
    endDate: '2022/02/28',
  },
];
