import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { CalculatorOutlined } from '@ant-design/icons';
import { Button as AntButton } from 'antd';
import TableCommon from 'src/components/common/table';
import newAlert from 'src/components/newAlert';

import { start_loading, end_loading } from 'src/core/actions/loader';
import CanView from 'src/components/permission/canview';
import { columns } from './dataStructure/data';

const rows = [{ test: '' }];

const Client = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const [tableData, setTableData] = useState(rows);

  const mainData = async () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      if (status === '0') {
        const new_temp_data = content?.table_data?.map((inner_data, key) => ({
          id: key + 1,
          ...inner_data,
        }));
        if (new_temp_data) {
          setTableData(new_temp_data);
        }
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts007_02_calculate_aldagdal_data',
      content: { month: data_date.month },
    });
    dispatch(start_loading({}));
  };

  const aldagdal_holboh = async () => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
      });
      dispatch(end_loading({}));
    });

    socket.emit('request_in', {
      channel: 'ts007_02_manage_aldagdal_data',
      content: { action_type: 'aldagdal_set_dun', month: data_date.month },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    console.log('>> Getting old list...');
    mainData();
  }, []);

  const title = () => (
    <>
      <CanView allowedRole="003_1">
        <AntButton
          id="first"
          className="add-btn"
          type="primary"
          size="small"
          icon={<CalculatorOutlined />}
          onClick={() => {
            aldagdal_holboh();
          }}
        >
          Алдагдлын дүн холбох
        </AntButton>
      </CanView>
    </>
  );

  return (
    <>
      <div className="table-antd-div">
        <TableCommon
          columns={columns({})}
          dataSource={tableData}
          scroll={{
            x: 'auto',
          }}
          title={title}
        />
      </div>
    </>
  );
};

export default Client;
