import React, { useState, useEffect } from 'react';
import { Tooltip, Image, Modal, Button } from 'antd';
import { ReactComponent as Video } from 'src/assets/images/video.svg';
import { ReactComponent as Question } from 'src/assets/images/question.svg';
import { ReactComponent as MainLogo } from 'src/assets/images/main.svg';
import { ReactComponent as Excel } from 'src/assets/images/excel.svg';
import Draggable from 'react-draggable';
import { tourList, newTourList } from './data';

const HintBar = ({ location = '' }) => {
  const [shown, seTshown] = useState(false);
  const [visible, setVisible] = useState(false);
  const [imgList, seTimgList] = useState([]);
  const [videoUrl, seTvideoUrl] = useState('');
  const [activeVideoUrl, seTactiveVideoUrl] = useState('');
  const [modalVisible, seTmodalVisible] = useState(false);

  const _tourList = [...tourList, ...newTourList];
  const listOfPath = _tourList.map((i) => i?.path);

  const watch = () => {
    seTmodalVisible(true);
  };

  useEffect(() => {
    const isWatched = localStorage.getItem('mainVideo');
    const finded = _tourList.find((t) => t?.path === location);
    if (finded) {
      if (finded?.imgList) {
        seTimgList(finded?.imgList);
      } else {
        seTimgList([
          {
            src: finded?.id,
          },
        ]);
      }
      if (finded?.youtube) seTvideoUrl(finded?.youtube);
      if (location === '/main' && !isWatched) {
        seTmodalVisible(true);
        localStorage.setItem('mainVideo', true);
      }
    } else {
      seTimgList([]);
    }
    return () => {
      seTvideoUrl('');
    };
  }, [location]);

  const VideoLayout = () => {
    if (Array.isArray(videoUrl)) {
      // seTactiveVideoUrl(videoUrl[0].url);
      return (
        <>
          {videoUrl.map((i) => (
            <div className="hint-bar-modal-header">
              <Button onClick={() => seTactiveVideoUrl(i.url)}>
                {i.title}
              </Button>
            </div>
          ))}
          <div className="hint-bar-modal-inside video">
            {activeVideoUrl ? (
              <iframe
                width="600"
                height="400"
                src={activeVideoUrl}
                title="YouTube тоглуулагч"
                // frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : (
              <span>Бичлэг сонгоно уу</span>
            )}
          </div>
        </>
      );
    }
    if (videoUrl) {
      return (
        <div className="hint-bar-modal-inside video">
          <iframe
            width="600"
            height="400"
            src={videoUrl}
            title="YouTube тоглуулагч"
            // frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      );
    }
    return (
      <div className="hint-bar-modal-inside video">
        <span>Бичлэг ороогүй байна</span>
      </div>
    );
  };
  const openInNewTab = () => {
    window.open(
      'https://drive.google.com/drive/folders/1l1pRUm4BpLxYmaD26HoWjAJAUaJgaVsv?usp=sharing',
      '_blank',
      'noreferrer'
    );
  };

  return (
    <>
      <Draggable bounds="parent" axis="y" handle="#handle">
        <div
          id="handle"
          className={`hint-side-bar active ${shown ? 'shown' : ''} `}
        >
          <Tooltip placement="leftBottom" title="Тусламж" color=" #2c3e50">
            <MainLogo
              onClick={() => {
                seTshown(!shown);
              }}
              className={`main-robot ${
                listOfPath.includes(location) ? 'shown' : ''
              } `}
            />
          </Tooltip>
          <div className={`inside ${shown ? 'boom' : ''} `}>
            <Tooltip placement="topRight" title="Видео заавар" color=" #2c3e50">
              <Video className="icon" onClick={watch} />
            </Tooltip>
            <Tooltip placement="topRight" title="Эксел импорт" color=" #2c3e50">
              <Excel
                className="icon"
                onClick={() => {
                  openInNewTab();
                }}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Гарын авлага" color=" #2c3e50">
              <Question
                onClick={() => {
                  setVisible(true);
                }}
                className="icon"
              />
            </Tooltip>
          </div>
        </div>
      </Draggable>
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
        >
          {
            // eslint-disable-next-line operator-linebreak
            imgList &&
              // eslint-disable-next-line operator-linebreak
              imgList.length > 0 &&
              imgList.map((i) => (
                <Image
                  src={`${process.env.PUBLIC_URL}/pictureList/${i?.src}.jpg`}
                />
              ))
          }
        </Image.PreviewGroup>
      </div>
      <Modal
        open={modalVisible}
        footer={null}
        centered
        zIndex={9999}
        width={800}
        transitionName="ant-move-up"
        className="hint-bar-modal"
        onCancel={() => {
          seTmodalVisible(false);
          seTactiveVideoUrl('');
        }}
        closable={false}
        title={null}
      >
        <VideoLayout />
      </Modal>
    </>
  );
};

export default HintBar;
