import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';

import Table from 'src/components/common/table';
import { Spin, Button, Popover, Card, List } from 'antd';
import TableTabs from 'src/components/common/tableTabs';

import { start_loading, end_loading } from 'src/core/actions/loader';
import { roundFloat } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import {
  columns,
  columns_2,
  columns_3,
  customSummary,
  customSummary_2,
  customSummary_3,
} from './dataStructure/data';

export default function Client() {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const { month } = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [tableData_2, setTableData_2] = useState([]);
  const [tableData_3, setTableData_3] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [tableDataSum_2, setTableDataSum_2] = useState({});
  const [tableDataSum_3, setTableDataSum_3] = useState({});
  const [disabled, seTdisabled] = useState({
    state: false,
    dans_une: 0.0,
    huugiin_zardal: 0.0,
    zoruu: 0.0,
    total_hetersen_dun: 0.0,
  });
  const [loading, seTloading] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const CardData = [
    {
      title: 'ТШБ дансны дүн :',
      elementId: 'first',
      value: roundFloat(disabled.dans_une),
    },
    {
      title: 'Өгөгдлийн дүн:',
      elementId: 'second',

      value: roundFloat(disabled.huugiin_zardal),
    },
    {
      title: 'Зөрүү:',
      elementId: 'third',

      value: roundFloat(disabled.zoruu),
    },
  ];
  const WarningContent = (
    <div className="white">
      <WarningOutlined className="white" /> Хүүгийн зардлын дүн Хүүгийн зардалд{' '}
      <br />
      холбогдсон дүнгээс зөрүүтэй байна.
    </div>
  );

  const mainData = (month) => {
    seTloading(true);
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      console.log('------------------------------------');
      if (status === '0') {
        const temp_dans_data = content?.dans_data.map((inner_data, key) => ({
          key: key + 1,
          ...inner_data,
        }));
        setTableData_3(temp_dans_data);
        const temp_shareholder_data = content?.shareholder_data.map(
          (inner_data, key) => ({
            key: key + 1,
            ...inner_data,
          })
        );
        setTableData_2(temp_shareholder_data);
        const temp_notshareholder_data = content?.notshareholder_data.map(
          (inner_data, key) => ({
            key: key + 1,
            ...inner_data,
          })
        );
        setTableDataSum(content.total_notshareholder);
        setTableDataSum_2(content.total_shareholder);
        setTableDataSum_3(content.total_dans_sum);
        setTableData(temp_notshareholder_data);
        seTdisabled(content.link_state);
        seTloading(false);
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_manage_huugiin_zardal',
      content: {
        month,
        action_type: 'get_required_dun_for_huu',
      },
    });

    dispatch(start_loading({}));
  };

  const handleSaveValue = (total_dans_dun, total_hetersen_dun) => {
    socket.on('request_out', ({ status, content }) => {
      socket.removeAllListeners('request_out');
      newAlert({
        type: status === '0' ? 'success' : 'error',
        msg: content.Message,
        functions: { onSuccess: mainData(month) },
      });
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts004_00_manage_huugiin_zardal',
      content: {
        month,
        action_type: 'set_hetersen_dun_to_zardal_main',
        total_hetersen_dun,
        total_dans_dun,
      },
    });
  };

  const Table1 = () => (
    <div className="display-hyazgaar-dans">
      <div className="hyazgaar-card">
        <Card title="Зардлын дүн" className="custom-card">
          <List
            size="small"
            dataSource={CardData}
            className="custom-list"
            renderItem={(item) => (
              <List.Item>
                <span id={item?.elementId}>{item.title}</span>
                <span>{item.value}</span>
              </List.Item>
            )}
          />
          <div className="link-btn">
            {disabled.state ? (
              <Popover overlayClassName="Warning" content={WarningContent}>
                <Button
                  type="primary"
                  size="small"
                  disabled={disabled.state}
                  className="add-btn"
                  shape="round"
                >
                  Нийт цонх руу холбох
                </Button>
              </Popover>
            ) : (
              <Button
                type="primary"
                size="small"
                className="add-btn"
                shape="round"
                onClick={() => {
                  handleSaveValue(
                    disabled.dans_une,
                    disabled.total_hetersen_dun
                  );
                }}

                // onClick={handleSaveValue(
                //   disabled.dans_une,
                //   disabled.total_hetersen_dun
                // )}
                // disabled={disabled.state}
              >
                Нийт цонх руу холбох
              </Button>
            )}
          </div>
        </Card>
      </div>
      <div className="hyazgaar-dans-table ww-60 mt-10">
        <Table
          columns={columns_3}
          dataSource={tableData_3}
          scroll={{
            x: 'auto',
          }}
          summaryList={{
            sumColumn: customSummary_3,
            sumDataSource: tableDataSum_3,
          }}

          // customSummary_3
        />
      </div>
    </div>
  );

  const Table2 = () => (
    <div className="zardal-overflow">
      <div className="table-antd-div zardal-container-huu-hhe">
        <Table
          columns={columns}
          dataSource={tableData}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableDataSum,
          }}
        />
      </div>
    </div>
  );

  const Table3 = () => (
    <div className="zardal-overflow">
      <div className="table-antd-div zardal-container-huu-hhe">
        <Table
          columns={columns_2}
          dataSource={tableData_2}
          summaryList={{
            sumColumn: customSummary_2,
            sumDataSource: tableDataSum_2,
          }}
        />
      </div>
    </div>
  );

  const list = [
    {
      key: '1',
      title: 'Зардлын дүн',
      content: <Table1 />,
    },
    {
      key: '2',
      title: <span id="fourth"> Хүүгийн зардлын хэтрэлт - ХХЭ </span>,
      content: <Table2 />,
    },
    {
      key: '3',
      title: <span id="fifth"> Хүүгийн зардлын хэтрэлт - ХО </span>,
      content: <Table3 />,
    },
  ];

  useEffect(() => {
    console.log('>> Getting old list...');
    mainData(month);
  }, [month]);

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin indicator={antIcon} />;
      </div>
    );
  }

  return (
    <div>
      <TableTabs listTabs={list} />
    </div>
  );
}
